<template>
  <Toolbar v-bind="$attrs" height="52">

    <Btn v-if="length > 1" @click="prev" icon>
      <v-icon>$prev</v-icon>
    </Btn>

    <v-spacer/>

    <div v-for="( group, index ) in groups" :key="index">
      <Btn
        class="pagination-button"
        v-for="num in number( group )"
        :color="group[0] + num === page ? 'primary' : 'secondary'"
        :key="num"
        @click="$emit( 'input', group[0] + num )"
        icon
      >
        {{ group[0] + num }}
      </Btn>
      <span v-if="index < groups.length - 1">...</span>
    </div>

    <v-spacer/>

    <Btn v-if="length > 1" @click="next" icon>
      <v-icon>$next</v-icon>
    </Btn>

  </Toolbar>
</template>

<script>
  export default {
    props: {
      value: Number,
      length: Number,
      visibles: Number
    },
    computed: {
      page() {
        return Math.max( Math.min( this.value || 0, this.length || 1 ), 1 );
      },
      limit() {
        return Math.max( this.visibles || 0, 3 );
      },
      groups() {
        let arr = [];
        let { page, length, limit } = this;
        if ( length > 0 ) {
          if ( limit >= length ) arr.push([ 0, length ]);
          else {

            limit = Math.max( limit, 3 );
            let mid  = Math.round( limit / 2 ),
                mid2 = ( limit - mid ),
                cuts = [];

            if ( page < mid || page > length - mid2 ) cuts = cuts.concat([ mid, length - mid2 ]);
            else {
              if ( page != mid ) cuts = cuts.concat([ 1, page - mid + 1 ]);
              if ( page != length - mid2 ) cuts = cuts.concat([ page + mid2 - 1, length - 1 ]);
            }

            arr.push([ 0, cuts[0] || length ]);
            cuts[1] && arr.push([ cuts[1], cuts[2] || length ]);
            cuts[3] && arr.push([ cuts[3], length ]);
          }
        }
        return arr;
      }
    },
    methods: {
      prev() {
        this.$emit( 'input', Math.max( this.page - 1, 1 ));
      },
      next() {
        this.$emit( 'input', Math.min( this.page + 1, this.length || 1 ));
      },
      number( group ) {
        return group[1] - group[0];
      }
    }
  };
</script>

<style>
.pagination-button {
  width: auto !important;
  min-width: 36px !important;
  border-radius: 36px !important;
  padding: 0 3px !important;
}
</style>

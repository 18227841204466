<template>
  <span class="change-date" :class="{ small: sm, open }">

    <Btn v-if="sm" :color="open ? 'primary' : 'secondary'" @click="open = !open" text>
      <v-icon style="font-size:20px;" v-text="'mdi-calendar-month'"/>
    </Btn>

    <v-slide-y-transition>
      <v-sheet
        v-show="!sm || open"
        class="change-date--wrapper"
        color="white"
        :elevation="sm ? 2 : 0"
      >

        <Btn @click="prev" :disabled="!showPrev" class="mr-3" color="secondary" icon>
          <v-icon>$prev</v-icon>
        </Btn>

        <DField
          v-model="date"
          field="date"
          field-style="1"
          class="mr-2"
          style="max-width: 150px;"
          :min="min"
          :max="max"
          :clearable="false"
          dense
        />

        <Btn @click="next" :disabled="!showNext" class="mr-3" color="secondary" icon>
          <v-icon>$next</v-icon>
        </Btn>

        <Btn class="subtitle mr-3" color="tertiary" @click="date = today" dark>
          Hoy
        </Btn>

      </v-sheet>
    </v-slide-y-transition>
  </span>
</template>

<script>

  import moment from 'moment';

  export default {
    props: {
      value: Number,
      min: Number,
      max: {
        type: Number,
        default: () => moment().endOf('date').valueOf()
      }
    },
    data() {
      const today = moment().endOf('date').valueOf();
      return {
        today,
        date: this.value ? this.value : today,
        open: false,
      }
    },
    watch: {
      value( value ) {
        this.date = value ? value : this.today;
      },
      date( value ) {
        this.$emit( 'input', value );
      },
      sm( value ) {
        if ( ! value ) this.open = false;
      }
    },
    computed: {
      start() {
        return moment( this.date ).startOf('date').valueOf();
      },
      end() {
        return moment( this.date ).endOf('date').valueOf();
      },
      showPrev() {
        if ( !this.min ) return true;
        return this.start > this.min;
      },
      showNext() {
        if ( !this.max ) return true;
        return this.end < this.max;
      },
      sm() {
        return this.$vuetify.breakpoint.xs;
      }
    },
    methods: {
      prev() {
        this.date = moment( this.date )
          .endOf('date')
          .add( -1, 'days' )
          .valueOf();
      },
      next() {
        this.date = moment( this.date )
          .endOf('date')
          .add( 1, 'days' )
          .valueOf();
      }
    }
  };
</script>

<style>
  .change-date {
    position: relative;
  }
  .change-date--wrapper {
    display: flex;
    align-items: center;
    width: 306px;
  }
  .change-date.small .change-date--wrapper {
    top: 100%;
    right: 0;
    position: absolute;
    padding: 8px;
    z-index: 99999;
  }
</style>

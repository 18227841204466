<template>
  <v-text-field
    class="dfield-search"
    v-bind="$attrs"
    v-on="$listeners"
    prepend-inner-icon="$search"
    color="secondary"
    outlined
    filled
    solo
    flat
    dense
  >
  </v-text-field>
</template>

<script>
  export default {
    nullValues: ''
  }
</script>

<style>
  .dfield-search {
    border-radius: 4px !important;
  }
  .dfield-search input {
    margin-top: 0 !important;
  }
</style>

<template>
  <Table
    service="CampaignLinks"
    :headers="headers"
    :sanitize="sanitize"
    :params="params"
    :mypopup="mypopup"
    messages="Link/Links"
    show-actions="new,edit,delete"
  >

    <template v-slot:form="{ value, listeners }">
      <LinkForm
        :value="value"
        v-on="listeners"
      />
    </template>

    <template v-slot:item.link="{ value }">
      <a :href="value" target="_blank">
        {{ value }}
      </a>
    </template>

  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import LinkForm from "@/components/forms/Campaigns/links.vue";
import { mapState } from 'vuex';

export default {
  components: { Table, LinkForm },
  data() {
    return {
      mypopup: {
        width: 500,
        height: 300,
        title: a => a ? 'Nuevo link' : 'Editar link'
      }
    };
  },
  computed: {
    ...mapState([ 'breadcrumbs' ]),
    campaignID() {
      return parseInt( this.$route.params.id ) || 0;
    },
    headers() {
      return [
        { value: 'title', text: 'Nombre', width: 240 },
        { value: 'link', text: 'Link' }
      ];
    },
    params() {
      return {
        filters: [
          { field: 'campaign', intValue: this.campaignID }
        ]
      }
    }
  },
  watch: {
    campaignID: 'setTitle'
  },
  methods: {
    sanitize( model ) {
      return {
        ...model,
        idCampaign: this.campaignID
      }
    },
    setTitle( force ) {

      const id = this.campaignID;
      const title = [ 'Campañas', '', 'Links' ];
      this.$store.commit( 'setTitle', title );

      if ( id && ( force || this.breadcrumbs[1].text === '...')) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

<template>
  <Table
    service="Tasks"
    :sanitize="sanitize"
    :messages="messages"
    :sections="sections"
    :headers="headers"
    :update="update"
    :mypopup="mypopup"
    show-actions="edit,new,delete"
    @section="section = $event[0]"
  >

    <template v-slot:item.sourceUser="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link" :title="value" :to="`/users/${ item.sourceUser.id }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.targetUser="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link" :title="value" :to="`/users/${ item.targetUser.id }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.campaign="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link" v-if="item.campaign" :title="value" :to="`/campaigns/${ item.campaign.id }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.priority="{ value }">
      <v-icon :title="priorities[value].text" :color="priorities[value].color">
        mdi-alert-circle
      </v-icon>
    </template>

    <template v-slot:form="{ value, listeners, isNew, close, save }">
      <DForm v-if="value" class="layer" :value="value" v-on="{ ...listeners, input: v => input( v, listeners ) }">
        <v-row v-if="isNew" class="fill-height flex-column" no-gutters>
          <div class="grow rel">
            <v-row class="layer autoscroll" no-gutters>
              <div class="grow pa-4">

                <DField
                  key="targetUser"
                  class="mb-4"
                  v-bind="user"
                />

                <v-row class="mb-4" no-gutters>
                  <DField
                    key="title"
                    v-bind="title"
                  />
                  <DField
                    key="priority"
                    class="ml-2"
                    v-bind="priority"
                    style="width:200px;max-width:200px;"
                  />
                </v-row>

                <DField
                  key="description"
                  v-bind="description"
                />

              </div>
              <Card class="fill-height pa-4" width="320" max-width="320" border-left>

                <p class="subtitle-2">Opcional</p>

                <DField
                  key="date"
                  class="mb-4"
                  v-bind="endDate"
                />

                <DField
                  key="campaign"
                  class="mb-4"
                  v-bind="campaign"
                />

                <DField
                  key="center"
                  class="mb-4"
                  v-bind="centers"
                />

              </Card>
            </v-row>
          </div>
          <Toolbar class="py-2" border-top>

            <v-spacer/>

            <Btn class="mr-2" color="tertiary" @click="close" text>
              Cancelar
            </Btn>

            <Btn color="primary" @click="save( true, section !== 1 )">
              Guardar
            </Btn>

          </Toolbar>
        </v-row>
        <v-row v-else class="fill-height flex-column" no-gutters>
          <Toolbar class="py-2" border-bottom>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-2" :color="priorities[value.priority].color">
                  mdi-alert-circle
                </v-icon>
              </template>
              <span><strong>Prioridad:</strong> {{ priorities[value.priority].text }}</span>
            </v-tooltip>

            <div>
              <span class="mr-2">Fecha de entrega:</span>
              <span v-html="estimateDate( value.date, 'Indefinida' )"></span>
            </div>

            <v-spacer/>

            <DField
              key="status"
              class="mr-2"
              style="max-width:240px"
              v-bind="status"
              />

            <DField
              key="substatus"
              style="max-width:240px"
              v-bind="substatus"
            />

          </Toolbar>
          <div class="grow rel">
            <div class="layer autoscroll pa-4">
              <div class="pa-2 mb-4">

                <div class="subtitle-2 tertiary--text mb-2">Descripción:</div>
                <div>{{ value.description }}</div>

                <Card v-if="value.files.length" class="mt-4" border>
                  <DGallery
                    :items="value.files"
                    item-key="id"
                    item-image="url"
                    cols="8"
                  />
                </Card>

              </div>
              <template v-for="( item, index ) in history">
                <div :key="index" v-if="item.comments || item.statusChanged">
                  <v-divider/>
                  <v-row class="flex-nowrap pa-2" align="center" no-gutters>

                    <div style="flex: 0 0 175px;">
                      <router-link class="caption mr-1" :to="`/users/${ item.idUser }`">
                        {{ userName( value.sourceUser.id === item.idUser ? value.sourceUser : value.targetUser, true ) }}
                      </router-link>
                      <p class="caption mb-0">
                        {{ date( item.date ) }} - {{ time( item.date ) }}
                      </p>
                    </div>

                    <div v-if="item.comments" class="body-2 fill-width">
                      {{ item.comments }}
                    </div>

                    <div class="body-2" style="flex: 0 0 175px;">
                      <p v-if="item.statusChanged" class="mb-0">
                        Cambió el estado a <span class="tertiary--text">{{ statuses[ item.status ] }}</span>
                      </p>
                    </div>

                  </v-row>
                </div>
              </template>
            </div>
          </div>
          <v-divider/>
          <div class="d-flex pa-2" no-gutters>

            <DField key="comments" v-bind="comments"/>

            <div class="pl-2">
              <Btn color="primary" @click="changeStatus( save )">
                Enviar
              </Btn>
            </div>

          </div>
        </v-row>
      </DForm>
    </template>

  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import { formatDate, direction, userName } from '@/utils';
  import { Task, CampaignStatus, UserRole } from '@/utils/constants';

  const priorities = {
    [Task.Priority.LOW]:    { text: 'Baja',    color: 'grey'    },
    [Task.Priority.NORMAL]: { text: 'Normal',  color: 'info'    },
    [Task.Priority.HIGH]:   { text: 'Alta',    color: 'warning' },
    [Task.Priority.URGENT]: { text: 'urgente', color: 'error'   }
  };

  const status = {
    [Task.Status.CREATED]:    'Creada',
    [Task.Status.VALIDATED]:  'Validada',
    [Task.Status.REASSIGNED]: 'Reasignada',
    [Task.Status.CLOSED]:     'Cerrada',
    [Task.Status.REMOVED]:    'Eliminada'
  }

  const substatus = {
    [Task.Substatus.TODO]:         'Todo',
    [Task.Substatus.INPROGRESS]:   'En progreso',
    [Task.Substatus.PENDING_INFO]: 'Pendiente',
    [Task.Substatus.DONE]:         'Realizada'
  }

  function date( value ) {
    return formatDate( value, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit' }
    );
  }

  function time( value ) {
    return formatDate( value, {
      hour: 'numeric',
      minute: '2-digit' }
    );
  }

  function popupTitle( isNew, scope ) {
    if ( isNew ) return 'Nueva tarea';
    if ( scope.value )
      return [ scope.value.title || 'Sin título', userName( scope.value.sourceUser ) ].join(' - Asignada por: ');
    return 'Tarea';
  }

  export default {
    components: { Table },
    data() {
      return {
        messages: { item: 'Tarea/Tareas', fem: true },
        mypopup: {
          width: 800,
          height: 520,
          title: popupTitle,
          hideFooter: true,
          arrows: true
        },
        sections: [
          { text: 'Todas'},
          { text: 'Recibidas', filters: [{ field: 'to',   intValue: this.$store.state.userID }]},
          { text: 'Enviadas',  filters: [{ field: 'from', intValue: this.$store.state.userID }]}
        ],
        priorities,
        statuses: status,
        section: 0,
        setStatus: false,
        model: null,
        centers: this.fieldCenters(),
        campaignSelected: 0,
        history: []
      }
    },
    computed: {
      headers() {
        return [
             { text: 'Usuario encargado',   value: 'targetUser',
              display: v => userName( v ),
              filter: {
                type: 'autocomplete',
                attrs: {
                  service: 'Users',
                  itemValue: 'id',
                  itemText: 'name',
                  display: v => userName( v, true ),
                  all: true
                }
              }
            },
            { text: 'Enviado por', value: 'sourceUser',
              display: v => userName( v ),
              filter: {
                type: 'autocomplete',
                attrs: {
                  service: 'Users',
                  itemValue: 'id',
                  itemText: 'name',
                  display: v => userName( v, true ),
                  all: true
                }
              }
            },
          { text: 'Título', value: 'title' },
          {
            text: 'Campaña', value: 'campaign',
            display: a => a ? `${a.id}. ${a.title||a.name}` : '',
            filter: {
              type: 'text',
              attrs: {
                service: 'Campaigns',
                itemValue: 'id',
                itemText: 'title',
                all: true
              }
            }
          },
          {
            text: 'Centro', value: 'center.place', display: v => direction( v ),
            filter: {
              type: 'autocomplete',
              field: 'center',
              attrs: {
                service: 'Places',
                itemValue: 'id',
                itemText: 'name',
                display: v => direction( v, false ),
                all: true
              }
            }
          },
          {
            text: 'Estado', value: 'status', display: status, width: 100,
            filter: {
              type: 'select',
              items: Object.keys( status ).map( value => ({
                value: parseInt( value ),
                text: status[value]
              }))
            }
          },
          {
            text: 'Subestado', value: 'substatus', display: substatus, width: 100,
            filter: {
              type: 'select',
              items: Object.keys( substatus ).map( value => ({
                value: parseInt( value ),
                text: substatus[value]
              }))
            }
          },
          {
            text: 'Prioridad', value: 'priority', width: 75,
            filter: {
              type: 'select',
              items: Object.keys( priorities ).map( value => ({
                value: parseInt( value ),
                text: priorities[value].text
              }))
            }
          },
          {
            text: 'Fecha estimanda', value: 'date', width: 160,
            display: v => this.estimateDate( v ),
            filter: {
              type: 'rangedate',
              compute: ( value, field ) => {
                if ( !value[0] || !value[1] ) return {[field]: null };
                return {[field]: { start: value[0], end: value[1] }};
              }
            }
          },
        ];
      },
      user() {
        return {
          field: 'autocomplete',
          fieldStyle: 1,
          label: 'Para',
          placeholder: 'Buscar usuario...',
          service: 'Users',
          itemText: 'name',
          itemValue: 'id',
          display: v => userName( v, true ),
          rules: [ v => !!v || 'Debes seleccionar un usuario para la tarea.' ],
          dense: true,
          params: { filters: [{
            field: 'role',
            listValue: [
              UserRole.SUPERADMIN,
              UserRole.KAM,
              UserRole.MANAGER,
              UserRole.RRHH
            ]
          }]},
        }
      },
      campaign() {
        return {
          field: 'autocomplete',
          fieldStyle: 1,
          label: 'Campaña',
          placeholder: 'Buscar campaña...',
          service: 'Campaigns',
          itemText: 'name',
          itemValue: 'id',
          dense: true,
          display: a => `${a.id}. ${a.title || a.name}`,
          params: { filters: [{
            field: 'status',
            listValue: [
              CampaignStatus.ACTIVE,
              CampaignStatus.DRAFT
            ]
          }]},
        }
      },
      endDate() {
        return {
          field: 'date',
          fieldStyle: 1,
          label: 'Fecha de finalización',
          min: Date.now(),
          dense: true
        }
      },
      title() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Título',
          rules: [ v => !!v || 'Debes ingresar un título para la tarea.' ],
          dense: true
        }
      },
      description() {
        return {
          field: 'textarea',
          fieldStyle: 1,
          label: 'Descripción',
          noResize: true,
          hideDetails: true,
          rows: 10
        }
      },
      comments() {
        return {
          field: 'textarea',
          fieldStyle: 1,
          placeholder: 'Escribe un comentario...',
          rows: 1,
          hideDetails: true,
          noResize: true,
          autoGrow: true,
          dense: true
        }
      },
      status() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Estado',
          dense: true,
          items: Object.keys( status ).map( value => ({
            value: parseInt( value ),
            text: status[value]
          }))
        }
      },
      substatus() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Subestado',
          dense: true,
          items: Object.keys( substatus ).map( value => ({
            value: parseInt( value ),
            text: substatus[value]
          }))
        }
      },
      priority() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Prioridad',
          dense: true,
          rules: [ v => v != null || 'Indica la prioridad de la taera.' ],
          items: Object.keys( priorities ).map( value => ({
            value: parseInt( value ),
            text: priorities[value].text
          }))
        }
      }
    },
    methods: {
      date,
      time,
      direction,
      userName,
      estimateDate( value, emptyText ) {
        if ( value ) {
          var diff = value - new Date().getTime();
          if ( diff < ( 7 * 24 * 3600 * 1000 )) {
            return "<strong style=\"color:red;\">"+date(value)+"</strong>";
          }
          return date( value );
        }
        return emptyText || '';
      },
      changeStatus( save ) {
        this.setStatus = true;
        save( true, this.section !== 1, true );
      },
      update( params ) {
        const target = this.setStatus ? 'Tasks/status' : 'Tasks/set';
        this.setStatus = false;
        return this.$store.dispatch( 'api', { target, params });
      },
      input( value, on ) {

        const { campaignSelected } = this;
        const { statuses } = value;

        this.model = value;
        this.campaignSelected = ({ id: 0, ...this.model.campaign }).id;

        if ( statuses && statuses.length > this.history.length )
          this.history = statuses
            .map(( item, index ) => this.prepareState( item, statuses[ index + 1 ] ));

        if ( campaignSelected !== this.campaignSelected ) this.centers = this.fieldCenters();
        on.input( this.model );
      },
      prepareState( item, next ) {
        item.statusChanged = !next || item.status !== next.status;
        return item;
      },
      sanitize( m ) {
        return this.setStatus ?
        {
          id: m.id,
          comments: m.comments,
          date: Date.now(),
          status: m.status,
          substatus: m.substatus
        } : {
          id: m.id || -1,
          title: m.title,
          description: m.description,
          type: m.type,
          priority: m.priority,
          idCampaign: m.campaign ? m.campaign.id : null,
          idCenter: m.center ? m.center.id : null,
          date: m.date,
          status: Task.Status.CREATED,
          substatus: Task.Substatus.TODO,
          targetUser: m.targetUser ? m.targetUser.id : null,
          files: ( m.files || [] ).map( f => f.id ),
        };
      },
      fieldCenters() {

        const { campaignSelected } = this;
        var props = {
          field: 'autocomplete',
          fieldStyle: 1,
          label: 'Centro',
          clearable: true,
          dense: true
        };

        if ( campaignSelected ) {
          Object.assign( props, {
            service: 'Campaigns',
            method: 'centers',
            itemSearch: 'place',
            itemText: 'name',
            itemValue: 'id',
            params: { filters: [{ field: 'campaign', intValue: campaignSelected }]},
            display: a => a.place ? a.place.name : ''
          });
        }

        return props;
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Tareas' );
    }
  }
</script>

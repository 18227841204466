<template>
  <Table
    service="IssueCategories"
    :messages="messages"
    :mypopup="mypopup"
    :headers="headers"
    :sanitize="sanitize"
    show-actions
  >
    <template v-slot:form="{ value, listeners }">
      <IssueCategory class="pa-4" :value="value" v-on="listeners" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import IssueCategory from "@/components/forms/IssueCategories/single.vue";

const types = ["Incidencia", "Alerta"];
const workingTypes = ["No", "Sí"];
const statusTypes = {
  "-1": "Inactivo",
  "1": "Activo",
};
export default {
  components: { Table, IssueCategory },
  data() {
    return {
      messages: { item: "Categoría/Categorías" },
      headers: [
        { text: "Nombre", value: "name" },

        {
          text: "Categoría padre",
          value: "parent",
          display: "name",
          filter: {
            type: "autocomplete",
            attrs: {
              service: "IssueCategories",
              itemText: "name",
              itemValue: "id",
              all: true,
              params: { filters: [{ field: "isMainCategory" }] },
            },
          },
        },

        {
          text: "Type",
          value: "type",
          display: this.displayType,
          filter: {
            type: "select",
            items: types.map((text, value) => ({ text, value })),
          },
        },
        {
          text: "Durante la jornada",
          value: "working",
          display: this.displayWorking,
          filter: {
            type: "select",
            items: workingTypes.map((text, value) => ({ text, value })),
          },
        },
        {
          text: "Por defecto",
          value: "default",
          display: this.displayWorking,
          filter: {
            type: "select",
            items: workingTypes.map((text, value) => ({ text, value })),
          },
        },
        {
          text: "Estado",
          value: "status",
          display: this.displayStatus,
          filter: {
            type: "select",
            items: Object.keys(statusTypes).map((value) => ({
              text: statusTypes[value],
              value,
            })),
          },
        },
      ],
      mypopup: {
        height: 450,
        title: (n) => (n ? "Nueva categoría" : "Editar categoría"),
        initial: (name) => ({ name }),
      },
    };
  },
  methods: {
    displayStatus(value) {
      switch (value) {
        case 1:
          return "Activo";
      }
      return "Inactivo";
    },
    displayWorking(value) {
      if (value) return "Sí";
      return "No";
    },
    displayType(value) {
      switch (value) {
        case 1:
          return "Alarma";
      }
      return "Incidencia";
    },
    sanitize(model) {
      return {
        id: model.id || -1,
        name: model.name,
        type: model.type,
        working: model.type == 0 ? model.working : true,
        isDefault: model.default,
        default: model.default,
        idParent: model.parent ? model.parent.id : -1,
        status: 1,
      };
    },
  },
  mounted() {
    this.$store.commit( 'setTitle', 'Categorias de incidencia' );
  }
};
</script>

<template>
  <Table
    service="Clients"
    :messages="messages"
    :headers="headers"
    show-actions
  >

    <template v-slot:item.website="{ value }">
      <a :href="value" :title="value" target="_blank">{{ value }}</a>
    </template>

    <template v-slot:item.link="{ value }">
      <a :href="value" :title="value" target="_blank">{{ value }}</a>
    </template>

  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';

  function brandNames( value ) {
    return ( value || [] ).map( a => a.name ).join(', ');
  }

  export default {
    components: { Table },
    data() {
      return {
        messages: { item: 'Cliente/Clientes' },
        headers: [
          { text: "id", value: "id", width: 80, filter: {
            type: 'number',
            field: 'ids',
            attrs: { min: 1, hideControls: true }
          }},
          { text: 'Nombre', value: 'name' },
          {
            text: 'Sector', value: 'sector', width: 100, display: 'name',
            filter: {
              type: 'select',
              attrs: {
                service: 'Sectors',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          },
          {
            text: 'Marca', value: 'brands', width: 100, display: brandNames,
            filter: {
              type: 'select',
              attrs: {
                service: 'Brands',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          },
          { text: 'CIF', value: 'cif', width: 100  },
          { text: 'Teléfono', value: 'phone', width: 100 },
          { text: 'Web', value: 'website' },
          { text: 'Link', value: 'link' }
        ]
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Clientes' );
    }
  }
</script>

<template>
  <Single
    :show-actions="false"
    :loading="loading"
  >
    <div class="pa-8">

      <p class="title mb-0">Recrear tareas de la campaña</p>
      <p><small>Vuelve a regenerar las tareas de hoy para la campaña.</small></p>

      <Btn class="mb-6" color="tertiary" :loading="loadingAction.tasks" @click="action('tasks')" dark>
        Recrear tareas
      </Btn>

      <p class="title mb-0">Enviar credenciales</p>
      <p><small>Envia un correo electrónico a todos los promotores de la campaña con sus credenciales de acceso.</small></p>

      <Btn class="mb-6" color="tertiary" :loading="loadingAction.credentials" @click="action('credentials')" dark>
        Enviar credenciales
      </Btn>

      <p class="title mb-0">Eliminar datos de campaña</p>
      <p><small>Elimina todos los datos de la campaña. Recuerda que una vez eliminado, no se podrá recuperar.</small></p>

      <Btn color="primary" :loading="loadingAction.remove" @click="removeData">
        Eliminar datos de campaña
      </Btn>

    </div>
  </Single>
</template>

<script>

  import Single from '@/components/core/Single.vue';
  import { mapState, mapMutations } from 'vuex';

  export default {
    components: { Single },
    data: () => ({
      loading: false,
      loadingAction: {
        remove: false,
        tasks: false,
        credentials: false
      }
    }),
    computed: {
      ...mapState([ 'breadcrumbs' ]),
      campaignID() {
        return parseInt( this.$route.params.id ) || 0;
      }
    },
    watch: {
      campaignID: 'setTitle'
    },
    methods: {
      ...mapMutations( 'app', [ 'setDialog' ]),
      action( name ) {
        this.loading = true;
        this.loadingAction[name] = true;
        this[name]().finally(() => {
          this.loading = false;
          this.loadingAction[name] = false;
        });
      },
      tasks() {
        return this.$store.dispatch( 'api', {
          target: 'CampaignTasks/recreate',
          params: { id: this.campaignID }
        }).then(() => {
          this.$store.dispatch( 'console/success', 'Las tareas han sido regeneradas.' );
        }).catch( err => {
          this.$store.dispatch( 'console/error', err );
        });
      },
      credentials() {
        return this.$store.dispatch( 'api', {
          target: 'Campaigns/credentials',
          params: { idCampaign: this.campaignID }
        }).then(() => {
          this.$store.dispatch( 'console/success', 'Se ha procesado tu petición, recibirás un correo con la información de los envíos realizados.' );
        }).catch( err => {
          this.$store.dispatch( 'console/error', err );
        });
      },
      removeData() {
        this.setDialog({
          width: 400,
          title: '¿Estás seguro de eliminar todos los datos de la campaña?',
          text: 'Esta acción no será reversible una vez efectuada',
          accept: () => this.action('remove')
        });
      },
      remove() {
        return this.$store.dispatch( 'api', {
          target: 'Campaigns/removeData',
          params: { id: this.campaignID }
        }).then(() => {
          this.$store.dispatch( 'console/success', 'Se han eliminado todos los datos de campaña.' );
        }).catch( err => {
          this.$store.dispatch( 'console/error', err );
        });
      },
      setTitle( force ) {

        const id = this.campaignID;
        const title = [ 'Campañas', '', 'Opciones de campaña' ];
        this.$store.commit( 'setTitle', title );

        if ( id && ( force || this.breadcrumbs[1].text === '...')) {
          this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
            title[1] = res.title;
            this.$store.commit( 'setTitle', title );
          });
        }
      }
    },
    beforeRouteEnter ( to, from, next ) {
      next( vm => {
        if ( vm.$store.state.isClient ) {
          //vm.$store.commit( 'cancelFetch', to.fullPath );
          if ( to.params.id === 'new' ) vm.$router.replace('/campaigns');
        }
      });
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

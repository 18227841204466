import DStats from './DStats.vue';
import DStatsProgress from './DStatsProgress.vue';
import DStatsCheese from './DStatsCheese.vue';
import DPagination from './DPagination.vue';
import DResponsive from './DResponsive.vue';
import DGallery from './DGallery.vue';
import DImage from './DImage.vue';
import DSlider from './DSlider.vue';
import DTable from './DTable.vue';
import DForm from './DForm.vue';
import DField from './DField.vue';
import DSteps from './DSteps.vue';
import DChangeDate from './DChangeDate.vue';
import DChangeRangeDate from './DChangeRangeDate.vue';
import FileIcon from './FileIcon.vue';
import Toolbar from './Toolbar.vue';
import Badge from './Badge.vue';
import Block from './Block.vue';
import Card from './Card.vue';
import Tabs from './Tabs.vue';
import Btn from './Btn.vue';

export default {
  DStats,
  DStatsProgress,
  DStatsCheese,
  DPagination,
  DResponsive,
  DGallery,
  DImage,
  DSlider,
  DTable,
  DForm,
  DField,
  DSteps,
  DChangeDate,
  DChangeRangeDate,
  FileIcon,
  Toolbar,
  Badge,
  Block,
  Card,
  Tabs,
  Btn
};

<template>
  <DField
    v-bind="$attrs"
    v-on="$listeners"
    :key="$vnode.key"
    :mypopup="mypopup"
    service="Brands"
    field="autocomplete"
    item-text="name"
    item-value="id"
  >
    <template v-slot:form="{ value, listeners }">

      <BrandForm :value="value" v-on="listeners" />

    </template>
  </DField>
</template>

<script>

  import BrandForm from '@/components/forms/Brands/single.vue';

  export default {
    components: { BrandForm },
    computed: {
      mypopup() {
        return {
          height: 100,
          title: n => n ? 'Nueva marca' : 'Editar marca',
          initial: name => ({ name })
        }
      }
    }
  }
</script>

<template>
  <Page
    :loading="loading"
    hide-footer
  >
    <div class="no-gutters pa-6">
      <v-row class="no-gutters">
            <div style="width:100%" class="text-center">
          <h3 class="mb-2">Información de la plataforma</h3>
      </div>
      </v-row>
      <v-row class="no-gutters">
            <div style="width:100%" class="text-center">
          <h2 class="mb-2">App Grupo OT gestión</h2>
      </div>
      </v-row>
      <v-row class="no-gutters">
            <div style="width:100%" class="text-center">
         
          <img
            width="200"
            :src="require('@/assets/logo-app.png')"
            alt=""
          />
      </div>
      </v-row>

      <v-row class="no-gutters">
        <v-col>
        <Card class="rel">
            <div class="text-center">

        <span class="title">Versión: <strong>{{this.$store.state.user.androidVersion}} </strong></span>
        <br>
        <a class="mx-1" :href="androidLink" target="_blank">
          <img
            width="200"
            :src="require('@/assets/google-play-badge.svg')"
            alt="Descarga android app"
          />
        </a>

      </div>
        </Card>
        </v-col>
        <v-col>
        <Card class="rel">
            <div class="text-center">

        <span class="title">Versión: <strong>{{this.$store.state.user.iosVersion}}</strong> </span>
        <br>
        <a class="mx-1" :href="iosLink" target="_blank">
          <img
            width="200"
            :src="require('@/assets/app-store-badge.svg')"
            alt="Descarga ios app"
          />
        </a>

      </div>
        </Card>
        </v-col>
      </v-row>
    </div>

  </Page>
</template>

<script>

  /* eslint-disable */

  import Page from '@/components/core/Page.vue';

  export default {
    components: { Page },
    data: () => ({
      key: 0,
      loading: false,
      androidLink: 'https://play.google.com/store/apps/details?id=com.grupoot.app',
      iosLink: 'https://apps.apple.com/es/app/id1524746306'
    }),
    computed:{
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Información' );
    }
  }
</script>

<style>
  
</style>

<template>
  <v-dialog
    v-model="show"
    :max-width="width"
  >
    <Card class="wizard rel" :height="height">
      <Toolbar
        class="structure"
        style="top:0;"
        color="secondary"
        dark
      >

        <h3 style="max-width:80%;" v-html="title"></h3>

        <v-spacer/>

        <Btn color="white" width="20" height="20" fab light @click="show = !show">
          <v-icon small>$close</v-icon>
        </Btn>

      </Toolbar>
      <v-stepper
        class="structure"
        style="top:52px;bottom:53px"
        v-model="step"
      >
        <v-stepper-header class="rel" style="z-index:1">
          <v-stepper-step
            step="1"
            edit-icon="$complete"
            :editable="valid[1]"
            :complete="valid[1]"
          >
            Campaña
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            step="2"
            edit-icon="$complete"
            :editable="valid[2]"
            :complete="valid[2]"
          >
            Fecha
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            step="3"
            edit-icon="$complete"
            :editable="valid[3]"
            :complete="valid[3]"
          >
            Centros
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items
          class="structure"
          style="top:72px;bottom:0"
        >
          <v-stepper-content step="1">
            <v-row class="pt-8" justify="center" no-gutters>
              <div
                class="text-center secondary--text"
                style="width:100%;max-width:360px"
              >

                <p class="headline">
                  ¿Para que campaña?
                </p>

                <DField
                  v-model="campaign"
                  class="mt-6"
                  :exclude="excludedCampaign"
                  :finded-items="campaigns"
                  service="Campaigns"
                  method="all"
                  label="Campaña"
                  field="autocomplete"
                  field-style="1"
                  item-text="title"
                  item-value="id"
                  hide-details dense all
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <span>{{ item.id }}. {{ item.title }}</span>
                    </v-list-item>
                  </template>
                </DField>
              </div>

            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row class="pt-8" justify="center" no-gutters>
              <div class="fill-width" style="max-width:400px">

                <p class="text-center headline secondary--text mb-12">
                  ¿Cual es la fecha de inicio?
                </p>

                <DField
                  v-model="date"
                  label="Fecha de inicio"
                  class="mt-6"
                  field="date"
                  field-style="1"
                  hide-details
                  dense
                />
              </div>

            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-row class="pt-8" justify="center" no-gutters>
              <div class="fill-width" style="max-width:720px">

                <p class="text-center headline secondary--text mb-12">
                  ¿Para que centros son los fichajes?
                </p>

                <CentersSelector
                  v-model="places"
                  class="grey lighten-4 mt-12 pa-6"
                  :campaign-id="campaignId"
                  :params="placeParams"
                  title="Seleccionar"
                />
              </div>

            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <Toolbar
        class="structure"
        style="bottom:0"
        border-top
      >

        <Btn class="mr-4" color="white" @click="show = !show">
          Cancelar
        </Btn>

        <v-spacer/>

        <Btn :disabled="!valid[step]" color="primary" @click="next">
          {{ step >= 3 ? 'Crear fichajes' : 'Siguiente' }}
        </Btn>

      </Toolbar>
    </Card>
  </v-dialog>
</template>

<script>
import CentersSelector from '@/components/forms/Centers/selector';
import { direction } from '@/utils';
import { mapState } from 'vuex';

function yesterday() {
  return Date.now() - 24 * 60 * 60 * 1000;
}

export default {
  components: { CentersSelector },
  props: {
    value: null,
    width: {
      type: [ Number, String ],
      default: '90vw'
    },
    height: {
      type: [ Number, String ],
      default: '90vh'
    },
    title: {
      type: String,
      default: 'Crear fichajes'
    }
  },
  data() {
    return {
      show: !!this.value,
      step: 1,
      campaign: null,
      places: [],
      date: yesterday(),
      excludedCampaign: [
        { status: -1 },
        { status: 0 }
      ]
    }
  },
  computed: {
    ...mapState([ 'campaigns' ]),
    valid() {
      return {
        '1': this.campaignId != null,
        '2': this.step >= 2,
        '3': !!(this.places && this.places.length)
      }
    },
    campaignId() {
      return ( this.campaign && this.campaign.id ) || null;
    },
    placeParams() {
      return {
        filters: [
          { field: 'onlyUsers', booleanValue: true },
          { field: 'validDate', doubleValue: this.date }
        ]
      };
    }
  },
  watch: {
    value( value ) {
      this.show = !!value;
    },
    show( value ) {
      this.$emit( 'input', value );
      if ( ! value ) {
        this.step = 1;
        this.campaign = parseInt( this.$route.params.id ) || null;
        this.places = [];
        this.date = yesterday();
      }
    },
    campaign() {
      this.places = [];
    },
    date( value ) {
      if ( ! value ) {
        this.$nextTick(() => this.date = yesterday());
      }
    }
  },
  methods: {
    direction,
    next() {
      this.step++;
      if ( this.step > 3 ) {
        this.$emit( 'save', {
          campaign: this.campaignId,
          places: this.places.map( p => p.id ),
          date: this.date
        });
        this.show = false;
      }
    }
  }
}
</script>

<style>
.wizard .structure {
  position: absolute;
  left: 0;
  right: 0;
}
.wizard .v-stepper__content {
  overflow: auto;
  height: 100%;
}
</style>


//export const BASE_URL = process.env.NODE_ENV == 'production' ? 'https://gestion-pre.agno.me:8443/api_2201' : 'http://localhost:8081/pre';
export const BASE_URL = process.env.NODE_ENV == "production" ? "https://gestion.grupo-ot.com/api_2201" : "http://localhost:8081/local";

export const WEBSOCKET_URL = BASE_URL + "/websocket";
export const DEBUG = process.env.NODE_ENV != "production";
export const OAUTH_OPTIONS = {
  clientId: "web",
  clientSecret: "Al564Bs@!",
  accessTokenUri: `${BASE_URL}/oauth/token`,
  authorizationUri: `${BASE_URL}/oauth/token`,
  scopes: ["read"],
  //redirectUri: 'http://localhost:8081/auth/callback',
};

export const ERROR_AUTHENTICATION = -2;
export const ERROR_NOT_VALIDATED = -3;
export const ERROR_USER_EXISTS = -4;
export const ERROR_USER_NOT_EXISTS = -5;
export const ERROR_USER_BANNED = -6;
export const ERROR_CLIENT_NOT_EXISTS = -7;
export const ERROR_CODE_NOT_EXISTS = -8;
export const ERROR_CODE_INVALIDATED = -9;
export const ERROR_DATA_INVALID = -10;
export const ERROR_ITEM_NOT_EXISTS = -11;
export const ERROR_FIELDS_NULL = -12;

export const COST_HOUR = 8.50;
export const HOLIDAY_COST_HOUR = 9.50;
export const EXTRA_COST_DAY = 12.50;

export const KpiLevels = {
  0: 'Mínimo',
  1: 'Medio',
  2: 'Alto'
};

export const KpiTypes = {
  0: 'Unidades',
  1: 'Facturación',
  2: 'Productividad'
};

export const SurveyType = {
  SALES: 0,
  GENERAL: 1,
};

export const SurveyQuestionType = {
  OPEN: 0,
  CLOSED: 1,
  MULTIPLE: 2,
  DECIMAL: 3,
  INTEGER: 4,
  EVALUATION: 5,
  PICTURE: 6,
  BARCODE: 7,
};

export const SurveyQuestionKPIType = {
  NONE: -1,
  STOCK: 0,
  SALES: 1,
  PVPR: 2,
};

export const Status = {
  ERROR: -99,
  REMOVED: -1,
  PUBLISHED: 1,
  UNPUBLISHED: 0,
};

export const Device = {
  ANDROID: 0,
  IOS: 1,
};

export const CampaignType = {
  REPOSITOR: 0,
  PROMOTOR: 1,
  GPV: 2,
};

export const CampaignStatus = {
  DELETED: -1,
  ARCHIVED: 2,
  DRAFT: 0,
  ACTIVE: 1,
};

export const Option = {
  DISABLED: 0,
  ENABLED: 1,
};

export const CampaignActionType = {
  PICTURES: 0,
  SURVEY: 1,
  SALES: 2,
  FOLLOW: 3,
};

export const CampaignActionFrequency = {
  DAYLY: 0,
  WEEKLY: 1,
  MONTHLY: 2,
  PUNTUAL: 3,
  PUNTUAL_UNIQUE: 4,
};

export const CampaignTask = {
  REMOVED: -1,
  UNDONE: 0,
  DONE: 1,
  VALIDATED: 2,
};

export const Issue = {
  INCIDENCE: 0,
  ALARM: 1,
};

export const IssueStatus = {
  //EL ESTADO 2 Y 3 SERAN ELIMINADOS
  CREATED: 0,
  TRAMITED: 1,
  MORE_INFO: 2,
  RESOLUTION: 3,
  CLOSED: 4,
  REMOVED: -1,
};

export const IssueIncidence = {
  ILLNESS: 0,
  PERSONAL_ISSUE: 1,
  DELAY: 2,
  STOCK_BREAK: 3,
  RETURN: 4,
  PROMOTION_ISSUE: 5,
  FURNITURE_BREAK: 6,
  OTHER: 100,
};

export const IssueAlarm = {
  OTHER_BRAND_PROMOTER: 900,
  OTHER_BRAND_PLACE: 901,
  OTHER_BRAND_PROMOTION: 902,
  OTHERS: 903,
};

export const TimeLog = {
  STOP: 0,
  START: 1,
};

export const TimeTable = {
  WEEKLY: 0,
  BIWEEKLY: 1,
  MONTHLY: 2,
  BIMONTHLY: 3,
};

export const UserRole = {
  SUPERADMIN: 99,
  WORKER: 0,
  KAM: 1,
  MANAGER: 2,
  CLIENT: 3,
  RRHH: 4,
  INTERNAL: 100,
};
export const RouteTag = {
  STATS: 0,
  TASKS: 1,
  PICTURES: 2,
  GEOLOCATION: 3,
  ISSUES: 4,
  WORKERS: 5
};

export const Task = {
  Type: {
    INTERAL: 0,
    EXTERNAL: 1,
  },
  Priority: {
    LOW: 0,
    NORMAL: 1,
    HIGH: 2,
    URGENT: 3,
  },
  Status: {
    CREATED: 0,
    VALIDATED: 1,
    REASSIGNED: 2,
    CLOSED: 3,
    REMOVED: -1,
  },
  Substatus: {
    TODO: 0,
    INPROGRESS: 1,
    PENDING_INFO: 2,
    DONE: 3,
  },
  Filter: {
    ID: "ids",
    SOURCE: "from",
    TARGET: "to",
    STATUS: "status",
    SUBSTATUS: "status",
    TITLE: "title",
    DESC: "description",
  },
};

export const AbsenceType = {
  NONE: 0,
  HOLIDAY_WORKING: 1,
  HOLIDAY_NO_WORKING: 2,
  USER_HOLIDAYS: 3,
  LIBRANZA: 4,
  PLACE: 5,
  INCOMPLETE: 6,
  OTHER: 7,
};

export const AbsenceSubtype = {
  NONE: 0,
  NO_CUBIERTA_PDT_SELECT: 1,
  NO_CUBIERTA_PDT_CLIENTE: 2,
  NO_CUBIERTA_PDT_PDV: 3,
  BAJA_VOLUNTARIA: 4,
  DESPIDO: 5,
  IMPREVISTO_PERSONAL: 6,
  ENFERMEDAD: 7,
  DOCUMENTATION: 8,
  RETRASO: 9,
};

export function getAbsenceSubtypes(type) {
  switch (type) {
    case AbsenceType.PLACE:
      return [
        AbsenceSubtype.NO_CUBIERTA_PDT_SELECT,
        AbsenceSubtype.NO_CUBIERTA_PDT_CLIENTE,
        AbsenceSubtype.NO_CUBIERTA_PDT_PDV,
        AbsenceSubtype.BAJA_VOLUNTARIA,
        AbsenceSubtype.DESPIDO,
        AbsenceSubtype.IMPREVISTO_PERSONAL,
        AbsenceSubtype.ENFERMEDAD,
        AbsenceSubtype.DOCUMENTATION,
      ];
      case AbsenceType.INCOMPLETE:
        return [
          AbsenceSubtype.BAJA_VOLUNTARIA,
          AbsenceSubtype.DESPIDO,
          AbsenceSubtype.IMPREVISTO_PERSONAL,
          AbsenceSubtype.ENFERMEDAD,
          AbsenceSubtype.DOCUMENTATION,
          AbsenceSubtype.RETRASO,
        ];
  }

  return [];
}

export function errorMessage(error, msg) {
  switch (error) {
    case "unauthorized":
      msg = "El usuario no existe.";
      break;
    case "invalid_grant":
      msg = "La contraseña es incorrecta.";
      break;
    case ERROR_AUTHENTICATION:
      msg = "Usuario o contraseña incorrectos.";
      break;
    case ERROR_NOT_VALIDATED:
      msg =
        "El usuario todavía no ha sido validado. Por favor, revise su email.";
      break;
    case ERROR_USER_EXISTS:
      msg =
        "El alias introducido ya existe en la base de datos. Por favor, introduzca un alias único por usuario.";
      break;
    case ERROR_USER_NOT_EXISTS:
      msg = "El usuario no existe.";
      break;
    case ERROR_USER_BANNED:
      msg =
        "Su usuario ha sido baneado. Por favor, contacte con nosotros en caso de error.";
      break;
    //case ERROR_NO_RESULTS:
    //  msg = "No se han hallado resultados.";
    //break;
    //case ERROR_PAGING:
    //  msg = "No existen más elementos.";
    //break;
    //case ERROR_NO_ADMIN:
    //  msg = "No dispone de servicios para ejecutar su consulta";
    //break;
    //case ERROR_CODE_INCORRECT:
    //  msg = "No se ha podido enviar el email de recuperación. Compruebe que sus datos son correctos.";
    //break;
    default:
      msg = msg || "Hubo un error en el servidor. Inténtelo de nuevo.";
  }

  return msg;
}

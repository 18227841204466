<template>
  <DForm v-bind="$attrs" v-on="$listeners">

    <DField
      key="alias"
      field="text"
      field-style="1"
      label="Alias"
      class="mb-6"
      :rules="rules"
      dense
    />

    <DField
      key="file"
      field="file"
      field-style="1"
      label="Archivo"
      :rules="rules"
      dense
    />

  </DForm>
</template>

<script>
  export default {
    data() {
      return {
        rules: [ v => !!v || 'El campo es requerido.' ]
      }
    }
  }
</script>

<template>
  <DSteps
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    :add="add"
    :readonly="readonly"
    title="Preguntas"
    edit-button
  >
    <template v-slot:header="{ item, open, index, active, input }">
      <DField
        v-bind="question"
        :value="item.question"
        :readonly="!active"
        @input="input( item, 'question', $event )"
        @click="open( index )"
      />
    </template>
    <template v-slot="{ item, index, input }">

      <DField
        v-bind="comments"
        :value="item.comments"
        @input="input( item, 'comments', $event )"
        class="mb-4"
      />

      <DField
        :value="item.type"
        v-bind="type"
        @input="input( item, 'type', $event )"
        class="mb-4"
      />

      <DField
        :value="item.kpiType"
        v-bind="kpiType"
        v-if="surveyType == CampaignActionType.SALES && isKpiType(type)"
        @input="input( item, 'kpiType', $event )"
        class="mb-4"
      />

      <Replies
        v-if="item.type === 1 || item.type === 2"
        :value="item.replies"
        :readonly="readonly"
        @input="input( item, 'replies', $event )"
      />

      <v-checkbox
        v-bind="checkbox"
        label="Opcional"
        :input-value="item.optional"
        @change="input( item, 'optional', $event )"
      />

      <template v-if="index">

        <v-checkbox
          v-bind="checkbox"
          label="Depende de la respuesta"
          :input-value="!!item.idParent"
          @change="input( item, 'idParent', $event )"
        />

        <div class="mt-4" v-if="item.idParent">

          <DField
            field="select"
            field-style="1"
            label="Pregunta padre"
            item-text="question"
            item-value="id"
            :value="item.idParent"
            :items="parentItems( index )"
            :readonly="readonly"
            @input="input( item, 'idParent', $event )"
            clearable
            dense
          />

          <v-row
            v-if="questions[ item.idParent ] && questions[ item.idParent ].replies.length"
            class="mt-2"
            align="center"
            no-gutters
          >

            <v-btn-toggle
              color="primary"
              class="mr-2"
              :value="item.parentType || 0"
              :readonly="readonly"
              @change="input( item, 'parentType', $event )"
              mandatory
              dense
            >
              <Btn color="white">Igual a:</Btn>
              <Btn color="white">Diferente a:</Btn>
            </v-btn-toggle>

            <DField
              field="select"
              field-style="1"
              label="Respuesta"
              item-text="reply"
              item-value="id"
              :value="item.idParentReply"
              :items="questions[ item.idParent ].replies"
              :readonly="readonly"
              @input="input( item, 'idParentReply', $event )"
              clearable
              dense
            />

          </v-row>
        </div>
      </template>
    </template>
  </DSteps>
</template>

<script>

  import Replies from './replies';
  import {
    SurveyQuestionType,
    SurveyQuestionKPIType,
    CampaignActionType
  } from '@/utils/constants';

  const types = [
    { value: SurveyQuestionType.OPEN, text: 'Abierta' },
    { value: SurveyQuestionType.CLOSED, text: 'Cerrada' },
    { value: SurveyQuestionType.MULTIPLE, text: 'Multiple' },
    { value: SurveyQuestionType.INTEGER, text: 'Númerica' },
    { value: SurveyQuestionType.DECIMAL, text: 'Decimal' },
    { value: SurveyQuestionType.PICTURE, text: 'Fotografía' },
    { value: SurveyQuestionType.BARCODE, text: 'Código de barras' }
  ];

  const kpiTypes = [
    { value: SurveyQuestionKPIType.NONE,  text: 'Ninguno' },
    { value: SurveyQuestionKPIType.STOCK, text: 'Stock'   },
    { value: SurveyQuestionKPIType.SALES, text: 'Ventas'  },
    { value: SurveyQuestionKPIType.PVPR, text: 'PVPR'  }
  ];

  export default {
    components: { Replies },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      surveyType: Number,
      readonly: Boolean
    },
    data () {
      return {
        CampaignActionType,
        model: this.value || [],
        uid: -1
      }
    },
    watch: {
      value( value ) {
        this.model = value || [];
      }
    },
    computed: {
      question() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Pregunta',
          autocomplete: 'none',
          dense: true,
          readonly: this.readonly
        }
      },
      comments() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Comentarios',
          autocomplete: 'none',
          dense: true,
          readonly: this.readonly
        }
      },
      checkbox() {
        return {
          color: 'primary',
          ripple: false,
          hideDetails: true,
          readonly: this.readonly
        }
      },
      type() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Tipo',
          items: types,
          dense: true,
          readonly: this.readonly
        }
      },
      kpiType() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Tipo de dato relacionado (kpi)',
          items: kpiTypes,
          dense: true,
          readonly: this.readonly
        }
      },
      questions() {
        const questions = {};
        this.model.forEach( q => questions[ q.id ] = q );
        return questions;
      }
    },
    methods: {
      add( item, model ) {
        model.push({ id: this.uid--, type: 0 });
        return model;
      },
      parentItems( index ) {
        return this.model
          .slice( 0, index )
          .filter( q => [
            SurveyQuestionType.CLOSED,
            SurveyQuestionType.MULTIPLE
          ].indexOf( q.type ) !== -1 );
      },
      isKpiType( type ) {
        switch ( type ) {
          case SurveyQuestionType.CLOSED:
          case SurveyQuestionType.MULTIPLE:
          case SurveyQuestionType.BARCODE:
          case SurveyQuestionType.PICTURE:
            return false;
        }
        return true;
      }
    }
  }

</script>

<template>
  <DField
    v-bind="$attrs"
    v-on="$listeners"
    :key="$vnode.key"
    :mypopup="{ initial, height: 100, title: 'Nuevo sector' }"
    service="Sectors"
    field="autocomplete"
    item-text="name"
    item-value="id"
  >
    <template v-slot:form="{ value, listeners }">
      <SectorForm
        :value="value"
        v-on="listeners"
      />
    </template>
  </DField>
</template>

<script>

  import SectorForm from '@/components/forms/Sectors/single.vue';

  export default {
    components: { SectorForm },
    methods: {
      initial( name ) {
        return { name };
      }
    }
  }
</script>

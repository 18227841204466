<template>
  <Table
    ref="table"
    service="Products"
    show-actions="edit,duplicate,delete,import,export,new"
    :messages="messages"
    :headers="headers"
    :mypopup="mypopup"
    :sanitize="sanitize"
    :is-loading="loading"
  >

    <template v-slot:item.picture="{ value }">
      <Card class="mask" width="90" height="90" border>
        <v-img v-if="value" :src="value.url" max-width="90" height="90"/>
      </Card>
    </template>

    <template v-slot:form="{ value, listeners }">
      <ProductForm :value="value" v-on="listeners" hide-actions />
    </template>

    <template v-slot:actions-left="{ selected }">
      <Btn v-if="selected.length === 1" @click="merge( selected )" color="tertiary" text>
        <v-icon class="mr-2">mdi-set-left-center</v-icon>
        Unir con
      </Btn>
    </template>

    <template v-slot:append-outer="{ selected }">
      <GallerySelector
        v-model="mergeDialog"
        :exclude="selected"
        textButton="Unir"
        @apply="applyMerge"
      />
    </template>

  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import ProductForm from '@/components/forms/Products/single.vue';
  import GallerySelector from '@/components/forms/Products/gallery.vue';

  export default {
    components: { Table, ProductForm, GallerySelector },
    name: 'Products',
    data() {
      return {
        loading: false,
        mergeDialog: false,
        mergeProduct: null,
        messages: { item: 'Producto/Productos' },
        headers: [
          {
            text: 'Imagen',  value: 'picture',  width: 100,
            align: 'center', filterable: false, sorteable: false
          },
          { text: 'Nombre', value: 'name' },
          {
            text: 'Marca',  value: 'brand', display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: { service: 'Brands', itemText: 'name', itemValue: 'id' }
            }
          },
          { text: 'Categoría',    value: 'category' },
          { text: 'Subcategoría', value: 'subcategory' },
          { text: 'Descripción',  value: 'description' },
          {
            text: 'PVP', value: 'pvp',
            filter: { type: 'number', attrs: { min: 0, hideControls: true }}
          },
          { text: 'EAN', value: 'ean', width: 200 },
        ],
        mypopup: {
          height: 640
        }
      }
    },
    methods: {
      sanitize( m ) {
        return {
          id: m.id,
          name: m.name,
          description: m.description,
          pvp: m.pvp,
          ean: m.ean,
          category: m.category,
          subcategory: m.subcategory,
          picId: m.picture ? m.picture.id : null,
          brand: m.brand ? m.brand.id : null,
          status: 1
        };
      },
      merge( selected ) {
        this.mergeDialog = true;
        this.mergeProduct = selected[0];
      },
      applyMerge( selected ) {

        if ( ! selected.length ) return;

        const target = 'Products/merge';
        const params = {
          targetId: this.mergeProduct.id,
          ids: selected.map( a => a.id )
        };

        this.loading = true;
        this.$store
          .dispatch( 'api', { target, params })
          .then(() => {
            this.loading = false;
            this.$nextTick( this.reload );
          })
          .catch( err => {
            this.loading = false;
            this.$store.dispatch( 'console/error', err )
          });
      },
      reload() {
        const { table } = this.$refs;
        if ( table ) table.reload();
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Productos' );
    }
  }
</script>

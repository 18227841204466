<template>
  <div class="items-selector">
    <DSteps
      v-model="model"
      :add="open"
      :title="title"
      :expandible="expandible"
      @input="$emit( 'input', $event )"
      headline
    >
      <template v-slot:prepend>
        <slot name="prepend"/>
      </template>
      <template v-slot:header="scope">
        <v-chip
          :color="chipColor( scope.item, scope.active )"
          :dark="scope.active"
          :ripple="false"
          @click="scope.open( scope.index )"
        >
          <slot name="item" v-bind="scope"/>
        </v-chip>
      </template>
    </DSteps>

    <TableDialog
      v-model="dialog"
      :exclude="excludeItems"
      :service="service"
      :method="method"
      :title="title"
      :headers="headers"
      :params="computedParams"
      @apply="apply"
    />

  </div>
</template>

<script>

  import TableDialog from './dialog';
  import { Params } from '@/utils';

  export default {
    components: { TableDialog },
    props: {
      value: Array,
      expandible: {
        type: Boolean,
        default: true
      },
      service: String,
      method: String,
      title: String,
      headers: Array,
      params: Object,
      excludeByFilters: Boolean,
      exclude: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        model: this.value || [],
        dialog: false
      }
    },
    computed: {
      excludeItems() {
        if ( this.excludeByFilters ) return this.exclude;
        return this.exclude.concat( this.model );
      },
      computedParams() {
        if ( ! this.excludeByFilters ) return this.params;
        return Params(
          this.params || {},
          { filters: [
            {
              field: 'excluded',
              listValue: this.model.map( a => a.id )
            }
          ]}
        );
      }
    },
    watch: {
      value( value ) {
        this.model = value || [];
      }
    },
    methods: {
      chipColor( item, active ) {
        if ( active ) return 'tertiary';
        return null;
      },
      open( item, model ) {
        this.dialog = true;
        return model;
      },
      apply( value ) {
        this.model = this.model.concat( value );
        this.$emit( 'input', this.model );
      }
    }
  }
</script>

<style>
  .place-chip > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

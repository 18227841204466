<template>
  <div class="block">
    <slot />
    <div v-if="block" class="elm-block" :style="style"></div>
  </div>
</template>

<script>
  export default {
    name: 'Block',
    props: [ 'block', 'color', 'opacity' ],
    computed: {
      style() {
        return {
          backgroundColor: this.color || '#fff',
          opacity: this.opacity != null ? this.opacity : .5
        }
      }
    }
  };
</script>

<style scoped>
  .block {
    position: relative;
  }
  .elm-block {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
  }
</style>

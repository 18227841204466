<template>
  <DForm ref="form" :value="value" v-on="{ ...$listeners, input }">
    <v-row class="pa-2" no-gutters>
      <v-col cols="12" md="6" class="pa-2">
        <h3 class="headline mb-6">General</h3>

        <DField v-for="(field, key) in userInfo" :key="key" v-bind="field" />

        <h3 class="headline mt-8 mb-7">Dirección</h3>

        <DField
          v-for="(field, key) in userDirection"
          :key="key"
          v-bind="field"
        />

      </v-col>
      <v-col cols="12" md="6" class="pa-2">
        <h3 class="headline mb-6">Cuenta</h3>

        <DField key="password" v-bind="userAccount.password" />

        <DField key="role" v-bind="userAccount.role" />

        <DField
          v-show="isWorker"
          v-bind="checkbox"
          key="locationVerification"
          label="Verificar localización"
          class="mb-6"
        />

        <DField
          key="client"
          v-show="isClient"
          v-bind="userAccount.client"
          class="mb-6"
        />

        <h3 v-if="isClient" class="headline mt-8 mb-7">
          Módulos activos
        </h3>

        <DField
          class="active-modules-checkbox"
          v-show="isClient"
          v-for="( label, key ) in activeModules"
          v-bind="checkbox"
          :key="key"
          :label="label"
        />

        <h3 class="headline mt-8 mb-7">
          Información de contacto
        </h3>

        <DField
          v-for="(field, key) in userContact"
          :key="key"
          v-bind="field"
        />

      </v-col>
    </v-row>
  </DForm>
</template>

<script>
import { UserRole } from "@/utils/constants";

const type = {
  text: {
    field: "text",
    fieldStyle: 1,
    labelSpace: 90,
    class: "mb-6",
    dense: true,
  },
  select: {
    field: "select",
    fieldStyle: 1,
    labelSpace: 90,
    class: "mb-6",
    dense: true,
  },
  autocomplete: {
    field: "autocomplete",
    fieldStyle: 1,
    labelSpace: 90,
    class: "mb-6",
    dense: true,
  },
};
const NO_CHARS = /[ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñ]/;

export default {
  props: {
    value: Object,
    readonly: Boolean,
    dense: Boolean,
    hide: [ String, Array ],
  },
  data() {
    return {
      currentRole: -1,
      UserRole,
    };
  },
  watch: {
    isWorker( value ) {
      if ( this.$route.params.id === 'new' && this.$refs.form ) {
        this.$refs.form.set( 'locationVerification', value );
      }
    }
  },
  methods: {
    input(value) {
      if (value) {
        this.currentRole = value.role;
      }
      if (this.$listeners.input)
        this.$listeners.input(value);
    },
    getRoles() {
      var roles = [];
      if ( this.$store.state.isAdmin ) {
        roles.push({ value: UserRole.SUPERADMIN, text: "Master" });
        roles.push({ value: UserRole.KAM, text: "KAM" });
        roles.push({ value: UserRole.MANAGER, text: "Gestor" });
        roles.push({ value: UserRole.RRHH, text: "RRHH" });
        roles.push({ value: UserRole.CLIENT, text: "Cliente" });
      }
      if ( this.$store.state.isKAM ) {
        roles.push({ value: UserRole.CLIENT, text: "Cliente" });
      }
      if (this.$store.state.isRRHH) {
        roles.push({ value: UserRole.RRHH, text: "RRHH" });
      }
      roles.push({ value: UserRole.WORKER, text: "Trabajador" });
      return roles;
    },
  },
  computed: {
    isClient() {
      return this.currentRole === UserRole.CLIENT;
    },
    isWorker() {
      return this.currentRole === UserRole.WORKER;
    },
    userInfo() {
      return {
        alias: {
          ...type.text,
          label: "alias",
          autocomplete: 'username',
          rules: [
            v => !!v || "Debe introducir el alias del usuario",
            v => !NO_CHARS.test(v) || 'El alias no debe contener acentos ni "ñ"'
          ],
        },
        name: {
          ...type.text,
          label: "Nombre",
          autocomplete: 'name',
          rules: [(v) => !!v || "Debe introducir el nombre del usuario"],
        },
        surname: { ...type.text, label: "Apellido", autocomplete: 'lastName' },
        nif: { ...type.text, label: "NIF" },
        ss: { ...type.text, label: "Otros datos" },
      };
    },
    userContact() {
      return {
        email: {
          ...type.text,
          label: "Email",
          autocomplete: "email",
          rules: [(v) => !!v || "Debe introducir el email del usuario"],
        },
        phone: { ...type.text, label: "Teléfono" },
        /*imei: { ...type.text, label: "IMEI" },*/
      };
    },
    userDirection() {
      return {
        street: { ...type.text, label: "Calle" },
        city: { ...type.text, label: "Ciudad" },
        province: { ...type.text, label: "Provincia" },
        postalCode: { ...type.text, label: "CP", autocomplete: 'postal-code' },
      };
    },
    userAccount() {
      return {
        password: {
          ...type.text,
          field: "password",
          label: "Contraseña",
          autocomplete: "new-password",
        },
        role: {
          ...type.select,
          label: "Rol",
          items: this.getRoles(),
          rules: [
            (v) =>
              this.getRoles().filter((a) => a.value == v).length > 0 ||
              "Debe introducir un rol de usuario válido",
          ],
        },
        client: {
          ...type.autocomplete,
          label: "Cliente",
          service: "Clients",
          itemText: "name",
          itemValue: "id",
        },
      };
    },
    checkbox() {
      return {
        field: 'checkbox',
        fieldStyle: 1,
        dense: true
      }
    },
    activeModules() {
      return {
        statsEnabled: 'Estadísticas',
        tasksEnabled: 'Registros',
        picturesEnabled: 'Fotografías',
        geolocationEnabled: 'Geoposición',
        issuesEnabled: 'Incidencias',
        workersEnabled: 'Fichajes',
        calendarEnabled: 'Calendario'
      };
    }
  },
};
</script>

<style>
.active-modules-checkbox {
  display: inline-block;
  width: 140px;
}
</style>

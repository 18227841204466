import Axios from 'axios';
import { BASE_URL } from '../utils/constants';

const RESOURCE_NAME = `${BASE_URL}/upload`;

export default {
  uploadPicture(token, formData) {

    const url = `${RESOURCE_NAME}/image`;
    var headers = this.createHeader(token);

    return Axios.post(url, formData, { headers: headers })
  },
  uploadFile(token, formData) {

    const url = `${RESOURCE_NAME}/file`;
    var headers = this.createHeader(token);

    return Axios.post(url, formData, { headers: headers })
  },

  createHeader(token) {
    var headers = {
      "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + token
    };
    return headers;
  }
};
import Axios from 'axios';
import { BASE_URL } from '../utils/constants';

export default class APIService {

  constructor( path ) {
    this.resourceName = BASE_URL + path;
    this.cancelSource = Axios.CancelToken.source();
  }

  headers( token, contentType ) {
    const header = { 'Content-Type': contentType };
    if ( token ) header.Authorization = 'Bearer ' + token;
    return header;
  }

  action( type, path, data, config, token, contentType ) {

    const url = this.resourceName + path;
    const options = {
      ...config,
      headers: this.headers( token, contentType || 'application/json' ),
      cancelToken: this.cancelSource.token
    };

    if ( type === 'delete' ) {
      options.data = data;
      data = null;
    }

    if ( data ) {
      data = data instanceof FormData ? data : JSON.stringify( data );
      return Axios[ type ]( url, data, options );
    }

    return Axios[ type ]( url, options );
  }

  get( token, params, options ) {
    params = params || {};
    return this.action( 'get', `/get?id=${ params.id }`, null, options, token );
  }

  set( token, params, options ) {
    return this.action( 'post', '/set', params, options, token );
  }

  remove( token, params, options ) {
    return this.action( 'delete', '/remove', params, options, token );
  }

  removeAll( token, params, options ) {
    return this.action( 'delete', '/remove/ids', params, options, token );
  }

  all( token, params, options ) {
    return this.action( 'post', '/page', params, options, token );
  }

  list( token, params, options ) {
    return this.action( 'post', '/list', params, options, token );
  }

  duplicate( token, params, options ) {
    return this.action( 'post', '/duplicate', params, options, token );
  }

  export( token, params, options ) {
    return this.action( 'post', '/export', params, options, token );
  }

  import( token, params, options ) {
    return this.action( 'post', '/import', params, options, token, 'multipart/form-data' );
  }

  cancel( message ) {
    return this.cancelSource.cancel( message );
  }
}

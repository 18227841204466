import APIService from './class';

class Campaigns extends APIService {
  constructor() {
    super( '/campaign' );
  }
  pictures( token, params, options ) {
    return this.action( 'post', '/pictures/page', params, options, token );
  }
  timetables( token, params, options ) {
    return this.action( 'get', '/calendar/get', null, { ...options, params }, token );
  }
  positions( token, params, options ) {
    return this.action( 'get', '/position/get', null, { ...options, params }, token );
  }
  issueCategories( token, params, options ) {
    return this.action( 'get', '/issueCategories/get', null, { ...options, params }, token );
  }
  setIssueCategories( token, params, options ) {
    return this.action( 'post', '/issueCategories/set', params, options, token );
  }
  centers( token, params, options ) {
    return this.action( 'post', '/centers/page', params, options, token );
  }
  exportCenters( token, params, options ) {
    return this.action( 'post', '/centers/export', params, options, token );
  }
  importCenters( token, params, options ) {
    return this.action( 'post', '/centers/import', params, options, token, 'multipart/form-data');
  }
  setCenter( token, params, options ) {
    return this.action( 'post', '/center/assign', params, options, token );
  }
  removeCenter( token, params, options ) {
    return this.action( 'post', '/center/unassign', params, options, token );
  }
  duplicateCenter( token, params, options ) {
    return this.action( 'post', '/center/duplicate', params, options, token );
  }
  assignCenters( token, params, options ) {
    return this.action( 'post', '/center/assign/all', params, options, token );
  }
  download( token, params, options ) {
    return this.action( 'post', '/pictures/download', params, options, token );
  }
  removePicture( token, params, options ) {
    return this.action( 'post', '/pictures/remove', params, options, token );
  }
  removeData( token, params, options ) {
    return this.action( 'delete', '/remove/allData', params, options, token );
  }
  credentials( token, params, options ) {
    return this.action( 'post', '/center/credentials', params, options, token );
  }
}

export default new Campaigns();

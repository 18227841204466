import APIService from './class';

class Payroll extends APIService {
  constructor() {
    super( '/payroll' );
  }
  exportLines( token, params, options ) {
    return this.action( 'post', '/export/lines', params, options, token );
  }
}

export default new Payroll();

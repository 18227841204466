<template>
  <v-row class="flex-column flex-nowrap" :key="null" no-gutters>
    <Toolbar v-if="!readonly || title" border-bottom>

      <h3 v-if="title" class="headline mr-1">
        {{ title }}
      </h3>

      <Btn v-if="!readonly" color="tertiary" class="mr-1" @click="onAdd" small icon>
        <v-icon>$plus</v-icon>
      </Btn>

      <v-spacer/>

      <Btn v-if="multiple && !readonly" class="mr-1" :color="isSelectedAll ? 'primary' : 'tertiary'" @click="toggle" dark>
        {{ isSelectedAll ? 'Deseleccionar' : 'Seleccionar todo' }}
      </Btn>

      <Btn v-if="!readonly" class="mr-1" :color="multiple ? 'primary' : 'tertiary'" @click="multiple = !multiple" text>
        Selección multiple
      </Btn>

      <Btn v-if="editable && !multiple && !readonly" :color="edit ? 'primary' : 'tertiary'" @click="edit = !edit" icon>
        <v-icon>$edit</v-icon>
      </Btn>

    </Toolbar>

    <slot name="prepend"/>

    <div :class="full ? 'grow rel' : null">
      <Body
        v-model="selected"
        :class="full ? 'abs full' : null"
        :items="model"
        :editable="editable && edit"
        :multiple="multiple"
        :readonly="readonly"
        :full="full"
        :cols="cols"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
        :list="list"
        :details-width="detailsWidth"
        :item-image="itemImage"
        @save="$emit( 'input', value )"
      >
        <template v-slot:info="scope">
          <slot name="info" v-bind="scope"/>
        </template>
        <template v-slot:list-item-content="scope">
          <slot name="list-item-content" v-bind="scope"/>
        </template>
      </Body>
    </div>
    <Toolbar v-if="!readonly" border-top>

      <v-spacer/>

      <Btn @click="remove" color="tertiary" text>
        <v-icon class="mr-2">$delete</v-icon>
        Quitar
      </Btn>

    </Toolbar>

    <GallerySelector
      v-model="gallery"
      :exclude="model"
      @apply="addProduct"
    />

  </v-row>
</template>

<script>

  import Body from './body.vue';
  import GallerySelector from './gallery.vue';
  import { toArray, Any } from '@/utils';

  export default {
    components: { Body, GallerySelector },
    props: {
      value: Array,
      selection: Any,
      title: String,
      itemImage: String,
      readonly: Boolean,
      editable: {
        type: Boolean,
        default: true
      },
      full: Boolean,
      list: Boolean,
      xl: [ Number, String ],
      lg: [ Number, String ],
      md: [ Number, String ],
      sm: [ Number, String ],
      cols: [ Number, String ],
      add: Function,
      detailsWidth: [ Number, String ]
    },
    data() {
      return {
        model: toArray( this.value ),
        loading: false,
        selected: this.selection,
        multiple: false,
        edit: true,
        gallery: false
      }
    },
    computed: {
      isSelectedAll() {
        if ( ! this.multiple || ! Array.isArray( this.selected )) return false;
        return this.selected.length === this.model.length;
      }
    },
    watch: {
      value( value ) {
        var items = [...toArray(value)];

        items = items.sort(( a, b ) => {
          if ( a.name > b.name ) return 1;
          else if ( b.name > a.name ) return -1;
          else if ( a.description > b.description ) return 1;
          else if ( b.description > a.description ) return -1;
          return 0;
        });
        this.model = items;
      },
      readonly( value ) {
        this.multiple = false;
        if ( value ) this.edit = true;
      },
      selected( value ) {
        this.$emit( 'selection', value );
      },
      selection( value ) {
        this.selected = value;
      }
    },
    methods: {
      toggle() {
        if ( this.isSelectedAll ) this.selected = [];
        else this.selected = this.model.slice();
      },
      onAdd() {
        if ( this.add ) this.add();
        else this.gallery = true;
      },
      addProduct( addeds ) {
        this.model = this.model.concat( addeds );
        this.$emit( 'input', this.model );
      },
      remove() {
        const items = toArray( this.selected );
        this.model = this.model.filter( item => items.indexOf( item ) === -1 );
        this.selected = this.multiple ? [] : null;
        this.$emit( 'input', this.model );
      }
    }
  }
</script>

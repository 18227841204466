import Vue from 'vue';

const requireModule = require.context( '.', true, /\.vue$/);
const modules = {};

requireModule.keys().forEach( fileName => {
  if ( fileName === './index.js' ) return;

  // Replace ./ and .js
  const name = fileName.replace( /(\.\/|\.vue)/g, '' ).toLowerCase();

  if ( !modules[name] ) {
    modules[name] = Vue.component( name, requireModule( fileName ).default )
  }
});

export default modules;

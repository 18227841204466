<template>
  <DForm>
    <ItemsSelector
      :value="value"
      :expandible="expandible"
      :title="title"
      :headers="headers"
      service="Places"
      exclude-by-filters
      @input="$emit('input',$event)"
    >
      <template v-slot:item="{ item }">
        <span class="single-line">
          {{ direction( item, true ) }}
        </span>
      </template>
    </ItemsSelector>
  </DForm>
</template>

<script>

  import ItemsSelector from '@/components/base/ListSelector';
  import { direction } from '@/utils';

  export default {
    components: { ItemsSelector },
    props: {
      value: Array,
      expandible: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: 'Centros'
      }
    },
    data() {
      return {
        headers: [
          { text: 'Nombre', value: 'name' },
          {
            text: 'Cadena', value: 'channel', display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Channels',
                itemText: 'name',
                itemValue: 'id'
              }
            }
          },
          { text: 'Área', value: 'area' },
          { text: 'Dirección', value: 'street' },
          { text: 'Ciudad', value: 'city', width: 150 },
          { text: 'Provincia', value: 'province', width: 150 },
          { text: 'CP', value: 'postalCode', width: 75 },
          { text: 'Telefono', value: 'phone', width: 100 }
        ]
      }
    },
    methods: {
      direction
    }
  }
</script>

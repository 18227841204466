<template>
  <DField
    v-bind="$attrs"
    v-on="$listeners"
    :key="$vnode.key"
    :mypopup="mypopup"
    service="Places"
    method="all"
    field="autocomplete"
    item-text="name"
    item-value="id"
  >

    <template v-slot:form="{ value, listeners }">
      <PlaceForm :value="value" v-on="listeners"/>
    </template>

  </DField>
</template>

<script>

  import PlaceForm from '@/components/forms/Places/single.vue';

  export default {
    components: { PlaceForm },
    computed: {
      mypopup() {
        return {
          width: 1024,
          height: 490,
          title: n => n ? 'Nuevo centro' : 'Editar centro',
          initial: name => ({ name }),
        }
      }
    }
  }
</script>

<template>
  <Table
    service="Sectors"
    :messages="messages"
    :headers="headers"
    :mypopup="mypopup"
    show-actions
  >
    <template v-slot:form="{ value, listeners }">

      <Sector class="pa-4" :value="value" v-on="listeners"/>

    </template>
  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import Sector from '@/components/forms/Sectors/single.vue';

  export default {
    components: { Table, Sector },
    data() {
      return {
        messages: {item:"Sector/Sectores"},
        headers: [
          { text: 'Nombre', value: 'name' }
        ],
        mypopup: {
          height: 200,
          title: n => n ? 'Nuevo sector' : 'Editar sector',
          initial: name => ({ name })
        }
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Sectores' );
    }
  }
</script>

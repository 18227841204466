<template>
  <v-tooltip bottom>

    <template v-slot:activator="{ on }">
      <Btn
        v-bind="$attrs"
        v-on="{ ...$listeners, ...on, click }"
        :color="color"
        :icon="false"
        :fab="false"
        :text="false"
        outlined
      >
        <span>
          {{ text }}
        </span>
      </Btn>
    </template>

    <span>
      {{ value }}
    </span>

  </v-tooltip>
</template>

<script>

  const URLRegex = /^https?:\/\/\S+/;
  const DEFAULT_COLORS = {
    default: 'border',
    jpg:     'primary',
    jpeg:    'primary',
    png:     'purpple',
    gif:     'tertiary',
    pdf:     'secondary',
    pwt:     'red'
  };

  export default {
    props: {
      value: String,
      colors: Object,
      target: { type: String, default: '_blanck' }
    },
    computed: {
      format() {
        return ! this.value ? '' : this.value
          .replace( /^.*(\..+)$/, '$1' )
          .replace( /[\s.]/g, '' );
      },
      text() {
        return this.format
          ? ( '.' + this.format )
          : '';
      },
      color() {

        if ( this.$attrs.color ) return this.$attrs.color;

        const { format } = this;
        if ( format ) {
          const colors = { ...DEFAULT_COLORS, ...this.colors };
          if ( colors[format] ) return colors[format];
          else if ( colors.default ) return colors.default;
        }

        return null;
      }
    },
    methods: {
      click( event ) {
        if (( ! this.$listeners.click || this.$listeners.click( event ) !== false ) && this.isValid()) {
            window.open( this.value, this.target );
        }
      },
      isValid() {
        return URLRegex.test( this.value ) && this.format;
      }
    }
  }
</script>

<style>

</style>

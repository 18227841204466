<template>
  <div id="login" class="d-flex justify-center align-center fill-height">
    <Card width="460" color="transparent" elevation="0" class="mx-4 pa-4">
      <div :style="{ paddingLeft: labelSpace + 'px' }">

        <img class="mb-4" width="240" :src="logoImage" />

      </div>
      <Block :block="loading" :opacity="0">
        <v-form ref="form" v-model="valid">

          <DField
            field="text"
            name="username"
            label="Usuario"
            class="mb-6"
            v-model.trim="user.value"
            :label-space="labelSpace"
            :rules="user.rules"
            @keypress.enter="submit"
            autocomplete="username"
            fieldStyle="2"
            required
          />

          <DField
            field="password"
            :label-space="labelSpace"
            name="password"
            label="Contraseña"
            v-model.trim="password.value"
            fieldStyle="2"
            @keypress.enter="submit"
            :rules="password.rules"
            required
          />

          <div class="mt-9" :style="{ paddingLeft: labelSpace + 'px' }">
            <Btn color="tertiary" :loading="loading" @click="submit" block dark>
              Entrar
            </Btn>
            <Btn color="tertiary" class="pa-0 mt-6" @click="resetPassword" small text>
              Olvidadé mi contraseña
            </Btn>
          </div>

        </v-form>
      </Block>
    </Card>
  </div>
</template>

<script>

  import logoImage from '@/assets/logo.png';

  export default {
    name: 'login',
    data: () => ({
      valid: true,
      loading: false,
      labelSpace: 120,
      logoImage,
      user: {
        value: '',
        rules: [ v => !!v || 'El campo es requirido.' ]
      },
      password: {
        value: '',
        rules: [ v => !!v || 'El campo es requirido.' ]
      }
    }),
    methods: {
      validate() {
        return this.$refs.form.validate();
      },
      submit() {
        if ( this.validate() ) {

          this.loading = true;
          this.$store
            .dispatch( 'login', { name: this.user.value, password: this.password.value })
            .then(() => this.$router.push('/'))
            .catch( err => this.$store.dispatch( 'console/error', err ))
            .finally(() => this.loading = false );
        }
      },
      resetPassword() {

      },
      sendResetPasswordEmail() {

      }
    },
    async mounted() {
      if ( this.$store.state.userID > 0 ) {
        this.$router.push('/');
      }
    }
  };
</script>

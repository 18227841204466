<template>
  <DForm :value="value" v-on="{ ...$listeners, input }">
    <DField :value="dateText" v-bind="date" class="mb-6" />
    <DField key="tag" v-bind="tag" class="mb-6" />
    <DField key="logMessage" v-bind="logMessage" class="mb-6" />
  </DForm>
</template>

<script>
import moment from "moment";

function datetime(time) {
  if (time) return moment(time).format("YYYY/MM/DD HH:mm:ss");
  return "-";
}

export default {
  props: {
    value: Object,
    readonly: Boolean,
  },
  data: () => ({
    tag: {
      field: "text",
      fieldStyle: 1,
      label: "Usuario",
      dense: true,
      rules: [(v) => !!v || "El campo es requerido."],
    },
    logMessage: {
      field: "text",
      fieldStyle: 1,
      label: "Acción",
      dense: true,
      rules: [(v) => !!v || "El campo es requerido."],
    },
  }),
  watch: {
  },
  methods: {
    input( value ) {
        this.model = value;
        if ( this.$listeners.input )
          this.$listeners.input( value );
      },
  },
  computed: {
    date() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Fecha",
          dense: true,
          readonly: true
        };
      },
    dateText() {
        return datetime(this.value.date);
      },
  },
};
</script>

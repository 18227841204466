<template>
  <DForm
    ref="form"
    class="pa-4"
    :value="value"
    v-on="$listeners"
  >

    <h3 class="mb-4">{{ headerTitle }}</h3>

    <DField key="title" class="mb-4" v-bind="title"/>

    <DField key="description" class="mb-4" v-bind="description"/>

    <DField key="questions">
      <template v-slot="{ value, on }">
        <Questions
          v-on="on"
          :value="value"
          :surveyType="surveyType"
          :readonly="readonly"
        />
      </template>
    </DField>

  </DForm>
</template>

<script>

  import Questions from './questions';
  import { CampaignActionType } from '@/utils/constants';

  const types = {
    [ CampaignActionType.SURVEY ]: 'cualitativo',
    [ CampaignActionType.SALES ]: 'cuantitativo'
  };

  export default {
    components: { Questions },
    props: {
      value: Object,
      type: Number,
      readonly: Boolean
    },
    computed: {
      headerTitle() {
        return [ 'Cuestionario', this.surveyTypeText ].join(' ');
      },
      title() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Título',
          dense: true,
          readonly: this.readonly
        }
      },
      description() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Descripción',
          dense: true,
          readonly: this.readonly
        }
      },
      surveyType() {
        return this.type || this.value.type || 0;
      },
      surveyTypeText() {
        return types[ this.surveyType ]
      }
    }
  }
</script>

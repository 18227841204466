<template>
  <div id="submenu">
    <div v-if="items && items.length" class="submenu-top">
      <Tabs
        classic
        class="grow"
        :items="items"
        :value="top"
        @input="input( $event, 0 )"
      />
    </div>
    <v-row class="submenu-bottom" align="center" no-gutters>

      <Btn v-if="!hideBackButton && parent.name" @click="$router.back()" active-class="transparent" color="secondary" text>
        <v-icon class="mr-1">$prev</v-icon>
        Atrás
      </Btn>

      <Tabs
        v-if="subitems.length"
        class="fill-width"
        :key="id"
        :items="subitems"
        :value="bottom"
        @input="input( $event, 1 )"
      />

      <v-spacer/>

      <slot name="middle"/>

      <div
        v-if="showCampaignSelector"
        class="mr-2"
        style="flex: 1 1 300px; max-width: 300px;"
      >
        <DField
          :exclude="exclude"
          :finded-items="campaigns"
          :disable-search="!!campaigns"
          @input="onSelectCampaign"
          @fetch="onFetchCampaigns"
          service="Campaigns"
          method="all"
          label="Ir a la campaña..."
          field="autocomplete"
          field-style="1"
          item-text="title"
          item-value="id"
          dense
          all
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <span class="border--text">{{ itemState(item) }}</span>
              <span class="ml-1">{{ item.id }}. {{ item.title }}</span>
            </v-list-item>
          </template>
        </DField>
      </div>

      <slot/>

    </v-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      value: [ Number, Array ],
      items: Array,
      hideBackButton: Boolean
    },
    data: () => ({
      top: -1,
      bottom: -1,
      subitems: [],
      id: 0
    }),
    computed: {
      ...mapState([ 'campaigns' ]),
      parent() {
        const path = this.$route.path.split('/');
        return {
          name: path[ path.length - 2 ],
          path: path.slice( 0, -1 ).join('/')
        };
      },
      exclude() {
        return { status: -1 };
      },
      showCampaignSelector() {
        const routes = this.$route.matched;
        for ( var i = 0; i < routes.length; i++ ) {
          if ( routes[i].path.indexOf( '/campaigns/:id' ) !== -1 ) return true;
        }
        return false;
      }
    },
    watch: {
      value( value ) {
        this.id++;
        this.tabs( value );
      },
      items() {
        this.tabs( this.value );
      }
    },
    methods: {
      getSubitems() {
        try { return this.items[this.top].children || []; }
        catch(e) { return []; }
      },
      tabs( value ) {
        if ( ! Array.isArray( value )) value = [ value, 0 ];
        this.top = this.tab( value[0] || 0, this.items );
        this.subitems = this.getSubitems();
        this.bottom = this.tab( value[1], this.subitems );
      },
      tab( value, items ) {
        if ( typeof value === 'number' ) {
          return Math.max( 0, Math.min(
            ( items || [] ).length - 1,
            value || 0
          ));
        }
      },
      input( value, index ) {
        var section = [ this.top, this.bottom ];
        section[index] = value;
        this.tabs( section );
        this.$emit( 'input', [ this.top, this.bottom ]);
      },
      onSelectCampaign( value ) {
        if(value){
        const url = this.$route.path.replace( /(campaigns\/)\d+/, '$1' + value.id );
        this.$router.push( url );
        }
      },
      onFetchCampaigns( items ) {
        console.log(items);
        //this.$store.commit( 'setCampaigns', items );
      },
      itemState( item ) {
        switch ( item.status ) {
          case 0:  return '(Borrador) -';
          case -1: return '(Archivada) -';
          default: return '';
        }
      }
    },
    beforeMount() {
      this.tabs( this.value );
    }
  };
</script>

<style scoped>
  .submenu-bottom {
    height: 52px;
    flex-wrap: nowrap;
    background-color: #fff;
    border-bottom: 1px solid var(--v-border-base);
    border-top: 1px solid var(--v-border-lighten1);
    margin-top: -1px;
  }
</style>

import APIService from './class';

class Users extends APIService {
  constructor() {
    super( '/user' );
  }
  current( token, options ) {
    return this.action( 'get', '/info', null, options, token );
  }
  setTime( token, params, options ) {
    return this.action( 'post', '/time/set', params, options, token );
  }
  removeTime( token, params, options ) {
    return this.action( 'delete', '/time/remove', params, options, token );
  }

  updateTimeLog( token, params, options ) {
    return this.action( 'post', '/time/update/log', params, options, token );
  }

  updatePriorityTime( token, params, options ) {
    return this.action( 'post', '/time/priority/change', params, options, token );
  }
}

export default new Users();

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import plugins from './plugins';
import components from './components/base';

Vue.config.productionTip = false;

// Global Components
Vue.mixin({ components });

new Vue({
  ...plugins,
  router,
  store,
  i18n,
//  maps,
  render: h => h( App )
}).$mount('#app');

<template>
  <div>
    <v-row class="mb-6" align="end" no-gutters>
      <div class="grow">
        <h3 class="title">{{ title }}</h3>
        <p v-if="description" class="body-2 border--text mb-0">
          {{ description }}
        </p>
      </div>
      <DField
        v-if="isSales || isFollow"
        v-model="search"
        field="text"
        field-style="1"
        append-icon="mdi-magnify"
        placeholder="Filtrar..."
        style="max-width: 300px"
        dense
      />
    </v-row>

    <Card v-if="isSales" border>
      <Products
        v-if="!readonly || filteredItems.length"
        :value="filteredItems"
        :selection="selected"
        :readonly="readonly"
        cols="2"
        sm="3"
        md="4"
        lg="6"
        xl="9"
        @input="onChange"
        @selection="selected = $event"
      >
        <template v-slot:info="{ product }">
          <div :key="key" class="fill-height pa-2">
            <Question
              v-for="q in questions"
              v-show="showQuestion(q, product && product.id)"
              class="mb-6"
              :key="q.id"
              :value="questionValue(q, product && product.id)"
              :question="q"
              :pics="questionPictures(q, product && product.id)"
              :readonly="readonly"
              full-width
              dense
              @input="product && input(q, product.id, $event)"
            />
          </div>
        </template>
      </Products>
      <div v-else style="color: gray" class="title pa-4">
        No hay productos para mostrar
      </div>
    </Card>

    <template v-else-if="isFollow">
      <Card border>
        <Products
          v-if="!readonly || filteredItems.length"
          :value="filteredItems"
          :selection="selected"
          :readonly="readonly"
          :add="onAdd"
          details-width="720"
          @input="onChange"
          list
        >
          <template v-slot:info="{ product }">
            <div :key="key" class="fill-height pa-2">
              <Question
                v-for="q in questions"
                v-show="showQuestion(q, product && product.id)"
                class="mb-6"
                :key="q.id"
                :value="questionValue(q, product && product.id)"
                :question="q"
                :pics="questionPictures(q, product && product.id)"
                :readonly="readonly"
                full-width
                dense
                @input="product && input(q, product.id, $event)"
              />
            </div>
          </template>
          <template v-slot:list-item-content="{ item, active }">
            <v-list-item-content>
              <v-list-item-title
                :class="{ 'error--text': isElementEmpty(item) }"
                v-text="item.name"
              />
            </v-list-item-content>
          </template>
        </Products>
        <div v-else style="color: gray" class="title pa-4">
          No hay elementos para mostrar
        </div>
      </Card>

      <v-dialog v-model="dialog" max-width="600">
        <Card>
          <Toolbar color="secondary" dark> Añadir elemento </Toolbar>
          <div class="pa-4">
            <DField v-model="category" v-bind="listCategories" class="mb-6" />
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>

            <Btn color="transparent" @click="dialog = false"> Cancelar </Btn>

            <Btn color="primary" @click="addElement"> Añadir </Btn>
          </v-card-actions>
        </Card>
      </v-dialog>
    </template>

    <div v-else :key="key">
      <Question
        v-for="q in questions"
        v-show="showQuestion(q)"
        class="mb-8"
        :key="q.id"
        :value="questionValue(q)"
        :pics="questionPictures(q)"
        :question="q"
        :task="value"
        :readonly="readonly"
        @input="input(q, $event)"
      />
    </div>
  </div>
</template>

<script>
import Products from "@/components/forms/Products/selector.vue";
import Question from "./question.vue";
import { get, set, toArray } from "@/utils";
import { CampaignActionType, SurveyQuestionType } from "@/utils/constants";

export default {
  components: { Products, Question },
  props: {
    value: Object,
    readonly: Boolean,
  },
  data: function () {
    return {
      response: null,
      selected: null,
      search: "",
      removeds: [],
      items: [],
      key: 0,
      dialog: false,
      category: null,
      emptyElements: false,
    };
  },
  watch: {
    key: "hasEmptyElements",
    elements: "hasEmptyElements",
    value() {
      this.refresh();
    },
    products(value) {
      const { selected } = this;
      if (selected) {
        if (Array.isArray(selected)) {
          this.selected = selected
            .map((p) => value.find((a) => a.id === p.id))
            .filter((a) => a);
        } else {
          this.selected = value.find((a) => a.id === selected.id);
        }
      }
    },
    emptyElements(value) {
      this.$emit("empty:elements", value);
    },
  },
  computed: {
    title() {
      return get(this.value, "action.survey.survey.title");
    },
    description() {
      return get(this.value, "evaluation.survey.description");
    },
    isSales() {
      return get(this.value, "action.type") === CampaignActionType.SALES;
    },
    isFollow() {
      return get(this.value, "action.type") === CampaignActionType.FOLLOW;
    },
    questions() {
      return (get(this.value, "action.survey.survey.questions") || [])
        .filter((q) => q.status !== -1)
        .sort((a, b) => a.position - b.position);
    },
    products() {
      if (!this.isSales) return [];

      let products = this.readonly
        ? get(this.value, "products")
        : get(this.value, "place.products");

      return (products || [])
        .filter((item) => !this.removeds.includes(item))
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });
    },
    elements() {
      if (!this.isFollow) return [];
      let items = this.items;
      items = items.filter((item) => !this.removeds.includes(item));

      return items.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
    },
    filteredItems() {
      let items = this.isFollow ? this.elements : this.products;

      if (this.search) {
        const search = this.search.toLowerCase();
        items = (items || []).filter((p) => {
          return (p.name || "").toLowerCase().indexOf(search) > -1;
        });
      }

      return items;
    },
    listCategories() {
      return {
        field: "select",
        fieldStyle: 1,
        label: "Categoría",
        dense: true,
        rules: [(v) => v != null || "El campo es requerido."],
        items: (get(this.value, "action.categories") || "")
          .split(",")
          .map((c) => c.trim())
          .filter(Boolean),
      };
    },
  },
  methods: {
    onAdd() {
      this.dialog = true;
    },
    addElement() {
      if (this.category == null) return;
      const sumItems = this.items.filter((c) =>
        c.name.startsWith(this.category)
      );
      const name = [this.category, sumItems.length + 1].join(" ");
      this.items.push({ id: name, name, status: 1 });
      this.dialog = false;
    },
    isElementEmpty(item) {
      const questions = Object.values(this.response);
      for (var i = 0; i < questions.length; i++) {
        if (item.id in questions[i]) return false;
      }
      return true;
    },
    hasEmptyElements() {
      this.emptyElements = !!this.elements
        .map(this.isElementEmpty)
        .filter(Boolean).length;
    },
    questionValue(question, id) {
      const path = (id ? [question.id, id] : [question.id])
        .concat(["value"])
        .join(".");
      const value = get(this.response, path);
      //console.log("Value: " + value);
      if (question.type === SurveyQuestionType.MULTIPLE) return toArray(value);
      //if ( question.type === SurveyQuestionType.PICTURE ) return toArray( value );
      return value;
    },
    questionPictures(question, id) {
      const path = (id ? [question.id, id] : [question.id])
        .concat(["pictures"])
        .join(".");
      const r = get(this.response, path) || [];
      //console.log("Response: " + JSON.stringify(this.response));
      //console.log("Pictures: " + r);

      return r;
    },
    showQuestion(question, id) {
      if (question.idParent) {
        var res = (get(this.value, "response.changed") || this.response)[
          question.idParent
        ];
        if (id) res = get(res, id);
        var replies = get(res, "value") || [];

        if (!Array.isArray(replies)) {
          replies = Array.of(replies);
        }

        if (question.idParentReply) {
          if (question.parentType) {
            // 1 - Diferente a

            return res && replies.indexOf(question.idParentReply) === -1;
          } else {
            // 0 - Igual a

            return res && replies.indexOf(question.idParentReply) !== -1;
          }
        } else if (!res) {
          return false;
        }
      }
      return true;
    },
    prepareResponse() {
      this.removeds = [];
      const response = {};
      const replies = get(this.value, "response.replies") || [];
      var question;

      replies.forEach((reply) => {
        if (
          (question = this.questions.find((q) => q.id === reply.idQuestion))
        ) {
          if (question.type === 1) reply.value = reply.replies[0];
          else if (question.type === 2) reply.value = reply.replies;
          else reply.value = reply.response;

          if (reply.idProduct != null)
            set(response, [reply.idQuestion, reply.idProduct].join("."), reply);
          else if (reply.itemName)
            set(response, [reply.idQuestion, reply.itemName].join("."), reply);
          else response[reply.idQuestion] = reply;
        }
      });

      return response;
    },
    prepareItems() {
      const items = [];

      Object.values(this.response).forEach((q) => {
        Object.keys(q).forEach((a) => {
          if (!items.find((c) => c.id === a)) {
            items.push({
              id: a,
              name: a,
              status: 1,
            });
          }
        });
      });

      return items;
    },
    input(question, b, c) {
      if (question.type == SurveyQuestionType.PICTURE) {
        var response = "";
        if (arguments.length > 2) {
          if (Array.isArray(c)) {
            response = c.map((i) => i.id).join(",");
          } else {
            response = c;
          }
          //console.log(c);
          //console.log(response);
          set(this.response, [question.id, b, "value"].join("."), response);
          set(this.response, [question.id, b, "pictures"].join("."), c);
        } else {
          response = b.map((i) => i.id).join(",");
          //console.log(b);
          //console.log(response);
          set(this.response, [question.id, "value"].join("."), response);
          set(this.response, [question.id, "pictures"].join("."), b);
        }
      } else {
        if (arguments.length > 2)
          set(this.response, [question.id, b, "value"].join("."), c);
        else set(this.response, [question.id, "value"].join("."), b);
      }
      this.emitResponse();
    },
    onChange(items) {
      this.removeds = [];
      this.filteredItems.forEach((item) => {
        if (!items.find((p) => p.id === item.id)) {
          this.removeds.push(item);
        }
      });
      this.emitResponse();
    },
    emitResponse() {
      var response, q, p;
      const isID = /^\d+$/;

      if (!this.isSales && !this.isFollow) response = this.response;
      else {
        response = {};
        for (q in this.response) {
          for (p in this.response[q]) {
            isID.test(p) && (p = parseInt(p));
            if (!this.removeds.find((a) => a.id === p))
              set(response, [q, p].join("."), this.response[q][p]);
          }
        }
      }

      set(this.value, "response.changed", response);
      this.$emit("input", this.value);
      this.key++;
    },
    refresh() {
      this.response = this.prepareResponse();
      this.items = this.prepareItems();
      this.key++;
    },
  },
  beforeMount() {
    this.response = this.prepareResponse();
    this.items = this.prepareItems();
  },
};
</script>

<template>
  <v-dialog v-model="show" fullscreen>
    <div class="fill-height pa-4">
      <TableBody
        ref="table"
        class="fill-height"
        :title="title"
        :service="service"
        :method="method"
        :params="params"
        :headers="headers"
        :exclude="exclude"
        @close="show = false"
        @input="apply"
      />
    </div>
  </v-dialog>
</template>

<script>

  import TableBody from './table';

  export default {
    components: { TableBody },
    props: {
      value: null,
      service: String,
      method: String,
      title: String,
      exclude: Array,
      headers: Array,
      params: Object
    },
    data() {
      return {
        show: !!this.value
      }
    },
    watch: {
      value( value ) {
        this.show = !!value;
      },
      show( value ) {
        if ( ! value ) this.$refs.table.clean();
        this.$emit( 'input', value );
      }
    },
    methods: {
      apply( value ) {
        this.show = false;
        this.$emit( 'apply', value );
      }
    }
  }
</script>

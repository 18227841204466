<template>
  <div class="fill-width">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :persistent="persistent"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <DFieldText
          field="text"
          :value="output"
          :label="label"
          :label-space="labelSpace"
          :field-style="fieldStyle"
          :small="small"
          :dense="dense"
          :rules="compRules"
          :clearable="clearable && !readonly"
          @click:clear="reset(true)"
          v-on="readonly ? null : on"
          readonly
        />
      </template>

      <Toolbar color="secondary" dark>
        ¿Como quieres filtrar el horario?
      </Toolbar>

      <v-sheet class="pa-3" color="white">

        <DField
          v-model="mode"
          :items="modes"
          field="select"
          field-style="1"
          class="mx-1 mb-4"
          dense
        />

        <v-row align="center" no-gutters>

          <DField
            v-if="mode < 2"
            v-model="method"
            :items="methods"
            style="max-width:180px"
            class="mx-1"
            field="select"
            field-style="1"
            dense
          />

          <span v-else class="mx-1">
            de
          </span>

          <DField
            :value="start"
            :style="mode != 2 ? 'max-width:117px' : null"
            @input="onInput( 'start', $event )"
            placeholder="00:00"
            field="time"
            field-style="1"
            class="mx-1"
            dense
          />

          <template v-if="mode === 2 || method === 3">

            <span class="mx-1">
              {{ mode === 2 ? 'a' : 'y las' }}
            </span>

            <DField
              :value="end"
              @input="onInput( 'end', $event )"
              class="mx-1"
              placeholder="00:00"
              field="time"
              field-style="1"
              dense
            />

          </template>
        </v-row>

        <DField
          v-model="middleJourney"
          field="checkbox"
          label="Incluir franjas laborales (jornada partida)"
          class="mt-2"
        />

      </v-sheet>

      <Toolbar border-top>
        <v-spacer></v-spacer>
        <Btn text color="primary" @click="dialog = false">Cancelar</Btn>
        <Btn color="primary" :disabled="!canSave" @click="save">OK</Btn>
      </Toolbar>

    </v-dialog>
  </div>
</template>

<script>

import DFieldText from './DFieldText.vue';
import { compare } from '@/utils';

export default {
  components: { DFieldText },
  props: {
    value: null,
    locale: { type: String, default: navigator.language },
    label: String,
    labelSpace: [ Number, String ],
    fieldStyle: [ Number, String ],
    min: [ Number, String ],
    max: [ Number, String ],
    small: Boolean,
    dense: Boolean,
    persistent: Boolean,
    readonly: Boolean,
    rules: Array,
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lazyModel: {},
      dialog: false,
      start: null,
      end: null,
      middleJourney: false,
      output: '',
      mode: 0,
      modes: [
        { value: 0, text: 'Comienza' },
        { value: 1, text: 'Termina' },
        /*{ value: 2, text: 'Comienza y termina' }*/
      ],
      method: 0,
      methods: [
        { value: 0, text: 'A las' },
        { value: 1, text: 'Hasta las' },
        { value: 2, text: 'A partir de las' },
        { value: 3, text: 'Entre las' }
      ]
    };
  },
  computed: {
    compRules() {
      if ( ! this.rules ) return [];
      return this.rules.map( f => () => f( this.model ));
    },
    canSave() {
      if ( this.method < 3 ) {
        if ( this.mode === 0 ) return !!this.start;
        if ( this.mode === 1 ) return !!this.start;
      }
      return !!( this.start && this.end );
    },
    model() {
      if ( ! this.canSave ) return undefined;
      const { mode, method, start, end, middleJourney } = this;
      const model = { mode, method, middleJourney, start, end };
      return model;
    }
  },
  watch: {
    dialog( dialog ) {
      if ( ! dialog && ! compare( this.model, this.lazyModel )) {
        this.reset();
      }
    },
    value( value, oldValue ) {
      if ( ! compare( value, oldValue )) {
        this.compute( value );
        this.save();
      }
    }
  },
  methods: {
    onInput( type, value ) {
      this[type] = ( value || '' ).length === 5 ? value : null;
    },
    setOutput() {

      if ( ! this.model ) return this.output = '';

      let result = [];
      let value;

      switch ( this.mode ) {
        case 0: result.push('»'); value = this.start; break;
        case 1: result.push('«'); value = this.start; break;
        default: result = result.concat([ this.start, '-', this.end ]);
      }

      if ( this.mode < 2 ) {
        switch ( this.method ) {
          case 0: result.push( value ); break;
          case 1: result = result.concat([ '<', value ]); break;
          case 2: result = result.concat([ '>', value ]); break;
          default: result = result.concat([ this.start, '~', this.end ]);
        }
      }

      this.middleJourney && result.push('*');
      this.output = result.join(' ');
    },
    computeString( str ) {

      str = str.trim().split(/\s+/);

      this.middleJourney = str.includes('*');

      switch ( str[0] ) {
        case '»': this.mode = 0; break;
        case '«': this.mode = 1; break;
        default: this.mode = 2;
      }

      switch ( this.mode ) {

        case 2:
          this.method = 0;
          this.start = str[0] || null;
          this.end = str[2] || null;
          break;

        default:

          if ( str[1] === '<' ) this.method = 1;
          else if ( str[1] === '>' ) this.method = 2;
          else if ( str[2] === '~' ) this.method = 3;
          else this.method = 0;

          if ( this.method === 3 ) {
            this.start = str[1] || null;
            this.end = str[3] || null;
          } else if ( this.mode ) {
            this.end = str[2] || null;
          } else {
            this.start = str[2] || null;
          }
      }
    },
    computeArray( value ) {
      this.mode = 2;
      this.method = 0;
      this.start = value[0] || null;
      this.end = value[1] || null;
      this.middleJourney = false;
    },
    computeObject( model = {}) {
      this.mode = model.mode || 0;
      this.method = model.method || 0;
      this.middleJourney = !!model.middleJourney;
      this.start = model.start || null;
      this.end = model.end || null;
    },
    compute( value ) {

      if ( ! value ) return;

      switch ( typeof value ) {

        case 'string':
          return this.computeString( value )

        case 'object':
          if ( Array.isArray( value )) {
            return this.computeArray( value );
          } else {
            return this.computeObject( value );
          }
      }
    },
    reset( save ) {
      this.computeObject();
      save && this.save();
    },
    save() {
      this.dialog = false;
      this.lazyModel = { ...this.model };
      this.setOutput();
      this.$emit( 'input', this.model );
      this.$emit( 'blur' );
    }
  },
  beforeMount() {
    this.compute( this.value );
    this.save();
  }
};
</script>

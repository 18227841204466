<template>
  <Card :class="classes" v-bind="$attrs">
    <div class="calendar-labels">
      <div class="calendar-row">
        <div v-if="view !== 'month'" class="calendar-cell calendar-time"/>
        <div class="calendar-cell" v-for="( cell, c ) in week" :key="c">
          <div v-if="view !== 'month'" class="calendar-day">

            <Btn :color="labelColor(cell) || 'transparent'" :dark="cell.today || cell.selected" @click="date = cell.date" x-small fab>
              {{ cell.day }}
            </Btn>

            <Holidays
              v-if="cell.holidays.length"
              :holidays="cell.holidays"
              position="bottom"
            />

          </div>
          <div class="label" :class="weekdayColor(cell)">
            {{ $vuetify.breakpoint.smAndUp ? cell.weekday.long : cell.weekday.short }}
          </div>
          <div class="calendar-dense-events scrollable">
            <v-sheet
              v-for="( event, index ) in filterEvents( cell.events, true )"
              :key="index"
              :color="eventColor( event[0] )"
              :class="eventStyle( event[0] )"
              v-html="eventLabel( event )"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="calendar-body">
      <template v-if="view === 'month'">
        <div class="calendar-row" v-for="( row, r ) in timetable" :key="r">
          <div
            class="calendar-cell"
            :class="{ inner: cell.inner }"
            v-for="( cell, c ) in row"
            :key="c"
          >

            <template v-if="view === 'month'">
              <div class="calendar-day">

                <Btn
                  :color="labelColor(cell) || 'transparent'"
                  :dark="cell.today || cell.selected"
                  @click="date = cell.date"
                  x-small fab
                >
                  {{ cell.day }}
                </Btn>

                <Holidays
                  v-if="cell.holidays.length"
                  :holidays="cell.holidays"
                />

              </div>
              <div v-if="cell.events.length" class="calendar-dense-events scrollable">
                <v-sheet
                  v-for="( event, index ) in cell.events"
                  :key="index"
                  :color="eventColor( event[0] )"
                  :class="eventStyle( event[0] )"
                  v-html="eventLabel( event )"
                  @click="onClickEvent( $event, { event, cell })"
                />
              </div>
              <v-hover v-else-if="!readonly && showAddButton" v-slot:default="{ hover }">
                <v-row class="layer" align="center" justify="center" no-gutters>
                  <Btn v-if="hover" color="grey" @click="onClickDay(cell)" dark icon>
                    <v-icon v-text="'$plus'"/>
                  </Btn>
                </v-row>
              </v-hover>
            </template>
          </div>
        </div>
      </template>
      <div v-else class="calendar-timing scrollable">
        <div>
          <div class="calendar-cell calendar-time">
            <div class="calendar-time-row" v-for="hour in 24" :key="hour - 1" :class="{ inner: isActive( hour - 1 ) }">
              {{ hour - 1 }}h
            </div>
          </div>
          <div class="calendar-cell" v-for="( cell, c ) in timetable[0]" :key="c">
            <div
              class="calendar-time-row"
              v-for="hour in 24" :key="hour - 1"
              :class="{ inner: isActive( hour - 1, cell ) }"
            />
            <div class="calendar-events">
              <div v-for="( events, col ) in filterEvents( cell.events )" :key="col">
                <v-sheet
                  class="calendar-event"
                  v-for="( event, index ) in events"
                  :key="index"
                  :color="eventColor( event )"
                  :style="{ top: `${event.position.top}%`, bottom: `${event.position.bottom}%` }"
                  :class="eventStyle( event )"
                  v-html="eventLabel([ event ])"
                  @click="onClickEvent( $event, { event: events, cell })"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>

  import moment from 'moment';
  import Holidays from './Holidays';
  import { upperCaseOn } from '@/utils';

  const weekdays = moment.weekdays().map(( long, value ) => {
    long = upperCaseOn( long, 0 );
    return { long, value, short: long === 'Miércoles' ? 'MX' : long[0] }
  });

  function compute( date ) {
    date = moment( date );
    if ( date.isValid() ) return date;
    return moment();
  }

  function round( value, decimals ) {
    decimals = Math.pow( 10, decimals || 0 );
    return Math.round( value * decimals ) / decimals;
  }

  function linear( value, min, max, vMin, vMax ) {
    if ( value <= min ) return vMin;
    if ( value >= max ) return vMax;
    return round( vMin + ( vMax * (( value - min )/( max - min ))), 6 );
  }

  export default {
    components: { Holidays },
    props: {
      value: String,
      now: String,
      events: Array,
      type: String,
      groupEvents: Boolean,
      showAddButton: Boolean,
      readonly: Boolean,
      holidays: {
        type: Array,
        default: () => []
      }
    },
    data: function() {

      const date  = compute( this.value );
      const today = compute( this.now );

      return {
        today: today.format('YYYY-MM-DD'),
        date: date.format('YYYY-MM-DD'),
        year: date.get('year'),
        month: date.get('month'),
        day: date.get('date'),
        weekday: today.get('day'),
        weekdays,
        minH: 7,
        maxH: 20
      }
    },
    watch: {
      value( value ) {
        const date   = compute( value );
        this.date    = date.format('YYYY-MM-DD');
        this.year    = date.get('year');
        this.month   = date.get('month');
        this.day     = date.get('date');
      },
      now( value ) {
        const today  = compute( value );
        this.today   = today.format('YYYY-MM-DD');
        this.weekday = today.get('day');
      },
      date( value ) {
        this.$emit( 'input', value );
      }
    },
    computed: {
      classes() {
        return {
          calendar: true,
          [this.view]: true,
          readonly: this.readonly
        }
      },
      view() {
        if ([ 'month', 'week', 'day' ].indexOf( this.type ) < 0 ) return 'month';
        return this.type;
      },
      week() {
        return this.timetable.find( r => r.find( c => c.date === this.date ));
      },
      compEvents() {

        const events = [];
        var start, end, month, day, allDay, d, e, i, initDay, endDay;

        ( this.events || [] ).forEach( data => {

          start = moment( data.start, 'YYYY-MM-DD HH:mm' );
          end   = moment( data.end, 'YYYY-MM-DD HH:mm' );
          month = { start: start.get('month'), end: end.get('month') };
          day   = { start: start.get('date'), end: end.get('date') };

          if ( month.start === month.end && day.start === day.end ) {

            allDay  = start.isSame( end ) || end.isSame( start.clone().endOf('day'));
            initDay = start.clone().startOf('day').valueOf();
            endDay  = start.clone().endOf('day').valueOf();

            events.push({
              data,
              date: start.format('YYYY-MM-DD'),
              allDay,
              priority: data.priority,
              position: {
                top: linear( start.valueOf(), initDay, endDay, 0, 100 ),
                bottom: 100 - linear( allDay ? endDay : end.valueOf(), initDay, endDay, 0, 100 )
              },
              time: {
                start: start.format('HH:mm'),
                end: allDay ? end.endOf('day').format('HH:mm') : end.format('HH:mm'),
                value: start.valueOf()
              }
            });

          } else {

            i = 0;
            d = start.clone();

            while ( d.isBefore( end )) {

              e = d.clone().add( 1, 'day' );
              initDay = d.clone().startOf('day').valueOf();
              endDay  = d.clone().endOf('day').valueOf();

              events.push({
                data,
                date: d.format('YYYY-MM-DD'),
                allDay: false,
                position: {
                  top: linear( i ? initDay : start.valueOf(), initDay, endDay, 0, 100 ),
                  bottom: 100 - linear( e.isAfter( end ) ? end.valueOf() : endDay, initDay, endDay, 0, 100 )
                },
                time: {
                  value: i ? d.clone().startOf('day').valueOf() : start.valueOf(),
                  start: i ? '00:00' : start.format('HH:mm'),
                  end: e.isAfter( end ) ? end.format('HH:mm') : '23:59',
                }
              });

              d = e;
              i++;
            }
          }
        });

        return events;
      },
      timetable() {

        // Prepare timetable

        const dates = {};
        const table = [];
        const start = moment( this.date, 'YYYY-MM-DD' );

        var cols, rows;
        switch ( this.view ) {
          case 'month':
            start.startOf( 'month' );
            start.subtract(( start.get('day') + 6 ) % 7, 'days' );
            cols = 7;
            rows = 6;
            break;
          case 'week':
            start.subtract(( start.get('day') + 6 ) % 7, 'days' );
            cols = 7;
            rows = 1;
            break;
          default:
            cols = rows = 1;
        }

        var date = start.clone(), row, r, c, m, w, id;
        for ( r = 0; r < rows; r++ ) {
          row = [];
          for ( c = 0; c < cols; c++ ) {

            id = date.format('YYYY-MM-DD');
            m = date.get('month');
            w = date.get('day');

            row.push(( dates[id] = {
              date: id,
              day: date.get('date'),
              month: m,
              year: date.get('year'),
              weekday: this.weekdays[w],
              selected: this.date === id,
              today: this.today === id,
              inner: this.month === m,
              events: [],
              holidays: []
            }));

            date.add( 1, 'day' );
          }
          table.push( row );
        }

        // Add events

        const filleds = {};
        this.compEvents.forEach( ev => {
          if ( dates[ev.date] ) {
            dates[ev.date].events.push([ ev ]);
            filleds[ev.date] = dates[ev.date];
          }
        });

        // Add holidays

        var hid;
        this.holidays.forEach( h => {
          hid = moment(h.date).format('YYYY-MM-DD');
          if ( dates[hid] ) dates[hid].holidays.push(h);
        });

        // Reorder events

        for ( date in filleds ) {
          filleds[date].events.sort(( a, b ) => a[0].time.value - b[0].time.value );
        }

        // Agrouping

        if ( this.groupEvents ) {
          var events, groups;
          for ( date in filleds ) {

            events = [];
            groups = {};

            filleds[date].events.forEach( ev => {
              ev = ev[0];
              if ( ev.data.id == null ) events.push( ev );
              else {
                groups[ev.data.id] = groups[ev.data.id] || [];
                groups[ev.data.id].push( ev );
                if ( events.indexOf( groups[ev.data.id] ) < 0 )
                  events.push( groups[ev.data.id] );
              }
            });

            filleds[date].events = events;
          }
        }

        return table;
      },
      weekdayLabels() {
        const weekdays = this.view !== 'day' ? this.weekdays.slice() : [ this.weekdays[ this.weekday ]];
        weekdays.push( weekdays.shift());
        return weekdays;
      }
    },
    methods: {
      weekdayColor( cell ) {
        var color;
        if ( cell.weekday.value === this.weekday ) {
          if ( this.view === 'month' ) color = 'primary';
          else color = this.labelColor( cell );
        } else {
          color = this.labelColor( cell );
        }
        return color ? `${color}--text` : null;
      },
      labelColor( cell ) {
        if ( cell.today ) return 'primary';
        if ( cell.selected ) return 'tertiary';
      },
      eventColor( event ) {
        return event.priority !== -1
          ? event.data.color || 'tertiary'
          : 'border lighten-1';
      },
      eventStyle( event ) {
        if ( ! event.priority ) return '';
        if ( event.priority === -1 ) return 'disabled';
        return 'edited';
      },
      eventLabel( event ) {
        var label = event[0].data.item.fullName;
        if ( event[0].data.item.user != null ) {
          label = event[0].data.item.user.name;
        }
        if ( ! event[0].allDay && event[0].priority !== -1 ) {
          event.forEach( ev => {
            label += ` <span>${ ev.time.start } - ${ ev.time.end }</span>`;
          });
        }
        return label;
      },
      isActive( hour, cell ) {
        const byWeekday = cell ? cell.weekday.value !== 0 && cell.weekday.value !== 6 : true;
        return ( byWeekday && hour >= this.minH && hour <= this.maxH );
      },
      filterEvents( events, allDay ) {
        allDay = allDay || false;
        return events.filter( event => event.find( e => e.allDay === allDay ));
      },
      onClickEvent( ev, data ) {
        ev.stopPropagation();
        if ( this.readonly ) return;
        this.$emit( 'click-event', data );
      },
      onClickDay(data) {
        this.$emit( 'click-day', data );
      }
    }
  }
</script>

<style>
  .calendar, .calendar-body, .calendar-row, .calendar-timing > div, .calendar-time, .calendar-events {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .calendar, .calendar-body, .calendar-time {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .calendar-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    min-height: 500px;
  }
  .calendar-row {
    height: 100%;
    max-height: 100%;
  }
  .calendar-row, .calendar-labels, .calendar-time-row {
    border-bottom: 1px solid var(--v-border-base);
  }
  .calendar-row, .calendar-cell, .calendar-events > div {
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .calendar-cell, .calendar-events > div {
    position: relative;
    width: 100%;
    max-width: 100%;
  }
  .calendar-cell {
    border-right: 1px solid var(--v-border-base);
  }
  .calendar-row:last-child,
  .calendar-cell:last-child,
  .calendar-time-row:last-child {
    border: 0;
  }
  .calendar-labels .label {
    text-align: center;
  }
  .calendar-labels .calendar-dense-events {
    text-align: left;
  }
  .calendar-day {
    text-align: center;
    padding: 2px 0;
  }
  .calendar-dense-events {
    padding: 0 1px;
  }
  .calendar-dense-events > *, .calendar-event {
    padding: 1px 2px;
    margin-bottom: 1px;
    font-size: 10px;
    color: var(--v-secondary-base);
    border-radius: 3px;
    cursor: pointer;
  }
  .calendar.readonly .calendar-dense-events > *, .calendar.readonly .calendar-event {
    cursor: default;
  }
  .calendar-dense-events > .edited, .calendar-event.edited,
  .calendar-dense-events > .disabled, .calendar-event.disabled {
    background-image: url('~@/assets/edited-event.png');
    background-repeat: repeat;
    border: 1px solid !important;
  }
  .calendar-dense-events > .disabled, .calendar-event.disabled {
    color: #888 !important;
  }
  .calendar-dense-events > *::after, .calendar-event::after {
    display: block;
    content: '';
    clear: both;
  }
  .calendar.month .calendar-dense-events {
    z-index: 0;
    padding-top: 40px;
  }
  .calendar.month .calendar-day {
    position: relative;
    z-index: 1;
  }
  .calendar-timing, .calendar-events, .calendar.month .calendar-dense-events, .calendar-event {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
  .calendar-timing > div {
    height: 100%;
    position: relative;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .calendar-time {
    width: 30px;
    max-width: 30px;
  }
  .calendar-time-row {
    text-align: center;
    height: 4.1666667%;
    min-height: 20px;
    font-size: 10px;
    padding: 2px 0;
  }
  .calendar-events > div {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  .calendar-event {
    padding: 1px;
  }
  .calendar-dense-events span {
    float: right;
    clear: both;
  }
  .calendar.month .calendar-body .calendar-cell,
  .calendar-time-row {
    background-color: rgba( 0, 0, 0, .02 );
  }
  .calendar.month .calendar-body .calendar-cell.inner,
  .calendar-time-row.inner {
    background-color: transparent;
  }
  .calendar .scrollable { overflow: auto; }
  .calendar .scrollable::-webkit-scrollbar { width: 0; }
  .calendar .scrollable::-webkit-scrollbar-track { background: transparent; }
  .calendar .scrollable::-webkit-scrollbar-button { display: none; }
  .calendar .scrollable::-webkit-scrollbar-thumb { background-color: #ccc; }
</style>

<template>
  <DForm ref="form">
    <v-row class="pa-4" align="end" no-gutters>

      <div class="pr-4 mb-2" style="flex: 0 0 300px; max-width: 300px;">
        <DField
          v-bind="start"
          :value="model.start"
          :rules="rules.start"
          @input="input( 'start', $event )"
        />
      </div>

      <div class="pr-4 mb-2" style="flex: 0 0 300px; max-width: 300px;">
        <DField
          v-bind="end"
          :value="model.end"
          :rules="rules.end"
          @input="input( 'end', $event )"
        />
      </div>

      <div class="mb-2">
        <label class="body-grupo-ot d-block mb-1">Frequencia</label>
        <v-btn-toggle :value="model.frequency" @change="input('frequency',$event)" mandatory>
          <Btn v-for="( mode, index ) in frequencies" :key="index" :value="index" active-class="primary white--text" height="38">
            {{ mode }}
          </Btn>
        </v-btn-toggle>
      </div>

    </v-row>
    <div class="pa-4">

      <Weekday
        ref="weekday"
        :value="model.times"
        :all-day="allDay"
        @input="input('times',$event)"
      />

    </div>
  </DForm>
</template>

<script>

  import Weekday from './weekday';
  const frequencies = [ 'Semanal', 'Quincenal', 'Mensual', 'Bimestral' ];

  export default {
    components: { Weekday },
    props: {
      value: Object,
      allDay: Boolean,
      hasErrors: Number
    },
    data() {
      return {
        model: { ...this.value },
        frequencies,
        rules: {
          start: [ v => !!v || 'Debes indicar la fecha de inicio.' ],
          end: [ v => !!v || 'Debes indicar la fecha de fin.' ]
        }
      }
    },
    watch: {
      value( value ) {
        this.model = { ...value };
      },
      hasErrors( error ) {
        error && this.validate();
      }
    },
    methods: {
      input( key, value ) {
        this.model[key] = value;
        this.$emit( 'input', this.model );
      },
      validate() {
        const { form, weekday } = this.$refs;
        form && form.validate();
        weekday && weekday.validate();
      },
      reset() {
        this.$refs.weekday && this.$refs.weekday.reset();
      }
    },
    computed: {
      start() {
        return {
          field: 'date',
          fieldStyle: 1,
          label: 'Fecha de inicio',
          max: this.model.end,
          dense: true
        }
      },
      end() {
        return {
          field: 'date',
          fieldStyle: 1,
          label: 'Fecha de finalización',
          min: this.model.start,
          dense: true
        }
      }
    }
  }
</script>

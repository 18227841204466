<template>
  <DForm :value="value" v-on="{ ...$listeners, input }">
    <Place key="place" v-bind="place" class="mb-6" />
    <DField key="user" v-bind="user" class="mb-6" />
    <DField key="startDate" v-bind="startDate" class="mb-6" />
    <DField key="endDate" v-bind="endDate" class="mb-6" />
    <DField key="costHour" v-bind="costHour" class="mb-6" />
    <DField key="holidayCostHour" v-bind="holidayCostHour" class="mb-6" />
    <DField key="extraCostDay" v-bind="extraCostDay" class="mb-6" />
    <DField key="paidHolidays" v-bind="paidHolidays" class="mb-6" />
    <DField key="docStatus" v-bind="docStatus" class="mb-6" />
    <DField key="signStatus" v-bind="signStatus" class="mb-6" />
    <DField key="accessStatus" v-bind="accessStatus" class="mb-6" />
    <DField key="formStatus" v-bind="formStatus" class="mb-6"/>
    <DField key="operationStatus" v-bind="operationStatus" />
  </DForm>
</template>

<script>

import Place from "@/components/forms/Places/selector";
import { userName } from '@/utils';
import { UserRole } from "@/utils/constants";
import { COST_HOUR, HOLIDAY_COST_HOUR, EXTRA_COST_DAY } from '@/utils/constants';

export default {
  components: { Place },
  props: {
    value: Object
  },
  data() {
    return {
      model: {},
      minDate: null,
      maxDate: null
    };
  },
  methods: {
    input( value ) {
      this.model = value;

      if(value.id <= 0) {
        this.model.costHour = COST_HOUR;
        this.model.holidayCostHour = HOLIDAY_COST_HOUR;
        this.model.extraCostDay = EXTRA_COST_DAY;
        this.model.paidHolidays = false;
      }

      this.minDate = this.model.startDate;
      this.maxDate = this.model.endDate;
      if ( this.$listeners.input ) this.$listeners.input( value );
    }
  },
  computed: {
    user() {
      return {
        field: "autocomplete",
        fieldStyle: 1,
        label: "Trabajador",
        itemText: 'name',
        itemValue: "id",
        display: v => userName( v, true ),
        service: "Users",
        params: { filters: [{
          field: 'role',
          listValue: [ UserRole.WORKER ]
        }]},
        dense: true
      };
    },
    place() {
      return {
        fieldStyle: 1,
        label: "Establecimiento",
        dense: true,
        readonly: this.$store.state.isRRHH,
        rules: [ v => !!v || 'El campo es requerido.' ]
      };
    },
    docStatus() {
      return {
        field: "checkbox",
        label: "¿Se dispone de la documentación del trabajador?"
      };
    },
    operationStatus() {
      return {
        field: "checkbox",
        label: "¿Ha recibido la bienvenida de operaciones?"
      };
    },
    signStatus() {
      return {
        field: "checkbox",
        label: "¿Se ha firmado el contrato con el trabajador?"
      };
    },
    accessStatus() {
      return {
        field: "checkbox",
        label: "¿Se tiene los accesos al centro para el trabajador?"
      };
    },
    formStatus() {
      return {
        field: "checkbox",
        label: "¿Ha realizado la formación?"
      };
    },
    startDate() {
      return {
        fieldStyle: 1,
        dense: true,
        field: "date",
        label: "Fecha de inicio",
        max: this.maxDate,
        rules: [ v => !!v || 'El campo es requerido.' ]
      };
    },
    endDate() {
      return {
        fieldStyle: 1,
        dense: true,
        field: "date",
        label: "Fecha de fin",
        min: this.minDate,
        rules: [ v => !!v || 'El campo es requerido.' ]
      };
    },
    costHour() {
      return {
        field: 'number',
        fieldStyle: 1,
        label: 'Coste por hora',
        min: 0,
        dense: true
      }
    },
    holidayCostHour() {
      return {
        field: 'number',
        fieldStyle: 1,
        label: 'Coste por hora festivo',
        min: 0,
        dense: true
      }
    },
    extraCostDay() {
       return {
        field: 'number',
        fieldStyle: 1,
        label: 'Plus por día',
        min: 0,
        dense: true
      }
    },
    paidHolidays() {
      return {
        field: "checkbox",
        label: "Vacaciones pagadas"
      }
    }
  }
};
</script>

<template>
  <Table
    service="Brands"
    :messages="messages"
    :headers="headers"
    :mypopup="{ height: 200 }"
    show-actions
  >

    <template v-slot:item.products="{ value }">
      <Btn class="px-0" color="tertiary" text small @click="show( value )" :title="`${ value.length } producto/s`">
        {{ value ? `Ver (${ value.length })` : '' }}
      </Btn>
    </template>

    <template v-slot:form="{ value, listeners }">
      <BrandForm class="pa-4" :value="value" v-on="listeners" />
    </template>

    <template v-slot:append-outer>
      <v-dialog v-model="dialog.show" max-width="640">
        <Card class="d-flex flex-column flex-nowrap" height="480">
          <Toolbar color="secondary">

            <span class="white--text title">
              Productos
            </span>

            <v-spacer/>

            <Btn color="white" width="20" height="20" fab @click="dialog.show = false">
              <v-icon small>$close</v-icon>
            </Btn>

          </Toolbar>
          <div class="grow rel">
            <div class="layer autoscroll">

              <DGallery
                :items="dialog.value"
                item-footer="name"
                item-image="picture.url"
                cols="3"
              />

            </div>
          </div>
        </Card>
      </v-dialog>
    </template>

  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import BrandForm from '@/components/forms/Brands/single.vue';

  export default {
    components: { Table, BrandForm },
    name: 'Brands',
    data() {
      return {
        messages: { item: 'Marca/Marcas', fem: true },
        headers: [
          { text: 'Nombre',     value: 'name' },
          { text: 'Productos',  value: 'products', width: 170, filter: {
            type: 'number',
            attrs: { min: 0, hideControls: true }
          }},
        ],
        dialog: {
          show: false
        }
      }
    },
    methods: {
      show( value ) {
        this.dialog = { show: true, value };
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Marcas' );
    }
  }
</script>

<template>
  <Page
    ref="page"
    service="Campaigns"
    :initialize="initialize"
    :loading="loading"
    hide-footer
  >
    <Submenu slot="top">
      <DField
        v-if="isClient"
        field="select"
        field-style="1"
        class="mr-2"
        item-text="campaignName"
        item-value="idCampaign"
        style="flex: 1 1 300px; max-width: 300px;"
        label="Campaña"
        :items="stats"
        :value="idCampaign"
        @input="changeCampaign"
        dense
      />
    </Submenu>

    <template v-slot>
      <v-row class="fill-height" style="background: white;" no-gutters>
        <v-col class="rel" cols="12" md="12" order="1" order-md="0">
          <div class="column-wrapper">
            <Toolbar border-bottom>
              <span class="subtitle-2">
                Configura los tipos de incidencias de la campaña
              </span>
            </Toolbar>

            <div class="grow rel">
              <div class="layer autoscroll">
                <v-treeview
                  selectable
                  open-all
                  dense
                  hoverable
                  item-key="id"
                  selection-type="independent"
                  :value="selected"
                  :items="items"
                  @input="set({ selected: $event })"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer>
      <Toolbar slot="footer" tag="footer" border-top>
        <slot name="actions">
          <template>
            <v-spacer />
            <Btn @click="onSave()" color="primary">
              Guardar
            </Btn>
          </template>
        </slot>
      </Toolbar>
    </template>
  </Page>
</template>

<script>
/* eslint-disable */

import Page from "@/components/core/Page.vue";
import Submenu from "@/components/core/Submenu.vue";
import { mapState, mapMutations } from "vuex";
import { Format } from "@/utils/time";
import { Issue } from "@/utils/constants";
import { direction, marker, get } from "@/utils";
import Colors from "@/utils/colors";
import moment from "moment";

export default {
  components: { Page, Submenu },
  data() {
    return {
      IssueType: Issue,
      initialized: false,
      loading: false
    };
  },
  watch: {
    selected( value, oldValue ) {

      if ( value.length == oldValue.length ) {
        return;
      }

      var selected = [...this.selected];
      var items = [...this.items];
      var removed = [];
      var newItems = value.filter((a) => !oldValue.includes(a));
      var removedItems = oldValue.filter((a) => !value.includes(a));

      newItems.forEach((item) => {
        items.forEach((a) => {
          if (a.id == item) {
            a.children.forEach((b) => {
              selected.push(b.id);
              b.children.forEach((c) => {
                selected.push(c.id);
              });
            });
          } else {
            a.children.forEach((a) => {
              if (a.id == item) {
                a.children.forEach((b) => {
                  selected.push(b.id);
                });
              }
            });
          }
        });
      });

      removedItems.forEach((item) => {
        items.forEach((a) => {
          if (a.id == item) {
            a.children.forEach((b) => {
              removed.push(b.id);

              b.children.forEach((c) => {
              removed.push(c.id);
              });
            });
          } else {
            a.children.forEach((a) => {
              if (a.id == item) {
                a.children.forEach((b) => {
              removed.push(b.id);
                });
              }
            });
          }
        });
      });

      selected = selected.filter(a => !removed.includes(a));

      if ( selected.length != this.selected.length ) {
        this.set({ selected });
      }
    },
  },
  computed: {
    ...mapState([ "core", "isClient", "path" ]),
    selected() {
      return this.core ? (this.core.selected||[]) : [];
    },
    campaign() {
      return this.core ? this.core.campaign : null;
    },
    types() {
      return this.core ? (this.core.types||[]) : [];
    },
    items() {
      var items = [];
      var alarms = { id: -1, name: "Alarmas", children: [] };
      var issuesBefore = {
        id: -2,
        name: "Indicencias Antes de la jornada",
        children: [],
      };
      var issuesDuring = {
        id: -3,
        name: "Indicencias Durante la jornada",
        children: [],
      };

      var parents = {};

      this.types
        .filter((a) => a.parent == null)
        .forEach((item) => {
          var node = {
            id: item.id,
            name: item.name,
            type: item.type,
            children: [],
          };
          switch (item.type) {
            case this.IssueType.ALARM:
              alarms.children.push(node);
              break;
            case this.IssueType.INCIDENCE:
              (item.working ? issuesDuring : issuesBefore).children.push(node);
              break;
          }

          parents[item.id] = node;
        });

      this.types
        .filter((a) => a.parent != null)
        .forEach((item) => {
          var node = { id: item.id, name: item.name, type: item.type };
          var parent = parents[item.parent.id];
          if (parent != null) parent.children.push(node);
        });

      items.push(alarms);
      items.push(issuesBefore);
      items.push(issuesDuring);

      return items;
    },
    idCampaign() {
      var id = parseInt(this.$route.params.id);
      if (id) return id;

      const { user } = this.$store.state;
      if (!user.client || !user.client.stats.length) return null;

      id = this.campaign;
      if (!user.client.stats.some((a) => a.idCampaign === id)) {
        id = user.client.stats[0].idCampaign;
      }

      return id;
    },
    stats() {
      const { user } = this.$store.state;
      if (this.isClient) return user.client.stats;
      return [];
    }
  },
  methods: {
    ...mapMutations([ "setView" ]),
    initialize() {
      return new Promise(( resolve, reject ) => {
        const { idCampaign } = this;
        if ( ! idCampaign ) return reject();

        this.loading = true;
        this.$store
          .dispatch( "page", {
            service: "Campaigns",
            method: "issueCategories",
            params: { idCampaign },
          })
          .then( data => {
            resolve({
              campaign: data.campaign,
              selected: data.selected,
              types: data.categories.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (b.name > a.name) return -1;
                return 0;
              })
            });
          })
          .catch( reject )
          .finally(() => (this.loading = false));
      });
    },
    set( state, section ) {
      section = section || [0,0];
      this.setView({
        path: this.path,
        state: {
          section,
          sections: { [section.join('')]: state }
        }
      });
    },
    reload() {
      const { page } = this.$refs;
      page && page.reload();
    },
    changeCampaign(stat) {

        var id = stat;
        if(stat.idCampaign !== undefined){
          id = stat.idCampaign;
        }
        if ( id === this.idCampaign ) return;
        this.set({ campaign: id });
      this.reload();
    },
    onSave() {

      const { idCampaign } = this;
      if ( !idCampaign ) return reject();

      var params = { id: idCampaign, selected : this.selected };

      this.loading = true;
      this.$store
        .dispatch( "page", {
          service: "Campaigns",
          method: "setIssueCategories",
          params: params,
        })
        .then( data => {
          this.set({
            campaign: data.campaign,
            selected: data.selected,
            types: data.categories.sort((a, b) => {
              if (a.name > b.name) return 1;
              if (b.name > a.name) return -1;
              return 0;
            })
          });
        })
        .catch( err => this.$store.dispatch( 'console/error', err ))
        .finally(() => this.loading = false );
    },
    setTitle() {

      const id = this.idCampaign;

      let title = [ 'Campañas', '', 'Configuración incidencias' ];
      this.$store.commit( 'setTitle', title );

      if ( id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

<style>
.outlined-left {
  border-left: 1px solid var(--v-border-base);
}
.column-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}
.map-label {
  list-style: none;
  padding: 0 !important;
}
.map-label li {
  margin-bottom: 8px;
}
.map-label li:last-child {
  margin-bottom: 0;
}
</style>

import { render, staticRenderFns } from "./stats2.vue?vue&type=template&id=1559ffcb&"
import script from "./stats2.vue?vue&type=script&lang=js&"
export * from "./stats2.vue?vue&type=script&lang=js&"
import style0 from "./stats2.vue?vue&type=style&index=0&id=1559ffcb&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
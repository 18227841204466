<template>
  <v-select
    v-bind="attr"
    v-on="$listeners"
    :value="model"
    :loading="loading"
    :menu-props="{ offsetY: true }"
    :items="currentItems"
    :item-text="itemText"
    :item-value="itemValue"
    item-color="tertiary"
    color="secondary"
  >
    <label v-if="attr.preLabel" slot="prepend" class="label" :style="labelStyle">
      {{ label }}
    </label>

    <template v-slot:selection="{ item, index }">
      <slot name="selection" v-bind="{ item, index }">
        <div v-if="index === 0">
          {{ selectionText( item ) }}
        </div>
        <span v-if="index === 1" class="caption ml-1">
          (+{{ value.length - 1 }})
        </span>
      </slot>
    </template>

    <template v-slot:item="scope">
      <slot name="item" v-bind="scope">
        <v-list-item class="py-0 px-3" v-bind="scope.attrs" v-on="scope.on">
          {{ selectionText( scope.item ) }}
        </v-list-item>
      </slot>
    </template>

  </v-select>
</template>

<script>

  import { Any } from '@/utils';
  import { toArray, Params, formatSize } from '@/utils';

  function excludeItems( exclude ) {
    return function( item ) {
      if ( typeof exclude !== 'object' ) return item !== exclude;
      for ( var key in exclude ) {
        if ( item[key] === exclude[key] ) return false;
      }
      return true;
    }
  }

  export default {
    props: {
      value: Any,
      service: String,
      method: {
        type: String,
        default: 'list'
      },
      params: Object,
      exclude: Any,
      label: String,
      labelSpace: [ Number, String ],
      fieldStyle: [ Number, String ],
      itemText: String,
      itemValue: String,
      display: Function,
      all: Boolean,
      disableSearch: Boolean,
      findedItems: Array,
      items: {
        type: Array,
        default: () => []
      }
    },
    data: function() {
      return {
        model: this.value,
        finded: this.findedItems || [],
        loading: false
      }
    },
    watch: {
      value( value ) {
        this.model = value;
      },
      service() {
        this.finded = this.findedItems || [];
        this.fetch().then(() => this.$emit( 'fetch', this.finded ));
      },
      params() {
        this.finded = this.findedItems || [];
        this.fetch().then(() => this.$emit( 'fetch', this.finded ));
      },
      method() {
        this.finded = this.findedItems || [];
        this.fetch().then(() => this.$emit( 'fetch', this.finded ));
      },
      disableSearch() {
        this.finded = this.findedItems || [];
        this.fetch().then(() => this.$emit( 'fetch', this.finded ));
      }
    },
    computed: {
      attr() {
        switch ( String( this.fieldStyle )) {
          case '1':
            return {
              ...this.$attrs,
              backgroundColor: 'white',
              label: this.label,
              outlined: true
            };
          case '2':
            return {
              ...this.$attrs,
              preLabel: true,
              dense: true
            };
        }
        return this.$attrs;
      },
      labelStyle() {
        const space = this.labelSpace;
        return { width: formatSize( space ) };
      },
      currentItems() {
        var items = ( this.items || [] ).slice().concat( this.finded );
        toArray( this.exclude ).forEach( exc => {
          if ( typeof exc === 'function' ) {
            items = items.filter( exc );
          } else if ( exc ) {
            items = items.filter( excludeItems( exc ))
          }
        });
        return items;
      }
    },
    methods: {
      fetch( params ) {
        return new Promise(( resolve, reject ) => {
          if ( this.service && !this.disableSearch ) {

            params = Params({ page: 1 }, params || this.params );
            const target = `${ this.service }/${ this.method }`;
            this.loading = true;

            this.$store.dispatch( 'api', { target, params }).then( response => {

              if ( response.page == null ) this.add( response );
              else {

                this.add( response.data );

                if ( !response.page ) {
                  this.loading = false;
                  return resolve();
                }

                if ( this.all && this.method === 'all' && response.page <= response.numPages ) {
                  params.page++;
                  return this.fetch( params )
                    .then( resolve )
                    .catch( reject )
                    .finally(() => this.loading = false );
                }
              }

              this.loading = false;
              resolve();

            })
            .catch( err => {
              this.loading = false;
              reject( err );
            });

          } else {
            resolve();
          }
        });
      },
      selectionText( item ) {
        if ( this.display ) return this.display( item );
        if ( item && typeof item === 'object' ) {
          if ( this.itemText ) return item[ this.itemText ];
          return item.text;
        }
        return item;
      },
      filter( data ) {
        data = toArray( data ).filter( item => item );
        return data.filter( item => ! this.isAdded( item ));
      },
      add( items ) {
        if ( items ) {
          items = this.filter( items );
          this.finded = this.finded.concat( items );
        }
      },
      isAdded( item ) {
        const field = this.itemValue;
        if ( item && typeof item === 'object' && field )
          return this.finded.some( a => a[field] === item[field]);
        return this.finded.indexOf( item ) !== -1;
      }
    },
    mounted() {
      this.fetch().then(() => this.$emit( 'fetch', this.finded ));
    }
  }
</script>

<style>
  .v-select .v-select__selections {
    flex-wrap: nowrap !important;
  }
  .v-select .v-select__selections > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>

<template>
  <div class="d-progress">
    <div v-if="title" class="d-progress--title">{{ title }}</div>
    <v-sheet :height="height" color="transparent" tile>
      <v-progress-circular
        :rotate="rotate"
        :size="size"
        :value="value"
        :width="width"
        :color="computedColor"
      >
        <p class="mb-0">
          <span class="subtitle-1">{{ value }}</span>%
        </p>
      </v-progress-circular>
    </v-sheet>
    <ul v-if="showLights" class="d-progress--info pa-2 mt-2" style="list-style:none">
      <li><v-badge :color="errorColor" inline dot/> 0% - {{ minToWarning }}%</li>
      <li><v-badge :color="warningColor" inline dot/> {{ minToWarning }}% - {{ minToSuccess }}%</li>
      <li><v-badge :color="successColor" inline dot/> {{ minToSuccess }}% - 100%</li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      color: String,
      showLights: {
        type: Boolean,
        default: true
      },
      size: {
        type: [ Number, String ],
        default: 140
      },
      width: {
        type: [ Number, String ],
        default: 14
      },
      height: {
        type: [ Number, String ],
        default: 140
      },
      rotate: {
        type: [ Number, String ],
        default: 270
      },
      value: {
        type: [ Number, String ],
        default: 0
      },
      minToSuccess: {
        type: [ Number, String ],
        default: 90
      },
      minToWarning: {
        type: [ Number, String ],
        default: 50
      },
      successColor: {
        type: String,
        default: 'success'
      },
      warningColor: {
        type: String,
        default: 'warning'
      },
      errorColor: {
        type: String,
        default: 'error'
      },
      statusColors: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      computedColor() {
        if ( this.statusColors ) {
          const value = Number(this.value);
            if ( value >= Number( this.minToSuccess )) return this.successColor;
            if ( value >= Number( this.minToWarning )) return this.warningColor;
          return this.errorColor;
        }
        return this.color;
      }
    }
  }
</script>

<style>
  .d-progress {
    flex: 0 0;
    vertical-align: top;
  }
  .d-progress--info > li {
    margin-bottom: 5px;
    font-size: 12px;
  }
  .d-progress--title {
    margin-bottom: 2rem;
    font-size: 1.2rem;
  }
  .d-progress .v-progress-circular__underlay {
    stroke: currentColor;
    opacity: .5;
  }
</style>

<template>
  <v-item-group class="dfield-modules" :class="{ readonly }" v-model="model" multiple>
    <v-fade-transition leave-absolute group>
      <v-item
        v-for="mod in items"
        v-slot="{ active, toggle }"
        class="v-item"
        :value="mod.id"
        :key="mod.id"
      >
        <div class="fill-width" :key="mod.id">
          <Card
            class="module"
            :class="validation( mod )"
            :to="readonly ? ( $route.path + mod.path ) : null"
            @click="!readonly && toggle()"
          >
            <v-simple-checkbox
              v-if="!readonly"
              :value="active"
              :ripple="false"
              color="primary"
              class="mr-3"
              @input="!readonly && toggle()"
            />

            <v-icon v-if="mod.config && readonly" small>
              {{ mod.validate === 1 ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle' }}
            </v-icon>

            <span class="subtitle-1 ml-2">
              {{ mod.title }}
            </span>

            <v-spacer/>

            <v-icon v-if="readonly" small>
              $next
            </v-icon>

          </Card>
        </div>
      </v-item>
    </v-fade-transition>

    <template v-if="readonly">
      <Card
        class="module options"
        :to="$route.path+'/targets'"
      >

        <span class="subtitle-1 ml-2">
          Objetivos
        </span>

        <v-spacer/>

        <v-icon small>
          $next
        </v-icon>

      </Card>

      <v-divider/>

      <Card
        class="module options"
        :to="$route.path+'/links'"
      >

        <span class="subtitle-1 ml-2">
          Links
        </span>

        <v-spacer/>

        <v-icon small>
          $next
        </v-icon>

      </Card>

      <v-divider/>

      <Card
        class="module options"
        :to="$route.path+'/options'"
      >

        <span class="subtitle-1 ml-2">
          Opciones de campaña
        </span>

        <v-spacer/>

        <v-icon small>
          $next
        </v-icon>

      </Card>
    </template>

  </v-item-group>
</template>

<script>

  import modules, { CampaignModule } from '@/utils/modules';

  export default {
    props: {
      value: Array,
      campaign: Object,
      readonly: Boolean,
      showModules: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        model: this.value || []
      }
    },
    computed: {
      configModules() {
        return modules.config.map(( mod, index ) => ({
          ...mod,
          index,
          config: true
        }));
      },
      viewModules() {
        var len = modules.config.length;
        return modules.views.map(( mod, index ) => ({
          ...mod,
          index: len + index
        }));
      },
      modules() {
        const { showModules } = this;
        const modules = this.configModules.concat( this.viewModules );
        return (!showModules || !showModules.length)
          ? modules
          : modules.filter( m => showModules.indexOf( m.id ) !== -1 );
      },
      items() {
        return ! this.readonly
          ? this.modules
          : ( this.model || [] )
            .map( this.module )
            .filter( a => a )
            .sort(( a, b ) => a.index - b.index );
      },
      valid() {
        if ( ! this.campaign || ! this.campaign.modules ) return true;
        const { centersStatus, calendarStatus } = this.campaign;
        if ( centersStatus == -1 || calendarStatus == -1 ) {
          return false;
        }
        return true;
      }
    },
    watch: {
      value( value ) {
        this.model = value;
      },
      model( value ) {
        this.$emit( 'input', value );
      },
      valid( value ) {
        this.$emit( 'validate', value );
      }
    },
    methods: {
      module( id ) {
        const mod = this.modules.find( m => m.id === id );
        if ( mod && this.campaign ) {
          switch ( id ) {
            case CampaignModule.CENTERS:
              mod.validate = this.campaign.centersStatus;
            break;
            case CampaignModule.REPORTS:
              mod.validate = this.campaign.reportsStatus;
            break;
            case CampaignModule.CALENDAR:
              mod.validate = this.campaign.calendarStatus;
              break;
            case CampaignModule.CAMPAIGN_ISSUE_CATEGORIES:
              mod.validate = (this.campaign.issueTypes||[]).length > 0 ? 1 : -1;
            break;
          }
        }
        return mod;
      },
      validation( mod ) {
        return {
          valid: mod.validate === 1,
          unvalid:  mod.validate === -1,
          indeterminate: mod.validate === 0
        };
      }
    },
    mounted() {
      this.$emit( 'validate', this.valid );
    }
  }
</script>

<style>
  .dfield-modules .v-item,
  .dfield-modules .options {
    border-bottom: 1px solid var(--v-border-lighten1);
  }
  .dfield-modules .module {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
  }
  .dfield-modules.readonly .module.valid, .dfield-modules.readonly .module.valid .v-icon {
    color: var(--v-success-base);
  }
  .dfield-modules.readonly .module.unvalid, .dfield-modules.readonly .module.unvalid .v-icon {
    color: var(--v-error-base);
  }
  .dfield-modules.readonly .module.indeterminate, .dfield-modules.readonly .module.indeterminate .v-icon {
    color: var(--v-warning-base);
  }
</style>

import APIService from './class';

class Clusters extends APIService {
  constructor() {
    super( '/cluster' );
  }
  places( token, params, options ) {
    return this.action( 'post', '/place/assign', params, options, token );
  }
  products( token, params, options ) {
    return this.action( 'post', '/product/assign', params, options, token );
  }
}

export default new Clusters();

<template>
  <div class="fill-width">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="date"
      :persistent="persistent"
      width="290px"
    >
      <template v-slot:activator="{ on }">
        <DFieldText
          field="text"
          :value="output"
          :label="label"
          :label-space="labelSpace"
          :field-style="fieldStyle"
          :small="small"
          :dense="dense"
          :rules="compRules"
          :clearable="clearable && !readonly"
          @click:clear="save()"
          v-on="readonly ? null : on"
          readonly
        />
      </template>
      <v-date-picker
        v-model="iso"
        v-bind="$attrs"
        :locale="locale"
        :min="compMin"
        :max="compMax"
        first-day-of-week="1"
      >
        <v-spacer></v-spacer>
        <Btn text color="primary" @click="dialog = false">Cancelar</Btn>
        <Btn color="primary" @click="save(iso)">OK</Btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>

import DFieldText from './DFieldText.vue';
import { Format, toISO } from '@/utils/time';

function toTime( value ) {
  return value ? new Date( value ).getTime() : null;
}

function output( value ) {
  return !value ? '' : Format( value, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

export default {
  nullValues: '',
  components: { DFieldText },
  props: {
    value: [ Number, String ],
    locale: { type: String, default: navigator.language },
    label: String,
    labelSpace: [ Number, String ],
    fieldStyle: [ Number, String ],
    min: [ Number, String ],
    max: [ Number, String ],
    small: Boolean,
    dense: Boolean,
    persistent: Boolean,
    readonly: Boolean,
    rules: Array,
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      date: this.value,
      iso: toISO( this.value ) || '',
      output: output( this.value )
    };
  },
  computed: {
    compRules() {
      if ( ! this.rules ) return [];
      return this.rules.map( f => () => f( this.date ));
    },
    compMin() {
      if ( this.min == null ) return null;
      return toISO( parseInt( this.min ));
    },
    compMax() {
      if ( this.max == null ) return null;
      return toISO( parseInt( this.max ));
    }
  },
  watch: {
    value( value ) {
      this.refresh( value );
      this.$emit( 'input', this.date );
    }
  },
  methods: {
    refresh( date ) {
      this.iso = toISO( date ) || '';
      this.date = typeof date === 'number' ? date : toTime( this.iso );
      this.output = output( this.iso );
    },
    save( date ) {
      this.refresh( date );
      this.$refs.dialog.save( this.iso );
      this.$emit( 'input', this.date );
      this.$emit( 'blur' );
    }
  }
};
</script>

<template>
  <Table
    ref="table"
    service="Payroll"
    min-width="1272px"
    :initialize="initialize"
    :exportData="exportData"
    :params="params"
    :headers="headers"
    :messages="messages"
    :show-select="false"
    :show-filter-button="false"
    :no-edit="true"
    :show-actions="actions"
  >
    <template v-slot:submenu-middle>
      <DChangeRangeDate
        v-model="statsRange"
      />
    </template>

    <template v-slot:item.count="{ value, item }">
       <Btn
        :color="'primary'"
        @click="showLines( item )"
        small
      >
         {{ value }}
       </Btn>
     </template>

     <template v-slot:append-outer>
      <v-dialog
        v-model="dialog"
        max-width="720"
      >
        <Card class="workers-dialog">

          <Toolbar color="secondary" dark>

            <h3>Listado de Jornadas</h3>

            <v-spacer/>

            <Btn color="white" class="mr-5" width="80" height="32" light @click="exportData('lines')">
              Exportar
            </Btn>

            <Btn color="white" width="20" height="20" fab light @click="closeDialog">
              <v-icon small>$close</v-icon>
            </Btn>

          </Toolbar>

          <div class="workers-dialog-body">
            <DTable
              :items="workersList"
              :headers="workersHeaders"
              :row-class="rowClass"
              :border="false"
              :multiple-sort="false">
              <template v-slot:item.holiday="{ item }">
                <div>{{item.holiday ? "Sí": "No"}}</div>
              </template>
            </DTable>
          </div>

        </Card>
      </v-dialog>
    </template>

  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import moment from "moment";

// COMMON headers

export default {
  components: { Table },
  data() {
    const today = moment();
    const statsRange = [ today.clone().startOf('month'), today.endOf('month') ];
    return {
      messages: { item: "Nómina" },
      statsRange: statsRange,
      dialog: false,
      item: null,
      workersList: [],
      workersHeaders: [
        { value: 'day', text: 'Fecha' },
        { value: 'campaignName', text: 'Campaña', width: 250, },
        { value: 'holiday', text: 'Festivo', width: 80 },
        { value: 'validatedWorkingTime', text: 'Tiempo validado (mins)' },
        { value: 'costHour', text: 'Coste / hora' },
        { value: 'amount', text: 'Importe laborables' },
        { value: 'extraCostDay', text: 'Plus jornada' },
        { value: 'holidayCostHour', text: 'Coste / hora festivo' },
        { value: 'holidayAmount', text: 'Importe festivos' },
        { value: 'paidHolidaysAmount', text: 'Importe vacaciones' },
        { value: 'total', text: 'Total' }
      ]
    };
  },
  computed: {
    actions() {
      return "export";
    },
    params() {
      return {
        statsRange: this.statsRange      
      };
    },
    headers() {

      var headers = [];

      headers.push({
          text: "NOMBRE",
          value: "user",
          display: (a) => a.surname + ", " + a.name,
          filterable: false,
        });

      headers.push({
          text: "NUM. JORNADAS",
          value: "count",
          display: Number,
          filterable: false,
        });

      headers.push({
          text: "699 - FORMULA (I)",
          value: "cost",
          display: Number,
          filterable: false,
        });

      headers.push({
          text: "697 - FORMULA (I)",
          value: "extraCost",
          display: Number,
          filterable: false,
        });
      
        headers.push({
          text: "197 – Vacaciones",
          value: "paidHolidays",
          display: Number,
          filterable: false,
        });

        headers.push({
          text: "032 - Com.Brut (I)",
          value: "subTotal",
          display: Number,
          filterable: false,
        });

        headers.push({
          text: "012 - Plus fes (I)",
          value: "holidayCost",
          display: Number,
          filterable: false,
        });

      return headers;
    },
  },
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    } 
  },
  methods: {
    initialize( params ) {
      params.filters = ( params.filters || [] )
        .concat([{ field: 'date', rangeValue:{ start: this.statsRange[0].valueOf() - 7200000, end: this.statsRange[1].valueOf() + 75600000 }}]);

      return this.$store.dispatch( 'page', params );
    },
    exportData( params ) {
      if (params === 'lines') {
        params = { service: 'Payroll', method: 'exportLines' };
        params.filters = ( params.filters || [] )
          .concat([{ field: 'id', stringValue: this.item.id }]);
        
        return new Promise(( resolve, reject ) => {
          Promise.resolve(this.$store.dispatch( 'exportData', params ))
          .then( result => {
            window.open( result, "_blank" );
            resolve( result );
          })
          .catch( err => {
            this.$store.dispatch( 'console/error', err );
            reject( err );
          });
        });
      }
      else {
        params.filters = ( params.filters || [] )
          .concat([{ field: 'date', rangeValue:{ start: this.statsRange[0].valueOf() - 7200000, end: this.statsRange[1].valueOf() + 75600000 }}]);

        return this.$store.dispatch( 'exportData', params );
      }
    },
    setTitle() {
      const title = 'Nómina';
      return this.$store.commit( 'setTitle', title );
    },
    showLines( worker ) {
      this.dialog = true;
      this.item = worker;
      const target = "Payroll/get";
      const params = { id: worker.id };

      this.$store
        .dispatch("api", { target, params })
        .then( response => {
          this.workersList = response.data;
        })
        .catch( err => this.$store.dispatch( 'console/error', err ));
    },
    rowClass( classes, item ) {
      return {
        ...classes,
        'worker-active': item.active
      };
    },
    closeDialog() {
      this.dialog = false;
      this.workersList = [];
      this.item = null;
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

<style>
.v-dialog__content {
  justify-content: normal;
}
.workers-dialog {
  min-width: 1400px;
  min-height: 350px;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
}
.workers-dialog > .workers-dialog-body {
  flex: 1 1 auto;
  position: relative;
}
.workers-dialog > .workers-dialog-body > div {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  overflow-y: auto;
}
.workers-dialog .dtable-header > div {
  background-color: var(--v-tertiary-base);
}
.workers-dialog .dtable-row:not(.worker-active),
.workers-dialog .dtable-row:not(.worker-active):hover {
  background-color: #eee;
}
</style>


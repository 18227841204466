<template>
  <div class="rel">
    <DField
      field="text"
      v-bind="$attrs"
      :value="text"
      :rules="compRules"
      :color="color"
      readonly
      @click="$refs.file.click()"
    />
    <v-progress-linear
      v-if="loading"
      :color="color||'secondary'"
      width="2"
      indeterminate
      absolute
    />
    <input
      v-show="false"
      ref="file"
      type="file"
      :accept="accept"
      @change="uploadFile"
    />
  </div>
</template>

<script>
  export default {
    props: {
      value: [ Object, String ],
      accept: String,
      rules: Array,
      color: String,
      upload: Function
    },
    data() {
      return {
        model: this.value,
        loading: false,
        percent: 0
      }
    },
    computed: {
      compRules() {
        return this.loading ? [] : this.rules;
      },
      text() {
        if ( this.loading ) return 'Subiendo archivo...';
        if ( this.model && this.model.url ) return this.model.url;
        return '';
      }
    },
    watch: {
      value( value ) {
        this.model = value;
        if ( ! value ) this.$refs.file.value = null;
      },
      percent( value ) {
        this.$emit( 'progress', value );
      },
      loading( value ) {
        this.$emit( 'loading', value );
      }
    },
    methods: {
      uploadFile() {

        const file = this.$refs.file.files[0];
        if ( this.upload ) return this.upload( file );
        if ( ! file ) return;

        const data = new FormData();
        data.append( 'file', file );

        this.loading = true;
        this.$store.dispatch( "uploadFile", data )
          .then( response => {
            this.model = response;
            this.$emit( "input", this.model );
            this.$emit( "blur" );
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => {
            this.$refs.file.value = null;
            this.loading = false;
            this.percent = 0;
          })
      },
      onUploadProgress( event ) {
        this.percent = Math.round(( event.loaded * 100) / event.total );
      }
    }
  }
</script>

<style scoped>
  .dfield-image {
    position: relative;
    width: 100%;
  }
  .dfield-image.clickable {
    cursor: pointer;
  }
  .dfilebutton{
    position: absolute;
    right: 0;
    top:0px;
    height:40px !important;
  }
</style>

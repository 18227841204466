<template>
  <div class="pa-4">

    <p class="body-grupo-ot">Filtrar por:</p>

    <DField
      v-bind="client"
      v-model="search.client"
      @keypress.enter="applyFilters"
      @blur="applyFilters"
    />

    <DField
      v-bind="campaign"
      v-model="search.campaign"
      @keypress.enter="applyFilters"
      @blur="applyFilters"
    />

    <DField
      v-bind="channel"
      v-model="search.channel"
      @keypress.enter="applyFilters"
      @blur="applyFilters"
    />

  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        search: {
          client: null,
          campaign: null,
          ...this.value
        }
      }
    },
    computed: {
      common() {
        return {
          class: 'mb-6',
          fieldStyle: 1,
          dense: true
        }
      },
      client() {
        return {
          ...this.common,
          label: 'Cliente',
          field: 'autocomplete',
          service: 'Clients',
          itemValue: 'id',
          itemText: 'name',
          display: a => `${a.id}. ${a.name}`,
        }
      },
      campaign() {
        return {
          ...this.common,
          label: 'Campaña',
          field: 'autocomplete',
          service: 'Campaigns',
          itemValue: 'id',
          itemText: 'name',
          display: a => `${a.id}. ${a.title || a.name}`,
        }
      },
      channel() {
        return {
          ...this.common,
          label: 'Canal',
          field: 'autocomplete',
          service: 'Channels',
          itemValue: 'id',
          itemText: 'name',
          display: a => `${a.id}. ${a.name}`,
        }
      }
    },
    watch: {
      value( value ) {
        this.search = { ...value };
      }
    },
    methods: {
      applyFilters() {
        this.$emit( 'input', this.search );
      }
    }
  }
</script>

<template>
  <v-row class="fill-height flex-nowrap" no-gutters>
    <div class="fill-width rel">
      <Block class="abs full autoscroll" :block="block" :opacity="0">

        <DGallery
          v-if="!list"
          v-model="selected"
          :items="products"
          :multiple="multiple"
          :cols="cols"
          :sm="sm"
          :md="md"
          :lg="lg"
          :xl="xl"
          selectable
          item-key="id"
          item-footer="name"
          :item-image="itemImage"
        />

        <v-list v-else>
          <v-list-item-group
            v-model="selected"
            :multiple="multiple"
          >
            <v-list-item
              v-for="item in products"
              v-slot:default="{ active }"
              :value="item"
              :ripple="false"
              :key="item.id"
            >
              <v-list-item-icon v-if="!readonly">
                <v-simple-checkbox
                  color="primary"
                  :value="active"
                  :ripple="false"
                />
              </v-list-item-icon>
              <slot name="list-item-content" v-bind="{ item, active }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"/>
                </v-list-item-content>
              </slot>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </Block>
    </div>
    <v-expand-x-transition>
      <Card
        v-show="editable && !multiple && product"
        class="fill-height fill-width"
        :max-width="detailsWidth"
        style="flex: 0 0 auto"
        border-left
      >
        <slot name="info" v-bind="{ product }">
          <Product
            class="fill-height"
            :value="product"
            :cancelable="cancelable"
            :full="full"
            :readonly="readonly"
            @save="onsave"
            @cancel="$emit('cancel')"
          />
        </slot>
      </Card>
    </v-expand-x-transition>
  </v-row>
</template>

<script>

  import Product from './single.vue';
  import { Any, toArray } from '@/utils';

  export default {
    components: { Product },
    props: {
      value: Any,
      items: Array,
      readonly: Boolean,
      editable: Boolean,
      multiple: Boolean,
      block: Boolean,
      cancelable: Boolean,
      full: Boolean,
      xl: [ Number, String ],
      lg: [ Number, String ],
      md: [ Number, String ],
      sm: [ Number, String ],
      cols: [ Number, String ],
      list: Boolean,
      itemImage: {
        type: String,
        default: 'picture.url'
      },
      detailsWidth: {
        type: [ Number, String ],
        default: 480
      }
    },
    data() {
      return {
        selected: this.value,
        products: toArray( this.items ).filter( a => a && a.status !== -1 )
      }
    },
    watch: {
      value( value ) {
        this.selected = value;
      },
      items( value ) {
        this.products = toArray( value ).filter( a => a && a.status !== -1 );
      },
      selected( value ) {
        this.$emit( 'input', value );
      }
    },
    computed: {
      product() {
        return Array.isArray( this.selected )
          ? null
          : this.selected
            ? { ...this.selected }
            : null;
      }
    },
    methods: {
      onsave( value ) {
        const index = this.products.indexOf( this.selected );
        this.selected = this.products[index] = value;
        this.$emit( 'save', this.products );
      },
    }
  }
</script>

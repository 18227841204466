<template>
  <Page
    ref="pageComponent"
    service="Campaigns"
    :initialize="initialize"
    :loading="loading"
    :section="section"
    hide-footer
  >
    <Submenu
      slot="top"
      :value="section"
      :items="sections"
      @input="set({}, $event)"
    >
      <Btn class="text-none subtitle-2" color="secondary" @click="reload" text>
        <v-icon class="btn-reload--icon">mdi-reload</v-icon>
        <span class="btn-reload--text">Refrescar</span>
      </Btn>

      <Btn
        @click="set({ showFilters: !showFilters })"
        :color="showFilters ? 'primary' : 'secondary'"
        class="text-none mr-4 subtitle-2"
        text
      >
        <v-icon class="mr-2">$filter</v-icon>
        Filtros
      </Btn>
    </Submenu>

    <v-row class="fill-height" no-gutters>
      <div class="fill-height grow rel">
        <div class="layer autoscroll">
          <p class="ma-4 mb-2">
            <v-chip color="grey" dark small>
              Total resultados: {{ totalItems }}
            </v-chip>
          </p>

          <DGallery
            :items="items"
            :item-footer="itemFooter"
            item-key="id"
            item-image="file.url"
            item-download="file.downloadUrl"
            cols="1"
            sm="2"
            md="3"
            lg="4"
            xl="5"
            @click-item="openSlider"
            download
            :remove = "!isClient"
            @remove="onremove"
          />
        </div>
      </div>
      <v-expand-x-transition>
        <div v-show="showFilters" class="fill-height">
          <Card class="fill-height autoscroll pa-4" width="300" border-left>
            <p class="subtitle-1 mb-6">Filtrar por:</p>

            <template v-if="isIssues">
              <DField
                class="mb-6"
                v-model="filter.issues.type"
                v-bind="filterType"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                v-model="filter.issues.priority"
                v-bind="filterPriority"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                v-model="filter.issues.categories"
                v-bind="filterCategories"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                label="Fecha"
                v-model="filter.issues.date"
                v-bind="filterDate"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                v-model="filter.issues.campaign"
                v-bind="filterCampaigns"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                v-model="filter.issues.places"
                v-bind="filterPlaces"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />
            </template>
            <template v-else>
              <DField
                class="mb-6"
                v-show="!idCampaign"
                v-model="filter.tasks.campaign"
                v-bind="filterCampaigns"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                v-model="filter.tasks.places"
                v-bind="filterPlaces"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                v-model="filter.tasks.users"
                v-bind="filterUsers"
                @keypress.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                class="mb-6"
                v-model="filter.tasks.action"
                v-bind="filterAction"
                @keyup.enter="applyFilters"
                @blur="applyFilters"
              />

              <DField
                v-model="filter.tasks.date"
                v-bind="filterDate"
                @blur="applyFilters"
              />
            </template>
          </Card>
        </div>
      </v-expand-x-transition>
    </v-row>

    <v-dialog v-model="slider" transition="fade-transition" fullscreen>
      <DSlider
        v-model="index"
        item-image="file.url"
        item-download="file.downloadUrl"
        :item-footer="itemFooter"
        :items="items"
        @close="slider = false"
        @remove="onremove"
        download
        :remove = "!isClient"
      />
    </v-dialog>

    <template v-slot:footer>
      <DPagination
        tag="footer"
        v-if="numPages > 1"
        :value="page"
        :length="numPages"
        :visibles="pagesVisibles"
        @input="set({ page: $event })"
        border-top
      />

      <Toolbar border-top>
        <v-spacer />

        <Btn
          color="primary"
          :loading="downloading"
          :disabled="!items.length"
          @click="download"
        >
          Descargar
        </Btn>
      </Toolbar>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/core/Page.vue";
import Submenu from "@/components/core/Submenu.vue";
import { mapState, mapMutations } from "vuex";
import { formatDate, copy, compare, get } from "@/utils";
import { CampaignStatus } from "@/utils/constants";

const defaultFilters = {
  tasks: {
    campaign: [],
    places: [],
    action: "",
    users: [],
    date: null,
  },
  issues: {
    date: null,
    campaign: [],
    places: [],
    categories: [],
    type: null,
    priority: null,
  },
};

export default {
  components: { Page, Submenu },
  data: function () {
    return {
      index: 0,
      slider: false,
      loading: false,
      downloading: false,
      initialized: false,
      changed: false,
    };
  },
  watch: {
    page: "reload",
    section(section, old) {
      if (!compare(section, old)) {
        this.reload();
      }
    },
    $route() {
      this.$nextTick(this.setTitle);
    },
  },
  computed: {
    ...mapState(["view", "core", "path", "breadcrumbs", "isClient"]),
    section() {
      return get(this.view, "section") || [0, 0];
    },
    page() {
      return get(this.core, "page") || 1;
    },
    numPages() {
      return get(this.core, "numPages") || 1;
    },
    totalItems() {
      return get(this.core, "totalItems") || 0;
    },
    showFilters() {
      return get(this.core, "showFilters") || false;
    },
    filter() {
      return { ...defaultFilters, ...get(this.core, "filterStore") };
    },
    items() {
      return get(this.core, "data") || [];
    },
    sections() {
      return this.idCampaign
        ? null
        : [
            { text: "Tareas", target: "Campaigns/pictures" },
            { text: "Incidencias", target: "Campaigns/pictures" },
          ];
    },
    isIssues() {
      return !!this.section[0];
    },
    pagesVisibles() {
      switch (true) {
        case this.$vuetify.breakpoint.xl:
          return 30;
        case this.$vuetify.breakpoint.lg:
          return 18;
        case this.$vuetify.breakpoint.md:
          return 15;
        default:
          return 6;
      }
    },
    idCampaign() {
      const id = parseInt(this.$route.params.id);
      return !id || id === "new" ? null : id;
    },
    filterDate() {
      return {
        field: "rangedate",
        fieldStyle: 1,
        label: "Fecha de la acción",
        placeholder: "Buscar por rango de fechas...",
        dense: true,
      };
    },
    filterCampaigns() {
      return {
        field: "autocomplete",
        fieldStyle: 1,
        label: "Campañas",
        placeholder: "Buscar campaña...",
        service: "Campaigns",
        itemText: "name",
        itemValue: "id",
        multiple: true,
        dense: true,
        display: (a) => `${a.id}. ${a.name}`,
      };
    },
    filterPlaces() {
      return {
        //field: 'autocomplete',
        field: "text",
        fieldStyle: 1,
        label: "Centros",
        placeholder: "Buscar centro...",
        //service: 'Places',
        //itemText: 'name',
        //itemValue: 'id',
        //multiple: true,
        dense: true,
      };
    },
    filterAction() {
      return {
        field: "text",
        fieldStyle: 1,
        label: "Acciones",
        placeholder: "Buscar acción...",
        dense: true,
      };
    },
    filterUsers() {
      return {
        field: "autocomplete",
        fieldStyle: 1,
        label: "Usuarios",
        placeholder: "Buscar usuario...",
        service: "Users",
        itemText: "name",
        itemValue: "id",
        multiple: true,
        dense: true,
      };
    },
    filterCategories() {
      return {
        field: "autocomplete",
        fieldStyle: 1,
        label: "Categorias",
        placeholder: "Buscar categoria...",
        service: "IssueCategories",
        itemText: "name",
        itemValue: "id",
        multiple: true,
        dense: true,
      };
    },
    filterType() {
      return {
        field: "select",
        fieldStyle: 1,
        label: "Tipo",
        clearable: true,
        dense: true,
        items: [
          { value: 0, text: "Incidencia" },
          { value: 1, text: "Alarma" },
        ],
      };
    },
    filterPriority() {
      return {
        field: "select",
        fieldStyle: 1,
        label: "Prioridad",
        clearable: true,
        dense: true,
        items: [
          { value: 0, text: "Baja" },
          { value: 1, text: "Normal" },
          { value: 2, text: "Alta" },
        ],
      };
    },
  },
  methods: {
    ...mapMutations(["setView"]),
    ...mapMutations("app", ["setDialog"]),
    set(state, section) {
      section = section || this.section;
      this.setView({
        path: this.path,
        state: {
          section,
          sections: { [section.join("")]: state },
        },
      });
    },
    initialize() {
      return new Promise((resolve, reject) => {
        this.loading = this.initialized;
        const target = this.isIssues ? "Issues/pictures" : "Campaigns/pictures";
        const params = this.parameters();

        this.$store
          .dispatch("api", { target, params })
          .then((response) => {
            if (this.initialized) this.set(response);
            this.initialized = true;
            resolve({ ...params, ...response });
          })
          .catch(reject)
          .finally(() => (this.loading = false));
      });
    },
    download() {
      if (this.downloading) return;
      this.downloading = true;

      const params = this.parameters();
      const target = this.isIssues
        ? "Issues/downloadPictures"
        : "Campaigns/download";

      this.$store
        .dispatch("api", { target, params })
        .then((url) => window.open(url, "_blank"))
        .catch((err) => this.$store.dispatch("console/error", err))
        .finally(() => (this.downloading = false));
    },
    date(value) {
      return formatDate(value, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    itemFooter(item) {
      var items = {};
      const footer = {
        color: "white",
        background: "black",
        opacity: 0.5,
        template: "",
      };

      if (this.isIssues) {
        if (item.issue) {
          const { issue } = item;
          var cat = issue.issueCategory;
          footer.template += `<strong>${
            issue.type ? "Alarma" : "Incidencia"
          } - ${issue.id}</strong></br>`;

          items = {
            categoría: cat.parent
              ? cat.parent.name + " - " + cat.name
              : cat.name,
            campaña: issue.campaign.title,
            centro: issue.place.name,
            usuario: issue.user.fullName,
            fecha: this.date(issue.date),
          };
        }
      } else if (item.task) {
        items = {
          centro: item.task.place.name,
          acción: item.task.action.title,
          fecha: this.date(item.task.date),
        };
      }

      // Prepre template
      Object.keys(items).forEach((key) => {
        footer.template += `<strong>${key}:</strong> ${items[key]}</br>`;
      });

      return footer;
    },
    openSlider({ index }) {
      this.index = index;
      this.slider = true;
    },
    parameters() {
      const { page, filter, idCampaign } = this;
      const task = parseInt(this.$route.query.task);
      const mode = this.isIssues ? "issues" : "tasks";
      const filters = [];

      if (mode === "tasks") {
        if (idCampaign) {
          filters.push({ field: "campaign", intValue: idCampaign });
        } else {
          filters.push({
            field: "campaign_status",
            intValue: CampaignStatus.ACTIVE,
          });
        }

        if (task) filters.push({ field: "task", intValue: task });
      }

      if (filter[mode].campaign.length)
        filters.push({
          field: "campaign",
          listValue: filter[mode].campaign.map((a) => a.id),
        });

      if (filter[mode].places.length) {
        if (Array.isArray(filter[mode].places)) {
          filters.push({
            field: "centers",
            listValue: filter[mode].places.map((a) => a.id),
          });
        } else {
          filters.push({ field: "centers", stringValue: filter[mode].places });
        }
      }

      if (filter[mode].users && filter[mode].users.length)
        filters.push({
          field: "users",
          listValue: filter[mode].users.map((a) => a.id),
        });

      if (filter[mode].date && filter[mode].date.length == 2) {
        filters.push({ field: "start", doubleValue: filter[mode].date[0] });
        filters.push({ field: "end", doubleValue: filter[mode].date[1] });
      }

      if (mode === "issues") {
        if (filter[mode].type != null)
          filters.push({ field: "type", intValue: filter[mode].type });

        if (filter[mode].priority != null)
          filters.push({ field: "priority", intValue: filter[mode].priority });

        if (filter[mode].categories.length)
          filters.push({
            field: "category",
            listValue: filter[mode].categories.map((a) => a.id),
          });
      }

      if (filter[mode].action)
        filters.push({ field: "action", stringValue: filter[mode].action });

      return { page, filters };
    },
    applyFilters() {
      if (!compare(this.filter, this._filter)) {
        this.set({ page: 1, filterStore: this.filter });
        this.reload();
      }
      this._filter = copy(this.filter);
    },
    reload() {
      const { pageComponent } = this.$refs;
      if (pageComponent) pageComponent.reload();
    },
    setTitle() {
      const id = parseInt(this.$route.params.id);
      const title = ["Campañas", "", "Fotografías"];

      if (!id) {
        title.splice(0, 2);
        return this.$store.commit("setTitle", title);
      }

      this.$store.commit("setTitle", title);
      if (this.breadcrumbs[1].text === "...") {
        this.$store
          .dispatch("api", { target: "Campaigns/get", params: { id } })
          .then((res) => {
            title[1] = res.title;
            this.$store.commit("setTitle", title);
          });
      }
    },
    onremove(e) {
      //console.log(this.section[0] + "Removing: " + JSON.stringify(e));
      //Eliminar imagen por id e.id!
      this.setDialog({
        show: true,
        width: 550,
        title: "¿Estas seguro de querer eliminar la imagen?",
        text: "Esta acción no será reversible. Una vez eliminada la imagen no se podrá recuperar.",
        acceptText: "Eliminar",
        accept: () => {
          this.loading;
          this.$store
            .dispatch("api", {
              target: "Campaigns/removePicture",
              params: { id: e.id, type: this.section[0] },
            })
            .then(() => {
              this.slider = false;
              this.reload();
            })
            .catch((err) => this.$store.dispatch("console/error", err))
            .finally(() => (this.loading = false));
        },
      });
    },
  },
  beforeMount() {
    this._filter = copy(this.filter);
  },
  mounted() {
    this.setTitle();
  },
};
</script>

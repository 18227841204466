<template>
  <Table
    ref="table"
    service="ClientPlace"
    :is-loading="loading"
    :initialize="initialize"
    :messages="messages"
    :headers="headers"
    :edit="onEdit"
    show-actions="edit,delete,import,export"
    disable-vars
  >
    <template v-slot:actions-right="{ edit }">

      <Btn class="ml-2" @click="edit()" color="tertiary" dark>
        Añadir
      </Btn>

    </template>
    <template v-slot:append-outer>

      <CentersDialog
        v-model="centers"
        :exclude="items"
        @apply="apply"
      />

      <v-dialog v-if="editing" v-model="dialog" max-width="640" style="overflow:visible" no-click-animation persistent>
        <Card class="rel" height="200">
          <v-row class="flex-column fill-height" no-gutters>
            <Toolbar color="secondary" dark>

              <h3>Editar centro</h3>

              <v-spacer/>

              <Btn color="white" width="20" height="20" fab light @click="dialog = false">
                <v-icon small>$close</v-icon>
              </Btn>

            </Toolbar>
            <div class="grow rel">
              <div class="layer autoscroll pa-4">

                <DField
                  v-model="editing.code"
                  field="text"
                  field-style="1"
                  label="Código"
                  hide-details
                  dense
                />

              </div>
            </div>
            <Toolbar border-top>

              <v-spacer/>

              <Btn class="mr-4" color="white" @click="dialog = false">
                Cancelar
              </Btn>

              <Btn color="primary" @click="edit">
                Guardar
              </Btn>

            </Toolbar>
          </v-row>
        </Card>
      </v-dialog>

      <v-dialog v-model="coding" max-width="720" style="overflow:visible" no-click-animation persistent>
        <Card class="rel" height="480">
          <v-row class="flex-column fill-height" no-gutters>
            <Toolbar color="secondary" dark>

              <h3>Asignar centros al cliente</h3>

              <v-spacer/>

              <Btn color="white" width="20" height="20" fab light @click="coding = false">
                <v-icon small>$close</v-icon>
              </Btn>

            </Toolbar>
            <div class="grow rel">
              <div class="layer autoscroll pa-4">

                <div v-for="( place, index ) in selected" :key="index" class="mb-4">

                  <label class="body-2 secondary--text">
                    <v-icon color="tertiary" v-text="'$place'"/>
                    <span>{{ direction( place, true ) }}</span>
                  </label>

                  <DField
                    v-model="place.code"
                    field="text"
                    field-style="1"
                    label="Código"
                    hide-details
                    dense
                  />
                </div>

              </div>
            </div>
            <Toolbar border-top>

              <v-spacer/>

              <Btn class="mr-4" color="white" @click="coding = false">
                Cancelar
              </Btn>

              <Btn color="primary" @click="assign">
                Asignar
              </Btn>

            </Toolbar>
          </v-row>
        </Card>
      </v-dialog>

    </template>
  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import CentersDialog from '@/components/forms/Places/dialog';
  import { direction } from '@/utils';
  import { mapState } from 'vuex';

  export default {
    components: { Table, CentersDialog },
    data() {
      return {
        messages: { item: 'Centro/Centros' },
        centers: false,
        coding: false,
        loading: false,
        dialog: false,
        editing: null,
        selected: [],
        headers: [
          { text: 'Código', value: 'code', width: 200 },
          {
            text: 'Centro', value: 'place', display: direction,
            filter: {
              type: 'autocomplete',
              attrs: { service: 'Places', itemText: 'name', itemValue: 'id' }
            }
          },
        ]
      }
    },
    computed: {
      ...mapState([ 'core' ]),
      items() {
        return this.core ? ( this.core.data || []) : [];
      },
      idClient() {
        return parseInt( this.$route.params.id ) || 0;
      }
    },
    watch: {
      coding( value ) {
        if ( ! value ) this.selected = [];
      }
    },
    methods: {
      direction,
      initialize( params ) {

        params.filters = ( params.filters || [] )
          .concat([{ field: 'client', intValue: this.idClient }]);

        return this.$store.dispatch( 'page', params );
      },
      onEdit( item ) {
        if ( ! item ) this.centers = true;
        else {
          this.editing = { ...item };
          this.dialog = true;
        }
      },
      apply( selected ) {
        this.selected = selected;
        this.coding = true;
      },
      edit() {

        const { idClient, editing } = this;
        const target = 'ClientPlace/set';
        const params = {
          idClient,
          idPlace: editing.place.id,
          code: editing.code
        };

        this.dialog = false;
        this.loading = true;

        this.$store
          .dispatch( 'api', { target, params })
          .then(() => {
            this.$refs.table.reload();
            this.$store.dispatch( 'console/success', 'Centro editado.' );
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.loading = false );
      },
      assign() {

        const { idClient, selected } = this;
        const target = 'ClientPlace/assign';
        const params = {
          idClient,
          items: selected.map( a => ({ idPlace: a.id, code: a.code || '' }))
        };

        this.coding = false;
        this.loading = true;

        this.$store
          .dispatch( 'api', { target, params })
          .then(() => {
            this.$refs.table.reload();
            this.$store.dispatch( 'console/success', 'Se han assignado los centros correctamente.' );
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.loading = false );
      }
    }
  }
</script>

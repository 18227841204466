import store from '@/store';

export function required( message ) {
  return function( v ) {
    return v != null || message || 'Required';
  }
}

export function minLength( length, message ) {
  return function( v ) {
    return !v || v.length >= ( length || 0 ) || message || `The field must have a minium of ${length} characters`;
  }
}

export function maxLength( length, message ) {
  return function( v ) {
    return !v || v.length <= ( length || 0 ) || message || `The field must have a maximum of ${length} characters`;
  }
}

export function min( num, message ) {
  return function( v ) {
    v = parseFloat(v);
    return !v || v >= ( length || 0 ) || message || `The field must be greater than "${num}"`;
  }
}

export function max( num, message ) {
  return function( v ) {
    v = parseFloat(v);
    return !v || v <= ( length || 0 ) || message || `The field must be less than "${num}"`;
  }
}

export function fieldExists( service, field, message ) {
  return function( v, data ) {
    return new Promise(( resolve, reject ) => {
      store.dispatch( 'api', {
        target: `${ service }/all`,
        params: { page: 1, filters:[{ field, stringValue: v }]}
      })
      .then( res => resolve( !res.data.filter( a => a[field] === v && a.id !== data.id ).length || message || `Exist item with the ${field} "${v}"` ))
      .catch( reject )
    })
  }
}

import APIService from './class';

class UserEvaluations extends APIService {
  constructor() {
    super( '/userEvaluation' );
  }
  campaign( token, params, options ) {
    params = params || {};
    return this.action( 'post', `/campaign/${ params.id }`, params, options, token );
  }
  remove( token, params, options ) {
    return this.action( 'post', '/remove', params, options, token );
  }
  removeAll( token, params, options ) {
    return this.action( 'post', '/remove/ids', params, options, token );
  }
  campaignExport( token, params, options ) {
    return this.action( 'post', `/campaign/export/${ params.id }`, params, options, token );
  }
}

export default new UserEvaluations();

import APIService from './class';

class ClientPlace extends APIService {
  constructor() {
    super( '/clientplace' );
  }
  assign( token, params, options ) {
    return this.action( 'post', '/assign', params, options, token );
  }
}

export default new ClientPlace();

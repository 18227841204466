<template>
  <Page
    :loading="loading"
    hide-footer>

    <div class="no-gutters ma-10 pa-6">
      <v-row class="no-gutters">
            <div style="width:100%" class="text-center">
          <h3 class="mb-2">Conexiones de la plataforma</h3>
      </div>
      </v-row>
      
      <v-row class="no-gutters">
        <v-col>
          <Card class="rel">
            <div class="text-center">
              <h3 class="title">A3ASESOR - Nom</h3>
              <p>Exporta tus nóminas para ser importados directamente en este sistema</p>
              <Btn color="primary">A3ASESOR - Nom</Btn>
            </div>
          </Card>
        </v-col>
      </v-row>
    </div>
  </Page>
</template>

<script>
  import Page from '@/components/core/Page.vue';

  export default {
    components: { Page },
    data: () => ({
      key: 0,
      loading: false
    }),
    computed:{
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Integraciones' );
    }
  }
</script>

<style>
  
</style>

import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import Axios from 'axios';

// Pages

import Users from '@/components/views/Users/index';
import User from '@/components/views/Users/single';
import Clients from '@/components/views/Clients/index';
import Client from '@/components/views/Clients/single';
import ClientCenters from '@/components/views/Clients/centers';
import Channels from '@/components/views/Channels/index';
import IssueCategories from '@/components/views/IssueCategories/index';
import Brands from '@/components/views/Brands/index';
import Sectors from '@/components/views/Sectors/index';
import Products from '@/components/views/Products/index';
import Places from '@/components/views/Places/index';
import Clusters from '@/components/views/Clusters/index';
import Cluster from '@/components/views/Clusters/single';
import Issues from '@/components/views/Issues/index';
import Tasks from '@/components/views/Tasks/index';
import Campaigns from '@/components/views/Campaigns/index';
import Campaign from '@/components/views/Campaigns/single';
import CampaignOptions from '@/components/views/Campaigns/options';
import CampaignLinks from '@/components/views/Campaigns/links';
import Chat from '@/components/views/Chat/index';
import Stats from '@/components/views/Stats/index';
import Stock from '@/components/views/Stock/index';
import Holidays from '@/components/views/Holidays/index';
import Info from '@/components/views/Info/index';
import Kpi from '@/components/views/Kpi/index';
import KpiCenters from '@/components/views/Kpi/centers';
import Payroll from '@/components/views/Payroll/index';
import UserLogs from '@/components/views/UserLogs/index';
import VersionLogs from '@/components/views/VersionLogs/index';
import Integrations from '@/components/views/Integrations/index';


// Campaign Modules

import Centers from '@/components/views/modules/Centers';
import Reports from '@/components/views/modules/Reports/index';
import Report from '@/components/views/modules/Reports/single';
import Pictures from '@/components/views/modules/Pictures/index';
import Calendar from '@/components/views/modules/Calendar/index';
import Geolocation from '@/components/views/modules/Geolocation/index';
import CampaignIssueCategories from '@/components/views/modules/CampaignIssueCategories/index';
import DayTasks from '@/components/views/modules/DayTasks/index';
import DayTask from '@/components/views/modules/DayTasks/single';
import Workers from '@/components/views/modules/Workers/index';
import Worker from '@/components/views/modules/Workers/single';
import Files from '@/components/views/modules/Files/index';
import Evaluation from '@/components/views/modules/Evaluations/index';

// Others

import Login from '@/components/views/Login';
import { RouteTag } from '@/utils/constants';

Vue.use( Router );

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'Admin', redirect: '/stats' },
    { path: '/login', name: 'Login', component: Login },
    { path: '/stats', name: 'Estadísicas', component: Stats, meta: { menu: 'main', icon: '$stats', roles: [99, 1, 2, 3 , 4], tag: RouteTag.STATS }},
    { path: '/campaigns', name: 'Campañas', component: Campaigns, meta: { menu: 'main', icon: '$campaigns', roles: [99, 1, 2, 3, 4] } },
    { path: '/campaigns/:id', name: 'Campaña', component: Campaign },
    { path: '/campaigns/:id/options', name: 'Opciones de campaña', component: CampaignOptions },
    { path: '/campaigns/:id/links', name: 'Links', component: CampaignLinks },
    { path: '/campaigns/:id/centers', component: Centers },
    { path: '/campaigns/:id/centers/:index', redirect: to => `/campaigns/${to.params.id}/centers` },
    { path: '/campaigns/:id/centers/:index/targets', component: KpiCenters },
    { path: '/campaigns/:id/workers', component: Workers },
    { path: '/campaigns/:id/workers/:index', component: Worker },
    { path: '/campaigns/:id/day-tasks', component: DayTasks },
    { path: '/campaigns/:id/day-tasks/:index', component: DayTask },
    { path: '/campaigns/:id/reports', component: Reports },
    { path: '/campaigns/:id/reports/:index', component: Report },
    { path: '/campaigns/:id/pictures', component: Pictures },
    { path: '/campaigns/:id/calendar', component: Calendar },
    { path: '/campaigns/:id/location', component: Geolocation },
    { path: '/campaigns/:id/issue-categories', component: CampaignIssueCategories },
    { path: '/campaigns/:id/files', component: Files },
    { path: '/campaigns/:id/evaluations', component: Evaluation },
    { path: '/campaigns/:id/targets', component: Kpi },
    { path: '/campaigns/:id/targets/centers', component: KpiCenters },
    { path: '/workers', name: 'Fichajes', component: Workers, meta: { menu: 'main', icon: '$worker', roles: [99, 1, 2, 3], tag: RouteTag.WORKERS }},
    { path: '/workers/:id', name: 'Fichaje', component: Worker },
    { path: '/day-tasks', name: 'Registros', component: DayTasks, meta: { menu: 'main', icon: '$register', roles: [99, 1, 2, 3], tag: RouteTag.TASKS } },
    { path: '/day-tasks/:id', component: DayTask },
    { path: '/location', name: 'Localizaciones', component: Geolocation, meta: { menu: 'main', icon: '$place', roles: [3], tag: RouteTag.GEOLOCATION }},
    { path: '/payroll', name: 'Nómina', component: Payroll,  meta: { menu: 'main', icon: '$money', roles: [99, 1, 2, 4]}},
    { path: '/evaluations', name: 'Evaluaciones', component: Evaluation,  meta: { menu: 'main', icon: 'mdi-check', roles: [99, 1, 2, 4]}},
    { path: '/pictures', name: 'Fotografías', component: Pictures, meta: { menu: 'main', icon: '$camera', roles: [99, 1, 2, 3], tag: RouteTag.PICTURES } },
    { path: '/users', name: 'Usuarios', component: Users, meta: { menu: 'main', icon: '$user', roles: [99, 1, 2, 4], tag: 3 } },
    { path: '/users/:id', name: 'Usuario', component: User },
    { path: '/users/:id/evaluation', name: 'Evaluación del usuario', component: Evaluation },
    { path: '/stock', name: 'Informe Ventas', component: Stock, meta: { menu: 'main', icon: '$stock', roles: [99, 1, 2] } },
    { path: '/clients', name: 'Clientes', component: Clients, meta: { menu: 'main', group: 'Configuración', groupIcon: '$config', icon: '$client', roles: [99, 1, 2] }},
    { path: '/clients/:id', name: 'Cliente', component: Client },
    { path: '/clients/:id/centers', name: 'ClientCenters', component: ClientCenters },
    { path: '/centers', name: 'Centros', component: Places, meta: { menu: 'main', icon: '$place', group: 'Configuración', roles: [99, 1] } },
    { path: '/products', name: 'Productos', component: Products, meta: { menu: 'main', icon: '$product', group: 'Configuración', roles: [99, 1,2] } },
    { path: '/clusters', name: 'Clusters', component: Clusters, meta: { menu: 'main', icon: '$cluster', group: 'Configuración', roles: [99, 1,2] } },
    { path: '/clusters/:id', name: 'Cluster', component: Cluster },
    { path: '/issue-categories', name: 'Categorías de incidencia', component: IssueCategories, meta: { menu: 'main', icon: '$issue', group: 'Configuración', roles: [99, 1] } },
    { path: '/channels', name: 'Canales', component: Channels, meta: { menu: 'main', icon: '$channel', group: 'Configuración', roles: [99, 1] } },
    { path: '/brands', name: 'Marcas', component: Brands, meta: { menu: 'main', icon: '$brand', group: 'Configuración', roles: [99, 1] } },
    { path: '/sectors', name: 'Sectores', component: Sectors, meta: { menu: 'main', icon: '$sector', group: 'Configuración', roles: [99, 1] } },
    { path: '/user-logs', name: 'Log de usuarios', component: UserLogs, meta: { menu: 'main', icon: '$user', group: 'Configuración', roles: [99, 1] } },
    { path: '/version-logs', name: 'Log de versiones', component: VersionLogs, meta: { menu: 'main', icon: 'mdi-view-list', group: 'Configuración', roles: [99, 1] } },
    { path: '/integrations', name: 'Integraciones', component: Integrations, meta: { menu: 'main', icon: 'mdi-ray-start-end', group: 'Configuración', roles: [99, 1] } },
    { path: '/holidays', name: 'Festivos', component: Holidays, meta: { menu: 'main', icon: 'mdi-calendar', group: 'Configuración', roles: [99, 1] } },
    { path: '/info', name: 'Información', component: Info, meta: { menu: 'main', icon: 'mdi-information-outline', group: 'Configuración', roles: [99, 1] } },
    { path: '/chat', name: 'Chats', component: Chat, meta: { menu: 'bottom', icon: '$chat', roles: [99, 1, 2] } },
    { path: '/issues', name: 'Incidencias', component: Issues, meta: { menu: 'bottom', icon: '$issue', roles: [99, 1, 2, 3], tag: RouteTag.ISSUES } },
    { path: '/tasks', name: 'Tareas', component: Tasks, meta: { menu: 'bottom', icon: '$task', roles: [99, 1, 2, 4] } }
  ]
});

router.beforeEach((to, from, next) => { // eslint-disable-line no-unused-vars
  const prevent = store.state.app.preventRouting;
  if (!prevent) next();
  else {

    var cancel = typeof prevent.cancel === 'function' ? prevent.cancel : null;
    var accept = typeof prevent.accept === 'function' ? prevent.accept : null;

    store.commit('app/setDialog', {
      ...prevent,
      cancel: function (event) {
        cancel && cancel(event);
        next(false);
      },
      accept: function (event) {
        accept && accept(event);
        store.commit('app/preventRouting', false);
        next();
      },
    });
  }
});


Axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status == 401) {
    console.log("send me to login");
    store.dispatch( 'logout' );
    router.push('/login');
  }
  else {
    return Promise.reject(error);
  }
})

export default router;

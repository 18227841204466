<template>
  <DForm ref="form" :value="value" v-on="{ ...$listeners, input }" always-changed>
    <v-row class="pa-2" no-gutters>
      <v-col class="pa-2" cols="12" md="4">

        <DField
          key="action.title"
          v-bind="title"
          class="mb-6"
        />

        <DField
          key="action.description"
          v-bind="description"
          class="mb-6"
        />

        <DField
          key="place.name"
          v-bind="place"
          class="mb-6"
        />

        <DField
          v-show="isFollow"
          v-bind="categories"
          key="action.categories"
          @input="changeCategories"
          class="mb-6"
        />

        <DField
          key="action.picture.numPictures"
          v-show="!hasSurvey"
          v-bind="pictures"
          class="mb-6"
        />

      </v-col>
      <v-col class="pa-2" cols="12" md="4">

        <DField
          key="status"
          v-bind="status"
          class="mb-6"
          @input="$emit( 'statusChanged', $event )"
        />

        <DField
          key="user.name"
          v-bind="user"
          class="mb-6"
        />

        <DField
          :value="computedDate"
          v-bind="date"
          class="mb-6"
        />

      </v-col>
      <v-col class="pa-2" cols="12">

        <Survey
          v-if="hasSurvey"
          :value="model"
          :readonly="readonly"
          @input="change"
          @empty:elements="$emit('empty:elements',$event)"
        />

        <Pictures
          v-else
          title="Fotografías"
          :value="model"
          :readonly="readonly"
          @input="change"
        />

      </v-col>
    </v-row>
  </DForm>
</template>

<script>

  import Survey from './survey.vue';
  import Pictures from './pictures.vue';
  import { get, formatDate } from '@/utils';
  import { CampaignActionType } from '@/utils/constants';

  export default {
    components: { Survey, Pictures },
    props: {
      value: Object,
      readonly: Boolean
    },
    data: function() {
      return {
        model: {},
        addCategories: false,
        listCategories: null
      }
    },
    computed: {
      computedDate() {
        return this.value ? formatDate( this.value.date, {
          year:  "numeric",
          month: "2-digit",
          day:   "2-digit"
        }) : null;
      },
      actionType() {
        return get( this.model, 'action.type' );
      },
      hasSurvey() {
        return this.actionType !== CampaignActionType.PICTURES;
      },
      isFollow() {
        return this.actionType === CampaignActionType.FOLLOW;
      },
      title() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Título',
          dense: true,
          readonly: true
        }
      },
      description() {
        return {
          field: "text",
          fieldStyle: 1,
          label: "Descripción",
          dense: true,
          readonly: true
        };
      },
      user() {
        return {
          field: "text",
          fieldStyle: 1,
          label: "Usuario",
          dense: true,
          readonly: true
        };
      },
      place() {
        return {
          field: "text",
          fieldStyle: 1,
          label: "Lugar",
          dense: true,
          readonly: true
        };
      },
      status() {
        return {
          field: "select",
          fieldStyle: 1,
          label: "Estado",
          dense: true,
          readonly: this.readonly,
          items: [
              { value: 0, text: 'Pendiente' },
              { value: 1, text: 'Realizada' },
              { value: 2, text: 'Validada' }
            ]
        };
      },
      date() {
        return {
          field: "text",
          fieldStyle: 1,
          label: "Fecha de la tarea",
          dense: true,
          future: true,
          readonly: true
        };
      },
      pictures() {
        return {
          field: "number",
          fieldStyle: 1,
          label: "Mínimo # de fotos",
          min: 0,
          max: 300,
          dense: true,
          readonly: true
        };
      },
      categories() {
        return {
          field: "text",
          fieldStyle: 1,
          label: "Categorías",
          dense: true,
          readonly: this.addCategories
            ? this.readonly
            : true
        };
      }
    },
    watch: {
      model( value ) {
        this.listCategories = ( get( value, 'action.categories' ) || '' ).trim();
      }
    },
    methods: {
      input( value ) {
        this.model = value;
        this.addCategories = get( value, 'action.addCategories' ) || false;
        if ( this.$listeners.input )
          this.$listeners.input( value );
      },
      changeCategories( value ) {
        value = ( value || '' ).trim();
        if ( this.listCategories !== value ) {
          this.model.categories = value;
        } else {
          this.model.categories = null;
        }
      },
      change() {
        this.$emit( 'input', this.model );
      },
      reset() {
        this.$refs.form.reset();
        this.model = { ...this.model };
      }
    }
  }
</script>

<template>
  <v-card
    class="card"
    elevation="0"
    v-bind="$attrs"
    v-on="$listeners"
    :ripple="false"
    :style="style"
    tile
  >
    <slot v-for="( s, name ) in $slots" :name="name"/>
  </v-card>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      border:       [ Boolean, String ],
      borderTop:    [ Boolean, String ],
      borderBottom: [ Boolean, String ],
      borderLeft:   [ Boolean, String ],
      borderRight:  [ Boolean, String ]
    },
    computed: {
      style() {
        return {
          ...this.val( this.border ),
          ...this.val( this.borderTop,    'Top'    ),
          ...this.val( this.borderRight,  'Right'  ),
          ...this.val( this.borderBottom, 'Bottom' ),
          ...this.val( this.borderLeft,   'Left'   )
        };
      }
    },
    methods: {
      val( value, type ) {
        type = type || '';
        const style = {};
        if ( value ) {
          if ( typeof value === 'string' ) {
            style[`border${type}Width`] = '1px';
            style[`border${type}Color`] = this.color( value );
          } else {
            style[`border${type}Width`] = '1px';
          }
        }
        return style;
      },
      color( value ) {
        if ( typeof value === 'string' ) {
          if ( value.charAt(0) === '#' ) return value;
          return `var(--v-${value}-base)`;
        }
        return null;
      }
    }
  };
</script>

<style scoped>
  .card {
    border-width: 0;
    border-style: solid;
    border-color: var(--v-border-base);
  }
</style>

<template>
  <v-list class="chat-list py-0">
    <v-list-item-group
      :value="value"
      @change="$emit( 'input', $event )"
    >
      <v-list-item
        v-for="( chat, index ) in chats"
        :key="index"
        :ripple="false"
        :value="chat"
        color="tertiary"
        class="px-0"
      >
        <template v-slot>
          <v-list-item-content class="pa-0" :style="{ backgroundColor: chat.pendingMessages ? '#f4f4f4' : null }">

            <v-row class="pa-2" align="center" no-gutters>
              <div class="grow pr-2">
                <p class="single-line mb-1">{{ chat.name }}</p>
                <p class="single-line subtitle-2 tertiary--text mb-0" v-if="chat.mainUser">
                  {{ userName( chat.mainUser, true ) }}
                </p>
              </div>
              <div class="shrink text-right" v-if="chat.pendingMessages">
                <v-chip color="tertiary" class="font-weight-bold" style="padding: 5px;" x-small dark>
                  {{ chat.pendingMessages > 100 ? '+99' : chat.pendingMessages }}
                </v-chip>
                <p class="caption tertiary--text mb-0">
                  hace {{ timefromNow( chat.lastMessage.date ) }}
                </p>
              </div>
            </v-row>

            <div
              class="single-line body-2 pa-2 pt-0"
              v-html="lastMessage( chat )"
              style="color:#999;">
            </div>

          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>

  import moment from 'moment';
  import { userName } from '@/utils';

  export default {
    props: {
      value: Object,
      items: Array
    },
    computed: {
      chats() {
        return this.items || [];
      }
    },
    methods: {
      userName,
      lastMessage( chat ) {
        const { lastMessage, participants } = chat;
        if ( lastMessage ) {
          const user = participants.find( u => u.idUser === lastMessage.idUser );

          const userName = this.$store.state.user.id === lastMessage.idUser ? 'Tu' : (user ? user.name : "");
          var { message } = lastMessage;
          if ( user ) message = `<span>${ userName }:</span> ${ message }`;
          return message;
        }
        return 'Todavía no hay mensajes...';
      },
      timefromNow( value ) {
        return moment( value ).fromNow(true);
      }
    }
  }
</script>

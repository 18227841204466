<template>
  <Card
    class="dtable"
    :class="{ draggable }"
    :border="border"
    v-bind="$attrs"
  >
    <div :style="{ minWidth }">
      <div v-if="!hideHeader" class="dtable-header" no-gutters>
        <div
          v-if="showSelect"
          class="dtable-cell selector"
          :class="{ hidden: singleSelect }"
          no-gutters
        >
          <v-simple-checkbox
            color="white"
            :value="!!sortered.length && selected.length === sortered.length"
            :indeterminate="selected.length > 0 && selected.length < sortered.length"
            :ripple="false"
            @input="selectAll"
          />
        </div>
        <div
          class="dtable-cell"
          v-for="(column, index) in columns"
          :key="index"
          v-bind="column.attrs"
          no-gutters
        >
          <span @click="column.header.sorteable !== false && orderBy(column)">
            <slot :name="`header.${column.header.value}`" v-bind="column">
              {{ column.header.text }}
            </slot>
          </span>

          <v-icon :class="orderIcon(column)" :style="column.icon" color="white">
            $dropdown
          </v-icon>
        </div>
      </div>

      <v-expand-transition>
        <div v-show="showFilters" :key="key">
          <div class="dtable-filters" no-gutters>
            <div v-if="showFirstCell" class="dtable-cell selector" no-gutters>
              <v-icon>
                $filter
              </v-icon>
            </div>
            <div
              class="dtable-cell px-1"
              v-for="(column, index) in columns"
              :key="index"
              v-bind="column.attrs"
              no-gutters
            >
              <DField
                v-if="column.filterable"
                v-bind="column.filter.attrs"
                :value="search[columnField(column)]"
                @blur="applyFilters"
                @keypress.enter="applyFilters"
                @change="applyFilters"
                @input="inputFilter(column, $event)"
                field-style="1"
                class="fill-width"
                hide-details
                clearable
                dense
              />
            </div>
          </div>
          <v-divider />
        </div>
      </v-expand-transition>

      <component
        v-if="sortered.length"
        :is=" draggable ? 'draggable' : 'div'"
        v-model="internalItems"
        v-bind="draggableOptions"
        @change="$emit( 'dragg:change', $event )"
      >
        <v-fade-transition class="dtable-body" group>
          <div
            class="dtable-row"
            v-for="row in sortered"
            :class="computeRowClass({ selected: row[0].selected }, row[0].item )"
            :key="`item-${ row[0].key }`"
            @click="$emit( 'click-item', row[0].item )"
            @dblclick="$emit( 'dblclick-item', row[0].item )"
            no-gutters
          >
            <div v-if="showFirstCell" class="dtable-cell selector" no-gutters>

              <v-icon
                v-if="draggable"
                class="dtable-handle"
                v-text="'mdi-drag-vertical'"
                color="#AAA"
              />

              <v-simple-checkbox
                v-if="showSelect"
                color="primary"
                :ripple="false"
                :value="isSelected( row[0].item )"
                @input="toggle( row[0].item )"
              />

            </div>
            <div class="dtable-cell" v-for="( column, index ) in row" :key="index" v-bind="column.attrs" no-gutters>

              <slot :name="`item.${ column.header.value }`" v-bind="column">
                <span class="dtable-cell-content body-grupo-ot" v-html="column.value" :title="column.value"/>
              </slot>

            </div>
            <div v-if="actions.length" class="dtable-actions" no-gutters>

              <Btn v-for="( action, index ) in actions" :key="index" color="secondary"
                class="ml-1" @click="$emit( action, row[0].item )" small icon>
                <v-icon>{{ actionsIcon[action] || '$' + action }}</v-icon>
              </Btn>

            </div>
          </div>
        </v-fade-transition>
      </component>
      <div v-else class="dtable-row nodata">
        <div class="dtable-cell">
          {{ noDataText || "No hay elementos para mostrar" }}
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
/* eslint-disable */
import Draggable from 'vuedraggable';
import { get } from '@/utils';
const compareOptions = { numeric: true, sensitivity: 'base' };
const defaultActions = [ 'edit', 'duplicate', 'delete' ];
const validActions = defaultActions.concat([ 'up', 'down', 'view', 'calendar' ]);

function sortItems( items, order ) {
  if ( ! order.length ) return items;
  const collator = new Intl.Collator( 'es', { sensitivity: 'accent', usage: 'sort' });
  return items.sort(( a, b ) => {
    for ( var i = 0; i < order.length; i++ ) {

      var col = order[i].column;
      var sortA = a[col].initial;
      var sortB = b[col].initial;

      if ( sortA && typeof sortA === 'object' && sortA !== a[col].value )
        sortA = a[col].value;

      if ( sortB && typeof sortB === 'object' && sortB !== b[col].value )
        sortB = b[col].value;

      if ( sortA === null && sortB === null ) {
        continue;
      }

      if ( order[i].order > 0 ) {
        [sortA, sortB] = [sortB, sortA];
      }

      [ sortA, sortB ] = [ sortA, sortB ].map(s => (s || '').toString().toLocaleLowerCase());

      if ( sortA !== sortB ) {
        if ( !isNaN( sortA ) && !isNaN( sortB )) return Number( sortA ) - Number( sortB );
        return collator.compare( sortA, sortB );
      }
    }
    return 0;
  });
}

export default {
  components: { Draggable },
  props: {
    value: Array,
    headers: Array,
    items: Array,
    filters: Object,
    sorting: Function,
    rowClass: Function,
    multipleSort: {
      type: Boolean,
      default: true
    },
    showActions: [ String, Boolean ],
    showSelect: Boolean,
    showFilters: Boolean,
    singleSelect: Boolean,
    draggable: Boolean,
    hideHeader: Boolean,
    noDataText: String,
    itemKey: [String, Function],
    filterOnBlur: Boolean,
    disableSorting: Boolean,
    orderItems: Array,
    minWidth: {
      type: String,
      default: '1300px'
    },
    border: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      key: 0,
      selected: this.value || [],
      internalItems: ( this.items || []).slice(),
      order: ( this.orderItems || [] ).slice(),
      search: this.filters || {},
      actionsIcon: {
        up: 'mdi-chevron-up',
        down: 'mdi-chevron-down'
      },
      draggableOptions: {
        animation: 0,
        group: 'rows',
        disabled: false,
        ghostClass: 'dtable-ghost',
        handle: '.dtable-handle'
      }
    };
  },
  watch: {
    value( value ) {
      this.selected = value;
    },
    items( value ) {
      this.internalItems = ( value || [] ).slice();
    },
    selected( value ) {
      this.$emit( 'input', value );
    },
    orderItems( value ) {
      this.order = ( value || [] ).slice();
    },
    filters( value ) {
      this.search = value;
      this.applyFilters();
    }
  },
  computed: {
    showFirstCell() {
      return this.showSelect || this.draggable;
    },
    columns() {
      var column, width, align, sorteable, filterable, filter, order;
      return ( this.headers || [] ).map(( header, column ) => {

        filter = this.prepareFilter( header.filter );
        filterable = header.filterable !== false;
        sorteable = header.sorteable !== false;
        align = header.align;
        width = typeof header.width == 'string'
          ? header.width
          : header.width > 0 ? `${ header.width }px` : 'auto';

        column = {
          column,
          filter,
          header,
          sorteable,
          filterable,
          attrs: {
            class: {
              'justify-start': !align || align === 'start' || align === 'left',
              'justify-center': align === 'center',
              'justify-end': align === 'end' || align === 'right',
              [`cell-${header.value}`]: true,
              sorteable,
              filterable
            },
            style: width === 'auto' ? null : {
              flex: `0 0 ${ width }`,
              maxWidth: width,
              minWidth: 'auto'
            }
          }
        };

        if (( order = this.order.findIndex( a => a.header.value === header.value )) !== -1 ) {
          column.order = this.order[order].order;
          this.order.splice( order, 1, column );
        }

        return column;
      })
    },
    internal() {
      var common;
      return this.internalItems.map(( item, row ) => {

        let key = ( typeof this.itemKey === "function"
          ? this.itemKey(item)
          : item[this.itemKey]) || `row-${row}`;

        common = {
          key,
          selected: this.isSelected( item ),
          select: () => this.select( item ),
          unselect: () => this.unselect( item ),
          toggle: () => this.toggle( item )
        };

        return this.columns.map( col => this.compute( item, col, common ));
      });
    },
    sortered() {
      return this.order.length && !this.disableSorting
        ? sortItems( this.internal.slice(), this.order )
        : this.internal;
    },
    actions() {
      return this.showActions
        ? typeof this.showActions === 'boolean'
          ? defaultActions
          : this.showActions
              .split(/\s*,\s*/)
              .filter( a => validActions.indexOf( a ) !== -1 )
        : [];
    }
  },
  methods: {
    prepareFilter(filter) {
      filter = filter || {};
      filter = {
        ...filter,
        attrs: {
          ...filter.attrs,
          field: filter.type || "text",
          items: filter.items,
        },
      };
      return filter;
    },
    compute(item, col, options) {

      const key = col.header.value;
      const { display } = col.header;
      const _default = col.header.default;

      var value = get(item, key);
      if (typeof value === "undefined" && typeof _default !== "undefined") value = _default;
      var initial = value;

      if (display) {
        if (typeof display === "object") value = display[value];
        else if (typeof display === "function")
          value = display(value, col.header, item);
        else if (typeof value === "object") value = get(value, String(display));
      }

      return {
        ...col,
        ...options,
        initial,
        value,
        item,
      };
    },
    computeRowClass( classes, row ) {
      return (this.rowClass && this.rowClass( classes, row )) || classes;
    },
    columnField(column) {
      var field = column.header.value;
      if (column.filter && column.filter.field) field = column.filter.field;
      return field;
    },
    inputFilter(column, value) {
      const field = this.columnField(column);
      const { compute } = column.filter;

      if (typeof compute === "function")
        this.search = { ...this.search, ...compute(value, field) };
      else this.search[field] = value;

      if (!this.filterOnBlur) this.applyFilters();
    },
    applyFilters() {
      this.$emit("filters", this.search);
    },
    orderIcon(column) {
      const show = this.order.indexOf(column) !== -1;
      return {
        "dtable-order-icon": true,
        show,
        down: show && column.order > 0,
        up: show && column.order < 0,
      };
    },
    orderBy(column) {

      let { order } = this;
      var index = order.indexOf(column);

      switch (column.order) {
        case 1:
          column.order = -1;
          break;
        case -1:
          column.order = 0;
          if (index !== -1) order.splice(index, 1);
          break;
        default:
          column.order = 1;
          if ( this.multipleSort ) {
            if (index === -1) order.push(column);
          } else {
            order = [ column ];
          }
      }

      this.order = this.sorting ? this.sorting(column, order) : order;
      this.$emit("orderBy", this.order);
    },
    isSelected(item) {
      return this.selected.indexOf(item) !== -1;
    },
    select(item) {
      if (!this.isSelected(item)) {
        this.selected.push(item);
        return true;
      }
    },
    unselect(item) {
      var index = this.selected.indexOf(item);
      if (index !== -1) this.selected.splice(index, 1);
    },
    toggle(item) {
      this.select(item) || this.unselect(item);
    },
    selectAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.sortered.map((a) => a[0].item);
    }
  },
  beforeMount() {
    if (this.filters && Object.keys(this.filters).length) {
      this.applyFilters();
    }
  },
};
</script>

<style>
  .dtable {
    font-size: .9rem;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .dtable-header, .dtable-filters, .dtable-row, .dtable-cell, .dtable-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
  }
  .dtable-header {
    color: white;
    font-weight: 700;
  }
  .dtable-header > div {
    background-color: var(--v-secondary-base);
  }
  .dtable-header.sorteable > div > span {
    cursor: pointer;
  }
  .dtable-header, .dtable-row, .dtable-filters {
    min-height: 40px;
  }
  .dtable-header .v-icon {
    color: white;
  }
  .dtable-filters {
    background-color: #eee;
  }
  .dtable-body {
    display: block;
  }
  .dtable-row {
    cursor: pointer;
    position: relative;
  }
  .dtable-row > .dtable-cell > .dtable-cell-content {
    cursor: initial;
    font-size: 1em;
  }
  .dtable-row:last-child {
    border-bottom: 0;
  }
  .dtable-row:hover {
    background-color: #fafafa;
  }
  .dtable-row.selected, .dtable-row.selected > .dtable-actions {
    background-color: #eee;
  }
  .dtable-row:hover > .dtable-actions {
    display: flex;
  }
  .dtable-cell {
    align-items: center;
    padding: 4px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 30px;
    border-bottom: 1px solid var(--v-border-base);
  }
  .dtable-row.nodata .dtable-cell {
    border-bottom: 0;
  }
  .dtable-cell.selector {
    position: relative;
    flex: 0 0 40px;
    max-width: 40px;
    justify-content: center;
    align-items: center;
    min-width: auto;
  }
  .dtable.draggable .dtable-cell.selector {
    flex: 0 0 60px;
    max-width: 60px;
  }
  .dtable-cell.selector .v-simple-checkbox,
  .dtable-header .sorteable > span {
    cursor: pointer;
  }
  .dtable-cell.hidden {
    visibility: hidden;
  }
  .dtable-order-icon {
    width: 0;
    pointer-events: none;
    transition: width opacity transform .135s ease;
    opacity: 0;
  }
  .dtable-order-icon.show, .dtable-order-icon.down {
    width: 24px;
    pointer-events: auto;
  }
  .dtable-order-icon.show {
    opacity: 1;
  }
  .dtable-order-icon.down {
    width: 24px;
    transform: rotate(0);
  }
  .dtable-order-icon.up {
    transform: rotate(180deg);
  }
  .dtable-actions {
    display: none;
    position: absolute;
    top: 0; right: 8px;
    height: 100%;
    align-items: center;
    background-color: #fafafa;
    border-bottom: 1px solid var(--v-border-base);
  }
  .dtable-row.nodata {
    text-transform: uppercase;
    text-align: center;
    display: block;
    padding: 1em;
    color: #777;
    cursor: inherit;
  }
  .dtable .v-input__slot {
    height: 32px !important;
    min-height: 32px !important;
  }
  .dtable .v-select__slot > div, .dtable .v-input__append-inner {
    margin-top: 4px !important;
    padding: 0;
  }
  .dtable .v-select__slot > .v-select__selections {
    margin-top: 0 !important;
  }
  .dtable .v-input__icon--clear > .v-icon {
    width: 16px !important;
    height: 16px !important;
  }
  .dtable .v-select__selections {
    padding: 0 !important;
  }
  .dtable .v-input:not(.v-autocomplete) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    flex: 1 !important;
  }
  .dtable .v-select__selections > input:not([type="text"]) {
    width: 0;
    height: 0;
    padding: 0;
  }
  .dtable .v-select__selections > .caption {
    vertical-align: middle;
  }
  .dtable-handle {
    position: absolute !important;
    left: 0;
    width: 18px;
    cursor: grab;
  }
  .dtable-ghost {
    background-color: #f4f4f4;
  }
</style>

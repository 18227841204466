<template>
  <div>
    <svg
      xmlsn="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      :viewBox="`0 0 ${width} ${height}`"
      :style="computedPadding"
      @mousemove="onMouseMove"
    >
      <template v-for="( elm, i ) in computedItems">
        <template v-if="elm.svg.path">

          <path
            :key="i"
            :stroke="elm.color"
            :stroke-width="lineWidth"
            :d="elm.svg.path"
            fill="none"
          />

          <circle
            v-for="( pt, e ) in elm.svg.pt"
            :key="`p-${i}-${e}`"
            :cx="pt.x"
            :cy="pt.y"
            :fill="elm.color"
            :r="selected && selected.index === i && selected.n === e ? 4 : 2"
          />

        </template>
      </template>
    </svg>
  </div>
</template>

<script>
import { toArray, round } from '@/utils';

function map( v, min, max, valMin = 0, valMax = 1 ) {
  return valMin + v * ( valMax - valMin )/( max - min );
}

export default {
  props: {
    items: Array,
    width: Number,
    height: Number,
    padding: Number,
    lineWidth: Number,
    min: Number,
    max: Number,
    sensivility: [ Number, String ]
  },
  data: () => ({
    selected: null
  }),
  watch: {
    selected( item ) {
      this.$emit( 'item:hover', item );
    }
  },
  computed: {
    computedItems() {
      return toArray( this.items ).map( item => {
        return {
          ...item,
          svg: this.createPath( item.values )
        }
      });
    },
    computedPadding() {
      const pd = this.padding + 'px';
      return { top: pd, bottom: pd, left: pd, right: pd };
    }
  },
  methods: {
    createPath( values ) {

      const r = { path: '', pt: [] };
      if ( values.length < 2 ) return r;

      const min = Math.min( this.min, this.max );
      const max = Math.max( this.min, this.max );
      const X = (( this.width - 8 ) / ( values.length - 1 )) || 0;
      var path = [];

      values.forEach(( y, i ) => {
        let x = 4 + round( X * i, 2 );
        y = 2 + round( map( y, min, max, ( this.height - 4 ), 0 ), 2 );
        path.push(( i ? 'L': 'M' ) + [ x, y ].join(' '));
        r.pt.push({ x, y });
      });

      r.path = path.join(' ');
      return r;
    },
    onMouseMove(e) {
      this.selected = this.findElementByPoint( e.offsetX, e.offsetY );
    },
    findElementByPoint( x, y ) {
      var sensivility = Number( this.sensivility ) || 10;
      for ( var i = 0, item, point, dx, dy, n; i < this.computedItems.length; i++ ) {
        item = this.computedItems[i];
        for ( n = 0; n < item.svg.pt.length; n++ ) {
          point = item.svg.pt[n];
          dx = Math.abs( point.x - x );
          dy = Math.abs( point.y - y );
          if ( dx <= sensivility && dy <= sensivility ) {
            return {
              item,
              point,
              index: i,
              value: item.values[n],
              n
            };
          }
        }
      }
    },
  }
}
</script>

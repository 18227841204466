<template>
  <v-sheet
    ref="graph"
    v-resize="onResize"
    color="transparent"
    :class="classes"
    :style="{ padding: computedPadding + 'px' }"
    :width="width"
    :height="height"
    :min-width="minWidth"
    :min-height="minHeight"
  >
    <div
      class="d-stats-linear--values"
      :style="{
        paddingTop: computedPadding + 'px',
        paddingBottom: computedPadding + 'px'
      }"
    >
      <div
        v-for="( value, index ) in values"
        :key="index"
      >
        <span>{{ value }}</span>
      </div>
      <div>
        <span>{{ computedMin }}</span>
      </div>
    </div>
    <template v-if="items.length > 1">

      <component
        class="d-stats-linear--body"
        v-bind="dim"
        :is="computedType"
        :items="computedItems"
        :padding="computedPadding"
        :line-width="computedLineWidth"
        :min="computedMin"
        :max="computedMax"
        :sensivility="sensivility"
        @item:hover="selected = $event"
      >
        <template v-slot:info="item">
          <slot
            v-if="item"
            name="info"
            v-bind="{ value: item.value, item }"
          >
            {{ item.value }}
          </slot>
        </template>
      </component>

    </template>
    <div
      class="d-stats-linear--labels"
      :style="{
        paddingLeft: computedPadding + 'px',
        paddingRight: computedPadding + 'px'
      }"
    >
      <div
        v-for="( label, index ) in computedLabels"
        :key="index"
      >
        <span>
          <slot name="label" v-bind="{ index }">
            {{ label }}
          </slot>
        </span>
      </div>
    </div>
    <v-sheet
      class="d-stats-linear--info elevation-2 pa-2"
      :class="{ 'show': !!selected }"
      :style="infoPosition"
      :color="selected ? selected.item.color[0] : ''"
      dark
    >
      <slot
        v-if="selected"
        name="info"
        v-bind="selected"
      >
        {{ selected.value }}
      </slot>
    </v-sheet>
  </v-sheet>
</template>

<script>
import LinearBody from './LinearBody';
import BarsBody from './BarsBody';
import { toArray, round } from '@/utils';

export default {
  components: { LinearBody, BarsBody },
  props: {
    type: {
      type: String,
      default: 'linear'
    },
    items: Array,
    labels: Array,
    itemKey: [ String, Array ],
    color: [ String, Array ],
    height: [ Number, String ],
    width: [ Number, String ],
    minWidth: {
      type: [ Number, String ],
      default: 400
    },
    minHeight: {
      type: [ Number, String ],
      default: 300
    },
    min: [ Number, String ],
    max: [ Number, String ],
    padding: [ Number, String ],
    lineWidth: {
      type: [ Number, String ],
      default: 2
    },
    minRowHeight: {
      type: [ Number, String ],
      default: 48
    },
    sensivility: {
      type: [ Number, String ],
      default: 10
    }
  },
  data: () => ({
    dim: { width: 0, height: 0 },
    selected: null
  }),
  computed: {
    classes() {
      return {
        'd-stats-linear': true,
        '--linear': this.type === 'linear',
        '--bars': this.type !== 'linear'
      }
    },
    computedType() {
      if ( this.type === 'linear' ) {
        return 'LinearBody';
      } else {
        return 'BarsBody';
      }
    },
    computedKeys() {
      return toArray( this.itemKey )
        .filter( v => typeof v === 'string' );
    },
    computedColors() {
      return toArray( this.color )
        .filter( v => typeof v === 'string' );
    },
    computedLabels() {

      if ( ! this.dim.width ) return [];

      const minColumn = 48;
      const labels = toArray( this.labels );
      const items = (this.computedItems[0] && this.computedItems[0].values) || [];
      const step = items.length
        ? Math.ceil( items.length / ( this.dim.width / minColumn )) || 1
        : 1;

      const arr = Array.from({ length: items.length }).map(() => '' );
      for ( var i = 0; i < items.length; i += step ) {
        arr[i] = labels[i] || '';
      }

      return arr;
    },
    computedItems() {

      const keys = this.computedKeys;
      const colors = this.computedColors;
      const items = toArray( this.items ).filter( Boolean );

      return keys.map(( key, index ) => {

        let color = toArray( colors[ index % colors.length ] || 'black' );
        let values = items.map( item => {
          if ( typeof item[key] !== 'number' ) return 0;
          return item[key];
        });

        return { key, color, values };
      });
    },
    computedPadding() {
      return Math.max( Number( this.padding ) || 0, 10 );
    },
    computedMin() {
      if ( this.min === '' || this.min == null ) {
        var min = Infinity;
        this.computedItems.forEach( item => {
          min = Math.min.apply( Math, [ min ].concat( item.values ))
        });
        if ( min === Infinity ) return 0;
        return min;
      }
      return Number( this.min ) || 0;
    },
    computedMax() {
      if ( this.max === '' || this.max == null ) {
        var max = -Infinity;
        this.computedItems.forEach( item => {
          max = Math.max.apply( Math, [ max ].concat( item.values ))
        });
        if ( max === -Infinity ) return 0;
        return max;
      }
      return Number( this.max ) || 0;
    },
    computedLineWidth() {
      return Number( this.lineWidth ) || 2;
    },
    values() {

      const { dim } = this;
      const min = Math.min( this.computedMin, this.computedMax );
      const max = Math.max( this.computedMin, this.computedMax );

      if ( ! dim.height || min === max ) return [];

      var rows = Math.round( dim.height / Number( this.minRowHeight ));
      var values = [];
      var range = ( max - min ) / rows;

      for ( var i = 1; i < rows; i++ ) {
        values.push( round( min + range * i, 1 ));
      }
      values.push( max );

      return values.reverse();
    },
    infoPosition() {
      const { selected } = this;
      const style = {};
      if ( selected ) {

        style.top = selected.point.y + 'px';
        style.left = selected.point.x + 'px';

        var translate = ['-35%','50%'];

        if ( selected.point.x <= 40 ) {
          translate[0] = '50%';
        } else if ( selected.point.x >= this.dim.width - 40 ) {
          translate[0] = '-100%';
        }

        if ( selected.point.y >= this.dim.height - 40 ) {
          translate[1] = '-100%';
        }

        style.transform = `translate(${translate.join(',')})`;
      }
      return style;
    }
  },
  watch: {
    width: 'onResize',
    height: 'onResize',
    items: 'onResize',
    computedType() {
      this.selected = null;
    },
    selected( value ) {
      this.$emit( 'over:point', value );
    }
  },
  methods: {
    onResize() {
      this.$nextTick(() => {
        const graph = this.$refs.graph && this.$refs.graph.$el;
        if ( graph ) {
          const p = this.computedPadding * 2;
          this.dim.width = ( graph.clientWidth || p ) - p;
          this.dim.height = ( graph.clientHeight || p ) - p;
        }
      })
    },
  },
  mounted() {
    this.onResize();
  }
}
</script>

<style>
.d-stats-linear {
  flex: 1 1 auto;
  position: relative;
  margin: 0 0 30px 30px;
}
.d-stats-linear--values {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: absolute;
  left: 0; right: 0;
  top: 2px; bottom: 2px;
}
.d-stats-linear--values > div {
  flex: 0 0 auto;
  border-bottom: 1px solid #EEE;
}
.d-stats-linear--values > div > span {
  position: absolute;
  right: 100%;
  font-size: 10px;
  transform: translateY(-50%);
}
.d-stats-linear--labels {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: absolute;
  left: 4px; right: 4px; top: 0;
  bottom: 0;
}
.d-stats-linear--labels > div {
  flex: 0 0 auto;
  border-right: 1px solid #EEE;
}
.d-stats-linear--labels > div > span {
  position: absolute;
  top: 100%;
  font-size: 10px;
  font-weight: bold;
  transform: rotate(20deg);
}
.d-stats-linear.--bars .d-stats-linear--labels {
  justify-content: flex-start;
}
.d-stats-linear.--bars .d-stats-linear--labels > div {
  flex: 1 1 auto;
  text-align: center;
  position: relative;
}
.d-stats-linear.--bars .d-stats-linear--labels > div > span {
  transform: none;
  left: 0; right: 0;
}
.d-stats-linear:not(.--bars) .d-stats-linear--labels > div:last-child > span {
  right: 8px;
}
.d-stats-linear--body {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}
.d-stats-linear--body svg {
  width: 100%;
  height: 100%;
}
.d-stats-linear--info {
  position: absolute;
  border-radius: 4px;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: 2;
  font-size: 10px;
}
.d-stats-linear--info.show {
  transition: opacity .3s;
  opacity: 1;
  width: auto;
  height: auto;
}
</style>

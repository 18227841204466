
function _get( obj, key ) {
  if ( obj && key ) {
    const args = toArray( arguments )
    args.splice( 0, 2, obj[key] );
    return _get.apply( null, args );
  }
  return obj;
}

export function get( obj, url ) {
  url = String( url || '' );
  if ( obj ) return _get.apply( null, [ obj ].concat( url.split('.')))
  return obj;
}

function _set( obj, key, value ) {
  if ( obj && key ) {
    const args = toArray( arguments );
    if ( args.length <= 3  ) obj[key] = value;
    else {
      if ( obj[key] == null ) obj[key] = {};
      args.splice( 0, 2, obj[key] );
      _set.apply( null, args );
    }
  }
}

export function set( obj, url, value ) {
  url = url || '';
  if ( obj ) _set.apply( null, [ obj ].concat( url.split('.')).concat([ value ]));
  return obj;
}

function isObject( obj, notArrays ) {
  var result = obj != null && typeof obj == 'object';
  if ( result && notArrays ) result = result && !Array.isArray( obj );
  return result;
}

export function merge( a, b, notArrays ) {
  for ( var key in b ) {
    if ( isObject( a[key], notArrays ) && isObject( b[key], notArrays )) {
      a[key] = merge( a[key], b[key], notArrays );
    } else {
      a[key] = b[key];
    }
  }
  return a;
}

export function round( value, decimals ) {
  decimals = decimals || 0;
  var t = Math.pow( 10, decimals );
  return Math.round( value * t ) / t;
}

export function loop( value, min, max ) {
  var a = ( value - min ) % ( max - min );
  if ( a < 0 ) return max + a
  return min + a;
}

export function formatSize( value ) {
  return value != null
    ? String( value ).replace( /^([+-]?\d*(\.\d+)?)$/, '$1px' )
    : null;
}

export function formatDate( timestamp, options ) {
  return new Date( timestamp ).toLocaleString( navigator.language, options );
}

export function parseDate( string ) {
  var [ date, time ] = string.trim().split(/\s+/);
  var [ day, month, year ] = date.split('/').map( parseFloat );
  var [ hour, min, sec ] = ( time || '0:0:0' ).split(':').map( parseFloat );
  return new Date( year, month - 1, day, hour, min, sec );
}

export function copy( a ) {
  try { return JSON.parse( JSON.stringify( a )); }
  catch(e) {
    if ( a === undefined ) return undefined;
    return null;
  }
}

export function compare( a, b ) {
  if ( Array.isArray( a )) a = a.slice().sort();
  if ( Array.isArray( b )) b = b.slice().sort();
  try { return JSON.stringify(a) === JSON.stringify(b) }
  catch(e) { return false; }
}

export function limit( v, min, max ) {
  if ( v <= min ) return min;
  if ( v >= max ) return max;
  return v;
}

export function between( v, a, b ) {
  return v >= a && v <= b;
}

export function remove( array, items ) {
  toArray( items )
    .forEach(( item, index ) => {
      if (( index = array.indexOf( item )) !== -1 )
        array.splice( index, 1 );
    });
  return array;
}

export const Any = { validator: () => true };

export function toArray( value ) {
  if ( value == null ) return [];
  if ( Array.isArray( value )) return value;
  if ( typeof value !== 'string' && value.length != null ) return Array.prototype.slice.call( value );
  return [ value ];
}

export function isEmpty( a ) {
  return a && typeof a.length !== 'undefined'
    ? !a.length
    : !a && a !== 0;
}

export function query( options ) {

  const params = { page: 1, filters:[] };
  var value;

  for ( var field in options ) {
    value = options[field];
    if ( value != null ) {
      switch ( typeof value ) {
        case 'string':
          params.filters.push({ field, stringValue: value })
          break;
          case 'boolean':
            params.filters.push({ field, booleanValue: value })
            break;
        case 'number':
          if ( Number.isInteger( value )) {
            if ( value <= 2147483647 && value >= -2147483648 ) {
              params.filters.push({ field, intValue: value });
            } else {
              params.filters.push({ field, doubleValue: value });
            }
          } else {
            params.filters.push({ field, doubleValue: value });
          }
          break;
        case 'object':
          if ( Array.isArray( value )) {
            if ( field === 'id' ) field += 's';
            params.filters.push({ field, listValue: value });
          } else if ( 'middleJourney' in value && 'start' in value ) {
            params.filters.push({ field, timetableValue: value });
          } else if ( 'start' in value && 'end' in value ) {
            params.filters.push({ field, rangeValue: value });
          } else if ( 'id' in value ) {
            params.filters.push({ field, intValue: value.id });
          }
          break;
      }
    }
  }
  return params;
}

export function Params() {

  const args   = toArray( arguments );
  const params = { ...args[0] };
  var filters = params.filters || [];

  for ( var i = 1; i < args.length; i++ ) {
    if ( args[i] ) {
      if ( args[i].filters ) filters = filters.concat( args[i].filters );
      Object.assign( params, args[i] );
    }
  }

  if ( filters.length ) params.filters = filters;
  return params;
}

export function Filter( items ) {
  if ( Array.isArray( items )) {
    const params = Params.apply( null, toArray( arguments ).slice( 1 ));
    if ( params.filters ) {
      var type, field, value;
      params.filters.forEach( filter => {

        field = filter.field;
        type  = filter.intValue != null
          ? 'intValue'     : filter.doubleValue != null
          ? 'doubleValue'  : filter.stringValue != null
          ? 'stringValue'  : filter.booleanValue != null
          ? 'booleanValue' : filter.listValue != null
          ? 'listValue'    : null;

        if ( ! type ) return;
        value = filter[type];

        if ( type !== 'listValue' ) items = items.filter( a => a[field] === value );
        else items = items.filter( a => a[field] && a[field].indexOf && a[field].indexOf( value ) !== -1 );
      })
    }
  }
  return items;
}

export function parent( vn, match ) {
  if ( ! vn.$parent || ! vn.$parent.$vnode ) return null;
  if ( match ) return vn.$parent.$vnode.tag.search( match ) !== -1
    ? vn.$parent
    : parent( vn.$parent, match );
  return vn.$parent;
}

export function direction( dir, complete ) {
  if ( dir ) {

    var str = [];
    dir.name && str.push( dir.name );

    if ( complete ) {
      dir.area && str.push(`area de ${ dir.area }`);
      dir.street && str.push( dir.street );
      dir.city && str.push( dir.city );
      dir.province && str.push( dir.province );
      dir.postalCode && str.push( dir.postalCode );
    }

    return str.join(', ');
  }
  return '';
}

export function userName( user, alias ) {
  if ( user ) {
    var items = [ user.name, user.surname ].filter( a => a );
    if ( alias && user.alias ) items.push(`(${ user.alias })`);
    return items.join(' ');
  }
  return '';
}

export function marker( options ) {
  const { label, icon } = options;
  return {
    clickable: true,
    draggable: false,
    ...options,
    label: label === null ? null : {
      color: '#ffffff',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontWeight: 'bold',
      text: '',
      ...label
    },
    icon: icon === null ? null : {
      labelOrigin: { x: 15, y: 16 },
      anchor: { x: 15, y: 42 },
      fillColor: 'red',
      strokeColor: 'white',
      fillOpacity: 1,
      strokeWeight: 1,
      path: 'M27.44,23.38h0L15,42,2.58,23.41l0-.05,0,0h0A15,15,0,1,1,30,15a14.89,14.89,0,0,1-2.53,8.33h0Z',
      ...icon
    }
  }
}

export function message( number ) {
  const text = toArray( arguments ).slice( 1 );
  return ( number === 1
    ? text.map( txt => String( txt ).split('/')[0])
    : [ number ].concat( text.map( txt => ( txt = String( txt ).split('/'))[1] || txt[0] ))
  ).join(' ');
}

export function upperCaseOn( str, index ) {
  index = Math.max( Math.min( index || 0, str.length - 1 ), 0 );
  return ( index ? str.slice( 0, index - 1 ) : '') + str.charAt( index ).toUpperCase() + str.slice( index + 1 );
}

export function deepClone (obj){
  return JSON.parse(JSON.stringify(obj));
}

export function anchorFromLink(link){
  return "<a href=\""+link+"\" target=\"_blank\">"+link+"</a>";
}
export function mailTo( email ) {
  return "<a href=mailto:\""+email+"\" target=\"_blank\">"+email+"</a>";
}

export function isElement( obj ) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  }
  catch ( e ) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return obj && ( typeof obj === "object" ) &&
      ( obj.nodeType === 1 ) && ( typeof obj.style === "object" ) &&
      ( typeof obj.ownerDocument === "object" );
  }
}

// Normalize text

const from  = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
const to    = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
const map   = {};

for( var i = 0, j = from.length; i < j; i++ )
    map[ from.charAt( i ) ] = to.charAt( i );

export function normalize( str, lower ) {
  var ret = [];
  for( var i = 0, j = str.length; i < j; i++ ) {
    var c = str.charAt( i );
    if ( Object.prototype.hasOwnProperty.call( map, c ) ) ret.push( map[ c ] );
    else ret.push( c );
  }
  if ( lower ) return ret.join( '' ).toLowerCase();
  return ret.join( '' );
}

export function downloadImage( url, fileName ) {

  var xhr = new XMLHttpRequest();
  fileName = fileName || url.substring( url.lastIndexOf('/') + 1 );

  xhr.open( "GET", url, true );
  xhr.responseType = "blob";
  xhr.onload = function(){
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }

  xhr.send();
}

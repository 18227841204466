<template>
  <Table
    service="Clusters"
    :messages="messages"
    :headers="headers"
    show-actions
  >

    <template v-slot:item.campaign="{ value, item }">
      <router-link class="body-grupo-ot grupo-ot-link" v-if="item.campaign" :title="value" :to="`/campaigns/${ item.campaign.id }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.places="{ item, value }">
      <Btn class="px-0" color="tertiary" text small @click="show( 'places', item.places )">
        {{ `Ver (${ value })` }}
      </Btn>
    </template>

    <template v-slot:item.products="{ item, value }">
      <Btn class="px-0" color="tertiary" text small @click="show( 'products', item.products )">
        {{ `Ver (${ value })` }}
      </Btn>
    </template>

    <template v-slot:append-outer>
      <v-dialog v-model="dialog.show" max-width="640">
        <Card class="d-flex flex-column flex-nowrap" height="480">
          <Toolbar color="secondary">

            <span class="white--text title">
              {{ dialog.type === 'places' ? 'Centros' : 'Productos' }}
            </span>

            <v-spacer/>

            <Btn color="white" width="20" height="20" fab @click="dialog.show = false">
              <v-icon small>$close</v-icon>
            </Btn>

          </Toolbar>
          <div class="grow rel">
            <div class="layer autoscroll">
              <template v-if="dialog.type === 'places'">

                <Card v-for="( place, index ) in dialog.value" :key="index" class="pa-4" border-bottom>
                  {{ direction( place, true ) }}
                </Card>

              </template>
              <template v-else>

                <DGallery
                  :items="dialog.value"
                  item-footer="name"
                  item-image="picture.url"
                  cols="3"
                />

              </template>
            </div>
          </div>
        </Card>
      </v-dialog>
    </template>

  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import { direction } from '@/utils';

  export default {
    components: { Table },
    data() {
      return {
        messages: { item: 'Clúster/Clústers' },
        headers: [
          { text: 'Nombre', value: 'name' },
          {
            text: 'Campaña', value: 'campaign',
            display: a => a ? `${a.id}. ${a.title||a.name}` : '',
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Campaigns',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          }, {
            text: 'Cadena', value: 'channel', display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Channels',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          },
          {
            text: 'Marca', value: 'brand', display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Brands',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          },
          {
            text: 'Sector', value: 'sector', display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Sectors',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          },
          { text: 'Centros', value: 'places', display: 'length', width: 75, filterable: false },
          { text: 'Productos', value: 'products', display: 'length', width: 170, filterable: false }
        ],
        dialog: {
          show: false
        }
      }
    },
    methods: {
      direction,
      show( type, value ) {
        this.dialog = { show: true, type, value };
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Clusters' );
    }
  }
</script>

<template>
  <div>

    <v-row class="mb-6" align="center" no-gutters>

      <h3 class="title picsTitle">{{title}}</h3>

      <Btn v-if="!readonly" class="ml-1" color="tertiary" :loading="loading" @click="addImage" icon>
        <v-icon>$plus</v-icon>
      </Btn>

      <v-spacer/>

      <Btn v-if="pictures.length && !readonly" :color="isSelectedAll ? 'primary' :' tertiary'" @click="selection" dark>
        {{ isSelectedAll ? 'Deseleccionar' : 'Seleccionar todo' }}
      </Btn>

      <Btn v-if="!readonly" class="ml-2" color="tertiary" :disabled="!length" @click="removeImage" icon>
        <v-icon>$delete</v-icon>
      </Btn>

    </v-row>

    <DGallery
      v-model="selected"
      v-if = isGalleryVisible()
      :items="pictures"
      :selectable="!readonly"
      item-image="url"
      cols="3" sm="4" md="5" lg="6" xl="7"
      multiple
      @click-item="showImage"
    />

  <div v-else>
    <span class="body-grupo-ot grey--text">No se ha subido ninguna image por el momento</span>
  </div>

    <v-dialog
      v-model="gallery"
      transition="fade-transition"
      fullscreen
    >
      <DSlider
        v-model="index"
        :items="pictures"
        item-image="url"
        @close="gallery = false"
      />
    </v-dialog>

    <input
      v-show="false"
      ref="file"
      type="file"
      accept="image/*"
      @change="upload"
    />

  </div>
</template>

<script>

  import { get, set, toArray } from '@/utils';

  export default {
    props: {
      value: Object,
      question: Object,
      title: String,
      readonly: Boolean
    },
    data: function() {
      return {
        pictures: ( get( this.value, 'response.pictures' ) || [] ).slice(),
        image: null,
        index: -1,
        gallery: false,
        selected: null,
        loading: false
      }
    },
    watch: {
      value( value ) {
        console.log("Pictures value: " + value);
        this.pictures = ( get( value, 'response.pictures' ) || [] ).slice();
      },
      readonly( value ) {
        if ( ! value )
          this.gallery = false;
      }
    },
    computed: {
      length() {
        return toArray( this.selected ).length;
      },
      isSelectedAll() {
        return this.length === this.pictures.length;
      }
    },
    methods: {
      showImage( item ) {
        if ( ! this.readonly ) return;
        if ( item ) {
          this.image = item.data;
          this.index = item.index;
          this.gallery = true;
        }
      },
      selection() {
        if ( this.isSelectedAll ) this.selected = [];
        else this.selected = this.pictures.slice();
      },
      addImage() {
        this.$refs.file.click();
      },
      removeImage() {

        toArray( this.selected ).forEach(( pic, index ) => {
          if (( index = this.pictures.indexOf( pic )) !== -1 ) {
            this.pictures.splice( index, 1 );
          }
        });

        set( this.value, 'response.changed', this.pictures );
        this.$emit( 'input', this.value );
      },
      isGalleryVisible(){
        return !this.readonly||this.pictures.length>0;
      },
      upload( event ) {

        if ( ! event.target.files.length ) return;

        const data = new FormData();
        data.append( 'file', event.target.files[0] );

        this.loading = true;
        this.$store
          .dispatch( 'uploadFile', data )
          .then( image => {
            console.log( image );
            this.pictures.push( image );
            set( this.value, 'response.changed', this.pictures );
            this.$emit( 'input', this.value );
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => {
            this.$refs.file.value = null;
            this.loading = false;
          })
      }
    }
  }
</script>

<template>
  <Table
    service="UserLogs"
    :messages="messages"
    :mypopup="mypopup"
    :headers="headers"
    :sanitize="sanitize"
    show-actions="export"
    :no-edit="true"
  >
    <template v-slot:form="{ value, listeners }">
      <UserLog class="pa-4" :value="value" v-on="listeners" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import UserLog from "@/components/forms/UserLogs/single.vue";
import moment from "moment";

function datetime(time) {
  if (time) return moment(time).format("YYYY/MM/DD HH:mm:ss");
  return "-";
}

export default {
  components: { Table, UserLog },
  data() {
    return {
      messages: { item: "Log Usuario/Log Usuarios" },
      headers: [
        {
          text: "Fecha",
          value: "date",
          display: datetime,
          width: 300,
          filter: {
            type: "rangedate",
            compute: (value, field) => {
              if (!value[0] || !value[1]) return { [field]: null };
              return { [field]: { start: value[0], end: value[1] } };
            },
          },
        },
        {
          text: "Usuario",
          value: "tag",
          width: 300,
          filter: { field: "tag" },
        },
        {
          text: "Acción",
          value: "logMessage",
          filter: { field: "logMessage" },
        },
      ],
      mypopup: {
        height: 450,
        title: (n) => (n ? "Nuevo log" : "Editar log"),
        initial: (name) => ({ name }),
      },
    };
  },
  methods: {
    sanitize(model) {
      return {
        id: model.id || -1,
        date: model.date,
        tag: model.tag,
        logMessage: model.logMessage
      };
    },
  },
  mounted() {
    this.$store.commit( 'setTitle', 'Log de Usuarios' );
  }
};
</script>

<template>
  <Single
    service="Clusters"
    :sanitize="sanitize"
    @initialized="setTitle"
  >
    <template v-slot="{ value, listeners }">

      <Cluster class="pa-4" :value="value" v-on="listeners"/>

    </template>
  </Single>
</template>

<script>

  import Single from '@/components/core/Single.vue';
  import Cluster from '@/components/forms/Clusters/single.vue';

  export default {
    components: { Single, Cluster },
    methods: {
      setTitle(res) {
        this.$store.commit( 'setTitle', ['Clusters', res && res.name ]);
      },
      places( items ) {
        return ( items || [] )
          .filter( a => a && a.status !== -1 )
          .map( a => a.id );
      },
      products( items ) {
        return ( items || [] )
          .filter( a => a && a.status !== -1 )
          .map( a => a.id );
      },
      sanitize( model ) {
        return {
          id: model.id || -1,
          name: model.name,
          idCampaign: model.campaign ? model.campaign.id : null,
          idChannel: model.channel ? model.channel.id : null,
          idBrand: model.brand ? model.brand.id : null,
          idSector: model.sector ? model.sector.id : null,
          places: this.places( model.places ),
          products: this.products( model.products )
        }
      }
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

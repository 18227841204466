<template>
  <DForm :value="value" v-on="$listeners">
    <v-row class="" no-gutters>
      <v-col cols="12" md="6" class="pa-2">

        <h3 class="title mb-4">
          Datos generales
        </h3>

        <DField
          v-for="( field, key ) in clientInfo"
          :key="key"
          v-bind="field"
        />

        <h3 class="title mt-8 mb-4">
          Dirección
        </h3>

        <DField
          v-for="( field, key ) in clientDirection"
          :key="key"
          v-bind="field"
        />

      </v-col>
      <v-col cols="12" md="6" class="pa-2">

        <h3 class="title mb-4">
          Otros datos
        </h3>

        <BrandSelector
          key="brands"
          v-bind="fields.brands"
          class="mb-6"
        />

        <SectorSelector
          key="sector"
          v-bind="fields.sector"
          class="mb-6"
        />

        <h3 class="title mt-7 mb-7">
          Página de estadísticas
        </h3>

        <DField
          v-for="( field, key ) in clientStats"
          :key="key"
          v-bind="field"
        />

        <DField class="pt-2" key="contacts">
          <template v-slot:default="{ value, on }">
            <Contacts :value="value" v-on="on"/>
          </template>
        </DField>

      </v-col>
    </v-row>
  </DForm>
</template>

<script>

  import BrandSelector from '@/components/forms/Brands/selector.vue';
  import SectorSelector from '@/components/forms/Sectors/selector.vue';
  import Contacts from './contacts';
  import { UserRole } from '@/utils/constants';

  const type = {
    text:   { field: 'text',   fieldStyle: 1, class: 'mb-6', dense: true },
    select: { field: 'select', fieldStyle: 1, class: 'mb-6', dense: true },
    autocomplete: { field: 'autocomplete', fieldStyle: 1, class: 'mb-6', dense: true }
  };

  export default {
    components: { BrandSelector, SectorSelector, Contacts },
    props: {
      value: Object,
      readonly: Boolean,
      dense: Boolean,
      hide: [ String, Array ]
    },
    data() {
      return {
        edit: false,
        places: []
      }
    },
    computed: {
      clientInfo() {
        return {
          name: { ...type.text, label: 'Nombre' },
          cif: { ...type.text, label: 'CIF' },
          email: { ...type.text, label: 'Email' },
          phone: { ...type.text, label: 'Teléfono' },
          website: { ...type.text, label: 'Página web' },
        };
      },
      clientStats() {
        return {
          link: { ...type.text, label: 'Link de estadísticas' }
        };
      },
      clientDirection() {
        return {
          street:     { ...type.text, label: 'Calle' },
          city:       { ...type.text, label: 'Ciudad' },
          province:   { ...type.text, label: 'Provincia' },
          postalCode: { ...type.text, label: 'CP' }
        };
      },
      clientOtherData() {
        return {
          role: { ...type.select, label: 'Rol', items: [
            { value: UserRole.SUPERADMIN, text: 'Administrador' },
            { value: UserRole.KAM,  text: 'KAM' },
            { value: UserRole.MANAGER,  text: 'Gestor' },
            { value: UserRole.CLIENT,  text: 'Cliente' },
            { value: UserRole.RRHH,  text: 'RRHH' },
            { value: UserRole.WORKER,  text: 'Usuario' }
          ]}
        };
      },
       fields() {
        return {
          brands: { ...type.autocomplete, label: 'Marca', multiple: true },
          sector: { ...type.autocomplete, label: 'Sector' }
        }
      }
    }
  }
</script>

<template>
  <div class="fill-width">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="date"
      :persistent="persistent"
      width="250px"
    >
      <template v-slot:activator="{ on }">
        <DFieldText
          field="text"
          :value="output"
          :label="label"
          :label-space="labelSpace"
          :field-style="fieldStyle"
          :small="small"
          :dense="dense"
          :rules="compRules"
          :clearable="clearable && !readonly"
          @click:clear="save()"
          v-on="readonly ? null : on"
          readonly
        />
      </template>
      <Card class="row no-gutters pa-2">
        <v-col cols="6" class="pr-1">

          <DField
            v-model="iso[0]"
            class="mb-4"
            field="time"
            field-style="1"
            label="Inicio"
            dense
          />

        </v-col>
        <v-col cols="6" class="pl-1">
          
          <DField
            v-model="iso[1]"
            class="mb-4"
            field="time"
            field-style="1"
            label="Fin"
            dense
          />

        </v-col>
      </Card>
      <Toolbar border-top>
        <v-spacer></v-spacer>
        <Btn text color="primary" @click="dialog = false">Cancelar</Btn>
        <Btn color="primary" :disabled="!canSave" @click="save(iso)">OK</Btn>
      </Toolbar>
    </v-dialog>
  </div>
</template>

<script>

import DFieldText from './DFieldText.vue';
import { toArray } from '@/utils';

function compute( value ) {
  switch ( typeof value ) {
    case 'string':
      return value.split(/\s*-\s*/);
    case 'object':
      if ( Array.isArray( value )) return value;
      return value ? [ value.start, value.end ] : [];
    default:
      return toArray( value );
  }
}

function _toISO( value ) {
  return compute( value )
    .map( v => v || '' );
}

function toTime( value ) {
  return compute( value )
    .map( v => v ? v : "" );
}

function output( value ) {
  return compute( value )
    .map( v => v )
    .filter( a => a )
    .join(' - ');
}

export default {
  //nullValues: '',
  components: { DFieldText},
  props: {
    value: null,
    locale: { type: String, default: navigator.language },
    label: String,
    labelSpace: [ Number, String ],
    fieldStyle: [ Number, String ],
    min: [ Number, String ],
    max: [ Number, String ],
    small: Boolean,
    dense: Boolean,
    persistent: Boolean,
    readonly: Boolean,
    rules: Array,
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      date: compute( this.value ),
      iso: _toISO( this.value ),
      output: output( this.value ),
    };
  },
  computed: {
    compRules() {
      if ( ! this.rules ) return [];
      return this.rules.map( f => () => f( this.date ));
    },
    canSave() {
      return this.iso[0] && this.iso[1];
    }
  },
  watch: {
    value( value, oldValue ) {
      if ( oldValue && value && ( oldValue[0] !== value[0] || oldValue[1] !== value[1] )) {
        this.refresh( value );
        this.$emit( 'input', this.date );
      }
    }
  },
  methods: {
    refresh( date ) {
      console.log("Refresh " + date);
      this.iso = _toISO( date );
      this.date = toTime( this.iso );
      this.output = output( this.iso );
    },
    save( date ) {
      console.log("save " + date);
      this.refresh( date );
      this.$refs.dialog.save( this.iso );
      this.$emit( 'input', this.date );
      this.$emit( 'blur' );
    }
  }
};
</script>

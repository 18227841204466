<template>
  <Single
    service="Clients"
    :sanitize="sanitize"
    @initialized="setTitle"
  >
    <template v-if="$route.params.id !== 'new'" v-slot:top>
      <Btn :to="`/clients/${$route.params.id}/centers`" active-class="transparent" color="secondary" text>
        Centros asociados
        <v-icon class="ml-1">$next</v-icon>
      </Btn>
    </template>
    <template v-slot="{ value, listeners }">
      <Client
        class="pa-4"
        :value="value"
        v-on="listeners"
      />
    </template>
  </Single>
</template>

<script>

  import Single from '@/components/core/Single.vue';
  import Client from '@/components/forms/Clients/single.vue';

  export default {
    components: { Single, Client },
     methods: {
      setTitle(res) {
        this.$store.commit( 'setTitle', ['Clientes', res && res.name ]);
      },
      sanitize( model ) {
        return {
          ...model,
          id: model.id || -1,
          sector: model.sector ? model.sector.id : null,
          contacts: model.contacts ? model.contacts.filter( a => a.name ) : [],
          brands: model.brands ? model.brands.map( a => a.id ) : [],
          brand: null,
          stats: null
        }
      }
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

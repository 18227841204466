<template>
  <Page
    ref="page"
    service="Campaigns"
    :initialize="initialize"
    :loading="loading"
    :section="section"
  >

    <Submenu
      :value="section"
      :items="sections"
      @input="set({},$event)"
      slot="top"
    >
      <template v-if="!isClient" v-slot:middle>
        <Btn
          :to="`/campaigns/${$route.params.id}/centers`"
          class="mr-2"
          color="tertiary"
          dark
        >
          Ver centros
        </Btn>
      </template>
    </Submenu>

    <v-row class="fill-height" style="background: white;" no-gutters>
      <v-col class="rel" cols="12" md="3" order="1" order-md="0">
        <div class="layer autoscroll">

          <Toolbar border-bottom>
            <span class="subtitle-2">Promotores</span>
          </Toolbar>

          <v-list class="py-0">

            <DField
              field="text"
              class="filter-input-search"
              append-icon="mdi-magnify"
              v-model="search"
              dense
            />

            <v-list-item-group
              :value="selected"
              @change="set({ selected: $event })"
              multiple
            >
              <v-list-item
                v-for="item in ( lazyItems || items )"
                v-show="item.show !== false"
                :value="item.id"
                :key="item.id"
                :ripple="false"
                :class="listItemClass(item) + ' px-4'"
                color="tertiary"
              >
                <template v-slot="{ active }">
                  <v-list-item-content>
                    <v-row class="flex-nowrap" align="center" no-gutters>

                      <Btn
                        :color="active ? item.color : 'border'"
                        class="mr-2"
                        width="25"
                        height="25"
                        dark fab
                      >
                        <small>
                          {{ item.letter }}
                        </small>
                      </Btn>

                      <div class="grow">
                        <p class="rel mb-0" style="height:1rem">
                          <span class="single-line fill-width abs">
                            {{ item.fullName }}
                          </span>
                        </p>
                        <p v-if="item.place" class="rel mb-0" style="height:1rem">
                          <span class="caption secondary--text single-line fill-width abs">
                            {{ item.place.name }}
                          </span>
                        </p>
                        <p v-if="item.isFuture||item.isPast" class="rel mb-0" style="height:1rem">
                          <span class="caption secondary--text single-line fill-width abs">
                            {{ listItemTitle(item) }}
                          </span>
                        </p>
                      </div>

                      <Btn
                        v-if="!isClient"
                        :color="model && active ? 'secondary' : 'border'"
                        @click.stop="edit(item)"
                        small icon
                      >
                        <v-icon style="width: 20px; height: 20px;">
                          $edit
                        </v-icon>
                      </Btn>

                    </v-row>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
      <v-col class="calendar rel" cols="12" md="9" order="0" order-md="1">
        <v-row class="flex-column flex-nowrap fill-height" no-gutters>
          <Toolbar border-bottom>

            <DField
              field="select"
              field-style="1"
              class="mr-2"
              :value="type"
              :items="types"
              @input="set({ type: $event })"
              style="max-width: 118px;"
              dense
            />

            <v-spacer/>

            <Btn color="secondary" class="mr-3" @click="prev" width="40" height="40" icon>
              <v-icon>$prev</v-icon>
            </Btn>

            <DField
              v-if="type !== 'month'"
              field="select"
              field-style="1"
              class="mr-2"
              v-model="day"
              :items="days"
              style="max-width: 75px;"
              dense
            />

            <DField
              field="select"
              field-style="1"
              class="mr-2"
              v-model="month"
              :items="months"
              style="max-width: 145px;"
              dense
            />

            <DField
              field="select"
              field-style="1"
              class="mr-2"
              v-model="year"
              :items="years"
              style="max-width: 95px;"
              dense
            />

            <Btn class="mr-2" color="secondary" @click="next" width="40" height="40" icon>
              <v-icon>$next</v-icon>
            </Btn>

            <v-spacer/>

            <Btn
              class="mr-2"
              :color="today.isSame(value) ? '#ddd':'tertiary'"
              @click="set({ value: today.format('YYYY-MM-DD')})"
              dark
            >
              Hoy
            </Btn>

          </Toolbar>
          <div class="grow rel">
            <div class="abs full autoscroll">

              <Calendar
                class="layer"
                :value="value"
                :type="type"
                :events="events.list"
                :border-left="$vuetify.breakpoint.mdAndUp"
                :border-bottom="!$vuetify.breakpoint.mdAndUp"
                :show-add-button="selected.length === 1"
                :holidays="holidays"
                :readonly="isClient"
                group-events
                @input="set({ value: $event })"
                @click-event="onClickEvent"
                @click-day="onClickDay"
              />

            </div>
          </div>
        </v-row>

        <Options
          v-if="!isClient && model"
          class="layer"
          style="z-index:2"
          :value="model.options"
          :name="editingNames"
          :all-day="model.allday"
          :has-errors="hasErrors"
          @save="apply"
        />

      </v-col>
    </v-row>

    <v-dialog v-if="!isClient" v-model="dialog.open" max-width="560">
      <Card class="d-flex flex-column flex-nowrap">
        <Toolbar class="shrink" color="secondary" dark>

          Editar día {{ dialog.date }} para {{ dialog.item.fullName }}

        </Toolbar>
        <Tabs
          class="shrink"
          v-model="dialog.mode"
          :items="dialog.tabs"
        />
        <v-row class="grow pa-4" no-gutters>
          <template v-if="!dialog.mode">
            <v-col class="pr-2">

              <v-checkbox
                v-if="!dialog.item.allday"
                v-model="dialog.middleJourney"
                :ripple="false"
                :disabled="dialog.holiday"
                label="Jornada partida"
                class="body-grupo-ot mt-0 mb-4"
                color="primary"
                hide-details
              />

              <DField
                v-if="!dialog.item.allday"
                v-model="dialog.start"
                :disabled="dialog.holiday"
                :max="dialog.end"
                class="mb-4"
                field="time"
                field-style="1"
                label="Hora de entrada"
                dense
              />

              <DField
                v-if="!dialog.item.allday"
                v-model="dialog.end"
                :disabled="dialog.holiday"
                :min="dialog.start"
                field="time"
                field-style="1"
                label="Hora de salida"
                dense
              />

            </v-col>
            <v-col v-if="dialog.middleJourney" class="pl-2 pt-11">

              <DField
                v-model="dialog.start2"
                :disabled="dialog.holiday"
                :max="dialog.end2"
                class="mb-4"
                field="time"
                field-style="1"
                label="Hora de entrada"
                dense
              />

              <DField
                v-model="dialog.end2"
                :disabled="dialog.holiday"
                :min="dialog.start2"
                field="time"
                field-style="1"
                label="Hora de salida"
                dense
              />

            </v-col>
          </template>
          <template v-else>
            <v-col class="pr-2">

              <DField
                v-model="dialog.absenceType"
                :items="dialog.absences"
                field="select"
                field-style="1"
                class="mb-4"
                label="Tipo"
                dense
              />

              <DField
                v-if="dialog.subabsences[dialog.absenceType]"
                v-model="dialog.absenceSubtype"
                :items="dialog.subabsences[dialog.absenceType]"
                field="select"
                field-style="1"
                label="Razón"
                class="mb-4"
                dense
              />

              <DField
                v-model="dialog.comments"
                field="textarea"
                field-style="1"
                label="Comentarios"
                rows="4"
              />

            </v-col>
          </template>
        </v-row>
        <Toolbar class="shrink" border-top>

          <v-spacer/>

          <Btn v-if="dialog.edited" @click="undoEdition" class="mr-2" color="secondary">
            Deshacer cambio
          </Btn>

          <Btn color="primary" @click="applyEdition">
            Aplicar
          </Btn>

        </Toolbar>
      </Card>
    </v-dialog>

    <template v-if="!isClient" v-slot:actions>

      <v-spacer/>

      <Btn v-if="!model" @click="add" color="tertiary" dark>
        Añadir horarios
      </Btn>

      <Btn v-else @click="model = null" color="tertiary" text>
        Cancelar
      </Btn>

    </template>
  </Page>
</template>

<script>

  /* eslint-disable */

  import Page from '@/components/core/Page.vue';
  import Submenu from '@/components/core/Submenu.vue';
  import Calendar from './Calendar';
  import Options from './Options';
  import moment from 'moment';
  import { mapState, mapMutations } from 'vuex';
  import { upperCaseOn, userName, normalize, formatDate, compare } from '@/utils';
  import { AbsenceType, AbsenceSubtype, CampaignType } from '@/utils/constants';

  moment.locale( navigator.language );

  const toRemove = [];
  const lang = navigator.language;
  const months = moment.months().map(( text, value ) => ({
    value,
    text: upperCaseOn( text, 0 )
  }));

  function format( value ) {
    value = String( value ).slice( 0, 2 );
    return '00'.slice( value.length ) + value;
  }

  function offsetToTime( offset ) {
    const H = Math.floor( offset / 60 );
    const M = offset - ( H * 60 );
    return format( H ) + ':' + format( M );
  }

  function timeToOffset( time ) {
    time = String( time ).split(':');
    const H = parseInt( time[0] ) || 0;
    const M = parseInt( time[1] ) || 0;
    return H * 60 + M;
  }

  const sortByStartoffset = ( a, b ) => a.startOffset - b.startOffset;

  const absences = [
    //{ text: 'Festivo laboral', value: AbsenceType.HOLIDAY_WORKING },
    { text: 'Festivo No laboral', value: AbsenceType.HOLIDAY_NO_WORKING },
    { text: 'Vacaciones', value: AbsenceType.USER_HOLIDAYS },
    { text: 'Libranza', value: AbsenceType.LIBRANZA },
    { text: 'Centro descubierto', value: AbsenceType.PLACE },
    { text: 'Jornada incompleta', value: AbsenceType.INCOMPLETE },
    { text: 'Observaciones', value: AbsenceType.OTHER }
  ];

  const subAbsences = [
    { text: "Pendiente de selección", value: AbsenceSubtype.NO_CUBIERTA_PDT_SELECT },
    { text: "Pendiente de cliente", value: AbsenceSubtype.NO_CUBIERTA_PDT_CLIENTE },
    { text: "Pendiente de PDV", value: AbsenceSubtype.NO_CUBIERTA_PDT_PDV },
    { text: 'Baja voluntaria', value: AbsenceSubtype.BAJA_VOLUNTARIA },
    { text: 'Despido / NSPP', value: AbsenceSubtype.DESPIDO },
    { text: 'Imprevisto personal', value: AbsenceSubtype.IMPREVISTO_PERSONAL },
    { text: 'Enfermedad', value: AbsenceSubtype.ENFERMEDAD },
    { text: 'Problemas documentación', value: AbsenceSubtype.DOCUMENTATION },
    { text: 'Retraso / Avance jornada', value: AbsenceSubtype.RETRASO }
  ];

  export default {
    components: { Page, Submenu, Options, Calendar },
    data() {
      const today = moment();
      const dayW = 100 / 7;
      return {
        today,
        lang,
        model: null,
        search: '',
        selectedToSave: [],
        errors: [],
        holidays: [],
        adding: false,
        loading: false,
        isPromotor: false,
        hasErrors: 0,
        removing: [],
        lazyItems: null,
        week: [ 1, 2, 3, 4, 5, 6, 0 ],
        year: today.get('year'),
        month: today.get('month'),
        day: today.get('date'),
        months,
        types: [
          { text: 'Día',    value: 'day'   },
          { text: 'Semana', value: 'week'  },
          { text: 'Mes',    value: 'month' }
        ],
        colors: [
          'red lighten-3',
          'pink lighten-3',
          'purple lighten-3',
          'indigo lighten-3',
          'blue lighten-3',
          'teal lighten-3',
        ],
        eventMod: {
          color: e => e.color,
          name:  e => e.input.letter
        },
        dialog: {
          open: false,
          mode: 0,
          date: '',
          item: {},
          event: null,
          tabs: [
            { text: 'Horario'  },
            { text: 'Ausencia' },
          ],
          absenceType: null,
          absenceSubtype: null,
          comments: '',
          absences,
          subabsences: {
            [AbsenceType.PLACE]: subAbsences.slice(0,-1),
            [AbsenceType.INCOMPLETE]: subAbsences.slice(3),
          }
        }
      }
    },
    watch: {
      $route() {
        this.$nextTick( this.setTitle );
      },
      section( value, old ) {
        if ( ! compare( value, old )) {
          this.model = null;
          this.search = '';
          this.set({ selected: [] });
        }
      },
      isPromotor( value ) {
        if ( value ) {
          this.set({},[0,0]);
        }
      },
      value( value ) {
        const date = moment( this.value );
        this.year = date.get('year');
        this.month = date.get('month');
        this.day = date.get('date');
      },
      model() {
        this.selectedToSave = [];
        toRemove.length = 0;
        this.hasErrors = 0;
      },
      selected( value, old ) {
        if ( ! compare( value, old ) && this.model ) {
          this.adding = false;
          if ( ! value.length ) this.model = null;
          else this.add();
        }
      },
      year( value ) {
        const date = moment( this.value ).year( value );
        this.set({ value: date.format('YYYY-MM-DD') });
        this.refresh();
      },
      month( value ) {
        const date = moment( this.value ).month( value );
        this.set({ value: date.format('YYYY-MM-DD') });
        this.refresh();
      },
      day( value ) {
        const date = moment( this.value ).date( value );
        this.set({ value: date.format('YYYY-MM-DD') });
      },
      search( search ) {
        search = normalize( search, true );
        if ( ! search ) this.lazyItems = null;
        this.lazyItems = this.items.map( item => {
          var name = normalize( userName( item.user || item, true ), true );
          item.show = name.indexOf( search ) !== -1;
          if ( ! item.show && item.place ) {
            item.show = normalize( item.place.name, true ).indexOf( search ) !== -1;
          }
          return item;
        });
      },
      hasErrors( error ) {
        if ( error ) {
          switch ( error ) {
            case 1:
              this.$store.dispatch(
                'console/error',
                'Debes seleccionar las fechas de inicio y fin del calendario.'
              );
              break;
            case 2:
              this.$store.dispatch(
                'console/error',
                'Debes seleccionar algún dia de la semana.'
              );
              break;
          }
        }
      }
    },
    provide: () => ({
      toTrash( id ) {
        toRemove.push( id );
      }
    }),
    computed: {
      ...mapState([
        'isClient', 'view',
        'core', 'path', 'breadcrumbs'
      ]),
      section() {
        return this.view ? (this.view.section||[0,0]) : [0,0];
      },
      sections() {
        if ( this.isPromotor ) return null;
        return [
          { text: 'Trabajadores' },
          { text: 'Centros' }
        ];
      },
      selected() {
        return this.core ? (this.core.selected||[]) : [];
      },
      items() {
        const data = (this.core && this.core.data) || {};
        if ( this.isPromotor ) return data.places || [];
        switch ( this.section[0] ) {
          case 0: return data.users || [];
          case 1: return data.places || [];
          default: return [];
        }
      },
      value() {
        var val = this.today.format('YYYY-MM-DD');
        return this.core ? (this.core.value || val) : val;
      },
      type() {
        return this.core ? (this.core.type || 'month') : 'month';
      },
      days() {
        const length = moment( this.value ).daysInMonth();
        return Array.apply( null, { length }).map(( a, num ) => num + 1 );
      },
      years() {
        const year = this.year;
        return Array.from({ length: 20 }).map(( a, i ) => {
          return year - 10 + i;
        });
      },
      events() {

        const events = { list: [] };
        const value  = moment( this.value );

        var startMonth = value.clone().startOf('month').subtract( 7, 'days' ).valueOf();
        var endMonth   = value.clone().endOf('month').add( 14, 'days' ).valueOf();
        var item, start, end, date, key, data, itemEvents, arr, i;

        this.selected.forEach( id => {

          item = this.items.find( p => p.id === id );
          if ( ! item ) return;
          itemEvents = {};

          ( item.events || [] ).forEach(( ev, startDate, endDate, timeStart, timeEnd ) => {

            startDate = moment( ev.startDate ).startOf('date').valueOf();
            endDate = moment( ev.endDate ).endOf('date').valueOf();
            start = Math.max( startDate, startMonth );
            end = Math.min( endDate, endMonth );

            if (( i = ev.dates.findIndex( n => n >= start )) !== -1 ) {
              while (( date = ev.dates[i++] ) <= end ) {

                date = moment( date );
                timeStart = offsetToTime( ev.startOffset );
                timeEnd = offsetToTime( ev.endOffset );
                key = date.format('YYYY-MM-DD');

                data = {
                  item,
                  id: item.id,
                  timeId: ev.id,
                  color: item.color,
                  letter: item.letter,
                  priority: ev.priority,
                  absenceType: ev.absenceType,
                  absenceSubtype: ev.absenceSubtype,
                  comments: ev.comments,
                  range: { start: timeStart, end: timeEnd },
                  start: ev.allDay ? key : `${ key } ${ timeStart }`,
                  end: ev.allDay ? key : `${ key } ${ timeEnd }`
                };

                itemEvents[key] = itemEvents[key] || [];
                itemEvents[key].push( data );
              }
            }
          });

          // Add with global
          for ( key in itemEvents ) {
            if (( arr = itemEvents[key].filter( a => a.priority !== 0 )).length ) itemEvents[key] = arr;
            events[key] = events[key] || [];
            events[key] = events[key].concat( itemEvents[key] );
            events.list = events.list.concat( itemEvents[key] );
          }
        });

        return events;
      },
      editingNames() {
        const item = this.items.find( p => p.id === this.selected[0] );
        const more = this.selected.length > 1 ? `,... (+${ this.selected.length - 1 })` : '';
        if ( item && item.user ) {
          return `${item.user.name} ${item.user.surname} ${more}`;
        }
        return ( item ? `${item.fullName}` : '' ) + more;
      },
    },
    methods: {
      userName,
      ...mapMutations([ 'setView' ]),
      set( state, section ) {
        section = section || this.section;
        this.setView({
          path: this.path,
          state: {
            section,
            sections: {[section.join('')]: state }
          }
        });
      },
      initialize( params ) {
        return new Promise(( resolve, reject ) => {
          this.loading = true;
          this.$store.dispatch( 'page', {
            ...params,
            service: 'Campaigns',
            method: 'timetables',
            params: {
              idCampaign: parseInt( this.$route.params.id ),
              start: moment( this.value ).startOf('month').valueOf(),
              end: moment( this.value ).endOf('month').valueOf()
            }
          })
          .then( response => {
            resolve( this.compute( response ));
          })
          .catch( reject )
          .finally(() => this.loading = false );
        });
      },
      refresh() {
        const { page } = this.$refs;
        page && page.reload();
      },
      prepareInfo( items ) {

        const letters = [];
        var num = 1;

        items.sort(( a, b ) => {
          if ( a.fullName > b.fullName ) return 1;
          if ( a.fullName < b.fullName ) return -1;
          return 0;
        }).forEach(( item, index, name, word ) => {

          item.events = [];
          item.options = [];
          item.color = this.colors[ item.id % this.colors.length ];

          name = item.fullName || '???';
          word = name.slice( 0, 1 );

          while( num <= name.length  ) {
            if ( letters.indexOf( word ) !== -1 ) word = name.slice( 0, ++num );
            else {
              letters.push(( item.letter = word ));
              break;
            }
          }

          item.letter = item.letter || name;
        });
      },
      prepareEvents( item, event ) {

        if ( ! item ) return;

        var options;
        event = {
          ...event,
          dayOfWeek: event.dayOfWeek - 1  // <- in JAVA: Sunday = 1, in Javascript: Sunday = 0;
        };

        item.events.push( event );

        options = item.options.find( a => {
          return a.start === event.startDate
              && a.end === event.endDate
              && a.priority === event.priority
        });

        if ( ! options ) {
          item.options.push(( options = {
            start: event.startDate,
            end: event.endDate,
            frequency: event.frequencyType,
            priority: event.priority,
            times: [],
          }));
        }

        options.times.push({
          id: event.id,
          weekday: event.dayOfWeek,
          startOffset: event.startOffset,
          endOffset: event.endOffset,
          start: offsetToTime( event.startOffset ),
          end: offsetToTime( event.endOffset ),
          priority: event.priority,
          absenceType: event.absenceType,
          absenceSubtype: event.absenceSubtype,
          comments: event.comments
        });
      },
      joinTimeOptions( items, working ) {

        const todayInit = this.today.clone().startOf('day').valueOf();
        const todayEnd = this.today.clone().endOf('day').valueOf();

        items.forEach(( item, i ) => {

          if ( working ) {
            let start = moment( item.startDate ).startOf('day').valueOf();
            let end = moment( item.endDate ).endOf('day').valueOf();
            item.isFuture = item.isFuture || start > todayEnd;
            item.isPast = item.isPast || end < todayInit;
            item.start = start;
            item.end = end;
          }

          item.options.forEach( opt => {

            var times = Array.from({ length: 7 }).map( a => ({ active: false, intervals: [] }));
            var group = [];
            var weekday = 0;

            opt.times
              .sort(( a, b ) => a.weekday - b.weekday )
              .forEach( time => {
                if ( time.weekday === weekday ) group.push( time );
                else {
                  if ( group.length ) {
                    group.sort( sortByStartoffset );
                    times[ weekday ].intervals = group;
                    times[ weekday ].active = true;
                  }
                  weekday = time.weekday;
                  group = [ time ];
                }
              });

            if ( group.length ) {
              group.sort( sortByStartoffset );
              times[ weekday ].intervals = group;
              times[ weekday ].active = true;
            }

            opt.times = times;
          });
        });
      },
      compute( response ) {

        const { places, users, timetables } = response;
        this.holidays = response.holidays;
        this.isPromotor = response.campaign.type === CampaignType.PROMOTOR;

        this.prepareInfo( places );
        this.prepareInfo( users );

        // Events
        timetables.forEach( event => {
          this.prepareEvents( places.find( p => ( p.idUser === event.idUser && event.idCampaignPlace == null ) || ( p.id === event.idCampaignPlace )), event );
          this.prepareEvents( users.find( u => u.id === event.idUser ), event );
        });

        // Join times with same weekday

        this.joinTimeOptions( places, true );
        this.joinTimeOptions( users );

        response.data = { places, users };
        return response;
      },
      sanitize( item, hasErrors ) {

        const timetable = [];
        const idCampaign = parseInt( this.$route.params.id );
        var error = 0;

        item.options.forEach(( option, index, event ) => {

          if ( ! option.start || ! option.end ) {
            error = error || 1;
            return;
          };

          event = {
            startDate: option.start,
            endDate: option.end,
            frequencyType: option.frequency || 0,
            priority: option.priority || 0,
            idCampaign,
            allDay: item.allday
          };

          var times = 0;
          option.times.forEach(( day, weekday ) => {
            day.intervals.forEach(( time, position ) => {
              if ( day.active ) {
                if ( time.start && time.end ) {

                  times++;
                  timetable.push({
                    ...event,
                    id: time.id || -1,
                    status: 1,
                    dayOfWeek: weekday + 1,
                    position,
                    startOffset: timeToOffset( time.start ),
                    endOffset: timeToOffset( time.end ),
                    absenceType: time.absenceType,
                    absenceSubtype: time.absenceSubtype,
                    comments: time.comments
                  })

                } else if ( item.allday ) {

                  times++;
                  timetable.push({
                    ...event,
                    id: time.id || -1,
                    status: 1,
                    dayOfWeek: weekday + 1,
                    position,
                    allDay: true,
                    startOffset: 0,
                    endOffset: (24*60),
                    absenceType: time.absenceType,
                    absenceSubtype: time.absenceSubtype,
                    comments: time.comments
                  })
                }
              } else if ( time.id > 0 && toRemove.indexOf( time.id ) === -1 ) {
                toRemove.push( time.id );
              }
            });
          });

          if ( ! times ) { error = 2; }
        });

        if ( hasErrors && error ) return error;

        const result = { timetable, idCampaignPlace: null, idUser: null };
        if ( this.isPromotor || this.section[0] ) {
          result.idCampaignPlace = item.id;
        } else {
          result.idUser = item.id;
        }

        return result;
      },
      prev() {
        switch ( this.type ) {
          case 'week': this.day -= 7; break;
          default: this[this.type] -= 1;
        }
      },
      next() {
        switch ( this.type ) {
          case 'week': this.day += 7; break;
          default: this[this.type] += 1;
        }
      },
      goTo( date, type, hour, minute ) {
        this.set({ value: date, type: type || this.type });
        if ( arguments.length > 2 )
          this.$refs.calendar.scrollToTime({ hour, minute });
      },
      edit( item ) {
        if ( item === this.model ) this.model = null;
        else {
          this.adding = false;
          this.model = item;
          this.set({ selected: [ item.id ]});
        }
      },
      add() {
        if ( this.selected.length !== 1 ) {
          this.adding = true;
          this.model  = { options: [] };
        } else {
          this.model  = this.items.find( p => p.id === this.selected[0] );
        }
      },
      apply( options ) {

        var error = 0;
        this.hasErrors = 0;

        const selecteds = this.selected.map( id => this.items.find( p => p.id === id ));
        const items = selecteds.map( item => {
          item.options = this.adding ? item.options.concat( options ) : options;
          item = this.sanitize( item, true );
          if ( typeof item === 'number' ) error = item;
          return item;
        });

        if ( error ) {
          this.$nextTick(() => this.hasErrors = error );
          return;
        };

        this.loading = true;
        this.remove()
          .then(() => this.save( items ))
          .then(() => {
            this.loading = false;
            this.adding = false;
            this.model = null;
            this.$store.dispatch( 'console/success', 'Horarios actualizados.');
            this.$nextTick( this.refresh );
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
      },
      save( items ) {
        return Promise.all( items.map( params => {
          return this.$store
            .dispatch( 'api', { target: 'Users/setTime', params })
        }));
      },
      remove() {
        return Promise.all( toRemove.map( id => {
          return this.$store
            .dispatch( 'api', { target: 'Users/removeTime', params: { id }})
        }))
      },
      listItemClass( item ) {
        if ( item.isFuture ) return 'is-future';
        if ( item.isPast ) return 'is-past';
        return '';
      },
      listItemTitle( item ) {
        if ( item.isFuture ) return 'Inicio: ' + formatDate( item.start, {
          year:  "numeric",
          month: "2-digit",
          day:   "2-digit"
        });
        if ( item.isPast ) return 'Periodo finalizado.';
        return '';
      },
      intervalStyle( props ) {
        const { hour, minute, weekday } = props;
        const workday = hour >= 8 && hour < 20 && weekday > 0;
        return {
          backgroundColor: workday ? null : 'rgba(0,0,0,.05)',
          borderTop: ( !workday || !minute ) ? null : '1px dashed rgba(0,0,0,.1)'
        };
      },
      intervalFormat( props ) {
        return props.hour;
      },
      onClickEvent({ event, cell }) {

        const { priority, absenceType, absenceSubtype } = event[0].data;
        const isAbsence = !!absenceType;
        const middleJourney = event.length > 1;
        const edited = !!priority;

        this.dialog = {
          ...this.dialog,
          open: true,
          date: moment( cell.date ).format('DD/MM/YYYY'),
          weekday: cell.weekday.value,
          value: moment( cell.date ).valueOf(),
          item: event[0].data.item,
          edited,
          mode: isAbsence ? 1 : this.isPromotor ? 0 : 1,
          middleJourney: edited && !isAbsence ? middleJourney : false,
          absenceType: event[0].data.absenceType,
          absenceSubtype: event[0].data.absenceSubtype,
          comments: event[0].data.comments || '',
          start: edited && !isAbsence ? event[0].time.start : null,
          end: edited && !isAbsence ? event[0].time.end : null,
          start2: middleJourney && edited && !isAbsence ? event[1].time.start : null,
          end2: middleJourney && edited && !isAbsence ? event[1].time.end : null,
          events: event
        };
      },
      onClickDay( cell ) {

        if ( this.selected.length !== 1 ) return;

        this.dialog = {
          ...this.dialog,
          open: true,
          date: moment( cell.date ).format('DD/MM/YYYY'),
          weekday: cell.weekday.value,
          value: moment( cell.date ).valueOf(),
          item: this.items.find( p => p.id === this.selected[0] ),
          edited: false,
          middleJourney: false,
          mode: 0,
          absenceType: null,
          absenceSubtype: null,
          comments: '',
          start: null,
          end: null,
          start2: null,
          end2: null,
          events: []
        };
      },
      undoEdition() {
        this.dialog.start =
        this.dialog.end =
        this.dialog.start2 =
        this.dialog.end2 =
        this.dialog.absenceType =
        this.dialog.absenceSubtype = null;
        this.dialog.comments = '';
        this.dialog.mode = 0;
        this.applyEdition();
      },
      applyEdition() {

        const { priority, middleJourney, mode } = this.dialog;
        const target = 'Users/setTime';
        const params = this.sanitize( this.dialog.item );
        const times = [
          [ this.dialog.start, this.dialog.end ],
          [ this.dialog.start2, this.dialog.end2 ]
        ].map( t => {
          if ( t[0] ) t[0] = timeToOffset( t[0] );
          if ( t[1] ) t[1] = timeToOffset( t[1] );
          return t;
        });

        // Prepare events

        var events = [], event;
        times.forEach(( time, i ) => {
          if (
            this.dialog.events[i]
            && ( event = params.timetable.find( a => a.id === this.dialog.events[i].data.timeId ))
            && event.priority !== 0
          ) {
            if (( mode && !i ) || ( time[0] != null && time[1] != null && ( !i || middleJourney ))) {
              Object.assign( event, {
                priority: mode ? -1 : 1,
                startDate: this.dialog.value,
                endDate: this.dialog.value,
                startOffset: time[0] || 0,
                endOffset: time[1] || ( 24 * 60 - 1 ),
                absenceType: mode ? this.dialog.absenceType : null,
                absenceSubtype: mode ? this.dialog.absenceSubtype : null,
                comments: mode ? this.dialog.comments : null
              });
            } else {
              // Remove event
              toRemove.push( event.id );
              params.timetable.splice( params.timetable.indexOf( event ), 1 );
            }
          } else if (( mode && !i ) || ( time[0] != null && time[1] != null && ( !i || middleJourney ))) {
            events.push({
              id: -1,
              priority: mode ? -1 : 1,
              position: i,
              idCampaign: parseInt( this.$route.params.id ),
              dayOfWeek: this.dialog.weekday + 1,
              frequencyType: 0,
              startDate: this.dialog.value,
              endDate: this.dialog.value,
              startOffset: time[0] || 0,
              endOffset: time[1] || ( 24 * 60 - 1 ),
              absenceType: mode ? this.dialog.absenceType : null,
              absenceSubtype: mode ? this.dialog.absenceSubtype : null,
              comments: mode ? this.dialog.comments : null,
              status: 1
            });
          }
        });

        // Add new events
        params.timetable = params.timetable.concat( events );

        // Save
        this.loading = true;
        this.dialog.open = false;

        this.remove()
          .then(() => this.$store.dispatch( 'api', { target, params }))
          .then(() => {
            this.loading = false;
            this.model = null;
            this.$store.dispatch( 'console/success', 'Horario actualizado.');
            this.$nextTick( this.refresh );
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
      },
      setTitle() {

        const id = parseInt( this.$route.params.id );
        const title = [ 'Campañas', '', 'Calendario' ];
        this.$store.commit( 'setTitle', title );

        if ( this.breadcrumbs[1].text === '...' && id ) {
          this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
            title[1] = res.title;
            this.$store.commit( 'setTitle', title );
          });
        }
      }
    },
    beforeMount() {
      this.set({
        value: this.today.format('YYYY-MM-DD'),
        type: 'month'
      });
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

<style>
.v-list-item.is-future p:nth-child(3) > span {
  font-size: 12px !important;
  font-weight: bold;
  padding: 0 5px;
  display: inline-block;
  margin: 5px 0;
  color: white !important;
  background-color: var(--v-tertiary-base);
}
.v-list-item.is-past p:nth-child(3) > span {
  font-size: 12px !important;
  font-weight: bold;
  padding: 0 5px;
  display: inline-block;
  margin: 5px 0;
  color: white !important;
  background-color: var(--v-primary-base);
}
</style>

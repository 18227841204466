<template>
  <div class="thumbnail">
    <v-img v-bind="props" v-on="$listeners">
      <v-row slot="placeholder" class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate :color="props.progressColor || 'white'"/>
      </v-row>
    </v-img>
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: [ 'value', 'itemValue', 'itemAttr' ],
    data: () => ({
      model: null
    }),
    computed: {
      props() {
        const props = { ...this.$attrs };
        if ( this.model && this.itemAttr && typeof this.itemAttr === 'object' && typeof this.model === 'object' ) {
          for ( var key in this.itemAttr )
            props[key] = this.model[ this.itemAttr[key]];
        }
        props.value = this.compute( this.model );
        return props;
      }
    },
    watch: {
      value( value ) {
        this.change( value );
      }
    },
    methods: {
      change( value ) {

        value = this.compute( value );
        const key = this.itemValue;

        if ( ! key ) this.model = value;
        else if ( this.model && typeof this.model === 'object') this.model[key] = value;
        else this.model = { [key]: value };

        this.$emit( 'input', value );
      },
      compute( value ) {
        const key = this.itemValue;
        if ( key && value && typeof value === 'object' ) value = value[key];
        return value;
      }
    },
    mounted() {
      this.change( this.value );
    }
  }
</script>

<template>
  <ListSelector
    :value="value"
    :params="computedParams"
    :headers="headers"
    :title="title"
    :expandible="false"
    service="Campaigns"
    method="centers"
    @input="$emit('input',$event)"
  >
    <template v-slot:item="{ item }">
      <span class="single-line">
        <strong>{{ userName( item.user, true ) }}</strong> | {{ direction( item.place, true ) }}
      </span>
    </template>
  </ListSelector>
</template>

<script>

import ListSelector from '@/components/base/ListSelector';
import { Format } from "@/utils/time";
import { direction, userName, Params } from "@/utils";

const NUMBER_FILTER = {
  type: 'number',
  attrs: { min: 0, max: 100, hideControls: true }
};

function date(value) {
  return Format(value, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export default {
  components: { ListSelector },
  props: {
    value: Array,
    campaignId: Number,
    params: Object,
    title: {
      type: String,
      default: 'Centros'
    }
  },
  data: () => ({
    headers: [
      {
        text: "Id",
        value: "id",
        width: 80,
        filterable: false,
      },{
        text: "Establecimiento",
        value: "place",
        display: direction,
        filter: {
          type: "text",
          attrs: {
            service: "Places",
            itemText: "name",
            itemValue: "id",
            all: true,
          },
        },
      },
      {
        text: "Trabajador",
        value: "user",
        display: (v) => userName(v),
        filter: {
          type: "autocomplete",
          attrs: {
            service: "Users",
            itemValue: "id",
            itemText: "name",
            display: (v) => userName(v, true),
            all: true,
          },
        },
      },
      {
        text: "Fecha de inicio",
        value: "startDate",
        width: 110,
        align: "center",
        display: date,
        filter: { type: "rangedate" },
      },
      {
        text: "Fecha de fin",
        value: "endDate",
        width: 110,
        align: "center",
        display: date,
        filter: { type: "rangedate" },
      },
      {
        text: "Coste por hora",
        value: "costHour",
        width: 110,
        align: "center",
        display: "numeric",
        filter: NUMBER_FILTER
      },
      {
        text: "Coste por hora festivo",
        value: "holidayCostHour",
        width: 110,
        align: "center",
        display: "numeric",
        filter: NUMBER_FILTER
      },
      {
        text: "Plus por día",
        value: "extraCostDay",
        width: 110,
        align: "center",
        display: "numeric",
        filter: NUMBER_FILTER
      },
      {
        text: "Vacaciones pagadas",
        value: "paidHolidays",
        width: 110,
        align: "center",
        filterable: true,
        filter: {
          type: 'select',
          field: 'paidHolidays',
          items: [
            { value: true, text: 'Sí' },
            { value: false, text: 'No' }
          ]
        }
      }
    ]
  }),
  computed: {
    computedParams() {
      return Params({
        filters: [
          { field: 'campaign', intValue: this.campaignId }
        ]
      }, this.params )
    },
  },
  methods: {
    direction,
    userName
  }
}
</script>

// https://vuex.vuejs.org/en/actions.html

import API from '../api';
import { OAUTH_OPTIONS, UserRole } from '../utils/constants';
import ClientOAuth2 from 'client-oauth2';
import uploadService from '../api/upload.service';

function setStorage(token) {

  const { accessToken, refreshToken, tokenType, expires } = token;

  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('tokenType', tokenType);
  localStorage.setItem('tokenExpiration', expires.getTime());
}

function variable(state, name, service, id) {
  const v = state.vars[name];
  return v && v.service === service && v.id === id ? v : null;
}

export default {

  login({ dispatch }, user) {
    return new Promise((resolve, reject) => {

      new ClientOAuth2(OAUTH_OPTIONS).owner.getToken(user.name, user.password)
        .then(response => {

          this.state.token = response.accessToken;
          setStorage(response);

          dispatch('currentUser')
            .then(resolve)
            .catch(reject);

        }).catch(err => {

          reject(err);
        })
    })
  },

  refreshToken({ dispatch }) {
    return new Promise((resolve, reject) => {

      const accessToken = localStorage.getItem('accessToken');

      if (accessToken && accessToken != 'undefined') {

        const tokenExpiration = localStorage.getItem('tokenExpiration');
        const now = Date.now();

        if (parseInt(tokenExpiration) > now) {

          dispatch('currentUser')
            .then(resolve)
            .catch(err => {

              console.warn(err);
              localStorage.removeItem('accessToken');
              this.state.userID = -1;
              resolve(null);

            });

          return;
        }

        const oauth = new ClientOAuth2(OAUTH_OPTIONS);
        const refreshToken = localStorage.getItem('refreshToken');
        const tokenType = localStorage.getItem('tokenType');

        var token = oauth.createToken(accessToken, refreshToken, tokenType, { data: {} });
        token.expiresIn(new Date(tokenExpiration));

        token.refresh().then(response => {

          this.state.token = response.accessToken;
          setStorage(response);

          dispatch('currentUser')
            .then(resolve)
            .catch(reject);

        }).catch(err => {

          this.state.userID = -1;
          reject(err);
        });

      } else {

        this.state.userID = -1;
        resolve(null);

      }
    });
  },

  logout({ dispatch }) {
    this.state.userID = -1;
    this.state.user = null;
    this.state.isAdmin = false;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch('socket/off');
  },

  // API Services

  api( context, payload ) {
    return new Promise((resolve, reject) => {

      const accessToken = localStorage.getItem('accessToken');
      const { target, params } = payload;
      const [ service, method ] = target.split('/');
      const APIService = API[service];

      if ( ! accessToken ) return reject();

      APIService[method].apply( APIService, [accessToken].concat( params || [] ))
        .then( response => {
          if ( response.data.error ) {

            if ( response.error == "invalid_token" ) {
              //Seteamos la expiración del token a 0
              localStorage.setItem('tokenExpiration', 0);
              reject( response );
              return;
            }
            else if ( response.data.error < 0 ) {
              reject( response );
              return;
            }
          }
          resolve( response.data.object );
        })
        .catch( err => reject([ err ]));
    });
  },

  page({ dispatch }, payload ) {
    return new Promise(( resolve, reject ) => {

      const { service, subservice, method, page, filters, order, ascending } = payload;
      const params = payload.params || { page: page || 1, order, ascending, filters };
      const target = `${ subservice || service }/${ method || 'all' }`;

      dispatch( 'api', { target, params }).then( response => {
        resolve({ ...params, ...response });
      }).catch( reject );
    });
  },

  single({ dispatch }, payload ) {
    return new Promise(( resolve, reject ) => {

      const id = payload.id || 'new';
      const { service, subservice, method } = payload;
      const params = payload.params || { id };

      if ( id === 'new' ) {

        resolve({});

      } else {

        const target = `${ subservice || service }/${ method || 'get' }`;

        dispatch( 'api', { target, params })
          .then( resolve )
          .catch( reject );
      }
    });
  },

  exportData({ dispatch }, payload ) {
    return new Promise(( resolve, reject ) => {

      const { service, filters, method, id } = payload;

      const target = `${service}/${method || 'export'}`;

      dispatch( 'api', { target: target, params: { id, filters }})
        .then( resolve )
        .catch( reject );

    });
  },

  importData({ dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const { service, data, method } = payload;

      const target = `${service}/${method || 'import'}`;

      dispatch('api', { target: target, params: data })
        .then(resolve)
        .catch(reject);
    });
  },

  cancelRequest(context, opt) {
    const { target, message } = opt;
    return API[target].cancel(message);
  },

  currentUser({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      dispatch('api', { target: 'Users/current' })
        .then(response => {

          this.state.user = response;
          this.state.userID = response.id;
          this.state.isAdmin = response.id > 0 && response.role === UserRole.SUPERADMIN;
          this.state.isKAM = response.id > 0 && response.role === UserRole.KAM;
          this.state.isManager = response.id > 0 && response.role === UserRole.MANAGER;
          this.state.isClient = response.id > 0 && response.role === UserRole.CLIENT;
          this.state.isRRHH = response.id > 0 && response.role === UserRole.RRHH;

          commit('socket/set', {
            chats: response.chats,
            issues: response.issues,
            tasks: response.tasks,
            evaluations: response.evaluations
          });

          // Connect to Web Sockets
          if ( process.env.NODE_ENV == 'production' ) {
            dispatch('socket/on')
              .then(() => dispatch('socket/subscribe', {
                [response.id]: res => commit('socket/set', res.data),
              }))
              .catch(console.warn);
          }

          resolve(response);

        }).catch(err => {
          //console.log( [err] );
          this.state.user = null;
          this.state.userId = -1;
          this.state.isAdmin = false;
          reject(err);
        })
    });
  },

  connectSocket({ dispatch, state, commit }) {
    console.log("Connecting socket for user " + state.userID);
    if (state.user && state.userID) {
      dispatch('socket/on')
        .then(() => dispatch('socket/subscribe', {
          [state.userID]: res => commit('socket/set', res.data),
        })).catch(err => {
          console.warn("STOMP: " + err);
          console.log('STOMP: Reconecting in 10 seconds');
          setTimeout(() => {
            dispatch('connectSocket')
          }, 10000);
        });
    }
  },

  vars({ dispatch, commit, state }, params ) {
    return new Promise( resolve => {

      var count = 0;
      const { core } = state;
      const { service, subservice } = core;
      const vars = {
        item: variable( state, 'item', service, params.id ),
        subitem: variable( state, 'subitem', subservice, params.index )
      };

      // Reset vars
      commit( 'setVars', vars );

      // Get value vars
      if ( !vars.item && service && params.id && params.id !== 'new' ) {

        dispatch( 'api', { target: `${ service }/get`, params: { id: params.id } })
          .then( data => {
            vars.item = { service, data, id: params.id };
            commit('setVars', vars);
          })
          .catch(() => commit('setVars', vars))
          .finally(() => {
            count++;
            if (count === 2) resolve(vars);
          });

      } else {
        count++;
      }

      if (!vars.subitem && subservice && params.index && params.index !== 'new') {

        dispatch('api', { target: `${subservice}/get`, params: { id: params.index } })
          .then(data => {
            vars.subitem = { service: subservice, data, id: params.index };
            commit('setVars', vars);
          })
          .catch(() => commit('setVars', vars))
          .finally(() => {
            count++;
            if (count === 2) resolve(vars);
          });

      } else {
        count++;
        if (count === 2) resolve(vars);
      }
    });
  },

  uploadImage(context, formData) {
    return new Promise((resolve, reject) => {

      const accessToken = localStorage.getItem('accessToken')

      if (accessToken != null) {
        uploadService.uploadPicture(accessToken, formData)
          .then(response => {
            // handle success
            //console.log( response );

            if (response.data.error < 0) {
              reject(response.data);
            } else {
              resolve(response.data.object)
            }
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      }
    });
  },

  uploadFile(context, formData) {
    return new Promise((resolve, reject) => {

      const accessToken = localStorage.getItem('accessToken')

      if (accessToken != null) {
        uploadService.uploadFile(accessToken, formData)
          .then(response => {
            // handle success
            //console.log( response );

            if (response.data.error < 0) {
              reject(response.data);
            } else {
              resolve(response.data.object)
            }
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      }
    });
  },
}

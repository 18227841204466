import { toArray } from '@/utils';

function setBreadcrumbs( state, path ) {

  path = path || state.path;
  path = path.slice(1).split('/');
  var _path = '', view;

  state.breadcrumbs = state.breadcrumbs.slice( 0, path.length );
  path.forEach(( str, index ) => {
    _path += '/' + str;
    if (( view = state.views[_path])) {
      state.breadcrumbs[index] = {
        text: view.title || '...',
        to: _path
      }
    } else {
      state.breadcrumbs[index] = state.breadcrumbs[index] || {
        text: '...',
        to: _path
      };
    }
  });
}

function mergeView( old, view ) {

  old = old || {};
  view = view || {};

  const sections = old.sections || {};
  old = { ...old, ...view, sections };

  Object.keys( view.sections || {}).forEach( s => {
    var oldSec = old.sections[s] || {};
    old.sections[s] = {
      ...oldSec,
      ...view.sections[s]
    }
  });

  return old;
}

export default {
  setCampaigns( state, campaigns ) {
    state.campaigns = campaigns;
  },
  setPath( state, path ) {

    path = path || state.path;
    if ( path === state.path ) return;

    state.path = path || state.path;
    state.view = state.views[path];

    const section = (( state.view ? state.view.section : 0 ) || [0,0]);
    const s = section.join('');

    if ( !state.view ) {
      state.view = {
        section,
        sections: {}
      };
    }

    if ( ! state.view.sections || ! state.view.sections[s] ) {
      state.view.sections = state.view.sections || {};
      state.view.sections[s] = {
        showFilters: false,
        totalItems: 0,
        page: 1,
        numPages: 1,
        data: [],
        selected: [],
        filterStore: {}
      }
    }

    state.core = { ...state.view.sections[s] };

    setBreadcrumbs( state );
  },
  setView( state, payload ) {

    const { path } = payload;

    state.views[path] = mergeView(
      state.views[path],
      payload.state
    );

    if ( state.path === path ) {
      state.view = { ...state.views[path] };
      const s = (state.view.section||[0,0]).join('');
      state.core = { ...state.view.sections[s] };
    }

    setBreadcrumbs( state, path );
  },
  setTitle( state, title ) {

    var path = '';
    title = toArray( title );

    state.path.slice(1).split('/').forEach(( str, index ) => {
      path += '/' + str;
      if ( ! title[index] ) return;
      var st = state.views[path] = state.views[path] || {};
      st.title = title[index];
    });

    setBreadcrumbs( state );
  },
  setCore( state, payload ) {
    state.core = { ...state.core, ...payload };
  },
  cancelFetch( state, path ) {
    state.canceled = path;
  }
}

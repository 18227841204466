<template>
  <Table
    ref="table"
    service="Workers"
    min-width="2072px"
    :params="params"
    :headers="headers"
    :messages="messages"
    :show-actions=actions
  >
    <template v-slot:item.user="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link" v-if="!isClient"
        :title="value"
        :to="`/users/${item.user.id}`"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.campaign="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="item.campaign && !isClient"
        :title="value"
        :to="`/campaigns/${item.campaign.id}`"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.user.email="{ value }">
      <a
      class="body-grupo-ot grupo-ot-link"
        :href="`mailto:${value}`"
        :title="value"
        target="_blank"
      >
        {{ value }}
      </a>
    </template>

    
    <template v-slot:item.validated="{ item }">
       <div v-bind:class="{ 'pending': !item.validated && item.workingTime <= 0, 'done': !item.validated && item.workingTime > 0, 'validated': item.validated }">{{item.validated ? "Sí": "No"}}</div>
     </template>

    <template v-slot:item.timetable="{ item, value }">
      <a color="primary" class="dtable-cell-content clickable body-grupo-ot grupo-ot-link" @click="showEditTimetable(item)" small text v-if="!isClient">
      <span v-html="getTiming(item).join('</br>')" />
      </a>
      <span class="body-grupo-ot" v-html="getTiming(item).join('</br>')" v-else/>
    </template>

    <template v-slot:item.workingTime="{ item, value }">
      <Btn class="body-grupo-ot" @click="showWorkingTime(item)" small text  v-if="!isClient">
        <span v-html="value" />
      </Btn>
    </template>

    <template v-slot:item.holiday="{ item }">
       <div>{{item.holiday ? "Sí": "No"}}</div>
     </template>
     
    <template v-slot:item.campaign.client="{ value }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="value"
        :title="value.name"
        :to="`/clients/${value.id}`"
      >
        {{ value.id }}. {{ value.name }}
      </router-link>
    </template>

    <template v-slot:item.lastLocation="{ item, value }">
      <Btn @click="showGeolocation(item)" icon>
        <v-icon
          style="width: 30px; height: 30px"
          :color="getPositionColor(value)"
        >
          $place
        </v-icon>
      </Btn>
    </template>

    <template v-slot:item.idChat="{ value }">
      <Btn
        color="secondary"
        @click="goTo('/chat', { selected: { id: value } })"
        small
        icon
      >
        <v-icon>$chat</v-icon>
      </Btn>
    </template>

    <template v-slot:item.issues="{ value }">
      <Btn color="secondary" @click="goTo('/issues')" small text  v-if="!isClient">
        <v-icon left>$issue</v-icon>
        {{ value }}
      </Btn>
    </template>

    <template v-slot:item.totalTaks="{ value, item }">
      <Btn color="secondary" @click="editTask(item)" small text>
        <v-icon left>$task</v-icon>
        {{ value }}
      </Btn>
    </template>

    <template v-slot:actions-right>
      <Btn class="ml-2" @click="initWizard" color="tertiary" dark v-if="!$store.state.isClient">
        Crear fichajes
      </Btn>
    </template>

    <template v-slot:append-outer>
      <v-dialog v-model="geolocation.open" max-width="640">
        <Card>
          <Toolbar color="secondary" dark>
            <h3>
              Última posición de <strong>{{ geolocation.user }}</strong> ({{
                getDate(geolocation.date)
              }})
            </h3>
            <v-spacer />
            <Btn
              color="white"
              width="20"
              height="20"
              fab
              light
              @click="geolocation.open = false"
            >
              <v-icon small>$close</v-icon>
            </Btn>
          </Toolbar>
          <div class="pa-4">
            <DField
              :markers="geolocation.markers"
              field="gmap"
              height="430"
              zoom="10"
              show-position
            />
          </div>
        </Card>
      </v-dialog>

      <v-dialog v-model="dialog.open" max-width="560">
        <Card class="d-flex flex-column flex-nowrap">
          <Toolbar class="shrink" color="secondary" dark>
            Editar día {{ dialog.date }} para {{ dialog.place.fullName }}
            <v-spacer />
            <Btn
              color="white"
              width="20"
              height="20"
              fab
              light
              @click="dialog.open = false"
            >
              <v-icon small>$close</v-icon>
            </Btn>
          </Toolbar>
          <Tabs class="shrink" v-model="dialog.mode" :items="dialog.tabs" />
          <v-row class="grow pa-4" no-gutters>
            <template v-if="!dialog.mode">
              <v-col class="pr-2">
                <v-checkbox
                  v-if="!dialog.place.allday"
                  v-model="dialog.middleJourney"
                  :ripple="false"
                  :disabled="dialog.holiday"
                  label="Jornada partida"
                  class="body-grupo-ot mt-0 mb-4"
                  color="primary"
                  hide-details
                />

                <DField
                  v-if="!dialog.place.allday"
                  v-model="dialog.start"
                  :disabled="dialog.holiday"
                  :max="dialog.end"
                  class="mb-4"
                  field="time"
                  field-style="1"
                  label="Hora de entrada"
                  dense
                />

                <DField
                  v-if="!dialog.place.allday"
                  v-model="dialog.end"
                  :disabled="dialog.holiday"
                  :min="dialog.start"
                  field="time"
                  field-style="1"
                  label="Hora de salida"
                  dense
                />
              </v-col>
              <v-col v-if="dialog.middleJourney" class="pl-2 pt-11">
                <DField
                  v-model="dialog.start2"
                  :disabled="dialog.holiday"
                  :max="dialog.end2"
                  class="mb-4"
                  field="time"
                  field-style="1"
                  label="Hora de entrada"
                  dense
                />

                <DField
                  v-model="dialog.end2"
                  :disabled="dialog.holiday"
                  :min="dialog.start2"
                  field="time"
                  field-style="1"
                  label="Hora de salida"
                  dense
                />
              </v-col>
            </template>
            <template v-else>
              <v-col class="pr-2">
                <DField
                  v-model="dialog.absenceType"
                  :items="dialog.absences"
                  field="select"
                  field-style="1"
                  class="mb-4"
                  label="Tipo"
                  dense
                />

                <DField
                  v-if="dialog.subabsences[dialog.absenceType]"
                  v-model="dialog.absenceSubtype"
                  :items="dialog.subabsences[dialog.absenceType]"
                  field="select"
                  field-style="1"
                  label="Razón"
                  class="mb-4"
                  dense
                />

                <DField
                  v-model="dialog.comments"
                  field="textarea"
                  field-style="1"
                  label="Comentarios"
                  rows="4"
                />
              </v-col>
            </template>
          </v-row>
          <Toolbar class="shrink" border-top>
            <v-spacer />

            <Btn color="primary" @click="applyEdition"> Aplicar </Btn>
          </Toolbar>
        </Card>
      </v-dialog>

      <v-dialog v-model="worktime.open" max-width="640">
        <Card>
          <Toolbar color="secondary" dark>
            <h3>Jornada del trabajador</h3>
            <v-spacer />
            <Btn
              color="white"
              width="20"
              height="20"
              fab
              light
              @click="worktime.open = false"
            >
              <v-icon small>$close</v-icon>
            </Btn>
          </Toolbar>

          <div v-if="worktime.item" class="pa-4">
            <span class="pa-1">
              Usuario: <strong>{{ worktime.item.user.alias }}</strong>
            </span>
            <span class="pa-1">
              Fecha:
              <strong>{{ getDate(worktime.item.date, "DD/MM/YYYY") }}</strong>
            </span>
          </div>
          <v-container
            class="pa-1"
            v-for="(log, index) in worktime.workLogs"
            :key="index"
            no-gutters
          >
            <v-row style="background: white" no-gutters>
              <v-col class="rel" cols="5">
                <DField
                  v-model="log.startOffset"
                  field="time"
                  field-style="1"
                  label="Inicio"
                  labelSpace="90"
                  class="pa-1"
                  :dense="true"
                />
              </v-col>

              <v-col class="rel" cols="5">
                <DField
                  v-model="log.endOffset"
                  field="time"
                  field-style="1"
                  label="Fin"
                  labelSpace="90"
                  class="pa-1"
                  :dense="true"
                />
              </v-col>

              <v-col cols="2" md="2" class="rel">
                <Btn
                  color="primary"
                  class="pa-2 ma-1"
                  @click="worktime.workLogs.splice(log, 1)"
                >
                  <v-icon>$delete</v-icon>
                </Btn>
              </v-col>
            </v-row>
          </v-container>
          <Toolbar border-top class="pb-1">
            <v-spacer />

            <Btn
              class="ma-4"
              color="secondary"
              @click="
                worktime.workLogs.push({ startOffset: '', endOffset: '' })
              "
            >
              Añadir Rango</Btn
            >

            <Btn color="primary" @click="saveWorkedTime"> Guardar </Btn>
          </Toolbar>
        </Card>
      </v-dialog>

      <!--<v-dialog v-model="geolocation.open" max-width="640">
        <Card>
          <Toolbar color="secondary" dark>
            <h3>
              Última posición de <strong>{{ geolocation.user }}</strong> ({{ getDate( geolocation.date ) }})
            </h3>
            <v-spacer />
            <Btn
              color="white"
              width="20" height="20" fab light
              @click="geolocation.open = false"
            >
              <v-icon small>$close</v-icon>
            </Btn>
          </Toolbar>
          <div class="pa-4">
            <DField
              :markers="geolocation.markers"
              field="gmap"
              height="430"
              zoom="10"
              show-position
            />
          </div>
        </Card>
      </v-dialog>-->

      <Wizard
        v-model="wizardShow"
        @save="createWorker"
      />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import Wizard from "@/components/forms/Workers/wizard.vue";
import moment from "moment";
import { userName, marker } from "@/utils";
import { AbsenceType, AbsenceSubtype, CampaignStatus } from "@/utils/constants";
import { mapState, mapMutations } from "vuex";

function minutes(value) {
  if (value) {
    if (value > 59) {
      var hours = Math.floor(value / 60);
      var minutes = value - hours * 60;
      var formattedminutes = ("0" + minutes).slice(-2);
      return hours + ":" + formattedminutes;
    }
    return Number(value) + " min";
  }
  return "-";
}

function timing(item) {
  //Miramos primero se no trabajaba...
  var notWorking = item.absenceType!=null;
  if (notWorking) {
    var array = [];

    var abs = absences.filter((v) => v.value == item.absenceType);
    array.push("<strong>"+abs[0].text+"</strong>");
    if (
      item.absenceType == AbsenceType.PLACE ||
      item.absenceType == AbsenceType.INCOMPLETE
    ) {
      var subabs = subAbsences.filter((v) => v.value == item.absenceSubtype);
      if (subabs.length > 0) {
        array.push(subabs[0].text);
      }
    }
    return array;
    //    return ["No trabaja"];
  }

  return item.timetable.split(",").map((v) => v.split("-").join(" - "));
}

function date(time) {
  if (time) return moment(time).format("DD/MM/YYYY");
  return "-";
}

function time(time) {
  if (time) return moment(time).format("HH:mm:ss");
  return "-";
}

function format(value) {
  value = String(value).slice(0, 2);
  return "00".slice(value.length) + value;
}

function offsetToTime(offset) {
  if (offset != null) {
    const H = Math.floor(offset / 60);
    const M = offset - H * 60;
    return format(H) + ":" + format(M);
  }
  return "";
}

function timeToOffset(time) {
  time = String(time).split(":");
  const H = parseInt(time[0]) || 0;
  const M = parseInt(time[1]) || 0;
  return H * 60 + M;
}

// COMMON headers

const absences = [
 // { text: "Festivo laboral", value: AbsenceType.HOLIDAY_WORKING },
  { text: "Festivo No laboral", value: AbsenceType.HOLIDAY_NO_WORKING },
  { text: "Vacaciones", value: AbsenceType.USER_HOLIDAYS },
  { text: "Libranza", value: AbsenceType.LIBRANZA },
  { text: "Centro descubierto", value: AbsenceType.PLACE },
  { text: "Jornada incompleta", value: AbsenceType.INCOMPLETE },
  { text: "Observaciones", value: AbsenceType.OTHER },
];

const subAbsences = [
  { text: "Pendiente de selección", value: AbsenceSubtype.NO_CUBIERTA_PDT_SELECT },
  { text: "Pendiente de cliente", value: AbsenceSubtype.NO_CUBIERTA_PDT_CLIENTE },
  { text: "Pendiente de PDV", value: AbsenceSubtype.NO_CUBIERTA_PDT_PDV },
  { text: "Baja voluntaria", value: AbsenceSubtype.BAJA_VOLUNTARIA },
  { text: "Despido / NSPP", value: AbsenceSubtype.DESPIDO },
  { text: "Imprevisto personal", value: AbsenceSubtype.IMPREVISTO_PERSONAL },
  { text: "Enfermedad", value: AbsenceSubtype.ENFERMEDAD },
  { text: "Problemas documentación", value: AbsenceSubtype.DOCUMENTATION },
  { text: "Retraso / Avance jornada", value: AbsenceSubtype.RETRASO },
];

export default {
  components: { Table, Wizard },
  data() {
    return {
      messages: { item: "Fichaje/Fichajes" },
      geolocation: {
        open: false,
        markers: [],
        user: null,
        date: null,
      },
      dialog: {
        open: false,
        mode: 0,
        date: "",
        place: {},
        event: null,
        tabs: [{ text: "Horario" }, { text: "Ausencia" }],
        absenceType: null,
        absenceSubtype: null,
        comments: "",
        absences,
        subabsences: {
          [AbsenceType.PLACE]: subAbsences.slice(0, -1),
          [AbsenceType.INCOMPLETE]: subAbsences.slice(3),
        },
      },
      worktime: {
        open: false,
        item: null,
        workLogs: [],
      },
      wizardShow: false
    };
  },
  computed: {
    ...mapState([ 'isClient', 'breadcrumbs' ]),
    params() {
      const { id } = this.$route.params;
      return {
        filters: [
          id ? {
            field: 'campaign',
            intValue: parseInt(id)
          } : {
            field: 'campaign_status',
            intValue: CampaignStatus.ACTIVE
          }
        ]
      };
    },
    actions() {
      if ( this.$store.state.isClient ) {
        return "export";
      }
      if ( this.$store.state.isAdmin ) {
        return "edit, export, validate";
      }
      return "edit, export, validate";
    },
    service() {
      const id = parseInt( this.$route.params.id );
      return id ? 'Campaigns' : 'Workers';
    },
    headers() {

      var headers = [];

      headers.push({
          text: "Fecha",
          value: "date",
          display: date,
          width: 100,
          filter: {
            type: "rangedate",
            compute: (value, field) => {
              if (!value[0] || !value[1]) return { [field]: null };
              return { [field]: { start: value[0], end: value[1] } };
            },
          },
        });

      headers.push({
          text: "Promotor",
          value: "user",
          display: (v) => userName(v),
        });

      headers.push({
          text: "Campaña",
          value: "campaign",
          display: (a) => (a ? `${a.id}. ${a.title || a.name}` : ""),
        });
        if(!this.isClient){
      headers.push({
          text: "Cliente",
          value: "campaign.client",
          filter: { field: "client" },
        });
        }

      headers.push({
          text: "Centro",
          value: "place",
          display: (v) => v.name,
          filter: {
            type: "autocomplete",
            attrs: {
              service: "Places",
              itemText: "name",
              itemValue: "id",
              display: (v) => v.name,
            },
          },
        });

      headers.push({
          text: "Validado",
          value: "validated",
          width: 80,
          filterable: true,
          filter: {
            type: 'select',
            field: 'validated',
            items: [
              { value: true, text: 'Sí' },
              { value: false, text: 'No' }
            ]
          }
        });

      if(!this.isClient){
      headers.push({
          text: "Email",
          value: "user.email",
          filter: { field: "email" },
        });
      }

      headers.push({
          text: "Horario",
          value: "timetable",
          width: 100,
          //display: timing,
          filterable: false,
          filter: { type: "rangetime" },
        });

      headers.push({
          text: "Inicio",
          value: "startDate",
          width: 100,
          display: time,
          filterable: false,
          //filter: { type: "date" },
        });

      headers.push({
          text: "Tiempo estimado",
          value: "estimatedWorkingTime",
          width: 100,
          display: minutes,
          filterable: false,
        });

      headers.push({
          text: "Tiempo fichado",
          value: "workingTime",
          width: 100,
          display: minutes,
          filterable: false,
        });

      headers.push({
          text: "Tiempo no trabajado",
          value: "notWorkingTime",
          width: 100,
          display: minutes,
          filterable: false,
        });

      headers.push({
          text: "Tiempo validado",
          value: "validatedWorkingTime",
          width: 100,
          display: minutes,
          filterable: false,
        });

      headers.push({
          text: "Festivo",
          value: "holiday",
          width: 80,
          filterable: true,
          filter: {
            type: 'select',
            field: 'holiday',
            items: [
              { value: true, text: 'Sí' },
              { value: false, text: 'No' }
            ]
          }
        });

      headers.push({
          text: "Incidencias",
          value: "issues",
          width: 75,
          align: "center",
          filterable: false,
          /*filter: {
              type: "number",
              attrs: { min: 0, hideControls: true },
            },*/
        });

      headers.push({
          text: "Tareas",
          value: "totalTaks",
          width: 75,
          align: "center",
          filterable: false,
          /*filter: {
              type: "number",
              attrs: { min: 0, hideControls: true },
            },*/
        });
      if(!this.isClient){
      headers.push({
          text: "Geo",
          value: "lastLocation",
          width: 80,
          align: "center",
          sorteable: false,
          filterable: false,
          filter: {
            type: "select",
            items: [
              { value: -1, text: "Incorrecta" },
              { value: 0, text: "Caducada" },
              { value: 1, text: "Correcta" },
            ],
          },
        });

      headers.push({
          text: "Chat",
          value: "idChat",
          width: 75,
          align: "center",
          sorteable: false,
          filterable: false,
        });
      }

      return headers;
    },
  },
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    }
  },
  methods: {
    ...mapMutations( 'app', [ 'setDialog' ]),
    showGeolocation(item) {
      const markers = [];
      const pos = item.lastLocation;
      const place = item.place;

      if (pos)
        markers.push(
          marker({
            position: { lat: pos.latitude, lng: pos.longitude },
            label: null,
            icon: { fillColor: "#ea4335" },
          })
        );

      if (place && place.latitude != null && place.longitude != null)
        markers.push(
          marker({
            position: { lat: place.latitude, lng: place.longitude },
            label: { text: "C", color: "black" },
            icon: { fillColor: "white", strokeColor: "black" },
          })
        );

      this.geolocation = {
        user: [item.user.name, item.user.surname].join(" "),
        markers,
        open: true,
        date: pos ? pos.date : null,
      };
    },
    isOldPosition(item) {
      let now = Date.now();
      let diffSeconds = (now - item.date) / 1000;
      let diffMinutes = diffSeconds / 60;
      if (diffMinutes < 60) return false;
      return true;
    },
    getPositionColor(item) {
      if (item) {
        if (this.isOldPosition(item)) return "#bebebe";
        else if (item.inRadius) return "green";
        else {
          let diffSeconds = (Date.now() - item.date) / 1000;
          let diffMinutes = diffSeconds / 60;
          if (diffMinutes < 30) return "green";
          else if (diffMinutes < 60) return "amber";
          return "red";
        }
      }
      return "#bebebe";
    },
    goTo( path, state ) {
      this.$store.commit( 'setView', {
        path, state: { sections: {['00']: state }}
      });
      this.$router.push( path );
    },
    editTask( item ) {
      this.goTo(`/campaigns/${item.idCampaign}/day-tasks`, {
        showFilters: true,
        filterStore: {
          date: item.date,
          user: item.user 
        },
      });
    },
    getDate(date, format) {
      if (date)
        return moment(date).format(
          format != null ? format : "DD/MM/YYYY HH:mm:ss"
        );
      return "-";
    },
    showEditTimetable(item) {

      var date = item.date;
      var times = item.timetable.split(",");
      var timetable1 = times[0];

      //return value.split(",").map((v) => v.split("-").join(" - "));

      const middleJourney = times.length > 1;
      var timetable2 = middleJourney ? times[1] : null;
      const { absenceType, absenceSubtype, comments } = item;
      const isAbsence = !!absenceType;
      const edited = absenceType != null;
      console.log(item);

      this.dialog = {
        ...this.dialog,
        open: true,
        date: moment(date).format("DD/MM/YYYY"),
        weekday: null,
        value: moment(date).valueOf(),
        place: item.place,
        item: item,
        edited,
        mode: isAbsence ? 1 : 0,
        middleJourney: !isAbsence ? middleJourney : false,
        absenceType: absenceType,
        absenceSubtype: absenceSubtype,
        comments: comments || "",
        start: timetable1 != null ? timetable1.split("-")[0] : null,
        end: timetable1 != null ? timetable1.split("-")[1] : null,
        start2:
          middleJourney && timetable2 != null
            ? timetable2.split("-")[0]
            : null,
        end2:
          middleJourney && timetable2 != null
            ?  timetable2.split("-")[1]
            : null,
        events: item.timetable,
      };
    },
    showWorkingTime(item) {
      this.worktime.item = item;

      this.worktime.workLogs = [];
      item.workLogs.forEach((wl) => {
        const start = offsetToTime(wl.startOffset);
        const end = offsetToTime(wl.endOffset);

        this.worktime.workLogs.push({ startOffset: start, endOffset: end });
      });

      if (item.workLogs.length == 0) {
        this.worktime.workLogs.push({ startOffset: null, endOffset: null });
      }

      this.worktime.open = true;
    },
    sanitize(worker) {
      const timetable = [];
      //No tenemos los horarios... asi que la idea es simplemente marcar el historial... pasamos el id del worker
      return {
        id: worker.idCampaignPlace,
        idHistory: worker.id,
        date: worker.date,
        lastPriorityTimetables: null,
        timetable,
      };
    },
    initWizard() {

      const text = '<p class="title text-uppercase black--text">Recuerda que los fichajes se generan de forma automática.</p>'
                + '<p>Esta funcionalidad solamente se debe utilizar excepcionalmente para crear fichajes anteriores a ayer.</p>'
                + '<p>En caso de duda consulte con el administrador.</p>';

      this.setDialog({
        show: true,
        width: 400,
        title: '',
        text,
        acceptText: 'Continuar',
        cancelText: 'Cancelar',
        accept: () => this.wizardShow = true
      });
    },
    createWorker( params ) {
      this.loading = true;
      const target = 'Workers/create';
      this.$store.dispatch( 'api', { target, params }).then(() => {
        this.$store.dispatch( 'console/success', 'Fichajes creados' );
      }).catch( err => {
        this.$store.dispatch( 'console/error', err );
      }).finally(() => {
        this.loading = false;
        this.$nextTick( this.$refs.table.reload );
      });
    },
    applyEdition() {
      const { middleJourney, mode } = this.dialog;
      const target = "Users/updatePriorityTime";
      const params = this.sanitize(this.dialog.item);
      const times = [
        [this.dialog.start, this.dialog.end],
        [this.dialog.start2, this.dialog.end2],
      ].map((t) => {
        if (t[0]) t[0] = timeToOffset(t[0]);
        if (t[1]) t[1] = timeToOffset(t[1]);
        return t;
      });

      // Prepare events

      var events = [],
        event;
      times.forEach((time, i) => {
        if (
          this.dialog.events[i] &&
          (event = params.timetable.find(
            (a) => a.id === this.dialog.events[i].data.timeId
          )) &&
          event.priority !== 0
        ) {
          if (
            (mode && !i) ||
            (time[0] != null && time[1] != null && (!i || middleJourney))
          ) {
            Object.assign(event, {
              priority: mode ? -1 : 1,
              startDate: this.dialog.value,
              endDate: this.dialog.value,
              startOffset: time[0] || 0,
              endOffset: time[1] || 24 * 60 - 1,
              absenceType: mode ? this.dialog.absenceType : null,
              absenceSubtype: mode ? this.dialog.absenceSubtype : null,
              comments: mode ? this.dialog.comments : null,
            });
          } else {
            // Remove event
            params.timetable.splice(params.timetable.indexOf(event), 1);
          }
        } else if (
          (mode && !i) ||
          (time[0] != null && time[1] != null && (!i || middleJourney))
        ) {
          events.push({
            id: -1,
            priority: mode ? -1 : 1,
            position: i,
            idCampaign: parseInt(this.$route.params.id),
            dayOfWeek: this.dialog.weekday + 1,
            frequencyType: 0,
            startDate: this.dialog.value,
            endDate: this.dialog.value,
            startOffset: time[0] || 0,
            endOffset: time[1] || 24 * 60 - 1,
            absenceType: mode ? this.dialog.absenceType : null,
            absenceSubtype: mode ? this.dialog.absenceSubtype : null,
            comments: mode ? this.dialog.comments : null,
            status: 1,
          });
        }
      });

      // Add new events
      params.timetable = params.timetable.concat(events);

      // Save
      this.loading = true;
      this.dialog.open = false;

      this.$store
        .dispatch("api", { target, params })
        .then(() => this.refresh())
        .then(() => {
          this.model = null;
          this.$store.dispatch("console/success", "Horario actualizado.");
        })
        .catch((err) => this.$store.dispatch("console/error", err))
        .finally(() => (this.loading = false));
    },
    refresh() {
      this.$refs.table.reload();
    },
    saveWorkedTime() {
      const item = this.worktime.item;
      const campaign = item.campaign;
      const place = item.place;
      const user = item.user;
      var params = {
        idHistory: item.id,
        idUser: user.id,
        idPlace: place.id,
        idCampaign: campaign.id,
        date: item.date,
        times: [],
      };

      this.worktime.workLogs.forEach((t) => {
        var startOffset = timeToOffset(t.startOffset);
        var endOffset = timeToOffset(t.endOffset);

        console.log("Start Offset: " + startOffset);
        console.log("End Offset: " + endOffset);

        params.times.push({ startOffset, endOffset });
      });

      const target = "Users/updateTimeLog";

      // Save
      this.loading = true;

      this.$store
        .dispatch("api", { target, params })
        .then(() => this.refresh())
        .then(() => {
          //this.model = null;
          this.$store.dispatch(
            "console/success",
            "Tiempo de trabajo actualizado."
          );
        })
        .catch((err) => this.$store.dispatch("console/error", err))
        .finally(() => (this.loading = false));

      //$refs.table.reload

      this.worktime.open = false;
    },
    getTiming(item) {
      if (item != null ) {
        return timing(item);
      }
      return ["-"];
    },
    setTitle() {

      const id = parseInt( this.$route.params.id );
      const title = [ 'Campañas', '', 'Fichajes' ];

      if ( ! id ) {
        title.splice( 0, 2 );
        return this.$store.commit( 'setTitle', title );
      }

      this.$store.commit( 'setTitle', title );
      if ( this.breadcrumbs[1].text === '...' && id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

<style>
  .pending {
    color: var(--v-error-base);
  }
  .done {
    color: var(--v-warning-base);
  }
  .validated {
    color: var(--v-success-base);
  }
</style>

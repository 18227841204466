export default {
  connected: false,
  socket: null,
  stomp: null,
  frame: null,
  chats: 0,
  issues: 0,
  tasks: 0,
  evaluations: 0,
  subscriptions: []
};

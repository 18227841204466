<template>
  <Table
    service="Issues"
    :sanitize="sanitize"
    :messages="messages"
    :sections="sections"
    :headers="headers"
    :update="update"
    :mypopup="mypopup"
    :show-actions="actions"
  >

    <template v-slot:item.pending="{ value }">
      <v-icon
        v-show="value"
        v-text="'mdi-circle'"
        color="primary"
        x-small
      />
    </template>

    <template v-slot:item.date="{ value, header }">
      {{ date( value, header ) }}
    </template>

    <template v-slot:item.user="{ value, item }">
       <div v-if="isClient">
        {{ value }}
      </div>
      <router-link class="body-grupo-ot grupo-ot-link" v-else :title="value" :to="`/users/${ item.user.id }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.campaign="{ value, item }">
      <div v-if="isClient">{{ value }}</div>
      <router-link class="body-grupo-ot grupo-ot-link" v-else-if="item.campaign" :title="value" :to="`/campaigns/${ item.idCampaign }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.priority="{ value }">
      <strong class="dtable-cell-content" :class="value.class" :title="value.text">
        {{ value.text }}
      </strong>
    </template>

    <template v-slot:item.status="{ value }">
      <strong class="dtable-cell-content" :class="value.class" :title="value.text">
        {{ value.text }}
      </strong>
    </template>

    <template v-slot:form="{ value, listeners, save }">
      <Issue
        class="fill-height"
        :value="value"
        v-on="listeners"
        @save="save()"
        @download="download( value )"
      />
    </template>

  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import Issue from '@/components/forms/Issues/single.vue';
  import { formatDate, direction, userName } from '@/utils';

  function category( value, complete ) {
    if ( value ) {
      if ( complete && value.parent ) {
        return value.parent.name;
      }
      return value.name;
    }
    return "";
  }

  function subcategory( value ) {
    if ( value ) {
      if ( value.parent ) {
        return value.name;
      }
    }
    return "";
  }

  const types = [ 'Incidencia', 'Alerta' ];
  const status = [
    { class: 'info--text text-uppercase', text: 'Abierta' },
    { class: 'success--text text-uppercase', text: 'En trámite' },
    { class: 'success--text text-uppercase', text: 'Ampliar información' },
    { class: 'success--text text-uppercase', text: 'Gestión para resolución' },
    { class: 'text-uppercase', text: 'Cerrada' }
  ];
  const priority = [
    { class: 'info--text text-uppercase', text: 'Baja' },
    { class: 'success--text text-uppercase', text: 'Normal' },
    { class: 'error--text text-uppercase', text: 'Alta' }
  ];

  export default {
    components: { Table, Issue },
    data() {
      return {
        messages: { item: 'Incidencia/Incidencias', fem: true },
        mypopup: {
          width: 720,
          height: 520,
          title: (n,s) => `${s.value?s.value.id:''}. Incidencia`,
          hideFooter: true,
          arrows: true
        },
        headers: [
          { text: 'Pendiente', value: 'pending', width: 60, filterable: true, filter: {
            type: 'select',
            items: [
              { value: true, text: 'Pendiente' },
              { value: false, text: 'No pendiente' }
            ]
          }},
          { text: "id", value: "id", width: 80, filter: {
            field: 'ids',
            type: 'number',
            attrs: { min: 1, hideControls: true }
          }},
          { text: 'Fecha', value: 'date', name: 'date', width: 90, sorteable: true, filter: {
            type: 'rangedate',
            compute: ( value, field ) => {
              if ( !value[0] || !value[1] ) return {[field]: null };
              return {[field]: { start: value[0], end: value[1] }};
            }}},
          //{ text: 'Hora', value: 'date', name: 'time', width: 60, sorteable: false, filterable: false },
          {
            text: 'Usuario', value: 'user', display: v => userName( v ),
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Users',
                itemText: 'name',
                itemValue: 'id',
                display: v => userName( v, true ),
                all: true
              }
            }
          },
          {
              text: 'Campaña',
              value: 'campaign',
              //display: a => `${a.id}. ${a.title||a.name}`
              display: a => a!=null ? `${a.id}. ${a.title||a.name}` : ''
          },
          {
            text: 'Centro',
            value: 'place',
            display: v => direction( v, true ),
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Places',
                itemText: 'name',
                itemValue: 'id',
                display: v => direction( v, true ),
                all: true
              }
            }
          },
          {
            text: 'Area',
            value: 'place.area',
          },
          { text: 'Incidencia', value: 'description' },
          {
            text: 'Tipo', value: 'type', display: types,
            filter: {
              type: 'select',
              items: types.map(( text, value )=> ({ text, value }))
            }
          },
          {
            text: 'Categoría',
            value: 'issueCategory',
            display: v => category( v, true ),
            filter: {
              field: 'category',
              type: 'autocomplete',
              attrs: {
                service: 'IssueCategories',
                itemText: 'name',
                itemValue: 'id',
                params: { filters:[{ field: 'isMainCategory', booleanValue: true }]},
                display: v => v.name,
                all: true
              }
            }
          },
          {
            text: 'Subcategoría',
            value: 'issueCategory',
            display: v => subcategory( v, true ),
            filter: {
              field: 'subcategory',
              type: 'autocomplete',
              attrs: {
                service: 'IssueCategories',
                itemText: 'name',
                itemValue: 'id',
                params: { filters:[{ field: 'isChildCategory', booleanValue: true }]},
                display: v => v.name,
                all: true
              }
            }
          },
          {
            text: 'Prioridad', value: 'priority', display: this.priority,
            width: 90, filterable: true, sorteable: true,  filter: {
            type: 'select',
            items: [
              { value: 0, text: 'Baja' },
              { value: 1, text: 'Normal' },
              { value: 2, text: 'Alta' }
            ]
          }
          },
          {
            text: 'Estado', value: 'status', display: this.status,
            width: 150, filterable: false, sorteable: true
          }
        ],
        sections: [
          { text: 'Todas' },
          { text: 'Abiertas',   filters: [{ field: 'status', intValue: 0 }]},
          { text: 'En trámite',  filters: [{ field: 'status', intValue: 1 }]},
          { text: 'Ampliar información',   filters: [{ field: 'status', intValue: 2 }]},
          { text: 'Gestión para resolución',   filters: [{ field: 'status', intValue: 3 }]},
          { text: 'Cerradas',   filters: [{ field: 'status', intValue: 4 }]}
        ],
        open: true,
        downloading: {}
      }
    },
    computed: {
      isClient(){
        return this.$store.state.isClient;
      },
      actions() {
        if( this.$store.state.isClient ) {
          return "edit,export";
        }
        return "edit,delete,export";
      }
    },
    methods: {
      status( value ) {
        return status[value] || "Otros";
      },
      priority( value ) {
        return priority[value] || "";
      },
      date( value, h ) {
        return formatDate( value, h.name === 'date'
          ? { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }
          : { hour: '2-digit', minute: '2-digit', hour12: false }
        );
      },
      download( item ) {

        const { id } = item;
        if ( this.downloading[id] ) return;
        this.downloading[id] = true;

        this.$store.dispatch( 'api', { target: 'Issues/download', params: { id }})
          .then( url => window.open( url, "_blank" ))
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.downloading[id] = false );
      },
      update( params ) {
        params["comments"] = params["editorComments"];
        return this.$store.dispatch( 'api', {
          target: 'Issues/status',
          params
        });
      },
      sanitize( model ) {
        return {
          id: model.id || -1,
          status: model.status,
          description: model.description,
          comments: model.comments,
          clientVisible: model.clientVisible,
          editorComments: model.editorComments,
          type: model.type,
          category: model.category,
          date: Date.now(),
          pictures: model.pictures.map( m => m.id )
        };
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Incidencias' );
    }
  }
</script>

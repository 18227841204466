<template>
  <Table
    ref="table"
    service="Stock"
    :is-loading="dialog.loading"
    :sections="sections"
    :headers="headers"
    :importData="openDialog"
    :exportData="exportData"
    :show-actions="false"
    :edit="edit"
    @section="section = $event[0]"
  >

    <template v-slot:item.clientPlace.client="{ value, item }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="item.clientPlace && item.clientPlace.client"
        :to="`/clients/${ item.clientPlace.client.id }`"
        :title="value"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.campaign.client="{ value, item }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="item.campaign && item.campaign.client"
        :to="`/clients/${ item.campaign.client.id }`"
        :title="value"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.campaign="{ value, item }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="item.campaign"
        :to="`/campaigns/${ item.campaign.id }`"
        :title="value"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.promoter="{ value, item }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="item.promoter"
        :to="`/users/${ item.promoter.id }`"
        :title="value"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:append-outer>
      <v-dialog v-model="dialog.show" max-width="720" style="overflow:visible" no-click-animation persistent>
        <Card class="rel" :height="dialog.force ? 350 : 250">
          <v-row class="flex-column fill-height" no-gutters>
            <Toolbar color="secondary" dark>

              <h3>Selecciona el archivo y el cliente asociado</h3>

              <v-spacer/>

              <Btn color="white" width="20" height="20" fab light @click="closeDialog">
                <v-icon small>$close</v-icon>
              </Btn>

            </Toolbar>
            <div class="grow rel">
              <div class="layer autoscroll pa-4">

                <DField
                  :value="dialog.fileData"
                  :upload="onSelectFile"
                  field="file"
                  field-style="1"
                  class="mb-6"
                  label="Archivo"
                  accept=".csv, .xls, .xlsx"
                  hide-details
                  dense
                />

                <DField
                  v-model="dialog.client"
                  field="autocomplete"
                  field-style="1"
                  label="Cliente"
                  service="Clients"
                  item-text="name"
                  item-value="id"
                  hide-details
                  dense
                />

                <v-alert v-if="dialog.force" type="error" class="mt-4">
                  <p>
                    {{ dialog.error.message }}.<br/>
                    ¿Desea forzar la importación igualmente?
                  </p>
                  <p class="body-2 mb-1">Detalles:</p>
                  <p class="error-detail body-grupo-ot" :class="{ open: dialog.showPlaces }">
                    <v-icon v-text="'mdi-menu-down'" small/>
                    <a @click="dialog.showPlaces = !dialog.showPlaces">Centros ({{ dialog.error.places.length }}):</a>
                    <ul>
                      <li v-for="(place,i) in dialog.error.places" :key="i">{{ place }}</li>
                    </ul>
                  </p>
                  <p class="error-detail body-grupo-ot" :class="{ open: dialog.showProducts }">
                    <v-icon v-text="'mdi-menu-down'" small/>
                    <a @click="dialog.showProducts = !dialog.showProducts">Productos ({{ dialog.error.products.length }}):</a>
                    <ul>
                      <li v-for="(product,i) in dialog.error.products" :key="i">{{ product }}</li>
                    </ul>
                  </p>
                </v-alert>

              </div>
            </div>
            <Toolbar border-top>

              <v-spacer/>

              <Btn class="mr-4" color="white" @click="closeDialog">
                Cancelar
              </Btn>

              <Btn color="primary" @click="importData()" :disabled="disableImport">
                {{ dialog.force ? 'Forzar importación' : 'Importar' }}
              </Btn>

            </Toolbar>
          </v-row>
        </Card>
      </v-dialog>
    </template>

    <template v-slot:actions="{ selected, exportData }">

      <v-spacer/>

      <Btn class="ml-2" @click="openDialog" color="tertiary" dark>
        Importar
      </Btn>

      <Btn class="ml-2" @click="exportData( selected )" color="tertiary" dark>
        Exportar
      </Btn>

    </template>
  </Table>
</template>

<script>

  import Table from "@/components/core/Table.vue";
  import { userName } from '@/utils';

  export default {
    components: { Table },
    data() {
      return {
        section: 0,
        sections: [
          { text: 'Ventas diarias',      subservice: 'Stock/all' },
          { text: 'Ventas por promotor', subservice: 'Stock/promoter' },
        ],
        dialog: {
          show: false,
          force: false,
          loading: false,
          client: null,
          file: null,
          fileData: null,
          error: null,
          showPlaces: false,
          showProducts: false
        },
      };
    },
    computed: {
      headers() {
        switch ( this.section ) {
          case 0:
            return [
              { text: "Fecha", value: "date", width: 80, filter: { type: 'rangedate' }},
              {
                text: "Cliente", value: "clientPlace.client",
                display: a => a ? `${a.id}. ${a.title||a.name}` : '',
                filter: {
                  type: 'autocomplete',
                  attrs: {
                    service: 'Clients',
                    itemText: 'name',
                    itemValue: 'id',
                    display: a => a ? `${a.id}. ${a.title||a.name}` : '',
                  }
                }
              },
              { text: "Campaña",  value: "campaign", display: 'title' },
              {
                text: "Promotor", value: "promoter", display: v => userName( v, true ),
                filter: {
                  type: 'autocomplete',
                  attrs: { service: 'Users', itemText: 'name', itemValue: 'id' }
                }
              },
              {
                text: "Punto de venta",  value: "clientPlace.place", display: 'name',
                filter: {
                  type: 'autocomplete',
                  attrs: { service: 'Places', itemText: 'name', itemValue: 'id' }
                }
              },
              { text: "Código Cliente", value: "clientPlace", display: 'code' },
              { text: "Marca", value: "product.brand", display: 'name'  },
              {
                text: "Producto", value: "product", display: 'name',
                filter: {
                  type: 'text',
                  attrs: { service: 'Products', itemText: 'name', itemValue: 'id' }
                }
              },
              { text: "EAN", value: "product.ean", display: 'ean', filter: {
                  type: 'text'
                } },
              {
                text: "Venta real", value: "realSales", align: 'center',
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Venta informada", value: "sales", align: 'center', filterable: false, sorteable: false,
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "PVP", value: "pvp", align: 'center', filterable: false, sorteable: false,
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Stock real", value: "realStock", align: 'center',
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Stock máximo informado", value: "stock", align: 'center', filterable: false, sorteable: false,
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Semana", value: "week",
                align: 'center', width: 100,
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              }
            ];
          default:
            return [
              { text: "Fecha", value: "date", width: 80, filter: { type: 'rangedate' }},
              {
                text: "Cliente", value: "campaign.client", display: 'name',
                filter: {
                  type: 'autocomplete',
                  attrs: { service: 'Clients', itemText: 'name', itemValue: 'id' }
                }
              },
              { text: "Campaña",  value: "campaign", display: 'title' },
              {
                text: "Promotor", value: "promoter", display: v => userName( v, true ),
                filter: {
                  type: 'autocomplete',
                  attrs: { service: 'Users', itemText: 'name', itemValue: 'id' }
                }
              },
              {
                text: "Punto de venta",  value: "place", display: 'name',
                filter: {
                  type: 'autocomplete',
                  attrs: { service: 'Places', itemText: 'name', itemValue: 'id' }
                }
              },
              {
                text: "Producto", value: "product", display: 'name',
                filter: {
                  type: 'autocomplete',
                  attrs: { service: 'Products', itemText: 'name', itemValue: 'id' }
                }
              },
              {
                text: "Venta real", value: "realSales", align: 'center',filterable: false, sorteable: false,
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Venta informada", value: "sales", align: 'center',
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Stock real", value: "realStock", align: 'center', filterable: false, sorteable: false,
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Stock informado", value: "stock", align: 'center',
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              },
              {
                text: "Semana", value: "week",
                align: 'center', width: 100,
                filter: { type: 'number', attrs: { min: 0, hideControls: true }}
              }
            ];
        }
      },
      disableImport() {
        return !this.dialog.file || !this.dialog.client;
      }
    },
    methods: {
      edit() { return },
      openDialog() {
        this.dialog.show = true;
      },
      closeDialog() {
        this.dialog.show = this.dialog.loading =
        this.dialog.showPlaces = this.dialog.showProducts =
        this.dialog.force = false;
        this.dialog.file = this.dialog.fileData = this.dialog.client = null;
      },
      onSelectFile( file ) {
        this.dialog.file = file;
        this.dialog.fileData = file ? { url: file.name } : null;
      },
      importData() {

        if ( this.disableImport ) return;
        const data = new FormData();
        const { force } = this.dialog;

        data.append( 'file', this.dialog.file );
        data.append( 'client', this.dialog.client.id );
        data.append( 'force', force );

        const params = {
          service: 'Stock',
          data: data
        };

        this.dialog.loading = true;
        this.dialog.show = false;

        this.$store
          .dispatch( 'importData', params )
          .then(() => {
            this.closeDialog();
            this.$refs.table.reload();
            this.$store.dispatch( 'console/success', 'Archivo importado.' );
          })
          .catch( err => {
            if ( ! force && err.data && err.data.error === -15 ) {
              this.dialog.error = err.data.object;
              this.dialog.force = this.dialog.show = true;
              this.dialog.loading = false;
            } else {
              this.closeDialog();
              this.$store.dispatch( 'console/error', err );
            }
          });
      },
      exportData( params ) {
        if ( this.section === 1 ) params.method = 'promoterExport';
        return this.$store.dispatch( 'exportData', params );
      },
      yearWeek( value, header, item ) {
        return `${ item.year } - ${ item.week }`;
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Informe ventas' );
    }
  };
</script>

<style>
  .error-detail {
    margin-bottom: 0 !important;
  }
  .error-detail a {
    color: white !important;
  }
  .error-detail a:hover {
    text-decoration: underline;
  }
  .error-detail:not(.open) .v-icon {
    transform: rotate(-90deg);
  }
  .error-detail:not(.open) ul {
    display: none;
  }
</style>

import Vue from 'vue';
import './style.css';

const requireModule = require.context( '.', true, /\.vue$/);
const modules = {};

requireModule.keys().forEach( fileName => {
  if ( fileName === './index.js' ) return;

  // Replace ./ and .js
  const iconName = fileName.replace( /(\.\/|\.vue)/g, '' );

  if ( !modules[iconName] ) {
    modules[iconName] = {
      component: Vue.component( 'Icon' + iconName, requireModule( fileName ).default )
    }
  }
});

export default modules;

<template>
  <div class="messages" >
    <div v-for="( group, i ) in ( messages || [] )" :key="i">

      <div class="text-center mt-8 mb-2">
        <v-divider/>
        <v-chip color="border" class="white--text mt-n6">
          {{ group.display }}
        </v-chip>
      </div>

      <div v-for="( msg, e ) in group.messages" :key="e" class="pa-2">
        <v-card
          class="chat-bubble pa-2"
          elevation="1"
          :class="msg.idUser === $store.state.user.id ? 'right' : 'left'"
          :ripple="false"
        >

          <div class="mt-n1" v-if="msg.user">
            <span class="float-left" :style="{ color: msg.user.color }">
              {{ msg.user.name }} {{ msg.user.surname }}
            </span>
            <span class="caption float-right" style="color: #999;">
              {{ time( msg.date ) }}
            </span>
          </div>

          <div
            class="pa-1"
            style="clear: both;"
            v-html="sanitizeMessage( msg.message )"
          />

        </v-card>
      </div>
    </div>

    <transition-group tag="div" name="bubble" :key="pendingID">
      <div v-for="( msg, n ) in ( pending || [] )" :key="`pending-${n}`" class="pa-2">
        <v-card class="chat-bubble right pa-2" elevation="1" :ripple="false">

          <div class="mt-n1" v-if="msg.user">
            <span class="float-left" :style="{ color: msg.user.color }">
              {{ msg.user.name }} {{ msg.user.surname }}
            </span>
            <span class="caption float-right" style="color: #999;">

              <v-progress-circular
                v-if="msg.loading"
                color="primary"
                size="10"
                width="1"
                indeterminate
              />

              <Btn v-else-if="msg.error" @click="msg.retry" color="error" x-small text>
                Reinentar
              </Btn>

              <span v-else>
                {{ time( msg.date ) }}
              </span>

            </span>
          </div>

          <div
            class="pa-1"
            style="clear: both;"
            v-html="sanitizeMessage( msg.message )"
          />

        </v-card>
      </div>
    </transition-group>
  </div>
</template>

<script>

  import htmlEscape from 'html-escape';
  import moment from 'moment';

  moment.locale( navigator.language );
  const RegexURL = /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;

  export default {
    props: {
      messages: Array,
      pending: Array
    },
    data: () => ({
      pendingID: Date.now()
    }),
    watch: {
      messages() {
        setTimeout(() => this.$emit('change'), 10 );
      }
    },
    methods: {
      time( value ) {
        return moment( value ).format('HH:mm');
      },
      sanitizeMessage( msg ) {
        return htmlEscape( msg )
          .replace( RegexURL, '<a href="$1" target="_blank">$1</a>' ); // Find URLS
      }
    }
  }
</script>

<style>
  .chat-bubble {
    width: 90%;
    max-width: 640px;
    min-height: 50px;
    position: relative;
  }
  .chat-bubble.left {
    border-radius: 0 4px 4px !important;
    background-color: white;
  }
  .chat-bubble.right {
    border-radius: 4px 0 4px 4px !important;
    background-color: #f0f5ff;
    margin: 0 0 0 auto;
  }
  .chat-bubble::after, .chat-bubble::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }
  .chat-bubble.left::after, .chat-bubble.left::before {
    left: -12px;
    border-width: 0 12px 12px 0;
    border-color: transparent white transparent transparent;
  }
  .chat-bubble.left::before {
    top: 1px;
    border-color: transparent #aaa transparent transparent;
  }
  .chat-bubble.right::after, .chat-bubble.right::before {
    right: -12px;
    border-width: 0 0 12px 12px;
    border-color: transparent transparent transparent #f0f5ff;
  }
  .chat-bubble.right::before {
    top: 1px;
    border-color: transparent transparent transparent #a4a4a4;
  }

  /* Message transition */

  .bubble-enter-active {
    transition: all .25s ease;
  }
  .bubble-enter {
    transform: translateY( 40px );
    opacity: 0;
  }

</style>

import APIService from './class';

class Issues extends APIService {
  constructor() {
    super( '/issue' );
  }
  download( token, params, options ) {
    return this.action( 'post', '/download', params, options, token );
  }
  status( token, params, options ) {
    return this.action( 'post', '/status', params, options, token );
  }
  pictures( token, params, options ) {
    return this.action( 'post', '/pictures/page', params, options, token );
  }
  downloadPictures( token, params, options ) {
    return this.action( 'post', '/pictures/download', params, options, token );
  }
}

export default new Issues();

<template>
  <DForm :value="value" v-on="$listeners" @input="onInput">
    <DField key="name" v-bind="name" class="mb-6" />
    <DField key="parent.id" v-bind="parent" class="mb-6" />
    <DField key="type" v-bind="type" class="mb-6" />
    <DField
      key="working"
      v-bind="working"
      v-show="issueType == IssueContants.INCIDENCE"
      class="mb-6"
    />
    <DField
      key="default"
      v-bind="defaultField"
      class="mb-6"
    />
  </DForm>
</template>

<script>
import { Issue } from "@/utils/constants";

const types = [
  { value: Issue.INCIDENCE, text: "Incidencia" },
  { value: Issue.ALARM, text: "Alarma" },
];
const workingTypes = [
  { value: false, text: "No" },
  { value: true, text: "Sí" },
];

export default {
  props: {
    value: Object,
    readonly: Boolean,
  },
  data: () => ({
    IssueContants: Issue,
    parentId: -1,
    issueType: -1,
    name: {
      field: "text",
      fieldStyle: 1,
      label: "Nombre",
      dense: true,
      rules: [(v) => !!v || "El campo es requerido."],
    },
  }),
  watch: {
    value(newValue) {
      this.onInput(newValue);
    },
  },
  methods: {
    onInput(value) {
      if (value) {
        this.parentId = value.parent ? value.parent.id : -1;
        this.issueType = value.type;
      }
    },
  },
  computed: {
    parent() {
      return {
        field: "select",
        fieldStyle: 1,
        label: "Categoría padre (opcional)",
        service: "IssueCategories",
        itemValue: "id",
        itemText: "name",
        exclude: { id: this.value ? this.value.id : null },

        all: true,
        params: { filters: [{ field: "isMainCategory" }] },
        dense: true,
      };
    },
    type() {
      return {
        field: "select",
        fieldStyle: 1,
        label: "Tipo",
        items: types,
        dense: true,
      };
    },
    working() {
      return {
        field: "select",
        fieldStyle: 1,
        label: "Durante la jornada",
        items: workingTypes,
        dense: true,
      };
    },
    defaultField() {
      return {
        field: "select",
        fieldStyle: 1,
        label: "Seleccionada por defecto",
        items: workingTypes,
        dense: true,
      };
    },
  },
};
</script>

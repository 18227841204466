import Vue from 'vue';
import VueI18n from 'vue-i18n';
//import messages from '@/lang'

Vue.use( VueI18n );

const messages = {
  'en': {
    'noUserData':'No data available'
  },
  'es': {
    'noUserData':'No hay usuarios disponibles para su búsqueda'
  },
  'ca': {
    'noUserData':'No hi ha usuaris disponibles amb aquesta cerca'
  }
};

const i18n = new VueI18n({
  locale: 'ca', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages // set locale messages
});

export default i18n;

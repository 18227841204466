import APIService from './class';
import Users from './users';
import Campaigns from './campaigns';
import Clusters from './clusters';
import Upload from './upload';
import Issues from './issues';
import Tasks from './tasks';
import Surveys from './surveys';
import Chat from './chat';
import CampaignTasks from './campaign_tasks';
import UserEvaluations from './user_evaluations';
import Stats from './stats';
import Sync from './sync';
import Products from './products';
import Evaluations from './evaluations';
import Stock from './stock';
import ClientPlace from './clientplace';
import Workers from './workers';
import Holidays from './holidays';
import Actions from './actions';
import KpiCenter from './kpiCenter';
import Payroll from './payroll';

export default {
  Users,
  Upload,
  Campaigns,
  Clusters,
  Issues,
  Tasks,
  Surveys,
  Chat,
  Stats,
  Sync,
  CampaignTasks,
  UserEvaluations,
  Products,
  Evaluations,
  Stock,
  Workers,
  ClientPlace,
  Holidays,
  Actions,
  KpiCenter,
  Kpi: new APIService('/kpi'),
  CampaignLinks: new APIService('/cmplink'),
  Clients: new APIService('/client'),
  Files: new APIService('/file'),
  Brands: new APIService('/brand'),
  Sectors: new APIService('/sector'),
  Channels: new APIService('/channel'),
  IssueCategories: new APIService('/issue_category'),
  Places: new APIService('/place'),
  Payroll,
  UserLogs: new APIService('/userlog'),
  VersionLogs: new APIService('/versionlog'),
}

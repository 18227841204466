<template>
  <Table
    ref="table"
    service="Stats"
    min-width="1972px"
    :headers="headers"
    :sections="sections"
    :params="parameters"
    :initialize="initialize"
    :edit="edit"
    :show-select="selectable"
    :hide-table="hideTable"
    @filters="filters = $event"
    @section="changeSection"
    server-filter
  >
    <template v-slot:submenu-middle>

      <DChangeDate
        v-if="workersChangeDate" v-model="date"
      />

      <template v-if="isStats">

        <DField
          v-if="section === 'stats-bydate'"
          field="select"
          field-style="1"
          v-model="client"
          :items="clientsList"
          label="Detalle por cliente"
          style="min-width:200px"
          class="mr-2"
          hide-details
          dense
        />

        <DChangeDate
          v-if="section === 'stats-dayly'"
          v-model="statsDate"
        />

        <DChangeRangeDate
          v-if="section === 'stats-bydate'"
          v-model="statsRange"
        />

        <Btn
          class="text-none subtitle-2"
          color="secondary"
          @click="$refs.table.reload"
          text
        >
          <v-icon class="btn-reload--icon">mdi-reload</v-icon>
          <span class="btn-reload--text">Refrescar</span>
        </Btn>

        <Btn
          @click="statShowFilters = !statShowFilters"
          :color="statShowFilters ? 'primary' : 'secondary'"
          class="text-none mr-4 subtitle-2"
          text
        >
          <v-icon class="btn-filter--icon">$filter</v-icon>
          <span class="btn-filter--text">Filtros</span>
        </Btn>

      </template>
    </template>

    <!-- CAMPAIGN STATS -->

    <template v-slot:prepend-outer>
      <template v-if="link">
        <iframe
          :src="link"
          class="embed-responsive-item"
          @load="loadingIFrame = false"
          @error="loadingIFrame = false"
        />
        <v-row
          v-if="loadingIFrame"
          class="layer white"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular color="border" indeterminate />
        </v-row>
      </template>

      <Stats
        v-else-if="section === 'stats-dayly'"
        v-model="statSearch"
        :show-filters="statShowFilters"
        :section="section"
        @filters="statFilters = $event"
        @click-item="onClickStat"
        @click-bar="onClickBar"
      />

      <Stats2
        v-else-if="section === 'stats-bydate'"
        v-model="stat2Search"
        :show-filters="statShowFilters"
        :section="section"
        :client="client"
        @clients:list="clientsList = $event"
        @filters="stat2Filters = $event"
        @click-item="onClickStat"
        @click-bar="onClickBar"
      />

    </template>

    <!-- DIALOGS -->

    <template v-slot:append-outer>
      <v-dialog v-model="push.open" max-width="640">
        <Card>
          <Toolbar color="secondary" dark>
            <h3>Notificación</h3>
            <v-spacer />
            <Btn
              color="white"
              width="20"
              height="20"
              fab
              light
              @click="push.open = false"
            >
              <v-icon small>$close</v-icon>
            </Btn>
          </Toolbar>
          <div class="pa-4">
            <DField
              v-model="push.message"
              field="textarea"
              field-style="1"
              placeholder="Escribe el mensaje a enviar..."
              rows="10"
              autofocus
              hide-details
              no-resize
            />
          </div>
          <Toolbar border-top>
            <v-spacer />

            <Btn color="primary" @click="sendPush"> Enviar </Btn>
          </Toolbar>
        </Card>
      </v-dialog>

      <v-dialog v-model="dialog.open" max-width="560">
        <Card class="d-flex flex-column flex-nowrap">
          <Toolbar class="shrink" color="secondary" dark>

            Editar día {{ dialog.date }} para {{ dialog.place.fullName }}
            <v-spacer />
              <Btn
                color="white"
                width="20"
                height="20"
                fab
                light
                @click="dialog.open = false"
              >
                <v-icon small>$close</v-icon>
              </Btn>
          </Toolbar>
          <Tabs
            class="shrink"
            v-model="dialog.mode"
            :items="dialog.tabs"
          />
          <v-row class="grow pa-4" no-gutters>
            <template v-if="!dialog.mode">
              <v-col class="pr-2">

                <v-checkbox
                  v-if="!dialog.place.allday"
                  v-model="dialog.middleJourney"
                  :ripple="false"
                  :disabled="dialog.holiday"
                  label="Jornada partida"
                  class="body-grupo-ot mt-0 mb-4"
                  color="primary"
                  hide-details
                />

                <DField
                  v-if="!dialog.place.allday"
                  v-model="dialog.start"
                  :disabled="dialog.holiday"
                  :max="dialog.end"
                  class="mb-4"
                  field="time"
                  field-style="1"
                  label="Hora de entrada"
                  dense
                />

                <DField
                  v-if="!dialog.place.allday"
                  v-model="dialog.end"
                  :disabled="dialog.holiday"
                  :min="dialog.start"
                  field="time"
                  field-style="1"
                  label="Hora de salida"
                  dense
                />

              </v-col>
              <v-col v-if="dialog.middleJourney" class="pl-2 pt-11">

                <DField
                  v-model="dialog.start2"
                  :disabled="dialog.holiday"
                  :max="dialog.end2"
                  class="mb-4"
                  field="time"
                  field-style="1"
                  label="Hora de entrada"
                  dense
                />

                <DField
                  v-model="dialog.end2"
                  :disabled="dialog.holiday"
                  :min="dialog.start2"
                  field="time"
                  field-style="1"
                  label="Hora de salida"
                  dense
                />

              </v-col>
            </template>
            <template v-else>
              <v-col class="pr-2">

                <DField
                  v-model="dialog.absenceType"
                  :items="dialog.absences"
                  field="select"
                  field-style="1"
                  class="mb-4"
                  label="Tipo"
                  dense
                />

                <DField
                  v-if="dialog.subabsences[dialog.absenceType]"
                  v-model="dialog.absenceSubtype"
                  :items="dialog.subabsences[dialog.absenceType]"
                  field="select"
                  field-style="1"
                  label="Razón"
                  class="mb-4"
                  dense
                />

                <DField
                  v-model="dialog.comments"
                  field="textarea"
                  field-style="1"
                  label="Comentarios"
                  rows="4"
                />

              </v-col>
            </template>
          </v-row>
          <Toolbar class="shrink" border-top>

            <v-spacer/>

            <Btn color="primary" @click="applyEdition">
              Aplicar
            </Btn>

          </Toolbar>
        </Card>
      </v-dialog>

      <v-dialog v-model="worktime.open" max-width="640">
        <Card>
          <Toolbar color="secondary" dark>
            <h3>Jornada del trabajador</h3>
            <v-spacer />
            <Btn
              color="white"
              width="20"
              height="20"
              fab
              light
              @click="worktime.open = false"
            >
              <v-icon small>$close</v-icon>
            </Btn>
          </Toolbar>

          <div v-if="worktime.item" class="pa-4">
            <span class="pa-1">
              Usuario: <strong>{{ worktime.item.user.alias }}</strong>
            </span>
            <span class="pa-1">
              Fecha:
              <strong>{{ getDate(worktime.item.date, "DD/MM/YYYY") }}</strong>
            </span>
          </div>

          <v-container
            class="pa-1"
            v-for="(log, index) in worktime.workLogs"
            :key="index"
            no-gutters
          >
            <v-row style="background: white" no-gutters>
              <v-col class="rel" cols="5">
                <DField
                  v-model="log.startOffset"
                  field="time"
                  field-style="1"
                  label="Inicio"
                  labelSpace="90"
                  class="pa-1"
                  :dense="true"
                />
              </v-col>

              <v-col class="rel" cols="5">
                <DField
                  v-model="log.endOffset"
                  field="time"
                  field-style="1"
                  label="Fin"
                  labelSpace="90"
                  class="pa-1"
                  :dense="true"
                />
              </v-col>

              <v-col cols="2" md="2" class="rel">
                <Btn
                  color="primary"
                  class="pa-2 ma-1"
                  @click="worktime.workLogs.splice(log, 1)"
                >
                  <v-icon>$delete</v-icon>
                </Btn>
              </v-col>
            </v-row>
          </v-container>
          <Toolbar border-top class="pb-1">
            <v-spacer />

            <Btn
              class="ma-4"
              color="secondary"
              @click="worktime.workLogs.push({ startOffset: '', endOffset: '' })"
            >
              Añadir Rango
            </Btn>

            <Btn color="primary" @click="saveWorkedTime">
              Guardar
            </Btn>

          </Toolbar>
        </Card>
      </v-dialog>
    </template>

    <!-- TABLE CELLS -->

    <template v-slot:item.campaign="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="item.campaign"
        :title="value"
        :to="`/campaigns/${item.campaign.id}`"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.campaignName="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link"
        :title="value"
        :to="`/campaigns/${item.idCampaign}`"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.lastLocation="{ item, value }">
      <Btn @click="goToLocation(item)" :color="getPositionColor(value)" icon v-if="item.user!=null">
        <v-icon style="width: 30px; height: 30px">$place</v-icon>
      </Btn>
    </template>

    <template v-slot:item.location="{ item }">
      <Btn @click="goToLocation(item)" color="primary" icon v-if="item.user!=null">
        <v-icon style="width: 30px; height: 30px">$place</v-icon>
      </Btn>
    </template>

    <template v-slot:item.okStatus="{ value }">
      <v-icon :color="okColor(value)" v-text="icon(value)" small />
    </template>

    <template v-slot:item.user="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link" v-if="item.user!=null"
        :title="value"
        :to="`/users/${item.user.id}`"
      >
        {{ value }}
      </router-link>
      <span v-else>-</span>
    </template>

    <template v-slot:item.timetable="{ item, value }">
      <a class="body-grupo-ot underline" @click="showEditTimetable(item)" small text>

        <span v-html="value.join('</br>')" v-if="value.length>0" />
        <span v-html="getWorkerTimetable(item)" v-else />
      </a>
    </template>

    <template v-slot:item.workingTime="{ item, value }">
      <Btn class="body-grupo-ot" color="primary" @click="showWorkingTime(item)" small text v-if="item.user!=null">
        <span v-html="value" />
      </Btn>
    </template>,

    <template v-slot:item.holiday="{ item }">
       <div>{{item.holiday ? "Sí": "No"}}</div>
     </template>

     <template v-slot:item.validated="{ item }">
       <div>{{item.validated ? "Sí": "No"}}</div>
     </template>

    <template v-slot:item.name="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link"
        :title="value"
        :to="`/campaigns/${item.id}`"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.client="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link"
        :title="value"
        :to="`/clients/${item.idClient}`"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.idChat="{ item, value }">
      <Btn
        color="secondary"
        @click="goTo('/chat', { selected: { id: value } })"
        small
        icon
        v-if="item.user!=null"
      >
        <v-icon>$chat</v-icon>
      </Btn>
    </template>

    <template v-slot:item.issues="{ item, value }">
      <Btn class="body-grupo-ot"  color="secondary" @click="goTo('/issues')" small text v-if="item.user!=null">
        <v-icon left>$issue</v-icon>
        {{ value }}
      </Btn>
      <span v-else></span>
    </template>

    <template v-slot:item.totalTaks="{ value, item }">
      <Btn class="body-grupo-ot" color="secondary" @click="edit(item)" small text>
        <v-icon left>$task</v-icon>
        {{ value }}
      </Btn>
    </template>

    <template v-slot:item.pendingTasks="{ value, item }">
      <Btn class="body-grupo-ot"  color="secondary" @click="edit(item)" small text>
        <v-icon left>$task</v-icon>
        {{ value }}
      </Btn>
    </template>

    <template v-slot:item.pendingValidationTasks="{ value, item }">
      <Btn class="body-grupo-ot"  color="secondary" @click="edit(item)" small text>
        <v-icon left>$task</v-icon>
        {{ value }}
      </Btn>
    </template>

    <template v-slot:item.status="{ value }">
      <component
        :is="value.tag"
        :style="{ color: value.color }"
        :title="value.text"
        class="body-grupo-ot"
      >
        {{ value.text }}
      </component>
    </template>

    <template v-slot:item.lastTimeLog="{ item, value }">
      <component
        :is="item.lastTimeLog && item.lastTimeLog.type == 1 ? 'strong' : 'span'"
        :style="item.lastTimeLog && item.lastTimeLog.type == 1 ? 'color: green' : null"
        :title="value"
      >
        {{ value }}
      </component>
    </template>

    <!-- ACTIONS -->

    <template v-slot:actions="{ selected, filters }">
      <template v-if="['today-promotors', 'today-gpv', 'future-promotors'].indexOf(section) !== -1">

        <v-spacer />

        <Btn
          v-if="selected.length || geolocation.loading"
          @click="requestGeo(selected)"
          :loading="geolocation.loading"
          class="mr-2"
          color="tertiary"
          dark
        >
          Solicitar geolocalización
        </Btn>

        <Btn
          v-if="selected.length || push.loading"
          @click="openPush(selected)"
          :loading="push.loading"
          class="mr-2"
          color="primary"
        >
          Enviar notificación
        </Btn>

        <Btn
          color="tertiary"
          :loading="exporting"
          dark
          v-if="['today-promotors', 'today-gpv'].indexOf(section) !== -1"
          @click="onExportWorkers(selected, filters)"
        >
          Exportar
        </Btn>

      </template>
      <template v-else-if="section === 'past-validation'">

        <v-spacer />

        <Btn
          v-if="selected.length || validating"
          @click="validate(selected)"
          :loading="validating"
          color="primary"
        >
          Validar
        </Btn>

      </template>
      <template v-else-if="section === 'past-history'">

        <v-spacer />

        <Btn color="tertiary" :loading="exporting" dark>
          Exportar
        </Btn>

      </template>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import Stats from "./stats";
import Stats2 from "./stats2";
import { mapState, mapMutations } from "vuex";
import { Device } from "@/utils/constants";
import { userName, Params } from "@/utils";
import moment from "moment";
import { AbsenceType, AbsenceSubtype } from "@/utils/constants";

function date(time) {
  if (time) return moment(time).format("DD/MM/YYYY");
  return "Sin horario definido";
}

function time(time) {
  if (time) return moment(time).format("HH:mm:ss");
  return "-";
}

function working( value, header, item ) {
  var hshTags = {
    "0": { tag: "strong", color: "#4f4f4f", text: "Pausado" },
    "1": { tag: "strong", color: "#008f00", text: "Iniciado" },
    "2": { tag: "strong", color: "#2f2f2f", text: "Finalizado" },
    "-1": { tag: "strong", color: "#cc4200", text: "No iniciado" },
    "-2": { tag: "strong", color: "#cc4200", text: "Llega tarde" },
    "-3": { tag: "strong", color: "#b0b0b0", text: "No asignado" },
    "-4": { tag: "strong", color: "#ff8c00", text: "Ausencia" },
  }

  var tag = hshTags[""+item.status];
  if(tag!=null){
    return tag;
  }
  else{
    return { tag: "strong", color: "#bebebe", text: "Pausado" };
  }
  /*if ( item.user == null ) {
      return { tag: "strong", color: "#bebebe", text: "No asignado" };
  }

  var notWorkingData = item.timetable.filter((a) => {
    return a.priority == -1;
  });

  var notWorking = notWorkingData.length > 0;
  if ( notWorking ) {
    return { tag: "strong", color: "#bebebe", text: "Finalizado" };
  }

  if (item.date && item.date < moment().startOf("date").valueOf()) {
    if (!item.lastTimeLog)
      return { tag: "strong", color: "red", text: "No iniciado" };
    return { tag: "strong", color: "#bebebe", text: "Finalizado" };
  }

  if (item.lastTimeLog && item.lastTimeLog.type == 1)
    return { tag: "strong", color: "green", text: "Iniciado" };

  //Aquí llegamos si tenemos un estado 0 por lo que miraremos si ha finalizado su jornada para poner Finalizada o Pausada...

  var endOffset = 0;
  var startOffset = -1;

  item.timetable.forEach((t) => {
    if (t.endOffset > endOffset) endOffset = t.endOffset;
    if (t.startOffset < startOffset || startOffset == -1)
      startOffset = t.startOffset;
  });

  var now = moment();
  if (!item.lastTimeLog) {
    if (startOffset > -1) {
      var hours = Math.floor(startOffset / 60);
      var minutes = startOffset - hours * 60;
      var startDate = moment(
        now.format("MM/DD/YYYY") +
          " " +
          hours +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes)
      );
      if (startDate.valueOf() < now.valueOf())
        return { tag: "strong", color: "red", text: "No iniciado" };
    }
    return { tag: "span", text: "No iniciado" };
  }

  if (endOffset > 0) {
    var endHours = Math.floor(endOffset / 60);
    var endMinutes = endOffset - endHours * 60;
    var endDate = moment(
      now.format("MM/DD/YYYY") +
        " " +
        endHours +
        ":" +
        (endMinutes < 10 ? "0" + endMinutes : endMinutes)
    );
    if (endDate.valueOf() <= now.valueOf())
      return { tag: "strong", color: "#bebebe", text: "Finalizado" };
  }

  return { tag: "strong", text: "Pausado" };*/
}

function device(value) {
  if (value) {
    var device = "";
    if (value.version) {
      device += "v" + value.version + " ";
    }

    if (value.brand) {
      device += value.brand + " ";
    }

    if (value.model) {
      device += value.model + " ";
    }

    if (value.type) {
      switch (value.type) {
        case Device.ANDROID:
          device += "Android";
          break;
        case Device.IOS:
          device += "IOS";
          break;
      }
    }

    return device;
  }
  return "-";
}

function datetime(time, format) {
  return moment(time).format(format != null? format : "DD/MM/YYYY HH:mm:ss");
}

function minutes(value) {
  if (value) {
    if (value > 59) {
      var hours = Math.floor(value / 60);
      var minutes = value - hours * 60;
      var formattedminutes = ("0" + minutes).slice(-2);
      return hours + ":" + formattedminutes;
    }
    return Number(value) + " min";
  }
  return "-";
}

function format(value) {
  value = String(value).slice(0, 2);
  return "00".slice(value.length) + value;
}

function offsetToTime(offset) {
  if (offset != null) {
    const H = Math.floor(offset / 60);
    const M = offset - H * 60;
    return format(H) + ":" + format(M);
  }
  return "";
}

function timeToOffset(time) {
  time = String(time).split(":");
  const H = parseInt(time[0]) || 0;
  const M = parseInt(time[1]) || 0;
  return H * 60 + M;
}

function timing(value) {



  value = value || [];
  var notWorkingData = value.filter((a) => {
    return a.priority == -1;
  });

  var notWorking = notWorkingData.length > 0;
  if (notWorking) {
    var t = notWorkingData[0];
    var array = [];

    var abs = absences.filter((v) => v.value == t.absenceType);
    if(abs.length>0){
    array.push("<strong>"+abs[0].text+"</strong>");
    if (
      t.absenceType == AbsenceType.PLACE ||
      t.absenceType == AbsenceType.INCOMPLETE
    ) {
      var subabs = subAbsences.filter((v) => v.value == t.absenceSubtype);
      if (subabs.length > 0) {
        array.push(subabs[0].text);
      }
    }
    }
    return array;
    //    return ["No trabaja"];
  }

  value.sort((a, b) => a.startOffset - b.startOffset);

  return value.map((data) =>
    [
      moment("00:00", "HH:mm").add(data.startOffset, "minutes").format("HH:mm"),
      moment("00:00", "HH:mm").add(data.endOffset, "minutes").format("HH:mm"),
    ].join(" - ")
  );
}

function statusDate(type) {
  return function (value, header, item) {
    return item[`${type}Status`] ? date(value) : "...";
  };
}

// COMMON headers

const absences = [
  //{ text: "Festivo laboral", value: AbsenceType.HOLIDAY_WORKING },
  { text: "Festivo No laboral", value: AbsenceType.HOLIDAY_NO_WORKING },
  { text: "Vacaciones", value: AbsenceType.USER_HOLIDAYS },
  { text: "Libranza", value: AbsenceType.LIBRANZA },
  { text: "Centro descubierto", value: AbsenceType.PLACE },
  { text: "Jornada incompleta", value: AbsenceType.INCOMPLETE },
  { text: "Observaciones", value: AbsenceType.OTHER },
];

const subAbsences = [
  { text: "Pendiente de selección", value: AbsenceSubtype.NO_CUBIERTA_PDT_SELECT },
  { text: "Pendiente de cliente", value: AbsenceSubtype.NO_CUBIERTA_PDT_CLIENTE },
  { text: "Pendiente de PDV", value: AbsenceSubtype.NO_CUBIERTA_PDT_PDV },
  { text: "Baja voluntaria", value: AbsenceSubtype.BAJA_VOLUNTARIA },
  { text: "Despido / NSPP", value: AbsenceSubtype.DESPIDO },
  { text: "Imprevisto personal", value: AbsenceSubtype.IMPREVISTO_PERSONAL },
  { text: "Enfermedad", value: AbsenceSubtype.ENFERMEDAD },
  { text: "Problemas documentación", value: AbsenceSubtype.DOCUMENTATION },
  { text: "Retraso / Avance jornada", value: AbsenceSubtype.RETRASO },
];

const STATUS_ITEMS = [
  { value: -2, text: "Llega tarde" },
  { value: -1, text: "No iniciado" },
  { value: 0,  text: "Pausado" },
  { value: 1,  text: "Iniciado" },
  { value: 2,  text: "Finalizado" },
  { value: -3, text: "No asignado" },
  { value: -4, text: "Ausencia" },
];

const HEADER = {
  user: {
    text: "Promotor",
    value: "user",
    display: (v) => userName(v),
    filter: { field: "user" },
  },
  campaign: {
    text: "Campaña",
    value: "campaign",
    display: (a) => (a ? `${a.id}. ${a.title || a.name}` : ""),
    filter: { field: "campaign" },
  },
  client: {
    text: "Cliente",
    value: "campaign.client",
    display: (a) => (a ? `${a.id}. ${a.name}` : ""),
    filter: { field: "client" },
  },
  center: {
    text: "Centro",
    value: "place",
    display: "name",
    filter: {
      type: "autocomplete",
      attrs: {
        service: "Places",
        itemText: "name",
        itemValue: "id",
        //display: (v) => direction(v, true)
      },
    },
  },
  province: {
    text: "Provincia",
    value: "place.province",
    filterable: true,
    filter: { field: "province" },
  },
  device: {
    text: "Dispositivo",
    value: "device",
    display: (v) => device(v),
    filterable: false,
    sorteable: false,
  },
  timetable: {
    text: "Horario",
    value: "timetable",
    width: 100,
    display: timing,
    filterable: true,
    filter: { type: "timetable" },
  },
  futureTimetable: {
    text: "Horario",
    value: "timetable",
    display: timing,
    filterable: true,
    sorteable: false,
    filter: { type: "timetable" },
  },
  start: {
    text: "Inicio",
    value: "startDate",
    width: 100,
    display: time,
    //filter: { type: "date" },
    filterable: false,
  },
  time: {
    text: "Tiempo",
    value: "workingTime",
    width: 100,
    display: minutes,
    filterable: false,
  },
  estimatedTime: {
    text: "Tiempo Estimado",
    value: "estimatedWorkingTime",
    width: 100,
    display: minutes,
    filterable: false,
  },
  validatedTime: {
    text: "Tiempo Validado",
    value: "validatedWorkingTime",
    width: 100,
    display: minutes,
    filterable: false,
  },
  holiday: {
    text: "Festivo",
    value: "holiday",
    width: 80,
    display: Boolean,
    filterable: true,
    filter: {
      type: 'select',
      field: 'holiday',
      items: [
        { value: true, text: 'Sí' },
        { value: false, text: 'No' }
      ]
    }
  },
  validated: {
    text: "Validado",
    value: "validated",
    width: 80,
    display: Boolean,
    filterable: true,
    filter: {
      type: 'select',
      field: 'validated',
      items: [
       { value: true, text: 'Sí' },
        { value: false, text: 'No' }
      ]
    }
  },
  status: {
    text: "Estado",
    value: "status",
    width: 100,
    display: working,
    filter: {
      type: "select",
      items: STATUS_ITEMS
    },
  },
  telf: {
    text: "Telf",
    value: "user.phone",
    width: 120,
    filter: { field: "phone" },
  },
  email: {
    text: "Email",
    value: "user.email",
    filter: { field: "email" },
  },
  issues: {
    text: "Incidencias",
    value: "issues",
    width: 75,
    align: "center",
    filterable: false,
    filter: {
      type: "number",
      attrs: { min: 0, hideControls: true },
    },
  },
  geo: {
    text: "Geo",
    value: "lastLocation",
    width: 80,
    align: "center",
    sorteable: false,
    // filterable: false,
    filter: {
      type: "select",
      items: [
        { value: -1, text: "Incorrecta" },
        { value: 0, text: "Caducada" },
        { value: 1, text: "Correcta" },
      ],
    },
  },
  chat: {
    text: "Chat",
    value: "idChat",
    width: 75,
    align: "center",
    sorteable: false,
    filterable: false,
  },
  tasks: {
    text: "Tareas",
    value: "totalTaks",
    width: 75,
    align: "center",
    filterable: false,
    filter: {
      type: "number",
      attrs: { min: 0, hideControls: true },
    },
  },
};

const toRemove = [];

export default {
  components: { Table, Stats, Stats2 },
  data() {

    const today = moment();
    const statsRange = [ today.clone().day(1).valueOf(), today.valueOf() ];

    return {
      today: today.format("DD/MM/YYYY"),
      date: today.valueOf(),
      section: "today-promotors",
      currentSection: [0,0],
      link: null,
      filters: null,
      validating: false,
      exporting: false,
      loadingIFrame: false,
      client: 0,
      clientsList: [],
      statSearch: {},
      stat2Search: { date: statsRange },
      statFilters: [],
      stat2Filters: [
        { field: 'date', rangeValue:{ start: statsRange[0], end: statsRange[1] }}
      ],
      statShowFilters: false,
      statsDate: null,
      statsRange: [ today.clone().day(1).valueOf(), today.valueOf() ],
      geolocation: {
        open: false,
        loading: false,
        location: null,
      },
      push: {
        open: false,
        loading: false,
        message: "",
        requests: null,
      },
      dialog: {
        open: false,
        mode: 0,
        date: '',
        place: {},
        event: null,
        tabs: [
          { text: 'Horario'  },
          { text: 'Ausencia' }
        ],
        absenceType: null,
        absenceSubtype: null,
        comments: '',
        absences,
        subabsences: {
          [AbsenceType.PLACE]: subAbsences.slice(0,-1),
          [AbsenceType.INCOMPLETE]: subAbsences.slice(3),
        }
      },
      worktime: {
        open: false,
        item: null,
        workLogs: [],
      }
    };
  },
  watch: {
    date(value) {
      this.$store.state.workersDate = value;
    },
    statsDate(value) {
      const date = moment(value).format("DD/MM/YYYY") === this.today ? null : value;
      this.statSearch = { ...this.statSearch, date };
    },
    statsRange( value ) {
      this.stat2Search = {
        ...this.stat2Search,
        date: value
      };
    },
    link(value) {
      if (value) this.loadingIFrame = true;
    }
  },
  computed: {
    ...mapState([ 'view' ]),
    sections() {

      const { isRRHH, isClient } = this.$store.state;
      const client = this.$store.state.user
        ? this.$store.state.user.client
        : null;

      const sections = isClient ? [] : [
        {
          text: "Trabajadores",
          children: [
            {
              text: "Promotores",
              target: "Stats/today",
              section: "today-promotors",
              filters: [{ field: "type", listValue: [1] }],
            },
            {
              text: "GPV/Reponedor",
              target: "Stats/today",
              section: "today-gpv",
              filters: [{ field: "type", listValue: [0, 2] }],
            },
          ],
        },
        {
          text: "Trabajadores futuros",
          children: [
            { text: "Trabajadores", target: "Stats/future", section: "future-promotors" },
            {
              text: "Centros asociados",
              target: "Stats/centers",
              section: "future-centers",
            },
          ],
        },
      ];

      if ( client ) {
        sections.unshift({
          text: "Estadísticas Campañas",
          section: "99",
          children: client.stats.map((c, i) => ({
            text: c.campaignName,
            section: "99-" + i,
            link: c.url,
          })),
        });
      }

      if ( !isRRHH && !isClient ) {

        sections.push({
          text: "Trabajadores pasados",
          children: [
            { text: "Reporte pendiente",
            target: "Stats/past",
            section: "past-reports",
              filters: [{ field: "status", intValue: 0}]
            },
            {
              text: "Validación pendiente",
              target: "Stats/past",
              section: "past-validation",
              filters: [{ field: "status", intValue: 1 }],
            }
            /*,
            {
              text: "Consulta histórico",
              target: "Stats/history",
              section: "past-history",
            },*/
          ],
        });

        sections.unshift({
          text: "Estadísticas",
          children: [
            {
              text: 'Por día',
              target: "Stats/get",
              section: "stats-dayly",
            },
            {
              text: 'Por fechas',
              target: "Stats/getDate",
              section: "stats-bydate",
            }
          ]
        });
      }

      return sections;
    },
    headers() {
      switch ( this.section ) {
        case "today-promotors":
          return [
            HEADER.user,
            HEADER.campaign,
            //HEADER.client,
            HEADER.center,
            HEADER.province,
            //HEADER.device,
            //HEADER.email,
            HEADER.telf,
            HEADER.timetable,
            HEADER.start,
            HEADER.time,
            HEADER.estimatedTime,
            HEADER.validatedTime,
            HEADER.holiday,
            HEADER.validated,
            HEADER.status,
            HEADER.geo,
            HEADER.issues,
            //HEADER.tasks,
            HEADER.chat,
          ];
        case "today-gpv":
          return [
            HEADER.user,
            HEADER.campaign,
            HEADER.telf,
            //HEADER.client,
            {
              text: "Primera visita",
              value: "startDate",
              display: time,
              filterable: false,
              filter: { type: "date" },
            },
            {
              text: "Visitas",
              value: "visits",
              filterable: false,
              filter: { type: "number", attrs: { min: 0, hideControls: true } },
            },
            //HEADER.email,
            HEADER.geo,
            HEADER.issues,
            //HEADER.tasks,
            HEADER.chat,
          ];
        case "future-promotors":
          return [
            HEADER.user,
            HEADER.campaign,
            HEADER.telf,
            //HEADER.client,
            //HEADER.email,
            {
              text: "Próxima jornada",
              value: "startDate",
              display: date,
              filter: { type: "date", field: "startDate" },
            },
            HEADER.futureTimetable,
            HEADER.chat,
          ];
        case "future-centers":
          return [
            HEADER.user,
            HEADER.campaign,
            //HEADER.client,
            HEADER.center,
            HEADER.province,
            HEADER.telf,
            //HEADER.email,
            {
              text: "Fecha de inicio",
              value: "startDate",
              width: 110,
              display: date,
              filter: { type: "date" },
            },
            {
              text: "Documentación",
              value: "docDate",
              width: 130,
              display: statusDate("doc"),
              filter: {
                type: "select",
                field: "documentation",
                items: [
                  { value: 0, text: "No" },
                  { value: 1, text: "Sí" },
                ],
              },
            },
            {
              text: "Firma",
              value: "signDate",
              width: 100,
              display: statusDate("sign"),
              filter: {
                type: "select",
                field: "sign",
                items: [
                  { value: 0, text: "No" },
                  { value: 1, text: "Sí" },
                ],
              },
            },
            {
              text: "Acceso",
              value: "accessDate",
              width: 100,
              display: statusDate("access"),
              filter: {
                type: "select",
                field: "access",
                items: [
                  { value: 0, text: "No" },
                  { value: 1, text: "Sí" },
                ],
              },
            },
            {
              text: "Formación",
              value: "formDate",
              width: 100,
              display: statusDate("form"),
              filter: {
                type: "select",
                field: "formation",
                items: [
                  { value: 0, text: "No" },
                  { value: 1, text: "Sí" },
                ],
              },
            },

            {
              text: "Operaciones",
              value: "operationDate",
              width: 100,
              display: statusDate("operation"),
              filter: {
                type: "select",
                field: "operation",
                items: [
                  { value: 0, text: "No" },
                  { value: 1, text: "Sí" },
                ],
              },
            },
            { text: "OK", value: "okStatus", width: 40, filterable: false },
          ];
        case "past-reports":
          return [
            HEADER.user,
            HEADER.campaign,
            //HEADER.client,
            HEADER.center,
            HEADER.province,
            HEADER.telf,
            {
              text: "Tareas",
              value: "pendingTasks",
              width: 75,
              align: "center",
              filterable: false,
              filter: { type: "number", attrs: { min: 0, hideControls: true } },
            },
            {
              text: "Días",
              value: "daysPendingReport",
              width: 75,
              sorteable: false,
              align: "center",
              filterable: false,
              filter: { type: "number", attrs: { min: 0, hideControls: true } },
            },
            HEADER.chat,
          ];
        case "past-validation":
          return [
            HEADER.user,
            HEADER.campaign,
            //HEADER.client,
            HEADER.center,
            HEADER.province,
            HEADER.telf,
            {
              text: "Tareas",
              value: "pendingValidationTasks",
              width: 75,
              align: "center",
              filterable: false,
              filter: { type: "number", attrs: { min: 0, hideControls: true } },
            },
            {
              text: "Días",
              value: "daysPendingValidation",
              width: 75,
              sorteable: false,
              align: "center",
              filterable: false,
              filter: { type: "number", attrs: { min: 0, hideControls: true } },
            },
            HEADER.issues,
            HEADER.chat,
          ];
        case "past-history":
          return [
            {
              text: "Fecha",
              value: "day",
              width: 100,
              filter: {
                type: "rangedate",
                compute: (value, field) => {
                  if (!value[0] || !value[1]) return { [field]: null };
                  return { [field]: { start: value[0], end: value[1] } };
                },
              },
            },
            HEADER.user,
            HEADER.campaign,
            //HEADER.client,
            HEADER.center,
            HEADER.province,
            HEADER.telf,
            HEADER.issues,
            //HEADER.tasks,
            {
              text: "Geo",
              value: "location",
              width: 60,
              align: "center",
              filterable: false,
              sorteable: false,
            },
            HEADER.chat,
          ];
      }
      return [];
    },
    selectable() {
      return ["today-promotors", "today-gpv", "future-promotors", "past-validation"].indexOf(this.section) !== -1;
    },
    isStats() {
      return ["stats-dayly", "stats-bydate"].indexOf(this.section) !== -1;
    },
    workersChangeDate() {
      return ["today-promotors", "today-gpv"].indexOf(this.section) !== -1;
    },
    hideTable() {
      return (
        this.isStats || ( this.section && this.section.startsWith("99"))
      );
    },
    parameters() {
      if ( this.workersChangeDate ) {
        var now = moment().startOf("date").valueOf();
        if ( this.date && this.date < now ) {
          return { filters: [{ field: "date", doubleValue: this.date }] };
        }
      } else if ( this.section === 'stats-dayly' ) {
        return { filters: this.statFilters };
      } else if ( this.section === 'stats-bydate' ) {
        return { filters: this.stat2Filters };
      }
      return null;
    },
  },
  created() {
    this.date = this.$store.state.workersDate;
  },
  mounted() {
    this.$store.commit( 'setTitle', 'Estadísticas' );
    if ( this.$store.state.view && this.$store.state.view.section) {
      this.changeSection( this.$store.state.view.section );
    }
    else{
      this.changeSection(this.currentSection);
    }
    //Miramos si es cliente...
    if (this.$store.state.isClient) {
      if (!this.$store.state.user.statsEnabled) {
        //No tenemos permiso para estar en esta página, miramos de ir a otra...
        if (this.$store.state.user.tasksEnabled) {
          this.$router.push("/day-tasks");
        } else if (this.$store.state.user.picturesEnabled) {
          this.$router.push("/pictures");
        } else if (this.$store.state.user.geolocationEnabled) {
          this.$router.push("/location");
        } else if (this.$store.state.user.issuesEnabled) {
          this.$router.push("/issues");
        } else if (this.$store.state.user.workersEnabled) {
          this.$router.push("/workers");
        } else {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        }
      }
    }
  },
  methods: {
    ...mapMutations([ 'setView' ]),
    set( state ) {
      this.setView({ path: this.$route.path, state: {
        section: this.currentSection,
        sections: {
          [this.currentSection.join('')]: state
        }
      }});
    },
    initialize( params ) {
      return new Promise(( resolve, reject ) => {

        var { target, page, filters, link, order, ascending } = params;
        this.link = link;

        if ( ! target ) return resolve();
        params = { page, filters, order, ascending };

        this.$store
          .dispatch( 'api', { target, params })
          .then( response => {
            if ( this.isStats ) resolve( response );
            else resolve( this.compute( response ));
          })
          .catch( reject );
      });
    },
    changeSection(a) {
      this.currentSection = a;
      const { sections } = this;
      if (sections[a[0]]) {
        if (sections[a[0]].children && sections[a[0]].children[a[1]]) {
          this.section = sections[a[0]].children[a[1]].section;
        } else {
          this.section = sections[a[0]].section;
        }
      }
    },
    onExportWorkers(items, filters) {
      const target = "Stats/todayExport";
      const params = Params({ filters }, this.parameters);
      this.$store
        .dispatch("api", { target, params })
        .then((result) => window.open(result, "_blank"))
        .catch((err) => this.$store.dispatch("console/error", err));
    },
    compute( response ) {
      var data = response.data;
      switch (this.section) {
        case "past-reports":
          data = data.filter((item) => item.daysPendingReport);
          break;
        case "past-validation":
          data = data.filter((item) => item.daysPendingValidation);
          break;
        case "future-promotors":
          data.forEach((item) => {
            if ("docStatus" in item) {
              ["doc", "sign", "access", "form", "operation"].forEach((type) => {
                item[`${type}Date`] = item[`${type}Status`]
                  ? date(item[`${type}Date`])
                  : "...";
              });
              item.ok =
                item.docStatus +
                item.signStatus +
                item.accessStatus +
                item.formStatus +
                item.operationStatus;
            }
          });
          break;
      }

      response.data = data;
      return response;
    },
    color(value) {
      switch (value) {
        case 0:
          return "error";
        case 4:
          return "success";
        default:
          return "warning";
      }
    },
    okColor(value) {
      switch (value) {
        case -1:
          return "error";
        case 1:
          return "success";
        default:
          return "warning";
      }
    },
    icon(value) {
      switch (value) {
        case 4:
          return "mdi-checkbox-marked-circle";
        default:
          return "mdi-checkbox-blank-circle";
      }
    },
    stats(items) {
      const today = moment().format("DD/MM/YYYY");
      return items.map((a) => {
        a.today = a.day === today;
        a.day = moment(a.day, "DD/MM/YYYY").format("ddd DD");
        return a;
      });
    },
    showEditTimetable( item ) {

        var timetable1 = item.timetable[0];

        var date = item.date;

        const middleJourney = item.timetable.length > 1;
        var timetable2 = middleJourney? item.timetable[1]:null;
        const { priority, absenceType, absenceSubtype, weekday, comments } = timetable1;
        const isAbsence = !!absenceType;
        const edited = priority != 0;
        // console.log( item );

        this.dialog = {
          ...this.dialog,
          open: true,
          date: moment( date ).format('DD/MM/YYYY'),
          weekday: weekday,
          value: moment( date ).valueOf(),
          place: item.place,
          item: item,
          edited,
          mode: isAbsence ? 1 : 0,
          middleJourney: !isAbsence ? middleJourney : false,
          absenceType: absenceType,
          absenceSubtype: absenceSubtype,
          comments: comments || '',
          start: timetable1 != null ? offsetToTime(timetable1.startOffset) : null,
          end: timetable1 != null ? offsetToTime(timetable1.endOffset) : null,
          start2: middleJourney && timetable2 != null? offsetToTime(timetable2.startOffset) : null,
          end2: middleJourney && timetable2 != null? offsetToTime(timetable2.endOffset) : null,
          events: item.timetable
        };
    },
    showWorkingTime( item ) {

      this.worktime.item = item;
      this.worktime.workLogs = [];

      item.workLogs.forEach((wl) => {
        const start = offsetToTime(wl.startOffset);
        const end = offsetToTime(wl.endOffset);
        this.worktime.workLogs.push({ startOffset: start, endOffset: end });
      });

      if ( item.workLogs.length == 0 ) {
        this.worktime.workLogs.push({ startOffset: null, endOffset: null });
      }

      this.worktime.open = true;
    },
    sanitize( worker ) {
      const timetable = [];
      return {
        id: worker.idCampaignPlace,
        date: worker.date,
        lastPriorityTimetables: worker.timetable.filter(n => n.priority!=0).map(n => n.id),
        timetable
      };
    },
    applyEdition() {

      const { middleJourney, mode } = this.dialog;
      const target = 'Users/updatePriorityTime';
      const params = this.sanitize( this.dialog.item);
      const times = [
        [ this.dialog.start, this.dialog.end ],
        [ this.dialog.start2, this.dialog.end2 ]
      ].map( t => {
        if ( t[0] ) t[0] = timeToOffset( t[0] );
        if ( t[1] ) t[1] = timeToOffset( t[1] );
        return t;
      });

      // Prepare events

      var events = [], event;
      times.forEach(( time, i ) => {
        if (
          this.dialog.events[i]
          && ( event = params.timetable.find( a => a.id === this.dialog.events[i].data.timeId ))
          && event.priority !== 0
        ) {
          if (( mode && !i ) || ( time[0] != null && time[1] != null && ( !i || middleJourney ))) {
            Object.assign( event, {
              priority: mode ? -1 : 1,
              startDate: this.dialog.value,
              endDate: this.dialog.value,
              startOffset: time[0] || 0,
              endOffset: time[1] || ( 24 * 60 - 1 ),
              absenceType: mode ? this.dialog.absenceType : null,
              absenceSubtype: mode ? this.dialog.absenceSubtype : null,
              comments: mode ? this.dialog.comments : null
            });
          } else {
            // Remove event
            toRemove.push( event.id );
            params.timetable.splice( params.timetable.indexOf( event ), 1 );
          }
        } else if (( mode && !i ) || ( time[0] != null && time[1] != null && ( !i || middleJourney ))) {
          events.push({
            id: -1,
            priority: mode ? -1 : 1,
            position: i,
            idCampaign: parseInt( this.$route.params.id ),
            dayOfWeek: this.dialog.weekday + 1,
            frequencyType: 0,
            startDate: this.dialog.value,
            endDate: this.dialog.value,
            startOffset: time[0] || 0,
            endOffset: time[1] || ( 24 * 60 - 1 ),
            absenceType: mode ? this.dialog.absenceType : null,
            absenceSubtype: mode ? this.dialog.absenceSubtype : null,
            comments: mode ? this.dialog.comments : null,
            status: 1
          });
        }
      });

      // Add new events
      params.timetable = params.timetable.concat( events );

      // Save
      this.loading = true;
      this.dialog.open = false;

      this.$store.dispatch( 'api', { target, params })
        .then(() => this.refresh())
        .then(() => {
          this.model = null;
          this.$store.dispatch( 'console/success', 'Horario actualizado.');
        })
        .catch( err => this.$store.dispatch( 'console/error', err ))
        .finally(() => this.loading = false );
    },
    refresh(){
      this.$refs.table.reload();
    },
    saveWorkedTime() {

      const item = this.worktime.item;
      const campaign = item.campaign;
      const place = item.place;
      const user = item.user;

      var params = {
        idHistory: -1,
        idUser: user.id,
        idPlace: place.id,
        idCampaign: campaign.id,
        date: item.date,
        times: [],
      };

      this.worktime.workLogs.forEach((t) => {
        var startOffset = timeToOffset(t.startOffset);
        var endOffset = timeToOffset(t.endOffset);
        console.log("Start Offset: " + startOffset);
        console.log("End Offset: " + endOffset);
        params.times.push({ startOffset, endOffset });
      });

      const target = "Users/updateTimeLog";

      // Save
      this.loading = true;

      this.$store
        .dispatch("api", { target, params })
        .then(() => this.refresh())
        .then(() => {
          //this.model = null;
          this.$store.dispatch("console/success", "Tiempo de trabajo actualizado.");
        })
        .catch((err) => this.$store.dispatch("console/error", err))
        .finally(() => (this.loading = false));

      this.worktime.open = false;
    },
    edit( item ) {
      var url, status;
      switch (this.section) {
        case "today-promotors":
        case "today-gpv":
          url = `/campaigns/${item.idCampaign}/day-tasks`;
          status = { filters: { date: item.date } };
          if (item.user) status.filters.user = item.user;
          break;
        case "future-promotors":
          url = `/campaigns/${item.campaign.id}/calendar`;
          if (item.user) status = { selected: [item.user] };
          break;
        case "future-centers":
          url = `/campaigns/${item.idCampaign}/centers`;
          status = { filters: {} };
          if (item.user) status.filters.user = item.user;
          if (item.place) status.filters.place = item.place.name;
          break;
        case "past-reports":
          url = `/campaigns/${item.idCampaign}/day-tasks`;
          status = { filters: { status: 0 } };
          if (item.user) status.filters.user = item.user;
          break;
        case "past-validation":
          url = `/campaigns/${item.idCampaign}/day-tasks`;
          status = { filters: { status: 1 } };
          if (item.user) status.filters.user = item.user;
          break;
        case "past-history":
          url = `/campaigns/${item.idCampaign}/day-tasks`;
          status = {
            //filters: { date: moment(item.day, "DD/MM/YYYY").valueOf() },
            filters: { date: item.date },
          };
          if (item.user) status.filters.user = item.user;
          if (item.place) status.filters.place = item.place;
          break;
      }

      if (url) {
        if (status) {
          if (status.filters) status.showFilters = true;
          status.page = 1;
        }
        this.goTo(url, status);
      }
    },
    requestGeo(items) {
      if (this.geolocation.loading) return;
      this.geolocation.loading = true;

      const target = "Stats/requestGeo";
      const campaigns = [];
      const requests = [];

      items.forEach((a, index) => {
        index = campaigns.indexOf(a.idCampaign);
        if (index !== -1) requests[index].user.push(a.user.id);
        else {
          campaigns.push(a.idCampaign);
          requests.push({ campaign: a.idCampaign, users: [a.user.id] });
        }
      });

      Promise.all(
        requests.map((params) =>
          this.$store.dispatch("api", { target, params })
        )
      )
        .then(() => {
          this.$store.dispatch(
            "console/success",
            items.length === 1
              ? "Localización solicitada."
              : "Localizaciónes solicitadas."
          );
        })
        .catch((err) => this.$store.dispatch("console/error", err))
        .finally(() => (this.geolocation.loading = false));
    },
    openPush(items) {
      if (this.push.loading) return;

      const campaigns = [];
      this.push.open = true;
      this.push.requests = [];

      items.forEach((a, index) => {
        index = campaigns.indexOf(a.idCampaign);
        if (index !== -1) this.push.requests[index].user.push(a.user.id);
        else {
          campaigns.push(a.idCampaign);
          this.push.requests.push({
            campaign: a.idCampaign,
            users: [a.user.id],
          });
        }
      });
    },
    sendPush() {
      this.push.loading = true;
      this.push.open = false;
      this.push.requests.forEach((req) => (req.message = this.push.message));
      const target = "Stats/sendPush";

      Promise.all(
        this.push.requests.map((params) =>
          this.$store.dispatch("api", { target, params })
        )
      )
        .then(() =>
          this.$store.dispatch(
            "console/success",
            'El mensaje "push" se envió correctamente.'
          )
        )
        .catch((err) => this.$store.dispatch("console/error", err))
        .finally(() => {
          this.push.loading = false;
          this.push.message = "";
        });
    },
    validate(items) {

      if (this.validating) return;
      this.validating = true;

      const target = "Stats/validate";
      const validations = items.map((a) => ({
        idUser: a.user.id,
        idPlace: a.place.id,
        idCampaign: a.idCampaign,
      }));

      this.$store
        .dispatch("api", { target, params: { validations } })
        .then(() => {

          if ( this.section === "past-validation" ) {
            const data = this.core.data.filter((a) => items.indexOf(a) < 0);
            this.set({ data });
          }

          this.$store.dispatch(
            "console/success",
            items.length === 1
              ? "Trabajador validado satisfactoriamente."
              : `${items.length} trabajadores validados satisfactoriamente.`
          );
        })
        .catch((err) => this.$store.dispatch("console/error", err))
        .finally(() => (this.validating = false));
    },
    getDate(date, format) {
      if (date) return datetime(date, format);
      return "-";
    },
    isOldPosition(item) {
      if (item) {
        let now = Date.now();
        let diffSeconds = (now - item.date) / 1000;
        let diffMinutes = diffSeconds / 60;
        if (diffMinutes < 60) return false;
        return true;
      }
      return true;
    },
    getPositionColor(item) {
      if (item) {
        if (this.isOldPosition(item)) return "#bebebe";
        else if (item.inRadius) return "green";
        else return "red";
      }
      return "#bebebe";
    },
    onClickStat( stats ) {

      var path, status, date;
      const s = this.statSearch || {};

      switch (stats) {
        case "workers":

          this.date = date.valueOf();

          status = {
            section: [1, 0],
            showFilters: !!(s.client || s.campaign),
            filters: {
              client: s.client ? s.client.name : null,
              campaign: s.campaign ? s.campaign.name : null
            },
          };

          break;
        case "campaign":
          status = {
            section: [2, 1],
            showFilters: !!(s.client || s.campaign),
            filters: {
              client: s.client ? s.client.name : null,
              campaign: s.campaign ? s.campaign.name : null,
            },
          };

          break;
        case "tasks":
          path = "/day-tasks";
          status = {
            showFilters: !!s.campaign,
            filters: {
              campaign: s.campaign ? s.campaign.name : null,
            },
          };

          break;
        case "issues":
        case "past-issues":
          date = s.date ? moment(s.date) : moment();
          if (stats === "past-issues") date.add(-1, "day");
          date = date.valueOf();

          path = "/issues";
          status = {
            showFilters: true,
            filters: {
              date: { start: date, end: date },
              campaign: s.campaign ? s.campaign.name : null,
            },
          };

          break;
        default:
          return;
      }

      this.goTo(path, status);
    },
    onClickBar({ type, index }) {

      var path, status, _status;
      const s = this.statSearch || {};
      var date = s.date ? moment(s.date) : moment();

      switch (type) {
        case "workers":

          var isToday = ! this.statsDate || moment( this.statsDate ).format("DD/MM/YYYY") === this.today;

          _status = [
            STATUS_ITEMS[3].value,
            STATUS_ITEMS[2].value,
            STATUS_ITEMS[0].value,
            STATUS_ITEMS[4].value,
            STATUS_ITEMS[1].value,
            STATUS_ITEMS[6].value
          ];

          this.date = date.valueOf();

          status = {
            section: [1,0],
            showFilters: true,
            filters: {
              status: _status[ index + ( isToday ? 0 : 3 )],
              campaign: s.campaign ? s.campaign.name : null,
            }
          };

          break;

        case "tasks":

          _status = [
            1, // Realizadas
            2, // Validadas
            0, // Pendientes
          ];

          if ( _status[index] == null )
            return;

          path = "/day-tasks";
          status = {
            showFilters: true,
            filters: {
              campaign: s.campaign ? s.campaign.name : null,
              client: s.client ? s.client.name : null,
              status: _status[index],
              date: { start: date.valueOf(), end: date.valueOf() },
            }
          };

          break;

        case "current-issues":
        case "last-issues":

          if ( type === "last-issues" )
            date.add(-1, "day");

          path = "/issues";
          status = {
            section: [ index + 1, 0 ],
            showFilters: true,
            filters: {
              campaign: s.campaign ? s.campaign.name : null,
              date: { start: date.valueOf(), end: date.valueOf() },
            }
          };

          break;
        default:
          return;
      }

      this.goTo( path, status );
    },
    goTo( path, state ) {

      state = state || {};
      const section = state.section || [0,0];
      delete state.section;

      if ( state.filters ) {
        state.filterStore = state.filters;
        delete state.filters;
      }

      this.setView({ path: path || this.$route.path, state: {
        section,
        sections: {
          [section.join('')]: state
        }
      }});

      path && this.$router.push( path );
    },
    goToLocation( item ) {

      var a = document.createElement("a");

      a.target = "_blanck";
      a.href = `/campaigns/${item.idCampaign}/location?date=${item.date}`;
      if ( item.user ) a.href += `&user=${item.user.id}`;

      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    getWorkerTimetable(item){
      if(item.timetableString){
        return item.timetableString.split(',').join('</br>');
      }
      return "";
    }
  },
};
</script>

<style>
.legend-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.embed-responsive-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}
</style>

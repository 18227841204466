<template>
  <v-dialog v-model="show" fullscreen>
    <div class="fill-height pa-4">
      <Card class="fill-height d-flex flex-column" border>
        <Toolbar color="secondary">

          <span class="white--text title">
            Productos
          </span>

          <v-spacer/>

          <Btn @click="showFilters = !showFilters" :color="showFilters ? 'primary' : 'white'" class="text-none mr-4" text>
            <v-icon class="mr-2">$filter</v-icon>
            Filtros
          </Btn>

          <Btn color="white" width="20" height="20" @click="show = false" fab>
            <v-icon small>$close</v-icon>
          </Btn>

          <v-progress-linear
            v-if="loading"
            color="primary"
            indeterminate
            absolute
            bottom
          />

        </Toolbar>
        <v-divider/>
        <div class="grow rel">
          <div class="layer autoscroll">

            <DTable
              v-model="selected"
              :headers="headers"
              :items="filteredItems"
              :field-filter="onfilter"
              :show-filters="showFilters"
              :order-items="order"
              :sorting="sorting"
              :filters="search"
              :border="false"
              item-key="id"
              show-select
              filter-on-blur
              disable-sorting
              @orderBy="order = $event"
              @filters="onfilter"
              @click-item="selected = [ $event ]"
            >
              <template v-slot:item.picture="{ value }">
                <Card class="mask" width="90" height="90" border>
                  <v-img v-if="value" :src="value.url" max-width="90" height="90"/>
                </Card>
              </template>
            </DTable>

          </div>
        </div>
        <DPagination
          v-if="numPages > 1"
          v-model="page"
          :length="numPages"
          :visibles="pagesVisibles"
          border-top
        />
        <Toolbar border-top>

          <v-spacer/>

          <Btn color="tertiary" @click="add" dark>
            Añadir
          </Btn>

        </Toolbar>
      </Card>
    </div>
  </v-dialog>
</template>

<script>

  import { query, compare, toArray } from '@/utils';

  export default {
    props: {
      value: null,
      exclude: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        show: !!this.value,
        model: [],
        loading: false,
        showFilters: false,
        items: [],
        selected: [],
        order: [],
        search: {},
        old_search: {},
        filters: [],
        page: 1,
        numPages: 0,
        headers: [
          {
            text: 'Imagen',  value: 'picture',  width: 100,
            align: 'center', filterable: false, sorteable: false
          },
          { text: 'Nombre', value: 'name' },
          {
            text: 'Marca',  value: 'brand', display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: { service: 'Brands', itemText: 'name', itemValue: 'id' }
            }
          },
          { text: 'Categoría',    value: 'category' },
          { text: 'Subcategoría', value: 'subcategory' },
          { text: 'Descripción',  value: 'description' },
          {
            text: 'PVP', value: 'pvp',
            filter: { type: 'number', attrs: { min: 0, hideControls: true }}
          },
          { text: 'EAN', value: 'ean', width: 200 },
        ]
      }
    },
    computed: {
      filteredItems() {
        const { items/*, exclude */} = this;
        //return items.filter( place => ! exclude.find( a => a.id === place.id ));
        return items;
      },
      pagesVisibles() {
        switch ( true ) {
          case this.$vuetify.breakpoint.xl:
            return 30;
          case this.$vuetify.breakpoint.lg:
            return 18;
          case this.$vuetify.breakpoint.md:
            return 15;
          default:
            return 6;
        }
      }
    },
    watch: {
      value( value ) {
        this.show = !!value;
      },
      show( value ) {
        if ( ! value ) this.items = [];
        else {
          this.model = [];
          this.selected = [];
          this.fetch();
        }
        this.$emit( 'input', value );
      },
      page() {
        this.show && this.fetch();
      },
      order() {
        this.show && this.fetch();
      },
      filters() {
        this.show && this.fetch();
      },
      showFilters( value ) {
        if ( ! value ) this.onfilter({});
      }
    },
    methods: {
      add() {
        this.show = false;
        this.model = this.model.concat( this.selected.filter( place => ! this.exclude.find( a => a.id === place.id )));
        this.$emit( 'apply', this.model );
      },
      fetch() {

        const { page, order, exclude } = this;
        const filters = this.filters.slice();
        const target = 'Products/all';
        const params = { page, filters };

        if ( order[0] ) Object.assign( params, {
          order: order[0].header.value,
          ascending: order[0].order === 1 ? true : false
        });

        if ( exclude.length )
          params.filters.push({ field: "excluded", listValue: exclude.map( a => a.id ) });

        this.loading = true;
        this.$store
          .dispatch( 'api', { target, params })
          .then( response => {

            this.page = response.page;
            this.numPages = response.numPages;
            this.items = response.data;

            // El filtro "excluded" no funciona, mientras tanto filtramos aqui.
            // Cuando se arregle borramos esto:
            if ( exclude.length ) {
              exclude.forEach(( item, index ) => {
                if (( index = this.items.findIndex( a => a.id === item.id )) >= 0 ) {
                  this.items.splice( index, 1 );
                }
              });
            }
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.loading = false );
      },
      sorting( column, order ) {
        return toArray( order[ order.length - 1 ] );
      },
      onfilter( filters ) {
        if ( ! compare( filters, this.old_search )) {
          this.filters = Object.keys( filters )
            .map( key => query({ [key]: filters[key] }).filters[0] )
            .filter( a => a );
        }
        this.page = 1;
        this.old_search = { ...filters };
        this.search = filters;
      },
    },
    mounted() {
      if ( this.show ) this.fetch();
    }
  }
</script>

import APIService from './class';

class CampaignTasks extends APIService {
  constructor() {
    super( '/ctask' );
  }
  validate( token, params, options ) {
    return this.action( 'post', '/validate', params, options, token );
  }
  create( token, params, options ) {
    return this.action( 'post', '/create', params, options, token );
  }
  recreate( token, params, options ) {
    return this.action( 'post', '/recreate', params, options, token );
  }
}

export default new CampaignTasks();

<template>
  <Table
    service="Channels"
    :messages="messages"
    :mypopup="mypopup"
    :headers="headers"
    :sanitize="sanitize"
    show-actions
  >
    <template v-slot:form="{ value, listeners }">

      <Channel class="pa-4" :value="value" v-on="listeners"/>

    </template>
  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import Channel from '@/components/forms/Channels/single.vue';

  export default {
    components: { Table, Channel },
    data() {
      return {
        messages: { item: 'Cadena/Cadenas' },
        headers: [
          { text: 'Nombre', value: 'name' },
          {
            text: 'Cadena padre', value: 'parent', display: 'name',
            filter: {
              type: 'select',
              attrs: {
                service: 'Channels',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          }
        ],
        mypopup: {
          height: 260,
          title: n => n ? 'Nueva cadena' : 'Editar cadena',
          initial: name => ({ name })
        }
      }
    },
    methods: {
      sanitize( model ) {
        return {
          id: model.id || -1,
          name: model.name,
          idParent: model.parent
        }
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Canales' );
    }
  }
</script>

<template>
  <Card class="d-flex flex-column" border>
    <Toolbar color="secondary">

      <span class="white--text title">
        {{ title }}
      </span>

      <v-spacer/>

      <Btn @click="showFilters = !showFilters" :color="showFilters ? 'primary' : 'white'" class="text-none mr-4" text>
        <v-icon class="mr-2">$filter</v-icon>
        Filtros
      </Btn>

      <Btn color="white" width="20" height="20" @click="$emit('close')" fab>
        <v-icon small>$close</v-icon>
      </Btn>

      <v-progress-linear
        v-if="loading"
        color="primary"
        indeterminate
        absolute
        bottom
      />

    </Toolbar>
    <v-divider/>
    <div class="grow rel">
      <div class="layer autoscroll">

        <DTable
          v-model="selected"
          :headers="headers"
          :items="filteredItems"
          :field-filter="onfilter"
          :show-filters="showFilters"
          :order-items="order"
          :sorting="sorting"
          :filters="search"
          :border="false"
          item-key="id"
          show-select
          filter-on-blur
          disable-sorting
          @orderBy="order = $event"
          @filters="onfilter"
          @click-item="selected = [ $event ]"
        />

      </div>
    </div>
    <DPagination
      v-if="numPages > 1"
      v-model="page"
      :length="numPages"
      :visibles="pagesVisibles"
      border-top
    />
    <Toolbar border-top>

      <v-spacer/>

      <Btn color="tertiary" @click="apply" dark>
        Añadir
      </Btn>

    </Toolbar>
  </Card>
</template>

<script>

  import { query, compare, toArray, Params } from '@/utils';

  export default {
    props: {
      value: {
        type: Array,
        default: () => []
      },
      headers: {
        type: Array,
        default: () => []
      },
      service: {
        type: String,
        default: 'Campaings'
      },
      method: {
        type: String,
        default: 'all'
      },
      exclude: {
        type: Array,
        default: () => []
      },
      itemKey: {
        type: String,
        default: 'id'
      },
      title: {
        type: String,
        default: 'all'
      },
      disableFetch: {
        type: Boolean,
        default: false
      },
      params: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        loading: false,
        showFilters: false,
        items: [],
        selected: ( this.value || [] ).slice(),
        order: [],
        search: {},
        old_search: {},
        filters: [],
        page: 1,
        numPages: 0
      }
    },
    computed: {
      filteredItems() {

        var items = this.items.slice();
        const { exclude, itemKey } = this;
        var toExclude = [];

        for ( var i = 0, exc; i < exclude.length; i++ ) {
          exc = exclude[i];
          if ( typeof exc === 'function' ) {
            items = items.filter( exc );
          } else {
            toExclude.push( exc );
          }
        }

        return items.filter( a => ! toExclude.find( b => a[itemKey] === b[itemKey] ));
      },
      pagesVisibles() {
        switch ( true ) {
          case this.$vuetify.breakpoint.xl:
            return 30;
          case this.$vuetify.breakpoint.lg:
            return 18;
          case this.$vuetify.breakpoint.md:
            return 15;
          default:
            return 6;
        }
      }
    },
    watch: {
      value( selected, old ) {
        if ( ! compare( selected, old )) {
          this.selected = selected.slice();
        }
      },
      page: 'fetch',
      order: 'fetch',
      filters: 'fetch',
      params: 'fetch',
      showFilters( value ) {
        if ( ! value ) this.onfilter({});
      }
    },
    methods: {
      apply() {
        this.$emit( 'input', this.selected.slice());
      },
      fetch() {

        if ( this.disableFetch ) return;

        const { service, method, page, filters, order } = this;
        const target = `${service}/${method}`;
        const params = Params( this.params || {}, { page, filters });

        if ( order[0] ) Object.assign( params, {
          order: order[0].header.value,
          ascending: order[0].order === 1 ? true : false
        });

        this.loading = true;
        this.$store
          .dispatch( 'api', { target, params })
          .then( response => {
            this.page = response.page;
            this.numPages = response.numPages;
            this.items = response.data;
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.loading = false );
      },
      sorting( column, order ) {
        return toArray( order[ order.length - 1 ] );
      },
      onfilter( filters ) {
        if ( ! compare( this.old_search, filters )) {
          this.filters = Object.keys( filters )
            .map( key => query({ [key]: filters[key] }).filters[0] )
            .filter( a => a );
        }
        this.page = 1;
        this.old_search = { ...filters };
        this.search = filters;
      },
      clean() {
        this.selected = [];
      }
    },
    mounted() {
      this.fetch();
    }
  }
</script>

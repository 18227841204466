<template>
  <div class="steps">

    <div :class="{ title }">

      {{ title }}

      <Btn v-if="canAdd" color="tertiary" @click="onadd()" icon>
        <v-icon>$plus</v-icon>
      </Btn>

      <slot name="actions" v-bind="scope"/>

    </div>

    <slot name="prepend"/>

    <component :is="readonly?'div':'Draggable'" v-model="model" handle=".handle">
      <div class="pa-2" v-for="( item, i ) in items" :key="i">
        <v-row class="flex-nowrap py-1" align="center" no-gutters>

          <v-chip class="handle mr-4" :color="index === i ? 'tertiary' : 'border'" dark>
            {{ i + 1 }}
          </v-chip>

          <slot name="header" v-bind="{ ...scope, item, index: i, active: index === i }"/>

          <Btn v-if="editButton" :color="index === i ? 'tertiary' : 'border'" @click="toggle(i)" icon large>
            <v-icon>{{ editIcon( index === i ) }}</v-icon>
          </Btn>

          <Btn v-if="!readonly && items.length > minItems" color="border" @click="onremove( item, i )" icon large>
            <v-icon>$delete</v-icon>
          </Btn>

        </v-row>
        <v-expand-transition>
          <div v-if="expandible && index === i" class="options" :style="padding">

            <slot v-bind="{ ...scope, item, index: i, active: index === i }"/>

          </div>
        </v-expand-transition>
      </div>
    </component>
  </div>
</template>

<script>

  import Draggable from 'vuedraggable';

  export default {
    components: { Draggable },
    props: {
      value: Array,
      title: String,
      min: [ Number, String ],
      max: [ Number, String ],
      add: Function,
      remove: Function,
      filter: Function,
      headline: Boolean,
      readonly: Boolean,
      editButton: [ Boolean, String ],
      expandible: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        index: -1,
        model: this.value || []
      }
    },
    watch: {
      value( value ) {
        this.model = value || [];
        this.initialize();
      },
      model( value ) {
        this.$emit( 'input', value );
      }
    },
    computed: {
      scope() {
        return {
          parent: this,
          value: this.model,
          open: this.open,
          toggle: this.toggle,
          add: this.onadd,
          remove: this.onremove,
          input: this.input,
          canAdd: this.canAdd,
          min: this.minItems,
          max: this.maxItems
        }
      },
      minItems() {
        return parseInt( this.min ) || 0;
      },
      maxItems() {
        return parseInt( this.max ) || Infinity;
      },
      padding() {
        return {
          paddingRight: this.editButton ? null : '44px'
        }
      },
      items() {
        if ( this.filter ) return this.filter( this.model );
        return this.model;
      },
      canAdd() {
        return !this.readonly && this.model.length < this.maxItems;
      }
    },
    methods: {
      open( index ) {
        this.index = index;
      },
      toggle( index ) {
        if ( this.index >= 0 ) this.index = -1;
        else this.index = index;
      },
      onadd( item ) {
        if ( this.items.length < this.maxItems ) {
          if ( this.add ) this.model = this.add( item, this.model );
          else this.model.push( item || {} );
        }
      },
      onremove( item, index ) {
        if ( this.remove ) this.model = this.remove( item, index, this.model );
        else this.model.splice( index, 1 );
      },
      input( item, key, value ) {
        const index = typeof item === 'number' ? item : this.model.indexOf( item );
        item = this.model[index];
        if ( key == null ) {
          this.model.splice( index, 1, value );
        } else if ( item ) {
          item[key] = value;
          this.model.splice( index, 1, item );
        }
      },
      initialize() {
        if ( this.minItems > 0 && ! this.items.length ) {
          for ( var i = 0; i < this.minItems; i++ ) this.onadd();
        }
      },
      editIcon( active ) {
        if ( this.readonly ) return active ? 'mdi-menu-up' : 'mdi-menu-down';
        else if ( typeof this.editButton === 'string' ) return this.editButton;
        return '$edit';
      }
    },
    mounted() {
      this.initialize();
    }
  }
</script>

<style>
  .steps .options {
    margin-left: 16px;
    padding: 8px 88px 8px 32px;
    border-left: 1px solid var(--v-border-base);
  }
  .steps .handle {
    cursor: grab;
  }
  .steps .handle:active {
    cursor: grabbing;
  }
</style>

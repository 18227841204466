<template>
  <Table
    ref="table"
    service="Users"
    :sanitize="sanitize"
    :sections="sections"
    :initialize="initialize"
    :messages="messages"
    :headers="headers"
    show-actions="edit,delete,new,export,import"
  >
    <template v-slot:item.devices="{ value }">
      <v-menu v-if="value" offset-y>
        <template v-slot:activator="{ on }">
          <Btn
            class="justify-end"
            color="tertiary"
            width="120"
            small
            text
            v-on="on"
          >
            {{ value.length }} disp.
            <v-icon v-if="value.length">$dropdown</v-icon>
          </Btn>
        </template>

        <Card border v-if="value.length">
          <v-list class="py-0" dense style="min-width: 200px">
            <template v-for="device in value">
              <v-list-item class="pr-0" :key="device.id">
                <v-list-item-title
                  v-if="device.needToUpdate"
                  class="body-2"
                  style="color: #ff1111"
                >
                  {{ deviceData(device) }}
                </v-list-item-title>
                <v-list-item-title
                  v-else
                  class="body-2"
                  style="color: #777"
                >
                  {{ deviceData(device) }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </Card>
      </v-menu>
    </template>

    <template v-slot:item.evaluations="{ item }">

      <div v-if="!item.avgEvaluations"></div>
      <div v-else class="d-flex align-center">

        {{ roundToTwo( item.avgEvaluations ) }}

        <v-icon color="red" v-if="item.lastEvaluationInc < 0">
          $dropdown
        </v-icon>

        <v-icon style="transform: rotate(180deg);" color="green" v-if="item.lastEvaluationInc > 0">
          $dropdown
        </v-icon>

        <Btn
          class="ml-2"
          color="tertiary"
          :to="`/evaluations?user=${item.id}`"
          style="color: white !important"
          x-small dark
        >
          Ver
        </Btn>

      </div>
    </template>

     <template v-slot:item.contactable="{ item }">
       <div>{{item.contactable ? "Sí": "No"}}</div>
     </template>

     <template v-slot:item.active="{ value, item }">
       <Btn
        :color="value ? 'tertiary' : ''"
        :dark="value"
        @click="showCampaigns( item )"
        small
      >
         {{ value ? "Sí" : "No" }}
       </Btn>
     </template>

     <template v-slot:item.absences="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            style="color:green"
            v-if="getAbsenceRate(item)<2"
          >

            {{getAbsenceRate(item)}} %</span>
          <span
            v-bind="attrs"
            v-on="on"
            style="color:red"
            v-else
          >
            {{getAbsenceRate(item)}} %
          </span>
        </template>

        <span>Jornadas: {{item.totalJourneys}}</span><br>
        <span>Ausencias: {{item.totalAbsences}}</span>

      </v-tooltip>
    </template>

    <template v-slot:append-outer>
      <v-dialog
        v-model="dialog"
        max-width="720"
      >
        <Card class="campaigns-dialog">

          <Toolbar color="secondary" dark>

            <h3>Listado de Campañas</h3>

            <v-spacer/>

            <Btn color="white" width="20" height="20" fab light @click="dialog = false">
              <v-icon small>$close</v-icon>
            </Btn>

          </Toolbar>

          <div class="campaigns-dialog-body">
            <DTable
              :items="campaignsList"
              :headers="campaignsHeaders"
              :row-class="rowClass"
              :border="false"
              :multiple-sort="false"
              min-width="720"
            />
          </div>

        </Card>
      </v-dialog>
    </template>

  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import { mailTo, round } from "@/utils";
import { Device, UserRole } from "@/utils/constants";
import moment from "moment";

const roles = {
  [UserRole.INTERNAL]: "Internal",
  [UserRole.SUPERADMIN]: "Master",
  [UserRole.KAM]: "KAM",
  [UserRole.MANAGER]: "Gestor",
  [UserRole.CLIENT]: "Cliente",
  [UserRole.RRHH]: "RRHH",
  [UserRole.WORKER]: "Trabajador",
};

function displayDate( date ) {
  return moment( date ).format('DD/MM/YYYY');
}

export default {
  components: { Table },
  data() {
    return {
      messages: "Usuario/Usuarios",
      sections: [
        {
          text: "Trabajadores",
          section: UserRole.WORKER,
        },
        {
          text: "Managers",
          section: UserRole.MANAGER,
        },
        {
          text: "KAM",
          section: UserRole.KAM,
        },
        {
          text: "Clientes",
          section: UserRole.CLIENT,
        },
        {
          text: "RRHH",
          section: UserRole.RRHH,
        },
        {
          text: "Master",
          section: UserRole.SUPERADMIN,
        },
        {
          text: "Internos",
          section: UserRole.INTERNAL,
        },
        {
          text: "Todos",
          section: -1,
        },
      ],
      section: UserRole.WORKER,
      dialog: false,
      campaignsList: [],
      campaignsHeaders: [
        { value: 'campaignName', text: 'Campaña' },
        { value: 'placeName',    text: 'Centro' },
        { value: 'start', text: 'Empieza', display: displayDate, width: 140 },
        { value: 'end',   text: 'Acaba',   display: displayDate, width: 140 }
      ]
    };
  },
  computed: {
    headers() {

      var headers = [];

      headers.push({ text: "Usuario", value: "alias" });
      headers.push({ text: "Nombre", value: "name" });
      headers.push({ text: "Apellido", value: "surname" });
      headers.push({ text: "Email", value: "email", display: mailTo  });
      headers.push({ text: "Teléfono", value: "phone", width: 100 });

      if ( this.section == -1 ) {
        headers.push({
          text: "Rol",
          value: "role",
          display: roles,
          filter: {
            type: "select",
            items: Object.keys(roles).map((value) => ({
              text: roles[value],
              value: parseInt(value),
            })),
          },
        });
      } else if ( this.section == UserRole.WORKER ) {

        headers.splice( 3, 0, {
          text: "Provincia",
          value: "province"
        });

        headers.push({
          text: "NIF",
          value: "nif",
          width: 100
        });

        headers.push({
          text: "Dispositivos",
          value: "devices",
          width: 120,
          filterable: false,
          align: "right",
        });

        headers.push({
          text: "Contratable",
          value: "contactable",
          width: 100,
          filterable: true,
          filter: {
            type: 'select',
            field: 'contactable',
            items: [
              { value: true, text: 'Sí' },
              { value: false, text: 'No' }
            ]
          }
        });

        headers.push({ text: "Activo", value: "active", width: 100, filterable: true, filter: {
          type: 'select',
          field: 'active',
          items: [
            { value: true, text: 'Sí' },
            { value: false, text: 'No' }
          ]
        }});

        headers.push({ text: "Evaluaciones", value: "totalEvaluations", width:100, filterable: false});
        headers.push({ text: "Media", value: "evaluations", filterable: true, filter: {
          type: 'select',
          field: 'evaluations',
          items: [
            { value: 3, text: 'Mayor que 3' },
            { value: 2, text: 'Mayor que 2' },
            { value: 1, text: 'Mayor que 1' }
          ]
        }});
        headers.push({ text: "% Ausencia", value: "absences", filterable: false });
      }

      return headers;
    }
  },
  methods: {
    roundToTwo(num) {
      return +(Math.round(num + "e+2")  + "e-2");
    },
    sanitize(m) {
      if (m.client) {
        m.clientId = m.client.id;
      } else {
        m.clientId = null;
      }
      return m;
    },
    initialize( params ) {
      return new Promise(( resolve, reject ) => {

        var { service, page, filters, order, ascending } = params;
        this.section = params.section;
        filters = filters.slice();
        var target = service+"/all";

        if ( params.section >= 0 ) {
          filters.push({ field:"role", intValue: params.section })
        }

        // Data
        this.$store
          .dispatch( 'api', { target, params: { page, filters, order, ascending }})
          .then( resolve )
          .catch( reject );
      });
    },
    getAbsenceRate(item) {
      if ( item.totalJourneys != 0 ) {
        return round( item.totalAbsences / item.totalJourneys * 100, 2);
      }
      return 0;
    },
    deviceData(value) {
      if (value) {
        var device = "";

        if (value.version) {
          device += "v" + value.version + " ";
        }

        if (value.brand) {
          device += value.brand + " ";
        }

        if (value.model) {
          device += value.model + " ";
        }

        if (value.type) {
          switch (value.type) {
            case Device.ANDROID:
              device += "Android";
              break;
            case Device.IOS:
              device += "IOS";
              break;
          }
        }

        return device;
      }
      return "-";
    },
    showCampaigns( user ) {
      const now = Date.now();
      this.dialog = true;
      this.campaignsList = user.history.map( item => {
        item.active = item.start <= now && item.end >= now;
        return item;
      }).sort(( a, b ) => {
        if ( a.active === b.active ) return 0;
        if ( a.active ) return -1;
        return 1;
      });
    },
    rowClass( classes, item ) {
      return {
        ...classes,
        'campaign-active': item.active
      };
    }
  },
  mounted() {
    this.$store.commit( 'setTitle', 'Usuarios' );
  }
};
</script>

<style>
.campaigns-dialog {
  min-height: 350px;
  max-height: calc( 100vh - 40px );
  display: flex;
  flex-direction: column;
}
.campaigns-dialog > .campaigns-dialog-body {
  flex: 1 1 auto;
  position: relative;
}
.campaigns-dialog > .campaigns-dialog-body > div {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  overflow-y: auto;
}
.campaigns-dialog .dtable-header > div {
  background-color: var(--v-tertiary-base);
}
.campaigns-dialog .dtable-row:not(.campaign-active),
.campaigns-dialog .dtable-row:not(.campaign-active):hover {
  background-color: #eee;
}
</style>

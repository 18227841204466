<template>
  <DForm
    ref="form"
    class="pa-4"
    v-bind="$attrs"
    v-on="$listeners"
  >

    <DField
      key="title"
      class="mb-6"
      field="text"
      field-style="1"
      label="Nombre"
      :rules="rules.name"
      dense
    />

    <DField
      key="link"
      field="text"
      field-style="1"
      label="Link"
      :rules="rules.link"
      dense
    />

  </DForm>
</template>

<script>
import { required } from '@/utils/rules';

export default {
  data: () => ({
    rules: {
      name: [ required('Debes insertar un nombre.') ],
      link: [ required('Debes insertar la dirección web.') ]
    }
  }),
  watch: {
    $attrs() {
      this.$refs.form && this.$refs.form.resetValidation();
    }
  }
}
</script>

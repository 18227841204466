<template>
  <Table
    service="Kpi"
    :is-loading="loading"
    :messages="messages"
    :initialize="initialize"
    :sanitize="sanitize"
    :headers="headers"
    :params="params"
    :mypopup="mypopup"
    show-actions="edit,new,duplicate,export,delete"
  >

    <template v-slot:submenu-middle>

      <Btn :to="`${$route.path}/centers`" color="tertiary" dark>
        Ver objetivos
      </Btn>

      <v-divider class="mx-2" vertical/>

    </template>

    <template v-slot:item.products="{ value }">
      {{( value || [] ).length || 'Todos' }}
    </template>

    <template v-slot:item.file="{ value }">
      <Btn
        v-if="value"
        :href="value.url"
        target="_blank"
        color="tertiary"
        width="24" height="24"
        dark fab
      >
        <v-icon x-small>
          mdi-download
        </v-icon>
      </Btn>
      <span class="grey--text" v-else>
        Sin archivo
      </span>
    </template>

    <template v-slot:item.assign="{ item }">

      <Btn @click="selected = item" color="tertiary" small dark>
        Asignar
      </Btn>

      <Btn @click="goTo(item)" class="ml-2" color="secondary" small dark>
        <v-icon small left>mdi-eye-outline</v-icon>
        Objetivos
      </Btn>

    </template>

    <template v-slot:form="{ value, listeners }">
      <KpiForm :value="value" v-on="listeners"/>
    </template>

    <template v-slot:append>
      <v-dialog
        v-model="dialog"
        max-width="640"
        style="overflow:visible"
      >
        <Card class="rel" height="480">
          <v-row class="flex-column fill-height" no-gutters>
            <Toolbar color="secondary" dark>

              <h3>
                Asignar objetivo {{ selected && selected.title }}
              </h3>

              <v-spacer/>

              <Btn color="white" width="20" height="20" fab light @click="selected = null">
                <v-icon small>$close</v-icon>
              </Btn>

            </Toolbar>
            <div class="grow rel">
              <div class="layer autoscroll">
                <KpiCenterForm
                  ref="centers"
                  v-model="assignModel"
                  assign-mode
                />
              </div>
            </div>
            <Toolbar border-top>

              <v-spacer/>

              <Btn class="mr-4" color="white" @click="selected = null">
                Cancelar
              </Btn>

              <Btn color="primary" @click="assign()">
                Guardar
              </Btn>

            </Toolbar>
          </v-row>
        </Card>
      </v-dialog>
    </template>

  </Table>
</template>

<script>
import Table from '@/components/core/Table.vue';
import KpiForm from '@/components/forms/Kpi/single.vue';
import KpiCenterForm from '@/components/forms/Kpi/centers.vue';
import { mapState, mapMutations } from 'vuex';
import { KpiTypes } from '@/utils/constants';

const NUMBER_FILTER = {
  type: 'number',
  attrs: { min: 0, max: 100, hideControls: true }
};

export default {
  components: { Table, KpiForm, KpiCenterForm },
  data() {
    return {
      messages: { item: 'Objetivo/Objetivos' },
      mypopup: {
        width: 1280,
        height: 635,
        title: n => n ? 'Nuevo objetivo' : 'Editar objetivo'
      },
      loading: false,
      dialog: false,
      selected: null,
      assignModel: {},
      headers: [{
        value: 'title',
        text: 'Título'
      },{
        value: 'description',
        text: 'Descripción'
      },{
        value: 'type',
        text: 'Tipo',
        display: KpiTypes,
        filter: {
          type: 'select',
          items: Object.keys( KpiTypes ).map( v => ({
            value: parseInt(v),
            text: KpiTypes[v]
          }))
        }
      },{
        value: 'minLevel',
        text: 'Nivel mínimo (%)',
        display: v => `<span class="warning--text">${v}</span>`,
        filter: NUMBER_FILTER
      },{
        value: 'mediumLevel',
        text: 'Nivel medio (%)',
        display: v => `<span class="info--text">${v}</span>`,
        filter: NUMBER_FILTER
      },{
        value: 'maxLevel',
        text: 'Nivel máximo (%)',
        display: v => `<span class="success--text">${v}</span>`,
        filter: NUMBER_FILTER
      },{
        value: 'products',
        text: 'Productos asociados',
        filterable: false,
        sorteable: false
      },{
        value: 'file',
        text: 'Fichero asociado',
        filterable: false,
        sorteable: false
      },{
        value: 'assign',
        text: '',
        width: 300,
        filterable: false,
        sorteable: false
      }]
    }
  },
  computed: {
    ...mapState([ 'breadcrumbs' ]),
    campaignId() {
      return parseInt( this.$route.params.id );
    },
    params() {
      return {
        filters:[{
          field: 'campaign',
          intValue: this.campaignId
        }]
      }
    }
  },
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    },
    selected( kpi ) {
      this.dialog = !!kpi;
      this.assignModel = kpi ? { kpi } : {};
    }
  },
  methods: {
    ...mapMutations([ 'setView' ]),
    initialize( params ) {
      return this.$store.dispatch( 'page', params ).then( res => {
        res.data.forEach( item => {
          item.products.forEach( product => {
            product.picture = { url: product.thumbnail };
          });
        });
        return res;
      });
    },
    sanitize( model ) {
      return {
        ...model,
        campaign: model.campaign && model.campaign.id ? model.campaign.id : this.campaignId,
        products: model.products ? model.products.map( p => p.id ) : [],
        fileId: model.file ? model.file.id : 0,
        position: undefined,
        file: undefined
      };
    },
    setTitle() {

      const id = this.campaignId;
      let title = [ 'Campañas', '', 'Objetivos' ];
      this.$store.commit( 'setTitle', title );

      if ( this.breadcrumbs[1].text === '...' && id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    },
    assign() {
      if ( this.$refs.centers.$refs.form.validate()) {
        const m = this.assignModel;
        if ( m.centers && m.centers.length ) {

          if ( this.loading ) return;
          this.loading = true;
          this.dialog = false;

          this.$store.dispatch( 'api', {
            target: 'KpiCenter/assign',
            params: {
              id: m.id || -1,
              startDate: m.startDate,
              endDate: m.endDate,
              units: m.units,
              kpi: m.kpi && m.kpi.id ? m.kpi.id : null,
              centers: m.centers.map( c => c.id ),
              status: 1
            }
          }).then(() => {
            this.$store.dispatch(
              'console/success',
              'El objetivo se ha asignado a los centros correctamente.'
            );
          }).catch( err => {
            this.$store.dispatch('console/error', err );
          }).finally(() => {
            this.loading = false;
          });
        }
      }
    },
    goTo( item ) {

      const path = this.$route.path + '/centers';

      this.setView({ path, state: {
        section: [0,0],
        sections:{ '00': {
          showFilters: true,
          filterStore: {
            kpi: item.id
          }
        }}
      }});

      this.$router.push( path );
    }
  },
  mounted() {
    this.setTitle()
  }
}
</script>

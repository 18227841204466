<template>
  <v-row class="fill-height" no-gutters>
    <div class="grow rel">
      <v-row class="stats-boxes-2 layer autoscroll pa-2" no-gutters>
        <v-col cols="12" md="6">
          <v-subheader class="title"> Tiempo trabajado </v-subheader>
          <v-card class="d-flex flex-wrap ma-2 pa-3">

            <DStatsProgress
              v-if="allVisible"
              title="Trabajado"
              class="ma-8"
              color="#ddd"
              :value="worked.percent"
              :min-to-success="90"
              :min-to-warning="75"
            />

            <DStats
              v-if="allVisible"
              title="Horas de trabajo"
              class="ma-8"
              v-bind="propStats"
              :items="worked.items"
            />

            <ListDetails
              :value="client"
              titleStats="Horas de trabajo"
              title-progress="Trabajado"
              :items="worked.clients"
              :progressProps="timeProgressProps"
              cols="12" sm="12" md="12" lg="12"
              hide-selector
            />

          </v-card>
        </v-col>
        <v-col cols="12" md="6">

          <v-subheader class="title">
            Ausencias detectadas
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2 pa-3">

            <DStatsProgress
              v-if="allVisible"
              title="Ausencias"
              class="ma-8"
              color="#ddd"
              :value="absences.percent"
              :min-to-success="21"
              :min-to-warning="11"
              success-color="error"
              error-color="success"
            />

            <DStats
              v-if="allVisible"
              title="Total"
              class="ma-8"
              v-bind="propStats"
              :items="absences.items"
            />

            <ListDetails
              :value="client"
              title-progress="Ausencias"
              titleStats="Total"
              :items="absences.clients"
              :progressProps="absenceProgressProps"
              cols="12" sm="12" md="12" lg="12"
              hide-selector
            />

          </v-card>
        </v-col>
        <v-col cols="12" md="12">

          <v-subheader class="title">
            Ausencias detectadas por tipo
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2 pa-3">

            <DStatsCheese
              v-if="allVisible"
              title="Nº de ausencias detectadas"
              class="ma-8"
              color="#ddd"
              :colors="propStats.colors"
              :items="absences.cheese"
              :showLegend="false"
            />

            <DStats
              v-if="allVisible"
              title="Número de ausencias"
              class="ma-8"
              v-bind="propStats"
              :items="absences.cheese"
              legend-columns
            />

            <ListDetails
              :value="client"
              title-cheese="Comparativa"
              title-stats="Nº de ausencias"
              :type="1"
              :colors="propStats.colors"
              :items="absences.clients"
              hide-selector
              legend-columns
            />

          </v-card>
        </v-col>

         <v-col cols="12" md="12">

          <v-subheader class="title">
            Ausencias detectadas por cliente
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2 pa-3">

            <DStatsCheese
              title="Comparativa"
              class="ma-8"
              color="#ddd"
              :colors="propStats.colors"
              :items="absences.clientStats"
              :show-legend="false"
            />

            <DStats
              title="Nº de ausencias"
              class="ma-8"
              v-bind="propStats"
              :items="absences.clientStats"
              legend-columns
            />

          </v-card>
        </v-col>
        <v-col cols="12" md="12">

          <TimingGraph
            class="ma-2 pa-3"
            v-bind="timingProps"
            :show-filters="openFilters"
            @update="onUpdate"
          />

        </v-col>
      </v-row>
    </div>
    <v-expand-x-transition>
      <div v-show="openFilters" class="shrink rel" style="width: 300px">
        <Filters v-model="search" class="layer autoscroll" />
      </div>
    </v-expand-x-transition>
  </v-row>
</template>

<script>
import Filters from "./filters";
//import moment from 'moment';
import { mapState } from "vuex";
import { round } from "@/utils";
import { AbsenceType, AbsenceSubtype } from "@/utils/constants";
import colors from "@/utils/colors";
import ListDetails from "./ListDetails";
import TimingGraph from "./TimingGraph";

const ABSENCES_MAP = {
  //[AbsenceType.NONE]: 'Ninguno',
  [AbsenceType.HOLIDAY_WORKING]: "Festivo laboral",
  [AbsenceType.HOLIDAY_NO_WORKING]: "Festivo No laboral",
  [AbsenceType.USER_HOLIDAYS]: "Vacaciones",
  [AbsenceType.LIBRANZA]: "Libranza",
  [AbsenceType.PLACE]: "Centro descubierto",
  [AbsenceType.INCOMPLETE]: "Jornada incompleta",
  [AbsenceType.OTHER]: "Observaciones",
};

const SUBABSENCES_MAP = {
  [AbsenceSubtype.NO_CUBIERTA_PDT_SELECT]: "Pendiente de selección",
  [AbsenceSubtype.NO_CUBIERTA_PDT_CLIENTE]: "Pendiente de cliente",
  [AbsenceSubtype.NO_CUBIERTA_PDT_PDV]: "Pendiente de PDV",
  [AbsenceSubtype.BAJA_VOLUNTARIA]: "Baja voluntaria",
  [AbsenceSubtype.DESPIDO]: "Despido / NSPP",
  [AbsenceSubtype.IMPREVISTO_PERSONAL]: "Imprevisto personal",
  [AbsenceSubtype.ENFERMEDAD]: "Enfermedad",
  [AbsenceSubtype.DOCUMENTATION]: "Problemas documentación",
  [AbsenceSubtype.RETRASO]: "Retraso / Avance jornada",
};

export default {
  components: { Filters , ListDetails, TimingGraph },
  props: {
    client: Number,
    showFilters: Boolean,
    section: {
      type: String,
      default: '0-0'
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allVisible: false,
      openFilters: !!this.showFilters,
      search: this.value || {},
      timeProgressProps: {
        minToSuccess: "90",
        minToWarning: "75"
      },
      absenceProgressProps: {
        minToSuccess: "21",
        minToWarning: "11",
        successColor: "error",
        errorColor: "success",
        warningColor: "warning"
      },
      propStats: {
        color: "tertiary",
        colors,
      },
    };
  },
  computed: {
    ...mapState([ 'core', 'view' ]),
    worked() {
      const { core } = this;
      const data = {
        percent: 0,
        items: [
          { name: "Horas teoricas", value: 0 },
          { name: "Horas reales",   value: 0 },
          { name: "Horas ausentes", value: 0 },
        ],
      };

      if (core.totalEstimatedTime) {
        data.percent = round(
          (core.totalWorkedTime / core.totalEstimatedTime) * 100,
          1
        );
      }

      data.items[0].value = round((core.totalEstimatedTime||0) / 60, 1);
      data.items[1].value = round((core.totalWorkedTime||0) / 60, 1);
      data.items[2].value = round((core.totalAbsenceTime||0) / 60, 1);

      var trunc = (v) => v - (v % 1);
      var ceil = (v) => trunc(v % 1 > 0 ? v + 1 : v);
      var keys = Object.keys(core.clients||{});

      data.clients = [];

      if ( keys.length != 1 ) {
        data.clients.push({
          ...data,
          id: 0,
          name: "Todos",
          estimated: core.totalEstimatedTime || 0,
          absented: core.totalAbsenceTime || 0,
          worked: core.totalWorkedTime || 0,
          progress: { value : data.percent },
          stats: {
            items: data.items
          }
        });
      }

      keys.forEach((key) => {
        var it = core.clients[key];
        const client = {
          name: it.name,
          id: it.id,
          percent: 0,
          estimated: it.totalEstimatedTime || 0,
          absented: it.totalAbsenceTime || 0,
          worked: it.totalWorkedTime || 0,
          progress: {
            value: 0
          },
          stats: {
            items: [
              { key: "estimated", name: "Horas teoricas", value: 0 },
              { key: "worked", name: "Horas reales", value: 0 },
              { key: "absented", name: "Horas ausentes", value: 0 },
            ]
          }
        };

        client.stats.items[0].value = round(client.estimated / 60, 1);
        client.stats.items[1].value = round(client.worked / 60, 1);
        client.stats.items[2].value = round(client.absented / 60, 1);


        if (client.estimated > 0) {
          var cPercent = (client.worked / client.estimated);
          client.progress.value = ceil( cPercent * 100);
        } else {
          client.progress.value = 100;
        }

        data.clients.push(client);
      });

      return data;
    },
    absences() {
      const { core } = this;
      const data = {
        percent: 0,
        cheese: [],
        clientStats: [],
        items: [
          { name: "Ausencias", value: 0 },
          { name: "Jornadas", value: 0 },
        ],
      };

      if ( core.absenceType ) {

        if (core.totalWorkers) {
          data.percent = round(
            (core.totalAbsences / core.totalWorkers) * 100,
            1
          );
        }

        data.cheese = Object.keys( core.absenceType )
          .map( v => {
            const parts = v.split("-");
            var name = ABSENCES_MAP[parts[0]];
            if (parts.length > 1) {
              name = name + ": " + SUBABSENCES_MAP[parts[1]];
            }
            return ({
              name: name,
              value: core.absenceType[v],
            });
          })
          .filter( c => c.value > 0 );


        data.items[0].value = core.totalAbsences;
        data.items[1].value = core.totalWorkers;
      }

      data.clients = [];
      data.clientStats = [];

      var keys = Object.keys( core.clients || {} );
      if ( keys.length != 1 ) {
        data.clients.push({
           ...data,
           id: 0,
           name: "Todos",
           totalAbsences: core.totalAbsences || 0,
           totalWorkers: core.totalWorkers || 0,
           worked: core.totalWorkedTime || 0,
           progress: { value : data.percent },
           stats: {
             percent: data.percent,
             cheese: data.cheese,
             items: data.items
           }
         });
      }

      keys.forEach((key) => {
        var it = core.clients[key];
        if(it.totalAbsences>0){
        data.clientStats.push({
          name: it.name,
          value: it.totalAbsences,
        })
        }
        const client = {
          name: it.name,
          id: it.id,
          percent: 0,
          totalAbsences: it.totalAbsences || 0,
          totalWorkers: it.totalWorkers || 0,
          worked: it.totalWorkedTime || 0,
          progress: {
            value: 0
          },
          stats: {
            percent: 0,
            cheese: [],
            items: [
              { key: "absences", name: "Ausencias", value: 0 },
              { key: "workers", name: "Jornadas", value: 0 },
            ]
          }
        };

        if (client.totalWorkers) {
          client.percent = round(
            (client.totalAbsences / client.totalWorkers) * 100,
            1
          );
          client.progress.value = client.percent;
        }

        client.stats.items[0].value = client.totalAbsences;
        client.stats.items[1].value = client.totalWorkers;

        client.stats.cheese = Object.keys( it.absenceType )
          .map( v => {
            const parts = v.split("-");
            var name = ABSENCES_MAP[parts[0]];
            if (parts.length > 1) {
              name = name + ": " + SUBABSENCES_MAP[parts[1]];
            }
            return ({
              name: name,
              value: it.absenceType[v],
            });
          })
          .filter( c => c.value > 0 );

        data.clients.push(client);
      });

      return data;
    },
    timingProps() {
      return (this.core && this.core.timingProps) || {};
    },
    clientsList() {
      return this.worked.clients.map(( item, value ) => ({
        value,
        text: [ item.id, item.name ].filter( Boolean ).join('.')
      }))
    }
  },
  watch: {
    value(value) {
      this.search = value || {};
    },
    showFilters(value) {
      this.openFilters = !!value;
    },
    search(value) {
      const filters = [];
      if (value.date) {
        filters.push({
          field: "date",
          rangeValue: { start: value.date[0], end: value.date[1] },
        });
      }
      if (value.client)
        filters.push({ field: "client", intValue: value.client.id });
      if (value.campaign)
        filters.push({ field: "campaign", intValue: value.campaign.id });
      if (value.channel)
        filters.push({ field: "channel", intValue: value.channel.id });
      this.$emit("filters", filters);
      this.$emit("input", value);
    },
    clientsList( list ) {
      this.$emit( 'clients:list', list );
    }
  },
  methods: {
    onUpdate( props ) {
      this.$store.commit('setView', {
        path: this.$route.path,
        state: {
          section: this.view.section,
          sections: {
            [this.view.section.join('')]: {
              timingProps: props
            }
          }
        }
      })
    }
  },
  beforeMount() {
    this.$emit( 'clients:list', this.clientsList );
  }
};
</script>

<style>
.stats-boxes-2 > div {
  display: flex;
  flex-direction: column;
}
.stats-boxes-2 > div > .v-card {
  flex: 1 0 auto;
}
</style>

<template>
  <div class="d-flex flex-column flex-nowrap">
    <Toolbar border-bottom>
      <slot name="top" v-bind="{ value: model, add }">

        <span v-if="name" class="mr-1">
            Horarios de <strong class="tertiary--text">{{ name }}</strong>
        </span>

        <Btn @click="add" color="secondary" icon>
          <v-icon>$plus</v-icon>
        </Btn>

        <v-spacer/>

        <Btn v-if="model" @click="$emit( 'save', model )" color="tertiary" dark>
          Aplicar
        </Btn>

      </slot>
    </Toolbar>
    <div class="grow rel">
      <div class="layer autoscroll pa-2" style="background-color: #f4f4f4;">
        <Card class="ma-2" v-for="( options, index ) in internal" :key="index" border>
          <Toolbar border-bottom>

            <v-spacer/>

            <Btn @click="remove(options)" color="secondary" icon>
              <v-icon>$close</v-icon>
            </Btn>

          </Toolbar>

          <Single
            ref="single"
            :value="options"
            :all-day="allDay"
            :has-errors="hasErrors"
            @input="input( options, $event )"
          />

        </Card>

      </div>
    </div>
  </div>
</template>

<script>

  import Single from './single';

  export default {
    components: { Single },
    inject: [ 'toTrash' ],
    props: {
      value: Array,
      name: String,
      allDay: Boolean,
      hasErrors: Number
    },
    data() {
      return {
        model: ( this.value || [] ).slice()
      }
    },
    watch: {
      value( value ) {
        this.model = ( value || [] ).slice();
        this.$refs.single && this.$refs.single[0] && this.$refs.single[0].reset();
      }
    },
    computed: {
      internal() {
        return this.model.filter( a => a.priority === 0 );
      }
    },
    methods: {
      input( opt, value ) {
        const index = this.model.indexOf( opt );
        this.model[index] = value;
        this.$emit( 'input', this.model );
      },
      add() {
        this.model.push({
          start: null,
          end: null,
          frequency: 0,
          times: [],
          priority: 0
        });
        this.$emit( 'input', this.model );
      },
      remove( opt ) {

        // Search timetables ids for remove
        const index = this.model.indexOf( opt );
        if ( this.model[index] ) {
          this.model[index].times.forEach( day => {
            day.intervals.forEach( time => {
              if ( time.id > 0 ) {
                this.toTrash( time.id );
              }
            })
          })
          this.model.splice( index, 1 );
        }

        this.$emit( 'input', this.model );
      }
    }
  }
</script>

<template>
  <DForm :value="value" :style="{ width }" v-on="{ ...$listeners, input }">
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
      absolute
    />
    <v-row class="flex-column flex-nowrap fill-height" no-gutters>
      <div :class="full ? 'grow rel' : null">
        <div :class="full ? 'abs full autoscroll' : null">
          <Card class="rel" border-bottom>

            <DField
              key="picture"
              v-bind="picture"
              :readonly="readonly"
            />

          </Card>
          <div class="pa-4">

            <DField key="name" class="mb-6" v-bind="name" :readonly="readonly"/>
            <Brand  key="brand" class="mb-6" v-bind="brand" :readonly="readonly"/>
            <DField key="category" class="mb-6" v-bind="category" :readonly="readonly"/>
            <DField key="subcategory" class="mb-6" v-bind="subcategory" :readonly="readonly"/>
            <DField key="pvp" class="mb-6" v-bind="pvp" :readonly="readonly"/>
            <DField key="ean" class="mb-6" v-bind="ean" :readonly="readonly"/>
            <DField key="description" class="mb-6" v-bind="description" :readonly="readonly"/>

          </div>
        </div>
      </div>
      <v-divider/>
      <Toolbar v-if="!readonly && !hideActions">

        <v-spacer/>

        <Btn v-if="cancelable" class="mr-2" color="tertiary" @click="$emit('cancel')" text>
          Cancelar
        </Btn>

        <Btn color="primary" @click="save">
          Guardar
        </Btn>

      </Toolbar>
    </v-row>
  </DForm>
</template>

<script>

  import Brand from '@/components/forms/Brands/selector';

  export default {
    components: { Brand },
    props: {
      value: Object,
      readonly: Boolean,
      cancelable: Boolean,
      hideActions: Boolean,
      full: Boolean,
      width: String
    },
    data() {
      return {
        model: this.value,
        loading: false
      }
    },
    computed: {
      picture() {
        return {
          field: 'image',
          aspectRatio: 1.5,
          itemSrc: 'url',
          editMode: 'button'
        }
      },
      name() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Nombre',
          dense: true
        }
      },
      brand() {
        return {
          fieldStyle: 1,
          label: 'Marca',
          dense: true
        }
      },
      category() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Categoría',
          dense: true
        }
      },
      subcategory() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Subcategoría',
          dense: true
        }
      },
      pvp() {
        return {
          field: 'number',
          fieldStyle: 1,
          label: 'PVPR',
          min: 0,
          dense: true
        }
      },
      ean() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'EAN',
          dense: true
        }
      },
      description() {
        return {
          field: 'textarea',
          fieldStyle: 1,
          hideDetails: true,
          noResize: true,
          rows: 4,
          label: 'Descripción'
        }
      }
    },
    watch: {
      value( value ) {
        this.model = value;
      }
    },
    methods: {
      input( value ) {
        this.model = value;
        if ( this.$listeners.input )
          this.$listeners.input( value );
      },
      save() {

        this.loading = true;

        const m = this.model;
        const params = {
          id: m.id,
          name: m.name,
          description: m.description,
          pvp: m.pvp,
          ean: m.ean,
          category: m.category,
          subcategory: m.subcategory,
          picId: m.picture ? m.picture.id : null,
          brand: m.brand ? m.brand.id : null,
          status: 1
        };

        this.$store
          .dispatch( 'api', { target: 'Products/set', params })
          .then( response => {
            this.$emit( 'save', ( this.model = response ));
            this.$store.dispatch( 'console/success', 'Producto guardado.' );
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.loading = false );
      }
    }
  };
</script>

<template>
  <div class="badge">
    <slot/>
    <v-sheet
      ref="bullet"
      class="bullet"
      v-if="value"
      v-bind="bullet"
      :color="color"
      :style="position"
      :transition="transition"
    >
      {{ content }}
    </v-sheet>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: true
      },
      content: [ Number, String ],
      color: String,
      bullet: Object,
      offsetX: [ Number, String ],
      offsetY: [ Number, String ],
      top: Boolean,
      bottom: Boolean,
      left: Boolean,
      right: Boolean,
      center: Boolean,
      transition: String
    },
    computed: {
      position() {

        const style = {};
        const x  = parseFloat( this.offsetX ) || 0;
        const y  = parseFloat( this.offsetY ) || 0;

        if ( this.right ) style.right = `${ x }px`;
        else if ( this.left )  style.left  = `${ x }px`;
        else if ( this.center ) {
          style.left = '50%';
          style.marginLeft = `${ x }px`;
        }

        if ( this.top ) style.top = `${ y }px`;
        else if ( this.bottom ) style.bottom  = `${ y }px`;
        else if ( this.center ) {
          style.top = '50%';
          style.marginTop = `${ y }px`;
        }

        return style;
      }
    },
    methods: {

    }
  };
</script>

<style>
  .badge {
    position: relative;
  }
  .badge > .bullet {
    position: absolute;
    line-height: 17px;
    text-align: center;
    padding: 0 2px;
    min-width: 16px;
    height: 16px;
    border-radius: 99999px !important;
    background-color: red;
    font-size: 11px;
    font-weight: 600;
    color: white;
  }
</style>

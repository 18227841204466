<template>
  <Single
    service="CampaignTasks"
    messages="Registro"
    :initialize="initialize"
    :sanitize="sanitize"
    :update="update"
    :auto-id="false"
    @initialized="setTitle"
    @saved="readonly = true"
  >
    <template v-slot="{ value, listeners }">
      <DayTask
        ref="task"
        v-on="listeners"
        :value="value"
        :readonly="readonly"
        @empty:elements="hasEmptyElements = $event"
      />
    </template>
    <template v-slot:actions="{ save }">

      <v-spacer/>

      <Btn class="mr-2" v-if="readonly && !isClient" @click="validate" :loading="validating" color="tertiary" text>
        <v-icon class="mr-2">mdi-check</v-icon>
        Validar
      </Btn>

      <Btn color="tertiary" @click="edit()" dark v-if="!isClient">
        {{ readonly ? 'Editar' : 'Cancelar' }}
      </Btn>

      <Btn v-if="!readonly && !isClient" class="ml-2" color="tertiary" @click="save()" dark>
        Guardar
      </Btn>

    </template>
  </Single>
</template>

<script>

import Single from "@/components/core/Single.vue";
import DayTask from "@/components/forms/DayTasks/single";
import { mapState, mapMutations } from 'vuex';
import { CampaignActionType } from '@/utils/constants';
import { toArray } from '@/utils';

export default {
  components: { Single, DayTask },
  data: function() {
    return {
      readonly: true,
      validating: false,
      hasEmptyElements: false
    }
  },
  computed: {
    ...mapState([ 'path', 'breadcrumbs' ]),
    isClient() {
      return  this.$store.state.isClient;
    },
    idTask() {
      const { id, index } = this.$route.params;
      return index ? parseInt( index ) : parseInt( id );
    }
  },
  watch: {
    $route() {
      this.$nextTick( this.setBreadcrumbs );
    }
  },
  methods: {
    ...mapMutations([ 'setView' ]),
    ...mapMutations( 'app', [ 'setDialog' ]),
    setTitle( res, campaign ) {
      const title = [ 'Campañas', campaign, 'Registros', res && res.action.title ];
      if ( ! this.$route.params.index ) title.splice(0,2);
      this.$store.commit( 'setTitle', title );
    },
    set( state ) {
      this.setView({
        path: this.path,
        state: {
          sections: {
            ['00']: state
          }
        }
      });
    },
    initialize( params ) {
      return this.$store.dispatch( "single", {
        ...params,
        id: this.idTask
      });
    },
    sanitize( model ) {

      const { idTask } = this;
      const { action, response } = model;
      const { changed } = ( response || {} );

      const params = {
        idTask,
        pictures: [],
        replies: changed ? [] : ( response || {} ).replies,
        status: model.status
      };

      if ( action.type === CampaignActionType.PICTURES ) {

        // Acción de fotos
        params.pictures = ( changed || response.pictures ).map( pic => pic.id );

      } else {

        if ( model.categories && action.addCategories ) {
          params.action = {
            ...action,
            numPictures: null,
            idSurvey: action.survey.survey.id,
            idCampaign: model.idCampaign,
            categories: model.categories
          };
        }

        // Acción de encuesta, venta o seguimiento

        var q, p, t, reply;
        for ( q in changed ) {

          reply = { idQuestion: q, idProduct: null, itemName: null };

          switch ( action.type ) {
            case CampaignActionType.SURVEY:

              params.replies.push({ ...reply, response: toArray( changed[q].value ).join(',') });
              break;

            case CampaignActionType.SALES:
            case CampaignActionType.FOLLOW:

              t = action.type === CampaignActionType.SALES ? 'idProduct' : 'itemName';
              for ( p in changed[q] ) {
                params.replies.push({
                  ...reply,
                  [t]: p,
                  response: toArray( changed[q][p].value ).join(',')
                });
              }
              break;
          }
        }
      }

      return params;
    },
    edit() {
      if ( ! this.readonly ) this.$refs.task.reset();
      this.readonly = !this.readonly;
    },
    update( params ) {
      return new Promise(( resolve, reject ) => {
        if ( ! this.hasEmptyElements ) {
          this.save( params ).then( resolve ).catch( reject );
        } else {
          this.setDialog({
            show: true,
            title: 'Hay questionarios sin contestar',
            text: 'Estos elementos serán eliminados.<br>¿Está seguro de que desea continuar?',
            acceptText: 'Guardar',
            cancel: () => reject(),
            accept: () => this.save( params ).then( resolve ).catch( reject ),
          })
        }
      });
    },
    save( params ) {
      return new Promise(( resolve, reject ) => {

        const { action } = params;
        delete params.action;

        Promise
          .resolve( action && this.$store.dispatch( 'api', { target: 'Actions/set', params: action }))
          .then(() => this.$store.dispatch( 'api', { target: 'CampaignTasks/set', params }))
          .then( resolve )
          .catch( reject )
      });
    },
    validate() {

      const target = 'CampaignTasks/validate';
      const params = { ids: [ this.idTask ], status: 2 };
      this.validating = true;

      this.$store
        .dispatch( 'api', { target, params })
        .then( response => {
          this.set( response[0] );
          this.$store.dispatch( 'console/success', 'Registro validado.');
        })
        .catch( err => this.$store.dispatch( 'console/error', err ))
        .finally(() => this.validating = false );
    },
    setBreadcrumbs() {
      this.setTitle();
      if ( this.breadcrumbs[1].text === '...' && this.$route.params.index ) {
        const id = parseInt( this.$route.params.id );
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          this.setTitle(null,res.title);
        });
      }
    }
  },
  mounted() {
    this.setBreadcrumbs();
  }
};
</script>

<template>
  <div class="dfield-autocomplete">
    <v-autocomplete
      v-bind="attr"
      v-on="$listeners"
      :value="model"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      :item-text="itemText"
      :item-value="itemValue"
      :readonly="readonly"
      :clearable="!readonly"
      append-icon=""
      autocomplete="off"
      color="secondary"
      hide-selected
      hide-no-data
      :return-object="returnObject"
      @keypress.enter="open"
    >

      <slot v-for="( s, name ) in $slots" :name="name"/>
      <template v-for="( s, name ) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>

      <template v-slot:prepend>
        <slot name="prepend">
          <label v-if="attr.preLabel" class="label" :style="labelStyle">
            {{ label }}
          </label>
        </slot>
      </template>

      <template v-slot:append-outer>
        <slot name="append-outer">
          <Btn
            v-if="service && mypopup"
            class="ml-1"
            color="secondary"
            @click="open"
            :disabled="readonly"
            icon
          >
            <v-icon>{{ ( search || !model || attr.multiple ) ? '$plus' : '$edit' }}</v-icon>
          </Btn>
        </slot>
      </template>

      <template v-slot:item="scope">
        <slot name="item" v-bind="scope">
          {{ seletionDisplay( scope.item ) }}
        </slot>
      </template>

      <template v-slot:selection="scope">
        <v-chip
          active-class="grey"
          class="d-chip single-line"
          :class="{ 'style-1': parseInt( fieldStyle ) === 1 }"
          :input-value="scope.selected"
          :ripple="false"
          :dark="scope.selected"
          small
        >
          <slot name="selection" v-bind="scope">
            {{ seletionDisplay( scope.item ) }}
          </slot>
        </v-chip>
      </template>

    </v-autocomplete>

    <v-dialog :value="form.show" :max-width="dialog ? dialog.width : null">
      <Card>
        <v-toolbar height="56" color="secondary" elevation="0" dark>
          <h3>{{ formTitle }}</h3>
          <v-spacer/>
          <Btn color="white" width="20" height="20" fab light @click="close">
            <v-icon small>$close</v-icon>
          </Btn>
        </v-toolbar>
        <Card class="autoscroll pa-4" :height="dialog ? dialog.height : null" border-bottom>

          <slot name="form" v-bind="form"/>

        </Card>
        <v-toolbar height="56" elevation="0">
          <Btn color="white" @click="close">
            Cancelar
          </Btn>
          <v-spacer/>
          <Btn color="primary" @click="save">
            Guardar
          </Btn>
        </v-toolbar>
      </Card>
    </v-dialog>
  </div>
</template>

<script>

  import { Any } from '@/utils';
  import { toArray, query, Params, formatSize } from '@/utils';

  function excludeItems( exclude ) {
    return function( item ) {
      if ( typeof exclude !== 'object' ) return item !== exclude;
      for ( var key in exclude ) {
        if ( item[key] === exclude[key] ) return false;
      }
      return true;
    }
  }

  export default {
    inheritAttrs: false,
    props: {
      value: Any,
      service: String,
      method: {
        type: String,
        default: 'list'
      },
      params: Object,
      exclude: [ Object, Array, Function ],
      label: String,
      labelSpace: [ Number, String ],
      fieldStyle: [ Number, String ],
      itemSearch: String,
      itemText: String,
      itemValue: String,
      readonly: Boolean,
      mypopup: [ Boolean, Object ],
      sanitize: Function,
      update: Function,
      display: Function,
      cacheItems: Array,
      returnObject: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        search: '',
        items: (this.cacheItems||[]).slice(),
        model: this.value,
        loading: false,
        form: {
          show: false,
          value: null,
          changed: false,
          validating: false,
          valid: false,
          listeners: {
            change:   v => this.form.changed = v,
            loading:  v => this.form.validating = v,
            validate: v => this.form.valid = v,
            input:    v => this.form.value = v
          }
        }
      }
    },
    watch: {
      value( value ) {
        this.model = value;
        this.add( value );
        this.search = "";
      },
      search( value ) {
        if ( value ) this.find( value );
      },
      exclude() {
        this.items = this.filter( this.items );
      },
      cacheItems( items ) {
        this.items = items || [];
      }
    },
    computed: {
      attr() {
        switch ( String( this.fieldStyle )) {
          case '1':
            return {
              ...this.$attrs,
              backgroundColor: 'white',
              label: this.label,
              outlined: true
            };
          case '2':
            return {
              ...this.$attrs,
              preLabel: true,
              dense: true
            };
        }
        return {
          ...this.$attrs,
          label: this.label
        };
      },
      labelStyle() {
        const space = this.labelSpace;
        return { width: formatSize( space ) };
      },
      dialog() {
        return this.mypopup
          ? { width: 640, height: 480, title: 'Nuevo', ...this.mypopup }
          : null;
      },
      formValue() {
        const { dialog, search, model } = this;
        return dialog
          ? search || !model || this.attr.multiple
            ? { id: null, status: 1, ...( typeof dialog.initial === 'function' ? dialog.initial( search ) : null )}
            : ( model || null )
          : null;
      },
      formTitle() {
        const title = this.dialog ? this.dialog.title : '';
        const editing = !!this.search || !this.model || this.attr.multiple;
        return typeof title === 'function'
          ? title( editing )
          : title ? title : editing
            ? 'Nuevo'
            : 'Editar';
      }
    },
    methods: {
      select( items ) {
        items = toArray( items ).filter( a => a );
        if ( ! this.$attrs.multiple ) this.model = items[ items.length - 1 ];
        else this.model = ( this.model || [] ).concat( items );
        this.$emit( 'input', this.model );
      },
      find( values ) {

        values = toArray( values );
        const { params } = this;
        const itemSearch = this.itemSearch || this.itemText;

        if ( itemSearch === 'id' ) values = values.map( v => this.fetch( Params( params, { id: v })));
        else if ( itemSearch ) values = values.map( v => this.fetch( Params( params, query({[ itemSearch ]: v }))));

        this.loading = true;

        Promise
          .all( values )
          .catch( console.warn )
          .finally(() => this.loading = false );
      },
      fetch( params ) {
        return new Promise(( resolve, reject ) => {

          const { service, method } = this;

          if ( service ) {

            const type = params && params.page ? method : 'get';
            const target = `${ service }/${ type }`;

            this.$store.dispatch( 'api', { target, params }).then( response => {

              if ( response.page == null ) this.add( response );
              else {

                this.add( response.data );
                if ( !response.page ) return resolve();

                if ( this.all && type === 'all' && response.page <= response.numPages ) {
                  params.page++;
                  this.fetch( params ).then( resolve ).catch( reject );
                  return;
                }
              }

              resolve();

            })
            .catch( reject );

          } else {
            resolve();
          }
        });
      },
      open() {
        const { formValue } = this;
        if ( formValue ) {
          this.form.show = true;
          this.form.value = formValue;
        }
      },
      close() {
        this.form.show = false;
      },
      save() {

        const { valid, changed, validating } = this.form;
        if ( ! valid || validating ) return;
        if ( ! changed ) return this.form.show = false;

        const params = this.sanitize ? this.sanitize( this.form.value ) : this.form.value;
        const update = this.update
          ? this.update( params )
          : this.$store.dispatch( 'api', { target: `${ this.service }/set`, params });

        Promise
          .resolve( update )
          .then( response => {
            this.add( response );
            this.select( response );
          })
          .catch( console.warn )
          .finally(() => this.loading = this.form.show = false );
      },
      filter( items ) {
        items = toArray( items );
        toArray( this.exclude ).forEach( exc => {
          if ( typeof exc === 'function' ) {
            items = items.filter( exc );
          } else if ( exc ) {
            items = items.filter( excludeItems( exc ))
          }
        });
        return items.filter( Boolean );
      },
      add( items ) {
        this.filter( items ).forEach(( item, index ) => {
          if (( index = this.items.findIndex( a => a.id === item.id )) !== -1 ) {
            this.items.splice( index, 1, item );
          }
          else this.items.push( item );
        });
        this.$emit( 'added', this.items );
        return this.items;
      },
      seletionDisplay( item ) {
        return this.display
          ? this.display( item )
          : item[ this.itemText ];
      },
      reset() {
        this.items = [];
        if ( this.search ) this.find( this.search );
      }
    },
    mounted() {
      this.add( this.value );
    }
  }
</script>

<style>
  .dfield-autocomplete .v-input__append-outer {
    margin: 0 !important;
  }
  .dfield-autocomplete .d-chip {
    position: relative;
    margin: -1px 8px 0 0;
    overflow: inherit;
  }
  .dfield-autocomplete .d-chip.style-1 {
    margin: 0 8px 0 -4px;
  }
</style>

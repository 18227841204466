<template>
  <v-tabs
    v-bind="$attrs"
    :value="value"
    :class="classes"
    :hide-slider="classic"
    @change="input"
    slider-size="4"
  >
    <v-tab
      v-for="( item, i ) in items"
      :key="`tab-${i}`"
      :ripple="false"
      :to="item.to"
      v-bind="item.tab"
      class="subtitle-2"
    >
      <v-icon v-if="item.icon" v-text="item.icon"/>
      {{ item.title || item.text }}
      <v-chip v-if="item.bullet" color="tertiary" class="ml-1 pa-1" x-small dark>
        {{ item.bullet > 100 ? '+99' : item.bullet }}
      </v-chip>
    </v-tab>
  </v-tabs>
</template>

<script>
  export default {
    name: 'Tabs',
    props: {
      value: [ Number, String ],
      items: Array,
      classic: Boolean
    },
    computed: {
      classes() {
        return {
          tabs: true,
          classic: this.classic
        }
      }
    },
    methods: {
      input( value ) {
        this.$emit( 'input', value );
      }
    }
  };
</script>

<style lang="scss">
  .theme--light {
    &.tabs {
      .v-tab {
        font-weight: normal;
        text-transform: none;
        letter-spacing: 0;
        ::before {
          background-color: #000 !important;
        }
        &.v-tab--active::before {
          display: none;
        }
      }
      &.classic {
        > .v-tabs-bar {
          background-color: transparent;
        }
        .v-tab--active {
          background-color: white;
          border: 1px solid var(--v-border-lighten1);
          border-bottom: 0;
        }
      }
    }
  }
</style>

import { RouteTag, UserRole } from '@/utils/constants';

function sortItems(a, b) {
  a = a.link.order == null ? Infinity : a.link.order;
  b = b.link.order == null ? Infinity : b.link.order;
  return a - b;
}

function join(a, b) {
  return (a || '').replace(/\/$/, '') + '/' +
    (b || '').replace(/\//, '');
}

export function menuitem(meta, route, path) {
  meta = meta || {};
  return {
    id: meta.menu,
    roles: meta.roles,
    tag: meta.tag,
    name: route.name,
    group: meta.group,
    groupIcon: meta.groupIcon,
    title: meta.title || route.name || route.path,
    icon: meta.icon,
    path: join(path, route.path),
    link: {
      to: join(path, route.path),
      ...meta.link
    }
  };
}

export function menus(user, routes) {

  const menus = {};

  routes.forEach(route => {

    const item = menuitem(route.meta, route);
    if (item.id && (!user || item.roles.includes(user.role))) {

      var valid = true;
      if ( user && user.role == UserRole.CLIENT ) {
        //En caso de cliente miramos cada uno de las posibilidades antes de seguir...
        if (route.meta.tag == RouteTag.STATS && !user.statsEnabled) {
          valid = false;
        }
        else if (route.meta.tag == RouteTag.TASKS && !user.tasksEnabled) {
          valid = false;
        }
        else if (route.meta.tag == RouteTag.PICTURES && !user.picturesEnabled) {
          valid = false;
        }
        else if (route.meta.tag == RouteTag.GEOLOCATION && !user.geolocationEnabled) {
          valid = false;
        }
        else if (route.meta.tag == RouteTag.ISSUES && !user.issuesEnabled) {
          valid = false;
        }
        else if (route.meta.tag == RouteTag.WORKERS && !user.workersEnabled) {
          valid = false;
        }
      }

      if (valid) {
        var menu = menus[item.id] = menus[item.id] || [];
        if (!item.group) menu.push(item);
        else {
          var group = menu.find(a => a.group === item.group);
          if (!group) {
            group = {
              ...item,
              title: item.group,
              icon: item.groupIcon,
              children: []
            };
            menu.push(group);
          }
          group.children.push(item);
        }
      }
    }

  });

  // Sort menu
  for (var id in menus) {
    menus[id].sort(sortItems);
    if (menus[id].children)
      menus[id].children.sort(sortItems);
  }

  return menus;
}

export function submenu(route, path) {
  path = join(path, route.path);
  return !route.children ? [] : route.children.map(route => {
    const item = menuitem(route.meta, route, path);
    item.children = submenu(route, path);
    return item;
  });
}

<template>
  <Table
    service="Campaigns"
    :initialize="initialize"
    :remove="remove"
    :update="update"
    :sanitize="sanitizeEvaluation"
    :headers="headers"
    :messages="messages"
    :sections="sections"
    :hide-table="hideTable"
    :hide-footer="hideTable"
    :exportData="exportData"
    :mypopup="mypopup"
    :is-loading="loading"
    @section="currentSection = $event"
    :show-actions="tableActions"
    :item-key="computeItemKey"

    hide-back-button
    reload-on-save
  >

    <template v-if="queryUser" v-slot:submenu-middle>
      <Btn class="text-none mr-2" color="secondary" @click="goToParent">
        Ver todas las evaluaciones
      </Btn>
    </template>

    <template v-slot:item.campaign="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link" v-if="value" :title="value" :to="`/campaigns/${ item.campaign.id }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.campaignName="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link single-line" v-if="value" :title="value" :to="`/campaigns/${ item.idCampaign }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.value="{ value }">
      <strong v-if="value" :class="colorValue( value )">
        {{ value }}
      </strong>
      <span v-else>Sin evaluar</span>
    </template>

    <template v-slot:item.storeValoration="{ item, value }">
      <span
        class="dtable-cell-content"
        :class="valorationColor(item.storeValoration)"
      >
        {{ value || 'Sin valoración' }}
      </span>
    </template>

    <template v-slot:item.clientValoration="{ item, value }">
      <span
        class="dtable-cell-content"
        :class="valorationColor(item.clientValoration)"
      >
        {{ value || 'Sin valoración' }}
      </span>
    </template>

    <template v-slot:item.selection="{ value }">
      <span class="dtable-cell-content">
        {{ value || 'Sin info' }}
      </span>
    </template>

    <template v-slot:item.selectAgain="{ item, value }">
      <span
        class="dtable-cell-content"
        :class="contactColor(item.selectAgain)"
      >
        {{ value || 'Sin valoración' }}
      </span>
    </template>

    <template v-slot:form="{ value, listeners }">
      <StandardEvaluation
        v-if="isStandard"
        :value="value"
        v-on="listeners"
      />
      <Evaluation
        v-else
        :value="value"
        v-on="listeners"
      />
    </template>

    <template v-slot:prepend-outer>
      <div v-if="configData" class="d-flex flex-column flex-nowrap fill-height pt-1">
        <div class="grow">

          <Configuration
            :value="configData"
            @input="model = $event"
            @change="changed = $event"
            @validate="valid = $event"
            @survey-uses="uses = $event"
          />

        </div>
        <Toolbar border-top>

          <v-spacer/>

          <Btn color="primary" :disabled="!validToSave" @click="save( model )">
            Guardar
          </Btn>

        </Toolbar>
      </div>
    </template>
  </Table>
</template>

<script>

import Table from "@/components/core/Table.vue";
import Configuration from "@/components/forms/Evaluations/config.vue";
import Evaluation from "@/components/forms/Evaluations/single.vue";
import StandardEvaluation from "@/components/forms/Evaluations/standard.vue";
import { SurveyQuestionType } from '@/utils/constants';
import { formatDate, userName, direction, get, toArray } from "@/utils";
import { mapState, mapMutations } from "vuex";

function date( value ) {
  return value && formatDate( value, {
    year:  "numeric",
    month: "2-digit",
    day:   "2-digit"
  }) || '-';
}

const finalValorationOptions = {
  '-1': 'Sin valoración',
  '1': 'Mala',
  '2': 'Media',
  '3': 'Buena',
  '4': 'Muy bien'
};

const selectAgainOptions = {
  //'-1': 'Sin valoración',
  '1': 'Sí',
  '0': 'No'
};

const selectionOptions = {
  '-1': 'Sin info',
  '1': 'RRHH Grupo OT',
  '2': 'Tienda',
  '3': 'Cliente'
};

const HEADER = {
  date: {
    text: "Fecha",
    value: "date",
    width: 100,
    display: v => date( v ),
    filter: {
      type: "rangedate",
      compute: (value, field) => {
        if (!value[0] || !value[1]) return { [field]: null };
        return { [field]: { start: value[0], end: value[1] } };
      },
    },
  },
  user: {
    text: "Promotor",
    value: "user",
    display: (v) => userName(v, true),
    filter: {
      type: "autocomplete",
      attrs: {
        service: "Users",
        itemValue: "id",
        itemText: "name",
        display: (v) => userName(v, true),
      },
    },
  },
  userName: {
    text: "Promotor",
    value: "userName",
    sorteable: true,
    filter: {
      field: 'userName'
    }
  },
  center: {
    text: "Centro",
    value: "place",
    sorteable: true,
    filter: {
      field: 'place'
    }
  },
  province: {
    text: "Provincia",
    value: "province",
    sorteable: true,
    filter: {
      field: 'province'
    }
  },
  channel: {
    text: "Canal",
    value: "channel",
    sorteable: true,
    filter: {
      field: 'channel'
    }
  },
  nif: {
    text: "NIF",
    value: "nif",
    sorteable: true,
    filter: {
      field: 'nif'
    }
  },
  evalNIF: {
    text: "NIF",
    value: "user.nif",
    sorteable: true,
    filter: {
      field: 'nif'
    }
  },
  place: {
    text: "Centro",
    value: "place",
    display: (v) => direction(v),
    filter: {
      type: "autocomplete",
      attrs: {
        service: "Places",
        itemValue: "id",
        itemText: "name",
        display: (v) => direction(v),
      },
    },
  },
  campaign: {
    text: "Campaña",
    value: "campaign",
    display: a => a ? `${a.id}. ${a.title||a.name}` : '',
  },
  client: {
    text: "Cliente",
    value: "campaign.client",
    display: a => a ? `${a.id}. ${a.title||a.name}` : '',
  },
  value: {
    text: "Nota",
    value: "value",
    width: 150,
  },
  storeValoration: {
    text: 'Valoración tienda',
    value: 'storeValoration',
    display: finalValorationOptions,
    sorteable: true,
    filter: {
      type: 'select',
      items: Object.keys( finalValorationOptions ).sort().map( value => ({
        text: finalValorationOptions[value],
        value: parseInt( value )
      }))
    }
  },
  clientValoration: {
    text: 'Valoración cliente',
    value: 'clientValoration',
    display: finalValorationOptions,
    sorteable: true,
    filter: {
      type: 'select',
      items: Object.keys( finalValorationOptions ).sort().map( value => ({
        text: finalValorationOptions[value],
        value: parseInt( value )
      }))
    }
  },
  selection: {
    text: 'Origen selección',
    value: 'selection',
    display: selectionOptions,
    sorteable: true,
    filter: {
      type: 'select',
      items: Object.keys( selectionOptions ).sort().map( value => ({
        text: selectionOptions[value],
        value: parseInt( value )
      }))
    }
  },
  selectAgain: {
    text: 'Contratable',
    value: 'selectAgain',
    display: selectAgainOptions,
    filterable: true,
    sorteable: true,
    filter: {
      type: 'select',
      items: Object.keys( selectAgainOptions ).sort().map( value => ({
        text: selectAgainOptions[value],
        value: parseInt( value )
      }))
    }
  },
}

export default {
  components: { Table, Configuration, Evaluation, StandardEvaluation },
  data() {
    return {
      model: null,
      changed: false,
      valid: false,
      loading: false,
      section: '0-0',
      uses: 0,
      currentSection: [0,0],
      queryUser: 0,
      messages: {
        item: "Evaluación/Evaluaciones",
        fem: true,
      },
      mypopup: {
        width: 600,
        height: 600,
        title: ( a, v ) => {
          var _date = get(v,'value.date');
          return this.isStandard
            ? `Evaluación de ${ get( v, 'value.userName' ) }` + ( _date ? `<br>Realizada el ${ date(_date)}` : '' )
            : `Evaluación del ${ date(_date)}`;
        }
      }
    };
  },
  computed: {
    ...mapState([ 'core', 'path', 'breadcrumbs' ]),
    idCampaign() {
      return parseInt( this.$route.params.id ) || 0;
    },
    headers() {
      if ( this.section == '0-0' ) {
        if ( this.idCampaign ) {
          return [
            HEADER.userName,
            HEADER.nif,
            HEADER.center,
            HEADER.province,
            HEADER.channel,
            HEADER.storeValoration,
            HEADER.clientValoration,
            HEADER.selection,
            HEADER.selectAgain,
          ]
        } else {
          return [
            HEADER.userName,
            HEADER.nif,
            {
              text: "Campaña",
              value: "campaignName",
              display: (a,b,c) => [ c.idCampaign, c.campaignName ].join('. '),
            },
            HEADER.center,
            HEADER.province,
            HEADER.channel,
            HEADER.storeValoration,
            HEADER.clientValoration,
            HEADER.selection,
            HEADER.selectAgain,
          ]
        }

      }
      if ( this.section == '0-1' ) {
        if ( this.idCampaign ) {
          return [
            HEADER.date,
            HEADER.user,
            HEADER.evalNIF,
            HEADER.value
          ]
        } else {
          return [
            HEADER.date,
            HEADER.user,
            HEADER.evalNIF,
            HEADER.campaign,
            HEADER.client,
            HEADER.value
          ]
        }
      }
      if ( this.idCampaign ) {
        return [
          HEADER.date,
          HEADER.user,
          HEADER.evalNIF,
          HEADER.place,
          HEADER.value
        ]
      } else {
        return [
          HEADER.date,
          HEADER.user,
          HEADER.evalNIF,
          HEADER.campaign,
          HEADER.client,
          HEADER.place,
          HEADER.value
        ]
      }
    },
    sections() {
      return this.queryUser ? [{
        text: 'Valoraciones',
        target: 'UserEvaluations/all',
        section: '0-0'
      }] : [{

        text: "Evaluaciones",
        children: [
          {
            text: "Valoraciones",
            target: `UserEvaluations/${ this.idCampaign ? 'campaign' : 'all' }`,
            section: '0-0'
          },
          {
            text: "Grupo OT",
            target: "Evaluations/all",
            filters: [{ field: "type", intValue: 0 }],
            section: '0-1'
          },
          {
            text: "Compañia",
            target: "Evaluations/all",
            filters: [{ field: "type", intValue: 1 }],
            section: '0-2'
          }
        ]
      }, this.idCampaign && {

          text: "Configuración",
          children: [
            { text: "Grupo OT",   target: "Evaluations/get", section: '1-0' },
            { text: "Compañia", target: "Evaluations/get", section: '1-1' },
          ]

      }].filter( Boolean );
    },
    hideTable() {
      return ['1-0','1-1'].includes( this.section );
    },
    configData() {
      if ( this.section === '1-0' ) {
        return get( this.core, 'single.grupoOtEvaluation' );
      } else if ( this.section === '1-1' ) {
        return get( this.core, 'single.companyEvaluation' );
      }
      return null;
    },
    validToSave() {
      return this.changed && this.valid && get( this.model, 'survey' );
    },
    isStandard() {
      return this.section === '0-0';
    },
    tableActions() {
      return this.isStandard ? 'edit,export' : 'edit,delete,export';
    }
  },
  watch: {
    $route( route ) {
      this.queryUser = route.query && parseInt( route.query.user );
      this.$nextTick( this.setTitle );
    }
  },
  methods: {
    ...mapMutations([ 'setView' ]),
    ...mapMutations( 'app', [ 'setDialog' ]),
    set( state ) {
      const s = this.currentSection.join('');
      this.setView({
        path: this.path,
        state: {
          section: this.currentSection,
          sections: { [s]: state }
        }
      })
    },
    goToParent() {
      this.queryUser = 0;
      this.$router.push('/evaluations');
    },
    initialize( params ) {
      return new Promise(( resolve, reject ) => {

        var { target, page, filters, order, ascending } = params;
        const id = this.idCampaign;
        this.section = params.section;
        filters = filters.slice();

        if ( target === "Evaluations/all" ) {
          if ( id ) filters.unshift({ field: "campaign", intValue: id });
        }

        if ( target.indexOf('UserEvaluations') !== -1 && this.queryUser ) {
          filters.push({ field: "ids", listValue: [ this.queryUser ]});
        }

        // Data
        this.$store
          .dispatch( 'api', { target, params: { page, filters, order, ascending, id }})
          .then( response => {
            if ( target === 'Evaluations/get' ) resolve({ single: response })
            else resolve( response );
          })
          .catch( reject );
      });
    },
    remove( items, params ) {

      const target = this.isStandard
        ? 'UserEvaluations/removeAll'
        : 'Evaluations/removeEvaluation';

      return this.$store.dispatch( "api", { target, params });
    },
    sanitize( model ) {
      return {
        id: model.id || -1,
        type: model.type,
        frequency: model.frequency,
        idSurvey: model.survey ? model.survey.id : null,
        idCampaign: parseInt( this.$route.params.id ),
        offset: model.offset,
        dayOfWeek: model.dayOfWeek,
        status: 1
      }
    },
    save( params ) {
      if ( this.uses <= 0 ) return this.updateConfig( params );
      return new Promise(( resolve, reject ) => {
        this.setDialog({
          show: true,
          width: 400,
          accept: () => this.updateConfig( params ).then( resolve ).catch( reject ),
          cancel: () => reject('Guardado cancelado'),
          acceptText: 'Guardar',
          title: '¿Confirma que desea guardar los cambios?',
          text: `Este cuestionario está siendo utilizado por ${ this.uses } acciones. `+
                'En caso de modificación se verían modificadas todas estas acciones.'
        });
      });
    },
    updateConfig( params ) {
      return new Promise(( resolve, reject ) => {
        this.loading = true;
        this
          .updateSurvey( params.survey )
          .then( survey => {
            params = this.sanitize({ ...params, survey });
            return this.$store.dispatch( 'api', { target: 'Evaluations/set', params });
          })
          .then( response => {
            const single = this.core.single;
            if ( this.section === '1-0' ) single.grupoOtEvaluation = response;
            else single.companyEvaluation = response;
            this.set({ single });
            this.$store.dispatch( 'console/success', 'Configuraión guardad.' );
            return response;
          })
          .then( resolve )
          .catch( err => {
            console.error( err );
            this.$store.dispatch( 'console/error', 'Ha habido un error desconocido.' );
            reject( err );
          })
          .finally(() => this.loading = false );
      });
    },
    updateSurvey( survey ) {
      return new Promise(( resolve, reject ) => {

        if ( ! survey ) return resolve( survey );
        var response;

        this.$store
          .dispatch( 'api', { target: 'Surveys/set', params: survey })
          .then( res => {
            response = res;
            const toRemove = ( get( survey, 'questions.removing' ) || [] ).filter( a => a.id > 0 && a.status !== -1 );
            return this.removeQuestions( toRemove );
          })
          .then(() => {
            const toSave = this.prepareQuestions( response.id, survey.questions );
            return this.updateQuestions( toSave, 0 );
          })
          .then(() => resolve( response ))
          .catch( reject );
      });
    },
    updateQuestions( questions, index ) {
      return new Promise(( resolve, reject ) => {

        const params = questions[index];
        if ( ! params ) return resolve();

        this.$store
          .dispatch( 'api', { target: 'Surveys/setQuestion', params })
          .then( res => {
            // Change temp ids by real id
            for ( var i = index + 1, q, r; i < questions.length; i++ ) {
              q = questions[i];
              if ( q.parentId === params.qid ) {
                q.parentId = res.id;
                if (( r = params.replies.findIndex( a => a.rid === q.parentReplyId )) !== -1 ) {
                  q.parentReplyId = res.replies[r].id;
                }
              }
            }
            return this.updateQuestions( questions, index + 1 );
          })
          .then( resolve )
          .catch( reject );
      });
    },
    prepareQuestions( idSurvey, data ) {
      return ( data || [] )
        .filter( a => String( a.question || '' ).trim() && a.type >= 0 && a.status !== -1 )
        .map(( question, position ) => {
          const qid = question.id;
          const id = typeof qid === 'number' ? qid : -1;
          return {
            ...question,
            id, qid,
            position,
            idSurvey,
            replies: this.prepareReplies( question.replies, question.type )
          };
        })
        .map( this.questionParent );
    },
    prepareReplies( data, type ) {
      if ([ SurveyQuestionType.CLOSED, SurveyQuestionType.MULTIPLE ].indexOf( type ) < 0 ) return [];
      return ( data || [] )
        .filter( a => String( a.reply || '' ).trim())
        .map(( reply, position ) => {
          const rid = reply.id;
          const id = typeof rid === 'number' ? rid : -1;
          return { ...reply, id, rid, position }
        })
    },
    questionParent( q, index, items ) {

      const parent = items.find( a => a.qid === q.idParent );
      if ( ! parent || ! index ) q.idParent = q.idParentReply = q.parentType = q.parentReplyText = null;
      else if ([ SurveyQuestionType.CLOSED, SurveyQuestionType.MULTIPLE ].indexOf( parent.type ) !== -1 ) {
        if ( q.idParentReply == null ) q.idParent = null;
        else q.parentType = q.parentType || 0;
        q.parentReplyText = parent.replies.find( a => a.rid === q.idParentReply ).reply;
      } else {
        q.parentType = q.idParentReply = q.parentReplyText = null;
      }

      q.parentId = q.idParent;
      q.parentReplyId = q.idParentReply;
      return q;
    },
    removeQuestions( questions ) {
      return Promise.all(
        questions.map( a => this.$store.dispatch( 'api', { target: 'Surveys/removeQuestion', params: { id: a.id }}))
      );
    },
    sanitizeStandard( model ) {
      const data = {};
      Object.keys( model ).forEach( key => {
        if ( model[key] == null ) {
          data[key] = ( key === 'comments' ? '' : -1 )
        } else {
          data[key] = model[key];
        }
      });
      return data;
    },
    sanitizeEvaluation( model ) {

      if ( this.isStandard )
        return this.sanitizeStandard( model );

      var replies = get( model, 'response.changed' );
      if ( ! replies ) replies = model.replies;
      else {
        replies = Object.keys( replies )
          .map( id => ({ idQuestion: parseInt( id ), ...replies[id] }));
      }

      return {
        id: model.id || -1,
        idEvaluation: get( model, 'evaluation.id' ),
        idUser: get( model, 'user.id' ),
        idPlace: get( model, 'place.id' ),
        status: 1,
        valoration: model.value,
        replies: ( replies || [] )
          .map( r => ({
            idQuestion: r.idQuestion,
            response: toArray( r.value ).join(',')
          }))
      }
    },
    update( params ) {
      return this.$store.dispatch( 'api', {
        target: this.isStandard ? 'UserEvaluations/set' : 'Evaluations/setResponse',
        params
      });
    },
    exportData( params ) {
      return new Promise(( resolve, reject ) => {

        params = { ...params };
        params.method = 'export';

        if ( this.isStandard ) {

          params.service = 'UserEvaluations';

          if ( this.idCampaign ) {
            params.method = 'campaignExport';
            params.id = this.idCampaign;
          }

        } else {

          params.service = 'Evaluations';
          params.method = 'export';
          params.filters = ( params.filters || [] ).slice();
          this.idCampaign && params.filters.push({
            field: "campaign",
            intValue: this.idCampaign
          });
        }

        this.$store
          .dispatch( "exportData", params )
          .then( resolve )
          .catch( reject );
      });
    },
    colorValue( value ) {
      return {
        'success--text': value > 7,
        'info--text': value >= 5 && value <= 7,
        'error--text': value && value < 5
      }
    },
    valorationColor( value ) {
      switch ( value ) {
        case 0: return 'error--text';
        case 1: return 'warning--text';
        case 2: return 'warning--text';
        case 3: return 'info--text';
        case 4: return 'success--text';
        default:
          return null;
      }
    },
    contactColor( value ) {
      switch ( value ) {
        case 0: return 'error--text';
        case 1: return 'success--text';
        default:
          return null;
      }
    },
    setTitle() {

      const id = parseInt( this.$route.params.id );
      const title = [ 'Campañas', '', 'Evaluaciones' ];

      if ( ! id ) {
        title.splice( 0, 2 );
        return this.$store.commit( 'setTitle', title );
      }

      this.$store.commit( 'setTitle', title );
      if ( this.breadcrumbs[1].text === '...' && id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    },
     computeItemKey( item ) {
      if ( this.section === '0-0' ) {
        return [
          item.id,
          item.idUser,
          item.idPlace
        ].filter( Boolean ).join('-');
      } else {
        return item.id;
      }
    }

  },
  beforeMount() {
    this.queryUser = this.$route.query && parseInt( this.$route.query.user );
  },
  mounted() {
    this.setTitle();
  }
};
</script>

<style>
.table-rating.v-rating .v-icon {
  padding: 0.2rem;
}
</style>

<template>
  <v-card
    class="timing-graph"
    :style="hasData.all ? {} : { position: 'absolute', top: '-99999px' }"
  >
    <v-row class="mb-2" align="center" no-gutters>

      <DField
        field="select"
        field-style="1"
        class="shrink mr-2"
        v-model="internalPeriod"
        :items="periods"
        dense
      />

      <DField
        field="select"
        field-style="1"
        class="shrink mr-2"
        v-model="internalType"
        :items="types"
        dense
      />

      <DField
        v-if="internalType === 'hours'"
        field="select"
        field-style="1"
        class="shrink mr-2"
        :value="values"
        :items="listValues"
        @input="onInput('values',$event)"
        multiple
        dense
      >
        <template v-slot:item="{ active, item, attrs, on }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            #default="{ active }"
          >
            <v-row
              align="center"
              no-gutters
            >

              <v-simple-checkbox
                class="mr-2"
                :value="active"
                :ripple="false"
                :color="item.color"
              />

              <span>{{ item.text }}</span>

            </v-row>
          </v-list-item>
        </template>
      </DField>

      <DField
        v-if="internalType === 'absences-types'"
        field="select"
        field-style="1"
        class="shrink mr-2"
        :value="absences"
        :items="listAbsences"
        @input="onInput('absences',$event)"
        mandatory
        multiple
        dense
      >
        <template v-slot:item="{ active, item, attrs, on }">
          <v-list-item
            v-on="on"
            v-bind="attrs"
            #default="{ active }"
          >
            <v-row
              align="center"
              no-gutters
            >

              <v-simple-checkbox
                class="mr-2"
                :value="active"
                :ripple="false"
                :color="item.color"
              />

              <span>{{ item.text }}</span>

            </v-row>
          </v-list-item>
        </template>
      </DField>

      <v-spacer/>

      <DField
        field="select"
        field-style="1"
        class="shrink mr-2"
        v-model="internalMode"
        :items="modes"
        dense
      />

    </v-row>

    <DStatsLinear
      ref="graph"
      v-bind="computedProps"
      :type="internalMode"
      min-width="600"
      min="0"
    >
      <template v-slot:info="{ value, item }">
        {{ map[item.key] }}: {{ value }} {{ internalType === 'hours' ? 'h' : '' }}
      </template>
    </DStatsLinear>

  </v-card>
</template>

<script>
import DStatsLinear from "@/components/base/DStatsLinear";
import { AbsenceType, AbsenceSubtype } from "@/utils/constants";
import { round, toArray } from '@/utils';
import { mapState } from 'vuex';
import colors from '@/utils/colors';
import { deepEqual } from 'vuetify/lib/util/helpers';

const ABSENCES_MAP = {
  [AbsenceType.HOLIDAY_WORKING]: "Festivo laboral",
  [AbsenceType.HOLIDAY_NO_WORKING]: "Festivo No laboral",
  [AbsenceType.USER_HOLIDAYS]: "Vacaciones",
  [AbsenceType.LIBRANZA]: "Libranza",
  [AbsenceType.PLACE]: "Centro descubierto",
  [AbsenceType.INCOMPLETE]: "Jornada incompleta",
  [AbsenceType.OTHER]: "Observaciones",
};

const SUBABSENCES_MAP = {
  [AbsenceSubtype.NO_CUBIERTA_PDT_SELECT]: "Pendiente de selección",
  [AbsenceSubtype.NO_CUBIERTA_PDT_CLIENTE]: "Pendiente de cliente",
  [AbsenceSubtype.NO_CUBIERTA_PDT_PDV]: "Pendiente de PDV",
  [AbsenceSubtype.BAJA_VOLUNTARIA]: "Baja voluntaria",
  [AbsenceSubtype.DESPIDO]: "Despido / NSPP",
  [AbsenceSubtype.IMPREVISTO_PERSONAL]: "Imprevisto personal",
  [AbsenceSubtype.ENFERMEDAD]: "Enfermedad",
  [AbsenceSubtype.DOCUMENTATION]: "Problemas documentación",
  [AbsenceSubtype.RETRASO]: "Retraso / Avance jornada",
};

const MAP_ABSENCES = Object.keys( ABSENCES_MAP ).map( absence => {
  let obj = {};
  obj[absence] = ABSENCES_MAP[absence];
  Object.keys( SUBABSENCES_MAP ).map( subtype => {
    obj[`${absence}-${subtype}`] = `${ABSENCES_MAP[absence]}: ${SUBABSENCES_MAP[subtype]}`;
  })
  return obj;
}).reduce(( obj, val ) => Object.assign( obj, val ),{});

const MAP_PERIODS = {
  week: 'Semanal',
  month: 'Mensual',
  year: 'Anual'
};

const MAP_VALUES = {
  worked: 'Trabajado',
  absences: 'Ausencias'
};

const MAP_COLORS = Object.keys( MAP_VALUES )
  .concat( Object.keys( MAP_ABSENCES ))
  .reduce(( obj, key, index ) => {
    obj[key] = colors[ index % colors.length ];
    return obj;
  },{});

export default {
  components: { DStatsLinear },
  props: {
    value: {
      type: Array,
      default: () => Object.keys( MAP_VALUES )
    },
    type: {
      type: String,
      default: 'hours'
    },
    mode: {
      type: String,
      default: 'linear'
    },
    period: {
      type: String,
      default: 'week'
    },
    showFilters: Boolean
  },
  data() {
    return {
      internalMode: this.mode,
      modes: [
        { value: 'linear', text: 'Lineal' },
        { value: 'bars',   text: 'Barras' },
      ],
      internalType: this.type,
      types: [
        { value: 'hours',    text: 'Trabajado' },
        { value: 'absences', text: 'Auséncias' },
        { value: 'absences-types', text: 'Tipos de auséncia' }
      ],
      internalPeriod: this.period,
      listValues: Object.keys( MAP_VALUES ).map( value => ({
        value,
        text: MAP_VALUES[value],
        color: MAP_COLORS[value]
      })),
      listAbsences: [],
      values: this.type === 'hours' ? this.value : Object.keys( MAP_VALUES ),
      absences: [],
      map: {
        ...MAP_VALUES,
        ...MAP_ABSENCES,
        absences: 'Auséncias'
      }
    };
  },
  computed: {
    ...mapState([ 'core' ]),
    computedData() {
      return Object.keys( MAP_PERIODS ).reduce(( obj, key ) => {
        obj[key] = {
          items: this.getData( this.core[key+'Info'] ),
          labels: Object.keys( this.core[key+'Info'] || {}).sort(( a, b ) => {

            a = a.split('-').map( v => parseInt( v ));
            a = (( a[1] || 0) * 100 ) + a[0];

            b = b.split('-').map( v => parseInt( v ));
            b = (( b[1] || 0 ) *100 ) + b[0];

            return a - b;
          }),
        };
        return obj;
      }, {});
    },
    computedProps() {

      const itemKey = toArray(
        this.internalType === 'hours'
        ? this.values
        : this.internalType === 'absences'
          ? ['absences']
          : this.absences );

      return {
        ...this.computedData[ this.internalPeriod ],
        itemKey,
        color: itemKey.map( k => MAP_COLORS[k] )
      };
    },
    periods() {
      return Object.keys( MAP_PERIODS )
        .filter( k => this.hasData[k] )
        .map( value => ({
          value,
          text: MAP_PERIODS[value]
        }))
    },
    hasData() {

      const periods = { ...MAP_PERIODS };
      periods.all = false;

      Object.keys( MAP_PERIODS ).forEach( k => {
        periods[k] = this.computedData[k].items.length > 1;
        periods.all = periods.all || periods[k];
      });

      return periods;
    }
  },
  watch: {
    value( value, oldValue ) {
      if ( deepEqual( value, oldValue )) return;
      if ( this.internalType === 'hours' ) {
        this.values = value.slice();
      } else if ( this.internalType !== 'absences' ) {
        this.absences = value.slice();
      }
    },
    type( type ) {
      this.internalType = type;
    },
    mode( mode ) {
      this.internalMode = mode;
    },
    period( period ) {
      this.internalPeriod = period;
    },
    internalMode: 'onUpdate',
    computedProps( props, old ) {
      if ( deepEqual( props, old )) return;
      this.onUpdate();
    },
    internalType() {
      this.setAbsencesTypes();
    },
    hasData( data, old ) {
      if ( data.all !== old.all ) {
        this.$nextTick( this.refresh );
      }
    },
    showFilters() {
      setTimeout( this.refresh, 500 );
    }
  },
  methods: {
    onUpdate() {
      this.$emit( 'update', {
        mode: this.internalMode,
        type: this.internalType,
        period: this.internalPeriod,
        value: this.computedProps.itemKey.slice()
      });
    },
    getData( data ) {
      if ( ! data ) return [];
      return Object.keys( data ).map( d => ({
        worked: round(( data[d].totalWorkedTime || 0 ) / 60, 1 ),
        estimated: round(( data[d].totalEstimatedTime || 0 ) / 60, 1 ),
        absences: round(( data[d].totalAbsenceTime || 0 ) / 60, 1 ),
        ...data[d].absenceType
      }))
    },
    onInput( type, value ) {
      if ( Array.isArray( value )) {
        if ( value.length ) {
          this[type] = value;
        } else {
          this[type] = this[type].slice();
        }
      } else {
        this[type] = value;
      }
    },
    setAbsencesTypes( value ) {
      if ( this.internalType === 'absences-types' ) {

        const data = this.computedData[ this.internalPeriod ];
        const absences = [];

        data.items.forEach( item => {
          Object.keys( item ).forEach( key => {
            if ( !isNaN(+key.charAt(0)) && !absences.includes(key)) {
              absences.push(key);
            }
          });
        });

        this.absences = value || absences;
        this.listAbsences = absences.map( key => ({
          value: key,
          text: MAP_ABSENCES[key],
          color: MAP_COLORS[key]
        }));
      }
    },
    refresh() {
      this.$refs.graph && this.$refs.graph.onResize();
    }
  },
  beforeMount() {
    this.setAbsencesTypes( this.value );
  }
}
</script>

<style>
.timing-graph {
  flex: 1 1 auto;
}
.timing-graph .v-input--checkbox .v-input--selection-controls__input {
  margin-right: 0;
}
.timing-graph .v-input--checkbox .v-label {
  font-size: 12px !important;
}
</style>

<template>
  <DForm :value="value" v-on="$listeners">
    <DField key="name" v-bind="name" class="mb-6"/>
    <DField key="parent" v-bind="parent"/>
  </DForm>
</template>

<script>

  export default {
    props: {
      value: Object,
      readonly: Boolean
    },
    data: () => ({
      name: {
        field: 'text',
        fieldStyle: 1,
        label: 'Nombre',
        dense: true,
        rules: [ v => !!v || 'El campo es requerido.' ]
      }
    }),
    computed: {
      parent() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Cadena padre',
          service: 'Channels',
          itemValue: 'id',
          itemText: 'name',
          exclude: { id: this.value ? this.value.id : null },
          dense: true
        }
      }
    }
  }
</script>

<template>
  <Table
    service="KpiCenter"
    :messages="messages"
    :sanitize="sanitize"
    :headers="headers"
    :params="params"
    :mypopup="mypopup"
    show-actions="edit,new,duplicate,export,import,delete"
  >

    <template v-slot:form="{ value, listeners }">
      <KpiCentersForm :value="value" v-on="listeners"/>
    </template>

  </Table>
</template>

<script>
import Table from '@/components/core/Table.vue';
import KpiCentersForm from '@/components/forms/Kpi/centers.vue';
import { mapState } from 'vuex';
import { direction, userName, formatDate, round } from '@/utils';

function displayDate( value ) {
  if ( value == null ) return '';
  return formatDate( value, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

function displayCurrentUnits(_,__,item){
  return round(item.currentUnits, 2 ) || 0;
}

function displayRealized( _, __, item ) {

  const kpi = {}/*item.kpi*/;
  const percent = round(( item.currentUnits / item.units ) * 100, 2 ) || 0;
  const min = kpi.minLevel == null ? 80 : kpi.minLevel;
  const mid = kpi.mediumLevel == null ? 90 : kpi.minLevel;
  const max = kpi.maxLevel == null ? 100 : kpi.minLevel;

  const type = percent < min
    ? 'error'
    : percent < mid
      ? 'warning'
      : percent < max
        ? 'info'
        : 'success';

  return `<span class="${type}--text">${percent}%</span>`;
}

function computeRangeDate( value, field ) {
  if ( !value[0] || !value[1] ) return {[field]: null };
  return {[field]: { start: value[0], end: value[1] }};
}

const RANGEDATE_FILTER = {
  type: 'rangedate',
  compute: computeRangeDate
}

export default {
  components: { Table, KpiCentersForm },
  data() {
    return {
      messages: { item: 'Objetivo/Objetivos' },
      mypopup: {
        height: 460,
        title: n => n ? 'Asignar objetivo' : 'Editar objetivo'
      }
    }
  },
  computed: {
    ...mapState([ 'breadcrumbs' ]),
    campaignId() {
      return parseInt( this.$route.params.id );
    },
    centerId() {
      return parseInt( this.$route.params.index );
    },
    headers() {
      return [ !this.centerId && {
        value: 'center',
        text: 'Centro',
        display: v => `${v.id}. ${direction( v.place, true )}`
      },{
        value: 'user',
        text: 'Promotor',
        display: (v,h,i) => userName( i.center.user, true )
      },{
        value: 'kpi',
        text: 'KPI',
        display: v => `${v.id}. ${v.title}`
      },{
        value: 'units',
        text: 'Objetivo',
        width: 150,
        filter: { type: 'number', attrs: { min: 0, hideControls: true }}
      },{
        value: 'currentUnits',
        text: 'Informado',
        display: displayCurrentUnits,
        width: 150,
        filter: { type: 'number', attrs: { min: 0, hideControls: true }}
      },{
        value: 'realUnits',
        text: 'Real',
        width: 150,
        filter: { type: 'number', attrs: { min: 0, hideControls: true }}
      },{
        value: 'startDate',
        text: 'Fecha de inicio',
        display: displayDate,
        width: 150,
        filter: RANGEDATE_FILTER
      },{
        value: 'endDate',
        text: 'Fecha de fin',
        display: displayDate,
        width: 150,
        filter: RANGEDATE_FILTER
      },{
        value: 'realiced',
        text: 'Realizado',
        display: displayRealized,
        width: 165,
        filterable: false,
        sorteable: false
      }].filter( Boolean );
    },
    params() {
      return {
        filters:[{
          field: 'campaign',
          intValue: this.campaignId
        }, this.centerId && {
          field: 'center',
          intValue: this.centerId
        }].filter( Boolean )
      }
    }
  },
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    }
  },
  methods: {
    sanitize( model ) {
      return {
        id: model.id || -1,
        kpi: model.kpi ? model.kpi.id : null,
        center: model.center ? model.center.id : null,
        startDate: model.startDate,
        endDate: model.endDate,
        units: model.units
      };
    },
    setTitle() {

      const id = this.campaignId;
      const cid = this.centerId;

      let title = [ 'Campañas', '' ];
      cid && ( title = title.concat([ 'Centros', '' ]));
      title.push('Objetivos');
      !cid && title.push('Centros');

      this.$store.commit( 'setTitle', title );

      if ( id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          if ( cid ) {
            let center = res.centers.find( c => c.id === cid );
            if ( center ) title[3] = `${center.id}. ${center.place.name}`;
          }
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle()
  }
}
</script>

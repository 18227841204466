<template>
  <v-text-field
    :class="size"
    :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
    :type="show ? 'text' : 'password'"
    :autocomplete="autocomplete"
    v-bind="attr"
    v-on="$listeners"
    @click:append="show = !show"
    color="secondary"
  >
    <label v-if="attr.preLabel" slot="prepend" class="label" :style="labelStyle">
      {{ label }}
    </label>
  </v-text-field>
</template>

<script>

  import { formatSize } from '@/utils';

  export default {
    nullValues: '',
    props: {
      label: String,
      labelSpace: [ Number, String ],
      fieldStyle: [ Number, String ],
      small: Boolean,
      autocomplete: {
        type: String,
        default: 'current-password'
      }
    },
    data: () => ({
      show: false
    }),
    computed: {
      size() {
        return {
          'dfield-text': true,
          'small': !!this.small
        }
      },
      attr() {
        switch ( String( this.fieldStyle )) {
          case '1':
            return {
              ...this.$attrs,
              backgroundColor: 'white',
              label: this.label,
              outlined: true
            };
          case '2':
            return {
              ...this.$attrs,
              preLabel: true,
              dense: true
            };
        }
        return this.$attrs;
      },
      labelStyle() {
        const space = this.labelSpace;
        return { width: formatSize( space ) };
      }
    }
  }
</script>

import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { WEBSOCKET_URL } from '@/utils/constants.js';

export default {
  on({ state, commit }) {
    return new Promise(( resolve, reject ) => {

      if ( state.connected )
        return resolve({
          socket: state.socket,
          stomp:  state.stomp,
          frame:  state.frame
        });

      const socket  = new SockJS( WEBSOCKET_URL);
      const stomp   = Stomp.over( socket );

      //const stomp   = Stomp.client( WEBSOCKET_URL , protocols = ['v10.stomp', 'v11.stomp', 'v12.stomp']);
      // Add the following if you need automatic reconnect (delay is in milli seconds)
      stomp.reconnect_delay = 5000;

      stomp.connect( {}, frame => {
        const response = { socket, stomp, frame, connected: true };
        commit( 'set', response );
        resolve( response );
      }, reject );

    });
  },
  off({ state, commit }) {
    if ( state.connected ) {
      state.stomp.disconnect();
      commit( 'set', {
        connected: false,
        socket: null,
        stomp: null,
        frame: null,
        info: {}
      });
    }
  },
  subscribe({ state, dispatch }, topics ) {

    //Lo utilizareos para conectarnos a notificaciones temporales.

    //Se creará un topic por chat (chat-1, chat-2 ...) con el fin de que cuando estemos con
    //el chat abierto podamos recibir notificaciones de lo que se está hablando.

    if ( ! state.connected ) {
        setTimeout(() => {
          dispatch('subscribe', topics);
        }, 1000 );
      return false;
    }

    for ( var topic in topics ) {

      dispatch( 'unsubscribe', `/topic/${ topic }`);

      state.subscriptions[topic] = state.stomp.subscribe( `/topic/${ topic }`, tick => {
        
        var topic = tick.headers.destination.substring("/topic/".length);
        const response = { topic, data: JSON.parse( tick.body ) };
        topics[topic]( response );

      });
    }

    return true;
  },
  unsubscribe({ state }, topic ) {
    if ( ! state.connected ) return false;
    var sId = state.subscriptions[topic];
    if ( sId ) {
      sId.unsubscribe();
      state.subscriptions[topic] = null;
    }
  },
};

<template>
  <v-row
    :key="key"
    :class="{ 'calendar-weekday-input': 1, 'has-error': hasError }"
  >
    <v-col v-for="day in weekdays" :key="day.text" :style="width">
      <div>
        <Card
          class="d-flex align-center justify-center mb-4"
          height="75" border
          :dark="model[day.value].active"
          :color="model[day.value].active ? 'primary': ''"
          @click="toggle( day.value )"
        >
          <span class="headline">
            {{ day.text }}
          </span>
        </Card>
        <div v-if="!allDay && model[day.value].active">
          <div v-for="( interval, i ) in model[day.value].intervals" :key="i">

            <DField
              v-bind="start"
              class="mb-6"
              :value="interval.start"
              :max="interval.end"
              @input="input( interval, 'start', $event )"
            />

            <DField
              v-bind="end"
              :value="interval.end"
              :min="interval.start"
              @input="input( interval, 'end', $event )"
            />

            <v-checkbox
              v-if="!i"
              :input-value="model[day.value].intervals.length > ( i + 1 )"
              @change="split( day.value, i + 1, $event )"
              class="body-grupo-ot"
              color="primary"
              label="Jornada partida"
            />

          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>

  const weekdays = [
    { value: 1, text: 'L' },
    { value: 2, text: 'M' },
    { value: 3, text: 'X' },
    { value: 4, text: 'J' },
    { value: 5, text: 'V' },
    { value: 6, text: 'S' },
    { value: 0, text: 'D' }
  ];

  const columnWidth = {
    maxWidth: `${ 100 / 7 }%`,
    flex: `1 1 ${ 100 / 7 }%`
  }

  function compute( value ) {
    value = ( value || [] ).slice();
    for ( var i = 0; i < 7; i++ ) {
      value[i] = { ...value[i] };
      value[i].intervals = ( value[i].intervals || [] ).slice();
      value[i].intervals[0] = { start: null, end: null, ...value[i].intervals[0] };
    }
    return value;
  }

  export default {
    props: {
      value: Array,
      allDay: Boolean
    },
    data() {
      return {
        key: 0,
        model: compute( this.value ),
        width: columnWidth,
        hasError: false,
        weekdays
      }
    },
    watch: {
      value( value ) {
        this.model = compute( value );
      }
    },
    computed: {
      start() {
        return {
          field: 'time',
          label: 'Hora de entrada',
          fieldStyle: 1,
          dense: true
        }
      },
      end() {
        return {
          field: 'time',
          fieldStyle: 1,
          dense: true,
          label: 'Hora de salida'
        }
      }
    },
    methods: {
      toggle( index ) {
        this.hasError = false;
        this.model[index].active = !this.model[index].active
        this.key++;
        this.$emit( 'input', this.model );
      },
      split( index, pos, value ) {
        this.hasError = false;
        const { intervals } = this.model[index];
        if ( value ) intervals[pos] = { start: null, end: null };
        else intervals.splice( pos, intervals.length - pos );
        this.key++;
        this.$emit( 'input', this.model );
      },
      input( interval, key, value ) {
        this.hasError = false;
        interval[key] = value;
        this.$emit( 'input', this.model );
      },
      validate() {
        var times = 0;
        this.model.forEach( day => {
          if ( day.active && day[0] && day[0].start && day[0].end ) times++;
        });
        this.hasError = !times;
      },
      reset() {
        this.hasError = false;
      }
    }
  }
</script>

<style>
.calendar-weekday-input.has-error {
  outline: 1px solid var(--v-error-base);
  outline-offset: -5px;
}
</style>

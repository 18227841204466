<template>
  <DForm
    ref="form"
    v-bind="$attrs"
    v-on="$listeners"
  >

    <DField
      key="type"
      field="select"
      field-style="1"
      label="Tipo"
      :items="types"
      :rules="rules.type"
      class="mb-6"
      dense
    />

    <DField
      key="date"
      field="date"
      field-style="1"
      label="Fecha"
      :rules="rules.date"
      class="mb-6"
      dense
    />

    <DField
      key="name"
      field="text"
      field-style="1"
      label="Nombre"
      :rules="rules.name"
      class="mb-6"
      clearable
      dense
    />

    <DField key="postalCodes">
      <template v-slot:default="{ value, on }">
        <DSteps
          ref="steps"
          title="Codigos postales"
          :value="value"
          :expandible="false"
          :add="addPostalCode"
          v-on="on"
        >
          <template v-slot:header="{ index, item, input }">
            <DField
              field="text"
              field-style="1"
              :value="item"
              @input="input( index, null, $event )"
              dense
            />
          </template>
          <template v-slot:actions="{ canAdd }">
            <Btn
              v-if="canAdd"
              class="ml-2"
              color="tertiary"
              title="Añadir en lote"
              @click="dialog = true"
              icon
            >
              <v-icon class="ml-1">mdi-playlist-plus</v-icon>
            </Btn>
          </template>
        </DSteps>
      </template>
    </DField>

    <v-dialog v-model="dialog" max-width="640">
      <Card class="rel" height="430">
        <v-row class="flex-column fill-height" no-gutters>
          <Toolbar color="secondary" dark>

            <h3>Añadir en lote</h3>

            <v-spacer/>

            <Btn color="white" width="20" height="20" fab light @click="dialog = !dialog">
              <v-icon small>$close</v-icon>
            </Btn>

          </Toolbar>
          <div class="grow rel">
            <div class="layer autoscroll pa-4">

              <p>Escribe una lista de codigos postales separados por linea.</p>

              <DField
                v-model="lote"
                field="textarea"
                field-style="1"
                rows="10"
                hide-details
                no-resize
              />

            </div>
          </div>
          <Toolbar border-top>

            <v-spacer/>

            <Btn class="mr-4" color="white" @click="dialog = !dialog">
              Cancelar
            </Btn>

            <Btn color="primary" @click="addLote">
              Guardar
            </Btn>

          </Toolbar>
        </v-row>
      </Card>
    </v-dialog>

  </DForm>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      lote: '',
      types: [
        { text: 'No laboral', value: 0 },
        { text: 'Laboral', value: 1 },
      ]
    }),
    computed: {
      rules() {
        return {
          type: [ v => v != null || 'Debes indicar el tipo de festivo.' ],
          date: [ v => v != null || 'Debes indicar la fecha.' ],
          name: [ v => !!v || 'Ingresa un nombre de Festivo.' ]
        }
      }
    },
    watch: {
      dialog() {
        this.lote = '';
      }
    },
    methods: {
      addPostalCode( value, model ) {
        model.push( value || '' );
        return model;
      },
      resetValidation() {
        return this.$refs.form.resetValidation();
      },
      addLote() {

        this.dialog = false;
        const { steps } = this.$refs;
        const codes = this.lote.split(/\n+/)
          .map( s => s.trim())
          .filter( Boolean );

        if ( codes.length && steps ) {
          codes.forEach( steps.onadd );
        }
      }
    }
  }
</script>

<template>
  <div class="fill-width">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="date"
      :persistent="persistent"
      width="608px"
    >
      <template v-slot:activator="{ on }">
        <DFieldText
          field="text"
          :value="output"
          :label="label"
          :placeholder="placeholder"
          :label-space="labelSpace"
          :field-style="fieldStyle"
          :small="small"
          :dense="dense"
          :rules="compRules"
          :clearable="clearable && !readonly"
          @click:clear="save()"
          v-on="readonly ? null : on"
          readonly
        />
      </template>
      <Card class="row no-gutters pa-2">
        <v-col cols="6" class="pr-1">
          <v-date-picker
            v-model="iso[0]"
            v-bind="$attrs"
            :locale="locale"
            :min="compMin[0]"
            :max="compMax[0] || compMax[1]"
            first-day-of-week="1"
          />
        </v-col>
        <v-col cols="6" class="pl-1">
          <v-date-picker
            v-model="iso[1]"
            v-bind="$attrs"
            :locale="locale"
            :min="compMin[1] || compMin[0]"
            :max="compMax[1]"
            first-day-of-week="1"
          />
        </v-col>
      </Card>
      <Toolbar border-top>
        <v-spacer></v-spacer>
        <Btn text color="primary" @click="dialog = false">Cancelar</Btn>
        <Btn color="primary" :disabled="!canSave" @click="save(iso)">OK</Btn>
      </Toolbar>
    </v-dialog>
  </div>
</template>

<script>

import DFieldText from './DFieldText.vue';
import { Format, toISO } from '@/utils/time';
import { toArray } from '@/utils';

function compute( value ) {
  switch ( typeof value ) {
    case 'string':
      return value.split(/\s*-\s*/);
    case 'object':
      if ( Array.isArray( value )) return value;
      return value ? [ value.start, value.end ] : [];
    default:
      return toArray( value );
  }
}

function toTime( value ) {
  return compute( value )
    .map( v => v ? new Date( v ).getTime() : null );
}

function _toISO( value, withTime ) {
  return compute( value )
    .map( v => toISO( v, withTime ) || '' );
}

function output( value ) {
  const opt = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return compute( value )
    .map( v => v ? Format( v, opt ) : '' )
    .filter( a => a )
    .join(' - ');
}

export default {
  //nullValues: '',
  components: { DFieldText },
  props: {
    value: null,
    locale: { type: String, default: navigator.language },
    label: String,
    labelSpace: [ Number, String ],
    fieldStyle: [ Number, String ],
    min: [ Number, String ],
    max: [ Number, String ],
    small: Boolean,
    dense: Boolean,
    persistent: Boolean,
    readonly: Boolean,
    rules: Array,
    placeholder: String,
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      date: compute( this.value ),
      iso: _toISO( this.value ),
      output: output( this.value ),
    };
  },
  computed: {
    compRules() {
      if ( ! this.rules ) return [];
      return this.rules.map( f => () => f( this.date ));
    },
    compMin() {
      return [
        this.min != null ? toISO( parseInt( this.min )) : null,
        this.iso[0] || null
      ];
    },
    compMax() {
      return [
        this.iso[1] || null,
        this.max != null ? toISO( parseInt( this.max )) : null
      ];
    },
    canSave() {
      return this.iso[0] && this.iso[1];
    }
  },
  watch: {
    value( value, oldValue ) {
      if ( oldValue && value && ( oldValue[0] !== value[0] || oldValue[1] !== value[1] )) {
        this.refresh( value );
        this.$emit( 'input', this.date );
      }
    }
  },
  methods: {
    refresh( date ) {
      this.iso = _toISO( date );
      this.date = toTime( this.iso );
      this.output = output( this.iso );
    },
    save( date ) {
      this.refresh( date );
      this.$refs.dialog.save( this.iso );
      this.$emit( 'input', this.date );
      this.$emit( 'blur' );
    }
  }
};
</script>

<template>
  <DForm :value="value" v-on="$listeners">
    <v-row class="fill-height flex-column" no-gutters>
      <Toolbar border-bottom>

        <div style="width:240px;">
          <Tabs
            v-model="tab"
            :items="tabs"
            height="51"
          />
        </div>

        <v-spacer/>

        <div style="width:150px;max-width:150px;" @click="hidePendingOptions">
          <DField key="status" v-bind="status"/>
        </div>

        <div v-if="!isClient" style="width:150px;max-width:150px;" class="mx-3" @click="hidePendingOptions">
          <DField key="clientVisible" v-bind="clientVisible"/>
        </div>

      </Toolbar>
      <div v-if="value" class="grow rel">
        <div v-if="!tab" class="layer autoscroll pa-4">
          <div class="pa-2 mb-4">

            <p class="subtitle-2 mb-1">
              <span class="tertiary--text">Prioridad:</span>
              <span class="font-weight-normal ml-1 info--text" v-if="value.priority == 0">BAJA</span>
              <span class="font-weight-normal ml-1 success--text" v-if="value.priority == 1">NORMAL</span>
              <span class="font-weight-normal ml-1 error--text" v-if="value.priority == 2">ALTA</span>
            </p>
            <p class="subtitle-2 mb-1">
              <span class="tertiary--text">Tipo:</span>
              <span class="font-weight-normal ml-1">{{ types[value.type] }}</span>
            </p>
            <p v-if="value.issueCategory" class="subtitle-2 mb-1">
              <span class="tertiary--text">Categoría:</span>
              <span class="font-weight-normal ml-1">{{
                value.issueCategory.parent
                  ? value.issueCategory.parent.name
                  : value.issueCategory.name
              }}</span>
            </p>

            <p v-if="value.issueCategory.parent" class="subtitle-2 mb-1">
              <span class="tertiary--text">SubCategoría:</span>
              <span class="font-weight-normal ml-1">{{
                value.issueCategory.name
              }}</span>
            </p>

            <p v-if="value.place" class="subtitle-2 mb-1">
              <span class="tertiary--text">Centro:</span>
              <span class="font-weight-normal ml-1">
                {{ direction( value.place ) }}
              </span>
            </p>

            <p class="subtitle-2 tertiary--text mb-2">Descripción:</p>
            <div>{{ value.description }}</div>

          </div>
          <template v-for="( item, index ) in computeHistory( value.history )">
            <div :key="index" v-if="item.comments || item.statusChanged">
              <v-divider/>
              <v-row class="flex-nowrap pa-2" align="center" no-gutters>

                <div style="flex: 0 0 175px;">
                  <router-link class="caption mr-1" :to="`/users/${ item.user.id }`">
                    {{ userName( item.user, true ) }}
                  </router-link>
                  <p class="caption mb-0">
                    {{ date( item.date ) }} - {{ time( item.date ) }}
                  </p>
                </div>

                <div class="body-2 fill-width">
                  {{ item.comments }}
                </div>

                <div class="body-2" style="flex: 0 0 175px;">
                  <p v-if="item.statusChanged" class="mb-0">
                    Cambió el estado a <span class="tertiary--text">{{ statuses[ item.status ] }}</span>
                  </p>
                </div>

              </v-row>
            </div>
          </template>
        </div>
        <div v-else class="layer autoscroll pa-4">
          <DGallery
            :items="images"
            item-image="url"
            cols="2" sm="3" md="4" lg="5" xl="6"
            @click-item="showImage"
          />
          <v-dialog v-model="gallery" transition="fade-transition" fullscreen>

            <DSlider
              v-model="index"
              item-image="url"
              :items="images"
              @close="gallery = false"
            />

          </v-dialog>
        </div>
      </div>
      <v-divider/>
      <v-fade-transition group leave-absolute>
        <div v-if="!tab" class="d-flex pa-2" key="desc">

          <DField key="editorComments" v-bind="comments"/>

          <div class="pl-2">
            <Btn color="primary" @click="$emit('save')">
              Guardar
            </Btn>
          </div>

        </div>
        <div v-if="tab" class="d-flex pa-2" key="pics">

          <v-spacer/>

          <Btn color="tertiary" @click="$emit('download')" dark>
            Descargar
          </Btn>

        </div>
      </v-fade-transition>
    </v-row>
  </DForm>
</template>

<script>

  import { formatDate, userName, direction } from '@/utils';
  import { IssueStatus } from '@/utils/constants';

  const statuses = {
    [IssueStatus.CREATED]       : 'Abierta',
    [IssueStatus.TRAMITED]     : 'En trámite',
    [IssueStatus.MORE_INFO]  : 'Ampliar información',
    [IssueStatus.RESOLUTION]  : 'Gestión para resolución',
    [IssueStatus.CLOSED]        : 'Cerrada',
  };

  function date( value ) {
    return formatDate( value, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit' }
    );
  }

  function time( value ) {
    return formatDate( value, {
      hour: 'numeric',
      minute: '2-digit' }
    );
  }

  export default {
    props: {
      value: Object,
      readonly: Boolean
    },
    data: () => ({
      statuses,
      types: [ 'Incidencia', 'Alerta' ],
      gallery: false,
      image: null,
      index: 0,
      tab: 0
    }),
    computed: {
      filteredStatus(){

        /*if(this.isClient && this.value){
        // if(this.value){
          var keys = Object.keys( statuses );
          keys = keys.filter(v => v >= this.value.status);
          return keys.map( v => ({
            value: parseInt( v ),
            text: statuses[v]
          }));
        }*/
        return Object.keys( statuses ).map( v => ({
            value: parseInt( v ),
            text: statuses[v]
          }));
      },
      isClient(){
        return this.$store.state.isClient;
      },
      status() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Estado',
          dense: true,
          items: this.filteredStatus,
          readonly: false
        }
      },
      clientVisible() {
        return {
          field: 'checkbox',
          fieldStyle: 1,
          label: 'Vista cliente',
          hideDetails: true,
          dense: true
        }
      },
      comments() {
        return {
          field: 'textarea',
          fieldStyle: 1,
          placeholder: 'Escribe un comentario...',
          rows: 1,
          hideDetails: true,
          noResize: true,
          autoGrow: true,
          dense: true
        }
      },
      images() {
        return this.value ? this.value.pictures : [];
      },
      tabs() {
        const tabs = [{ text: 'Descripción' }];
        if ( this.images.length ) tabs.push({ text: 'Fotos' });
        return tabs;
      }
    },
    methods: {
      date,
      time,
      userName,
      direction,
      computeHistory( items ) {
        for ( var i = items.length - 1; i >= 0; i-- )
          items[i].statusChanged = !items[i+1] || items[i].status !== items[i+1].status;
        return items;
      },
      showImage(item) {
        if (item) {
          this.image = item.data;
          this.index = item.index;
          this.gallery = true;
        }
      },
      hidePendingOptions() {
       /* const div = document.querySelector('.v-menu__content > div');
        if ( div ) {
          if ( div.children[2] ) div.children[2].style.display = 'none';
          if ( div.children[3] ) div.children[3].style.display = 'none';
        }*/
      }
    }
  }
</script>

<style>
  .issue-comments textarea {
    padding-right: 100px !important;
  }
  .issue-desc-label {
    word-break: normal;
  }

</style>

import APIService from './class';

class Stock extends APIService {
  constructor() {
    super( '/stock' );
  }
  promoter( token, params, options ) {
    return this.action( 'post', '/promoter/page', params, options, token );
  }
  promoterExport( token, params, options ) {
    return this.action( 'post', '/promoter/export', params, options, token );
  }
}

export default new Stock();

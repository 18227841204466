<template>
  <v-text-field
    v-bind="attr"
    v-on="$listeners"
    color="secondary"
    :class="size"
    :autocomplete="autofill"
  >
    <label v-if="attr.preLabel" slot="prepend" class="label" :style="labelStyle">
      {{ label }}
    </label>
    <slot v-for="( s, name ) in $slots" :name="name"/>
    <template v-for="( s, name ) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-text-field>
</template>

<script>

  import { formatSize } from '@/utils';

  export default {
    nullValues: '',
    props: {
      label: String,
      autocomplete: String,
      labelSpace: [ Number, String ],
      fieldStyle: [ Number, String ],
      small: Boolean
    },
    computed: {
      autofill() {
        if ( this.autocomplete != null ) return this.autocomplete;
        return ( this.label || '' ).toLowerCase();
      },
      size() {
        return {
          'dfield-text': true,
          'small': !!this.small
        }
      },
      attr() {
        switch ( String( this.fieldStyle )) {
          case '1':
            return {
              ...this.$attrs,
              backgroundColor: 'white',
              label: this.label,
              outlined: true
            };
          case '2':
            return {
              ...this.$attrs,
              preLabel: true,
              dense: true
            };
        }
        return this.$attrs;
      },
      labelStyle() {
        const space = this.labelSpace;
        return { width: formatSize( space ) };
      }
    }
  }
</script>

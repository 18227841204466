import APIService from './class';

class Stats extends APIService {
  constructor() {
    super( '/stats' );
  }
  get( token, params, options ) {
    return this.action( 'post', '/get', params, options, token );
  }
  getDate( token, params, options ) {
    return this.action( 'post', '/get/date', params, options, token );
  }
  today( token, params, options ) {
    return this.action( 'post', '/today/page', params, options, token );
  }
  todayExport( token, params, options ) {
    return this.action( 'post', '/today/export', params, options, token );
  }
  past( token, params, options ) {
    return this.action( 'post', '/past/page', params, options, token );
  }
  history( token, params, options ) {
    return this.action( 'post', '/history/page', params, options, token );
  }
  future( token, params, options ) {
    return this.action( 'post', '/future/page', params, options, token );
  }
  centers( token, params, options ) {
    return this.action( 'post', '/centers/page', params, options, token );
  }
  validate( token, params, options ) {
    return this.action( 'post', '/validate', params, options, token );
  }
  requestGeo( token, params, options ) {
    return this.action( 'post', '/geo', params, options, token );
  }
  sendPush( token, params, options ) {
    return this.action( 'post', '/push', params, options, token );
  }
}

export default new Stats();

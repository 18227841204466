<template>
  <v-checkbox
    v-model="model"
    :ripple="false"
    v-bind="$attrs"
  />
</template>

<script>

  import { Any } from '@/utils';

  export default {
    props: { value: Any },
    data() {
      return {
        model: !!this.value
      }
    },
    watch: {
      value( value ) {
        this.model = !!value;
      },
      model( value ) {
        this.$emit( 'input', value );
      }
    }
  }
</script>

import APIService from './class';

class Surveys extends APIService {
  constructor() {
    super( '/survey' );
  }
  get( token, params, options ) {
    params = { page: 1, ...params };
    return this.action( 'get', `/get?id=${ params.id }`, params, options, token );
  }
  all( token, params, options ) {
    return this.action( 'post', '/get', params, options, token );
  }
  setQuestion( token, params, options ) {
    return this.action( 'post', '/question/set', params, options, token );
  }
  removeQuestion( token, params, options ) {
    return this.action( 'post', '/question/remove', params, options, token );
  }
}

export default new Surveys();

<template>
  <DForm
    ref="form"
    class="pa-4"
    :value="model"
    v-on="{ ...$listeners, input }"
  >

    <DField
      v-if="!assignMode"
      key="kpi"
      field="select"
      fieldStyle="1"
      class="mb-6"
      label="Objetivo"
      service="Kpi"
      item-value="id"
      item-search="name"
      item-text="name"
      :rules="rules.kpi"
      returnObject
      dense
    />

    <DField
      v-if="!assignMode"
      key="center"
      field="autocomplete"
      fieldStyle="1"
      class="mb-6"
      label="Centro"
      service="Campaigns"
      method="centers"
      item-value="id"
      item-search="place"
      item-text="place.name"
      :display="centerDisplay"
      :params="params"
      :rules="rules.center"
      returnObject
      dense
    />

    <DField
      key="startDate"
      field="date"
      fieldStyle="1"
      class="mb-6"
      label="Empieza"
      :max="end"
      :rules="rules.start"
      dense
    />

    <DField
      key="endDate"
      field="date"
      fieldStyle="1"
      class="mb-6"
      label="Termina"
      :min="start"
      :rules="rules.end"
      dense
    />

    <DField
      v-bind="unitsProps"
      key="units"
      field="number"
      fieldStyle="1"
      class="mb-6"
      min="1"
      dense
    />

    <DField v-if="assignMode" key="centers">
      <template v-slot="{ value, on }">
        <CenterSelector
          :value="value"
          :campaign-id="campaignId"
          v-on="on"
        />
      </template>
    </DField>

  </DForm>
</template>

<script>
import CenterSelector from '../Centers/selector';
import { direction, userName } from '@/utils';
import { required } from '@/utils/rules';

export default {
  components: { CenterSelector },
  props: {
    value: Object,
    assignMode: Boolean
  },
  data() {
    const model = this.value || {};
    return {
      model,
      start: model.start,
      end: model.end
    }
  },
  computed: {
    campaignId() {
      return parseInt( this.$route.params.id );
    },
    params() {
      return {
        filters: [{
          field: 'campaign',
          intValue: this.campaignId
        }]
      };
    },
    rules() {
      return {
        kpi: [ required('Selecciona un objetivo.') ],
        center: [ required('Selecciona el centro al que se le asignará el objetivo.') ],
        start: [ required('Debes añadir una fecha de inicio.') ],
        end: [ required('Debes añadir una fecha de finalización.') ],
        units: [ required('Debes añadir el numero de unidades.') ]
      };
    },
    unitsProps() {
      return this.assignMode
        ? { label: 'Unidades (Opcional)' }
        : { label: 'Unidades', rules: this.rules.units };
    }
  },
  watch: {
    value( model ) {
      this.model = model || {};
      this.start = this.model.startDate;
      this.end = this.model.endDate;
      this.$refs.form.resetValidation();
    }
  },
  methods: {
    centerDisplay( item ) {
      return `${userName(item.user,true)} - ${direction( item.place )}`;
    },
    input( model ) {
      this.model = model;
      this.start = model.startDate;
      this.end = model.endDate;
      this.$listeners.input && this.$listeners.input( model );
    }
  }
};
</script>

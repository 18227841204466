<template>
  <DForm :value="value" v-on="$listeners">
    <div class="mb-6" style="max-width: 960px;">

      <DField key="name" v-bind="name" class="mb-6" />

      <DField key="campaign" v-bind="campaign" class="mb-6" />

      <Channel key="channel" v-bind="channel" class="mb-6" />

      <BrandSelector key="brand" v-bind="brand" class="mb-6" />

      <SectorSelector key="sector" v-bind="sector" class="mb-6" />

      <DField key="places">
        <template v-slot="{ value, on }">
          <Places
            :value="value"
            :expandible="false"
            v-on="on"
          />
        </template>
      </DField>

    </div>
    <DField key="products">
      <template v-slot="{ value, on }">
        <Card border>
          <Products
            title="Productos"
            :value="value"
            v-on="on"
            cols="2" sm="3" md="4" lg="6" xl="9"
          />
        </Card>
      </template>
    </DField>
  </DForm>
</template>

<script>
import Places from "../Places";
import Channel from "../Channels/selector";
import Products from "../Products/selector.vue";
import BrandSelector from "@/components/forms/Brands/selector.vue";
import SectorSelector from "@/components/forms/Sectors/selector.vue";

export default {
  components: { Places, Channel, Products, SectorSelector, BrandSelector },
  props: { value: Object },
  computed: {
    name() {
      return {
        field: "text",
        fieldStyle: 1,
        label: "Nombre",
        dense: true
      };
    },
    channel() {
      return {
        fieldStyle: 1,
        label: "Cadena",
        dense: true
      };
    },
    brand() {
      return {
        fieldStyle: 1,
        label: "Marca",
        dense: true
      }
    },
    sector() {
      return {
        fieldStyle: 1,
        label: "Sector",
        field: "autocomplete",
        dense: true
      }
    },
    campaign() {
      return {
        field: "autocomplete",
        service: "Campaigns",
        label: "Campaña",
        itemText: "title",
        itemValue: "id",
        fieldStyle: 1,
        display: a => `${a.id}. ${a.title || a.name}`,
        dense: true
      }
    },
  }
};
</script>

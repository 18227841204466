


export const CampaignModule = {
  CENTERS : 0, REPORTING : 1, REPORTS : 2, PRODUCT : 3, PICTURES : 4, CALENDAR : 5,
  LOCATION : 6, EXPENSES : 7, FILES : 8, CLIENT_VISION : 9, EVALUATIONS : 10, CAMPAIGN_ISSUE_CATEGORIES : 11, 
  WORKERS: 12
}

export default {
  views: [
    { id: CampaignModule.WORKERS,     title: 'Fichajes',        path: '/workers'   },
    { id: CampaignModule.REPORTING,   title: 'Registros',       path: '/day-tasks'   },
    { id: CampaignModule.PICTURES,    title: 'Fotos',           path: '/pictures'    },
    { id: CampaignModule.LOCATION,    title: 'Geolocalización', path: '/location'    },
    { id: CampaignModule.FILES,       title: 'Archivos',        path: '/files'       },
    { id: CampaignModule.EVALUATIONS, title: 'Evaluaciones',    path: '/evaluations' }
  ],
  config: [
    { id: CampaignModule.CENTERS,   title: 'Centros',    path: '/centers'  },
    { id: CampaignModule.REPORTS,   title: 'Reportes',   path: '/reports'  },
    { id: CampaignModule.CALENDAR,  title: 'Calendario', path: '/calendar' },
    { id: CampaignModule.CAMPAIGN_ISSUE_CATEGORIES,  title: 'Configuración Incidencias', path: '/issue-categories' }
  ]
};

<template>
  <DForm
    ref="form"
    class="kpi-form"
    :value="model"
    v-on="{ ...$listeners, input }"
  >
    <v-container class="py-0" fluid>
      <v-row>
        <v-col cols="12" md="5">

          <DField
            key="title"
            field="text"
            fieldStyle="1"
            class="mb-6"
            label="Título"
            :rules="rules.title"
            dense
          />

          <DField
            key="type"
            field="select"
            fieldStyle="1"
            class="mb-6"
            label="Tipo"
            :items="types"
            :rules="rules.type"
            dense
          />

          <DField
            key="minLevel"
            field="number"
            fieldStyle="1"
            class="mb-6"
            label="Nivel mínimo (%)"
            min="0"
            :rules="rules.minLevel"
            @input="reload"
            dense
          />

          <DField
            key="mediumLevel"
            field="number"
            fieldStyle="1"
            class="mb-6"
            label="Nivel medio (%)"
            min="0"
            :rules="rules.mediumLevel"
            @input="reload"
            dense
          />

          <DField
            key="maxLevel"
            field="number"
            fieldStyle="1"
            class="mb-6"
            label="Nivel máximo (%)"
            :rules="rules.maxLevel"
            @input="reload"
            dense
          />

          <DField
            key="file"
            field="file"
            fieldStyle="1"
            class="mb-6"
            label="Asociar archivo (opcional)"
            clearable
            dense
          />

          <DField
            key="description"
            field="textarea"
            fieldStyle="1"
            label="Descripción"
            rows="4"
            hide-details
          />

        </v-col>
        <v-col cols="12" md="7">

          <DField class="fill-height" key="products">
            <template v-slot="{ value, on }">
              <Card height="100%" min-height="480" border>
                <Products
                  title="Productos (opcional)"
                  class="product-selector"
                  :value="value"
                  :editable="false"
                  v-on="on"
                  cols="3" sm="4" md="3" lg="4"
                  full
                >
                  <template v-slot:prepend>
                    <div class="caption tertiary--text shrink pa-4">

                      Déjalo vacio para asignar todos los productos.

                    </div>
                    <v-divider/>
                  </template>
                </Products>
              </Card>
            </template>
          </DField>

        </v-col>
      </v-row>
    </v-container>
  </DForm>
</template>

<script>
import Products from "../Products/selector.vue";
import { KpiLevels, KpiTypes } from '@/utils/constants';
import { required } from '@/utils/rules';

const DEFAULT_MODEL = {
  minLevel: 80,
  mediumLevel: 90,
  maxLevel: 100
};

export default {
  components: { Products },
  props: {
    value: Object,
    campaignId: Number
  },
  data() {
    return {
      model: this.value || {},
      minLevel: null,
      mediumLevel: null,
      maxLevel: null,
      levels: Object.keys( KpiLevels ).map( v => ({
        value: parseInt(v),
        text: KpiLevels[v]
      })),
      types: Object.keys( KpiTypes ).map( v => ({
        value: parseInt(v),
        text: KpiTypes[v]
      }))
    }
  },
  computed: {
    rules() {
      return {
        title: [ required('Debes añadir un título.') ],
        type: [ required('Debes escoger el tipo de objetivo.') ],
        minLevel: [ v => v != null && v < this.mediumLevel || 'Debes asignar un nivel mínimo por debajo del nivel medio.'  ],
        mediumLevel: [  v => v != null && v < this.maxLevel && v > this.minLevel || 'Debes asignar un nivel medio entre el nivel mínimo y máximo.' ],
        maxLevel: [ v => v != null && v > this.mediumLevel || 'Debes asignar un nivel máximo por encima del nivel medio.'  ]
      };
    }
  },
  watch: {
    value( model ) {
      this.model = model || {};
      this.setDefaultLevels();
      this.getLevels();
      this.$refs.form.resetValidation();
    }
  },
  methods: {
    input( model ) {
      this.model = model;
      this.getLevels();
      this.$listeners.input && this.$listeners.input( model );
    },
    setDefaultLevels() {
      Object.keys( DEFAULT_MODEL ).forEach( key => {
        if ( this.model[key] == null ) this.model[key] = DEFAULT_MODEL[key];
      });
    },
    getLevels() {
      this.minLevel = this.model.minLevel;
      this.mediumLevel = this.model.mediumLevel;
      this.maxLevel = this.model.maxLevel;
    },
    reload(){
      this.$refs["form"].validate();
    }

  },
  beforeMount() {
    this.setDefaultLevels();
    this.getLevels();
  }
};
</script>

<style>
.kpi-form .product-selector {
  height: 100%;
}
.kpi-form .product-selector h3.headline {
  font-size: 16px !important;
}
</style>

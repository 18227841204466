<template>
  <v-tooltip
    content-class="calendar-holidays--tooltip"
    class="calendar-holidays"
    v-bind="compPosition"
  >

    <template v-slot:activator="{ on, attrs }">
      <span
        class="calendar-holidays--bullet"
        v-bind="attrs"
        v-on="on"
      >
        *
      </span>
    </template>

    <div class="calendar-holidays--card">
      <div>Festivo</div>
      <ul>
        <li v-for="(h,i) in holidays" :key="i">
          {{ h.name }}
        </li>
      </ul>
    </div>

  </v-tooltip>
</template>

<script>
export default {
  props: {
    holidays: Array,
    position: {
      type: String,
      default: 'top'
    }
  },
  computed: {
    compPosition() {
      return {[this.position]: true };
    }
  }
}
</script>

<style>
.calendar-holidays {

}
.calendar-holidays--bullet {
  display: block;
  position: absolute;
  color: white;
    top: 10px;
    right: 8px;
    border-radius: 25%;
    width: 24px;
    height: auto;
    line-height: 16px;
    height: 16px;
    padding:2px;
  background-color: var(--v-primary-base);
  cursor: pointer;
}
.calendar-holidays--tooltip {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  overflow: hidden;
  padding: 0;
}
.calendar-holidays--card > div {
  background-color: var(--v-primary-base);
  padding: 4px;
  color: white;
  font-size: 10px;
  line-height: 1;
}
.calendar-holidays--card > ul {
  color: black;
  list-style: none;
  padding: 0;
}
.calendar-holidays--card > ul > li {
  padding: 6px;
  border-bottom: 1px solid #CCC;
}
.calendar-holidays--card > ul > li:last-child {
  border-bottom: 0;
}
</style>

<template>
  <v-text-field
    v-bind="attr"
    v-on="$listeners"
    :value="model"
    :class="size"
    :readonly="readonly"
    @keydown.up.prevent="increment"
    @keydown.down.prevent="decrement"
    @input="model = sanitize($event)"
    color="secondary"
  >
    <label v-if="attr.preLabel" slot="prepend" class="label" :style="labelStyle">
      {{ label }}
    </label>
    <v-btn v-if="!readonly && !hideControls" small icon slot="append" @click="decrement( step )">
      <v-icon small color="secondary">$minus</v-icon>
    </v-btn>
    <v-btn v-if="!readonly && !hideControls" small icon slot="append" @click="increment( step )">
      <v-icon small color="secondary">$plus</v-icon>
    </v-btn>
  </v-text-field>
</template>

<script>

  import { formatSize } from '@/utils';

  function round( number, decimals ) {
    const pw = Math.pow( 10, decimals );
    return Math.round( number * pw ) / pw;
  }

  function limit( number, min, max ) {
    min = min == null ? -Infinity : min;
    max = max == null ? Infinity : max;
    return number < min ? min : number > max ? max : number;
  }

  export default {
    nullValues: '',
    props: {
      value: [ Number, String ],
      max: [ Number, String ],
      min: [ Number, String ],
      step: {
        type: [ Number, String ],
        default: 1
      },
      decimals: {
        type: [ Number, String ],
        default: 7
      },
      label: String,
      labelSpace: [ Number, String ],
      fieldStyle: [ Number, String ],
      hideControls: Boolean,
      readonly: Boolean,
      small: Boolean
    },
    data() {
      return {
        start: 0,
        end: 0,
        model: null
      }
    },
    computed: {
      size() {
        return {
          'small': !!this.small
        }
      },
      attr() {
        switch ( String( this.fieldStyle )) {
          case '1':
            return {
              ...this.$attrs,
              backgroundColor: 'white',
              label: this.label,
              outlined: true
            };
          case '2':
            return {
              ...this.$attrs,
              preLabel: true,
              dense: true
            };
        }
        return this.$attrs;
      },
      labelStyle() {
        const space = this.labelSpace;
        return { width: formatSize( space ) };
      }
    },
    watch: {
      value( value ) {
        this.model = this.sanitize( value );
      },
      model( value ) {
        this.$emit( 'input', value );
      }
    },
    methods: {
      sanitize( value ) {
        value = String( value ).replace( /^\s*(\+|-)?(\d*)([,.]+)?(\d*\s*)\s*$/, '$10$2.$40' );
        value = parseFloat( value );
        return isNaN( value ) ? null : round(
          limit( value, this.min, this.max ),
          parseInt( this.decimals )
        );
      },
      increment( num ) {

        if ( this.readonly ) return;
        const step = this.step;
        num = num || step;

        if ( typeof num !== 'number' ) {
          this.start = num.target.selectionStart;
          this.end = num.target.selectionEnd;
          num = num.shiftKey ? ( step * 10 ) : step;
        }

        this.model = this.sanitize( this.model + num );
      },
      decrement( num ) {

        if ( this.readonly ) return;
        const step = this.step;
        num = num || step;

        if ( typeof num !== 'number' ) {
          this.start = num.target.selectionStart;
          this.end = num.target.selectionEnd;
          num = num.shiftKey ? ( step * 10 ) : step;
        }

        this.model = this.sanitize( this.model - num );
      }
    },
    beforeMount() {
      this.model = this.sanitize( this.value );
    }
  }
</script>

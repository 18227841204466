<template>
  <v-sheet color="#eee" :class="className" @click="hasMode('click') && selectImage()">

    <DResponsive v-if="loading" :aspect-ratio="aspectRatio">
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          color="tertiary"
          :value="percent"
          :indeterminate="!percent"
        />
      </v-row>
    </DResponsive>

    <v-img v-else :src="url" :aspect-ratio="aspectRatio" v-bind="$attrs" v-on="$listeners">
      <v-row v-if="url" slot="placeholder" class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          color="border"
          indeterminate
        />
      </v-row>
    </v-img>

    <Btn v-if="hasMode('button')" color="tertiary" @click.stop="selectImage" absolute bottom right dark>
      <v-icon class="mr-2">$edit</v-icon>
      {{ url ? 'Editar imágen' : 'Subir imágen' }}
    </Btn>

    <input
      v-show="false"
      ref="file"
      type="file"
      accept="image/*"
      @change="uploadFile"
    />

  </v-sheet>
</template>

<script>

  import { get } from '@/utils';

  export default {
    props: {
      value: [ String, Object ],
      itemSrc: String,
      aspectRatio: [ String, Number ],
      editMode: {
        type: [ Boolean, Array, String ],
        default: 'click'
      }
    },
    data() {
      return {
        model: this.value,
        loading: false,
        percent: 0
      }
    },
    watch: {
      value( value ) {
        this.model = value;
      }
    },
    computed: {
      className() {
        return {
          'dfield-image': true,
          clickable: this.hasMode('click')
        }
      },
      url() {
        return get( this.model, this.itemSrc ) || '';
      },
      mode() {
        const mode = this.editMode;
        switch ( typeof mode ) {
          case 'boolean':
            return mode ? ['click','button'] : [];
          case 'string':
            return mode.split(/\s*,\s*/);
          default:
            return mode || [];
        }
      }
    },
    methods: {
      hasMode( type ) {
        return this.mode.indexOf( type ) !== -1;
      },
      selectImage() {
        this.$refs.file.click();
      },
      uploadFile() {

        const { onUploadProgress } = this;
        const config = { onUploadProgress };
        const data = new FormData();
        data.append( 'file', this.$refs.file.files[0] );

        this.loading = true;
        this.$store.dispatch( 'api', { target: 'Upload/image', params: [ data, config ]})
          .then( response => this.$emit( 'input', ( this.model = response )))
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => {
            this.$refs.file.value = null;
            this.loading = false;
            this.percent = 0;
          })
      },
      onUploadProgress( event ) {
        this.percent = Math.round(( event.loaded * 100) / event.total );
      }
    }
  }
</script>

<style scoped>
  .dfield-image {
    position: relative;
    width: 100%;
  }
  .dfield-image.clickable {
    cursor: pointer;
  }
</style>

<template>
  <DForm class="fill-height" :value="value" v-on="{ ...$listeners, input }">
    <v-row class="fill-height" no-gutters>
      <div class="rel order-1 order-md-0" :style="column(0)">
        <Card
          :class="scrollable"
          :border-top="!$vuetify.breakpoint.mdAndUp"
          :border-right="$vuetify.breakpoint.mdAndUp"
        >

          <v-row class="pa-2" align="center" no-gutters>

            <span class="subtitle-2">
              Módulos
            </span>

            <v-spacer/>

            <Btn
              v-if="!readonly && !isNew && !isRRHH"
              :color="editable ? 'secondary' : 'border'"
              @click="edit = !edit"
              small icon
            >
              <v-icon>$edit</v-icon>
            </Btn>

          </v-row>

          <v-divider/>

          <DField
            key="modules"
            field="modules"
            :campaign="model"
            :readonly="!editModules || isRRHH"
            :show-modules="showModules"
            @validate="$emit( 'modules-complete', $event )"
          />

        </Card>
      </div>
      <div class="rel order-0 order-md-1 autoscroll" :style="column(1)">
        <div class="campaign-column-1">
          <v-col cols="12" md="5">

            <h3 class="title mb-6">Datos generales</h3>

            <DField key="title" v-bind="title" class="mb-6" />

            <DField key="description" v-bind="description" class="mb-6" />

            <DField key="type" v-bind="type" class="mb-6" />

            <DField key="startDate" v-bind="startDate" class="mb-6" />

            <DField key="endDate" v-bind="endDate" class="mb-6" />

            <DField key="kams" v-bind="kams" class="mb-6"/>

            <DField key="managers" v-bind="managers" class="mb-6"/>


            <h3 class="title mb-6">Datos de guardia</h3>

            <DField key="comments" v-bind="comments" class="mb-6" />

          </v-col>
          <v-col cols="12" md="7">

            <h3 class="title mb-6">Otros datos</h3>

            <DField key="tracking" v-bind="tracking" class="mb-6" />

            <DField key="notifDay" v-bind="notifDay" class="mb-6" />

            <DField key="productType" v-bind="productType" class="mb-6" />

            <DField key="client" v-bind="client" class="mb-6" />

            <DField key="clientUsers" v-show="model.client" v-bind="clientUsers" class="mb-6"/>

            <DField key="clientAdminLink" v-bind="clientAdminLink" class="mb-6" />

            <DField key="clientLink" v-bind="clientLink" class="mb-6" />

            <Sectors key="sector" v-bind="sector" class="mb-6" />

            <Brands key="brands" v-bind="brands" class="mb-6" />

          </v-col>
        </div>
      </div>
    </v-row>
  </DForm>
</template>

<script>

  import Brands from "@/components/forms/Brands/selector.vue";
  import Sectors from "@/components/forms/Sectors/selector.vue";
  import { CampaignType, Option, UserRole } from '@/utils/constants';
  import { CampaignModule } from '@/utils/modules';
  import { mapState } from 'vuex';
  import { get } from '@/utils';

  const style = {
    fieldStyle: 1,
    dense: true
  };

  export default {
    components: { Brands, Sectors },
    props: {
      value: Object,
      readonly: Boolean
    },
    data() {
      return {
        edit: false,
        modules: null,
        minDate: null,
        maxDate: null,
        model: {}
      };
    },
    methods: {
      input( value ) {
        this.model = value;
        this.minDate = this.model.startDate;
        this.maxDate = this.model.endDate;
        if ( this.$listeners.input )
          this.$listeners.input( value );
      },
      column( num ) {
        if ( num ) {
          const maxWidth = this.$vuetify.breakpoint.mdAndUp ? '' : '100%';
          const flex = `1 1 ${ maxWidth }`;
          return { maxWidth, flex };
        } else {
          const maxWidth = this.$vuetify.breakpoint.mdAndUp ? '240px' : '100%';
          const flex = `1 1 ${ maxWidth }`;
          return { maxWidth, flex };
        }
      }
    },
    watch: {
      editModules( value ) {
        if ( !value && !this.isNew ) {
          this.$emit('save');
        }
      }
    },
    computed: {
      ...mapState([ 'isRRHH' ]),
      showModules() {
        return this.isRRHH
          ? [
              CampaignModule.CENTERS,
              CampaignModule.CALENDAR,
              CampaignModule.LOCATION,
              CampaignModule.EVALUATIONS
            ]
          : [];
      },
      isNew() {
        return this.$route.params.id === "new";
      },
      editable() {
        return this.edit || this.isNew;
      },
      editModules() {
        return !this.readonly && this.editable;
      },
      scrollable() {
        return this.$vuetify.breakpoint.mdAndUp
          ? 'layer autoscroll'
          : '';
      },
      title() {
        return {
          ...style,
          field: "text",
          label: "Título",
          rules: [ v => !!v || 'La campaña debe tener un título' ],
          readonly: this.isRRHH
        }
      },
      description() {
        return {
          ...style,
          field: "text",
          label: "Descripción",
          readonly: this.isRRHH
        }
      },
      comments() {
        return {
          ...style,
          field: "textarea",
          label: "Introduzca comentarios para el personal de guardia",
          readonly: this.isRRHH
        }
      },
      clientLink() {
        return {
          ...style,
          field: "text",
          label: "Enlace web para el cliente",
          readonly: this.isRRHH
        }
      },
      clientAdminLink() {
        return {
          ...style,
          field: "text",
          label: "Enlace web para administración del cliente",
          readonly: this.isRRHH
        }
      },
      type() {
        return {
          ...style,
          field: "select",
          label: "Plantilla",
          items: [
            { value: CampaignType.PROMOTOR, text: "Promotor" },
            { value: CampaignType.REPOSITOR , text: "Reponedor" },
            { value: CampaignType.GPV , text: "GPV" }
          ],
          rules: [ v => v != null || 'Es necesario especificar el tipo de campaña.' ],
          readonly: this.isRRHH
        }
      },
      productType() {
        return {
          ...style,
          field: "select",
          label: "Productos en acciones cuantitativas",
          itemText: "text",
          itemValue: "value",
          readonly: this.isRRHH,
          items: [
            { value: Option.ENABLED, text: "Se permite añadir productos" },
            { value: Option.DISABLED, text: "No se permite añadir productos" }
          ]
        }
      },
      tracking() {
        return {
          ...style,
          field: "select",
          label: "Envío de ubicación de los trabajadores",
          itemText: "text",
          itemValue: "value",
          readonly: this.isRRHH,
          items: [
            { value: true, text: "Activado el envío ubicación automático por visita o jornada" },
            { value: false, text: "Desactivado el envío de ubicación automático." }
          ]
        }
      },
      notifDay() {
        return {
          ...style,
          field: "select",
          label: "Día de envío de email de feedback semanal",
          itemText: "text",
          itemValue: "value",
          readonly: this.isRRHH,
          items: [
            { value: -1, text: "Desactivado" },
            { value: 2, text: "Lunes" },
            { value: 3, text: "Martes" },
            { value: 4, text: "Miércoles" },
            { value: 5, text: "Jueves" },
            { value: 6, text: "Viernes" }
          ]
        }
      },
      startDate() {
        const { maxDate } = this;
        return {
          ...style,
          field: "date",
          label: "Fecha de inicio",
          max: maxDate,
          readonly: this.isRRHH,
          rules: [ v => !!v || 'Debes indicar una fecha de inicio de campaña.' ]
        }
      },
      endDate() {
        const { minDate } = this;
        return {
          ...style,
          field: "date",
          label: "Fecha de fin",
          min: minDate,
          readonly: this.isRRHH
        }
      },
      client() {
        return {
          ...style,
          field: "autocomplete",
          label: "Cliente",
          service: "Clients",
          itemText: "name",
          itemValue: "id",
          display: a => `${a.id}. ${a.name}`,
          readonly: this.isRRHH
        }
      },
      brands() {
        return {
          ...style,
          label: "Marcas", multiple: true,
          readonly: this.isRRHH
        }
      },
      sector() {
        return {
          ...style,
          label: "Sector",
          readonly: this.isRRHH
        }
      },
      managers() {
        return {
          ...style,
          field: "autocomplete",
          label: "Gestores de campaña",
          service: "Users",
          itemText: "name",
          itemValue: "id",
          multiple: true,
          readonly: this.isRRHH,
          params: {
            filters: [
              { field: 'role', listValue: [
                UserRole.SUPERADMIN,
                UserRole.KAM,
                UserRole.MANAGER,
              ]},
              { field: 'status', intValue: 1 }
            ]
          },
          display: a => a.fullName || a.name,
        }
      },
      kams() {
        return {
          ...style,
          field: "autocomplete",
          label: "KAMs",
          service: "Users",
          itemText: "name",
          itemValue: "id",
          multiple: true,
          readonly: this.isRRHH,

           params: { filters: [{
            field: 'role',
            listValue: [
              UserRole.SUPERADMIN,
              UserRole.KAM
            ]
          }, {
            field: 'status',
            intValue: 1
            }]},
          display: a => a.fullName || a.name,
        }
      },
      clientUsers() {
        return {
          ...style,
          field: "autocomplete",
          label: "Usuarios de cliente con visibilidad (opcional)",
          service: "Users",
          itemText: "name",
          itemValue: "id",
          multiple: true,
          display: a => a.fullName || a.name,
          readonly: this.isRRHH,
          params: { filters: [{
            field: 'role',
            listValue: [
              UserRole.CLIENT,
            ]
          }, {
            field: 'status',
            intValue: 1
            }, {
            field: 'client',
            intValue: get( this.model, 'client.id' )
            }]}
        }
      },
      accountManager() {
        return {
          ...style,
          field: "autocomplete",
          label: "KAM",
          service: "Users",
          itemText: "name",
          itemValue: "id",
          readonly: this.isRRHH,
          display: a => a.fullName || a.name,
          params: { filters: [{
            field: 'role',
            listValue: [ UserRole.SUPERADMIN, UserRole.KAM, ]
            },{
            field: 'status',
            intValue: 1
            }]
          },
        }
      }
    }
  };
</script>

<style>
  .campaign-column-1 {
    position: relative;
  }
  .campaign-column-1 > div {
    padding: 16px;
  }
  @media (min-width: 960px) {
    .campaign-column-1 > div {
      position: absolute;
      top: 0;
    }
    .campaign-column-1 > .col-md-5 {
      left: 0;
      padding: 16px 8px 16px 16px;
    }
    .campaign-column-1 > .col-md-7 {
      left: 41.6666666667%;
      padding: 16px 16px 16px 8px;
    }
  }
</style>

<template>
  <v-text-field
    ref="input"
    v-bind="attr"
    v-on="$listeners"
    :value="model"
    :class="size"
    :readonly="readonly"
    @input="change"
    @blur="blur"
    @keydown.up.prevent="increment"
    @keydown.down.prevent="decrement"
    maxlength="5"
    color="secondary"
  >
    <label v-if="attr.preLabel" slot="prepend" class="label" :style="labelStyle">
      {{ label }}
    </label>
  </v-text-field>
</template>

<script>

  import { formatSize, limit, isElement } from '@/utils';

  function format( value ) {
    value = String( value ).slice( 0, 2 );
    return '00'.slice( value.length ) + value;
  }

  function isNumberKey( ev ) {
    var charCode = ev.which ? ev.which : ev.keyCode;
    if ( charCode > 31 && ( charCode < 48 || charCode > 57 )) {
      ev.preventDefault();
      return false;
    }
  }

  export default {
    nullValues: '',
    props: {
      value: String,
      max: String,
      min: String,
      label: String,
      labelSpace: [ Number, String ],
      fieldStyle: [ Number, String ],
      small: Boolean,
      readonly: Boolean
    },
    data() {
      return {
        model: this.value,
        input: null,
        start: 0,
        end: 0
      }
    },
    computed: {
      size() {
        return {
          'small': !!this.small
        }
      },
      attr() {
        switch ( String( this.fieldStyle )) {
          case '1':
            return {
              ...this.$attrs,
              backgroundColor: 'white',
              label: this.label,
              outlined: true
            };
          case '2':
            return {
              ...this.$attrs,
              preLabel: true,
              dense: true
            };
        }
        return this.$attrs;
      },
      labelStyle() {
        const space = this.labelSpace;
        return { width: formatSize( space ) };
      }
    },
    watch: {
      value( value ) {
        this.model = this.sanitize( value );
      },
      model( value ) {
        value = this.sanitize( value );
        if ( value !== this.model ) this.$nextTick(() => this.model = value );
        else this.$emit( 'input', value );
      }
    },
    methods: {
      separe( value ) {
        if ( typeof value === 'string' )
          value = [ value.slice( 0, 2 ), value.slice( 2, 4 ) ];
        return value;
      },
      limit( value ) {
        return this.separe( value ).map(( v, i ) => {
          return limit( parseInt( v ) || 0, 0, i ? 59 : 23 )
        });
      },
      compare( a, b ) {
        if ( a[0] > b[0] ) return 1;
        else if ( a[0] < b[0] ) return -1;
        else if ( a[1] > b[1] ) return 1;
        else if ( a[1] < b[1] ) return -1;
        return 0;
      },
      sanitize( value ) {
        var val = ( value || '' ).replace( /[:\D]/g, '' );
        if ( val.length < 2 ) return value;
        else if ( val.length < 4 ) return this.separe( val ).join(':');
        else return this.format( val );
      },
      format( value ) {
        const min = this.min ? this.limit( this.min ) : null;
        const max = this.max ? this.limit( this.max ) : null;
        value = this.limit(( value || '' ).replace( /[:\D]/g, '' ));
        if ( min && this.compare( value, min ) < 0 ) value = min;
        else if ( max && this.compare( value, max ) > 0 ) value = max;
        return value.map( format ).join(':');
      },
      change( value ) {
        this.model = value;
      },
      sum( num ) {
        const v = this.limit(( this.model || '' ).replace( /[:\D]/g, '' ));
        v[1] += num;
        if ( v[1] < 0 ) { v[0]--; v[1] += 60; }
        else if ( v[1] > 59 ) { v[0]++; v[1] -= 60; }
        if ( v[0] > 23 ) v[0] = 0;
        else if ( v[0] < 0 ) v[0] = 23;
        this.model = v.map( format ).join(':');
      },
      blur( event ) {
        if ( this.$listeners.blur ) this.$listeners.blur( event );
        if ( this.model ) this.model = this.format( this.model );
      },
      increment( event ) {
        this.sum( event.shiftKey ? 10 : 1 );
      },
      decrement( event ) {
        this.sum( event.shiftKey ? -10 : -1 );
      }
    },
    mounted() {
      var element;
      this.interval = setInterval(() => {
        element = this.$refs.input ? this.$refs.input.$el : null;
        if ( isElement( element ) && element.querySelector ) {
          clearInterval( this.interval );
          if (( this.input = element.querySelector('input'))) {
            this.input.addEventListener( 'keypress', isNumberKey );
          }
        }
      }, 100 );
    },
    beforeDestroy() {
      clearInterval( this.interval );
      if ( this.input ) this.input.removeEventListener( 'keypress', isNumberKey );
    }
  }
</script>

<template>
  <Table
    service="Holidays"
    :headers="headers"
    :messages="messages"
    :sections="sections"
    :mypopup="mypopup"
    :sanitize="sanitize"
    @edit="resetForm"
    show-actions="edit,duplicate,delete,new,export,import"
    hide-back-button
    reload-on-save
  >

    <template v-slot:form="{ value, listeners }">
      <HolidaysForm
        ref="form"
        class="pa-4"
        :value="value"
        v-on="listeners"
      />
    </template>

  </Table>
</template>

<script>

import Table from "@/components/core/Table.vue";
import HolidaysForm from "@/components/forms/Holidays/single.vue";

const HOLIDAYS_TYPE = [
  'No laboral',
  'Laboral'
];

export default {
  components: { Table, HolidaysForm },
  data() {
    return {
      section: '0-0',
      messages: 'Festivo/Festivos',
      mypopup: {
        width: 600,
        height: 600,
        title: isNew => isNew
          ? 'Nuevo festivo'
          : 'Editar festivo'
      },
      sections: [
        { text: 'Próximos',   filters: [{ field: 'section', intValue: 1 }]},
        { text: 'Anteriores', filters: [{ field: 'section', intValue: 2 }]},
        { text: 'Todos', filters: [{ field: 'section', intValue: 3 }]}
      ],
      headers: [
        {
          text: "Fecha",
          value: "day",
          filter: {
            type: "rangedate",
            field: 'date',
            compute: (value, field) => {
              if (!value[0] || !value[1]) return { [field]: null };
              return { [field]: { start: value[0], end: value[1] } };
            }
          },
        },{
          text: "Nombre",
          value: "name",
          filter: {

          }
        },{
          text: "Tipo",
          value: "type",
          display: HOLIDAYS_TYPE,
          filter: {
            type: 'select',
            items: HOLIDAYS_TYPE.map(( text, value ) => ({ text, value }))
          }
        },{
          text: "Codigos postales",
          value: "postalCodes",
          sorteable : false,
          display: v => ( v || [] ).join(', '),
          width: '50%',
          filter: {

          }
        }
      ]
    };
  },
  methods: {
    sanitize( model ) {

      const postalCodes = ( model.postalCodes || [] )
        .filter( code => !isNaN( parseInt( code )));

      return {
        ...model,
        id: model.id || -1,
        postalCodes
      };
    },
    resetForm() {
      this.$refs.form && this.$refs.form.resetValidation();
    }
  },
  mounted() {
    this.$store.commit( 'setTitle', 'Festivos' );
  }
};
</script>

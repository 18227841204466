<template>
  <div
    v-if="question && question.status !== -1"
    :class="`ma-${isInput ? 1 : 2}`"
    :style="{ maxWidth: dense && isInput && '358px' || null }"
  >
    <div class="mb-3">
      <p class="subtitle-1 mb-1">{{ questionTitle(question) }}</p>
      <p v-if="question.comments" class="caption grey--text">
        {{ question.comments }}
      </p>
    </div>

    <div v-if="isInput" :class="!fullWidth && 'col-md-4 col-12 pa-0 pr-md-4'">
      <DField
        :value="model"
        :readonly="readonly"
        v-bind="field"
        @blur="onBlur"
        field-style="1"
        dense
      />
    </div>

    <div v-else-if="isPicture" :class="!fullWidth && 'mt-0'">
      <Pictures
        title="Fotografías seleccionadas"
        :value="pictures"
        :readonly="readonly"
        @input="picturesChange"
      />
    </div>

    <v-radio-group
      v-else
      class="mt-0"
      v-model="model"
      :multiple="isMultiple"
      :readonly="readonly"
      :row="row"
      hide-details
      @mouseup="toggle"
    >
      <v-radio
        v-for="r in replies"
        :key="r.id"
        :label="r.reply"
        :value="r.id"
        :ripple="false"
      />
    </v-radio-group>

  </div>
</template>

<script>
import { toArray } from "@/utils";
import { SurveyQuestionType } from "@/utils/constants";
import Pictures from "./questionPictures"

export default {
  components: {
    Pictures
  },
  props: {
    value: [String, Number, Array],
    question: Object,
    pics: Array,
    task: Object,
    readonly: Boolean,
    row: Boolean,
    fullWidth: Boolean,
    dense: Boolean
  },
  data: function() {
    return {
      model: this.value,
      pictures: this.pics || []
    };
  },
  watch: {
    value(value) {
      this.model = value;
    },
    model(value) {
      this.$emit("input", value);
    },
    pictures(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    type() {
      return this.question ? this.question.type : "";
    },
    field() {
      switch (this.type) {
        case SurveyQuestionType.OPEN:
          return { field: "textarea", rows: 2 };
        case SurveyQuestionType.PICTURE:
          return { field: "images" };
        case SurveyQuestionType.INTEGER:
        case SurveyQuestionType.DECIMAL:
          return { field: "number" };
      }
      return { field: "text" };
    },
    isInput() {
      switch (this.type) {
        case SurveyQuestionType.MULTIPLE:
        case SurveyQuestionType.CLOSED:
        case SurveyQuestionType.PICTURE:
          return false;
      }
      return true;
    },
    isMultiple() {
      return this.type === SurveyQuestionType.MULTIPLE;
    },
    isPicture() {
      return this.type === SurveyQuestionType.PICTURE;
    },
    replies() {
      return !this.question
        ? []
        : (this.question.replies || []).filter((r) => r.status === 1);
    },
  },
  methods: {
    questionTitle(q) {
      return [q.question, q.optional ? "(Opcional)" : ""].join(" ");
    },
    isNumeric(){

      switch (this.type) {
        case SurveyQuestionType.INTEGER:
        case SurveyQuestionType.DECIMAL:
          return true;
      }
      return false;
    },
    onBlur(event) {
      if (!this.readonly) {
        this.model =
          this.isNumeric() ? parseFloat(event.target.value) : event.target.value;
      }
    },
    picturesChange(pictures) {
      //console.log("picturesChange: " + JSON.stringify(pictures));
      this.pictures = pictures;
      //this.model = pictures.map(i=>i.id).join(",");

    },
    toggle(event) {
      if (this.readonly || !Array.isArray(this.model)) return;

      // Get radio clicked

      var radio, index;
      for (var i = 0, el; i < event.path.length; i++) {
        el = event.path[i];
        if (el.classList && el.classList.contains("v-radio")) radio = el;
      }

      // Get index and remove value if is selected

      if (radio && radio.parentNode) {
        if (
          (index = toArray(radio.parentNode.children).findIndex(
            (el) => el === radio
          )) !== -1
        ) {
          const reply = this.replies[index];
          if (reply) {
            if ((index = this.model.indexOf(reply.id)) !== -1) {
              setTimeout(() => {
                this.model = this.model.slice();
                this.model.splice(index, 1);
              }, 60);
            }
          }
        }
      }
    },
  },
};
</script>

  <style>
    .title.picsTitle{
      font-size : 1em !important;
      margin:0px;
      line-height: 1rem;
    }
  </style>

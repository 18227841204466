import APIService from './class';

class Products extends APIService {
  constructor() {
    super( '/product' );
  }
  merge( token, params, options ) {
    return this.action( 'post', '/merge', params, options, token );
  }
}

export default new Products();

import { set, toggle } from '@/utils/vuex';

export default {
  setDrawer: set('drawer'),
  toggleDrawer: toggle('drawer'),
  setBlock: set('block'),
  preventRouting: set('preventRouting'),
  toggleDialog( state ) {
    state.dialog && ( state.dialog.show = !state.dialog.show );
  },
  setDialog( state, dialog ) {
    state.dialog = {
      show: true,
      width: 400,
      title: 'Sin titulo',
      text: '',
      cancel: null,
      cancelText: 'Cancelar',
      accept: null,
      acceptText: 'Aceptar',
      ...dialog
    };
  }
};

<template>
  <DForm ref="form" :value="value" v-on="{ ...$listeners, input }">
    <v-row class="pa-2" no-gutters>
      <v-col cols="12" md="6" class="pa-2">
        <DField :value="computedDate" v-bind="date" class="mb-6 bg-grey" />
        <DField key="user.fullName" v-bind="user" class="mb-6" />
        <DField key="campaign.title" v-bind="campaign" class="mb-6" />
        <DField key="campaign.client.name" v-bind="client" class="mb-6" />
        <DField key="place.name" v-bind="place" class="mb-6" />
        <DField key="user.email" v-bind="email" class="mb-6" />
      </v-col>

      <v-col cols="12" md="6" class="pa-2">
        <DField key="timetable" v-bind="timetable" class="mb-6" />
        <DField :value="startDateText" v-bind="startDate" class="mb-6" />
        <DField :value="workingTimeText" v-bind="workingTime" class="mb-6" />
        <DField :value="estimatedWorkingTimeText" v-bind="estimatedWorkingTime" class="mb-6" />
        <DField key="absenceType" v-bind="absenceType" class="mb-6" />
        <DField key="comments" v-bind="comments" class="mb-6" />
      </v-col>

      <v-col cols="12" md="6" class="pa-2">
        <DField key="validatedWorkingTime" v-bind="validatedWorkingTime" class="mb-6" />
        <DField key="holiday" v-bind="holiday" class="mb-6" />
      </v-col>

      <v-col cols="12" md="6" class="pa-2">
        <DField key="validated" v-bind="validated" class="mb-6" />
      </v-col>
    </v-row>
  </DForm>
</template>

<script>
import moment from "moment";
import { formatDate } from '@/utils';
import { AbsenceType } from "@/utils/constants";

const absences = [
 // { text: "Festivo laboral", value: AbsenceType.HOLIDAY_WORKING },
  { text: "", value: null },
  { text: "Festivo No laboral", value: AbsenceType.HOLIDAY_NO_WORKING },
  { text: "Vacaciones", value: AbsenceType.USER_HOLIDAYS },
  { text: "Libranza", value: AbsenceType.LIBRANZA },
  { text: "Centro descubierto", value: AbsenceType.PLACE },
  { text: "Jornada incompleta", value: AbsenceType.INCOMPLETE },
  { text: "Observaciones", value: AbsenceType.OTHER },
];

const booleans = [
  { text: "Sí", value: true },
  { text: "No", value: false },
];

function minutes(value) {
  if (value) {
    if (value > 59) {
      var hours = Math.floor(value / 60);
      var minutes = value % 60;
      var formattedminutes = ("0" + minutes).slice(-2);
      return hours + ":" + formattedminutes + " (" + Number(value) + " min)";
    }
    return Number(value) + " min";
  }
  return "-";
}

function time(time) {
  if (time) return moment(time).format("HH:mm:ss");
  return "-";
}

function initValidatedWorkingTime( model ) {
  if(model.validated)
    return model.validatedWorkingTime;
  else {
    if(!model.absenceType) {
      return model.estimatedWorkingTime;
    }
    else if(model.absenceType === AbsenceType.HOLIDAY_NO_WORKING) {
      return 0;
    }
    else if(model.absenceType === AbsenceType.USER_HOLIDAYS) {
      return model.estimatedWorkingTime;
    }
    else if(model.absenceType === AbsenceType.LIBRANZA) {
      return 0;
    }
    else if(model.absenceType === AbsenceType.PLACE) {
      return 0;
    }
    else if(model.absenceType === AbsenceType.INCOMPLETE) {
      return model.estimatedWorkingTime;
    }
    else if(model.absenceType === AbsenceType.OTHER) {
      return 0;
    }
    else {
      return 0;
    }
  }
}

export default {
  props: {
    value: Object,
    readonly: Boolean,
    dense: Boolean
  },
  data: function() {
    const model = this.value || {};
    model.validatedWorkingTime = initValidatedWorkingTime(model);
    return {
      model
    }
  },
  methods: {
    input(value) {
      if (this.$listeners.input)
        this.$listeners.input(value);
    },
    reset() {
      this.$refs.form.reset();
      this.model = { ...this.model };
    }
  },
  computed: {
    computedDate() {
        return this.value ? formatDate( this.value.date, {
          year:  "numeric",
          month: "2-digit",
          day:   "2-digit"
        }) : null;
      },
    date() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Fecha",
          dense: true,
          future: true,
          readonly: true
        };
      },
    user() {
      return {
          fieldStyle: 1,
          field: "text",
          label: "Promotor",
          dense: true,
          readonly: true
        };
      },
    campaign() {
      return {
          fieldStyle: 1,
          field: "text",
          label: "Campaña",
          dense: true,
          readonly: true
        }
      },
    client() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Cliente",
          dense: true,
          readonly: true
        };
      },
    place() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Centro",
          dense: true,
          readonly: true
        };
      },
    email() {
      return {
          fieldStyle: 1,
          field: "text",
          label: "Email",
          dense: true,
          readonly: true
        };
      },
    timetable() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Horario",
          dense: true,
          readonly: true
        };
      },
    startDate() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Inicio",
          dense: true,
          readonly: true
        };
      },
    startDateText() {
        return time(this.value.startDate);
      },
    workingTime() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Tiempo",
          dense: true,
          readonly: true
        };
      },
    workingTimeText() {
        return minutes(this.value.workingTime);
      },
    estimatedWorkingTime() {
        return {
          fieldStyle: 1,
          field: "text",
          label: "Tiempo Estimado",
          dense: true,
          readonly: true
        };
      },
    estimatedWorkingTimeText() {
        return minutes(this.value.estimatedWorkingTime);
      },
    absenceType() {
        return {
          fieldStyle: 1,
          field: "select",
          items: absences,
          label: "Tipo Ausencia",
          dense: true
        };
      },
    comments() {
      return {
          fieldStyle: 1,
          field: "text",
          label: "Comentarios",
          dense: true,
        };
      },
    validatedWorkingTime() {
        return {
          fieldStyle: 1,
          field: "number",
          label: "Tiempo Validado",
          dense: true
        };
      },
    holiday() {
        return {
          fieldStyle: 1,
          field: "select",
          items: booleans,
          label: "Festivo",
          dense: true
        };
      },
    validated() {
        return {
          field: "select",
          items: booleans,
          fieldStyle: 1,
          label: "Validado",
          dense: true,
          readonly: true
        };
      }
  },
};
</script>

<style>
.active-modules-checkbox {
  display: inline-block;
  width: 140px;
}
</style>

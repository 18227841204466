<template>
  <Single
    service="Workers"
    messages="Fichaje"
    :initialize="initialize"
    :sanitize="sanitize"
    :update="update"
    :auto-id="false"
    @initialized="setTitle"
    @saved="readonly = true"
  >
    <template v-slot="{ value, listeners }">
      <Worker 
        ref="worker"
        v-on="listeners"
        :value="value"
        :readonly="readonly"
        @empty:elements="hasEmptyElements = $event"
      />
    </template>
    <template v-slot:actions="{ save }">

      <v-spacer/>

      <Btn class="mr-2" v-if="readonly && !isClient" @click="validateWorker()" :loading="validating" color="tertiary" text>
        <v-icon class="mr-2">mdi-check</v-icon>
        Validar
      </Btn>

      <Btn color="tertiary" @click="edit()" dark v-if="!isClient">
        {{ readonly ? 'Editar' : 'Cancelar' }}
      </Btn>

      <Btn v-if="!readonly && !isClient" class="ml-2" color="tertiary" @click="save()" dark>
        Guardar
      </Btn>

    </template>
  </Single>
</template>

<script>

import Single from '@/components/core/Single.vue';
import Worker from '@/components/forms/Workers/single.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  components: { Single, Worker },
  data: function() {
    return {
      readonly: true,
      validating: false,
      hasEmptyElements: false
    }
  },
  computed: {
    ...mapState([ 'path', 'breadcrumbs' ]),
    isClient() {
      return  this.$store.state.isClient;
    },
    idWorker() {
      const { id, index } = this.$route.params;
      return index ? parseInt( index ) : parseInt( id );
    }
  },
  watch: {
    $route() {
      this.$nextTick( this.setBreadcrumbs );
    }
  },
  methods: {
    ...mapMutations([ 'setView' ]),
    ...mapMutations( 'app', [ 'setDialog' ]),
    setTitle( res, campaign ) {
      const title = [ 'Campañas', campaign, 'Fichajes', res && res.user.fullName ];
      if ( ! this.$route.params.index ) title.splice(0,2);
      this.$store.commit( 'setTitle', title );
    },
    set( state ) {
      this.setView({
        path: this.path,
          state: {
          sections: {
            ['00']: state
          }
        }
      });
    },
    initialize( params ) {
      return this.$store.dispatch( "single", {
        ...params,
        id: this.idWorker,
      });
    },
    sanitize( model ) {

      const { idWorker } = this;

      const params = {
        id : idWorker,
        validatedWorkingTime: model.validatedWorkingTime,
        holiday: model.holiday,
        absenceType: this.$refs.worker.model.absenceType,
        comments: model.comments
      };
      return params;
    },
    edit() {
      if ( ! this.readonly ) this.$refs.worker.reset();
      this.readonly = !this.readonly;
    },
    update( params ) {
      return new Promise(( resolve, reject ) => {
        if ( ! this.hasEmptyElements ) {
          this.save( params ).then( resolve ).catch( reject );
        } else {
          this.setDialog({
            show: true,
            title: 'Hay datos sin contestar',
            text: 'Estos elementos serán eliminados.<br>¿Está seguro de que desea continuar?',
            acceptText: 'Guardar',
            cancel: () => reject(),
            accept: () => this.save( params ).then( resolve ).catch( reject ),
          })
        }
      });
    },
    save( params ) {
      return new Promise(( resolve, reject ) => {

        Promise
          .resolve(this.$store.dispatch( 'api', { target: 'Workers/set', params }))
          .then( resolve )
          .catch( reject )
      });
    },
    validateWorker() {
      const target = 'Workers/validateWorker';
      const params = { 
        id: this.idWorker, 
        validatedWorkingTime: this.$refs.worker.model.validatedWorkingTime,
        holiday: this.$refs.worker.model.holiday,
        absenceType: this.$refs.worker.model.absenceType,
        comments: this.$refs.worker.model.comments
      };

      if(this.$refs.worker.model.validated) {
        this.$store.dispatch( 'console/error', 'El fichaje ya estaba validado.');
      }
      else {
        this.validating = true;
        
        this.$store
          .dispatch( 'api', { target, params })
          .then( response => {
            this.set( response );
            this.$refs.worker.model.validated = true;
            this.$store.dispatch( 'console/success', 'Fichaje validado.');
          })
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.validating = false );
      }
    },
    setBreadcrumbs() {
      this.setTitle();
      if ( this.breadcrumbs[1].text === '...' && this.$route.params.index ) {
        const id = parseInt( this.$route.params.id );
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          this.setTitle(null,res.title);
        });
      }
    }
  },
  mounted() {
    this.setBreadcrumbs();
  }
};
</script>

<template>
  <v-form ref="form" v-model="valid">
    <slot/>
  </v-form>
</template>

<script>

  import { get, set } from '@/utils';

  export default {
    props: {
      value: Object,
      alwaysChanged: Boolean
    },
    data() {
      return {
        fields: [],
        watchers: [],
        changed: {},
        loading: {},
        model: this.value || {},
        valid: false
      }
    },
    watch: {
      value( value ) {
        this.model = value || {};
        this.refresh();
      },
      changed: {
        handler( values ) {
          if ( this.alwaysChanged ) this.$emit( 'change', true );
          else this.$emit( 'change', Object.values( values ).includes( true ));
        },
        deep: true,
        immediate: true
      },
      loading: {
        handler( values ) {
          this.$emit( 'loading', Object.values( values ).includes( true ));
        },
        deep: true,
        immediate: true
      },
      model: {
        handler( value ) {
          this.$emit( 'input', value );
        },
        deep: true,
        immediate: true
      },
      valid( value ) {
        this.$emit( 'validate', value );
      }
    },
    methods: {
      register( field ) {
        this.fields.push( field );
        this.watchers.push( this.watchField( field ));
      },
      set( key, value ) {
        const field = this.fields.find( f => f.$vnode.key === key );
        if ( field ) field.setValue( value );
      },
      unregister( field ) {

        const index = this.fields.findIndex( i => i._uid === field._uid );
        if ( index < 0 ) return;

        const watch = this.watchers.findIndex( i => i._uid === field._uid );
        if ( watch !== -1 ) {
          this.watchers[watch].loading();
          this.watchers[watch].changed();
          this.watchers[watch].model();
        }

        this.fields.splice( index, 1 );
        this.watchers.splice( watch, 1 );
        this.$delete( this.changed, field._uid );
      },
      watchField( field ) {
        return {
          _uid: field._uid,
          changed: field.$watch( 'changed',
            v => this.$set( this.changed, field._uid, v ),
            { inmediate: true }
          ),
          model: field.$watch( 'model',
            v => {
              const { key } = field.$vnode;
              if ( key ) {
                set( this.model, key, v );
                this.$emit( 'input', this.model );
              }
            },
            { inmediate: true }
          ),
          loading: field.$watch( 'isLoading',
            v => this.$set( this.loading, field._uid, v ),
            { inmediate: true }
          )
        }
      },
      refresh( initialize ) {
        this.fields.forEach( field => {
          const { key } = field.$vnode;
          if ( key ) {
            const value = get( this.model, key );
            field.setValue( value, initialize );
          }
        })
      },
      validate() {
        return this.$refs.form.validate();
      },
      reset() {
        this.fields.forEach( field => field.reset());
        this.resetValidation();
      },
      resetValidation() {
        return this.$refs.form.resetValidation();
      }
    },
    provide() {
      return {
        dform: {
          register: this.register,
          unregister: this.unregister
        }
      };
    },
    mounted() {
      this.refresh( true );
    }
  }
</script>

<template>
  <DForm ref="form" :value="value" v-on="{ ...$listeners, input }" always-changed>

    <Survey
      class="pa-4 pb-2"
      :value="model"
      :readonly="readonly"
      @input="change"
    >
      <template v-slot:info>

        <p class="caption tertiary--text mb-1">
          <v-icon color="tertiary" v-text="'$user'" x-small/>
          {{ user }}
        </p>

        <p v-if="place" class="caption tertiary--text mb-1">
          <v-icon color="tertiary" v-text="'$place'" x-small/>
          {{ place }}
        </p>

      </template>
    </Survey>

    <div class="pt-0 pa-4">

      <p class="body-grupo-ot mb-2">¿Que valoración le dás al promotor?</p>

      <v-rating
        :value="model.value/2"
        @input="ratingInput"
        color="yellow darken-3"
        background-color="border"
        half-increments
        hover
      />

    </div>

  </DForm>
</template>

<script>

  import Survey from './survey.vue';
  import { userName, direction } from '@/utils';

  export default {
    components: { Survey },
    props: {
      value: Object,
      readonly: Boolean
    },
    data: function() {
      return {
        model: {}
      }
    },
    computed: {
      user() {
        return userName( this.model.user, true );
      },
      place() {
        return direction( this.model.place );
      }
    },
    methods: {
      input( value ) {
        this.model = value;
        if ( this.$listeners.input )
          this.$listeners.input( value );
      },
      ratingInput( value ) {
        this.model.value = value * 2;
        this.input( this.model );
      },
      change() {
        this.$emit( 'input', this.model );
      },
      reset() {
        this.$refs.form.reset();
        this.model = { ...this.model };
      }
    }
  }
</script>

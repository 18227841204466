
import { upperCaseOn } from './index';

function PAD( n ) {
  if ( n < 10 ) return '0' + n;
  return n;
}

export const MonthList = Array.apply( null, { length: 12 }).map(( a, month, date ) => {
  date = new Date( 0, month, 1 );
  return upperCaseOn( date.toLocaleString( 'es-es', { month: "long" }));
});

export function Month( date ) {

  date = date ? new Date( date ) : new Date();
  const year   = date.getFullYear();
  const month  = date.getMonth();
  const day    = date.getDate();
  const offset = new Date( year, month, 31 ).getDate();
  const length = offset < 31 ? 31 - offset : 31;
  const min    = new Date( year, month, 1 );
  const max    = new Date( year, month, length );
  const start  = min.getDay();
  const end    = max.getDay();
  const name   = MonthList[month];

  return { min, max, start, end, year, month, day, length, name };
}

export function Format( date, options ) {
  return new Date( date ).toLocaleString( navigator.language, options );
}


export function minutes( str ) {

const parts = str.split(':');

if(parts.length==2){
return parts[0] * 60 + parts[1] * 1;
}
return 0;
}

export function toISO( value, withTime ) {
  if ( value ) {
    value = withTime
      ? new Date( value ).toISOString().substr( 0, 19 ).replace( 'T', ' ' )
      : new Date( value ).toISOString().substr( 0, 10 );
  }
  return value;
}

export function DateToString( date, time ) {

  date = date ? new Date( date ) : new Date();
  const output = [[ date.getFullYear(), date.getMonth() + 1, date.getDate() ].map( PAD ).join( '-' )];

  if ( time != null ) {
    var h = Math.floor( time / 60 );
    var m = Math.round(( h - Math.floor( time / 60 )) * 60 );
    date.setHours( h, m );
    output.push([ date.getHours(), date.getMinutes() ].map( PAD ).join( ':' ));
  }

  return output.join(' ');
}

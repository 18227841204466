<template>
  <TableDialog
    :value="value"
    :exclude="exclude"
    :headers="headers"
    :title="title"
    @input="$emit('input',$event)"
    @apply="$emit('apply',$event)"
  />
</template>

<script>

  import TableDialog from '@/components/base/ListSelector/dialog';

  export default {
    components: { TableDialog },
    props: {
      value: null,
      exclude: Array,
      title: {
        type: String,
        default: 'Centros'
      }
    },
    data() {
      return {
        headers: [
          { text: 'Nombre', value: 'name' },
          {
            text: 'Cadena', value: 'channel', display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Channels',
                itemText: 'name',
                itemValue: 'id'
              }
            }
          },
          { text: 'Área', value: 'area' },
          { text: 'Dirección', value: 'street' },
          { text: 'Ciudad', value: 'city', width: 150 },
          { text: 'Provincia', value: 'province', width: 150 },
          { text: 'CP', value: 'postalCode', width: 75 },
          { text: 'Telefono', value: 'phone', width: 100 }
        ]
      }
    }
  }
</script>

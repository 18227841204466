<template>
  <v-row class="fill-height" no-gutters>
    <div class="grow rel">
      <v-row class="stats-boxes layer autoscroll pa-2" no-gutters>
        <v-col cols="12" md="12">

          <v-subheader class="title">
            Jornadas
            <span class="body-grupo-ot ml-2">({{ computedDates.current }})</span>
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2">

            <Btn
              color="secondary"
            style="color:white !important;"
              @click="$emit( 'click-item', 'workers' )"
              small dark
              absolute top right
            >
              Ver
            </Btn>

            <DStatsProgress
              title="Activos"
              class="ma-8"
              color="#ddd"
              :value="workers.percent"
              :min-to-success="90"
              :min-to-warning="75"
            />

            <DStats
              title="Estado"
              class="ma-8"
              v-bind="propStats"
              :items="workers.status"
              style="max-width:400px"
              cols="4" sm="4" md="4" lg="4"
              @click:bar="clickBar('workers', $event)"
            />

          </v-card>
        </v-col>
        <v-col cols="12" md="6">

          <v-subheader class="title">
            Tiempo trabajado
            <span class="body-grupo-ot ml-2">({{ computedDates.current }})</span>
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2">

            <Btn
              color="secondary"
            style="color:white !important;"
              @click="$emit('click-item', 'workers')"
              small dark
              absolute top right
            >
              Ver
            </Btn>

            <DStatsProgress
              title="Trabajado"
              class="ma-8"
              color="#ddd"
              :value="workingTime.percent"
              :min-to-success="97"
              :min-to-warning="90"
            />

            <DStats
              title="Horas de trabajo"
              class="ma-8"
              v-bind="propStats"
              cols="12" sm="12" md="12" lg="12"
              :items="workingTime.status"
            />

          </v-card>
        </v-col>
        <v-col cols="12" md="6">

          <v-subheader class="title">
            Informes
            <span class="body-grupo-ot ml-2">({{ computedDates.current }})</span>
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2">

            <Btn
              color="secondary"
            style="color:white !important;"
              @click="$emit('click-item', 'tasks')"
              small dark
              absolute top right
            >
              Ver
            </Btn>

            <DStatsProgress
              title="Validados"
              class="ma-8"
              :value="tasks.percent"
              :min-to-success="97"
              :min-to-warning="90"
            />

            <DStats
              title="Estado de los informes"
              class="ma-8"
              :items="tasks.status"
              style="max-width:250px"
              cols="6" sm="6" md="6" lg="6"
              @click:bar="clickBar('tasks', $event)"
              v-bind="propStats"
            />

          </v-card>
        </v-col>
        <v-col v-if="isToday" cols="12">

          <v-subheader class="title">
            Cobertura en próximas incorporaciones
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2">

            <Btn
              color="secondary"
              :to="campaignCentersUri"
              small dark
              absolute top right
            >
              Centros
            </Btn>

            <DStatsProgress
              title="Cobertura"
              class="ma-8"
              :value="places.percent"
              :min-to-success="97"
              :min-to-warning="90"
            />

            <DStats
              title="Proximas incorporaciones"
              class="ma-8"
              v-bind="propStats"
              style="max-width:260px"
              cols="6" sm="6" md="6" lg="6"
              :items="places.status"
            />

            <ListDetails
              v-model="selected"
              title="Detalles por campaña"
              class="ma-8"
              style="flex: 1 0"
              cols="6" sm="6" md="6" lg="6"
              :items="places.campaigns"
            />

          </v-card>
        </v-col>
        <v-col cols="12" md="12">

          <v-subheader class="title">
            Incidencias
            <span class="body-grupo-ot ml-2">({{ computedDates.current }})</span>
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2">

            <Btn
              color="secondary"
            style="color:white !important;"
              @click="$emit('click-item', 'issues')"
              small dark
              absolute top right
            >
              Ver
            </Btn>

            <DStatsProgress
              title="General"
              class="ma-8"
              :value="currentIssues.issues.percent"
              :min-to-success="21"
              :min-to-warning="11"
              success-color="error"
              error-color="success"
            />

            <DStatsCheese
              title="Tipos de incidencia"
              class="ma-8"
              color="#ddd"
              :colors="propStats.colors"
              :items="currentIssues.issues.types"
            />

            <template v-if="currentIssues.clients.status.length > 0">

              <DStatsCheese
                v-if="currentIssues.clients.status.length > 0"
                title="Clientes"
                class="ma-8"
                color="#ddd"
                :colors="propStats.colors"
                :items="currentIssues.clients.types"
              />

              <DStats
                v-if="currentIssues.clients.status.length > 0"
                title="Incidencias por cliente"
                class="ma-8"
                v-bind="propStats"
                :items="currentIssues.clients.status"
                cols="6" sm="6" md="6" lg="6"
                @click:bar="clickBar('client-issues', $event)"
              />

            </template>

            <DStats
              v-else
              title="Estado de las incidencias"
              class="ma-8"
              v-bind="propStats"
              :items="currentIssues.issues.status"
              cols="6" sm="4" md="4" lg="2"
              @click:bar="clickBar('current-issues', $event)"
            />

          </v-card>
        </v-col>
        <v-col cols="12" md="12">

          <v-subheader class="title">
            Incidencias del día anterior
            <span class="body-grupo-ot ml-2">({{ computedDates.last }})</span>
          </v-subheader>

          <v-card class="d-flex flex-wrap ma-2" color="#fcfcfc">

            <Btn
              color="secondary"
            style="color:white !important;"
              @click="$emit('click-item', 'past-issues')"
              small absolute
              top right dark
            >
              Ver
            </Btn>

            <DStatsProgress
              title="General"
              class="ma-8"
              :value="lastIssues.issues.percent"
              :min-to-success="21"
              :min-to-warning="11"
              success-color="error"
              error-color="success"
            />

            <DStatsCheese
              title="Tipos de alarma"
              class="ma-8"
              color="#ddd"
              :colors="propStats.colors"
              :items="lastIssues.issues.types"
            />

            <template v-if="lastIssues.clients.status.length > 0">

              <DStats
                title="Estado de las alarmas"
                class="ma-8"
                v-bind="propStats"
                :items="lastIssues.issues.status"
                cols="6" sm="6" md="4" lg="6"
                @click:bar="clickBar('last-issues', $event)"
              />

              <DStatsCheese
                v-if="lastIssues.clients.status.length > 0"
                title="Clientes"
                class="ma-8"
                color="#ddd"
                :colors="propStats.colors"
                :items="lastIssues.clients.types"
              />

              <DStats
                v-if="lastIssues.clients.status.length > 0"
                title="Incidencias por cliente"
                class="ma-8"
                v-bind="propStats"
                :items="lastIssues.clients.status"
                cols="6" sm="6" md="4" lg="6"
                @click:bar="clickBar('client-issues', $event)"
              />

            </template>

            <DStats
              v-else
              title="Estado de las alarmas"
              class="ma-8"
              v-bind="propStats"
              :items="lastIssues.issues.status"
              @click:bar="clickBar('last-issues', $event)"
            />

          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-expand-x-transition>
      <div v-show="openFilters" class="shrink rel" style="width: 300px">
        <Filters v-model="search" class="layer autoscroll" />
      </div>
    </v-expand-x-transition>
  </v-row>
</template>

<script>
import Filters from "./filters";
import ListDetails from "./ListDetails";
import moment from "moment";
import { mapState } from "vuex";
import { round } from "@/utils";
import { IssueStatus } from "@/utils/constants";
import colors from "@/utils/colors";

export default {
  components: { Filters, ListDetails },
  props: {
    showFilters: Boolean,
    section: {
      type: String,
      default: "0-0",
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      openFilters: !!this.showFilters,
      search: this.value || {},
      selected: 0,
      selected2: 0,
      propStats: {
        color: "tertiary",
        colors,
      },
    };
  },
  computed: {
    ...mapState(["core"]),
    date() {
      return this.search.date ? moment(this.search.date) : moment();
    },
    computedDates() {
      return {
        current: this.date.format("DD/MM/YYYY"),
        last: this.date.add(-1, "day").format("DD/MM/YYYY"),
      };
    },
    isToday() {
      return (this.core && this.core.showPlaces) || false;
    },
    workingTime() {

      const { core } = this;
      const data = {
        percent: 0,
        estimated: core.totalEstimatedTime || 0,
        absented: core.totalAbsenceTime || 0,
        worked: core.totalWorkedTime || 0,
        clients: [],
        status: [
          { key: "estimated", name: "Horas estimadas", value: 0 },
          { key: "worked", name: "Horas trabajadas", value: 0 },
          { key: "absented", name: "Horas de ausencia", value: 0 },
        ],
      };

      data.status[0].value = round(data.estimated / 60, 1);
      data.status[1].value = round(data.worked / 60, 1);
      data.status[2].value = round(data.absented / 60, 1);

      var trunc = (v) => v - (v % 1);
      var ceil = (v) => trunc(v % 1 > 0 ? v + 1 : v);

      if (data.estimated > 0) {
        data.percent = ceil((data.worked / data.estimated) * 100);
      } else {
        data.percent = 100;
      }

      var keys = Object.keys(core.clients||{});
      keys.forEach((key) => {
        var it = core.clients[key];
        const client = {
          name: it.name,
          id: it.id,
          percent: 0,
          estimated: it.totalEstimatedTime || 0,
          absented: it.totalAbsenceTime || 0,
          worked: it.totalWorkedTime || 0,
          progress: {
            value: 0
          },
          stats: {
            items: [
              { key: "estimated", name: "Horas estimadas", value: 0 },
              { key: "worked", name: "Horas trabajadas", value: 0 },
              { key: "absented", name: "Horas de ausencia", value: 0 },
            ]
          }
        };

        client.stats.items[0].value = round(client.estimated / 60, 1);
        client.stats.items[1].value = round(client.worked / 60, 1);
        client.stats.items[2].value = round(client.absented / 60, 1);


        if (client.estimated > 0) {
          client.progress.value = ceil((client.worked / client.estimated) * 100);
        } else {
          client.progress.value = 100;
        }

        data.clients.push(client);
      });

      return data;
    },
    workers() {
      const { core } = this;
      const workers = {
        percent: 0,
        status: [
          { key: "active", name: "Activos", value: 0 },
          { key: "paused", name: "Pausados", value: 0 },
          { key: "late", name: "Llegan tarde", value: 0 },
          { key: "finished", name: "Finalizados", value: 0 },
          { key: "notStarted", name: "No iniciados", value: 0 },
          { key: "absence", name: "Ausencias", value: 0 },
        ],
      };

      if (core.workers) {
        if (!this.isToday) {
          workers.status.splice(0, 3);
        }

        var total = 0;
        workers.status.forEach((s) => {
          s.value = core.workers[s.key];
          total += s.value;
        });

        // Percentage
        if (total) {
          if (this.isToday) {
            workers.percent = round(
              ((total - core.workers.late - core.workers.absence) / total) *
                100,
              1
            );
          } else {
            workers.percent = round((core.workers.finished / total) * 100, 1);
          }
        }
      }

      return workers;
    },
    campaignCentersUri() {
      const { selected, places } = this;
      return places.campaigns[selected]
        ? `/campaigns/${places.campaigns[selected].id}/centers`
        : "";
    },
    places() {

      const { core } = this;
      const places = {
        percent: 0,
        campaigns: [],
        status: [
          { key: "total", name: "Posiciones totales", value: 0 },
          { key: "opened", name: "Processos de selección abiertos", value: 0 },
          { key: "inProgress", name: "Trabajadores en proceso", value: 0 },
          { key: "completed", name: "Trabajadores incorporados", value: 0 },
        ],
      };

      // Campaigns

      if (core.places) {
        places.status.forEach((s) => (s.value = core.places[s.key]));
        places.campaigns = Object.values(core.places.campaigns).map((c) => {
          return {
            id: c.idCampaign,
            name: c.name,
            progress: {
              value: round((c.completed / c.total) * 100, 1)
            },
            stats: {
              items: places.status.map((s) => ({ ...s, value: c[s.key] }))
            }
          };
        });

        // Percentage
        if (core.places.total) {
          places.percent = round((core.places.completed / core.places.total) * 100, 1 );
        }
      }

      return places;
    },
    tasks() {
      const { core } = this;
      const tasks = {
        percent: 0,
        status: [
          { key: "done", name: "Realizadas", value: 0 },
          { key: "validated", name: "Validadas", value: 0 },
          { key: "pending", name: "Pendientes", value: 0 },
          { key: "expired", name: "Caducadas", value: 0 },
        ],
      };

      if (core.tasks) {
        tasks.status.forEach((s) => (s.value = core.tasks[s.key]));

        // Percentage
        if (core.tasks.total) {
          var num = core.tasks.done + core.tasks.validated + core.tasks.pending;
          tasks.percent = round(
            (num / (core.tasks.total + core.tasks.expired)) * 100,
            1
          );
        }
      }

      return tasks;
    },
    issues() {
      const { core } = this;
      return {
        current: this.separeIssues(core.issues || []),
        past: this.separeIssues(core.pastIssues || []),
      };
    },
    currentIssues() {
      return {
        issues: this.computeIssues(this.issues.current.issues),
        working: this.computeIssues(this.issues.current.working),
        alarms: this.computeIssues(this.issues.current.alarms),
        clients: this.computeIssuesByClient(this.issues.current.issues),
      };
    },
    lastIssues() {
      return {
        issues: this.computeIssues(this.issues.past.issues),
        working: this.computeIssues(this.issues.past.working),
        alarms: this.computeIssues(this.issues.past.alarms),
        clients: this.computeIssuesByClient(this.issues.past.issues),
      };
    },
  },
  watch: {
    value(value) {
      this.search = value || {};
    },
    showFilters(value) {
      this.openFilters = !!value;
    },
    search(value) {
      const filters = [];
      if (value.date) filters.push({ field: "date", doubleValue: value.date });
      if (value.client)
        filters.push({ field: "client", intValue: value.client.id });
      if (value.campaign)
        filters.push({ field: "campaign", intValue: value.campaign.id });
      if (value.channel)
        filters.push({ field: "channel", intValue: value.channel.id });
      this.$emit("filters", filters);
      this.$emit("input", value);
    },
  },
  methods: {
    separeIssues(issues) {
      return {
        issues: issues,
        working: [],
        alarms: [],
      };
    },
    computeIssues(issues) {
      const info = {
        percent: 0,
        types: [],
        status: [
          { key: IssueStatus.CREATED, name: "Abiertas", value: 0 },
          { key: IssueStatus.TRAMITED, name: "Pendientes", value: 0 },
          { key: IssueStatus.MORE_INFO, name: "Ampliar información", value: 0 },
          {
            key: IssueStatus.RESOLUTION,
            name: "Gestión para resolución",
            value: 0,
          },
          { key: IssueStatus.CLOSED, name: "Cerradas", value: 0 },
        ],
      };

      var name, type, status;
      var total = 0,
        opened = 0,
        color = 0;
      issues.forEach((issue) => {
        // Status
        if ((status = info.status.find((s) => s.key === issue.status))) {
          status.value++;
          if (issue.status === IssueStatus.CREATED) opened++;
        } else {
          return;
        }

        // Type
        name = issue.issueCategory.name;
        if (issue.issueCategory.parent)
          name = issue.issueCategory.parent.name + " - " + name;

        if ((type = info.types.find((t) => t.name === name))) {
          type.value++;
        } else {
          info.types.push({
            name,
            color: colors[color % colors.length],
            value: 1,
          });
          color++;
        }

        total++;
      });

      // Percentage
      if (total) {
        info.percent = round((opened / total) * 100, 1);
      }

      return info;
    },
    computeIssuesByClient(issues) {
      const info = {
        types: [],
        status: [],
      };

      var type;
      var color = 0;
      issues.forEach((issue) => {
        var cmp = issue.campaign;
        var key = -1;
        var name = "Sin cliente";
        if (cmp != null && cmp.client != null) {
          key = cmp.client.id;
          name = cmp.client.name;
        }

        var clients = info.status.filter((it) => it.key == key);
        var client = { key: key, name: name, value: 0 };

        if (clients.length > 0) {
          client = clients[0];
        } else {
          info.status.push(client);
        }

        client.value = client.value + 1;

        // Type

        // Type
        if ((type = info.types.find((t) => t.name === name))) {
          type.value++;
        } else {
          info.types.push({
            name: name,
            color: colors[color % colors.length],
            value: 1,
          });
          color++;
        }
      });

      return info;
    },
    clickBar(type, event) {
      this.$emit("click-bar", { type, ...event });
    },
  },
};
</script>

<style>
.stats-boxes > div {
  display: flex;
  flex-direction: column;
}
.stats-boxes > div > .v-card {
  flex: 1 0 auto;
}
</style>

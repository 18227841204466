<template>
  <div class="d-stats" :class="{ 'd-stats--empty': isEmpty }" v-resize="onResize">
    <div v-if="title" class="d-stats--title">{{ title }}</div>
    <div v-if="showUnits && units" class="d-stats--units">{{ units }}</div>
    <v-sheet :height="height" color="transparent" tile>
      <v-row ref="body" class="fill-height" no-gutters>

        <div v-if="showUnits" class="d-stats--values">
          <div v-for="( value, i ) in rows" :key="i" :style="rowStyle">
            <span>{{ value }} -</span>
          </div>
          <div>
            <span>0 -</span>
          </div>
        </div>

        <div class="d-stats--body" :class="{['click-bar']: hasClickBar }">
          <div class="fill-height">
            <v-tooltip
              v-for="( bar, index ) in internalValues.items"
              color="transparent"
              :key="index"
              top
            >
              <template v-slot:activator="{ on }">
                <div
                  class="d-stats--bar"
                  :style="`height:${bar.percent}%`"
                  v-on="on"
                >
                  <v-sheet :color="color">
                    <div
                      :style="`background-color:${bar.color}`"
                      @click="$emit( 'click:bar', { bar, index })"
                    />
                    <span class="d-stats--bar-value" @click="$emit( 'click:bar', { bar, index })">
                      {{ bar.value }}
                    </span>
                  </v-sheet>
                </div>
              </template>
              <v-sheet class="body-grupo-ot pa-2" rounded dark>
                {{ bar.name }} ({{ bar.value }})
              </v-sheet>
            </v-tooltip>
          </div>
        </div>
      </v-row>
    </v-sheet>

    <ul class="d-stats--info" v-if="showLegend">
      <li
        v-for="( item, index ) in internalValues.items"
        :class="computedColunms + ' py-1 px-4'"
        :style="`color:${item.color}`"
        :key="index"
      >
        <span class="d-stats--text">
          {{ item.name }}
        </span>
      </li>
    </ul>

  </div>
</template>

<script>

  import { round } from '@/utils';

  export default {
    props: {
      title: String,
      color: String,
      colors: {
        type: Array,
        default: () => []
      },
      showLegend: {
        type: Boolean,
        default: () => true
      },
      legendColumns: Boolean,
      units: String,
      height: {
        type: [ Number, String ],
        default: 140
      },
      items: {
        type: Array,
        default: () => []
      },
      showUnits: {
        type: Boolean,
        default: false
      },
      cols: {
        type: [ Number, String ],
        default: 6
      },
      sm: {
        type: [ Number, String ],
        default: 4
      },
      md: {
        type: [ Number, String ],
        default: 3
      },
      lg: {
        type: [ Number, String ],
        default: 2
      }
    },
    data: () => ({
      minHeightRow: 25,
      bodyHeight: 0
    }),
    computed: {
      computedColunms() {
        let classes = [];
        ['cols','sm','md','lg'].forEach( type => {
          if ( this[type] ) {
            if ( type === 'cols' ) {
              classes.push(`col-${this[type]}`);
            } else {
              classes.push(`col-${type}-${this[type]}`);
            }
          }
        });
        return classes.join(' ');
      },
      internalValues() {

        // Prepare items

        const items = this.items.map( item => {
          if ( typeof item !== 'object' ) item = { value: Number( item ) };
          item.value = Number( item.value ) || 0;
          return item;
        });

        // Prepare colors

        const colors = this.colors.filter( Boolean );

        // Get total

        let max = Math.ceil( Math.max( ...items.map( a => a.value )));
        if ( max < 0 ) max = 0;

        // Calc percents

        items.forEach(( item, index ) => {
          item.name = item.name || 'item - ' + ( index + 1 );
          item.color = item.color || colors[ index % colors.length ];
          item.percent = max ? ( item.value / max ) * 100 : 0;
        });

        return { items, max };
      },
      isEmpty() {
        return !this.internalValues.max;
      },
      rows() {

        let { max } = this.internalValues;
        const { bodyHeight, minHeightRow } = this;
        const rows  = max ? Math.floor( bodyHeight / minHeightRow ) : 1;
        const units = max / rows;

        return Array.from({ length: rows }, ( a, index ) => round(( max || 1 ) - units * index, 1 ));
      },
      rowStyle() {
        return {
          height: ( 100 / this.rows.length ) + '%'
        };
      },
      hasClickBar() {
        return !!this.$listeners['click:bar'];
      }
    },
    methods: {
      onResize() {
        const { body } = this.$refs;
        if ( body ) this.bodyHeight = body.clientHeight;
      }
    },
    mounted() {
      this.onResize();
    }
  }
</script>

<style>
  .d-stats {
    flex: 1 1;
    vertical-align: top;
  }
  .d-stats--values {
    height: 100%;
    flex: 0 0 auto;
    margin-right: 1rem;
    text-align: right;
  }
  .d-stats--body {
    height: 100%;
    flex: 0 1 auto;
    position: relative;
  }
  .d-stats--body > div {
    display: flex;
    align-items: flex-end;
  }
  .d-stats--info {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0 !important;
    margin-top: 14px;
    list-style: none;
    line-height: 1.2;
  }
  .d-stats--info > li {
    position: relative;
  }
  .d-stats--info > li::before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 10px;
    height: 10px;
    background-color: currentColor;
    border-radius: 50%;
  }
  .d-stats--text {
    color: rgba(0,0,0,.78);
    font-size: 1rem;
  }
  .d-stats--body.click-bar .d-stats--bar {
    cursor: pointer;
  }
  .d-stats--bar {
    flex: 1 1;
    min-width: 50px;
    min-height: 1px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: bottom;
    position: relative;
  }
  .d-stats--bar > div, .d-stats--bar > div > div {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0 0;
  }
  .d-stats--empty .d-stats--bar {
    position: relative;
    height: 100% !important;
  }
  .d-stats--empty .d-stats--bar > div {
    height: 1px;
    bottom: 0;
  }
  .d-stats--values span {
    color: inherit;
    font-size: 8px;
    display: block;
    line-height: 0;
    color: #777;
  }
  .d-stats--units {
    font-size: 10px;
    margin-bottom: 1rem;
    color: #333;
    padding-left: .5rem;
  }
  .d-stats--bar-value {
    display: block;
    position: absolute;
    top: -1.5rem;
    width: 100%;
    text-align: center;
    font-size: .8rem;
    white-space: nowrap;
  }
  .d-stats--title {
    margin-bottom: 2rem;
    font-size: 1.2rem;
  }
</style>

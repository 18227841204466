import APIService from './class';

class Chat extends APIService {
  constructor() {
    super( '/chat' );
  }
  getByUser( token, params, options ) {
    return this.action( 'get', '/getByUser', null, { ...options, params }, token );
  }
  messages( token, params, options ) {
    return this.action( 'post', '/messages/page', params, options, token );
  }
  addMessage( token, params, options ) {
    return this.action( 'post', '/message/add', params, options, token );
  }
  removeMessage( token, params, options ) {
    return this.action( 'delete', '/message/remove', params, options, token );
  }
  addUser( token, params, options ) {
    return this.action( 'post', '/user/add', params, options, token );
  }
  removeUser( token, params, options ) {
    return this.action( 'delete', '/user/remove', params, options, token );
  }
  read( token, params, options ) {
    return this.action( 'post', '/read', params, options, token );
  }
}

export default new Chat();

import APIService from './class';

class Upload extends APIService {
  constructor() {
    super( '/upload' );
  }
  image( token, params, options ) {
    return this.action( 'post', '/image', params, options, token, 'multipart/form-data' );
  }
  file( token, params, options ) {
    return this.action( 'post', '/file', params, options, token, 'multipart/form-data' );
  }
}

export default new Upload()

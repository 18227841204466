export default {
  set( state, options ) {
    var n, key;
    for ( key in options ) {
      switch ( key ) {
        case 'chats': case 'issues':
        case 'tasks': case 'evaluations':
          if (( n = Math.floor( options[key] / 1000000 ))) {
            state[key] = n + 'M';
          } else if (( n = Math.floor( options[key] / 1000 ))) {
            state[key] = n + 'K';
          } else {
            state[key] = options[key];
          }
          break;
        default:
          state[key] = options[key];
      }
    }
  }
};

import APIService from './class';

class Evaluations extends APIService {
  constructor() {
    super( '/evaluation' );
  }
  all( token, params, options ) {
    return this.action( 'post', '/users/page', params, options, token );
  }
  setCampaign( token, params, options ) {
    return this.action( 'post', '/campaign/set', params, options, token );
  }
  setResponse( token, params, options ) {
    return this.action( 'post', '/user/response', params, options, token );
  }
  export( token, params, options ) {
    return this.action( 'post', '/users/export', params, options, token );
  }
  removeEvaluation( token, params, options ) {
    return this.action( 'delete', '/user/remove/ids', params, options, token );
  }
}

export default new Evaluations();

<template>
  <InfiniteLoading v-bind="$attrs" v-on="$listeners">

    <v-progress-circular
      slot="spinner"
      class="ma-4"
      color="border"
      size="30"
      width="3"
      indeterminate
    />

    <div slot="error" slot-scope="{ trigger }">
      <p>{{ errorMessage || 'Error al cargar :(' }}</p>
      <Btn color="primary" @click="trigger" small>
        Reintentar
      </Btn>
    </div>

    <span slot="no-more"/>
    <span slot="no-results"/>

  </InfiniteLoading>
</template>

<script>

  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    components: { InfiniteLoading },
    props: {
      errorMessage: String
    }
  }
</script>

<template>
  <Layout :breadcrumbs="breadcrumbs">
    <template v-slot:top>

      <v-progress-linear
        v-if="load"
        color="primary"
        indeterminate
        absolute
      />

      <Toolbar border-bottom>

        <Btn @click="$router.back()" active-class="transparent" color="secondary" text>
          <v-icon class="mr-1">$prev</v-icon>
          Atrás
        </Btn>

        <v-spacer/>

        <slot name="top"/>

        <div v-if="showCampaignSelector" style="flex: 1 1 300px; max-width: 300px;">
          <DField
            :exclude="exclude"
            :finded-items="campaigns"
            :disable-search="!!campaigns"
            @input="onSelectCampaign"
            @fetch="onFetchCampaigns"
            service="Campaigns"
            method="all"
            label="Ir a la campaña..."
            field="autocomplete"
            field-style="1"
            item-text="title"
            item-value="id"
            dense
            all
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <span class="border--text">{{ itemState(item) }}</span>
                <span class="ml-1">{{ item.title }}</span>
              </v-list-item>
            </template>
          </DField>
        </div>

      </Toolbar>

    </template>

    <slot v-bind="scope"/>

    <template v-slot:footer>
      <Toolbar slot="footer" tag="footer" border-top>
        <slot name="actions" v-bind="scope">
          <template v-if="!isRemoved">

            <Btn v-if="showRemove" class="mr-2" color="secondary" text
            @click="onremove('¿Confirma que desea eliminar $item?', true )" >
              Eliminar
            </Btn>

            <Btn v-if="showArchive" class="mr-2" color="secondary" text @click="onarchive">
              Archivar
            </Btn>

            <v-spacer/>

            <Btn v-if="showActive" @click="onactive" :disabled="!isActivable"
            :dark="isActivable" class="mr-2" color="tertiary">
              Activar
            </Btn>

            <Btn v-if="showSave" @click="onsave()" :disabled="!isSubmitable" color="primary">
              Guardar {{ showActive && isNew ? 'borrador' : '' }}
            </Btn>

          </template>
        </slot>
      </Toolbar>
    </template>
  </Layout>
</template>

<script>

  import Layout from '@/components/core/Layout.vue';
  import { mapMutations, mapState } from 'vuex';
  import { CampaignStatus } from '@/utils/constants';

  export default {
    components: { Layout },
    props: {
      service: String,
      subservice: String,
      messages: [ String, Object ],
      initialize: Function,
      sanitize: Function,
      validate: Function,
      activable: Function,
      update: Function,
      clean: Function,
      formInput: Function,
      loading: Boolean,
      hideFooter: Boolean,
      disableCampaignSelector: Boolean,
      btnCleanText: String,
      section: {
        type: Array,
        default: () => [0,0]
      },
      autoId: {
        type: Boolean,
        default: true
      },
      showActions: {
        type: [ Boolean, String, Array ],
        default: 'save'
      }
    },
    data() {
      return {
        load: false,
        model: {},
        changed: false,
        validating: false,
        valid: false,
        listeners: {
          change:   v => this.changed = v,
          loading:  v => this.validating = v,
          validate: v => this.valid = v
        }
      }
    },
    watch: {
      $route: 'reload',
      loading( value ) {
        this.load = value;
      },
      load( value ) {
        this.setBlock( value );
      }
    },
    computed: {
      ...mapState([ 'view', 'core', 'breadcrumbs', 'path', 'campaigns' ]),
      id() {
        if ( this.isNew ) return -1;
        return parseInt( this.$route.params.id );
      },
      value() {
        if ( this.isNew ) return {};
        return this.core || {};
      },
      parent() {
        const path = this.$route.path.split('/');
        return {
          name: path[ path.length - 2 ],
          path: path.slice( 0, -1 ).join('/')
        };
      },
      isSubmitable() {
        const { model, valid, changed, validating } = this;
        if ( this.validate ) {
          if ( this.validate({ value: model, valid, changed, validating }) === false ) {
            return false;
          }
        } else if ( ! model || ! valid || validating || ! changed ) {
          return false;
        }
        return true;
      },
      isActivable() {
        const { model, valid, changed, validating } = this;
        if ( this.activable ) {
          if ( this.activable({ value: model, valid, changed, validating }) === false ) {
            return false;
          }
        } else if ( ! model || ! valid || validating ) {
          return false;
        }
        return true;
      },
      isNew() {
        const { id, index } = this.$route.params;
        return ( index || id ) === 'new';
      },
      isRemoved() {
        return this.model && this.model.status === CampaignStatus.DELETED;
      },
      isActive() {
        return this.model && this.model.status === CampaignStatus.ACTIVE;
      },
      isDraft() {
        return this.model && this.model.status === CampaignStatus.DRAFT;
      },
      isArchived() {
        return this.model && this.model.status === CampaignStatus.ARCHIVED;
      },
      actions() {
        var actions = this.showActions || [];
        if ( typeof actions === 'boolean' && actions ) actions = ['save'];
        else if ( typeof actions === 'string'  ) return actions.split(/\s*,\s*/);
        return actions;
      },
      scope() {
        return {
          value: this.value,
          submitable: this.isSubmitable,
          activable: this.isActivable,
          save: this.onsave,
          active: this.onactive,
          remove: this.onremove,
          listeners: this.listeners
        }
      },
      exclude() {
        //const { id } = this;
        return { status: -1 };
      },
      showCampaignSelector() {
        return !this.disableCampaignSelector && this.$route.path.search(/campaigns\/\d+/) !== -1;
      },
      showRemove() {
        return this.hasAction( 'remove' );
      },
      showArchive() {
        return this.hasAction( 'archive' ) && ( this.isActive || this.isDraft );
      },
      showActive() {
        return this.hasAction( 'active' ) && ( this.isDraft || this.isArchived );
      },
      showSave() {
        return this.hasAction( 'save' ) && !this.isArchived;
      },
      showClean() {
        return this.hasAction( 'clean' );
      }
    },
    methods: {
      ...mapMutations([ 'setView', 'setPath' ]),
      ...mapMutations( 'app', [ 'setBlock', 'setDialog' ]),
      set( state, section ) {
        section = (section || this.section) || [0,0];
        this.setView({
          path: this.path,
          state: {
            section,
            sections: {
              [section.join('')]: state
            }
          }
        });
      },
      reload() {
        if ( this.load ) return;

        const { service, subservice, id, view } = this;
        this.setPath( this.$route.fullPath );

        if ( ! service && ! subservice ) return;

        const s = this.section.join('');
        const params = { service, subservice, id };

        if ( ! view || ! view.sections || ! view.sections[s] ) {
          this.set({}, this.section );
        }

        this.load = true;

        if(this.isNew){
          this.model = {}
          this.set( this.model, this.section );
          this.$emit( 'initialized', this.model );
          this.load = false;
        }
        else{
        Promise.resolve(
          this.initialize
            ? this.initialize( params )
            : this.$store.dispatch( 'single', params )
        )
        .then( response => {
          this.set( response, this.section );
          this.$emit( 'initialized', response );
        })
        .finally(() => {
          this.load = false;
        });
        }
      },
      onsave( model ) {

        if ( ! this.isSubmitable ) return false;

        // Prepare data

        model = model || this.model;
        if ( this.sanitize ) model = this.sanitize( model );

        const target = `${ this.service }/set`;
        const params = { ...model };

        if ( params.id == null && this.autoId ) {
          params.id = this.id || -1;
        }

        // Update data

        this.load = true;
        Promise.resolve(
          this.update
            ? this.update( params )
            : this.$store.dispatch( 'api', { target, params })
        ).then( single => {

          this.set( single );
          this.$store.dispatch( 'console/success', this.message( '', 'guardad@.' ));
          this.$emit( 'saved', single );

          if ( this.isNew ){
            this.load = false;
             this.$router.push(`${ this.parent.path }/${ single.id }`);
          }
        })
        .catch( err => this.$store.dispatch( 'console/error', err ))
        .finally(() => this.load = false );
      },
      onremove( msg, exit ) {

        const options = ( typeof this.messages === 'string'
          ? { item: this.messages }
          : this.messages ) || { item: 'elemento' };

        const item = [
          options.fem ? 'la' : 'el',
          options.item.toLowerCase()
        ].join(' ');

        this.setDialog({
          show: true,
          width: 400,
          title: ( msg || '' ).replace( '$item', item ),
          text: '',
          accept: () => {

            const target = `${ this.service }/remove`;
            const params = { id: this.id };

            this.load = true;
            Promise.resolve(
              this.remove
                ? this.remove( params )
                : this.$store.dispatch( 'api', { target, params })
            ).then( single => {
              this.$store.dispatch( 'console/success', this.message( '', 'eliminad@.' ));
              if ( exit ) this.$router.push( this.parent.path );
              else this.set( single );
            })
            .catch( err => this.$store.dispatch( 'console/error', err ))
            .finally(() => this.load = false );
          }
        });
      },
      onarchive() {
        this.onsave({ ...this.model, status: CampaignStatus.ARCHIVED });
      },
      onactive() {
        this.onsave({ ...this.model, status: CampaignStatus.ACTIVE });
      },
      onclean() {
        this.load = true;
        return Promise.resolve( this.clean && this.clean({ id: this.id }))
          .catch( err => this.$store.dispatch( 'console/error', err ))
          .finally(() => this.load = false );
      },
      input( value ) {
        this.model =  { ...value };
        if ( this.formInput ) this.formInput( value );
      },
      message( before, after ) {
        const options = this.messages
          ? typeof this.messages === 'string' ? { item: this.messages } : this.messages
          : { item: 'Elemento' };

        const { item, fem } = options;
        return [ before, item, after ]
          .filter( a => a )
          .join(' ')
          .replace( /@/g, fem ? 'a' : 'o' );
      },
      onSelectCampaign( value ) {
        if(value){
          const url = this.$route.path.replace( /(campaigns\/)\d+/, '$1' + value.id );
          this.$router.push( url );
        }
      },
      onFetchCampaigns( items ) {
        console.log(items);
        //this.$store.commit( 'setCampaigns', items );
      },
      hasAction( action ) {
        return this.actions.indexOf( action ) !== -1;
      },
      itemState( item ) {
        switch ( item.status ) {
          case 0:  return '(Borrador) -';
          case -1: return '(Archivada) -';
          default: return '';
        }
      }
    },
    mounted() {
      this.listeners.input = this.input.bind( this );
      this.reload();
    }
  }
</script>

<template>
  <Single
    service="Users"
    :sanitize="sanitize"
    @initialized="setTitle"
  >
    <template v-slot="{ value, listeners }">

      <User class="pa-4" :value="value" v-on="listeners"/>

    </template>
  </Single>
</template>

<script>

  import Single from '@/components/core/Single.vue';
  import User from '@/components/forms/Users/single.vue';

  export default {
    components: { Single, User },
    methods: {
      setTitle( res ) {
        this.$store.commit( 'setTitle', [ '', res.name ]);
      },
      sanitize( m ) {
        if ( m.client ) {
          m.clientId = m.client.id;
        } else {
          m.clientId = null;
        }
        return m;
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Usuarios' );
    }
  }
</script>

<template>
  <div class="d-cheese">
    <div v-if="title" class="d-cheese--title">{{ title }}</div>
    <v-sheet color="transparent" tile>
      <div
        ref="box"
        v-bind="boxAttrs"
        @mousemove="onMouseMove"
        @mouseleave="selected = null"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :viewBox="viewBox"
          :style="`transform: rotate(${rotate}deg);`"
        >
          <circle class="d-cheese--base" :cx="viewBoxSize*2" :cy="viewBoxSize*2" :r="radius"/>
          <template v-for="( item, index ) in internalValues">
            <path
              v-if="item.path"
              :key="index"
              :fill="item.color"
              :d="item.path"
            />
            <circle
              v-else
              :key="index"
              :fill="item.color"
              :cx="viewBoxSize*2"
              :cy="viewBoxSize*2"
              :r="radius"
            />
          </template>
        </svg>
        <v-scroll-x-transition>
          <v-sheet v-if="selected" :color="selected.color" class="d-cheese--tooltip body-grupo-ot pa-1" rounded dark>
            {{ selected.name }} ({{ round( selected.percent * 100, 0 ) }}%)
          </v-sheet>
        </v-scroll-x-transition>
      </div>

      <ul v-if="showLegend" class="d-cheese--info">
        <li v-for="( item, index ) in internalValues" :key="index">
          <v-sheet class="d-cheese--bullet" :color="color" tile>
            <div :style="`background-color:${item.color}`"></div>
          </v-sheet>
          <span class="d-cheese--text">
            {{ item.name }}
          </span>
        </li>
      </ul>

    </v-sheet>
  </div>
</template>

<script>

  import partialCircle from 'svg-partial-circle';
  import { loop, round } from '@/utils';

  export default {
    props: {
      title: String,
      color: String,
      colors: {
        type: Array,
        default: () => []
      },
      size: {
        type: [ Number, String ],
        default: 140
      },
      rotate: {
        type: [ Number, String ],
        default: 270
      },
      items: {
        type: Array,
        default: () => []
      },
      height: {
        type: [ Number, String ],
        default: 100
      },
      showLegend: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      radius: 20,
      selected: null
    }),
    computed: {
      dimensions() {
        return {
          width: this.convertToUnit( this.size ),
          height: this.convertToUnit( this.size )
        }
      },
      viewBoxSize() {
        return this.radius;
      },
      viewBox() {
        return [ this.viewBoxSize, this.viewBoxSize, this.viewBoxSize * 2, this.viewBoxSize * 2 ].join(' ');
      },
      computedColor() {
        if ( this.color == null ) return null;
        if ( String( this.color ).startsWith('#')) return { style: { color: this.color }};
        return { class: `${this.color}--text` };
      },
      boxAttrs() {
        const attrs = { ...this.computedColor };
        if ( attrs.style ) attrs.style = { ...attrs.style, ...this.dimensions };
        else attrs.style = this.dimensions;
        return attrs;
      },
      internalValues() {

        // Prepare items

        const items = this.items.map( item => {
          if ( typeof item !== 'object' ) item = { value: Number(item) };
          item = { ...item, value: Number( item.value ) || 0 };
          return item;
        });

        // Prepare colors

        const colors = this.colors.filter( Boolean );

        // Get total

        const values = items.map( a => a.value );
        let length = values.reduce(( sum, v ) => sum + v, 0 ) || 0;

        // Normalize values

        const min = Math.min( ...values ) || 0;
        if ( min < 0 ) {
          length -= min;
          items.forEach( item => item.value -= min );
        }

        // Calc percents and Path

        let start = 0;
        let center = this.viewBoxSize * 2;
        let closedPath = ` L ${center} ${center} Z`;

        items.forEach(( item, index ) => {

          item.name = item.name || 'item - ' + ( index + 1 );
          item.color = item.color || colors[index % colors.length];
          item.percent = length ? item.value / length : 1;
          item.radians = Math.PI * 2 * item.percent;

          if ( item.percent !== 1 ) {

            item.path = partialCircle(
              this.viewBoxSize * 2,             // cx
              this.viewBoxSize * 2,             // cy,
              this.radius,                      // radius
              start,                            // startAngle
              ( start = start + item.radians )  // endAngle
            )
            .map( command => command.join(' '))
            .join(' ') + closedPath;

          } else {
            item.path = null;
          }
        });

        return items;
      }
    },
    methods: {
      round,
      convertToUnit( value ) {
        if ( value === '' || value == null ) return undefined;
        if ( isNaN(+value)) return String( value );
        return Number(value) + 'px';
      },
      onMouseMove( ev ) {

        const { box } = this.$refs;
        if ( ! box ) return;

        const bounding = box.getBoundingClientRect();
        const mx = ev.clientX - bounding.left - ( box.clientWidth / 2 );
        const my = ev.clientY - bounding.top - ( box.clientHeight / 2 );
        const an = loop( Math.atan2( my, mx ) - (( this.rotate * Math.PI ) / 180), 0, Math.PI * 2 );

        // Find item by angle
        var start = 0;
        for ( var i = 0, item; i < this.internalValues.length; i++ ) {
          item = this.internalValues[i];
          if ( an >= start && an < start + item.radians ) {
            this.selected = item;
            return;
          }
          start += item.radians;
        }

        this.selected = null;
      }
    }
  }
</script>

<style>
  .d-cheese {
    flex: 0 0;
    vertical-align: top;
  }
  .d-cheese > div > div {
    position: relative;
  }
  .d-cheese svg {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .d-cheese--base {
    fill: currentColor;
  }
  .d-cheese--info {
    list-style: none;
    line-height: 1.2;
    padding-left: 0 !important;
    margin-top: 14px;
  }
  .d-cheese--info > li {
    margin-bottom: 5px;
  }
  .d-cheese--bullet {
    position: relative;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    margin-right: .35rem;
  }
  .d-cheese--bullet > div {
    width: 100%;
    height: 100%;
  }
  .d-cheese--text {
    color: inherit;
    font-size: 1rem;
  }
  .d-cheese--title {
    margin-bottom: 2rem;
    font-size: 1.2rem;
  }
  .d-cheese--tooltip {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1;
    text-align: center;
    margin: 4px;
    white-space: nowrap;
  }
</style>

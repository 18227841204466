<template>
  <Page
    ref="pageComponent"
    :key="key"
    :service="service"
    :params="parameters"
    :section="currentSection"
    :initialize="initialize"
    :exportData="exportData"
    :importData="importData"
    :import-accept="importAccept"
    :loading="isLoading || loading"
    :hide-footer="hideFooter"
    :can-export="canExport"
    :silent="silent"
  >
    <template v-slot:top>

      <Submenu
        v-model="currentSection"
        :hide-back-button="hideBackButton"
        :items="sections"
      >

        <template v-slot:middle>
          <slot name="submenu-middle" v-bind="scope"/>
        </template>

        <Btn v-if="!hideTable" class="text-none subtitle-2" color="secondary" @click="reload" text>
          <v-icon class="btn-reload--icon">mdi-reload</v-icon>
          <span class="btn-reload--text">Refrescar</span>
        </Btn>

        <Btn
          v-if="!hideTable && showFilterButton"
          :color="showFilters ? 'primary' : 'secondary'" class="text-none mr-4 subtitle-2"
          @click="onClickFilter"
          text
        >
          <v-icon class="btn-filter--icon">$filter</v-icon>
          <span class="btn-filter--text">Filtros</span>
        </Btn>

        <slot name="submenu" v-bind="scope"/>

      </Submenu>

      <slot name="top"/>

    </template>
    <template v-slot>

      <slot name="prepend-outer"/>

      <div v-if="!hideTable" class="pa-sm-4">

        <slot name="prepend"/>

        <p>
          <v-chip color="grey" dark small>
            Total resultados: {{ totalItems }}
          </v-chip>
        </p>

        <DTable
          ref="table"
          v-model="currentSelection"
          :headers="headers"
          :items="items"
          :show-actions="actions"
          :show-select="showSelect"
          :show-filters="showFilters"
          :sorting="sorting"
          :order-items="order"
          :filters="search"
          :item-key="itemKey"
          :draggable="draggable"
          :min-width="minWidth"
          @click-item="onclick"
          @dblclick-item="onedit"
          @edit="onedit"
          @view="onedit"
          @calendar="oncalendar"
          @duplicate="onduplicate"
          @validate="onvalidate"
          @delete="onremove"
          @orderBy="order = $event"
          @filters="onfilter"
          @dragg:change="ondragg"
          @up="onmove(-1,$event)"
          @down="onmove(1,$event)"
          filter-on-blur
          disable-sorting
        >
          <template v-for="( s, name ) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
          </template>
        </DTable>

        <slot name="append"/>

        <v-dialog v-if="dialog" v-model="form.show" :max-width="dialog.width" style="overflow:visible">
          <Card class="rel" :height="dialog.height">
            <v-row class="flex-column fill-height" no-gutters>
              <Toolbar color="secondary" dark>

                <h3 style="max-width:80%;" v-html="dialog.title( form.isNew, scope )"></h3>

                <v-spacer/>

                <Btn color="white" width="20" height="20" fab light @click="close">
                  <v-icon small>$close</v-icon>
                </Btn>

              </Toolbar>
              <div class="grow rel">
                <div class="layer autoscroll">

                  <slot name="form" v-bind="scope"/>

                </div>
              </div>
              <Toolbar v-if="!dialog.hideFooter" border-top>

                <v-spacer/>

                <Btn class="mr-4" color="white" @click="close">
                  Cancelar
                </Btn>

                <Btn color="primary" @click="onsave()">
                  Guardar
                </Btn>

              </Toolbar>
            </v-row>
            <template v-if="dialog.arrows && !form.isNew">

              <Btn
                v-if="hasPrev"
                @click="goPrev"
                class="dialog-btn prev"
                color="secondary"
                small fab dark
              >
                <v-icon>$prev</v-icon>
              </Btn>

              <Btn
                v-if="hasNext"
                @click="goNext"
                class="dialog-btn next"
                color="secondary"
                small fab dark
              >
                <v-icon>$next</v-icon>
              </Btn>

            </template>
          </Card>
        </v-dialog>
      </div>

      <slot name="append-outer" v-bind="scope"/>

    </template>
    <template v-slot:footer>

      <DPagination
        v-if="numPages > 1"
        v-model="currentPage"
        :length="numPages"
        :visibles="pagesVisibles"
        tag="footer"
        border-top
      />

      <slot name="footer" v-bind="scope"/>

    </template>
    <template v-slot:actions="{ exportData, importData }">
      <slot name="actions" v-bind="{ ...scope, exportData, importData }">

        <Btn v-if="selected.length && hasAction('duplicate')" class="mr-2"
        @click="onduplicate( selected )" color="tertiary" text>
          <v-icon class="mr-2">$duplicate</v-icon>
          Duplicar
        </Btn>

        <Btn v-if="selected.length && hasAction('validate')" class="mr-2"
        @click="onvalidate( selected )" :loading="validating" color="tertiary" text>
          <v-icon class="mr-2">mdi-check</v-icon>
          Validar
        </Btn>

        <Btn v-if="selected.length && hasAction('delete')" class="mr-2"
        @click="onremove( selected )" color="tertiary" text>
          <v-icon class="mr-2">$delete</v-icon>
          Eliminar
        </Btn>

        <slot name="actions-left" v-bind="scope"/>

        <v-spacer/>

        <slot name="actions-right" v-bind="scope"/>

        <Btn v-if="hasAction('import')" class="ml-2" @click="importData" color="tertiary" dark>
          Importar
        </Btn>

        <Btn v-if="hasAction('export')" class="ml-2" @click="exportData( selected )" color="tertiary" dark>
          Exportar
        </Btn>

        <Btn v-if="hasAction('custom')" class="ml-2" @click="oncustom()" color="tertiary" dark>
          {{ customlabel }}
        </Btn>

        <Btn v-if="hasAction('new')" class="ml-2" @click="onedit()" color="tertiary" dark>
          Nuevo
        </Btn>

      </slot>
    </template>
  </Page>
</template>

<script>

  import Page from '@/components/core/Page.vue';
  import Submenu from '@/components/core/Submenu.vue';
  import { mapMutations, mapState } from 'vuex';
  import { toArray, message, Params, query, compare } from '@/utils';

  /*
  * Filter prop in headers:
  *
  *   type:  <String>       * Especifica el tipo de input a mostrar
  *                           ( default: text )
  *
  *   field: <String>       * La propiedad 'field' de API->filters, por defecto
  *                           se usará la prop 'value' del 'header'.
  *
  *   items: <Object|Array> * Los items a mostrar en el input de tipo 'select o autocomplete'.
  *   attrs: <Object>       * Atributos para el input
  */

  function cleanFilters( old, filters ) {
    for ( var key in old ) {
      if ( typeof filters[key] == 'undefined' )
        filters[key] = undefined;
    }
    return filters;
  }

  function sortByPosition( a, b ) {
    return a.position - b.position;
  }

  export default {
    components: { Page, Submenu },
    props: {
      service: String,
      subservice: String,
      params: Object,
      messages: [ String, Object ],
      sections: Array,
      headers: Array,
      showActions: [ Boolean, String, Array ],
      hideTable: Boolean,
      hideFooter: Boolean,
      silent: Boolean,
      mypopup: [ Boolean, Object ],
      draggable: Boolean,
      draggend: Function,
      move: Function,
      initialize: Function,
      exportData: Function,
      importData: Function,
      sanitize: Function,
      filter: Function,
      edit: Function,
      remove: Function,
      update: Function,
      duplicate: Function,
      validate: Function,
      hideBackButton: Boolean,
      isLoading: Boolean,
      disableRemoveAll: Boolean,
      reloadOnSave: Boolean,
      reloadOnCancel: {
        type: Boolean,
        default: false
      },
      noEdit: {
        type: Boolean,
        default: false
      },
      showSelect: {
        type: Boolean,
        default: true
      },
      showFilterButton: {
        type: Boolean,
        default: true
      },
      importAccept: {
        type: String,
        default: '.csv'
      },
      itemKey: {
        type: [ String, Function ],
        default: 'id'
      },
      minWidth: {
        type: String,
        default: '1300px'
      },
      customlabel: String
    },
    data() {
      return {
        loading: false,
        initQuery: false,
        validating: false,
        currentPage: 1,
        currentSelection: [],
        currentSection: [0,0],
        order: [],
        items: [],
        moving: false,
        old_search: {},
        key: 0,
        canExport: false,
        actionsList: [
          'new',
          'edit',
          'view',
          'calendar',
          'duplicate',
          'delete',
          'export',
          'import',
          'validate',
          'custom',
          'up',
          'down'
        ],
        defaultActions: [ 'new', 'edit', 'duplicate', 'delete'],
        form: {
          isNew: false,
          show: false,
          title: '',
          changed: false,
          validating: false,
          valid: false,
          value: null,
          listeners: {
            change:   v => this.form.changed = v,
            loading:  v => this.form.loading = v,
            validate: v => this.form.valid = v,
            input:    v => this.form.value = v
          }
        }
      }
    },
    watch: {
      page( value ) {
        this.currentPage = value;
      },
      currentPage( page ) {
        this.set({ page });
      },
      selected( value, old ) {
        if ( ! compare( value, old )) {
          this.currentSelection = value;
          this.$emit( 'selected', value );
        }
      },
      currentSelection( selected ) {
        this.select( selected );
      },
      model( value ) {
        this.$emit( 'input', value );
      },
      section( value, old ) {
        this.currentSection = value;
        if ( ! compare( value, old )) {
          if(value.length == 2 && value[0]!=old[0]){
            //reiniciamos la subsección en caso de cambio de tab
            value[1] = 0;
          }
          this.$emit( 'section', value );
        }
      },
      currentSection( section, old ) {
        if ( ! compare( section, old )) {
          this.set({}, section );
        }
      },
      showFilters( value ) {
        if ( ! value ) this.onfilter({});
        this.$emit( 'filters', value );
      },
      parameters( value, old ) {
        if ( ! compare( value, old )) {
          this.$nextTick( this.reload );
        }
      },
      coreItems: 'refreshItems',
      search( search ) {
        this.canExport = Object.keys( search ).some( k => {
          return search[k] != undefined;
        });
      }
    },
    computed: {
      ...mapState([ 'view', 'path', 'core' ]),
      section() {
        return this.view ? ( this.view.section || [0,0] ) : [0,0];
      },
      page() {
        return this.core ? ( this.core.page || 1 ) : 1;
      },
      numPages() {
        return this.core ? ( this.core.numPages || 1 ) : 1;
      },
      totalItems() {
        return this.core ? ( this.core.totalItems || 0 ) : 0;
      },
      coreItems() {
        return this.core
          ? Array.isArray( this.core.data )
            ? this.core.data.slice()
            : []
          : [];
      },
      search() {
        return this.core ? ( this.core.filterStore || {} ) : {};
      },
      filters() {
        return Object.keys( this.search )
          .map( key => query({ [key]: this.search[key] }).filters[0] )
          .filter( Boolean );
      },
      selected() {
        return this.core ? (this.core.selected||[]) : [];
      },
      showFilters() {
        return this.core ? (this.core.showFilters||false) : false;
      },
      parameters() {

        const { sections, section, page, filters, order, params } = this;
        const [ a, b ] = section;
        const list = [{ page, filters: filters.slice() }];

        // Extra params
        if ( params ) list.push( params );

        // Order
        if ( order[0] ) Object.assign( list[0], {
          order: order[0].header.value,
          ascending: order[0].order === 1 ? true : false
        });

        // Section params
        if ( sections ) {
          if ( sections[a] ) {
            if ( sections[a].children && sections[a].children[b] )
              list.push( sections[a].children[b] );
            else
              list.push( sections[a] );
          }
        }

        // Join all
        return Params.apply( 0, list );
      },
      actions() {
        var actions = this.showActions||[];
        const list = this.actionsList;
        if ( typeof actions === 'string' ) actions = actions.split(/,\s*/);
        else if ( typeof actions === 'boolean' ) actions = actions ? this.defaultActions : [];
        return actions.filter( name => list.indexOf( name ) !== -1 ).join(',');
      },
      dialog() {
        return this.mypopup
          ? { width: 640, height: 480, title: () => this.form.title,  ...this.mypopup }
          : null;
      },
      scope() {
        return {
          ...this.form,
          selected: this.selected,
          filters: this.filters,
          save: this.onsave,
          duplicate: this.onduplicate,
          validate: this.onvalidate,
          remove: this.onremove,
          edit: this.onedit,
          custom: this.oncustom,
          close: this.close
        }
      },
      pagesVisibles() {
        switch ( true ) {
          case this.$vuetify.breakpoint.xl:
            return 30;
          case this.$vuetify.breakpoint.lg:
            return 18;
          case this.$vuetify.breakpoint.md:
            return 15;
          default:
            return 6;
        }
      },
      hasPrev() {
        if ( this.selected.length === 1 ) {
          return this.items.indexOf( this.selected[0] ) > 0;
        }
        return false;
      },
      hasNext() {
        if ( this.selected.length === 1 ) {
          const index = this.items.indexOf( this.selected[0] );
          return index !== -1 && index < this.items.length - 1;
        }
        return false;
      },
      query() {
        return this.$route.query;
      }
    },
    methods: {
      ...mapMutations([ 'setView' ]),
      ...mapMutations( 'app', [ 'setDialog' ]),
      set( state, section ) {
        section = section || this.section;
        const s = section.join('');
        this.setView({
          path: this.path,
          state: {
            section,
            sections: {[s]: state }
          }
        });
      },
      refreshItems() {

        if ( this.moving ) return;

        let items = this.coreItems;
        const filter = this.parameters.filter || this.filter;
        const initIndex = ( this.page - 1 ) * 30;

        if ( typeof filter === 'function' ) {
          items = filter(items);
        }

        items.forEach(( item, index ) => {
          item.position = initIndex + index;
          return item;
        });

        this.items = items.sort( sortByPosition );
      },
      hasAction( action ) {
        return this.actions.indexOf( action ) !== -1;
      },
      close() {
        this.form.show = false;
        if(this.reloadOnCancel)
          this.$nextTick( this.reload );
      },
      onduplicate( items ) {

        items = toArray( items );
        const target = `${ this.service }/duplicate`;
        const params = { ids: items.map( item => item.id ) };

        this.loading = true;
        Promise.resolve(
          this.duplicate
            ? this.duplicate( items )
            : this.$store.dispatch( 'api', { target, params })
        ).then( response => {
          this.loading = false;
          this.$store.dispatch( 'console/success', this.message( response.length, 'duplicad@./duplicad@s.' ));
          this.$nextTick( this.reload );
        })
        .catch( err => this.$store.dispatch( 'console/error', err ));
      },
      onvalidate( items ) {

        const total = this.totalItems - (( this.page - 1 ) * 30 );

        if ( this.disableRemoveAll || items.length < this.items.length || total <= 30 )
          return this.initValidation( items );

        this.setDialog({
          show: true,
          width: 400,
          title: '¿Quiere validar todos los resultados o solo la selección actual?',
          text: 'Se validará todas los datos que coincidan con la consulta actual.',
          acceptText: 'Solo la selección',
          cancelText: 'Todos los resultados',
          accept: () => this.initValidation( items ),
          cancel: () => this.initValidation( items, true )
        });
      },
      initValidation( items, allResults ) {

        items = toArray( items );
        const target = `${ this.service }/validate`;
        const params = allResults
          ? { ids: null, filters: this.parameters.filters, status: 2 }
          : { ids: items.map( item => item.id ), filters: null, status: 2 };

        this.validating = true;

        Promise.resolve(
          this.validate
            ? this.validate( items, params )
            : this.$store.dispatch( 'api', { target, params })
        ).then( response => {
          this.validating = false;
          this.$store.dispatch( 'console/success', this.message( response.length, 'validad@./validad@s.' ));
          this.$nextTick( this.reload );
        })
        .catch( err => this.$store.dispatch( 'console/error', err ));
      },
      onremove( items ) {

        items = toArray( items );
        const total = this.totalItems - (( this.page - 1 ) * 30 );

        if ( this.disableRemoveAll || items.length < this.items.length || total <= 30 )
          return this.initRemove( items );

        this.setDialog({
          show: true,
          width: 400,
          title: '¿Quiere eliminar todos los resultados o solo la selección actual?',
          text: 'Se eliminará todas los datos que coincidan con la consulta actual.',
          acceptText: 'Solo la selección',
          cancelText: 'Todos los resultados',
          accept: () => this.$nextTick(() => this.initRemove( items )),
          cancel: () => this.$nextTick(() => this.initRemove( items, true ))
        });
      },
      initRemove( items, allResults ) {

        items = toArray( items );
        const total = this.totalItems;
        const target = `${ this.service }/removeAll`;
        const params = allResults
          ? { ids: null, filters: this.parameters.filters }
          : { ids: items.map( a => a.id ), filters: null };

        this.setDialog({
          show: true,
          width: 400,
          title: '¿Confirma que desea eliminar los elementos seleccionados?',
          text: 'Esta acción no será reversible una vez efectuada',
          accept: () => {
            this.loading = true;
            Promise.resolve(
              this.remove
                ? this.remove( items, params, allResults )
                : this.$store.dispatch( 'api', { target, params })
            ).then(() => {
              this.loading = false;
              this.$nextTick( this.reload );
              this.$store.dispatch(
                'console/success',
                this.message( allResults ? total : items.length, 'eliminad@./eliminad@s.' )
              );
            })
            .catch( err => this.$store.dispatch( 'console/error', err ));
          }
        });
      },
      select( items ) {
        this.set({ selected: items || [] });
      },
      onclick( item ) {
        if ( this.showSelect ) {
          this.select([ item ]);
        }
      },
      oncustom() {
        //Tenemos que seleccionar datos de elementos
        this.$emit("custom");
      },
      onedit( item ) {

        if(this.noEdit)
          return;
        
        this.form.value = { id: null, status: 1, ...item };
        this.form.title = item ? 'Editar' : 'Nuevo';
        this.form.isNew = !item;

        if ( this.edit ) return this.edit( item );
        if ( this.dialog ) this.form.show = true;
        else return this.$router.push(`${ this.$route.path }/${ item ? item.id : 'new' }`);
        this.$emit( 'edit', item );
      },
      oncalendar( item ) {
        this.$emit( 'calendar', item );
      },
      onsave( permanent, always ) {

        const { valid, changed, validating } = this.form;
        if ( ! valid || validating ) return;
        if ( ! changed && ! always ) return this.form.show = !!permanent;

        const params = this.sanitize ? this.sanitize( this.form.value ) : this.form.value;
        const target = `${ this.service }/set`;

        this.loading = true;
        Promise.resolve(
          this.update
            ? this.update( params )
            : this.$store.dispatch( 'api', { target, params })
        ).then( res => {

          this.loading = false;
          this.form.show = !!permanent;

          if ( permanent ) this.form.value = res;
          else {
            this.$store.dispatch(
              'console/success',
              this.message( 1, 'guardad@./guardad@s.' )
            );
          }

          this.$nextTick( this.reload );
        })
        .catch( err => {
          this.loading = false;
          this.$store.dispatch( 'console/error', err );
        });
      },
      onfilter( filters ) {
        if ( ! compare( this.old_search, filters )) {
          filters = cleanFilters( this.old_search, filters );
          this.set({ page: 1, filterStore: filters });
        }
        this.old_search = { ...filters };
      },
      changeInternalPosition( item, newIndex, oldIndex ) {

        if ( oldIndex < 0 ) return;

        let mode = 'replace';
        const items = this.items.slice();
        this.moving = true;

        if ( items[newIndex] ) {
          const { position } = item;
          item.position = items[newIndex].position;
          items[newIndex].position = position;
        } else if ( newIndex > oldIndex ) {
          mode = 'nextPage';
          item.position++
        } else if ( item.position ) {
          mode = 'prevPage';
          item.position--;
        }

        this.items = items.sort( sortByPosition );
        setTimeout(() => this.moving = false, 1000 );

        return mode;
      },
      ondragg({ moved }) {

        this.changeInternalPosition(
          moved.element,
          moved.newIndex,
          moved.oldIndex
        );

        this.draggend && this.draggend(
          moved.element, moved.newIndex,
          moved.oldIndex, this.items
        );
      },
      onmove( delta, item ) {

        const oldIndex = this.items.indexOf( item );
        const newIndex = oldIndex + delta;
        this.changeInternalPosition( item, newIndex, oldIndex );

        this.move && this.move(
          item, newIndex,
          oldIndex, this.items
        );
      },
      sorting( column, order ) {
        return toArray( order[ order.length - 1 ] );
      },
      message( number, action ) {
        const msg = typeof this.messages === 'string' ? { item: this.messages } : this.messages;
        const opt = { item: 'Elemento/Elementos', ...msg };
        action = action.replace( /@/g, opt.fem ? 'a' : 'o' );
        return message( number, opt.item, action );
      },
      goPrev() {
        const index = this.items.indexOf( this.selected[0] );
        if ( index > 0 ) {
          const item = this.items[ index - 1 ];
          this.select([ item ]);
          this.onedit( item );
        }
      },
      goNext() {
        const index = this.items.indexOf( this.selected[0] );
        if ( index >= 0 && index < this.items.length - 1 ) {
          const item = this.items[ index + 1 ];
          this.select([ item ]);
          this.onedit( item );
        }
      },
      goToSection( section ) {
        this.set({},section);
      },
      onClickFilter() {
        this.set({ showFilters: !this.showFilters });
      },
      reload() {
        const { pageComponent } = this.$refs;
        return pageComponent && pageComponent.reload();
      },
    },
    beforeMount() {
      this.refreshItems();
      this.old_search = { ...this.search };
    }
  }
</script>

<style media="screen">

  .v-dialog {
    overflow: visible !important;
  }
  .dialog-btn {
    position: absolute;
    top: 50%;
    margin-top: -20px;
  }
  .dialog-btn.prev {
    left: -60px;
  }
  .dialog-btn.next {
    right: -60px;
  }
  .btn-filter--icon, .btn-reload--icon {
    margin-right: 8px;
  }

  @media (max-width: 600px) {
    .btn-filter--icon, .btn-reload--icon {
      margin-right: 0;
    }
    .btn-filter--text, .btn-reload--text {
      display: none;
    }
  }
</style>

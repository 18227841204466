<template>
  <v-sheet
    class="stast-linear--bars-body"
    color="transparent"
    :style="computedPadding"
  >
    <div
      class="stast-linear--bars-column"
      v-for="(column,i) in computedItems"
      :key="i"
    >
      <div
        v-for="(bar,e) in column"
        :key="e"
      >
        <v-tooltip :color="bar.color" top>

          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              class="stast-linear--bars-bar"
              :color="bar.color"
              :height="`${bar.percent}%`"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <span class="stast-linear--bars-info">
            <slot name="info" v-bind="bar">
              {{ bar.key }}: {{ bar.value }}
            </slot>
          </span>

        </v-tooltip>

      </div>
    </div>
  </v-sheet>
</template>

<script>
import { toArray, round } from '@/utils';

function map( v, min, max, valMin = 0, valMax = 1 ) {
  return valMin + v * ( valMax - valMin )/( max - min );
}

export default {
  props: {
    items: Array,
    width: Number,
    height: Number,
    padding: Number,
    lineWidth: Number,
    min: Number,
    max: Number,
    sensivility: [ Number, String ]
  },
  data: () => ({
    selected: null
  }),
  watch: {
    selected( item ) {
      this.$emit( 'item:hover', item );
    }
  },
  computed: {
    computedItems() {

      const min = Math.min( this.min, this.max );
      const max = Math.max( this.min, this.max );
      const items = toArray( this.items );
      const length = items.reduce(( len, item ) => {
        return Math.max( len, item.values.length );
      }, 0 );

      const arr = [];
      for ( var i = 0; i < length; i++ ) {
        arr.push( items.map( item => {
          let value = item.values[i] || 0;
          return {
            value,
            percent: round( map( value, min, max, 0, 100 ), 2 ),
            color: item.color[0],
            key: item.key
          };
        }));
      }

      return arr;
    },
    computedPadding() {
      return { padding: `3px ${this.padding/2}px` };
    }
  }
}
</script>

<style>
.stast-linear--bars-body {
  display: flex;
  flex-wrap: nowrap;
}
.stast-linear--bars-column {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 10px;
}
.stast-linear--bars-column > div {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
}
.stast-linear--bars-bar {
  border: 1px solid #fff !important;
  position: absolute;
  bottom: 0;
  left: -1px;
  right: 0;
  cursor: pointer;
  min-height: 4px;
}
.stast-linear--bars-info {
  font-size: 10px;
  color: white;
}
</style>

import APIService from './class';

class Sync extends APIService {
  constructor() {
    super( '/sync' );
  }
  checkTasks( token, params, options ) {
    return this.action( 'post', '/check', params, options, token );
  }
}

export default new Sync();

import APIService from './class';

class Actions extends APIService {
  constructor() {
    super( '/action' );
  }
  position( token, params, options ) {
    return this.action( 'post', '/position/set', params, options, token );
  }
}

export default new Actions();

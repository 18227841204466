import APIService from './class';

class Workers extends APIService {
  constructor() {
    super( '/worker' );
  }
  validate( token, params, options ) {
    return this.action( 'post', '/validate', params, options, token );
  }
  validateWorker( token, params, options ) {
    return this.action( 'post', '/validateWorker', params, options, token );
  }
  create( token, params, options ) {
    return this.action( 'post', '/create', params, options, token );
  }
}

export default new Workers();

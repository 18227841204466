<template>
  <div>
    <DSteps
      ref="steps"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :expandible="false"
      :add="add"
      title="Respuestas"
      min="2"
    >
      <template v-slot:actions="{ canAdd }">
        <Btn
          v-if="canAdd"
          class="ml-2"
          color="tertiary"
          title="Añadir en lote"
          @click="dialog = true"
          icon
        >
          <v-icon class="ml-1">mdi-playlist-plus</v-icon>
        </Btn>
      </template>
      <template v-slot:header="{ item, open, index, active, input }">
        <DField
          v-bind="reply"
          :value="item.reply"
          :readonly="!active"
          @click="open( index )"
          @input="input( index, 'reply', $event )"
        />
      </template>
    </DSteps>
    <v-dialog v-model="dialog" max-width="640">
      <Card class="rel" height="430">
        <v-row class="flex-column fill-height" no-gutters>
          <Toolbar color="secondary" dark>

            <h3>Añadir respuestas en lote</h3>

            <v-spacer/>

            <Btn color="white" width="20" height="20" fab light @click="dialog = !dialog">
              <v-icon small>$close</v-icon>
            </Btn>

          </Toolbar>
          <div class="grow rel">
            <div class="layer autoscroll pa-4">

              <p>Escribe una lista de respuestas separadas por linea.</p>

              <DField
                v-model="lote"
                field="textarea"
                field-style="1"
                rows="10"
                hide-details
                no-resize
              />

            </div>
          </div>
          <Toolbar border-top>

            <v-spacer/>

            <Btn class="mr-4" color="white" @click="dialog = !dialog">
              Cancelar
            </Btn>

            <Btn color="primary" @click="addLote">
              Guardar
            </Btn>

          </Toolbar>
        </v-row>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      value: Array,
      default: () => []
    },
    data: () => ({
      uid: -1,
      dialog: false,
      lote: ''
    }),
    watch: {
      dialog() {
        this.lote = '';
      }
    },
    computed: {
      reply() {
        return {
          field: 'text',
          fieldStyle: 1,
          autocomplete: 'none',
          dense: true
        }
      }
    },
    methods: {
      add( item, model ) {
        model.push({ ...item, id: this.uid-- });
        return model;
      },
      addLote() {

        this.dialog = false;
        const replies = this.lote.split(/\n+/)
          .map( s => s.trim())
          .filter( Boolean );

        const { steps } = this.$refs;
        if ( replies.length && steps ) {
          if ( steps.model.length === 2 ) {
            if ( ! steps.model[0].reply && ! steps.model[1].reply ) {
              steps.input( 0, 'reply', replies.shift() || '');
            }
            if ( ! steps.model[1].reply ) {
              steps.input( 1, 'reply', replies.shift() || '');
            }
          }
          replies.forEach( reply => steps.onadd({ reply }));
        }
      }
    }
  }
</script>

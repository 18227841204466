export default {
  add( state, alert ) {
    state.alerts.push( alert );
  },
  remove( state, index ) {
    state.alerts.splice( index, 1 );
  },
  clear( state ) {
    state.alerts.length = 0;
  }
};

<template>
  <DResponsive class="d-image" :aspect-ratio="aspectRatio">
    <Card
      :class="{ full: aspectRatio != null }"
      :ripple="false"
      :border="_border"
      v-on="$listeners"
    >
      <v-img
        class="grey lighten-4"
        :src="src"
        :class="{ full: aspectRatio != null }"
        @error="error = true"
      >
        <div class="full rel mask">
          <div
            v-if="info"
            class="overlay layer"
            :class="{ permanent: info.permanent }"
          >
            <v-sheet
              class="layer"
              :color="info.background"
              :style="{ opacity: info.opacity }"
              style="z-index: -1"
            />
            <v-row class="fill-height ma-0" align="center" justify="center">
              <div
                class="overlay-text text-center"
                :style="{ color: info.color }"
              >
                <h2 v-if="title" class="headline mb-0">{{ title }}</h2>
                <p v-if="subtitle" class="mb-0">{{ subtitle }}</p>
              </div>
            </v-row>
          </div>

          <v-simple-checkbox
            v-if="selectable"
            :value="value"
            :ripple="false"
            color="primary"
            @input="oninput"
          />

          <div class="download-btn">
          <Btn
            v-if="download"
            class="mr-2 left-btn"
            color="black"
            @click="ondownload"
            icon
          >
            <v-icon>mdi-download</v-icon>
          </Btn>

          <Btn
            v-if="remove"
            class="mr-2 left-btn"
            color="black"
            @click="onremove"
            icon
          >
            <v-icon>mdi-delete</v-icon>
          </Btn>
          </div>
        </div>
        <v-row
          v-if="src && !error"
          slot="placeholder"
          class="fill-height"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular color="border" indeterminate />
        </v-row>
      </v-img>
      <div v-if="_footer" class="image-footer">
        <div
          v-html="_footer.template || ''"
          :class="`${_footer.color}--text`"
          style="position: relative; z-index: 1"
        />
        <v-sheet
          :color="_footer.background"
          :style="{ opacity: _footer.opacity, zIndex: 0 }"
          class="layer"
        />
      </div>
    </Card>
  </DResponsive>
</template>

<script>
import { get, downloadImage, formatDate } from "@/utils";

export default {
  props: {
    value: Boolean,
    image: [String, Object],
    imageUrl: String,
    downloadKey: String,
    color: String,
    aspectRatio: [Number, String],
    overlay: [Boolean, Object],
    footer: [String, Function],
    selectable: Boolean,
    download: Boolean,
    remove: {
      type: Boolean,
      default: false
    },
    border: {
      type: [Boolean, String],
      default: null,
    },
  },
  data: function () {
    return {
      error: false,
    };
  },
  computed: {
    src() {
      const src =
        typeof this.image === "object"
          ? get(this.image, this.imageUrl)
          : this.image;
      return typeof src === "string" ? src : "";
    },
    info() {
      return this.overlay
        ? {
            color: "white",
            background: "black",
            opacity: 0.15,
            permanent: false,
            ...this.overlay,
          }
        : null;
    },
    title() {
      if (!this.info) return null;
      const { image } = this;
      const { title } = this.info;
      return image[title];
    },
    subtitle() {
      if (!this.info) return null;
      const { image } = this;
      const { subtitle } = this.info;
      return image[subtitle];
    },
    primaryColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    _border() {
      if (this.border != null) return this.border;
      return this.selectable && this.value
        ? this.$vuetify.theme.themes.light.primary
        : true;
    },
    _footer() {
      const { footer, image } = this;
      if (footer) {
        var style = { color: "white", background: "black", opacity: 0.5 };

        if (typeof footer === "string") {
          style.template = get(image, footer);
        } else {
          style = { ...style, ...footer(image) };
        }

        if (style.template) {
          return style;
        }
      }
      return null;
    },
  },
  methods: {
    oninput(e) {
      this.$emit("click", e);
    },
    getTaskName(task) {
      var name = "";
      if (task.campaign && task.campaign.title) {
        name = task.campaign.title + "_";
      }
      if (task.place && task.place.name) {
        name = name + task.place.name + "_";
      }
      if (task.action && task.action.title) {
        name = name + task.action.title + "_";
      }
      if (task.date) {
        var fd = formatDate(task.date, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        if (fd) {
          name = name + fd.replace(/-|\//g, "");
          +"_";
        }
      }

      return name;
    },
    getIssueName(task) {
      var name = "";
      if (task.id ) {
        name = task.id + "_";
      }
      if (task.campaign && task.campaign.title) {
        name = name + task.campaign.title + "_";
      }
      if (task.place && task.place.name) {
        name = name + task.place.name + "_";
      }
      if (task.description) {
        var desc = task.description;
        if(desc.length > 20){
          desc = desc.substring(0,20);
        }
        name = name + desc + "_";
      }
      if (task.date) {
        var fd = formatDate(task.date, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        if (fd) {
          name = name + fd.replace(/-|\//g, "");
          +"_";
        }
      }

      return name;
    },

    onremove(e) {
      e.stopPropagation();

      this.$emit("remove", this.image);
    },
    ondownload(e) {
      e.stopPropagation();
      var url;
      if (typeof this.image == "object" && this.downloadKey) {
        url = get(this.image, this.downloadKey);
        console.log("Downloading: " + JSON.stringify(this.image));
        var name = "";

        //String name = item.getTask().getAction().getCampaign().getTitle() + "_"
        //					+ item.getTask().getPlace().getName() + "_"
        //					+ item.getTask().getAction().getTitle() + "_" + item.getTask().getDay() + "_"
        //					+ item.getLastUpdate() + ".png";
        if (this.image.task) {
          var task = this.image.task;
          name = this.getTaskName(task);
        } else if (this.image.issue) {
          var issue = this.image.issue;
          name = this.getIssueName(issue);
        }

        name = name + Date.now() + ".png";
        name = name.replace(/\s/g, "-");

        url && downloadImage(url, name);
      } else {
        url = this.src;
        url && downloadImage(url);
      }
    },
  },
};
</script>

<style lang="scss">
.d-image {
  .download-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .left-btn {
    position: relative;
    float:right;
    padding:4px;
  }
  .image-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 4px;
    text-align: center;
  }
  .overlay:not(.permanent) {
    transition: opacity 0.35s ease-in;
    opacity: 0;

    .overlay-text {
      transition: transform 0.35s ease-in;
      transform: scale(1.2);
    }
  }
  &:hover {
    .overlay:not(.permanent) {
      opacity: 1;
      .overlay-text {
        transform: scale(1);
      }
    }
  }
}
</style>

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import Icons from '../components/icons';

Vue.use( Vuetify, {
  directives: {
    Ripple
  }
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary:   '#FF7B84',
        secondary: '#244062',
        tertiary:  '#4170A9',
        border:     '#BFBFBF',
        background: '#FAFAFA',
        bgdark:     '#17293F'
        //accent: '#82B1FF',
        //error: '#FF5252',
        //info: '#2196F3',
        //success: '#4CAF50',
        //warning: '#FFC107'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: Icons
  }
});

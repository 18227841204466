<template>
  <DForm ref="form" :value="model" v-on="{ ...$listeners, input }">
    <v-row class="flex-nowrap" no-gutters>
      <div class="grow px-4">

       <Channel key="channel" v-bind="channel" class="mb-6" />

        <DField
          v-for="(field, key) in fields"
          v-bind="field"
          :key="key"
          :readonly="readonly"
          field-style="1"
          class="mb-6"
          dense
        />

      </div>
      <div style="flex: 0 0 520px; max-width: 520px;">

        <DField
          key="position"
          v-bind="map"
          :readonly="readonly"
          @input="changePosition"
        />

        <DField
          v-for="( field, key ) in mapField"
          v-bind="field"
          :key="key"
          :readonly="readonly"
          field-style="1"
          dense
        />

      </div>
    </v-row>
  </DForm>
</template>

<script>

import Channel from "../Channels/selector";
//import { get } from '@/utils';

export default {
  components: { Channel },
  props: { value: Object, readonly: Boolean },
  data() {
    return {
      model: this.compute( this.value )
    }
  },
  watch: {
    value( value ) {
      this.model = this.compute( value );
    }
  },
  computed: {
    fields() {
      return {
        name:       { label: "Nombre",    field:"text" },
        code:       { label: "Código cliente",      field:"text" },
        cluster:    { label: "Cluster",      field:"text" },
        area:       { label: "Área",      field:"text" },
        street:     { label: "Dirección", field:"text" },
        city:       { label: "Ciudad",    field:"text" },
        province:   { label: "Provincia", field:"text" },
        postalCode: { label: "CP",        field:"text" }
      };
    },
    channel() {
      return {
        fieldStyle: 1,
        label: "Cadena",
        dense: true
      };
    },
    map() {
      return {
        field: 'gmap',
        class: 'mb-5',
        height: 300,
        zoom: 7,
        showPosition: true,
        editable: true,
      }
    },
    mapField() {
      return {
        latitude:  {
          label: "Latitud (decimal)",
          field: "number",
          class: 'mb-6',
          step: .01
        },
        longitude: {
          label: "Longitud (decimal)",
          field: "number",
          class: 'mb-6',
          step: .01
        },
        radious: {
          label: "Radio de acción (metros)",
          field: "number",
          class: 'mb-6',
          step: .01
        },
        
        phone:      { label: "Teléfono",  field:"text" }
      }
    }
  },
  methods: {
    compute( value ) {
      value.position = null;
      if ( value.latitude != null || value.longitude != null ) {
        value.position = {
          lat: value.latitude == null ? 41.5 : value.latitude,
          lng: value.latitude == null ? 2.15 : value.longitude
        }
      }
      return value;
    },
    changePosition( value ) {
      const { form } = this.$refs;
      if ( value && form ) {
        form.set( 'latitude',  value.lat );
        form.set( 'longitude', value.lng );
      }
    },
    input( value ) {
      const { form } = this.$refs;
      if ( value && form ) {

        var { position, latitude, longitude } = value;

        if ( isNaN( latitude + longitude ) && ( latitude != null || longitude != null )) {

          latitude  = latitude  == null ? 41.5 : latitude;
          longitude = longitude == null ? 2.15 : longitude;
          form.set( 'latitude',  latitude  );
          form.set( 'longitude', longitude );
          if ( ! position ) form.set( 'position', { lat: latitude, lng: longitude });
          return;

        } else if ( position && ( position.lat !== latitude || position.lng !== longitude )) {

          return form.set( 'position', { lat: latitude, lng: longitude });
        }

      }

      if ( this.$listeners.input ) this.$listeners.input( value );
      this.$emit( 'input', value );
    }
  }
};
</script>

<template>
  <component :is="tag||'div'" class="responsive" v-bind="$attrs" v-on="$listeners">
    <div :style="style"/>
    <div class="responsive-wrapper">
      <slot/>
    </div>
  </component>
</template>

<script>
  export default {
    props: {
      tag: String,
      aspectRatio: [ Number, String ],
    },
    computed: {
      aspect() {
        var aspect = this.aspectRatio;
        if ( typeof aspect === 'string' ) {
          aspect = aspect.split('/').map( n => parseFloat( n ));
          aspect = aspect[1] ? aspect[0]/aspect[1] : aspect[0];
        }
        return aspect;
      },
      style() {
        const { aspect } = this;
        if ( aspect ) {
          return {
            paddingTop: `${ 100 / aspect }%`
          }
        }
        return null;
      }
    }
  };
</script>

<style scoped>
  .responsive {
    position: relative;
    width: 100%;
  }
  .responsive-wrapper {
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    overflow: hidden;
  }
</style>

<template>
  <v-item-group
    v-model="selected"
    :multiple="multiple"
  >
    <div class="fill-width pa-2">
      <v-scale-transition v-if="items" class="row no-gutters" leave-absolute group>
        <v-col v-for="( item, index ) in items" :style="size" class="pa-2" :key="item[itemKey] || `item-${index}`">

          <v-item :value="item" v-slot:default="{ active, toggle }">
            <DImage
              :value="active"
              :image="item"
              :aspect-ratio="relation || 1"
              :overlay="itemOverlay"
              :footer="itemFooter"
              :image-url="itemImage"
              :download-key="itemDownload"
              :selectable="selectable"
              :download="download"
              :remove="remove"
              @remove="onremove"
              @click="clickItem( item, index ) && toggle()"
            />
          </v-item>

        </v-col>
        <v-col v-if="editable" :style="size" class="pa-2" key="add">

          <DResponsive :aspect-ratio="relation || 1" >
            <Card class="d-flex full justify-center align-center" :ripple="false" @click="add" border>
              <v-icon color="tertiary">
                $plus
              </v-icon>
            </Card>
          </DResponsive>

        </v-col>
        <v-col v-if="!items.length || items.length % columns" key="void" :style="size" class="pa-2">

          <DResponsive :aspect-ratio="relation || 1"/>

        </v-col>
      </v-scale-transition>
    </div>
  </v-item-group>
</template>

<script>

  import { get, Any, toArray } from '@/utils';

  export default {
    props: {
      value: Any,
      items: Array,
      editable: Boolean,
      adding: Function,
      selectable: Boolean,
      multiple: Boolean,
      xl: [ Number, String ],
      lg: [ Number, String ],
      md: [ Number, String ],
      sm: [ Number, String ],
      cols: [ Number, String ],
      relation: [ String, Number ],
      itemOverlay: [ Boolean, Object ],
      itemImage: String,
      itemKey: String,
      itemDownload: String,
      itemFooter: [ String, Function ],
      download: Boolean,
    remove: {
      type: Boolean,
      default: false
    },
    },
    data() {
      return {
        selected: this.multiple ? toArray( this.value ) : this.value,
        error: false
      }
    },
    computed: {
      columns() {

        const breaks = this.$vuetify.breakpoint;
        const sizes = [ 'xs', 'sm', 'md', 'lg', 'xl' ];
        const cols = sizes.map( size => {
          size = parseInt( size === 'xs' ? this.cols : this[size] );
          return size > 0 ? size : null;
        });

        var size, i = -1, e;
        while(( size = sizes[++i] )) {
          if ( breaks[size] ) {
            if ( cols[i] != null ) return cols[i];
            else {
              for ( e = 0; e < cols.length; e++ ) {
                if ( cols[e] != null ) return cols[e];
              }
            }
          }
        }

        return 10;
      },
      size() {
        const mode = this.columns ? ( 100 / this.columns ) + '%' : null;
        return {
          flex: `0 0 ${ mode || 'auto' }`,
          maxWidth: mode || '100%'
        }
      }
    },
    watch: {
      value( value ) {
        this.selected = this.multiple ? toArray( value ) : value;
      },
      multiple( value ) {
        if ( ! value ) this.selected = this.selected[ this.selected.length - 1 ];
        else this.selected = toArray( this.selected );
      },
      selected( value ) {
        this.$emit( 'input', value );
      }
    },
    methods: {
      isSelected( item ) {
        if ( ! this.selectable ) return false;
        return this.selected.indexOf( item ) !== -1;
      },
      image( item ) {
        const src = get( item, this.itemImage );
        return typeof src === 'string' ? src : '';
      },
      clickItem( item, index ) {
        this.$emit( 'click-item', { item, index });
        return 1;
      },
      add( event ) {
        const { adding } = this;
        if ( typeof adding === 'function' )
          adding( event );
      },
      onremove(e){
        this.$emit("remove", e);
      }
    }
  }
</script>

<style lang="scss">
  .image-item {
    .image-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 4px;
      text-align: center;
    }
    .overlay:not(.permanent) {

      transition: opacity .35s ease-in;
      opacity: 0;

      .overlay-text {
        transition: transform .35s ease-in;
        transform: scale(1.2);
      }
    }
    &:hover {
      .overlay:not(.permanent) {
        opacity: 1;
        .overlay-text {
          transform: scale(1);
        }
      }
    }
  }
</style>

<template>
  <div class="content">
    <v-hover v-slot="{ hover }">
      <aside class="drawer" :class="menuClass( hover )">

        <div class="grow rel">
          <div class="abs full autoscroll">

            <Menu
              class="menu-main"
              :items="mainMenu"
              :collapse="collapse && !( hover || submenu )"
              @submenu="submenu = $event"
            />

          </div>
        </div>

        <Menu
          class="menu-bottom"
          :items="bottomMenu"
          :collapse="collapse && !( hover || submenu )"
          dark
        />

        <div class="menu-logout">
          <Btn class="justify-start text-none" height="52" color="tertiary" @click="logout" block dark>
            <v-icon size="28" class="ml-n1 mr-4">$offline</v-icon>
            <span>Logout</span>
          </Btn>
        </div>

      </aside>
    </v-hover>
    <div class="wrapper" :class="contentClass">
      <Block
        :block="$store.state.app.block"
        :opacity="silent ? 0 : .5"
        class="d-flex flex-column full"
      >
        <slot name="submenu"/>
        <div class="grow rel">
          <div class="layer autoscroll">

            <slot/>

          </div>
        </div>
        <slot name="footer"/>
      </Block>
      <v-overlay
        :value="isMobile && show"
        :opacity=".2"
        absolute
      />
    </div>
  </div>
</template>

<script>

  import Menu from '@/components/core/Menu.vue';
  import { mapState, mapMutations } from 'vuex';

  export default {
    components: { Menu },
    props: {
      silent: Boolean
    },
    data: () => ({
      mouseover: false,
      submenu: false
    }),
    computed: {
      ...mapState( 'app', [ 'drawer' ]),
      ...mapState( 'nav', [ 'menus' ]),
      ...mapState( 'socket', [ 'chats', 'issues' , 'tasks', 'evaluations' ]),
      mainMenu() {
        if ( this.menus.main ) {
          return this.menus.main.map( btn => {
            if ( btn.path === '/evaluations' ) btn.bullet = this.evaluations;
            return btn;
          })
        }
        return [];
      },
      bottomMenu() {
        if ( this.menus.bottom ) {
          return this.menus.bottom.map( btn => {
            if ( btn.path === '/chat' ) btn.bullet = this.chats;
            if ( btn.path === '/issues' ) btn.bullet = this.issues;
            if ( btn.path === '/tasks' ) btn.bullet = this.tasks;
            return btn;
          })
        }
        return [];
      },
      contentClass() {
        return {
          'with-drawer': this.isMobile ? false : this.show,
          collapsed: this.collapse
        }
      },
      isMobile() {
        return this.$vuetify.breakpoint.xs;
      },
      show() {
        return this.isMobile ? this.drawer : true;
      },
      collapse() {
        return this.isMobile ? false : !this.drawer;
      }
    },
    watch: {
      isMobile( value ) {
        if ( value ) this.setDrawer( false );
      }
    },
    methods: {
      ...mapMutations( 'nav', [ 'searchMenus' ]),
      ...mapMutations( 'app', [ 'setDialog', 'setDrawer' ]),
      menuClass( hover ) {
        this.mouseover = hover;
        return {
          show: this.show,
          collapse: this.collapse,
          hover: this.mouseover || this.submenu
        }
      },
      logout() {
        this.setDialog({
          show: true,
          title: 'Cerrando sesión...',
          text: '¿Estás seguro de que quieres cerrar la sesión actual?',
          accept: () => {
            this.$store.dispatch( 'logout' );
            this.$router.push('/login');
          }
        });
      }
    },
    created() {
      this.searchMenus( {user: this.$store.state.user, routes: this.$router.options.routes} );
    }
  };
</script>

<style>
  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .drawer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -200px;
    width: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: width .15s ease, left .15s ease;
    background: var(--v-bgdark-base);
    z-index: 1;
    overflow: hidden;
  }
  .drawer.collapse {
    width: 50px;
  }
  .drawer.hover {
    width: 200px;
  }
  .drawer.show {
    left: 0;
  }
  .drawer > * {
    width: 200px;
  }
  .wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: left .15s ease;
    z-index: 0;
  }
  .wrapper.with-drawer {
    left: 200px;
  }
  .wrapper.with-drawer.collapsed {
    left: 50px;
  }
  .menu-bottom {
    border-top: 1px solid var(--v-tertiary-base) !important;
  }
</style>

<template>
  <div>

    <h3 class="title">{{ title }}</h3>
    <p class="body-2 grey--text mb-2">{{ description }}</p>

    <slot name="info"/>

    <div class="mt-6" :key="key">
      <Question
        v-for="q in questions"
        v-show="showQuestion(q)"
        class="mb-8"
        :key="q.id"
        :value="questionValue(q)"
        :question="q"
        :readonly="readonly"
        @input="input( q.id, $event )"
      />
    </div>

  </div>
</template>

<script>

  import Question from './question.vue';
  import { get, set } from '@/utils';

  export default {
    components: { Question },
    props: {
      value: Object,
      readonly: Boolean
    },
    data: function() {
      return {
        response: null,
        selected: null,
        removeds: [],
        key: 0
      }
    },
    watch: {
      value() {
        this.refresh();
      }
    },
    computed: {
      title() {
        return get( this.value, 'evaluation.survey.title' );
      },
      description() {
        return get( this.value, 'evaluation.survey.description' );
      },
      questions() {
        return ( get( this.value, 'evaluation.survey.questions' ) || [] )
          .filter( q => q.status !== -1 )
          .sort(( a, b ) => a.position - b.position );
      }
    },
    methods: {
      questionValue( question ) {
        const path = [ question.id, 'value' ].join('.');
        const value = get( this.response, path );
        return value;
      },
      showQuestion( question, product ) {
        if ( question.idParent ) {

          var res = ( get( this.value, 'response.changed' ) || this.response )[ question.idParent ];
          if ( product ) res = get( res, product.id );
          var replies = get( res, 'value' ) || [];

          if ( question.idParentReply ) {
            if ( question.parentType ) { // 1 - Diferente a

              return res && replies.indexOf( question.idParentReply ) === -1;

            } else { // 0 - Igual a

              return res && replies.indexOf( question.idParentReply ) !== -1;

            }
          } else if ( ! res ) {

            return false;

          }
        }
        return true;
      },
      prepareResponse() {

        this.removeds = [];
        const response = {};
        const replies = get( this.value, 'replies' ) || [];
        var question;

        replies.forEach( reply => {
          if (( question = this.questions.find( q => q.id === reply.idQuestion ))) {
            if ( question.type === 1 ) reply.value = reply.replies[0];
            else if ( question.type === 2 ) reply.value = reply.replies;
            else reply.value = reply.response;
            response[reply.idQuestion] = reply;
          }
        });

        return response;
      },
      input( a, b, c ) {
        if ( arguments.length > 2 ) set( this.response, [ a, b, 'value' ].join('.'), c );
        else set( this.response, [ a, 'value' ].join('.'), b );
        this.emitResponse();
      },
      emitResponse() {
        set( this.value, 'response.changed', this.response );
        this.$emit( 'input', this.value );
        this.key++;
      },
      refresh() {
        this.response = this.prepareResponse();
        this.key++;
      }
    },
    beforeMount() {
      this.response = this.prepareResponse();
    }
  }
</script>

<template>
  <DForm ref="form" :value="value" v-on="{ ...$listeners, input }">

    <DField key="startDate" v-bind="startDate" class="mb-6" />
    <DField key="endDate" v-bind="endDate" class="mb-6" />
    <DField key="costHour" v-bind="costHour" class="mb-6" />
    <DField key="holidayCostHour" v-bind="holidayCostHour" class="mb-6" />
    <DField key="extraCostDay" v-bind="extraCostDay" class="mb-6" />
    <DField key="paidHolidays" v-bind="paidHolidays" class="mb-6" />
    <DField key="places">
      <template v-slot="{ value, on }">
         <Places :value="value" v-on="on" />
      </template>
    </DField>

  </DForm>
</template>

<script>
import Places from "../Places";

//import Places from '@/components/forms/Places/dialog';

export default {
  components: { Places },
  props: {
    value: Object
  },
  data() {
    return {
      model: {},
      places: [],
      minDate: null,
      maxDate: null
    };
  },
  methods: {
    input( value ) {
      this.model = value;
      this.places = this.model.places;
      this.minDate = this.model.startDate;
      this.maxDate = this.model.endDate;
      if ( this.$listeners.input ) this.$listeners.input( value );
    },
    reset() {
      return this.$refs.form.reset();
    }
  },
  computed: {
    startDate() {
      return {
        fieldStyle: 1,
        dense: true,
        field: "date",
        label: "Fecha de inicio",
        max: this.maxDate,
        rules: [ v => !!v || 'El campo es requerido.' ]
      };
    },
    endDate() {
      return {
        fieldStyle: 1,
        dense: true,
        field: "date",
        label: "Fecha de fin",
        min: this.minDate,
        rules: [ v => !!v || 'El campo es requerido.' ]
      };
    },
    costHour() {
      return {
        fieldStyle: 1,
        field: 'number',
        min: 0,
        label: 'Coste por hora',
        dense: true
      }
    },
    holidayCostHour() {
      return {
        fieldStyle: 1,
        field: 'number',
        min: 0,
        label: 'Coste por hora',
        dense: true
      }
    },
    extraCostDay() {
       return {
        fieldStyle: 1,
        field: 'number',
        min: 0,
        label: 'Plus por día',
        dense: true
      }
    },
    paidHolidays() {
      return {
        field: "checkbox",
        label: "Vacaciones pagadas"
      }
    }
  }
};
</script>

<template>
  <Table
    ref="tableComponent"
    service="Campaigns"
    min-width="1972px"
    :sections="sections"
    :initialize="initialize"
    :exportData="exportData"
    :importData="importData"
    :remove="remove"
    :duplicate="duplicate"
    :update="update"
    :headers="headers"
    :messages="messages"
    :mypopup="mypopup"
    :is-loading="loading"
    :show-actions="actions"
    :reload-on-cancel="true"
    disable-remove-all
    @selected="onSelected"
    @custom="showAddCenters"
    customlabel="Añadir Centros"
  >

    <template v-slot:submenu-middle>
      <Btn
        :to="`/campaigns/${$route.params.id}/calendar`"
        class="mr-2"
        color="tertiary"
        dark
      >
        Calendario
      </Btn>
    </template>

    <template v-slot:actions-right>
      <Btn v-if="selected.length > 0" class="ml-2" @click="sendCredentials()" color="tertiary" dark>
        Credenciales
      </Btn>
    </template>

    <template v-slot:item.user="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link"
        v-if="value"
        :title="value"
        :to="`/users/${item.user.id}`"
      >
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.paidHolidays="{ item }">
      <div>{{item.paidHolidays ? "Sí": "No"}}</div>
    </template>

    <template v-slot:item.kpis="{ item, value }">
      <Btn :to="`${$route.path}/${item.id}/targets`" color="tertiary" x-small dark>
        Ver {{( value || [] ).length }}
      </Btn>
    </template>

    <template v-slot:item.okStatus="{ value }">
      <v-icon :color="okColor(value)" v-text="icon(value)" small />
    </template>

    <template v-slot:form="{ value, listeners }">
      <Center class="pa-4" :value="value" v-on="listeners" />
    </template>

    <template v-slot:append-outer>
      <v-dialog
        v-if="dialog"
        v-model="form.show"
        :max-width="dialog.width"
        style="overflow:visible"
      >
        <Card :height="dialog.height">
          <v-row class="flex-column fill-height" no-gutters>
            <Toolbar color="secondary" dark>
              <h3>Añadir Centros</h3>

              <v-spacer />

              <Btn
                color="white"
                width="20"
                height="20"
                fab
                light
                @click="form.show = !form.show"
              >
                <v-icon small>$close</v-icon>
              </Btn>

              <v-progress-linear
                v-if="form.loading"
                color="primary"
                indeterminate
                absolute
                bottom
              />
            </Toolbar>
            <Block :block="form.loading" class="grow rel">
              <div class="layer autoscroll">
                <AddCenters
                  class="pa-4"
                  ref="formCenters"
                  :value="value"
                  @input="disableCenters"
                  @validate="(form.valid = $event), disableCenters()"
                />
              </div>
            </Block>
            <Toolbar border-top>
              <v-spacer />

              <Btn class="mr-4" color="white" @click="form.show = !form.show">
                Cancelar
              </Btn>

              <Btn
                color="primary"
                @click="onAddCenters()"
                :disabled="form.disabled"
              >
                Guardar
              </Btn>
            </Toolbar>
          </v-row>
        </Card>
      </v-dialog>
    </template>
  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import Center from "@/components/forms/Centers/single.vue";
import AddCenters from "@/components/forms/Centers/addcenters.vue";
import { mapState } from "vuex";
import { Format } from "@/utils/time";
import { direction, userName } from "@/utils";
import { COST_HOUR, HOLIDAY_COST_HOUR, EXTRA_COST_DAY } from '@/utils/constants';

const NUMBER_FILTER = {
  type: 'number',
  attrs: { min: 0, max: 100, hideControls: true }
};

function date(value) {
  return Format(value, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function statusDate(type) {
  return function(value, header, item) {
    return item[`${type}Status`] ? date(value) : "...";
  };
}

export default {
  components: { Table, Center, AddCenters },
  data() {
    return {
      loading: false,
      value: {},
      selected: [],
      form: {
        show: false,
        valid: false,
        disabled: true,
        loading: false,
      },
      dialog: {
        show: false,
        height: 600,
        width: 800,
      },
      messages: { item: "Centro/Centros" },
      sections: [
        { text: 'Activos',   filters: [{ field: 'section', intValue: 1 }]},
        { text: 'No activos',  filters: [{ field: 'section', intValue: 2 }]},
        { text: 'Sin asignar',   filters: [{ field: 'section', intValue: 3 }]},
        { text: 'Todos' }
      ],
      headers: [
        {
          text: "Id",
          value: "id",
          width: 80,
          filterable: false,
        },{
          text: "Establecimiento",
          value: "place",
          display: direction,
          filter: {
            type: "text",
            attrs: {
              service: "Places",
              itemText: "name",
              itemValue: "id",
              all: true,
            },
          },
        },
        {
          text: "Trabajador",
          value: "user",
          display: (v) => userName(v),
          filter: {
            type: "autocomplete",
            attrs: {
              service: "Users",
              itemValue: "id",
              itemText: "name",
              display: (v) => userName(v, true),
              all: true,
            },
          },
        },
        {
          text: "Fecha de inicio",
          value: "startDate",
          width: 110,
          align: "center",
          display: date,
          filter: { type: "rangedate" },
        },
        {
          text: "Fecha de fin",
          value: "endDate",
          width: 110,
          align: "center",
          display: date,
          filter: { type: "rangedate" },
        },
        {
          text: "Coste por hora",
          value: "costHour",
          width: 110,
          align: "center",
          display: "numeric",
          filter: NUMBER_FILTER
        },
        {
          text: "Coste por hora festivo",
          value: "holidayCostHour",
          width: 110,
          align: "center",
          display: "numeric",
          filter: NUMBER_FILTER
        },
        {
          text: "Plus por día",
          value: "extraCostDay",
          width: 110,
          align: "center",
          display: "numeric",
          filter: NUMBER_FILTER
        },
        {
          text: "Vacaciones pagadas",
          value: "paidHolidays",
          width: 110,
          align: "center",
          filterable: true,
          filter: {
            type: 'select',
            field: 'paidHolidays',
            items: [
              { value: true, text: 'Sí' },
              { value: false, text: 'No' }
            ]
          }
        },
        {
          text: "Documentación",
          value: "docDate",
          width: 130,
          display: statusDate("doc"),
          align: "center",
          filter: { type: "rangedate" },
        },
        {
          text: "Firma",
          value: "signDate",
          width: 100,
          display: statusDate("sign"),
          align: "center",
          filter: { type: "date" },
        },
        {
          text: "Acceso",
          value: "accessDate",
          width: 100,
          display: statusDate("access"),
          align: "center",
          filter: { type: "rangedate" },
        },
        {
          text: "Formación",
          value: "formDate",
          width: 100,
          display: statusDate("form"),
          align: "center",
          filter: { type: "rangedate" },
        },
        {
          text: "Operaciones",
          value: "operationDate",
          width: 100,
          display: statusDate("operation"),
          align: "center",
          filter: { type: "rangedate" },
        },
        {
          text: "Última actualización",
          value: "lastUpdate",
          width: 150,
          display: v => v ? date(v) : "...",
          align: "center",
          filter: { type: "rangedate" }
        },
        { text: "Objetivos", value: "kpis", width: 100, sorteable: false, filterable: false },
        { text: "OK", value: "okStatus", width: 100, filterable: false },
      ],
      mypopup: {
        height: 480,
        title: (n) => (n ? "Nuevo centro" : "Editar centro"),
      },
    };
  },
  computed: {
    ...mapState([ 'isRRHH', 'breadcrumbs' ]),
    actions() {
      if ( this.isRRHH ) return "edit, import, export, duplicate";
      return "edit, duplicate, delete, new, import, export, custom";
    }
  },
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    }
  },
  methods: {
    direction,
    initialize(params) {
      return new Promise((resolve, reject) => {

        params.method = "centers";
        if ( ! params.filters ) {
          params.filters = [];
        }

        params.filters.push(
          { field: "campaign", intValue: parseInt( this.$route.params.id ) },
        );

        this.$store
          .dispatch( "page", params )
          .then( response => {
            response.data = response.data.map( this.compute );
            resolve( response );
          })
          .catch( reject );
      });
    },
    exportData(params) {
      return new Promise((resolve, reject) => {

        params.method = "exportCenters";
        if ( ! params.filters ) {
          params.filters = [];
        }

        params.filters.push(
          { field: "campaign", intValue: parseInt(this.$route.params.id) },
        );

        this.$store
          .dispatch("exportData", params)
          .then( resolve )
          .catch( reject );
      });
    },
    importData(params) {
      return new Promise((resolve, reject) => {
        params.method = "importCenters";
        this.$store
          .dispatch("importData", {
            ...params,
            filters: [
              { field: "campaign", intValue: parseInt(this.$route.params.id) },
            ],
          })
          .then( resolve )
          .catch(reject);
      });
    },
    compute(item) {
      item.ok =
        item.docStatus + item.signStatus + item.accessStatus + item.formStatus + item.operationStatus;
      return item;
    },
    remove( items ) {
      const target = "Campaigns/removeCenter";
      return Promise.all(
        items.map((a) =>
          this.$store.dispatch("api", { target, params: { id: a.id } })
        )
      );
    },
    duplicate( items ) {
      return this.$store.dispatch("api", {
        target: 'Campaigns/duplicateCenter',
        params: { id: this.$route.params.id, items: items.map( item => item.id ) }
      });
    },
    prepare( params ) {
      const date = Date.now();
      const idCampaign = parseInt(this.$route.params.id);
      const status = (v) => (v ? 1 : 0);

      return {
        id: params.id || -1,
        status: 1,
        idCampaign,
        idPlace: params.place ? params.place.id : null,
        idUser: params.user ? params.user.id : null,
        startDate: params.startDate,
        endDate: params.endDate,
        costHour: params.costHour,
        holidayCostHour: params.holidayCostHour,
        extraCostDay: params.extraCostDay,
        paidHolidays: params.paidHolidays,
        docStatus: status(params.docStatus),
        docDate: params.docDate || date,
        signStatus: status(params.signStatus),
        signDate: params.signDate || date,
        accessStatus: status(params.accessStatus),
        accessDate: params.accessDate || date,
        formStatus: status(params.formStatus),
        formDate: params.formDate || date,
        operationStatus: status(params.operationStatus),
        operationDate: params.operationDate || date,
      };
    },
    update(params) {
      const target = "Campaigns/setCenter";
      params = this.prepare(params);

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("api", { target, params })
          .then((res) => resolve(this.compute(res)))
          .catch(reject);
      });
    },
    color(value) {
      switch (value) {
        case 0:
          return "error";
        case 4:
          return "success";
        default:
          return "warning";
      }
    },
    okColor(value) {
      switch (value) {
        case -1:
          return "error";
        case 1:
          return "success";
        default:
          return "warning";
      }
    },
    icon(value) {
      switch (value) {
        case 4:
          return "mdi-checkbox-marked-circle";
        default:
          return "mdi-checkbox-blank-circle";
      }
    },
    showAddCenters() {
      this.form.show = true;
      this.value = {};
      this.value.costHour = COST_HOUR;
      this.value.holidayCostHour = HOLIDAY_COST_HOUR;
      this.value.extraCostDay = EXTRA_COST_DAY;
      this.value.paidHolidays = false;
      this.$refs.formCenters?.reset();
    },
    disableCenters() {
      this.form.disabled =
        !this.form.valid || !this.value.places || !this.value.places.length;
    },
    onSelected( value ) {
      this.selected = value;
    },
    sendCredentials() {
      this.loading = true;
      return this.$store.dispatch( 'api', {
        target: 'Campaigns/credentials',
        params: { idCampaign: this.$route.params.id, ids: this.selected.map( item => item.id ) }
      }).then(() => {
        this.loading = false;
        this.$store.dispatch( 'console/success', 'Se ha procesado tu petición, recibirás un correo con la información de los envíos realizados.' );
      }).catch( err => {
        this.loading = false;
        this.$store.dispatch( 'console/error', err );
      });
    },
    onAddCenters() {

      const { startDate, endDate, costHour, holidayCostHour, extraCostDay, paidHolidays, places } = this.value;
      const target = "Campaigns/assignCenters";
      const params = {
        id: parseInt(this.$route.params.id),
        places: places.map((place) =>
          this.prepare({
            place,
            startDate,
            endDate,
            costHour, 
            holidayCostHour,
            extraCostDay,
            paidHolidays
          })
        ),
      };

      this.form.loading = true;
      this.$store
        .dispatch("api", { target, params })
        .then(() => {
          this.form.show = false;
          this.reload();
        })
        .catch( err => this.$store.dispatch( 'console/error', err ))
        .finally(() => this.form.loading = false );
    },
    reload() {
      const { tableComponent } = this.$refs;
      if ( tableComponent ) tableComponent.reload();
    },
    setTitle() {
      const id = parseInt( this.$route.params.id );
      const title = [ 'Campañas', '', 'Centros' ];
      this.$store.commit( 'setTitle', title );

      if ( this.breadcrumbs[1].text === '...' && id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

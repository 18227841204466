<template>
  <Single
    service="Campaigns"
    :show-actions="actions"
    :messages="messages"
    :validate="validate"
    :activable="activable"
    :sanitize="sanitize"
    @initialized="setTitle"
    @saved="onSaved"
    btn-clean-text="Borrar archivos de campaña"
  >
    <template v-slot="{ value, listeners, save }">

      <Campaign
        :value="value"
        :save="save"
        v-on="listeners"
        @save="save()"
        @modules-complete="complete = $event"
      />

    </template>
  </Single>
</template>

<script>

  import Single from '@/components/core/Single.vue';
  import Campaign from '@/components/forms/Campaigns/single.vue';
  import { mapState } from 'vuex';

  export default {
    components: { Single, Campaign },
    data: () => ({
      messages: { item: 'Campaña', fem: true },
      complete: false
    }),
    computed: {
      ...mapState([ 'isRRHH' ]),
      actions() {
        return this.isRRHH ? null : 'save, archive, active';
      }
    },
    methods: {
      setTitle( res ) {
        this.$store.commit( 'setTitle', [ 'Campañas', res && res.title ]);
      },
      validate( form ) {
        if ( ! form.value || ! form.valid || form.validating ) return false;
      },
      activable( form ) {
        if ( ! form.value || ! form.valid || form.validating ) return false;
        if ( ! this.complete ) return false;
      },
      sanitize( model ) {
        return {
          ...model,
          promoterType: model.type,
          idBrand: model.brand ? model.brand.id : null,
          brands: ( model.brands || [] ).map( m => m.id ),
          idSector: model.sector ? model.sector.id : null,
          idClient: model.client ? model.client.id : null,
          idAccountManager: model.accountManager ? model.accountManager.id : null,
          managers: ( model.managers || [] ).map( m => m.id ),
          kams: ( model.kams || [] ).map( m => m.id ),
          clientUsers: ( model.clientUsers || [] ).map( m => m.id ),
          modules: model.modules || []
        };
      },
      onSaved() {
        this.$store.commit('setCampaigns');
      }
    },
    beforeRouteEnter ( to, from, next ) {
      next( vm => {
        if ( vm.$store.state.isClient ) {
          //vm.$store.commit( 'cancelFetch', to.fullPath );
          if ( to.params.id === 'new' ) vm.$router.replace('/campaigns');
          else vm.$router.replace(`/campaigns/${ to.params.id }/reports`);
        }
      });
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

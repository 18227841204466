<template>
  <Table
    service="Campaigns"
    :sections="sections"
    :messages="messages"
    :headers="headers"
    :params="parameters"
    @section="section = $event[0]"
    :show-actions="actions"
    @calendar="goToCalendar"
  >

    <template v-slot:item.client="{ item, value }">
      <router-link class="body-grupo-ot grupo-ot-link" :to="`/clients/${ item.client.id }`" :title="value" v-if="item.client">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.status="{ item, value }">
      <span :class="`${ statusType(item) }--text body-grupo-ot`" :title="value">
        {{ value }}
      </span>
    </template>

    <template v-slot:item.calendar="{ item }">
      <Btn color="tertiary" @click.stop="goToCalendar(item)" small dark>
        Ver
      </Btn>
    </template>

    <template v-slot:item.modules="{ item, value }">
      <v-menu offset-y>

        <template v-slot:activator="{ on }">
          <Btn class="pl-1 pr-0" color="tertiary" width="120" outlined small text v-on="on">
            {{ value.length }} modulos
            <v-icon>$dropdown</v-icon>
          </Btn>
        </template>

        <Card border>
          <v-list class="py-0" dense>

            <template v-for="mod in value">
              <v-list-item v-if="mod" class="pr-0" :key="mod[0]" :to="`/campaigns/${ item.id + mod[1] }`">
                <v-list-item-title class="body-grupo-ot" style="color: #777;">
                  {{ mod[0] }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon x-small>$next</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>

            <v-divider/>

            <v-list-item class="pr-0" :to="`/campaigns/${item.id}/targets`">
              <v-list-item-title class="caption" style="color:#777;">
                Objetivos
              </v-list-item-title>
              <v-list-item-action>
                <v-icon x-small>$next</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item class="pr-0" :to="`/campaigns/${item.id}/options`">
              <v-list-item-title class="caption" style="color:#777;">
                Opciones
              </v-list-item-title>
              <v-list-item-action>
                <v-icon x-small>$next</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </Card>

      </v-menu>
    </template>

  </Table>
</template>

<script>

  import Table from "@/components/core/Table.vue";
  import modules from '@/utils/modules';
  import moment from 'moment';
  import { formatDate } from "@/utils";
  import { CampaignStatus } from "@/utils/constants";
  import { mapState } from 'vuex';

  export default {
    components: { Table },
    data() {
      return {
        today: moment().startOf('day'),
        section: 0,
        messages: { item: "Campaña/Campañas", fem: true }
      };
    },
    computed: {
      ...mapState([ 'user' ]),
      headers() {

        const headers = [
          { text: "id", value: "id", width: 80, filter: {
            type: 'number',
            field: 'ids',
            attrs: { min: 1, hideControls: true }
          }},
          { text: "Titulo",      value: "title",       },
          { text: "Descripción", value: "description"  },
          {
            text: "Cliente", value: "client", display: 'name',
            filter: {
              type: 'autocomplete',
              attrs: {
                service: 'Clients',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          },
          { text: "Inicio", value: "startDate", width: 90, display: this.date, filter: { type: 'date' }},
          { text: "Fin", value: "endDate", width: 90, display: this.date, filter: { type: 'date' }},
          { text: "Centros", value: "centers", width: 75, display: "length", align: 'center', filterable: false }
        ];

        if ( ! this.$store.state.isClient ) {
          headers.push({
            text: "Modulos", value: "modules", width: 230, display: this.mods,
            filterable: false, sorteable: false
          });
        } else if ( this.user && this.user.calendarEnabled ) {
          headers.push({
            text: "Calendario", value: "calendar", width: 230,
            filterable: false, sorteable: false
          });
        } else {
          headers[headers.length-1].width = 230;
        }

        if ( this.section === 0 )
          headers.unshift({
            text: 'Estado', value: 'status', display: this.statusText,
            width: 80, filterable: false, sorteable: false
          });

        return headers;
      },
      sections() {
        if ( ! this.$store.state.isClient ) {
          return [
            { text: 'Activa',     filters: [{ field: 'status', intValue: CampaignStatus.ACTIVE   }]},
            { text: 'Borrador',   filters: [{ field: 'status', intValue: CampaignStatus.DRAFT    }]},
            { text: 'Archivada',  filters: [{ field: 'status', intValue: CampaignStatus.ARCHIVED }]},
          ];
        }
        return null;
      },
      actions() {
      if ( this.$store.state.isClient ) {
        return "view";
      }
      if ( this.$store.state.isAdmin ) {
        return "new, edit, delete, export, import, duplicate";
      }
      return "new, edit, delete, export";
    },
      parameters() {
        if ( this.$store.state.isClient ) {
          return {
            filters: [{ field: 'status', intValue: CampaignStatus.ACTIVE }]
          }
        }
        return null;
      },
      modules() {
        const mods = {};
        modules.views.forEach( m => mods[ m.id ] = m );
        modules.config.forEach( m => mods[ m.id ] = m );
        return mods;
      }
    },
    methods: {
      date( value ) {
        if ( value ) {
          return formatDate( value, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          });
        }
        return "";
      },
      mods( value ) {
        const { modules } = this;
        return value
          .map( i => modules[i] ? [ modules[i].title, modules[i].path, value.length ] : null )
          .filter( a => a );
      },
      statusText( value, header, item ) {
        if ( this.today.isSameOrAfter( moment( item.endDate ))) {
          return 'Finalizada';
        } else if ( this.today.isBefore( moment( item.startDate ))) {
          return 'No iniciada';
        } else {
          return 'Iniciada';
        }
      },
      statusType( item ) {
        if ( this.today.isSameOrAfter( moment( item.endDate ))) {
          return 'error';
        } else if ( this.today.isBefore( moment( item.startDate ))) {
          return 'warning';
        } else {
          return 'success';
        }
      },
      goToCalendar( item ) {
        this.$router.push(`/campaigns/${item.id}/calendar`);
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Campañas' );
    }
  };
</script>

<template>
  <div class="rel" :style="_size">
    <GmapMap
      ref="gmap"
      class="full"
      :center="center"
      :zoom="_zoom"
      :options="mapOptions"
      v-on="_listeners"
      v-bind="$attrs"
      @dragend="onDragEnd"
      @zoom_changed="onZoomChanged"
    >

      <GmapMarker
        v-if="showPosition && model"
        :position="model"
        v-bind="positionStyle"
      />

      <GmapMarker
        v-for="( marker, index ) in markers"
        :key="index"
        v-bind="marker"
        v-on="markerOn( marker.on, marker, index )"
      />

      <gmap-polyline
        v-for="( polyline, e ) in polylines"
        :key="`path-${ e }`"
        v-bind="polyline"
      />

      <slot/>

    </GmapMap>

    <v-btn
      v-if="editable"
      class="gmap-btn-place"
      color="white"
      width="38" height="38"
      :ripple="false"
      @click="toggle"
      elevation="3"
      dark fab
    >
      <v-icon :color="setPosition ? 'primary' : 'border'">
        $edit
      </v-icon>
    </v-btn>

    <v-autocomplete
      v-if="searcheable"
      v-model="selected"
      :items="finded"
      :search-input.sync="search"
      class="gmap-autocomplete-input"
      background-color="white"
      item-text="text"
      autocomplete="off"
      color="secondary"
      prepend-inner-icon="$search"
      append-icon=""
      outlined
      clearable
      hide-selected
      hide-no-data
      return-object
      dense
    />

    <span
      v-if="editable && setPosition"
      class="gmap-center"
    />

  </div>
</template>

<script>

  /* eslint-disable */
  import { formatSize } from '@/utils';

  export default {
    props: {
      value: Object,
      options: Object,
      query: [ String, Object ],
      zoom: {
        type: [ Number, String ],
        default: 10
      },
      width: {
        type: [ Number, String ],
        default: '100%'
      },
      height: {
        type: [ Number, String ],
        default: '100%'
      },
      markers: Array,
      polylines: Array,
      positionStyle: Object,
      showPosition: Boolean,
      editable: Boolean,
      searcheable: Boolean
    },
    data() {
      return {
        model: this.value,
        center: { lat: 42, lng: 1 },
        setPosition: false,
        search: '',
        finded: [],
        selected: null,
        service: null,
        mapOptions: {
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
          ...this.options
        }
      }
    },
    watch: {
      value( value ) {
        this.model = value;
        value && this.centrate();
      },
      center( value ) {
        if ( this.editable && this.setPosition ) {
          this.model = value;
          this.$emit( 'input', value );
        }
      },
      search( value ) {
        value && this.find( value );
      },
      query( value ) {
        value && this.find( value );
      },
      selected( value ) {
        value && ( this.center = value.position );
      },
      _positions() {
        this.centrate( true );
      }
    },
    computed: {
      _zoom() {
        return parseFloat( this.zoom );
      },
      _size() {
        return {
          width:  formatSize( this.width ),
          height: formatSize( this.height )
        }
      },
      _positions() {
        return ( this.markers || [] ).map( marker => marker.position )
          .concat(( this.polylines || [] ).map( poly => poly.path ).flat())
          .filter( p => p && !isNaN( p.lat + p.lng ));
      },
      _listeners() {
        const listeners = {};
        for ( var key in this.$listeners )
          listeners[key] = this.createHandler( this.$listeners[key] );
        return listeners;
      }
    },
    methods: {
      map() {
        return new Promise( resolve => {
          if ( ! this.$refs.gmap ) {
            setTimeout(() => this.map().then( resolve ), 10 );
          } else {
            this.$refs.gmap.$mapPromise.then( resolve );
          }
        })
      },
      createHandler( handler ) {
        return function( event ) {
          this.map().then( map => {
            if ( typeof handler === 'function' ) {
              handler.call( event, map );
            }
          });
        }
      },
      centrate( withAll ) {
        this.map().then( map => {
          if ( withAll ) {

            const bounds = new google.maps.LatLngBounds();
            const positions = this._positions;

            if ( positions.length > 1 ) {

              positions.forEach( position => {
                bounds.extend( new google.maps.LatLng(
                  position.lat,
                  position.lng
                ));
              });

              map.fitBounds( bounds );
              this.getCenter( map );

            } else if ( positions.length === 1 ) {

              this.center = positions[0];

            }
          } else if ( this.model && ( this.model.lat !== this.center.lat || this.model.lng !== this.center.lng )) {

            this.center = this.model;

          }
        });
      },
      getCenter( map ) {
        const center = map.getCenter();
        this.center  = {
          lat: center.lat(),
          lng: center.lng()
        };
      },
      onDragEnd() {
        this.map().then( map => {
          this.getCenter( map );
          if( typeof this.$listeners.dragend === 'function' )
            this.$listeners.dragend( map );
        });
      },
      onZoomChanged() {
        this.map().then( map => {
          clearInterval( this.interval );
          this.interval = setTimeout(() => this.getCenter( map ), 500 );
          if( typeof this.$listeners.zoom_changed === 'function' )
            this.$listeners.zoom_changed( map );
        });
      },
      toggle() {
        this.map().then( map => {
          this.setPosition = !this.setPosition;
          this.getCenter( map );
        });
      },
      find( query ) {

        if ( ! this.service || ! this.searcheable ) return;

        const status  = google.maps.places.PlacesServiceStatus;
        const request = typeof query === 'object'
          ? { location: this.center, ...query }
          : { query };

        this.service.textSearch( request, ( results, status ) => {
          switch ( status ) {
            case status.OK:
              this.finded = results.map( data => ({
                text: data.formatted_address,
                position: data.geometry.location,
                data
              }))
            break;
            //case status.INVALID_REQUEST: break;
            //case status.OVER_QUERY_LIMIT: break;
            //case status.REQUEST_DENIED: break;
            //case status.UNKNOWN_ERROR: break;
            //case status.ZERO_RESULTS: break;
            //case status.UNKNOWN_ERROR: break;
            default:
              this.finded = [];
          }
        });
      },
      listener( handler, marker, index ) {
        return function( event ) {
          return handler( event, marker, index );
        }
      },
      markerOn( on, marker, index ) {
        if ( on ) {
          for ( var listener in on )
            on[listener] = this.listener( on[listener], marker, index );
        }
        return on;
      }
    },
    mounted() {
      this.centrate( true );
      this.map().then( map => {
        this.service = new google.maps.places.PlacesService( map );
      });
    }
  }
</script>

<style>
  .gmap-autocomplete-input {
    position: absolute;
    top: 10px;
    left: 8px;
    right: 60px;
    box-shadow: 0 3px 6px rgba(0,0,0,.25);
  }
  .gmap-btn-place {
    position: absolute;
    top: 11px;
    right: 11px;
  }
  .gmap-center {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
  }
  .gmap-center::before, .gmap-center::after {
    position: absolute;
    display: block;
    content: '';
    background-color: white;
    box-shadow: 0 0 3px #000;
  }
  .gmap-center::before {
    width: 30px;
    height: 1px;
    left: -15px;
  }
  .gmap-center::after {
    height: 30px;
    width: 1px;
    top: -15px;
  }
</style>

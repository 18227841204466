<template>
  <DForm ref="form" :value="value" v-on="{ ...$listeners, input }">
    <v-row class="pa-2" no-gutters>
      <v-col class="reports-column pa-2" cols="12" :md="columns[0]">

        <DField v-if="isNew" key="type" v-bind="type" class="mb-6"/>

        <DField key="title" v-bind="title" class="mb-6"/>

        <DField key="description" v-bind="description" class="mb-6"/>

        <DField key="frequency" v-bind="frequency" class="mb-6"/>

        <div v-show="[ frequencyType.PUNTUAL, frequencyType.PUNTUAL_UNIQUE ].indexOf( modelFrequency ) !== -1">
          <DField
            key="date"
            v-bind="date"
            class="mb-6"
          />
          <DField
            key="endDate"
            v-bind="endDate"
            class="mb-6"
          />
        </div>

        <DField
          v-if="isPicturesType"
          key="picture.numPictures"
          v-bind="pictures"
          class="mb-6"
        />

        <DField
          key="exportable"
          v-show="modelType === actionType.SALES"
          v-bind="exportable"
          class="mb-4"
          @input="revalidate"
        />

        <DField
          key="categories"
          v-show="modelType === actionType.FOLLOW"
          v-bind="categories"
          class="mb-6"
        />

        <DField
          key="addCategories"
          v-show="modelType === actionType.FOLLOW"
          v-bind="addCategories"
          class="mb-4"
          @input="revalidate"
        />

        <div v-if="!isClient && isSurveyType">

          <DField
            ref="surveysField"
            key="survey.survey"
            v-bind="surveys"
            class="mb-6"
            return-object
          />

          <Btn class="mb-2" color="tertiary" @click="createSurvey" block dark>
            Nuevo cuestionario
          </Btn>

          <v-alert type="warning" v-if="surveyUses > 0" style="font-size:0.9em;">

            Este cuestionario está siendo utilizado por {{ surveyUses }} acciones.
            En caso de modificación se verían modificadas todas estas acciones.
            En caso de que solamente la quiera modificar en esta acción por favor duplique

            <Btn color="white" @click="duplicateSurvey" :loading="duplicating"
              :disabled="!survey" light block style="margin-top:10px;">
              Duplicar cuestionario
            </Btn>
          </v-alert>

        </div>

      </v-col>
      <v-col class="reports-column pa-2" cols="12" :md="columns[1]">
        <Card v-if="hasSurvey" transition="fade" class="pa-2" border>

          <Survey
            :value="survey"
            :type="modelType"
            :readonly="isClient"
            @input="change( 'survey.survey', $event )"
          />

        </Card>
      </v-col>
    </v-row>
  </DForm>
</template>

<script>

  import Survey from '@/components/forms/Surveys/single';
  import { CampaignActionType, CampaignActionFrequency } from '@/utils/constants';
  import { mapState } from 'vuex';
  import { get } from '@/utils';

  export default {
    components: { Survey },
    props: { value: Object },
    data() {
      return {
        model: this.value || {},
        actionType: CampaignActionType,
        frequencyType: CampaignActionFrequency,
        survey: get( this.value, 'survey.survey' ),
        surveyItems: [],
        duplicating: false,
        modelType: 0,
        modelFrequency: 0
      }
    },
    watch: {
      modelType: 'resetSurvey',
      surveyUses( value ) {
        this.$emit( 'survey-uses', value );
      },
    },
    computed: {
      ...mapState(['isClient']),
      isNew() {
        return this.$route.params.index === 'new';
      },
      isPicturesType() {
        return this.modelType === CampaignActionType.PICTURES;
      },
      isSalesType() {
        return this.modelType === CampaignActionType.SALES;
      },
      isSurveyType() {
        return [
          CampaignActionType.SURVEY,
          CampaignActionType.SALES,
          CampaignActionType.FOLLOW
        ].indexOf( this.modelType ) !== -1;
      },
      hasSurvey() {
        return this.isSurveyType && this.survey;
      },
      surveyUses() {
        return this.uses( this.survey );
      },
      columns() {
        return this.hasSurvey
          ? [ 4, 8 ]
          : [ 7, 5 ];
      },
      type() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Tipo de acción',
          dense: true,
          readonly: this.isClient,
          items: [
            { text: 'Fotografia',   value: CampaignActionType.PICTURES },
            { text: 'Cualitativa',  value: CampaignActionType.SURVEY   },
            { text: 'Cuantitativa', value: CampaignActionType.SALES    },
            { text: 'Encuesta',  value: CampaignActionType.FOLLOW   }
          ],
          rules: [
            v => v != null || 'Debes especificar un tipo de acción.'
          ]
        }
      },
      frequency() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Frecuencia de la acción',
          dense: true,
          readonly: this.isClient,
          items: [
            { text: 'Diariamente',         value: CampaignActionFrequency.DAYLY   },
            { text: 'Una vez a la semana', value: CampaignActionFrequency.WEEKLY  },
            { text: 'Una vez al mes',      value: CampaignActionFrequency.MONTHLY },
            { text: 'Acción puntual (diaria)',      value: CampaignActionFrequency.PUNTUAL },
            { text: 'Acción puntual (única respuesta)',      value: CampaignActionFrequency.PUNTUAL_UNIQUE }
          ],
          rules: [
            v => v != null || 'Debes especificar la frecuencia de la acción.'
          ]
        }
      },
      title() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Título',
          readonly: this.isClient,
          dense: true
        }
      },
      description() {
        return {
          field: 'text',
          fieldStyle: 1,
          label: 'Descripción',
          readonly: this.isClient,
          dense: true
        }
      },
      date() {
        return {
          field: 'date',
          fieldStyle: 1,
          label: 'Inicio de la acción puntual',
          max: this.model.endDate,
          dense: true,
          future: true,
          readonly: this.isClient,
          rules: [
            v => !!v || 'Debes especificar la fecha de inicio de la acción.'
          ]
        }
      },
      endDate() {
        return {
          field: 'date',
          fieldStyle: 1,
          label: 'Fin de la acción puntual',
          min: this.model.date,
          dense: true,
          future: true,
          readonly: this.isClient,
          rules: [
            v => !!v || 'Debes especificar la fecha de fin de la acción.'
          ]
        }
      },
      pictures() {
        return {
          field: 'number',
          fieldStyle: 1,
          label: 'Mínimo # de fotos',
          min: 0,
          max: 300,
          dense: true,
          readonly: this.isClient
        }
      },
      surveys() {
        return {
          field: 'autocomplete',
          fieldStyle: 1,
          label: 'Cuestionario',
          service: 'Surveys',
          method: 'all',
          params: { filters:[{ field: 'type', intValue: this.modelType }]},
          items: this.surveyItems,
          itemText: 'title',
          itemValue: 'id',
          readonly: this.isClient,
          display: item => `${item.id}.${item.title}`,
          dense: true,
          all: true
        }
      },
      categories() {
        return {
          field: 'text',
          label: 'Categorias',
          placeholder: 'Nombre de categoría o categorías separadas por coma',
          readonly: this.isClient,
          fieldStyle: 1,
          dense: true,
          rules: [
            v => this.model.addCategories ? true : !!v || 'Debes añadir almenos una categoría o marcar la casilla de "Se permite añadir categorías"'
          ]
        }
      },
      addCategories() {
        return {
          field: 'checkbox',
          readonly: this.isClient,
          label: 'Se permite añadir categorias'
        }
      },
      exportable() {
        return {
          field: 'checkbox',
          readonly: this.isClient,
          label: 'Se exporta la acción al validarse'
        }
      }
    },
    methods: {
      revalidate() {
        this.$nextTick(() => this.$refs.form && this.$refs.form.validate());
      },
      createSurvey() {

        const num = this.surveyItems.length;
        const survey = {
          id: -num - 1,
          type: this.modelType,
          title: [ 'Nuevo cuestionario', num ? ( num + 1 ) : '' ].join(' '),
          description: '',
          questions: []
        };

        this.surveyItems.push( survey );
        this.change( 'survey.survey', survey );
      },
      duplicateSurvey() {
        if ( this.survey ) {

          const field = get( this.$refs, 'surveysField.$vnode.componentInstance.$children.0' );
          const target = 'Surveys/duplicate';
          const params = { ids: [ this.survey.id ] };

          this.duplicating = true;
          this.$store
            .dispatch( 'api', { target, params })
            .then( survey => {
              field.add( survey[0] );
              this.change( 'survey.survey', survey[0] );
            })
            .catch( err => this.$store.dispatch( 'console/error', err ))
            .finally(() => this.duplicating = false );
        }
      },
      resetSurvey() {
        if ( this.survey && this.survey.id > 0 ) {
          const field = get( this.$refs, 'surveysField.$vnode.componentInstance.$children.0' );
          field && field.reset();
          this.survey = null;
        }
      },
      input( value ) {
        this.model = value;
        this.modelType = value.type;
        this.modelFrequency = value.frequency;
        this.survey = get( value, 'survey.survey' );
        if ( this.$listeners.input ) this.$listeners.input( value );
        this.$emit( 'input', value );
      },
      change( key, value ) {
        if ( this.$refs.form )
          this.$refs.form.set( key, value );
      },
      uses( value ) {
        if ( value && value.actions ) {
          return value.actions.filter( id => this.model.id != id ).length;
        }
        return 0;
      }
    }
  }
</script>

<style>
  .reports-column {
    transition: all .135s ease;
  }
</style>

<template>
  <v-textarea
    class="dfield-textarea"
    :class="{ dense }"
    v-bind="attr"
    v-on="$listeners"
    color="secondary"
  >

    <label v-if="attr.preLabel" slot="prepend" class="label" :style="labelStyle">
      {{ label }}
    </label>

    <slot v-for="( s, name ) in $slots" :name="name"/>

    <template v-for="( s, name ) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>

  </v-textarea>
</template>

<script>

  import { formatSize } from '@/utils';

  export default {
    nullValues: '',
    props: {
      label: String,
      labelSpace: [ Number, String ],
      fieldStyle: [ Number, String ],
      dense: Boolean
    },
    computed: {
      attr() {
        switch ( String( this.fieldStyle )) {
          case '1':
            return {
              ...this.$attrs,
              backgroundColor: 'white',
              label: this.label,
              outlined: true
            };
          case '2':
            return {
              ...this.$attrs,
              preLabel: true,
              dense: true
            };
        }
        return this.$attrs;
      },
      labelStyle() {
        const space = this.labelSpace;
        return { width: formatSize( space ) };
      }
    }
  }
</script>

<style>
  .dfield-textarea.dense textarea {
    margin-top: 7px !important;
  }
  .dfield-textarea.dense .v-input__prepend-outer,
  .dfield-textarea.dense .v-input__prepend-inner,
  .dfield-textarea.dense .v-input__append-inner,
  .dfield-textarea.dense .v-input__append-outer {
    margin-top: 8px !important;
  }
  .dfield-textarea .v-input__slot {
    min-height: 40px !important;
  }
</style>

<template>
  <v-main class="fill-height">
    <v-app-bar class="header-bar justify-center" elevation="0" dense app>
      <v-app-bar-nav-icon class="navigation-icon" @click="toggleDrawer()">
        <img
          style="padding-left:100px;"
          height="32"
          :src="require('@/assets/logo.png')"
          alt=""
          />
      </v-app-bar-nav-icon>
      <v-row class="layer" justify="center" align="center" no-gutters>
        <template v-for="( crumb, index ) in crumbs">

          <span v-if="index === crumbs.length - 1" class="crumb secondary--text" :key="`crumb-${index}`">
            {{ crumb.text }}
          </span>

          <router-link v-else class="crumb secondary--text" :to="crumb.to" :key="`crumb-${index}`">
            {{ crumb.text }}
          </router-link>

          <v-icon v-if="index < crumbs.length - 1" :key="`icon-${index}`" class="mx-1" small>
            $next
          </v-icon>

        </template>
      </v-row>
    </v-app-bar>
    <Drawer :items="menu" :silent="silent" app>
      <template v-slot:submenu>

        <slot name="top"/>

      </template>

      <slot/>

      <template v-slot:footer>

        <slot name="footer"/>

      </template>
    </Drawer>
  </v-main>
</template>

<script>

  import Drawer from '@/components/core/Drawer.vue';
  import { mapMutations } from 'vuex';

  export default {
    components: { Drawer },
    name: 'Layout',
    props: {
      menu: Array,
      breadcrumbs: Array,
      silent: Boolean
    },
    computed: {
      crumbs() {
        return this.breadcrumbs || [];
      }
    },
    methods: {
      ...mapMutations([ 'setPath' ]),
      ...mapMutations( 'app', [ 'toggleDrawer' ])
    },
    mounted() {
      if ( this.$store.state.userID <= 0 )
        this.$router.push('/login');
    }
  };
</script>

<style>
  .header-bar {
    position: relative;
    border-bottom: 1px solid var(--v-border-base) !important;
  }
  .navigation-icon {
    position: absolute;
    top: 0;
    left: 14px;
    z-index: 1;
  }
  span.crumb {
    font-weight: bold;
  }
  a.crumb {
    text-decoration: none;
    font-size: inherit;
  }
  a.crumb:hover {
    text-decoration: underline;
  }
</style>

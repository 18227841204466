<template>
  <DForm :value="value" v-on="{ ...$listeners, input }">
    <DField key="date" v-bind="date" class="mb-6 bg-grey" />
    <DField key="build" v-bind="build" class="mb-6" />
    <DField key="description" v-bind="description" class="mb-6" />
  </DForm>
</template>

<script>
export default {
  props: {
    value: Object,
    readonly: Boolean,
  },
  data: () => ({
    date: {
      field: "date",
      fieldStyle: 1,
      label: "Fecha",
      dense: true,
      rules: [ v => !!v || 'Debes indicar una fecha.' ]
    },
    build: {
      field: "text",
      fieldStyle: 1,
      label: "Versión",
      dense: true,
      rules: [(v) => !!v || "El campo es requerido."],
    },
    description: {
      field: "text",
      fieldStyle: 1,
      label: "Descripción",
      dense: true,
      rules: [(v) => !!v || "El campo es requerido."],
    },
  }),
  watch: {
  },
  methods: {
    input( value ) {
        this.model = value;
        if ( this.$listeners.input )
          this.$listeners.input( value );
      },
  },
  computed: {
  },
};
</script>

<template>
  <div>
    <label class="d-inline-block body-2 mb-2">{{ label }}</label>
    <v-row class="flex-nowrap" no-gutters>

      <Btn
        class="day-week-btn mr-2"
        v-for="( label, index ) in days"
        :key="label"
        :color="index === model ? 'primary' : 'border'"
        :outlined="index !== model"
        @click="model = index"
        width="20"
        height="30"
      >
        {{ label }}
      </Btn>

    </v-row>
  </div>
</template>

<script>

  export default {
    props: {
      value: Number,
      label: String,
      initWeekOnValue: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        model: this.real( this.value || 0 ),
        days: [ 'L', 'M', 'MX', 'J', 'V', 'S', 'D' ]
      }
    },
    watch: {
      value( value ) {
        this.model = this.real( value );
      },
      model( value ) {
        this.$emit( 'input', this.compute( value ));
      }
    },
    methods: {
      compute( index ) {
        return ( index + ( this.initWeekOnValue || 0 )) % 7;
      },
      real( index ) {
        index -= ( this.initWeekOnValue || 0 );
        return Math.max( Math.min( index < 0 ? 7 - index : index, 6 ), 0 );
      }
    }
  }
</script>

<style>
  .day-week-btn {
    min-width: auto !important;
  }
</style>

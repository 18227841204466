<template>
  <div class="slider mask">
    <div
      class="slider-wrapper"
      :class="{ transition }"
      :style="`left:${ -index * 100 }%`"
    >
      <div
        v-for="( img, i ) in images"
        :key="i"
        :style="`left: ${ i * 100 }%;padding-bottom: ${ paddingBottom };`"
        :class="{ 'with-footer': !!footer[index] }"
        class="slider-image"
      >
        <v-img
          v-if="itemImage"
          :src="get( img, itemImage ) || ''"
          class="fill-height"
          contain
        />
      </div>
    </div>

    <div ref="footer" class="slider-footer" v-if="footer[index]">
      <div
        v-html="footer[index].template || ''"
        :class="`${ footer[index].color || 'white' }--text`"
        style="position: relative; z-index: 1;"
      />
      <v-sheet
        :color="footer[index].background || 'black'"
        :style="{ opacity: footer[index].opacity, zIndex: 0 }"
        class="layer"
      />
    </div>

    <div v-if="hasPrev" class="slider-arrows prev">
      <Btn color="white" @click="go( index - 1 )" x-large icon>
        <v-icon>$prev</v-icon>
      </Btn>
    </div>

    <div v-if="hasNext" class="slider-arrows next">
      <Btn color="white" @click="go( index + 1 )" x-large icon>
        <v-icon>$next</v-icon>
      </Btn>
    </div>

    <div class="slider-actions">

      <Btn v-if="remove" class="mr-2" color="white" @click="onremove" icon>
        <v-icon>mdi-delete</v-icon>
      </Btn>
      <Btn v-if="download" class="mr-2" color="white" @click="ondownload" icon>
        <v-icon>mdi-download</v-icon>
      </Btn>

      <Btn color="white" @click="$emit('close')" icon>
        <v-icon>$close</v-icon>
      </Btn>

    </div>

  </div>
</template>

<script>

import { get, downloadImage } from '@/utils';

export default {
  props: {
    value: Number,
    items: Array,
    itemImage: String,
    itemDownload: String,
    itemFooter: [ String, Function ],
    download: Boolean,
    remove: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      index: this.value || 0,
      paddingBottom: null,
      transition: false
    };
  },
  watch: {
    value( value ) {
      this.index = value;
      this.paddingBottom = this.footerHeight();
    },
    index() {
      this.paddingBottom = this.footerHeight();
    },
    items() {
      this.paddingBottom = this.footerHeight();
    },
    itemFooter() {
      this.paddingBottom = this.footerHeight();
    }
  },
  computed: {
    images() {
      return this.items || [];
    },
    footer() {

      if ( ! this.itemFooter ) return [];
      const style = { color: 'black', background: 'white', opacity: 1 };

      return this.images.map( item => {
        return typeof this.itemFooter === 'string'
          ? { ...style, template: get( item, this.itemFooter )}
          : { ...style, ...this.itemFooter( item ) };
      });
    },
    hasPrev() {
      return this.images.length > 1 && this.index;
    },
    hasNext() {
      return this.images.length > 1 && this.index < this.images.length - 1;
    }
  },
  methods: {
    get,
    go( index ) {
      clearInterval(this._interval);
      this.transition = true;
      this.$nextTick(() => {
        this.index = Math.max( Math.min( index, this.images.length - 1 ), 0 );
        this.$emit( 'input', this.index );
        this._interval = setTimeout(() => this.transition = false, 250 );
      });
    },
    footerHeight() {
      if ( this.$refs.footer ) {
        var height = this.$refs.footer.clientHeight;
        return height ? ( height + 40 )+'px' : null;
      }
      return 0;
    },
    ondownload() {
      const img = this.images[ this.index ];
      var url;
      if ( this.itemDownload ) {
        url = get( img, this.itemDownload );
      } else {
        url = get( img, this.itemImage );
      }
      url && downloadImage( url );
      this.$emit( 'download', img );
    },
    onremove() {
      const img = this.images[ this.index ];
      this.$emit( 'remove', img );
    }
  },
  mounted() {
    this.paddingBottom = this.footerHeight();
  }
};
</script>

<style>
.slider {
  position: relative;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.slider-wrapper, .slider-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.slider-wrapper {
  left: 0;
}
.slider-wrapper.transition {
  transition: left .25s ease;
}
.slider-image {
  padding: 80px;
}
.slider-image.with-footer {
  padding-top: 40px;
  padding-bottom: 120px;
}
.slider-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 4px;
  text-align: center;
}
.slider-arrows {
  display: flex;
  position: absolute;
  top: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.slider-arrows.next {
  right: 0;
}
.slider-arrows.prev {
  left: 0;
}
.slider-actions {
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
}
</style>

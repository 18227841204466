<template>
  <Page
    :loading="loading"
    hide-footer
  >
    <div class="layer mask">
      <v-row class="chat-wrapper layer no-gutters" :style="position">
        <Card class="rel" v-bind="column(0)" :border-right="!isMobile">
          <v-row class="flex-column flex-nowrap fill-height" no-gutters>
            <Card class="d-flex rel" height="52">

              <Tabs
                v-model="selectedGroup"
                class="align-self-end pa-0"
                :items="tabs"
              />

              <Btn :color="searchActive ? 'secondary' : 'border'" absolute right style="top: 8px;"
                @click="searchActive = !searchActive" icon>
                <v-icon>$search</v-icon>
              </Btn>

            </Card>
            <v-expand-transition>
              <Card v-if="searchActive" height="52" class="d-flex align-center px-2" border-top>

                <DField
                  field="text"
                  field-style="1"
                  v-model="search"
                  autofocus
                  dense
                />

              </Card>
            </v-expand-transition>
            <Card class="grow rel" border-top>
              <div :key="key" class="layer autoscroll">

                <Chats
                  :value="selected"
                  :items="filteredChats"
                  @input="set({ selected: [$event] })"
                />

                <Infinite
                  :identifier="infiniteChatID"
                  error-message="No se han podido cargar los chats :("
                  @infinite="loadChats"
                />

              </div>
            </Card>
            <Toolbar border-top>

              <v-spacer/>

              <Btn @click="addChat" color="tertiary" dark>
                <v-icon class="mr-2">$chat</v-icon>
                Nuevo chat
              </Btn>

            </Toolbar>
          </v-row>
        </Card>
        <v-row class="flex-column flex-nowrap" v-bind="column(1)" no-gutters>
          <Toolbar v-if="selected" class="rel" border-bottom>

            <div class="fill-width text-center">
              {{ selected.name }}
            </div>

            <v-row class="layer px-2" style="height: 0;" no-gutters>

              <Btn
                v-if="isMobile"
                color="secondary" width="80" class="mt-2"
                @click="set({ selected: [] })"
                text
              >
                <v-icon>$prev</v-icon>
                Atrás
              </Btn>

              <v-spacer/>

              <Btn v-if="!selected.idCampaign" @click="usersDialog = true" color="secondary" class="mt-2" icon>
                <v-icon>$edit</v-icon>
              </Btn>

            </v-row>
          </Toolbar>
          <div class="grow rel">
            <div ref="messages" class="layer autoscroll pa-4" style="background-color: #fafafa;">

              <Messages
                v-if="selected"
                :messages="messages.items"
                :pending="pendingMessages"
              />

              <Infinite
                direction="top"
                error-message="No se han podido cargar los mensajes :("
                :identifier="messages.id"
                @infinite="loadMessages"
              />

            </div>
            <v-fade-transition>
              <Btn v-if="selected && !isStart" @click="goToStart" color="tertiary"
                width="30" height="30" style="right: 20px; bottom: 10px;" absolute fab dark>
                <v-icon style="margin-top: -1px;">$dropdown</v-icon>
              </Btn>
            </v-fade-transition>

          </div>
          <Card v-if="selected" border-top>
            <v-fade-transition>
              <v-row v-if="isParticipant" class="fill-height" align="end" style="padding: 6px;" no-gutters>

                <DField
                  class="grow"
                  v-model="write"
                  v-bind="writer"
                  @click:append="file"
                  @keypress.enter="onEnter"
                />

                <Btn @click="send(write)" color="secondary" class="ml-1" icon>
                  <v-icon class="ml-1">$send</v-icon>
                </Btn>

              </v-row>
              <v-row v-else align="center" justify="center" class="noParticipant" no-gutters>
                No eres participante de este chat. No se pueden enviar mensajes.
              </v-row>
            </v-fade-transition>
          </Card>
        </v-row>
      </v-row>
    </div>

    <v-dialog v-model="usersDialog" transition="scroll-x-transition" fullscreen>
      <Block :block="loading" class="d-flex flex-column flex-nowrap white full">
        <Toolbar color="secondary" dark>

          <span class="title">Opciones del chat</span>

          <v-spacer/>

          <Btn @click="usersDialog = false" color="white" icon>
            <v-icon>$close</v-icon>
          </Btn>

          <v-progress-linear
            v-if="loading"
            color="primary"
            indeterminate
            absolute
            bottom
          />

        </Toolbar>
        <div class="px-4 pt-6">

          <DField
            v-if="chat"
            v-model="chat.name"
            field="text"
            field-style="1"
            label="Título"
            class="mb-6"
            dense
          />

          <Toolbar border-bottom>

            <span class="title">
              Invitados
            </span>

            <v-spacer/>

            <Btn v-if="!showFinder" @click="showFinder = true" color="border" icon>
              <v-icon>
                $plus
              </v-icon>
            </Btn>

            <v-expand-x-transition>
              <DField
                v-if="showFinder"
                style="width:320px;"
                :value="finded"
                :exclude="excludeOnSearch"
                @blur="showFinder = false, finded = null"
                @input="addUser"
                field="autocomplete"
                field-style="1"
                service="Users"
                method="all"
                item-text="text"
                placeholder="Buscar usuarios..."
                prepend-inner-icon="$search"
                autofocus
                dense
              />
            </v-expand-x-transition>

          </Toolbar>
        </div>
        <div class="grow rel">
          <div class="layer autoscroll px-4">
            <v-list v-if="chat" class="participants-list py-0">
              <v-list-item-group v-model="participants" multiple>
                <v-list-item
                  v-for="user in chatParticipants"
                  :key="user.id"
                  :ripple="false"
                  :value="user"
                  color="tertiary"
                  class="px-0"
                >
                  <v-list-item-content class="pa-0">
                    <v-row class="fill-height pa-4" align="center" no-gutters>

                      <Btn :color="user.color" class="chat-user-icon mr-4" width="25" height="25" dark fab>
                        {{ user.letter }}
                      </Btn>

                      <span class="subtitle">
                        {{ user.name }} {{ user.surname }}
                      </span>

                      <v-spacer/>

                      <Btn @click="removeUser( user )" color="border" icon>
                        <v-icon>$delete</v-icon>
                      </Btn>

                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
        <Toolbar border-top>

          <Btn v-if="!creating && !selectedGroup" @click="removeChat()" color="tertiary" text>
            <v-icon class="mr-2">$delete</v-icon>
            Eliminar
          </Btn>

          <v-spacer/>

          <Btn :disabled="!submitable" @click="updateChat" color="primary">
            {{ creating ? 'Crear' : 'Guardar' }}
          </Btn>

        </Toolbar>
      </Block>
    </v-dialog>

  </Page>
</template>

<script>

  /* eslint-disable */

  import Page from '@/components/core/Page.vue';
  import Chats from './Chats';
  import Messages from './Messages';
  import Infinite from './Infinite';

  import { mapState, mapMutations } from "vuex";
  import { upperCaseOn, normalize } from '@/utils';
  import Colors from '@/utils/colors';
  import moment from 'moment';

  moment.locale( navigator.language );
  const sortbyDate = ( a, b ) => a.date - b.date;

  function orderChats( a, b ) {
    //var diff = b.pendingMessages - a.pendingMessages;
    //if ( diff ) return diff;
    return  b.lastUpdate - a.lastUpdate;
  }

  export default {
    components: { Page, Chats, Messages, Infinite },
    data: () => ({
      key: 0,
      index: 0,
      chat: null,
      chats: {
        items: [],
        groups: { page: 1, numPages: 0, items: [] },
        campaign: { page: 1, numPages: 0, items: [] }
      },
      infiniteChatID: 1,
      pendingGroups: 0,
      pendingCampaign: 0,
      messages: {
        id: Date.now(),
        page: 1,
        numPages: 0,
        items: []
      },
      users: [],
      selectedGroup: 0,
      searchActive: false,
      search: '',
      write: '',
      writer: {
        field: 'textarea',
        fieldStyle: 1,
        rows: 1,
        hideDetails: true,
        noResize: true,
        autoGrow: true,
        dense: true
      },
      scrollTop: 0,
      scrollMax: 0,
      isStart: false,
      usersDialog: false,
      participants: [],
      finded: null,
      loading: false,
      showFinder: false,
      creating: false
    }),
    watch: {
      selectedGroup() {
        this.infiniteChatID++;
      },
      search( search ) {
        clearInterval( this.searchInterval );
        this.searchInterval = setTimeout(() => {
          this.infiniteChatID++;
          this.chats = {
            items: [],
            groups: { page: 1, numPages: 0, items: [] },
            campaign: { page: 1, numPages: 0, items: [] }
          };
        }, 500 );
      },
      searchActive( value ) {
        if ( ! value ) this.search = '';
      },
      selected( chat ) {

        if ( ! this.creating ) this.chat = chat;
        if ( ! chat ) this.index = 0;
        else {
          this.index = 1;
          this.selectedGroup = this.chats.groups.items.indexOf( chat ) !== -1 ? 0 : 1;
        }

        this.messages = {
          id: this.messages.id + 1,
          page: 1,
          numPages: 0,
          items: []
        };
      },
      participants( value ) {
        if ( this.chat ) {
          var index = value.findIndex( a => a.id === this.user.id || a.idUser === this.user.id );
          if ( index !== -1 ) value.splice( index, 1 );
        }
      },
      pending( value ) {
        if ( value.length ) this.goToStart();
      },
      usersDialog( value ) {
        this.finded = null;
        this.participants = [];
        if ( ! value ) {
          this.creating = false;
          if ( this.chat ) {
            this.chat.name = this.oldName;
            this.chat.addeds = [];
            this.chat.removeds = [];
          }
        } else if ( this.chat ) {

          this.oldName = this.chat.name;
        }
      }
    },
    computed: {
      ...mapState([ 'core', 'user', 'path' ]),
      ...mapState( 'chat', [ 'pending' ]),
      selected() {
        return (this.core ? ( this.core.selected || [] ): [])[0];
      },
      tabs() {
        return [
          { text: 'Grupos',   bullet: this.pendingGroups   },
          { text: 'Campañas', bullet: this.pendingCampaign }
        ];
      },
      pendingMessages() {
        const { chat } = this;
        if ( chat ) return this.pending.filter( a => a.idChat === chat.id );
        return [];
      },
      idChat() {
        return parseInt( this.$route.query.id );
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
      position() {
        return this.isMobile
          ? { left: `${ -this.index * 100 }%`}
          : null;
      },
      submitable() {
        const { chat } = this;
        return chat && chat.name && this.chatParticipants.length > 1;
      },
      chatParticipants() {
        const { chat } = this;
        if ( chat ) {
          return ( chat.participants || [] )
            .filter( a => chat.removeds.indexOf( a ) === -1 )
            .concat( chat.addeds );
        }
        return [];
      },
      isParticipant() {
        const { chat, $store } = this;
        if ( chat ) {
          let users = ( chat.participants || [] ).filter( a => a.id == $store.state.userID );
          return users.length > 0;
        }
        return false;
      },
      excludeOnSearch() {
        return this.chatParticipants.map( a => ({
          id: a.idUser || a.id,
          idUser: a.idUser || a.id
        }));
      },
      filteredChats() {
        const type  = this.selectedGroup ? 'campaign' : 'groups';
        const chats = this.chats[type];
        return chats.items;
      }
    },
    methods: {
      ...mapMutations([ 'setView' ]),
      ...mapMutations( 'chat', [ 'sendMessage', 'messageSended' ]),
      ...mapMutations( 'app', [ 'setDialog' ]),
      set( state ) {
        this.setView({
          path: this.path,
          state: { sections: {['00']: state }}
        });
      },
      loadChats( state ) {

        const type  = this.selectedGroup ? 'campaign' : 'groups';
        const chats = this.chats[type];

        if ( ! chats.numPages || chats.page <= chats.numPages ) {

          const target = 'Chat/all';
          const params = {
            page: chats.page,
            filters: [{
              field: 'isCampaign',
              booleanValue: !!this.selectedGroup
            }]
          };

          if ( this.search ) {
            params.filters.push({
              field: 'name',
              stringValue: this.search
            });
          }

          this.$store
            .dispatch( 'api', { target, params })
            .then( response => {
              chats.page++;
              chats.numPages = response.numPages;
              this.prepareChats( response.data );
              state.loaded();
            })
            .catch( err => {
              console.log( err );
              state.error();
            });

        } else {
          state.complete();
        }
      },
      loadMessages( state ) {

        const chat = this.selected;
        if ( ! chat ) return state.complete();

        const page = this.messages.page;
        const numPages = this.messages.numPages;

        if ( !numPages || page <= numPages ) {

          const target = 'Chat/messages';
          const params = { page, filters:[{ field: 'chat', intValue: chat.id }]};

          this.$store
            .dispatch( 'api', { target, params })
            .then( response => {
              if ( page === 1 ) this.readChat( chat );
              this.messages.page++;
              this.messages.numPages = response.numPages;
              this.addMessages( response.data.reverse() );
              state.loaded();
            })
            .catch(() => state.error() );

        } else {
          state.complete();
        }
      },
      prepareChats( items ) {

        items = items.filter( a => a.status !== -1 && !this.chats.items.find( b => b.id === a.id ));
        items = this.prepareUsers( items );

        const { chats } = this;
        const groups = items.filter( a => a.idCampaign == null );
        const campaign = items.filter( a => a.idCampaign != null );

        this.pendingGroups += groups.reduce(( n, chat ) => n + chat.pendingMessages || 0, 0 );
        this.pendingCampaign += campaign.reduce(( n, chat ) => n + chat.pendingMessages || 0, 0 );

        chats.groups.items = chats.groups.items.concat( groups );
        chats.campaign.items = chats.campaign.items.concat( campaign );
        chats.items = chats.items.concat( items );
        this.chats = { ...chats };
        this.reorderChats();

        // Subscribe on chat topics
        this.subscribeChats( items );

        return items;
      },
      reorderChats() {
        this.chats.groups.items.sort( orderChats );
        this.chats.campaign.items.sort( orderChats );
      },
      subscribeChats( chats ) {
        const topics = {};
        chats.forEach( chat => {
          topics[`chat-${ chat.id }`] = data => {

          var msg = data.data;

          //Miramos si el mensaje es nuestro... si es así no hacemos nada...
          if ( msg.idUser != this.$store.state.user.id ) {

            this.addMessages([ msg ]);
            chat.lastMessage = msg;
            chat.lastUpdate = msg.date;
            chat.pendingMessages++;
            if ( chat.idCampaign == null ) this.pendingGroups++;
            else this.pendingCampaign++;
            this.reorderChats();
            this.key++;

            if ( this.selected && this.selected.id === chat.id ) {
              this.readChat( chat );
            }
          }
          };
        });
        this.$store.dispatch( 'socket/subscribe', topics );
      },
      prepareUser( user ) {

        // ids users
        user.id = user.idUser || user.id;
        user.idUser = user.idUser || user.id;

        var index = this.users.findIndex( a => a.idUser === user.id );
        if ( index !== -1 ) {
          user.letter = this.users[index].letter;
          user.color = this.users[index].color;
          return user;
        }

        var num, word, name;
        if ( ! ( name = String( user.name || '' ))) user.letter = '?';
        else {

          word = name[0];
          num = 1;

          while( num <= name.length  ) {
            if (( index = this.users.findIndex( a => a.letter === word )) !== -1 ) {

              word = name.slice( 0, ++num );

            } else {

              user.letter = word;
              break;
            }
          }
        }

        user.color  = Colors[ this.users.length % Colors.length ];
        user.letter = user.letter || user.name;
        this.users.push( user );
        return user;
      },
      prepareUsers( chats ) {
        chats.forEach(( chat, num ) => {
          //!chat.idCampaign && console.log( chat );
          //chat.users = chat.participants.slice( 0, 4 );
          chat.participants.forEach( this.prepareUser );
          chat.addeds = [];
          chat.removeds = [];
        });
        return chats;
      },
      column( index ) {
        return this.isMobile
          ? { class: 'layer', style: { left: `${ index * 100 }%` }}
          : { class: `col col-${ index ? 8 : 4 }` };
      },
      send( message, idFile ) {
        message = message.trim();
        const chat = this.selected;
        if ( chat && message ) {

          const params = {
            idChat: chat.id,
            date: Date.now(),
            user: this.user,
            message,
            idFile,
            status: 1
          };

          this.write = '';
          ( params.retry = () => this.updateMessage( params ))();
        }
      },
      updateMessage( params ) {

        if ( params.retries == null ) {
          params.retries = 0;
          this.sendMessage( params );
        }

        if ( params.retries < 5 ) {

          params.loading = true;
          if ( ! params.retries && params.error ) this.pendingID++;

          return this.$store
            .dispatch( 'api', { target: 'Chat/addMessage', params })
            .then( msg => {
              params.loading = false;
              this.messageSended( params );
              if ( this.selected && this.selected.id === params.idChat ) {
                this.addMessages([ msg ]);
                this.selected.lastMessage = { idUser: msg.user.id, message: msg.message, date: msg.date };
                this.selected.pendingMessages++;
                if ( this.selected.idCampaign == null ) this.pendingGroups++;
                else this.pendingCampaign++;
                this.reorderChats();
                this.readChat( this.selected );
              }
            })
            .catch(() => {
              params.retries++;
              setTimeout(() => {
                if ( ! this.updateMessage( params )) {
                  params.retries = 0;
                  params.error = true;
                  params.loading = false;
                  this.pendingID++;
                }
              }, 5000 );
            });
        }
        return false;
      },
      addMessages( data ) {

        var date = moment().startOf('date');
        var time = date.valueOf();
        var day  = date.get('date');
        const today = day;
        const yesterday = moment(date).add( -1, 'days').get('date');
        const items = this.messages.items;
        var group, current;

        // Nos aseguramos que no haya mensajes repetidos
        var total = items.map( a => a.messages ).flat();
        data = data.filter( msg => ! total.find( a => a.id === msg.id ));

        data.forEach( msg  => {

          msg.user = this.users.find( a => a.idUser === msg.idUser );
          current = moment( msg.date ).startOf('date');

          if ( current.valueOf() !== time ) {
            if ( group ) group.messages.sort( sortbyDate );
            date = current;
            time = current.valueOf();
            day  = current.get('date');
          }

          if (( group = items.find( a => a.date === time ))) {

            group.messages.push( msg );

          } else {

            items.push({
              date: time,
              messages: [ msg ],
              display: day === today
                ? 'Hoy' : day === yesterday
                ? 'Ayer'
                : [ day, upperCaseOn( date.format('MMMM'), 0 ), date.get('year')].join(' de ')
            });
          }
        });

        // Sort
        if ( group ) group.messages.sort( sortbyDate );
        items.sort( sortbyDate );
      },
      readChat( chat, msg ) {
        if ( chat && chat.pendingMessages > 0 ) {
          const now = Date.now();
          this.$store
            .dispatch( 'api', { target: 'Chat/read', params: { id: chat.id }})
            .then(() => {
              if ( chat.idCampaign == null ) this.pendingGroups -= chat.pendingMessages;
              else this.pendingCampaign -= chat.pendingMessages;
              chat.pendingMessages = 0;
              chat.lastRead = now;
              this.reorderChats();
              this.key++;
            })
            .catch( console.warn );
        }
      },
      addChat() {
        this.set({ selected: [] });
        this.creating = true;
        this.usersDialog = true;
        this.chat = {
          id: -1,
          idCampaign: null,
          name: '',
          participants: [],
          addeds: [ this.prepareUser( this.user ) ],
          removeds: []
        };
      },
      updateChat() {
        var { chat, submitable } = this;
        if ( submitable ) {

          this.loading = true;
          const { addeds, removeds } = chat;
          const target = 'Chat/set';
          const params = { id: chat.id, idCampaign: null, name: chat.name };

          this.$store
            .dispatch( 'api', { target: 'Chat/set', params })
            .then( response => {

              if ( chat.id === -1 ) {
                chat = response;
                this.prepareChats([ chat ]);
                this.subscribeChats([ chat ]);
              }

              return Promise.all([
                  removeds.map( user => this.updateRemoveUser( chat, user )),
                  addeds.map( user => this.updateAddUser( chat, user )),
              ].flat());
            })
            .then(() => {
              this.oldName = chat.name;
              this.prepareUsers([ chat ]);
              this.chat = chat;
              this.set({ selected: [chat] });
              this.usersDialog = false;
            })
            .catch( err => this.$store.dispatch( 'console/error', err ))
            .finally(() => this.loading = false );
        }
      },
      removeChat( chat ) {
        chat = chat || this.chat;
        if ( chat && chat.id > 0 ) {
          this.setDialog({
            show: true,
            width: 500,
            title: '¿Estas seguro de querer eliminar el chat?',
            text: 'Se perderán todos los mensajes.',
            acceptText: 'Eliminar',
            accept: () => {

              this.loading;
              this.$store
                .dispatch( 'api', { target: 'Chat/remove', params: { id: chat.id }})
                .then(() => {
                  this.usersDialog = false;
                  this.set({ selected: [] });
                  var index = this.chats.groups.items.findIndex( a => a.id === chat.id );
                  if ( index !== -1 ) this.chats.groups.items.splice( index, 1 );
                  this.$store.dispatch( 'socket/unsubscribe', `chat-${ chat.id }` );
                })
                .catch( err => this.$store.dispatch( 'console/error', err ))
                .finally(() => this.loading = false );
            }
          });
        }
      },
      addUser( user ) {
        if ( this.chat && user ) {
          var index = this.chat.removeds.findIndex( a => a.id === user.id );
          if ( index !== -1 ) this.chat.removeds.splice( index, 1 );
          else this.chat.addeds.push( this.prepareUser( user ));
        }
      },
      removeUser( user ) {
        if ( this.chat && user ) {
          var index = this.chat.addeds.findIndex( a => a.id === user.id );
          if ( index !== -1 ) this.chat.addeds.splice( index, 1 );
          else this.chat.removeds.push( user );
        }
      },
      updateRemoveUser( chat, user ) {
        return new Promise(( resolve, reject ) => {

          const target = 'Chat/removeUser';
          const params = { id: user.id };

          this.$store
            .dispatch( 'api', { target, params })
            .then(() => {
              chat.removeds.splice( chat.removeds.indexOf( user ), 1 );
              var index = chat.participants.findIndex( a => a.id === user.id );
              if ( index !== -1 ) chat.participants.splice( index, 1 );
              resolve( user );
            })
            .catch( reject );
        });
      },
      updateAddUser( chat, user ) {
        return new Promise(( resolve, reject ) => {

          const target = 'Chat/addUser';
          const params = { idChat: chat.id, idUser: user.id };

          this.$store
            .dispatch( 'api', { target, params })
            .then(() => {
              chat.addeds.splice( chat.addeds.indexOf( user ), 1 );
              chat.participants.push( user );
              resolve( user );
            })
            .catch( reject );
        });
      },
      goToStart() {
        setTimeout(() => {
          const target = this.$refs.messages;
          this.onScroll({ target });
          target.scrollTop = this.scrollMax;
          this.isStart = this.scrollTop >= this.scrollMax;
        }, 10 );
      },
      onScroll( e ) {
        const el = e.target;
        this.scrollTop = Math.round( el.scrollTop );
        this.scrollMax = el.scrollHeight - el.clientHeight;
        this.isStart = this.scrollTop >= this.scrollMax;
      },
      onEnter( e ) {
        if ( ! e.shiftKey ) {
          e.preventDefault();
          this.send( this.write );
          return false;
        }
      },
      file() {
        console.log('File!');
      },
    },
    created() {
      this.user.idUser = this.user.id;
      this.prepareUser( this.user );
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Chat' );
      this.$refs.messages.addEventListener( 'scroll', this.onScroll );
    },
    beforeDestroy() {
      this.$refs.messages.removeEventListener( 'scroll', this.onScroll );
      this.chats.items.forEach( chat => {
        this.$store.dispatch( 'socket/unsubscribe', `chat-${ chat.id }` );
      });
    }
  };
</script>

<style>
  .chat-user-icon {
    font-size: 10px !important;
    margin: 4px;
  }
  .chat-wrapper {
    transition: left .25s ease;
  }
  .participants-list .v-list-item {
    border: 1px solid var(--v-border-base);
    border-top: 0;
  }
  .chat-list .v-list-item {
    border-bottom: 1px solid var(--v-border-base);
  }
  .noParticipant {
    color: darkgray;
    width: 100%;
    height: 52px;
  }
</style>

// https://vuex.vuejs.org/en/state.html

export default {
  oauth: null,
  userID: -2,
  isAdmin: false,
  isKAM: false,
  isRRHH: false,
  isManager: false,
  isClient: false,
  canceled: '',
  status: '',
  token: localStorage.getItem('accessToken') || '',
  user: null,
  breadcrumbs: [],
  views: {},
  view: null,
  path: '/',
  core: {},
  workersDate: null,
  campaigns: null
}

<template>
  <DForm ref="form" :value="value" v-on="{ ...$listeners, input }">
    <v-row class="pa-2" no-gutters>
      <v-col class="reports-column pa-2" cols="12" :md="columns[0]">

        <DField key="frequency" v-bind="frequency" class="mb-4"/>

        <DField key="dayOfWeek" v-bind="dayOfWeek" class="mb-6"/>

        <DField key="offset" v-bind="offset" class="mb-6"/>

        <DField
          ref="surveysField"
          key="survey"
          v-bind="surveys"
          class="mb-6"
          return-object
        />

        <Btn class="mb-2" color="tertiary" @click="createSurvey" block dark>
          Nuevo cuestionario
        </Btn>

        <v-alert type="warning" v-if="surveyUses > 0" style="font-size:0.9em;">

          Este cuestionario está siendo utilizado por {{ surveyUses }} evaluaciones.
          En caso de modificación se verían modificadas todas estas evaluaciones.
          En caso de que solamente la quiera modificar en esta evaluación por favor duplique el cuestionario.

          <Btn color="white" @click="duplicateSurvey" :loading="duplicating"
          :disabled="!survey" light block style="margin-top:10px;">
            Duplicar cuestionario
          </Btn>

        </v-alert>

      </v-col>
      <v-col class="reports-column pa-2" cols="12" :md="columns[1]">
        <Card v-if="survey" transition="fade" class="pa-2" border>

          <Survey
            :value="survey"
            @input="change( 'survey', $event )"
          />

        </Card>
      </v-col>
    </v-row>
  </DForm>
</template>

<script>

  import Survey from '@/components/forms/Surveys/single';
  import { get } from '@/utils';

  export default {
    components: { Survey },
    props: { value: Object },
    data() {
      return {
        model: this.value || {},
        survey: get( this.value, 'survey' ),
        surveyItems: [],
        duplicating: false,
        modelType: 0
      }
    },
    watch: {
      surveyUses( value ) {
        this.$emit( 'survey-uses', value );
      }
    },
    computed: {
      surveyUses() {
        return this.uses( this.survey );
      },
      columns() {
        return this.survey
          ? [ 4, 8 ]
          : [ 7, 5 ];
      },
      frequency() {
        return {
          field: 'select',
          fieldStyle: 1,
          label: 'Frecuencia',
          dense: true,
          items: [
            { text: 'Una única vez',       value: 0  },
            { text: 'Una vez a la semana', value: 7  },
            { text: 'Una vez al mes',      value: 30 }
          ],
          rules: [
            v => v != null || 'Debes especificar la frecuencia de la acción.'
          ]
        }
      },
      dayOfWeek() {
        return {
          field: 'dayweek',
          label: 'Día de la semana',
          initWeekOnValue: 0
        }
      },
      offset() {
        return {
          field: 'number',
          fieldStyle: 1,
          label: 'Días para la primera evaluación',
          dense: true,
          initValue: 15
        }
      },
      surveys() {
        return {
          field: 'autocomplete',
          fieldStyle: 1,
          label: 'Cuestionario',
          service: 'Surveys',
          method: 'all',
          params: { filters:[{ field: 'type', intValue: this.modelType + 3 }]},
          items: this.surveyItems,
          itemText: 'title',
          itemValue: 'id',
          display: item => `${item.id}.${item.title}`,
          dense: true
        }
      }
    },
    methods: {
      createSurvey() {

        const num = this.surveyItems.length;
        const survey = {
          id: -num-1,
          type: this.modelType + 3,
          title: [ 'Nuevo cuestionario', num ? ( num + 1 ) : '' ].join(' '),
          description: '',
          questions: []
        };

        this.surveyItems.push( survey );
        this.change( 'survey', survey );
      },
      duplicateSurvey() {
        if ( this.survey ) {

          const field = get( this.$refs.surveysField, '$vnode.componentInstance.$children.0' );
          const target = 'Surveys/duplicate';
          const params = { ids: [ this.survey.id ] };

          this.duplicating = true;
          this.$store
            .dispatch( 'api', { target, params })
            .then( survey => {
              field.add( survey[0] );
              this.change( 'survey', survey[0] );
            })
            .catch( err => this.$store.dispatch( 'console/error', err ))
            .finally(() => this.duplicating = false );
        }
      },
      input( value ) {
        this.model = value;
        this.modelType = value.type;
        this.survey = get( value, 'survey' );
        if ( this.$listeners.input ) this.$listeners.input( value );
        this.$emit( 'input', value );
      },
      change( key, value ) {
        if ( this.$refs.form )
          this.$refs.form.set( key, value );
      },
      uses( value ) {
        if ( value && value.evaluations ) {
          return value.evaluations.filter( id => this.model.id != id ).length;
        }
        return 0;
      }
    },
    mounted() {
      this.$emit( 'survey-uses', this.surveyUses );
    }
  }
</script>

<style>
  .reports-column {
    transition: all .135s ease;
  }
</style>

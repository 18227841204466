<template>
  <ListSelector
    :value="value"
    :params="params"
    :headers="headers"
    :title="title"
    :expandible="false"
    service="Actions"
    @input="$emit('input',$event)"
  >
    <template v-slot:item="{ item }">
      {{ item.title }}
    </template>
  </ListSelector>
</template>

<script>

import ListSelector from '@/components/base/ListSelector';
import { CampaignActionType } from '@/utils/constants';
import { Format } from "@/utils/time";

function date(value) {
  return Format(value, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function frequency(value, header, item) {
  const type = frequencies[value];
  if (value === 3) {
    return [
      type + ":",
      "de " + date(item.date),
      "a " + date(item.endDate),
    ].join("</br>");
  }
  return type;
}

const types = [
  { value: CampaignActionType.PICTURES, text: 'Fotografias' },
  { value: CampaignActionType.SURVEY,   text: 'Encuesta cualitativa' },
  { value: CampaignActionType.SALES,    text: 'Encuesta cuantitativa' },
  { value: CampaignActionType.FOLLOW,   text: 'Encuesta' }
];

const frequencies = [
  "Diariamente",
  "Una vez a la semana",
  "Una vez al mes",
  "Puntual (diaria)",
  "Puntual (única respuesta)",
];

export default {
  components: { ListSelector },
  props: {
    value: Array,
    campaignId: Number,
    title: {
      type: String,
      default: 'Centros'
    }
  },
  data: () => ({
    headers: [
      { text: "Titulo", value: "title" },
      { text: "Descripción", value: "description" },
      {
        text: "Tipo",
        value: "type",
        width: 150,
        display: Object.values( types ).reduce(( a, v ) => { a[v.value] = v.text; return a; }, {}),
        filter: {
          type: "select",
          items: types,
        },
      },
      {
        text: "Frecuencia",
        value: "frequency",
        width: 180,
        display: frequency,
        filter: {
          type: "select",
          items: frequencies.map((text, value) => ({ text, value })),
        },
      }
    ]
  }),
  computed: {
    params() {
      return {
        filters: [
          { field: 'campaign', intValue: this.campaignId }
        ]
      }
    },
  }
}
</script>

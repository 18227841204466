<template>
  <span class="change-range-date" :class="{ small: sm, open }">

    <Btn v-if="sm" :color="open ? 'primary' : 'secondary'" @click="open = !open" text>
      <v-icon style="font-size:20px;" v-text="'mdi-calendar-month'"/>
    </Btn>

    <v-slide-y-transition>
      <v-sheet
        v-show="!sm || open"
        class="change-range-date--wrapper"
        color="white"
        :elevation="sm ? 2 : 0"
      >

        <DField
          v-model="date"
          field="rangedate"
          field-style="1"
          class="mr-2"
          style="max-width:220px;"
          :clearable="false"
          :min="min"
          :max="max"
          dense
        />

      </v-sheet>
    </v-slide-y-transition>
  </span>
</template>

<script>

  import moment from 'moment';

  export default {
    props: {
      value: null,
      min: [ Number, String ],
      max: {
        type: [ Number, String ],
        default: () => moment().endOf('date').valueOf()
      }
    },
    data() {
      const today = moment().endOf('date');
      const monday = today.clone().day(1).valueOf();
      return {
        today: today.valueOf(),
        monday,
        date: this.value ? this.value : [ monday, today.valueOf() ],
        open: false,
      }
    },
    watch: {
      value( value ) {
        this.date = value ? value : [ this.monday, this.today ];
      },
      date( value ) {
        this.$emit( 'input', value );
      },
      sm( value ) {
        if ( ! value ) this.open = false;
      }
    },
    computed: {
      sm() {
        return this.$vuetify.breakpoint.xs;
      }
    }
  };
</script>

<style>
  .change-range-date {
    position: relative;
  }
  .change-range-date--wrapper {
    display: flex;
    align-items: center;
    width: 240px;
  }
  .change-range-date.small .change-range-date--wrapper {
    top: 100%;
    right: 0;
    position: absolute;
    padding: 8px;
    z-index: 99999;
  }
</style>

<template>
  <Table
    service="Places"
    :messages="messages"
    :headers="headers"
    :mypopup="mypopup"
    show-actions="edit,new,delete,export,import"
  >
    <template v-slot:form="{ value, listeners }">

      <Place class="pa-4" :value="value" v-on="listeners"/>

    </template>
    <template v-slot:append-outer>
      <v-dialog v-model="geolocation.open" max-width="640">
        <Card>
          <Toolbar color="secondary" dark>
            <v-spacer/>
            <Btn color="white" width="20" height="20" fab light @click="geolocation.open = false">
              <v-icon small>$close</v-icon>
            </Btn>
          </Toolbar>
          <div class="pa-4">
            <DField
              :value="geolocation.location"
              field="gmap"
              height="430"
              show-position
            />
          </div>
        </Card>
      </v-dialog>
    </template>

    <template v-slot:item.location="{ item }">
      <Btn @click="showGeolocation( item )" :color="hasLocation( item ) ? 'primary' : 'border'" icon>
        <v-icon style="width: 30px; height: 30px;">$place</v-icon>
      </Btn>
    </template>

  </Table>
</template>

<script>

  import Table from '@/components/core/Table.vue';
  import Place from '@/components/forms/Places/single.vue';

  export default {
    components: { Table, Place },
    data() {
      return {
        messages: { item: 'Dirección/Direcciones', fem: true },
        headers: [
          { text: 'Nombre', value: 'name' },
          {
            text: 'Cadena', value: 'channel', display: 'name',
            filter: {
              type: 'select',
              attrs: {
                service: 'Channels',
                itemText: 'name',
                itemValue: 'id',
                all: true
              }
            }
          },
          { text: 'Área', value: 'area' },
          { text: 'Dirección', value: 'street' },
          { text: 'Ciudad', value: 'city', width: 150 },
          { text: 'Provincia', value: 'province', width: 150 },
          { text: 'CP', value: 'postalCode', width: 75 },
          { text: 'Telefono', value: 'phone', width: 100 },
          { text: 'Geo', value: 'location', width: 120, sorteable: false, filterable: false }
        ],
        mypopup: {
          width: 1024,
          height: 700
        },
        geolocation: {
          open: false
        }
      }
    },
    methods: {
      hasLocation( item ) {
        return item.latitude != null && item.longitude != null;
      },
      showGeolocation( item ) {
        if ( this.hasLocation( item )) {
          this.geolocation = {
            location: { lat: item.latitude, lng: item.longitude },
            open: true
          };
        }
      }
    },
    mounted() {
      this.$store.commit( 'setTitle', 'Centros' );
    }
  }
</script>

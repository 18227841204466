import vuetify from './vuetify';
import './vue2-google-maps';
//import './vuesax';
//import './vue-logger';
//import './vee-validate';
//import './vue-moment';
//import './vue-mq';

export default {
  vuetify
};

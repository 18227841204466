<template>
  <div v-if="question" :class="`ma-${ isInput ? 1 : 2 }`">

    <div class="mb-3">
      <p class="subtitle-1 mb-1">{{ questionTitle( question ) }}</p>
      <p v-if="question.comments" class="caption grey--text">{{ question.comments }}</p>
    </div>

    <div v-if="isInput" class="col-12 pa-0 pr-md-4">
      <DField
        :value="model"
        v-bind="field"
        :readonly="readonly"
        @blur="onBlur"
        field-style="1"
        dense
      />
    </div>

    <v-radio-group
      v-else
      class="mt-0"
      v-model="model"
      :multiple="isMultiple"
      :readonly="readonly"
      :row="row"
      hide-details
      @mouseup="toggle"
    >
      <v-radio
        v-for="r in replies"
        :key="r.id"
        :value="r.id"
        :label="r.reply"
        :ripple="false"
      />
    </v-radio-group>

  </div>
</template>

<script>

  import { toArray } from '@/utils';

  export default {
    props: {
      value: [ String, Number, Array ],
      question: Object,
      readonly: Boolean,
      row: Boolean
    },
    data: function() {
      return {
        model: this.value
      }
    },
    watch: {
      value( value ) {
        this.model = value;
      },
      model( value ) {
        this.$emit( 'input', value );
      }
    },
    computed: {
      type() {
        return this.question ? this.question.type : '';
      },
      field() {
        if ( this.type === 0 ) return { field: 'textarea', rows: 2 };
        else if ( this.type >= 3 ) return { field: 'number' };
        else return { field: 'text' };
      },
      isInput() {
        return this.type !== 1 && this.type !== 2;
      },
      isMultiple() {
        return this.type === 2;
      },
      replies() {
        return !this.question ? [] : ( this.question.replies || [] )
          .filter( r => r.status === 1 );
      }
    },
    methods: {
      questionTitle( q ) {
        return [
          q.question,
          q.optional ? '(Opcional)' : ''
        ].join(' ');
      },
      onBlur( event ) {
        if ( ! this.readonly ) {
          this.model = this.type >= 3
            ? parseFloat( event.target.value )
            : event.target.value;
        }
      },
      toggle( event ) {

        if ( this.readonly || ! Array.isArray( this.model )) return;

        // Get radio clicked

        var radio, index;
        for ( var i = 0, el; i < event.path.length; i++ ) {
          el = event.path[i];
          if ( el.classList && el.classList.contains('v-radio'))
            radio = el;
        }

        // Get index and remove value if is selected

        if ( radio && radio.parentNode ) {
          if (( index = toArray( radio.parentNode.children ).findIndex( el => el === radio )) !== -1 ) {

            const reply = this.replies[index];
            if ( reply ) {
              if (( index = this.model.indexOf( reply.id )) !== -1 ) {
                setTimeout(() => {
                  this.model = this.model.slice();
                  this.model.splice( index, 1 );
                }, 60 );
              }
            }
          }
        }
      }
    }
  }
</script>

<template>
  <component
    v-if="exists( field )"
    class="d-field"
    :is="`dfield${field}`"
    :loading="isLoading"
    :value="model"
    v-bind="$attrs"
    v-on="on"
  >
    <slot v-for="( s, name ) in $slots" :name="name"/>
    <template v-for="( s, name ) in $scopedSlots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </component>
  <div v-else class="d-field">
    <slot v-bind="{ value: model, loading: isLoading, changed, on }"/>
  </div>
</template>

<script>

  import components from './fields';
  import { Any, copy } from '@/utils';

  export default {
    components,
    props: {
      field: String,
      value: Any,
      loading: Boolean,
      validateOnBlur: Boolean
    },
    data() {
      return {
        model: undefined,
        lazyLoading: false,
        changed: false,
        on: {
          ...this.$listeners,
          input: v => {
            this.setValue( v );
            this.$emit( 'input', v );
          },
          blur:  e => this.$emit( 'blur', e )
        }
      }
    },
    computed: {
      isLoading() {
        return this.loading || this.lazyLoading;
      }
    },
    watch: {
      value( value ) {
        this.setValue( value );
      }
    },
    methods: {
      exists( field ) {
        return !!components[`dfield${ field }`];
      },
      compute( value ) {
        const comp = components[`dfield${ this.field }`];
        const values = [ undefined, null ].concat(( comp ? [ comp.options.nullValues ] : []).flat());
        if ( values.includes( value )) return undefined;
        return value;
      },
      setValue( newValue, initialize ) {
        newValue = this.compute( newValue );
        if ( initialize ) this.initial = copy( newValue );
        this.model = newValue;
        this.changed = !this.compare( this.initial, newValue );
      },
      toString( value ) {
        try { return JSON.stringify( value ); }
        catch(e) { return ''; }
      },
      compare( a, b ) {
        try { return JSON.stringify(a) === JSON.stringify(b) }
        catch(e) { return false; }
      },
      reset() {
        this.setValue( this.initial );
      }
    },
    inject: {
      dform: {
        default: {
          register: () => {},
          unregister: () => {}
        }
      }
    },
    created() {
      if ( this.value != null ) this.setValue( this.value, true );
      this.dform && this.dform.register( this );
    },
    beforeDestroy() {
      this.dform && this.dform.unregister(this);
    }
  }
</script>

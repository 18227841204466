<template>
  <Table
    service="Files"
    :initialize="initialize"
    :sanitize="sanitize"
    :headers="headers"
    :messages="messages"
    :mypopup="mypopup"
    show-actions
  >

    <template v-slot:item.file.url="{ value }">
      <FileIcon :value="value" small/>
    </template>

    <template v-slot:form="{ value, listeners }">
      <FileForm class="pa-4" :value="value" v-on="listeners" />
    </template>

  </Table>
</template>

<script>

import Table from '@/components/core/Table.vue';
import FileForm from '@/components/forms/File/single.vue';
import { Format } from '@/utils/time';
import { mapState } from 'vuex';

function date( value ) {
  return Format( value, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  })
}

export default {
  components: { Table, FileForm },
  data() {
    return {
      messages: 'Archivo/Archivos',
      headers: [
        { text: 'Titulo', value: 'alias' },
        { text: 'Fecha', value: 'date', display: date, filter: { type: 'date' }},
        { text: 'Archivo', value: 'file.url', width: 180, filterable: false }
      ],
      mypopup: {
        height: 275,
        title: n => ( n ? 'Nuevo fichero' : 'Editar fichero' )
      }
    };
  },
  computed: mapState([ 'breadcrumbs' ]),
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    }
  },
  methods: {
    initialize( params ) {
      return this.$store.dispatch( 'page', {
        ...params,
        service: 'Files',
        filters: [{ field: 'campaign', intValue: this.$route.params.id }]
      });
    },
    sanitize( model ) {
      return {
        ...model,
        id: model.id || -1,
        idCampaign: this.$route.params.id,
        idFile: model.file ? model.file.id : null
      };
    },
    setTitle() {
      const id = parseInt( this.$route.params.id );
      const title = [ 'Campañas', '', 'Archivos' ];
      this.$store.commit( 'setTitle', title );

      if ( this.breadcrumbs[1].text === '...' && id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

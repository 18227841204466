import { errorMessage } from '@/utils/constants';

export default {
  alert({ state, commit }, alert ) {
    return new Promise( resolve => {

      const index = state.alerts.length;
      const { error } = alert;
      console.log([ error ]);

      if ( error ) {
        if ( error instanceof Error ) {
          alert.message = errorMessage( error.body ? error.body.error : error );
        } else if ( typeof error === 'string' ) {
          alert.message = error;
        } else {
          alert.message = errorMessage( error.data ? error.data.error : error.error );
        }
      }

      commit( 'add', ( alert = {
        type: 'info',
        temporary: true,
        ...alert,
        index
      }));

      if ( ! alert.temporary ) resolve( alert );
      else setTimeout(() => {
        commit( 'remove', index );
        resolve( alert );
      }, 5000 );
    });
  },
  success({ dispatch }, message ) {
    if ( !message ) return;
    dispatch( 'alert', {
      type: 'success',
      dismissible: true,
      message
    });
  },
  info({ dispatch }, message ) {
    if ( !message ) return;
    dispatch( 'alert', {
      type: 'info',
      dismissible: true,
      message
    });
  },
  warn({ dispatch }, message ) {
    if ( !message ) return;
    dispatch( 'alert', {
      type: 'warning',
      dismissible: true,
      message
    });
  },
  error({ dispatch }, error ) {

    if ( !error ) return;

    dispatch( 'alert', {
      type: 'error',
      dismissible: true,
      error
    });
  }
};

<template>
  <DForm>
    <DSteps
      v-bind="$attrs"
      :add="add"
      title="Personas de contacto"
      @input="$emit( 'input', $event )"
      edit-button
      headline
    >
      <template v-slot:header="{ item, open, index, active, input }">
        <DField
          v-bind="name"
          :value="item.name"
          @input="input( index, 'name', $event )"
          @click="open( index )"
        />
      </template>
      <template v-slot="{ item, index, input }">

        <DField
          class="mb-4"
          v-bind="rol"
          :value="item.rol"
          @input="input( index, 'rol', $event )"
        />

        <DField
          class="mb-4"
          v-bind="phone"
          :value="item.phone"
          @input="input( index, 'phone', $event )"
        />

        <DField
          class="mb-4"
          v-bind="directPhone"
          :value="item.directPhone"
          @input="input( index, 'directPhone', $event )"
        />

        <DField
          v-bind="email"
          :value="item.email"
          @input="input( index, 'email', $event )"
        />

      </template>
    </DSteps>
  </DForm>
</template>

<script>

  const fields = { fieldStyle: 1, dense: true };

  export default {
    methods: {
      add( a, model ) {
        model.push({
          name: '',
          rol: '',
          phone: '',
          directPhone: '',
          email: ''
        });
        return model;
      }
    },
    computed: {
      name() {
        return {
          ...fields,
          field: 'text',
          label: 'Nombre',
          rules: [
            v => !!v || 'Debes ingresar un nombre.'
          ]
        }
      },
      rol() {
        return {
          ...fields,
          field: 'text',
          label: 'Rol'
        }
      },
      phone() {
        return {
          ...fields,
          field: 'text',
          label: 'Teléfono'
        }
      },
      directPhone() {
        return {
          ...fields,
          field: 'text',
          label: 'Móvil'
        }
      },
      email() {
        return {
          ...fields,
          field: 'text',
          label: 'Email'
        }
      }
    }
  }
</script>

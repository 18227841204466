<template>
  <DField
    v-bind="$attrs"
    v-on="$listeners"
    :key="$vnode.key"
    :mypopup="mypopup"
    service="Channels"
    method="all"
    field="autocomplete"
    item-text="name"
    item-value="id"
  >
    <template v-slot:form="{ value, listeners }">

      <Channel :value="value" v-on="listeners"/>

    </template>
  </DField>
</template>

<script>

  import Channel from './single';

  export default {
    components: { Channel },
    computed: {
      mypopup() {
        return {
          height: 150,
          title: n => n ? 'Nuevo canal' : 'Editar canal',
          initial: name => ({ name }),
        }
      }
    }
  }
</script>

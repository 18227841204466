import APIService from './class';

class Tasks extends APIService {
  constructor() {
    super( '/task' );
  }
  status( token, params, options ) {
    return this.action( 'post', '/status', params, options, token );
  }

  validate( token, params, options ) {
    return this.action( 'post', '/validate', params, options, token );
  }
}

export default new Tasks();

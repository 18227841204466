<template>
  <v-app id="app" class="mask">

    <div id="alerts">
      <v-alert :key="`alert-${i}`" v-for="( alert, i ) in alerts" v-bind="alert">
        {{ alert.message }}
      </v-alert>
    </div>

    <router-view v-if="$store.state.userID > -2"/>

    <v-dialog v-model="dialog.show" :max-width="dialog.width">
      <Card border>
        <slot name="dialog">

          <v-card-title
            class="headline"
            style="word-break: anywhere;"
            v-html="dialog.title"
          />

          <v-card-text v-html="dialog.text"/>

          <v-card-actions>

            <v-spacer></v-spacer>

            <Btn color="transparent" @click="cancel">
              {{ dialog.cancelText }}
            </Btn>

            <Btn color="primary" @click="accept">
              {{ dialog.acceptText }}
            </Btn>

          </v-card-actions>
        </slot>
      </Card>
    </v-dialog>

  </v-app>
</template>

<script>

  import { mapState, mapMutations } from 'vuex';

  export default {
     data() {
      return {
        received_messages: [],
        send_message: null,
        connected: false
      };
    },
    watch: {
      $route( route ) {
        this.setPath( route.path );
      }
    },
    computed: {
      ...mapState( 'console', [ 'alerts' ]),
      ...mapState( 'app', [ 'dialog' ])
    },
    methods: {
      ...mapMutations([ 'setPath' ]),
      ...mapMutations( 'app', [ 'setDialog', 'toggleDialog' ]),
      cancel( event ) {
        const cancel = this.dialog.cancel;
        if ( typeof cancel === 'function' ) cancel( event );
        this.toggleDialog();
      },
      accept( event ) {
        const accept = this.dialog.accept;
        if ( typeof accept === 'function' ) accept( event );
        this.toggleDialog();
      }
    },
    mounted() {
      this.$store
        .dispatch( 'refreshToken' )
        .catch( err => this.$store.dispatch( 'console/error', err ));
    }
  }
</script>

<style>
  /*html {
    font-size: 14px !important;
  }*/
  #app {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    color: var(--v-secondary-darken1);
  }
  #alerts {
    position: absolute;
    bottom: 52px;
    right: 16px;
    z-index: 20;
  }

  .body-grupo-ot{
    font-size: 13px !important;
  }

  .v-application .dtable-cell a:not(.v-btn) {
    color: rgb(162 30 30) !important;
  }

  .fill-width {
    width: 100%;
  }
  .fill-height {
    height: 100%;
  }
  .full, .layer {
    width: 100%;
    height: 100%;
  }
  .rel {
    position: relative;
  }
  .abs, .layer {
    position: absolute;
  }
  .layer {
    top: 0; left: 0;
  }
  .mask {
    overflow: hidden;
  }
  .scroll {
    overflow: scroll;
  }
  .autoscroll {
    overflow: auto;
  }
  .transparent {
    background: transparent;
  }
  .single-line {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  .transparent.theme--light.v-btn--active:hover::before, .transparent.theme--light.v-btn--active::before {
    background: transparent;
  }
  .v-simple-checkbox .v-icon, .v-input--selection-controls__input .v-icon {
    font-size: 20px !important;
  }
  .accordion .v-expansion-panel::before {
    display: none;
  }
  .accordion .v-expansion-panel-header,
  .accordion .v-expansion-panel-content__wrap {
    padding-left: 16px;
    padding-right: 16px;
  }
  .accordion .v-list-item {
    padding-left: 0;
    padding-right: 0;
  }
  .d-field .label {
    text-align: right;
    font-weight: 500;
    color: var(--v-secondary-base);
    margin-top: 2px;
  }
  .d-field .small {
    font-size: 1em;
    margin-top: 0;
  }
  .d-field .v-input__control {
    position: relative;
  }
  .d-field.v-input--selection-controls {
    margin-top: 0 !important;
  }
  .d-field .v-input__slot {
    margin-bottom: 0 !important;
  }
  .d-field .v-text-field__details {
    position: absolute;
    width: 100%;
    top: 110%;
  }
  .v-menu__content {
    border: 1px solid var(--v-secondary-base);
  }
  .v-select-list > .v-list {
    padding: 0 !important;
  }
  .v-text-field input {
    width: 0 !important;
  }
  .font-weight-normal {
    font-weight: normal !important;
  }
  .filter-input-search {
    margin-top: 0 !important;
  }
  .filter-input-search .v-input__slot {
    padding: 0 6px !important;
  }
  .filter-input-search > .v-input__control > .v-input__slot {
    min-height: 32px !important;
  }
  .filter-input-search .v-icon {
    font-size: 1.5rem !important;
  }
  .w-50 {
    width: 50%;
  }
  .clickable:hover {
    cursor: pointer !important;
    text-decoration: underline;
  }
</style>

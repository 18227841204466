export default [
  '#F44336', // red
  '#80CBC4', // teal lighten-3
  '#FFCA28', // amber lighten-1
  '#42A5F5', // blue lighten-1
  '#5C6BC0', // indigo lighten-1
  '#AB47BC', // purple lighten-1
  '#78909C', // blue-grey lighten-1
  '#F06292', // pink lighten-2
  '#66BB6A', // green lighten-1
  '#a3dc32',
  '#141e06',
  '#eee108',
  '#756f2c',
  '#427ed4',
  '#f6ab3e',
  '#f070be',
  '#aec7f1',
  '#2d3064',
  '#54a46d',
  '#36dec6',
  '#de7764',
  '#f187e0'
];

<template>
  <v-list class="d-menu pa-0" :class="{ collapse }" color="transparent">
    <v-list-item-group color="tertiary">
      <template v-for="( item, i ) in items">

        <v-menu
          v-if="item.children && item.children.length"
          transition="slide-x-transition"
          @input="active = $event"
          :key="i"
          :open-on-click="false"
          open-on-hover
          offset-x
        >

          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" :ripple="false">

              <v-icon color="white" class="ml-n1 mr-3" size="28" v-text="item.icon"/>

              <v-list-item-content class="white--text">
                <v-list-item-title v-text="item.text || item.title"/>
              </v-list-item-content>

              <v-chip class="menu-bullet" v-if="item.bullet" color="primary" dark>
                {{ item.bullet }}
              </v-chip>

              <v-icon color="white">$next</v-icon>

            </v-list-item>
          </template>

          <Card color="tertiary">
            <v-list color="transparent" class="submenu pa-0">
              <v-list-item-group style="color: var(--v-tertiary-lighten3);">
                <v-list-item v-for="( subitem, e ) in item.children" :key="e" v-bind="subitem.link" :ripple="false">

                  <v-icon color="white" class="ml-n1 mr-3" size="28" v-text="subitem.icon"/>

                  <v-list-item-content class="white--text">
                    <v-list-item-title v-text="subitem.text || subitem.title"/>
                  </v-list-item-content>

                  <v-chip class="menu-bullet" v-if="subitem.bullet">
                    {{ subitem.bullet }}
                  </v-chip>

                </v-list-item>
              </v-list-item-group>
            </v-list>
          </Card>

        </v-menu>

        <v-list-item v-else :key="i" v-bind="item.link" :ripple="false">

          <v-icon color="white" class="ml-n1 mr-3" size="28" v-text="item.icon"/>

          <v-list-item-content class="white--text">
            <v-list-item-title v-text="item.text || item.title"/>
          </v-list-item-content>

          <v-chip class="menu-bullet" v-if="item.bullet" dark>
            {{ item.bullet }}
          </v-chip>

        </v-list-item>

      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    props: {
      items: Array,
      collapse: Boolean
    },
    data() {
      return {
        active: false
      }
    },
    watch: {
      active( value ) {
        this.$emit( 'submenu', value );
      }
    }
  };
</script>

<style>
  .d-menu .v-list-item::before {
    background-color: var(--v-tertiary-base);
    z-index: -1;
  }
  .submenu .v-list-item::before {
    background-color: var(--v-tertiary-lighten3);
    z-index: -1;
  }
  .d-menu .v-item--active::before,
  .submenu .v-item--active::before {
    opacity: 1 !important;
  }
  .d-menu .v-list-item:hover::before,
  .d-menu .v-list-item:focus::before,
  .submenu .v-list-item:hover::before,
  .submenu .v-list-item:focus::before {
    opacity: .5 !important;
  }
  .v-menu__content {
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .d-menu .menu-bullet {
    position: absolute;
    right: 8px;
    padding: 4px;
    height: auto !important;
    min-width: 20px;
    font-size: 10px !important;
    border-radius: 10px !important;
    line-height: 1.3;
    font-weight: bold;
    background-color: var(--v-primary-base) !important;
    color: white !important;
    justify-content: center;
    cursor: pointer;
    transition: all .125s ease;
  }
  .d-menu.collapse .menu-bullet {
    font-size: 0px !important;
    right: 161px;
    top: 9px;
    min-width: auto;
    border: 2px solid var(--v-bgdark-base);
  }
  .d-menu.collapse .v-list-item--active .menu-bullet {
    border: 2px solid var(--v-tertiary-base);
  }
  .d-menu .v-list-item .v-list-item__title {
    /*font-weight: 300;*/
  }
</style>

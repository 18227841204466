<template>
  <Single
    service="Campaigns"
    subservice="Reports"
    :initialize="initialize"
    :validate="validate"
    :update="update"
    :auto-id="false"
    :show-actions="!isClient && 'save'"
    @initialized="setTitle"
    disable-campaign-selector
  >
    <template v-slot="{ value, listeners }">

      <Action
        :value="value"
        v-on="listeners"
        @survey-uses="uses = $event"
      />

    </template>
  </Single>
</template>

<script>

  import Single from '@/components/core/Single.vue';
  import Action from '@/components/forms/Actions/single';
  import { mapState, mapMutations } from 'vuex';
  import { CampaignActionType, SurveyQuestionType } from '@/utils/constants';
  import { get } from '@/utils';

  const hasReplies = type => [
    SurveyQuestionType.CLOSED,
    SurveyQuestionType.MULTIPLE
  ].includes( type );

  export default {
    components: { Single, Action },
    data() {
      return {
        uses: 0
      }
    },
    computed: {
      ...mapState([ 'isClient', 'breadcrumbs' ]),
      campaignId() {
        return parseInt( this.$route.params.id );
      }
    },
    watch: {
      $route() {
        this.$nextTick( this.setBreadcrumbs );
      }
    },
    methods: {
      ...mapMutations( 'app', [ 'setDialog' ]),
      setTitle( res, campaign ) {
        const title = [ 'Campañas', campaign, 'Reportes', res && res.title ];
        this.$store.commit( 'setTitle', title );
      },
      validate( form ) {

        if ( ! form.value ) return false;
        var model = form.value;

        if ( typeof model.type === 'undefined' || typeof model.title === 'undefined' || typeof model.frequency === 'undefined' )
          return false;

        if ( ! model.title.length )
          return false;

        switch ( model.frequency ) {
          case 3: case 4:
             if ( typeof model.date === 'undefined' || typeof model.endDate === 'undefined') return false;
        }

        switch ( model.type ) {
          case CampaignActionType.PICTURES:

            //Miramos si ha introducido el número de fotografías a reportar
            return model.picture && model.picture.numPictures;

          case CampaignActionType.SURVEY:
          case CampaignActionType.SALES:

            //Miramos si tiene la encuesta seleccionada...
            if ( ! model.survey ) return false;
            break;

          case CampaignActionType.FOLLOW:

            //Miramos si tiene la encuesta seleccionada...
            if ( ! model.survey ) return false;

            // Miramos si ha añadido alguna categoria o si posibilita añadirlas
            if ( ! model.addCategories && ! model.categories ) return false;
            break;
        }

        return true;
      },
      initialize( params ) {
        return new Promise(( resolve, reject ) => {
          this.$store.dispatch( 'single', { ...params, subservice: null })
            .then( response => {

              const data   = response.actions ? response.actions : null;
              const index  = parseInt( this.$route.params.index );
              const single = data ? data.find( a => a.id === index ) : null;
              this.compute( single );

              resolve( single );
            })
            .catch( reject );
        });
      },
      sanitize( m ) {
        return {
          id: m.id || -1,
          title: m.title,
          description: m.description,
          type: m.type,
          frequency: m.frequency,
          numPictures: !m.type ? get( m, 'picture.numPictures' ) : null,
          date: m.date,
          endDate: m.endDate,
          idSurvey: m.type ? get( m, 'survey.survey.id' ) || null : null,
          idCampaign: parseInt( this.$route.params.id ),
          category: m.category,
          categories: m.categories,
          addCategories: m.addCategories,
          exportable: m.exportable,
          status: 1
        };
      },
      sanitizeSurvey( survey ) {

        if ( ! survey ) return;
        survey = { ...survey };

        survey.questions = survey.questions
          .slice()
          .filter( q => ( q.question || '' ).trim() && q.type >= 0 )
          .map(( q, i ) => {

            q = { ...q };

            q.position = i;
            if (typeof(q.idParent) === "number") {
              q.parentId = q.idParent;
            }
            else {
              q.parentId = null;
            }
            q.parentReplyId = q.idParentReply;
            delete q.idParent, q.idParentReply;

            if ( hasReplies( q.type )) {
              q.replies = q.replies
                .slice()
                .filter( r => ( r.reply || '' ).trim())
                .map(( r, e ) => {
                  r = { ...r };
                  r.position = e;
                  return r;
                });
            } else {
              q.replies = [];
            }
            return q;
          });

        return survey;
      },
      updateSurvey( params ) {
        return new Promise(( resolve, reject ) => {

          const isSurveyType = [
            CampaignActionType.SURVEY,
            CampaignActionType.SALES,
            CampaignActionType.FOLLOW
          ].indexOf( params.type ) !== -1;

          const survey = this.sanitizeSurvey( params.survey && params.survey.survey );

          if ( ! isSurveyType || ! survey )
            return resolve( params );

          this.$store.dispatch( 'api', {
            target: 'Surveys/set',
            params: survey
          })
          .then( resolve )
          .catch( reject );
        });
      },
      save( params ) {
        return new Promise(( resolve, reject ) => {
          this
            .updateSurvey( params )
            .then( survey => {
              params.survey = params.survey || {};
              params.survey.survey = survey;
              return this.$store.dispatch( 'api', {
                target: 'Actions/set',
                params: this.sanitize( params )
              });
            })
            .then( response => resolve( this.compute( response )))
            .catch( reject );
        });
      },
      update( params ) {
        if ( this.uses <= 0 ) return this.save( params );
        return new Promise(( resolve, reject ) => {
          this.setDialog({
            show: true,
            width: 400,
            accept: () => this.save( params ).then( resolve ).catch( reject ),
            cancel: () => reject('Guardado cancelado'),
            acceptText: 'Guardar',
            title: '¿Confirma que desea guardar los cambios?',
            text: `Este cuestionario está siendo utilizado por ${ this.uses } acciones. `+
                  'En caso de modificación se verían modificadas todas estas acciones.'
          });
        });
      },
      compute( single ) {
        const survey = get( single, 'survey.survey' );
        if ( survey ) {
          survey.questions = survey.questions
            .filter( a => a.status !== -1 )
            .sort(( a, b ) => a.position - b.position );
        }
        return single;
      },
      setBreadcrumbs() {

        this.setTitle();
        const id = this.campaignId;

        if ( this.breadcrumbs[1].text === '...' && id ) {
          this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
            this.setTitle(null,res.title);
          });
        }
      }
    },
    mounted() {
      this.setBreadcrumbs();
    }
  }
</script>

<template>
  <Table
    ref="table"
    service="Actions"
    :initialize="initialize"
    :remove="remove"
    :update="update"
    :sanitize="sanitize"
    :headers="headers"
    :messages="messages"
    :draggend="onMove"
    :move="onMove"
    :silent="silent"
    :show-actions="actions"
    :draggable="draggable"
  />
</template>

<script>
import Table from "@/components/core/Table.vue";
import { CampaignActionType } from '@/utils/constants';
import { Format } from "@/utils/time";
import { mapState } from 'vuex';

function date(value) {
  return Format(value, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

function frequency(value, header, item) {
  const type = frequencies[value];
  if (value === 3) {
    return [
      type + ":",
      "de " + date(item.date),
      "a " + date(item.endDate),
    ].join("</br>");
  }
  return type;
}

const types = [
  { value: CampaignActionType.PICTURES, text: 'Fotografias' },
  { value: CampaignActionType.SURVEY,   text: 'Encuesta cualitativa' },
  { value: CampaignActionType.SALES,    text: 'Encuesta cuantitativa' },
  { value: CampaignActionType.FOLLOW,   text: 'Encuesta' }
];

const frequencies = [
  "Diariamente",
  "Una vez a la semana",
  "Una vez al mes",
  "Puntual (diaria)",
  "Puntual (única respuesta)",
];

export default {
  components: { Table },
  data() {
    return {
      silent: false,
      messages: {
        item: "Acción/Acciones",
        fem: true,
      },
      headers: [
        { text: "Titulo", value: "title" },
        { text: "Descripción", value: "description" },
        {
          text: "Tipo",
          value: "type",
          width: 150,
          display: Object.values( types ).reduce(( a, v ) => { a[v.value] = v.text; return a; }, {}),
          filter: {
            type: "select",
            items: types,
          },
        },
        {
          text: "Frecuencia",
          value: "frequency",
          width: 180,
          display: frequency,
          filter: {
            type: "select",
            items: frequencies.map((text, value) => ({ text, value })),
          },
        },
      ],
    };
  },
  computed: {...mapState([ 'breadcrumbs' ]),
  actions() {
      if ( this.$store && this.$store.state.isClient ) {
        return "view";
      }
      return "edit, delete, new, up, down";
    },
  draggable() {
      if ( this.$store && this.$store.state.isClient ) {
        return false;
      }
      return true;
  }
  },
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    }
  },
  methods: {
    initialize( params ) {

      if ( this.$route.params.id ) {
        params.filters = params.filters.filter((a) => a.field != "campaign");
        params.filters.push({
          field: "campaign",
          intValue: this.$route.params.id,
        });
      }

      return this.$store.dispatch( "page", params );
    },
    remove( items, params ) {
      return this.$store.dispatch( "api", {
        target: "Actions/removeAll",
        params,
      });
    },
    update( params ) {
      return this.$store.dispatch( "api", { target: "Actions/set", params });
    },
    sanitize( model ) {
      return {
        ...model,
        id: model.id || -1,
        numPictures: !model.type ? model.picture.numPictures : null,
        idSurvey: model.type > 0 ? model.survey.id : null,
        idCampaign: parseInt(this.$route.params.id),
      };
    },
    onMove( item, newIndex, oldIndex, items ) {

      this.silent = true;
      const positions = items.map( i => ({ id: i.id, position: i.position }));
      let start = new Date().getTime();

      this.$store.dispatch( 'api', {
        target: 'Actions/position',
        params: { positions }
      }).then(() => {
        // Wait for move effect
        return new Promise(( resolve, reject ) => {
          let time = 1000 - (new Date().getTime() - start);
          setTimeout(() => {
            this.$refs.table.reload()
              .then( resolve )
              .catch( reject );
          }, time < 0 ? 0 : time );
        });
      }).catch( err => {
        this.$store.dispatch( 'console/error', err );
        this.$refs.table.reload();
      }).finally(() => {
        this.silent = false;
      });
    },
    setTitle() {

      const id = parseInt( this.$route.params.id );
      const title = [ 'Campañas', '', 'Reportes' ];
      this.$store.commit( 'setTitle', title );

      if ( this.breadcrumbs[1].text === '...' && id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

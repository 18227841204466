<template>
  <Table
    ref="table"
    service="CampaignTasks"
    min-width="1414px"
    :is-loading="loading"
    :params="params"
    :sanitize="sanitize"
    :headers="headers"
    :messages="messages"
    :show-actions="actions"
    :importData="importData"
    import-accept=".xlsx"
  >

    <template v-slot:item.date="{ item, value }">
      <span :title="value" class="dtable-cell-content">
        {{ value }}
        <template v-if="item.numberOfDays === 7">
          <br/><strong>Semanal</strong>
        </template>
        <template v-if="item.numberOfDays >= 28 && item.numberOfDays <= 31">
          <br/><strong>Mensual</strong>
        </template>
      </span>
    </template>

    <template v-slot:item.campaign="{ item, value }">
      <div v-if="isClient">
        {{ value }}
      </div>
      <router-link class="body-grupo-ot grupo-ot-link" v-else :title="value" :to="`/campaigns/${ item.campaign.id }`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.user="{ item, value }">
       <div v-if="isClient">
          {{ value }}
      </div>
      <router-link class="body-grupo-ot grupo-ot-link" v-else-if="value" :title="value" :to="`/users/${item.user.id}`">
        {{ value }}
      </router-link>
    </template>

    <template v-slot:item.status="{ value }">
      <span v-if="value == 0" class="pending" title="Pendiente">Pendiente</span>
      <span v-if="value == 1" class="done" title="Realizada">Realizada</span>
      <span v-if="value == 2" class="validated" title="Validada">Validada</span>
    </template>

    <template v-slot:actions-right>
      <Btn class="ml-2" @click="initWizard" color="tertiary" dark v-if="!$store.state.isClient">
        Crear tareas
      </Btn>
    </template>

    <template v-slot:append-outer>
      <Wizard
        v-model="wizardShow"
        @save="createTask"
      />
    </template>

  </Table>
</template>

<script>

import Table from "@/components/core/Table.vue";
import Wizard from "@/components/forms/DayTasks/wizard.vue";
import { formatDate, userName, direction } from "@/utils";
import { CampaignStatus, CampaignActionType } from "@/utils/constants";
import { mapState, mapMutations } from "vuex";

const types = [
  { value: CampaignActionType.PICTURES, text: 'Fotografias' },
  { value: CampaignActionType.SURVEY,   text: 'Encuesta cualitativa' },
  { value: CampaignActionType.SALES,    text: 'Encuesta cuantitativa' },
  { value: CampaignActionType.FOLLOW,   text: 'Encuesta' }
];

function date( value ) {
  return formatDate( value, {
    year:  "numeric",
    month: "2-digit",
    day:   "2-digit"
  });
}

export default {
  components: { Table, Wizard },
  data() {
    return {
      initialized: false,
      loading: false,
      messages: { item: "Registro/Registros" },
      campaign: null,
      wizardShow: false
    };
  },
  computed: {
    ...mapState([ 'isClient', 'breadcrumbs' ]),
    params() {
      const { id } = this.$route.params;
      return {
        filters: [
          id ? {
            field: 'campaign',
            intValue: parseInt(id)
          } : {
            field: 'campaign_status',
            intValue: CampaignStatus.ACTIVE
          }
        ]
      };
    },
    actions() {
      if ( this.$store.state.isClient ) {
        return "export";
      }
      if ( this.$store.state.isAdmin ) {
        return "edit, delete, export, import, validate";
      }
      return "edit, delete, export, validate";
    },
    service() {
      const id = parseInt( this.$route.params.id );
      return id ? 'Campaigns' : 'CampaignTasks';
    },
    headers() {
      return [
        {
          text: "Fecha", value: 'date', width: 100,
          display: v => date( v ),
          filter: {
            type: 'rangedate',
            compute: ( value, field ) => {
              if ( !value[0] || !value[1] ) return {[field]: null };
              return {[field]: { start: value[0], end: value[1] }};
            }
          }
        },
        {
          text: "Campaña",
          value: "campaign",
          display: a => a ? `${a.id}. ${a.title||a.name}` : '',
        },
        {
          text: "Cliente",
          value: "campaign.client",
          display: a => a ? `${a.id}. ${a.title||a.name}` : '',
        },
        {
          text: "Tarea", value: "action", display: "title",
          filter: {
            type: 'text',
            field: 'action',
            attrs: {
              service: 'Actions',
              itemText: 'title',
              itemValue: 'id'
            }
          }
        },
        {
          text: "Tipo",
          value: "action.type",
          display: Object.values( types ).reduce(( a, v ) => { a[v.value] = v.text; return a; }, {}),
          width: 150,
          filter: {
            type: 'select',
            field: 'type',
            items: types
          }
        },
        {
          text: "Usuario", value: "user", display: v => userName(v),
          filter: {
            type: 'autocomplete',
            attrs: {
              service: 'Users',
              itemValue: 'id',
              itemText: 'name',
              display: v => userName( v, true )
            }
          }
        },
        {
          text: "Centro", value: "place", display: v => direction( v ),
          filter: {
            type: 'autocomplete',
            attrs: {
              service: 'Places',
              itemValue: 'id',
              itemText: 'name',
              display: v => direction( v ),
              all: true
            }
          }
        },
        {
          text: "Area", value: "place.area",
        },
        {
          text: "Estado",  value: "status", width: 145,
          filter: {
            type: 'select',
            items: [
              { value: 0, text: 'Pendiente' },
              { value: 1, text: 'Realizada' },
              { value: 2, text: 'Validada' }
            ]
          }
        }
      ]
    }
  },
  watch: {
    $route() {
      this.$nextTick( this.setTitle );
    }
  },
  methods: {
    ...mapMutations( 'app', [ 'setDialog' ]),
    sanitize( model ) {
      return {
        ...model,
        id: model.id || -1
      };
    },
    importData( params ) {
      return new Promise(( resolve, reject ) => {
        this.$store.commit( 'app/setDialog', {
          show: true,
          width: 400,
          accept: () => this.$store.dispatch( 'importData', params ).then( resolve ).catch( reject ),
          cancel: () => reject(),
          acceptText: 'Importar',
          title: 'Esta acción puede modificar los datos de los registros de la campaña',
          text: 'Por favor, asegurese de importar un archivo solamente con los datos modificados para evitar errores.'+
                '<p class="title mt-2 mb-0">¿Está seguro que desea continuar?</p>',
        });
      });
    },
    initWizard() {

      const text = '<p class="title text-uppercase black--text">Recuerda que las tareas se generan de forma automática.</p>'
                 + '<p>Esta funcionalidad solamente se debe utilizar para crear registros desaparecidos.</p>'
                 + '<p>En caso de duda consulte con el administrador.</p>';

      this.setDialog({
        show: true,
        width: 400,
        title: '',
        text,
        acceptText: 'Continuar',
        cancelText: 'Cancelar',
        accept: () => this.wizardShow = true
      });
    },
    createTask( params ) {
      this.loading = true;
      const target = 'CampaignTasks/create';
      this.$store.dispatch( 'api', { target, params }).then(() => {
        this.$store.dispatch( 'console/success', 'Tareas creadas' );
      }).catch( err => {
        this.$store.dispatch( 'console/error', err );
      }).finally(() => {
        this.loading = false;
        this.$nextTick( this.$refs.table.reload );
      });
    },
    setTitle() {

      const id = parseInt( this.$route.params.id );
      const title = [ 'Campañas', '', 'Registros' ];

      if ( ! id ) {
        title.splice( 0, 2 );
        return this.$store.commit( 'setTitle', title );
      }

      this.$store.commit( 'setTitle', title );
      if ( this.breadcrumbs[1].text === '...' && id ) {
        this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
          title[1] = res.title;
          this.$store.commit( 'setTitle', title );
        });
      }
    }
  },
  mounted() {
    this.setTitle();
  }
};
</script>

<style>
  .pending {
    color: var(--v-error-base);
  }
  .done {
    color: var(--v-warning-base);
  }
  .validated {
    color: var(--v-success-base);
  }
</style>

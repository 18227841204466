<template>
  <Layout
    :breadcrumbs="breadcrumbs"
    :silent="silent"
  >
    <template v-slot:top>

      <v-progress-linear
        v-if="isLoading && !silent"
        color="primary"
        indeterminate
        absolute
      />

      <slot name="top" v-bind="scope"/>

    </template>

    <slot v-bind="scope"/>

    <template v-slot:footer>

      <slot name="footer" v-bind="scope"/>

      <Toolbar v-if="!hideFooter" slot="footer" tag="footer" border-top>

        <slot name="actions" v-bind="scope"/>

      </Toolbar>
    </template>

    <input
      ref="file"
      type="file"
      v-show="false"
      :accept="importAccept"
      @change="onSelectFile"
    />

  </Layout>
</template>

<script>

  import Layout from '@/components/core/Layout.vue';
  import { mapMutations, mapState } from 'vuex';
  import { get } from '@/utils';

  export default {
    components: { Layout },
    props: {
      service: String,
      subservice: String,
      initialize: Function,
      importData: Function,
      exportData: Function,
      params: Object,
      loading: Boolean,
      hideFooter: Boolean,
      silent: Boolean,
      section: {
        type: Array,
        default: () => [0,0]
      },
      canExport: Boolean,
      importAccept: {
        type: String,
        default: '.csv'
      }
    },
    data: () => ({
      load: false
    }),
    computed: {
      ...mapState([ 'view', 'breadcrumbs' ]),
      isLoading() {
        return this.load || this.loading;
      },
      scope() {
        return {
          loading: this.isLoading,
          exportData: this.onExport.bind( this ),
          importData: this.onImport.bind( this )
        }
      }
    },
    watch: {
      isLoading( value ) {
        this.setBlock( value );
      },
      $route: 'reload'
    },
    methods: {
      ...mapMutations([ 'setPath', 'setView' ]),
      ...mapMutations( 'app', [ 'setBlock', 'setDialog' ]),
      reload() {

        if ( this.isLoading ) return;

        const path = this.$route.path;
        this.setPath( path );

        return new Promise( resolve => {
          this.$nextTick(() => {

            const { initialize, service, view } = this;
            if ( !service && !initialize ) return;

            const section = (( view ? view.section : 0 ) || this.section );
            const s = section.join('');
            const params = { service, page: 1, path, ...this.params };

            this.load = true;
            Promise.resolve( initialize
              ? initialize( params )
              : this.$store.dispatch( 'page', params )
            ).then( response => {

              const state = {
                sections: {
                  [s]: {
                    ...response,
                    filterStore: get( view, `sections.${s}.filterStore` ),
                    //filters: get( view, `sections.${s}.filters` )
                  }
                }
              };

              this.setView({ path, state });
              this.$emit( 'initialized', response );

            }).catch( err => {
              this.$store.dispatch( 'console/error', err );
            }).finally(() => {
              this.load = false;
              resolve();
            });
          });
        });
      },
      onImport() {
        this.$refs.file.click();
      },
      onExport( items ) {

        if ( this.canExport === false ) {
          this.setDialog({
            show: true,
            width: 400,
            title: 'ALERTA',
            text: '<p>No se recomienda ejecutar la exportación sin filtros ya que su ejecución podría'
                + ' tener un tiempo de ejecución excesivo. Se recomienda '
                + 'siempre exportar con filtros para ajustar mejor los resultados.</p>'
                + '<p class="mb-0"><strong>¿Está seguro que desea continuar?</strong></p>',
            acceptText: 'Continuar',
            accept: () => this.initializeExport( items )
          });
        } else {
          return this.initializeExport( items );
        }
      },
      initializeExport( items ) {
        return new Promise(( resolve, reject ) => {

          const { service, exportData } = this;
          if ( ! service ) return;

          var params = items && items.length
            ? { service, filters: [{ field: "ids", listValue: items.map( item => item.id )}] }
            : { service, page: 1, ...this.params };

          this.load = true;

          Promise.resolve( exportData
            ? exportData( params, items )
            : this.$store.dispatch( 'exportData', params )
          )
          .then( result => {
            window.open( result, "_blank" );
            resolve( result );
          })
          .catch( err => {
            this.$store.dispatch( 'console/error', err );
            reject( err );
          })
          .finally(() => this.load = false );
        });
      },
      onSelectFile() {

        const file = this.$refs.file.files[0];
        const { service, importData } = this;
        if ( ! service || ! file ) return;

        const data = new FormData();
        data.append( 'file', file );

        if ( this.$route.params.id ) {
          data.append( "idCampaign", this.$route.params.id );
          if ( this.$route.name == "ClientCenters" ) {
            data.append( "client", this.$route.params.id );
          }
        }

        const params = {
          service,
          data,
          idCampaign: this.$route.params.id || null
        };

        this.load = true;
        Promise.resolve( importData
          ? importData( params )
          : this.$store.dispatch( 'importData', params )
        )
        .then( res => {
          this.$store.dispatch( 'console/success', res );
          this.reload();
        })
        .catch( err => this.$store.dispatch( 'console/error', err ))
        .finally(() => {
          this.load = false;
          this.$refs.file.value = '';
        });
      },
    },
    beforeMount() {
      this.reload();
    }
  }
</script>

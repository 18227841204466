<template>
  <div class="stats-details-list">

    <div v-if="!hideSelector" class="mb-3">
      <DField
        field="select"
        field-style="1"
        v-model="selected"
        :items="computedItems"
        :label="title"
        style="max-width:300px"
        hide-details
        dense
      />
    </div>
    <div v-else-if="title">
      {{ title }}
    </div>

    <v-scroll-y-transition leave-absolute>
      <div
        v-if="items.length"
        class="d-flex flex-wrap"
        :key="selected"
      >

        <DStatsCheese
          v-if="type == 1"
          :title="titleCheese"
          :showLegend="false"
          class="ma-8"
          v-bind="computedCheeseProps"
        />

        <DStats
          v-if="type == 1"
          class="ma-8"
          :title="titleStats"
          :legend-columns="legendColumns"
          :cols="cols" :sm="sm" :md="md" :lg="lg"
          v-bind="computedCheeseStatsProps"
        />

        <DStatsProgress
          v-if="type == 0"
          :title="titleProgress"
          class="ma-8"
          v-bind="computedProgressProps"
        />

        <DStats
          v-if="type == 0"
          :title="titleStats"
          :legend-columns="legendColumns"
          :cols="cols" :sm="sm" :md="md" :lg="lg"
          class="ma-8"
          style="flex:1 1"
          v-bind="computedStatsProps"
        />

      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import colors from '@/utils/colors';

const defaultStatsProps = {
  color: 'tertiary',
  colors: colors
};

const defaultProgressProps = {
  minToSuccess: 97,
  minToWarning: 90
};

export default {
  props: {
    title: String,
    titleProgress: String,
    titleCheese: String,
    titleStats: String,
    value: Number,
    statsProps: Object,
    progressProps: Object,
    legendColumns: Boolean,
    hideSelector: Boolean,
    type : {
      type: Number, default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    cols: {
      type: [ Number, String ],
      default: 6
    },
    sm: {
      type: [ Number, String ],
      default: 4
    },
    md: {
      type: [ Number, String ],
      default: 3
    },
    lg: {
      type: [ Number, String ],
      default: 2
    }
  },
  data() {
    return {
      colors,
      selected: this.value || 0
    }
  },
  computed: {
    currentItem() {
      return this.items[ this.selected ] || {};
    },
    computedItems() {
      return this.items.map(( item, value ) => ({
        value: value,
        text: this.getName( item )
      }));
    },
    computedProgressProps() {
      return {
        ...defaultProgressProps,
        ...this.progressProps,
        ...this.currentItem.progress
      };
    },
    computedCheeseProps() {
      var stats = {
        ...defaultProgressProps,
        ...this.progressProps,
        ...this.currentItem.stats
      };
      stats.items = this.currentItem.stats.cheese;
      //Los colores a veces no se cargan bien, por eso los pongo aquí de nuevo...
      stats.color = "tertiary";
      stats.colors = colors;
      return stats;
    },
    computedStatsProps() {
      return {
        ...defaultStatsProps,
        ...this.statsProps,
        ...this.currentItem.stats
      };
    },
    computedCheeseStatsProps() {
      var stats = {
        ...defaultStatsProps,
        ...this.statsProps,
        ...this.currentItem.stats
      }

      stats.items = this.currentItem.stats.cheese;

      return stats;
    }
  },
  watch: {
    value( value ) {
      this.selected = value;
    },
    selected( value ) {
      this.$emit( 'input', value )
    },
    items() {
      this.selected = 0;
    }
  },
  methods: {
    getName( item ) {
      if ( item.id != null && item.id > 0 ) {
        return item.id + "." + item.name;
      }
      return item.name;
    }
  },
}
</script>

<style>
.stats-details-list {
  flex: 1 1;
}
.stats-details-list--title  {
  margin-bottom: 1.5rem;
}
.stats-details-list--list {
  width: 200px;
  max-height: 260px;
  overflow: auto;
}
.stats-details-list--list.v-list--dense .v-list-item {
  min-height: 30px;
}
.stats-details-list--list .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #AAA !important;
}
</style>

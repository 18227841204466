<template>
  <Page
    ref="page"
    service="Campaigns"
    :initialize="initialize"
    :loading="loading"
  >

    <Submenu slot="top">
      <DField
        v-if="isClient"
        field="select"
        field-style="1"
        class="mr-2"
        item-text="campaignName"
        item-value="idCampaign"
        style="flex: 1 1 300px; max-width: 300px;"
        label="Campaña"
        :items="stats"
        :value="idCampaign"
        @input="changeCampaign"
        dense
      />
    </Submenu>

    <template v-slot>
      <v-row class="fill-height" style="background: white;" no-gutters>
        <v-col class="rel" cols="12" md="2" order="1" order-md="0">
          <div class="column-wrapper">

            <Toolbar border-bottom>
              <span class="subtitle-2">Promotores</span>
            </Toolbar>

            <DField
              field="text"
              class="filter-input-search"
              style="flex: 0 0 35px"
              append-icon="mdi-magnify"
              v-model="search"
              dense
            />

            <div class="grow rel">
              <div class="layer autoscroll">

                <v-list v-if="users.length" class="py-0">
                  <v-list-item-group
                    :value="selected"
                    @change="set({ selected: $event })"
                    multiple
                  >
                    <v-list-item
                      v-for="( user ) in ( lazyUsers || users )"
                      v-show="user.show !== false"
                      :key="user.id"
                      :ripple="false"
                      color="tertiary"
                      class="px-4"
                    >
                      <template v-slot="{ active }">
                        <v-list-item-content>
                          <v-row class="flex-nowrap" align="center" style="max-width:100%" no-gutters>

                            <Btn class="mr-2" :color="active ? user.color : 'border'" width="30" height="30" small dark fab>
                              {{ user.letter }}
                            </Btn>

                            <v-list-item-title>
                              {{ user.name }}
                            </v-list-item-title>

                            <v-spacer/>

                            <v-icon small>
                              {{ active ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
                            </v-icon>

                          </v-row>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <p v-else class="pa-4" style="color:#999;">
                  No hay ningún promotor disponible en la fecha señalada.
                </p>

              </div>
            </div>
          </div>
        </v-col>
        <v-col class="rel" cols="12" md="3" order="2" order-md="1">
          <div class="column-wrapper outlined-left">

            <Toolbar border-bottom>
              <span class="subtitle-2">Posiciones</span>
            </Toolbar>

            <div class="grow rel">
              <div class="layer autoscroll">

                <v-list v-if="items.length" class="py-0">
                  <v-list-item-group
                    :value="position"
                    @change="set({ position: $event })"
                  >
                    <v-list-item
                      v-for="( pos, index ) in items"
                      :key="index"
                      :ripple="false"
                      color="tertiary"
                      class="px-4"
                    >
                      <template v-slot="{ active, toggle }">
                        <v-list-item-content>
                          <v-tooltip color="transparent" top>

                            <template v-slot:activator="{ on }">
                              <v-row v-on="on" class="flex-nowrap" align="center" no-gutters>

                                <v-icon class="mr-2" :color="accuracy(pos)" small>
                                  {{ accuracyIcon(pos) }}
                                </v-icon>

                                <span class="subtitle-2">
                                  {{ pos.name }}
                                </span>

                                <v-spacer/>

                                <span class="subtitle-1">
                                  {{ formatDate( pos.date ) }}
                                </span>

                              </v-row>
                            </template>

                            <Card class="pa-2" border>
                              <div>
                                <strong>Precisión:</strong>
                                <span :class="`${accuracy(pos)}--text`">
                                  {{ pos.accuracy }}
                                </span>
                              </div>
                              <div>
                                <strong>En area de acción:</strong>
                                <span :class="`${ pos.inRadius ? 'success' : 'error'}--text`">
                                  {{ pos.inRadius ? 'Sí':'No' }}
                                </span>
                              </div>
                            </Card>

                          </v-tooltip>

                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <p v-else class="pa-4" style="color:#999;">
                  No hay datos de posición.
                </p>

              </div>
            </div>

            <DPagination
              v-if="numPages"
              tag="footer"
              :value="page"
              :length="numPages"
              :visibles="3"
              @input="set({ page: $event })"
              border-top
            />

          </div>
        </v-col>
        <v-col cols="12" md="7" order="0" order-md="2">
          <div class="column-wrapper outlined-left">
            <Toolbar border-bottom>

              <Btn v-if="start > minDate" @click="prev" class="mr-3" color="secondary" icon>
                <v-icon>$prev</v-icon>
              </Btn>

              <DField
                field="date"
                field-style="1"
                class="mr-2"
                style="max-width: 150px;"
                :value="date"
                :min="minDate"
                :max="maxDate"
                :clearable="false"
                @input="set({ date: $event })"
                dense
              />

              <Btn v-if="end < maxDate" @click="next" color="secondary" icon>
                <v-icon>$next</v-icon>
              </Btn>

              <v-spacer/>

              <Btn @click="fixToday" color="tertiary" dark>
                Hoy
              </Btn>

            </Toolbar>
            <div class="grow rel">

              <DField
                field="gmap"
                :markers="markers"
                :polylines="polylines"
              >

                <gmap-info-window v-bind="info" @closeclick="set({ position: null })">
                  <div v-html="info.html"/>
                </gmap-info-window>

              </DField>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>

  /* eslint-disable */

  import Page from '@/components/core/Page.vue';
  import Submenu from '@/components/core/Submenu.vue';
  import { mapState, mapMutations } from 'vuex';
  import { Format } from '@/utils/time';
  import { direction, marker, get, normalize, userName, compare } from '@/utils';
  import Colors from '@/utils/colors';
  import moment from 'moment';

  export default {
    components: { Page, Submenu },
    data() {
      const today = moment().endOf('date').valueOf();
      return {
        today,
        firstTime: true,
        initialized: false,
        loading: false,
        lastDate: moment().endOf('date').add( 1, 'days' ).valueOf(),
        search: '',
        numItems: 20,
        lazyUsers: null,
        info: {
          opened: false,
          options: {
            maxWidth: 300,
            pixelOffset: {
              width: 0,
              height: -35
            }
          }
        }
      }
    },
    watch: {
      $route() {
        this.$nextTick( this.setTitle );
      },
      initialized() {
        this.$nextTick( this.loadByQuery );
      },
      selected( value, old ) {
        if ( ! compare( value, old )) {
          this.set({
            page: 1,
            position: null
          });
        }
      },
      date( value ) {
        if ( this.position == null && value !== this.lastDate )
          this.reload();
      },
      position( index, oldIndex ) {
        if ( oldIndex == null ) this.lastDate = this.date;
        this.setLabel( this.items[ index ] );
      },
      page() {
        const pos = this.items[ this.position ];
        pos && this.setLabel();
      },
      minDate( value ) {
        if ( value ) {
          this.set({
            date: Math.max( this.date, value )
          });
        }
      },
      maxDate( value ) {
        if ( value ) {
          this.set({
            date: Math.min( this.date, value )
          });
        }
      },
      search( value ) {
        var search = normalize( value, true );
        if ( ! search ) this.lazyUsers = null;
        this.lazyUsers = this.users.map( user => {
          var name = normalize( userName( user, true ), true );
          user.show = name.indexOf( search ) !== -1;
          return user;
        });
      }
    },
    computed: {
      ...mapState([ 'core', 'path', 'isClient', 'breadcrumbs' ]),
      date() {
        return get( this.core, 'date' ) || this.today;
      },
      position() {
        return get( this.core, 'position' ) || null;
      },
      selected() {
        return get( this.core, 'selected' ) || [];
      },
      page() {
        return get( this.core, 'page' ) || 1;
      },
      campaign() {
        return get( this.core, 'campaign' ) || null;
      },
      idCampaign() {

        var id = parseInt( this.$route.params.id );
        if ( id ) return id;

        const { user } = this.$store.state;
        if ( ! user.client || ! user.client.stats.length ) return null;

        id = this.campaign;
        if ( ! user.client.stats.some( a => a.idCampaign === id )) {
          id = user.client.stats[0].idCampaign;
        }

        return id;
      },
      selected() {
        return get( this.core, 'selected' ) || [];
      },
      stats() {
        const { user } = this.$store.state;
        if ( this.isClient ) return user.client.stats;
        return [];
      },
      minDate() {
        return get( this.core, 'data.campaign.startDate' );
      },
      maxDate() {
        const max = get( this.core, 'data.campaign.endDate' );
        return ! max || max > this.today ? this.today : max;
      },
      start() {
        return moment( this.date )
          .startOf('date').valueOf();
      },
      end() {
        return moment( this.date )
          .endOf('date').valueOf();
      },
      users() {

        const users = [];
        const positions = get( this.core, 'data.positions' ) || [];
        const centers = get( this.core, 'data.centers' ) || [];
        const buffer = { ids: [], chars: []};

        // Separe users
        positions.forEach(( a, index, position ) => {

          position = {
            accuracy: a.accuracy,
            lat: a.latitude,
            lng: a.longitude,
            inRadius: a.inRadius,
            name: a.userName,
            date: a.date
          };

          if (( index = buffer.ids.indexOf( a.idUser )) !== -1 ) {

            users[index].positions.push( position );
            users[index].lastPosition = position;

          } else {

            buffer.ids.push( a.idUser );
            users.push({
              id: a.idUser,
              name: a.userName,
              positions: [ position ],
              lastPosition: position,
              firstPosition: position
            })
          }
        });

        centers.forEach( center => {
          var user = center.user;

          if(user != null && users.filter(a=> a.id == center.user.id).length == 0){
            //No existe lo ponemos
             buffer.ids.push( user.id );
            users.push({
              id: user.id,
              name: user.fullName,
              positions: [],
              lastPosition: null,
              firstPosition: null
            })
          }
        });

        // Center, Letter and Color

        var num = 1;
        users.forEach(( user, index, name, word ) => {

          if ( user.positions.length > 0 )
            user.positions[ user.positions.length - 1 ].last = true;

          user.center = centers.find( a => a.user!=null && a.user.id === user.id );
          if ( user.center ) user.center = user.center.place;

          if ( ! ( name = String( user.name || '' ))) user.letter = '?';
          else {

            word = name.slice( 0, 1 );

            while( num <= name.length  ) {
              if ( buffer.chars.indexOf( word ) !== -1 ) word = name.slice( 0, ++num );
              else {
                buffer.chars.push(( user.letter = word ));
                break;
              }
            }
          }

          user.color  = Colors[ index % Colors.length ];
          user.letter = user.letter || user.name;
          num = 1;
        });

        return users.sort(( a, b ) => {
          if ( a.name > b.name ) return 1;
          else if ( b.name > a.name ) return -1;
          return 0;
        });
      },
      positions() {
        return this.selected
          .map( index => this.users[ index ] ? this.users[ index ].positions : [] )
          .flat()
          .sort(( a, b ) => b.date - a.date );
      },
      markers() {
        return this.selected.map(( user, markers, place ) => {

          user = this.users[ user ];
          if(user){
          markers = [ marker({
            position: user.lastPosition,
            label: { text: user.letter },
            icon: { fillColor: user.color },
            on: { click: this.select.bind( this ) }
          })];

          if ( user.center && user.center.latitude != null  && user.center.longitude != null )
            markers.push( marker({
              center: user.center,
              position: { lat: user.center.latitude, lng: user.center.longitude },
              label: { text: 'C', color: 'black' },
              icon: { fillColor: 'white', strokeColor: 'black' },
              on: { click: this.selectCenter.bind( this ) }
            }));
          }
          else{
            markers = [];
          }

          return markers;
        }).flat();
      },
      polylines() {
        return this.selected.map( index => ({
          path: this.users[ index ]?this.users[ index ].positions:[],
          editable: false,
          options: { strokeColor: this.users[ index ].color }
        }));
      },
      items() {
        const offset = ( this.page - 1 ) * this.numItems;
        return this.positions.slice( offset, offset + this.numItems );
      },
      numPages() {
        return Math.ceil( this.positions.length / this.numItems );
      }
    },
    methods: {
      ...mapMutations([ 'setView' ]),
      set( state ) {
        this.setView({
          path: this.path,
          state: { sections: { ['00']: state }}
        });
      },
      initialize() {
        return new Promise(( resolve, reject ) => {

          const { start, end, idCampaign } = this;
          if ( ! idCampaign ) return reject();

          this.loading = true;
          this.$store.dispatch( 'page', {
            service: 'Campaigns',
            method: 'positions',
            params: { idCampaign, start, end }
          })
          .then( data => {
            this.initialized = true;
            resolve({ data });
          })
          .catch( reject )
          .finally(() => this.loading = false );
        });
      },
      accuracyIcon( position ) {
        return position.inRadius
          ? 'mdi-checkbox-marked-circle-outline'
          : 'mdi-checkbox-blank-circle-outline';
      },
      accuracy( position ) {
        if ( position.accuracy > 50 ) return 'error';
        if ( position.accuracy > 25 ) return 'warning';
        return 'success';
      },
      formatDate( value ) {
        return Format( value, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        });
      },
      prev() {
        this.set({
          selected: [],
          position: null,
          date: moment( this.date )
            .endOf('date')
            .add( -1, 'days' )
            .valueOf()
        });
      },
      next() {
        this.set({
          selected: [],
          position: null,
          date: moment( this.date )
            .endOf('date')
            .add( 1, 'days' )
            .valueOf()
        });
      },
      fixToday() {
        this.set({
          selected: [],
          position: null,
          date: moment()
            .endOf('date')
            .valueOf()
        });
      },
      select( e, marker ) {

        var index = this.items.indexOf( marker.position );
        var position = this.position;
        var page = this.page;

        if ( index !== -1 ) position = index;
        else {
          if (( index = this.positions.indexOf( marker.position )) === -1 ) return;
          var page = Math.ceil(( index + 1 ) / this.numItems );
          index -= page > 1 ? ( page - 1 ) * this.numItems : 0;
          page = page;
          position = index;
        }

        this.set({ page, position });
      },
      selectCenter( e, marker ) {
        const { position, center } = marker;
        this.set({ position: null });
        center && this.setLabel( position, center );
      },
      setLabel( position, center ) {
        const { info } = this;
        if ( position ) {

          const { options } = info;
          const { pixelOffset } = options;

          if ( position.date ) {
            this.set({ date: position.date });
          }

          this.info = {
            ...info,
            options: {
              ...options,
              pixelOffset: {
                ...pixelOffset,
                height: ( position.last || center ) ? -35 : 0
              },
            },
            opened: true,
            position,
            html: (
              '<ul class="map-label">'
                + ( center
                  ? [
                      { f: 'Centro',   v: direction( center )},
                      { f: 'Teléfono', v: center.phone }
                    ]
                  : [
                      { f: 'Promotor', v: position.name },
                      { f: 'Fecha',    v: this.formatDate( position.date ) }
                    ]
                )
                .filter( a => a.v )
                .map( a => `<li><strong>${ a.f }:</strong> ${ a.v }</li>`)
                .join('')
              + '</ul>'
            )
          };

        } else {

          this.set({ date: this.lastDate });
          this.info = {
            ...info,
            opened: false,
            position: null,
            html: ''
          };

        }
      },
      changeCampaign( stat ) {
        var id = stat;
        if(stat.idCampaign !== undefined){
          id = stat.idCampaign;
        }
        if ( id === this.idCampaign ) return;
        this.set({ campaign: id });
        this.reload();
      },
      loadByQuery() {

        var hasQuery = false;
        var selected = this.selected.slice();
        var { date, page, position } = this;

        // Selection
        var user = parseInt( this.$route.query.user );
        if ( user > 0 && ( user = this.users.findIndex( a => a.id === user )) !== -1 ) {
          selected.push( user );
          hasQuery = true;
        }

        // Date
        if ( this.firstTime ) {
          var _date = parseInt( this.$route.query.date );
          if ( _date > 0 ) {
            date = _date;
            hasQuery = true;
          }
          this.firstTime = false;
        }

        // Page
        var _page = parseInt( this.$route.query.page );
        if ( _page > 0 ) {
          page = _page;
          hasQuery = true;
        }

        // Position
        var _pos = parseInt( this.$route.query.pos );
        if ( _pos > 0 ) {
          position = _pos;
          hasQuery = true;
        }

        hasQuery && this.set({
          selected,
          page,
          position,
          date
        });
        
        return hasQuery;
      },
      reload() {
        const { page } = this.$refs;
        page && page.reload();
      },
      setTitle() {

        const id = parseInt( this.$route.params.id );
        const title = [ 'Campañas', '', 'Geolocalización' ];
        this.$store.commit( 'setTitle', title );

        if ( this.breadcrumbs[1].text === '...' && id ) {
          this.$store.dispatch( 'api', { target: 'Campaigns/get', params: { id }}).then( res => {
            title[1] = res.title;
            this.$store.commit( 'setTitle', title );
          });
        }
      }
    },
    mounted() {
      this.setTitle();
    }
  }
</script>

<style>
  .outlined-left {
    border-left: 1px solid var(--v-border-base);
  }
  .column-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
  }
  .map-label {
    list-style: none;
    padding: 0 !important;
  }
  .map-label li {
    margin-bottom: 8px;
  }
  .map-label li:last-child {
    margin-bottom: 0;
  }
</style>

<template>
  <DForm
    ref="form"
    class="pa-4"
    :value="value"
    v-on="{ ...$listeners, input }"
    always-changed
  >

    <template v-for="(field,i) in selectFields">

      <p :key="i" class="body-grupo-ot mb-2">
        {{ field.label }}
      </p>

      <DField
        :key="field.key"
        :value="model[field.key]"
        v-bind="field.props"
        @input="fieldInput( field.key, $event )"
      />

    </template>

    <p class="body-grupo-ot mb-2">
      Observaciones
    </p>

    <DField
      field="textarea"
      field-style="1"
      rows="4"
      :value="model.comments"
      @input="fieldInput('comments',$event)"
      no-resize
    />

  </DForm>
</template>

<script>

  export default {
    props: {
      value: Object,
      readonly: Boolean
    },
    data: function() {
      return {
        model: {},
        fields: {
          puntuality: 'Puntualidad en tienda',
          presence: 'Preséncia',
          uniformity: 'Uniformidad',
          product: 'Conocimiento de producto',
          globalInterest: 'Interés global',
          requests: 'Actitud ante peticiones del coordinador',
          changes: 'Actitud ante los cambios',
          comercial: 'Aptitud comercial / Ventas',
          relationship: 'Relación con los compañeros de tienda',
          reporting: 'Puntualidad en reporte',
          reportQuality: 'Calidad información reporte',
          storeValoration: 'Valoración tienda',
          clientValoration: 'Valoración cliente',
          selection: 'Origen selección',
          selectAgain: 'Volver a contactar'
        },
        fieldItems: {
          valoration: [
            { text: 'Sin valoración', value: null },
            { text: 0, value: 0 },
            { text: 1, value: 1 },
            { text: 2, value: 2 },
            { text: 3, value: 3 },
            { text: 4, value: 4 }
          ],
          selection: [
            { text: 'Sin info',       value: null },
            { text: 'RRHH Grupo OT',    value: 1 },
            { text: 'Tienda',         value: 2 },
            { text: 'Cliente',        value: 3 }
          ],
          clientValoration: [
            { text: 'Sin info',       value: null },
            { text: 'Mala',    value: 1 },
            { text: 'Media',         value: 2 },
            { text: 'Buena',        value: 3 },
            { text: 'Muy bien',        value: 4 }
          ],
          storeValoration: [
            { text: 'Sin info',       value: null },
            { text: 'Mala',    value: 1 },
            { text: 'Media',         value: 2 },
            { text: 'Buena',        value: 3 },
            { text: 'Muy bien',        value: 4 }
          ],
          selectAgain: [
            { text: 'Sin valoración', value: null },
            { text: 'Sí',             value: 1 },
            { text: 'No',             value: 0 },
          ]
        }
      }
    },
    computed: {
      selectFields() {
        return Object.keys( this.fields ).map( key => {
          var items = this.fieldItems[key] || this.fieldItems.valoration;
          return {
            key,
            label: this.fields[key],
            props: {
              field: 'select',
              fieldStyle: 1,
              class: 'mb-4',
              placeholder: items[0].text,
              dense: true,
              items
            }
          };
        });
      }
    },
    methods: {
      input( value ) {
        this.model = value;
        if ( this.$listeners.input )
          this.$listeners.input( value );
      },
      fieldInput( key, value ) {
        this.model[key] = value;
        this.input( this.model );
      },
      change() {
        this.$emit( 'input', this.model );
      },
      reset() {
        this.$refs.form.reset();
        this.model = { ...this.model };
      }
    }
  }
</script>

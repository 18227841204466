<template>
  <Table
    service="VersionLogs"
    :messages="messages"
    :mypopup="mypopup"
    :headers="headers"
    :sanitize="sanitize"
    show-actions="new, edit, delete, import, export"
  >
    <template v-slot:form="{ value, listeners }">
      <VersionLog class="pa-4" :value="value" v-on="listeners" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/core/Table.vue";
import VersionLog from "@/components/forms/VersionLogs/single.vue";
import moment from "moment";

function date(time) {
  if (time) return moment(time).format("DD/MM/YYYY");
  return "-";
}

export default {
  components: { Table, VersionLog },
  data() {
    return {
      messages: { item: "Log Versión/Log Versiones" },
      headers: [
        {
          text: "Fecha",
          value: "date",
          display: date,
          width: 300,
          filter: {
            type: "rangedate",
            compute: (value, field) => {
              if (!value[0] || !value[1]) return { [field]: null };
              return { [field]: { start: value[0], end: value[1] } };
            },
          },
        },
        {
          text: "Versión",
          value: "build",
          width: 300,
          filter: { field: "build" },
        },
        {
          text: "Descripción",
          value: "description",
          filter: { field: "description" },
        },
      ],
      mypopup: {
        height: 450,
        title: (n) => (n ? "Nuevo log" : "Editar log"),
        initial: (name) => ({ name }),
      },
    };
  },
  methods: {
    sanitize(model) {
      return {
        id: model.id || -1,
        date: model.date,
        build: model.build,
        description: model.description
      };
    },
  },
  mounted() {
    this.$store.commit( 'setTitle', 'Log de Versiones' );
  }
};
</script>
